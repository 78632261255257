<template>
  <div class="form-control-container d-flex align-items-end">
    <k-input-config sticky="right" placeholder="Hours" no-container v-bind="passedConfig">
      <k-input-generic-select
        v-if="hourOptions"
        v-model="hours"
        :label
        :options="hourOptions"
        :get-id="(o) => o.toString()"
        :get-label="(o) => o.toString() + ' hr'"
        class="flex-grow-1" />
      <k-input-number v-else v-model="hours" :label :min="0" class="w-50" suffix="hr" />
    </k-input-config>
    <k-input-config sticky="left" placeholder="Minutes" no-container v-bind="passedConfig">
      <k-input-generic-select
        v-if="minuteOptions"
        v-model="minutes"
        :options="minuteOptions"
        :get-id="(o) => o.toString()"
        :get-label="(o) => o.toString() + ' min'"
        class="flex-grow-1" />
      <k-input-number v-else v-model="minutes" :min="hours ? -1 : 0" :max="60" class="flex-grow-1" suffix="min" />
    </k-input-config>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import KInputConfig from "../KInputConfig.vue";
import KInputNumber from "../numeric/KInputNumber.vue";
import KInputGenericSelect from "../select/KInputGenericSelect.vue";
import { useInputConfig } from "../inputConfig";

import type { MinutePrecision } from "@data/fields/basic/DurationField";

const props = defineProps<{
  /** Label for the input */
  label?: string;
  /** Maximum hours */
  maxHours?: number;
  /** Minute precision to use */
  precision?: MinutePrecision;
}>();

const value = defineModel<number>();

const config = useInputConfig();
const passedConfig = computed(() => ({
  status: config.value.status,
  disabled: config.value.disabled,
  readonly: config.value.readonly,
  required: config.value.required
}));

const hourOptions = computed(() => {
  if (!props.maxHours) return undefined;
  const options = [];
  for (let i = 0; i <= props.maxHours; i++) {
    options.push(i);
  }
  return options;
});

const minuteOptions = computed(() => {
  if (!props.precision) return undefined;
  const options = [];
  for (let i = 0; i < 60; i += props.precision) {
    options.push(i);
  }
  return options;
});

const minutes = ref<number>();
const hours = ref<number>();

watch(
  value,
  (v) => {
    if (v === undefined) {
      minutes.value = undefined;
      hours.value = undefined;
    } else {
      // allow minutes to be undefined when it would be 0
      const targetMinutes = v % 60;
      if (targetMinutes !== 0 || minutes.value !== undefined) {
        minutes.value = targetMinutes;
      }
      hours.value = Math.floor(v / 60);
    }
  },
  { immediate: true }
);

watch([minutes, hours], ([m, h]) => {
  if ((m === undefined && h === undefined) || (m === undefined && minuteOptions.value) || (h === undefined && hourOptions.value)) {
    value.value = undefined;
  } else {
    value.value = Math.max(0, (h ?? 0) * 60 + (m ?? 0));
  }
});
</script>
