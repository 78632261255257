<template>
  <p v-if="link" :id class="user-selectable">
    <router-link v-if="internal" :to="link">{{ label ?? "Unknown" }}</router-link>
    <a v-else :href="formattedLink" :target="target ?? '_blank'">{{ label ?? stripProtocol(link) }}</a>
  </p>
  <p v-else :id class="use-selectable text-secondary">None</p>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { RouterLink } from "vue-router";

import { getValidURL, stripProtocol } from "@data/helpers/strings/Urls";

const props = defineProps<{
  /** ID of the display for labelling purposes */
  id: string;
  /** URL to display */
  link: string;
  /** Whether the link is internal */
  internal?: boolean;
  /** Link text to display */
  label?: string;
  /** Target for external links */
  target?: "_blank" | "_self" | "_parent" | "_top";
}>();

const formattedLink = computed(() => {
  if (props.internal) {
    return props.link;
  }
  return getValidURL(props.link);
});
</script>
