import { KField } from "../KField";

import type { KRecord, StoredKRecord } from "../../data/Record";
import type { KEntity } from "../../data/KEntity";
import type { KFieldSetup } from "../KField";

export class MultiObjectField<R extends KRecord = KRecord> extends KField<R[]> {
  readonly type = "objects";

  entity: KEntity;

  constructor(setup: KFieldSetup | string, entity: KEntity) {
    super(setup);
    this.entity = entity;
  }

  getValue(record: KRecord): R[] | undefined {
    return record[this.key] as R[] | undefined;
  }

  getSortableValue(record: KRecord): number | undefined {
    return this.getValue(record)?.length;
  }

  getDisplayValue(record: KRecord): string {
    const count = this.getSortableValue(record);
    if (count === undefined) {
      return "";
    }
    if (count === 1) {
      return `1 ${this.entity.singular.toLowerCase()}`;
    }
    return `${count} ${this.entity.plural.toLowerCase()}`;
  }

  validateType(value: unknown): value is R[] {
    return Array.isArray(value) && value.every((r) => r && typeof r === "object" && this.entity.validateRecordType(r as KRecord));
  }

  storeValue(source: KRecord, target: StoredKRecord): void {
    const value = this.getValue(source);
    if (value) {
      target[this.key] = value.map((r) => this.entity.storeRecord(r));
    }
  }

  restoreValue(source: StoredKRecord, target: KRecord): void {
    const value = source[this.key] as StoredKRecord[] | undefined;
    if (value) {
      target[this.key] = value.map((sr) => this.entity.restoreRecord(sr));
    }
  }
}
