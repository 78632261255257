<template>
  <k-input v-model="input" :label />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { useDebounce } from "@vueuse/shared";

import { mergeInputConfig, useInputConfig } from "../inputConfig";

import KInput from "./KInput.vue";

import { getValidURL, stripProtocol } from "@data/helpers/strings/Urls";
import type { ValidationIssues } from "@data/validation/Validation";

defineProps<{
  /** Label for the input */
  label?: string;
}>();

const input = ref<string>();
const modelValue = defineModel<string>();
const couldConnect = ref<boolean>();
// getValidURL will also call appendDefaultProtocol and handle that
const url = computed(() => getValidURL(input.value) || input.value);
const debouncedUrl = useDebounce(url, 1000);

watch(
  modelValue,
  (newModelValue) => {
    if (newModelValue !== url.value) input.value = stripProtocol(newModelValue || "");
  },
  { immediate: true }
);

watch(url, () => {
  couldConnect.value = undefined;
});

/* watch the debouced url and ping the website, updating `couldConnect`
 * this isn't great, because it only returns false if fetch throws an exception
 */
watch(debouncedUrl, async (newUrl) => {
  // fetch really doesn't like undefined, so just check before calling it
  if (newUrl) {
    const abort = AbortSignal.timeout(2000);

    try {
      await fetch(newUrl, { method: "HEAD", mode: "no-cors", signal: abort });
      couldConnect.value = true;
    } catch (e) {
      couldConnect.value = false;
    }
  }
});

const extraIssues = computed(() => {
  const issues: ValidationIssues = [];

  // if invalidURL and not null, set it as an error
  if (!getValidURL(input.value) && input.value) {
    issues.push({
      message: "Invalid URL",
      severity: "error"
    });
  }
  // if you can't ping the website, set it as a warning
  if (couldConnect.value === false) {
    issues.push({
      message: "Site could not be reached",
      severity: "warning"
    });
  }

  return issues;
});

const config = useInputConfig();

mergeInputConfig(
  computed(() => ({
    issues: config.value.issues.concat(extraIssues.value),
    status: couldConnect.value && config.value.status === "NONE" ? "VALID" : config.value.status
  }))
);

watch(url, (newUrl) => (modelValue.value = newUrl));
</script>
