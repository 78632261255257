<template>
  <div :class="dropdownClasses">
    <button
      type="button"
      class="btn dropdown-toggle dropdown-toggle-split"
      :class="classes"
      :disabled
      :title="label"
      :aria-expanded="isOpen"
      :aria-disabled="disabled"
      :aria-pressed="pressed"
      @click="onToggle">
      <k-icon v-if="icon" :icon :title="capitalise(icon)" />
    </button>
    <ul ref="dropdown" class="icon-picker-dropdown dropdown-menu py-2 ps-2" :class="{ show: isOpen, 'dropdown-menu-end': alignright }">
      <div class="pb-2 pe-2"><k-input-search v-model="iconFilter" /></div>
      <div v-if="isOpen" class="icon-picker-icons-list pe-2">
        <li
          v-for="i in icons"
          :key="i.icon"
          :title="i.label"
          class="icon-option"
          :class="{ active: modelValue == i.icon }"
          @click="selectIcon(i)"
          @keydown.enter="selectIcon(i)">
          <k-icon :icon="i.icon" :title="capitalise(i.label)" />
        </li>
      </div>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { onClickOutside } from "@vueuse/core";

import KIcon from "@ui/icon/KIcon.vue";
import KInputSearch from "@ui/inputs/search/KInputSearch.vue";

import IconList from "./icons.json";

import type { UIColourVariant } from "@data/types/ColourVariant";
import { capitalise } from "@data/helpers/strings/Casing";

const props = withDefaults(
  defineProps<{
    /** Colour for the button */
    variant?: UIColourVariant;
    /** Label for the button */
    label?: string;
    /** Disabled state */
    disabled?: boolean;
    /** Determines if button is in pressed state */
    pressed?: boolean;
    /** Aligns the dropdown to the right */
    alignright?: boolean;
    /** CSS classes for button */
    classes?: string;
    /** Size of the input element */
    size?: "sm" | "md" | "lg";
    /** Direction of dropdown (up) */
    dropup?: boolean;
    /** Direction of dropdown (left) */
    dropstart?: boolean;
    /** Direction of dropdown (right) */
    dropend?: boolean;
  }>(),
  {
    variant: "secondary",
    label: "Select an icon",
    disabled: false,
    pressed: false,
    alignright: false,
    classes: "",
    size: "sm",
    iconFilter: ""
  }
);

const emit = defineEmits<{
  /** Event handler for when user clicks on button */
  (e: "click"): void;
  /** Event handler for when icon picker dropdown toggles visibility */
  (e: "toggle", value: boolean): void;
}>();

const icon = defineModel<string>();

const iconFilter = ref("");
const isOpen = ref(false);
const dropdown = ref();

onClickOutside(dropdown, () => (isOpen.value = false));

const selectIcon = (newIcon: { icon: string; label: string }) => {
  icon.value = newIcon.icon;
};

const classes = computed(() => ({
  btn: true,
  [`btn-${props.size}`]: true,
  [`btn-${props.variant}`]: true,
  active: props.pressed,
  "dropdown-toggle-no-caret": true
}));

const dropdownClasses = computed(() => ({
  dropup: props.dropup,
  dropend: props.dropend,
  dropstart: props.dropstart
}));

const onToggle = (event: MouseEvent) => {
  if (props.disabled) {
    event.preventDefault();
  } else {
    isOpen.value = !isOpen.value;
    iconFilter.value = "";
    emit("toggle", isOpen.value);
    emit("click");
  }
};

const icons = computed(() => {
  if (iconFilter.value) {
    return IconList.filter((i) => i.icon.includes(iconFilter.value.toLowerCase()) || i.terms.some((x) => x.includes(iconFilter.value.toLowerCase())));
  } else {
    return IconList.slice(0, 200);
  }
});
</script>

<style scoped lang="scss">
.icon-option {
  float: left;
  width: 26px;
  height: 26px;
  padding: 0;
  border-radius: 4px;
  text-align: center;
  line-height: 1.6rem;
  &:hover,
  &:focus {
    color: var(--k-navbar-item-color);
    background-color: var(--k-navbar-item-hover-background);
  }
  &:active,
  &.highlighted {
    color: var(--k-navbar-item-color);
    background-color: var(--k-navbar-item-pressed-background);
  }
  &.active {
    color: var(--k-navbar-item-active-color);
    background-color: var(--k-dropdown-item-active-background);
  }
}

.icon-picker-dropdown {
  width: 280px;
  .icon-picker-icons-list {
    overflow: auto;
    width: 100%;
    max-height: 200px;
  }
}
</style>
