import type { Ref } from "vue";
import { ref, watch, watchEffect, computed } from "vue";

import { defineStore } from "pinia";

import { NotificationAPI } from "../notifications";

import type { ReadonlyRef } from "@ui/helpers/refHelpers";

import type { ServerNotification, KNotification } from "@data/data/Notification";

import { useAuth } from "@/services/auth";
import { isUnitTest } from "@/tests/helpers/testFlag";
import { useLinkLookup } from "@/helpers/entity";

export const useNotificationStore = defineStore("notifications", () => {
  const notifications = ref<ServerNotification[]>();
  const notificationCount = ref(0);
  const notificationsRequired = ref(false);
  const loading = ref(true);

  const urlLookup = useLinkLookup();

  const getLinkUrl = (n: ServerNotification) => {
    if (!n.metadata) return;
    return urlLookup(n.metadata);
  };

  const kNotifications = computed(() =>
    notifications.value?.map<KNotification>((n) => ({
      id: n.id,
      createdAt: n.createdAt,
      title: n.title,
      content: n.content,
      priority: n.priority,
      isRead: n.isRead,
      linkUrl: getLinkUrl(n)
    }))
  );

  const { initialUnreadNotifications } = useAuth();

  let loadTestNotifications: ((serverNotifications: ServerNotification[]) => void) | undefined;
  if (isUnitTest) {
    loadTestNotifications = (testNotifications) => {
      notifications.value = testNotifications;
      loading.value = false;
    };
  } else {
    const { onResult, refetch, onError } = NotificationAPI.useNotifications(notificationsRequired);
    onResult((newData: { data?: { notifications?: ServerNotification[] } }) => {
      loading.value = false;
      if (newData.data?.notifications) {
        notifications.value = newData.data.notifications;
      }
    });
    onError(() => {
      loading.value = false;
    });
    const { onResult: onNotificationEvent } = NotificationAPI.onNotificationUpdated();
    onNotificationEvent((evt) => {
      if (evt.data?.notificationUpdated) {
        const info = evt.data.notificationUpdated;
        switch (info.eventType) {
          case "NOTIFICATION_ADDED":
            if (notifications.value) {
              void refetch();
            } else {
              // all added notifications are likely unread
              notificationCount.value += info.notificationIds.length;
            }
            break;
          case "NOTIFICATION_READ":
            if (notifications.value) {
              const idSet = new Set(info.notificationIds);
              for (const notification of notifications.value.filter((n) => idSet.has(n.id))) {
                notification.isRead = true;
              }
            } else {
              notificationCount.value -= info.notificationIds.length;
            }
            break;
          case "NOTIFICATION_DELETED":
            notifications.value = notifications.value?.filter((n) => !info.notificationIds.includes(n.id));
            break;
        }
      }
    });
  }

  watchEffect(() => {
    if (notifications.value) {
      notificationCount.value = notifications.value.filter((n) => !n.isRead).length;
    }
  });

  watch(
    initialUnreadNotifications,
    (newVal) => {
      notificationCount.value = newVal;
    },
    { immediate: true }
  );

  const handleEnable = (enable?: Ref<boolean>) => {
    if (enable) {
      watchEffect(() => {
        if (enable.value) {
          notificationsRequired.value = true;
        }
      });
    } else {
      notificationsRequired.value = true;
    }
  };

  const useNotifications = (enable?: Ref<boolean>): ReadonlyRef<ServerNotification[] | undefined> => {
    handleEnable(enable);
    return notifications;
  };

  const useKNotifications = (enable?: Ref<boolean>) => {
    handleEnable(enable);
    return kNotifications;
  };

  const { mutate: markAsReadMutation } = NotificationAPI.useMarkNotificationRead();

  const markAsRead = async (id: string | string[]) => {
    const ids = Array.isArray(id) ? id : [id];
    const idSet = new Set(ids);
    // optimistic update
    notifications.value = notifications.value?.map((n) => {
      if (idSet.has(n.id)) {
        return { ...n, isRead: true };
      }
      return n;
    });
    const result = await markAsReadMutation({ ids });
    if (!result?.data?.markNotificationRead) {
      // revert optimistic update
      notifications.value = notifications.value?.map((n) => {
        if (idSet.has(n.id)) {
          return { ...n, isRead: false };
        }
        return n;
      });
      return false;
    }
    return true;
  };

  const { mutate: dismissNotification } = NotificationAPI.useDeleteNotifications();
  const dismiss = async (id: string | string[]) => {
    const ids = Array.isArray(id) ? id : [id];
    const idSet = new Set(ids);
    // optimistic update
    const deleted = notifications.value?.filter((n) => idSet.has(n.id));
    notifications.value = notifications.value?.filter((n) => !idSet.has(n.id));
    const result = await dismissNotification({ ids });
    if (!result?.data?.deleteNotifications) {
      // revert optimistic update
      notifications.value = notifications.value?.concat(deleted ?? []);
      return false;
    }
    return true;
  };

  const readonlyCount = notificationCount as ReadonlyRef<number>;

  return {
    notificationCount: readonlyCount,
    loadTestNotifications,
    useNotifications,
    useKNotifications,
    markAsRead,
    dismiss,
    loading
  };
});
