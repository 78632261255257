import { displayURL } from "../../helpers/strings/Urls";
import { StringlikeField } from "../basic/StringFields";

import type { KRecord } from "../../data/Record";

export class WebsiteField extends StringlikeField {
  readonly type = "website";

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    if (value) {
      return displayURL(value);
    }
    return "";
  }

  getSortableValue(record: KRecord, toLowerCase?: boolean): string | undefined {
    const value = this.getValue(record);
    if (value) {
      const display = displayURL(value);
      return toLowerCase ? display.toLowerCase() : display;
    }
    return undefined;
  }

  getCSVValue(record: KRecord): string {
    return this.getValue(record) ?? "";
  }

  getDefaultColumnWidth() {
    return 150;
  }
}
