import { HasRoleExpression, PersonIdExpression } from "./permissions";

import { expressionParser } from "@data/expressions/parser";
import { refine } from "@data/helpers/NullHelpers";

export const registerKinabaseExpressions = () => {
  expressionParser.registerFunction(
    "hasRole",
    (tokens, context) =>
      // evaluate the arguments for the moment since want to remove quotes - might want dynamic roles in the future?
      new HasRoleExpression(refine(tokens, (a) => expressionParser.parseString(a, context).evaluate({})))
  );
  expressionParser.registerFunction("personId", (args) => {
    if (args.length !== 0) {
      throw new Error("personId function takes no arguments");
    }
    return new PersonIdExpression();
  });
};
