<template>
  <div class="notification-item d-flex py-2" :class="{ unread: !notification.isRead }">
    <div v-if="!notification.isRead" class="notification-indicator"></div>
    <div class="px-2 pt-1">
      <k-people-badge v-if="notification.createdByName" :badge="{ fullName: notification.createdByName }" />
      <img v-else-if="iconUrl" :src="iconUrl" height="30" width="30" />
      <div v-else class="notification-default-icon">
        <i class="far fa-fw fa-bell" />
      </div>
    </div>
    <div class="pe-2 flex-grow-1 notification-content" :class="{ 'text-d-15-blue': !notification.isRead }">
      <a v-if="notification.linkUrl" :href="notification.linkUrl" @click="emit('markAsRead')">
        <h6 class="mb-0">{{ notification.title }}</h6>
      </a>
      <h6 v-else class="mb-0">{{ notification.title }}</h6>
      <p class="mb-0 mt-1">{{ notification.content }}</p>
      <div v-if="notification.actionButtons?.length" class="pt-2">
        <k-button v-for="action in notification.actionButtons" :key="action.label" class="me-1" :variant="action.variant" @click="action.onClick">{{
          action.label
        }}</k-button>
      </div>
    </div>
    <div>
      <k-button v-if="!notification.isRead" variant="transparent" icon="eye" title="Mark as read" @click="emit('markAsRead')" />
      <k-button v-else variant="transparent" icon="trash" title="Dismiss" @click="emit('dismiss')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import KButton from "@ui/button/KButton.vue";
import KPeopleBadge from "@ui/badge/KPeopleBadge.vue";
import { useTheme } from "@ui/navbar/theme";

import type { KNotification } from "@data/data/Notification";

const props = defineProps<{
  /** Notification to show */
  notification: KNotification;
}>();

const emit = defineEmits<{
  (e: "markAsRead"): void;
  (e: "dismiss"): void;
}>();

const { isDark } = useTheme();
const iconUrl = computed(() => (isDark.value ? props.notification.icon?.dark : props.notification.icon?.light));
</script>

<style scoped lang="scss">
.notification-item {
  border-bottom: 1px dashed var(--k-border);
  align-items: center;
  position: relative;
  overflow: visible;
  transition: background-color ease-in-out 0.1s;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item.unread {
  background: var(--k-dropdown-item-active-background);
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.notification-item.unread:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.notification-item.unread:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.notification-indicator {
  position: absolute;
  top: calc(50% - 2px);
  left: -2px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--k-color-primary);
}

.notification-content {
  font-size: 0.9em;
}

.notification-default-icon {
  width: 30px;
  height: 30px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--k-color-secondary);
}
.unread .notification-default-icon {
  color: var(--k-color-primary);
}
</style>
