export const isFileAccepted = (accept: string | undefined, file: { name?: string; type: string }): boolean => {
  if (!accept) return true;
  if (!file.type && file.name) {
    const extension = file.name.split(".").pop();
    if (!extension) return false;
    return isFileAccepted(accept, { type: `application/${extension}` });
  }
  const re = new RegExp(accept.replace(/,/g, "|").replace(/\*/g, ".*"));
  return re.test(file.type);
};
