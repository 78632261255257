<template>
  <teleport to="body">
    <div id="message-box" ref="element" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div v-if="title && content" class="modal-header">
            <h1 class="h5 my-2">{{ title }}</h1>
          </div>
          <div class="modal-body">
            <slot v-if="title && !content">{{ title }}</slot>
            <div v-if="content" class="modal-content-pre">{{ content }}</div>
          </div>
          <div class="modal-footer">
            <k-button v-if="!hideCancel" label="Cancel" variant="secondary" @click="submit(false)" />
            <k-button :label="okLabel" :variant="okVariant" :loading="unref(loading)" :disabled="unref(loading)" @click="submit(true)" />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { ref, watchEffect, onMounted, watch, unref } from "vue";
import type { Ref } from "vue";

import { useEventListener } from "@vueuse/core";
import { Modal } from "bootstrap";

import KButton from "@ui/button/KButton.vue";

import type { UIColourVariantWithOutlines } from "@data/types/ColourVariant";

const props = withDefaults(
  defineProps<{
    /** Title of message box */
    title: string;
    /** (Optional) Content of message box */
    content?: string;
    /** (Optional) Boolean indicating whether primary message box action is loading. Needs Ref<> to be reactive */
    loading?: boolean | Ref<boolean>;
    /** Button variant colour of the primary button */
    okVariant?: UIColourVariantWithOutlines;
    /** Label for the primary button */
    okLabel?: string;
    /** Callback to handle message box result */
    onSubmit?: (ok: boolean) => void | Promise<void>;
    /** Callback (used in message-box.ts) to handle dismissing the message box */
    destroy?: () => void;
    /** Boolean indicating whether to hide the cancel button */
    hideCancel?: boolean;
  }>(),
  {
    okVariant: "primary",
    okLabel: "OK",
    loading: false,
    content: "",
    onSubmit: undefined,
    destroy: undefined
  }
);

const show = defineModel<boolean>("show");

const element = ref<HTMLElement>();
const instance = ref<Modal>();

const updateShow = (isShown: boolean) => {
  show.value = isShown;
  if (!isShown && props.destroy) {
    props.destroy();
  }
};

watchEffect(
  () => {
    useEventListener(element, "shown.bs.modal", () => updateShow(true));
    useEventListener(element, "hidden.bs.modal", () => updateShow(false));
  },
  { flush: "post" }
);

onMounted(() => {
  instance.value = new Modal(element.value as HTMLElement, {});
  instance.value.show();
});

watch(
  () => show.value,
  (value) => {
    if (value) {
      instance.value?.show();
    } else {
      instance.value?.hide();
    }
  }
);

const submit = async (result: boolean) => {
  if (props.onSubmit) {
    await props.onSubmit(result);
  }
  instance.value?.hide();
  updateShow(false);
};
</script>

<style scoped>
.modal-content-pre {
  white-space: pre-line;
}
</style>
