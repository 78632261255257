import type { Person } from "../data/Person";
import type { KEntity } from "../data/KEntity";
import type { KRecord } from "../data/Record";
import type { KField } from "../fields/KField";

// execution context for expressions
export interface KContext {
  entity?: KEntity; // current entity, needed for finding fields from field IDs
  record?: KRecord; // current record
  records?: KRecord[]; // current records, makes "unique" validator work on client
  field?: KField; // current field, needed for "value" shorthand to work
  items?: unknown[]; // current item of data, needed for "item" accessor to work
  state?: { editing?: boolean };
  person?: Person; // current person using Kinabase
  people?: Person[]; // all people in the system (needed for people lookups)
  personEntity?: KEntity; // entity for the person record
  roleLabels?: Map<string, string>; // map of role IDs to role labels
}

export class InsufficientContextError extends Error {
  public readonly missing: (keyof KContext)[];

  constructor(missing: keyof KContext | (keyof KContext)[], message?: string) {
    super(message ?? `Insufficient context, missing: ${Array.isArray(missing) ? missing.join(", ") : missing}`);
    this.missing = Array.isArray(missing) ? missing : [missing];
  }
}
