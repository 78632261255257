<template>
  <div class="pb-3 h-100 d-flex flex-column multi-collection-container">
    <div ref="scrollContainer" class="px-5 pt-3 flex-grow-1 border-bottom multi-collection-scrollable">
      <wizard-multi-collection-item
        v-for="collection in collections"
        :key="collection.id"
        :collection
        :name="collection.plural"
        :collections
        :disabled="isGenerating || !!saving"
        :fields="isGenerating ? fields[collection.id] : collection.fields"
        @icon-changed="onCollectionIconChanged(collection.id, $event)"
        @edit="emit('edit', collection.id, 'general')"
        @add-field="emit('edit', collection.id, 'fields')"
        @edit-field="emit('edit', collection.id, 'fields', $event)"
        @delete-field="deleteField(collection.id, $event)"
        @delete="deleteCollection(collection.id)" />

      <wizard-multi-collection-add-form v-if="currentState === 'ADD_FORM_OPEN'" ref="addForm" @cancel="currentState = 'DEFAULT'" @add="addCollection" />
      <wizard-multi-collection-placeholder v-else-if="collections.length === 0" :disabled="isGenerating || !!saving" @add="openAddForm" />

      <k-button v-else-if="!isGenerating" icon="plus" label="Add collection" class="mb-4" :disabled="isGenerating || !!saving" @click="openAddForm" />
      <div v-if="isGenerating" class="pb-3">
        <k-spinner v-if="isGenerating" />
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3 px-3">
      <div v-if="isGenerating" class="me-auto w-50 text-secondary pt-1">
        <small>Generating suggestions using AI, this may take a while...</small>
      </div>
      <k-issue-display v-else-if="showIssues && issues && !saving" class="mt-1 me-auto" :issues />
      <div v-else-if="hasGenerated" class="me-auto w-50 text-secondary disclaimer-text">
        <small>Please note that suggestions are generated by AI and may contain misjudgements, errors or inaccuracies</small>
      </div>
      <k-button v-if="isGenerating" icon="stop" label="Stop suggesting" class="me-3" @click="stopGenerating" />
      <k-button
        v-else
        icon="bolt"
        :label="hasGenerated ? 'Suggest new fields' : 'Suggest fields'"
        class="me-3"
        :loading="isGenerating"
        :disabled="isGenerating || !!saving || collections.length === 0"
        @click="suggestFields" />
      <k-button
        icon="plus"
        variant="success"
        label="Add collections"
        :disabled="isGenerating || (showIssues && (!canGoNext || saving))"
        :loading="saving"
        @click="next" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onBeforeUnmount, onMounted, ref } from "vue";

import { throttledWatch } from "@vueuse/core";

import KButton from "@ui/button/KButton.vue";
import KSpinner from "@ui/progress/KSpinner.vue";
import KIssueDisplay from "@ui/inputs/KIssueDisplay.vue";

import WizardMultiCollectionAddForm from "./WizardMultiCollectionAddForm.vue";
import WizardMultiCollectionItem from "./WizardMultiCollectionItem.vue";
import WizardMultiCollectionPlaceholder from "./WizardMultiCollectionPlaceholder.vue";
import { CollectionGenerator } from "./generators/CollectionFieldsGenerator";

import type { Collection } from "@data/data/Collection";
import type { KField } from "@data/fields/KField";
import type { ValidationIssues } from "@data/validation/Validation";

import { useCollectionStore } from "@/api/stores/useCollectionStore";

const props = defineProps<{
  /** Initial search value from user */
  prompt?: string;
  /** Suggested collections from previous step */
  collections: Collection[];
  /** Whether the user is saving */
  saving?: boolean;
  /** If AI generated collections then suggest fields */
  isai?: boolean;
}>();

const emit = defineEmits<{
  (event: "add", value: Collection): void;
  (event: "edit", collectionId: string, step: "general" | "fields", field?: KField): void;
  (event: "update", collectionId: string, property: "name" | "icon" | "fields", value: string | KField[]): void;
  (event: "delete", collectionId: string): void;
  (event: "save"): void;
}>();

const { collections: existingCollections } = useCollectionStore();

// Manage the list state
type EditorState = "DEFAULT" | "ADD_FORM_OPEN" | "GENERATING";
const currentState = ref<EditorState>("DEFAULT");

// Scroll to bottom when new collections are added
const scrollContainer = ref<HTMLElement>();
const scrollToBottom = () => {
  if (!(scrollContainer.value?.scrollTo as unknown)) return; // Need this because JSDom tests don't have scrollTo
  scrollContainer.value?.scrollTo({ top: scrollContainer.value.scrollHeight, behavior: "smooth" });
};

// Manage the collection field generation
const generator = new CollectionGenerator(existingCollections);
const fields = computed(() => generator.proposedFields.value);
const isGenerating = computed(() => generator.isGenerating.value);
const hasGenerated = computed(() => Object.keys(generator.proposedFields.value).length > 0);

onBeforeUnmount(() => {
  generator.stop();
});

const collectionStore = useCollectionStore();

const showIssues = ref(false);
const issues = computed(() => {
  const messages: ValidationIssues = [];

  if (props.collections.length === 0) {
    messages.push({
      message: "Please add at least one collection",
      severity: "error"
    });
  }

  // Check for name clashes
  const allCollections = [...(collectionStore.collections ?? []), ...props.collections];
  const allNames = allCollections.map((c) => c.plural);
  const hasNameClashes = allNames.some((name, i) => allNames.indexOf(name) !== i);
  if (hasNameClashes) {
    messages.push({
      message: "Some collections have the same name",
      severity: "error"
    });
  }

  // Check all collections have fields
  const allCollectionsHaveFields = props.collections.every((collection) => {
    const genFields = generator.proposedFields.value[collection.plural] as KField[] | undefined;
    return collection.fields.length > 0 || (genFields && genFields.length > 0);
  });

  if (!allCollectionsHaveFields) {
    messages.push({
      message: "Please add fields to all collections",
      severity: "error"
    });
  }

  return messages;
});

// Button handlers
const canGoNext = computed(() => props.collections.length > 0 && issues.value.length === 0 && !isGenerating.value);

const next = () => {
  showIssues.value = true;
  if (!canGoNext.value) return;
  emit("save");
};

const suggestFields = async () => {
  currentState.value = "GENERATING";
  await generator.generateCollectionFields(props.prompt ?? "", props.collections);

  // Update the collections with the generated fields
  for (const collectionId of Object.keys(generator.proposedFields.value)) {
    emit("update", collectionId, "fields", generator.proposedFields.value[collectionId]);
  }

  currentState.value = "DEFAULT";
};

// Adding new collections
const addForm = ref<{ focusInput: () => void }>();
const openAddForm = async () => {
  currentState.value = "ADD_FORM_OPEN";
  await nextTick(() => {
    addForm.value?.focusInput();
    scrollToBottom();
  });
};

const addCollection = async (newCollection: Collection) => {
  emit("add", newCollection);
  await nextTick(scrollToBottom);
};

// Editing collections
const onCollectionIconChanged = (collectionId: string, newIcon: string) => {
  emit("update", collectionId, "icon", newIcon);
};

// Deleting collections
const deleteCollection = (collectionId: string) => {
  emit("delete", collectionId);
};

// Deleting fields
const deleteField = (collectionId: string, field: KField) => {
  const collection = props.collections.find((c) => c.id === collectionId);
  if (!collection) return;
  emit(
    "update",
    collectionId,
    "fields",
    collection.fields.filter((f) => f !== field)
  );
};

onMounted(async () => {
  if (props.isai && !!props.collections.length && !hasGenerated.value && !props.collections.some((x) => x.fields.length)) {
    await suggestFields();
  }
});

throttledWatch(
  () => fields.value,
  (val) => {
    const count = Object.values(val).reduce((acc, curr) => acc + curr.length, 0);
    // Scroll to bottom if we have generated more than 5 fields
    if (isGenerating.value && count > 5) {
      scrollToBottom();
    }
  },
  { throttle: 500, deep: true }
);

const stopGenerating = () => {
  generator.stopGenerating();
};
</script>

<style scoped>
.multi-collection-container {
  min-height: 450px;
}

.multi-collection-scrollable {
  min-height: 420px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.disclaimer-text {
  line-height: 1.1;
}
</style>
