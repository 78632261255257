<template>
  <template v-if="commonCollectionOptions.length">
    <k-input-config description="Filters which linked records to show in this field">
      <k-input-select v-model="commonCollection" label="Filter by common collections" :options="commonCollectionOptions" null-label="No filter" />
    </k-input-config>
  </template>
  <filter-config
    v-if="targetCollection"
    v-model="customFilters"
    :description="!commonCollectionOptions.length ? 'Filters which linked records to show in this field' : undefined"
    :entity="targetCollection"
    label="Custom Filter" />
</template>

<script setup lang="ts">
import { computed } from "vue";

import KInputSelect from "@ui/inputs/select/KInputSelect.vue";
import KInputConfig from "@ui/inputs/KInputConfig.vue";

import FilterConfig from "./FilterConfig.vue";
import { useRelationalFilterLogic } from "./relationalFilterLogic";

import type { Collection } from "@data/data/Collection";
import type { RelationalFilters, RelationalField } from "@data/fields/relational/RecordFields";
import { getRelationalFields } from "@data/fields/relational/RecordFields";
import type { JsonSerialisable } from "@data/helpers/serialisation/TypedJSON";

import { useKContext } from "@/expressions/context";
import { useCollectionStore } from "@/api/stores/useCollectionStore";

const props = defineProps<{
  /** Relational field to create filters for */
  relationalField: RelationalField<JsonSerialisable>;
  /** Collection to configure relational filters for */
  collection?: Collection;
}>();

const modelValue = defineModel<RelationalFilters>();

const context = useKContext();
// load the collection from the context if not provided (and need different name to avoid conflict with prop)
const actualCollection = computed(() => props.collection ?? context.value.entity);

const collectionStore = useCollectionStore();
const targetCollection = collectionStore.useCollectionWithId(computed(() => props.relationalField.collectionId));

const thisCollectionOtherRelationals = computed(() =>
  actualCollection.value ? getRelationalFields(actualCollection.value).filter((f) => f.id !== props.relationalField.id) : []
);
const targetCollectionRelationals = computed(() => (targetCollection.value ? getRelationalFields(targetCollection.value) : []));

const { commonCollectionOptions, commonCollection, customFilters } = useRelationalFilterLogic(
  modelValue,
  thisCollectionOtherRelationals,
  targetCollectionRelationals
);
</script>
