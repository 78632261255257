import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { Registry } from "../helpers/serialisation/Registry";
import { omitFrom } from "../helpers/manipulation/Objects";

import { restoreSetup } from "./KField";
import { CurrencyField } from "./basic/CurrencyField";
import { DatetimeField } from "./basic/DatetimeField";
import { MetricField } from "./basic/MetricField";
import { MultiOptionField, OptionField } from "./basic/OptionFields";
import { BooleanField, NumberField, ProbabilityField, RatingField } from "./basic/SimpleFields";
import { StringField, RichTextField, IconField } from "./basic/StringFields";
import { FileField, MultiFileField } from "./complex/FileField";
import { LocationField } from "./complex/LocationField";
import { PasswordField } from "./complex/PasswordField";
import { RiskField } from "./complex/RiskField";
import { StageField } from "./complex/StageField";
import { ComputedNumberField } from "./computed/ComputedNumberField";
import { ComputedStringField } from "./computed/ComputedStringField";
import { EmailField } from "./contact/EmailField";
import { PhoneField } from "./contact/PhoneField";
import { WebsiteField } from "./contact/WebsiteField";
import { PersonField, PeopleField } from "./relational/PeopleFields";
import { RecordField, MultiRecordField } from "./relational/RecordFields";
import { AggregationField } from "./relational/AggregationField";
import { TimeSpanField } from "./complex/TimeSpanField";
import { DurationField } from "./basic/DurationField";
import { LookupField } from "./relational/LookupField";
import { ComputedDatetimeField } from "./computed/ComputedDatetimeField";

import type { AggregationFieldSetup } from "./relational/AggregationField";
import type { RegistryType } from "../helpers/serialisation/Registry";

dayjs.extend(utc);

export const fieldRegistry = new Registry()
  .register(
    "boolean",
    (bField: BooleanField) => bField.setup,
    (data) => new BooleanField(restoreSetup(data))
  )
  .register(
    "number",
    (nField: NumberField) => nField.setup,
    (data) => new NumberField(restoreSetup(data))
  )
  .register(
    "rating",
    (iField: RatingField) => ({ ...iField.setup, ...iField.range, inputType: iField.inputType }),
    (data) => new RatingField({ min: 1, max: 10, ...restoreSetup(data) })
  )
  .register(
    "currency",
    (cField: CurrencyField) => cField.setup,
    (data) => new CurrencyField(restoreSetup(data))
  )
  .register(
    "probability",
    (pField: ProbabilityField) => pField.setup,
    (data) => new ProbabilityField(restoreSetup(data))
  )
  .register(
    "string",
    (sField: StringField) => sField.setup,
    (data) => new StringField(restoreSetup(data))
  )
  .register(
    "richText",
    (rField: RichTextField) => rField.setup,
    (data) => new RichTextField(restoreSetup(data))
  )
  .register(
    "icon",
    (iField: IconField) => iField.setup,
    (data) => new IconField(restoreSetup(data))
  )
  .register(
    ["date", "datetime"],
    (dField: DatetimeField) => dField.setup,
    (data) => new DatetimeField(restoreSetup(data))
  )
  .register(
    "timeSpan",
    (field: TimeSpanField) => field.setup,
    (data) => new TimeSpanField(restoreSetup(data))
  )
  .register(
    "duration",
    (field: DurationField) => field.setup,
    (data) => new DurationField(restoreSetup(data))
  )
  .register(
    "option",
    (field: OptionField) => ({ ...field.setup, options: field.options }),
    (data) => {
      if (!data.options) {
        console.warn("Options data not found during deserialisation, using empty list instead.");
      }
      return new OptionField(restoreSetup(data), data.options ?? []);
    }
  )
  .register(
    "multiOption",
    (field: MultiOptionField) => ({ ...field.setup, options: field.options }),
    (data) => {
      if (!data.options) {
        console.warn("Options data not found during deserialisation, using empty list instead.");
      }
      return new MultiOptionField(restoreSetup(data), data.options ?? []);
    }
  )
  .register(
    "metric",
    (e: MetricField) => ({ ...e.setup, unit: e.unitSetup }),
    (data) => {
      if (!data.unit) {
        throw new Error("No unit found for Metric Field");
      }
      return new MetricField(restoreSetup(data), data.unit);
    }
  )
  .register(
    "email",
    (e: EmailField) => e.setup,
    (data) => new EmailField(restoreSetup(data))
  )
  .register(
    "website",
    (w: WebsiteField) => w.setup,
    (data) => new WebsiteField(restoreSetup(data))
  )
  .register(
    "location",
    (field: LocationField) => field.setup,
    (data) => new LocationField(restoreSetup(data))
  )
  .register(
    "password",
    (pField: PasswordField) => pField.setup,
    (data) => new PasswordField(restoreSetup(data))
  )
  .register(
    "risk",
    (field: RiskField) => field.setup,
    (data) => new RiskField(restoreSetup(data))
  )
  .register(
    "phone",
    (field: PhoneField) => field.setup,
    (data) => new PhoneField(restoreSetup(data))
  )
  .register(
    "file",
    (field: FileField) => field.setup,
    (data) => new FileField(restoreSetup(data))
  )
  .register(
    "multiFile",
    (field: MultiFileField) => field.setup,
    (data) => new MultiFileField(restoreSetup(data))
  )
  .register(
    "computedNumber",
    (f: ComputedNumberField) => ({
      ...f.setup,
      expression: f.expression ?? null
    }),
    (setup) => new ComputedNumberField(restoreSetup(setup), setup.expression ?? undefined)
  )
  .register(
    "computedString",
    (f: ComputedStringField) => ({
      ...f.setup,
      expression: f.expression ?? null
    }),
    (setup) => new ComputedStringField(restoreSetup(setup), setup.expression ?? undefined)
  )
  .register(
    "computedDatetime",
    (f: ComputedDatetimeField) => ({
      ...f.setup,
      expression: f.expression ?? null
    }),
    (setup) => new ComputedDatetimeField(restoreSetup(setup), setup.expression ?? undefined)
  )
  .register(
    "record",
    (field: RecordField) => ({ ...field.setup, collectionId: field.collectionId }),
    (data) => {
      if (data.collectionId) {
        return new RecordField(restoreSetup(data), data.collectionId);
      }
      throw new Error("Entity slug not found when deserialising RecordField");
    }
  )
  .register(
    "person",
    (field: PersonField) => field.setup,
    (data) => new PersonField(restoreSetup(data))
  )
  .register(
    "people",
    (field: PeopleField) => field.setup,
    (data) => new PeopleField(restoreSetup(data))
  )
  .register(
    "multiRecord",
    (field: MultiRecordField) => ({ ...field.setup, collectionId: field.collectionId }),
    (data) => {
      if (data.collectionId) return new MultiRecordField(restoreSetup(data), data.collectionId);
      throw new Error("Entity slug not found when deserialising MultiRecordField");
    }
  )
  .register(
    "stage",
    (field: StageField) => ({ ...field.setup, stages: field.stages }),
    (data) => {
      if (!data.stages) {
        console.warn("Stage data not found during deserialisation, using empty list instead.");
      }
      return new StageField(restoreSetup(data), data.stages ?? []);
    }
  )
  .register(
    "aggregation",
    (field: AggregationField) => ({ ...field.aggregationSetup }),
    (data) => {
      if (!data.mode) throw new Error("Aggregation mode not found when deserialising AggregationField");
      else if (!data.link) throw new Error("Link not found when deserialising AggregationField");
      return new AggregationField(restoreSetup(data) as AggregationFieldSetup);
    }
  )
  .register(
    "lookup",
    (field: LookupField) => ({
      ...field.lookupConfig,
      targetFieldId: field.targetField?.id
    }),
    (data) =>
      new LookupField({
        ...restoreSetup(omitFrom(data, "targetFieldId"))
      })
  );

export type RegisteredField = RegistryType<typeof fieldRegistry>;
// uncomment below line to check type of fieldRegistry is correct
// "other" satisfies RegisteredField['type']
