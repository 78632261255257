<template>
  <router-link v-if="badge.to" :to="badge.to" class="person-badge" :class="cssClass" :title="badge.fullName">
    <img v-if="badge.image" :src="badge.image" :alt="badge.fullName" class="person-badge-image" />
    <span v-else>{{ initials }}</span>
  </router-link>
  <div v-else class="person-badge" :class="cssClass" :title="badge.fullName">
    <img v-if="badge.image" :src="badge.image" :alt="badge.fullName" class="person-badge-image" />
    <span v-else>{{ initials }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { RouterLink } from "vue-router";

import type { PersonBadge } from "@data/fields/TableCell";

const props = defineProps<{
  /** Person badge to show */
  badge: PersonBadge;
  /** Size of the badge */
  size?: "xs" | "sm" | "md";
}>();

const initials = computed(() =>
  props.badge.fullName
    .split(" ")
    .map((word) => word[0])
    .join("")
    .substring(0, 2)
);

const colours = ["blue", "indigo", "purple", "pink", "red", "orange", "yellow", "green", "teal", "cyan"];
const colour = computed(() => {
  if (props.badge.deactivated) return "gray";
  let sum = 0;
  for (let i = 0; i < props.badge.fullName.length; i++) {
    sum += props.badge.fullName.charCodeAt(i);
  }

  return colours[sum % colours.length];
});

const cssClass = computed(() => ({
  "bg-l-20-gray": !!props.badge.image,
  [`bg-l-20-${colour.value}`]: !props.badge.image,
  [`text-d-15-${colour.value}`]: !props.badge.image,
  [`person-badge-${props.size}`]: !!props.size
}));
</script>

<style scoped lang="scss">
@mixin person-badge-size($size, $font-size, $line-height) {
  width: $size;
  height: $size;
  font-size: $font-size;
  line-height: $line-height;

  .person-badge-image {
    width: ($size - 2px);
    height: ($size - 2px);
  }
}
.person-badge {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 30px;
  border-radius: 50%;
  background-color: var(--k-color-primary);
  color: var(--k-background);
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  font-weight: 500;
  cursor: default;
  overflow: hidden;
  position: relative;

  .person-badge-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: block;
  }

  &.person-badge-sm {
    @include person-badge-size(26px, 11px, 24px);
  }

  &.person-badge-xs {
    @include person-badge-size(22px, 10px, 20px);
  }
}
</style>
