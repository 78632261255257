<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div v-if="showGroup" class="navbar-group mb-2">
    <div class="navbar-group-header ms-1 ps-2 pe-2" @click.right="($evt) => showMenu($evt, false)">
      <div v-if="mode == 'EDITING'" class="group-drag-handle"><i class="far fa-grip-dots-vertical" /></div>
      <div v-else class="hover-buttons">
        <k-button v-if="addButtonLabel && canCustomise" class="group-header-button" variant="transparent" icon="plus" :title="addButtonLabel" @click="onAdd" />
        <k-button v-if="canCustomise" class="group-header-button" variant="transparent" icon="pencil" title="Edit" @click="toggleEditing" />
        <k-button
          class="group-header-button"
          variant="transparent"
          :title="isExpanded ? 'Collapse' : 'Expand'"
          :icon="isExpanded ? 'chevron-down' : 'chevron-right'"
          @click="isExpanded = !isExpanded" />
      </div>

      <k-button
        v-if="mode == 'EDITING'"
        title="Options"
        class="float-end px-1 group-header-button"
        variant="transparent"
        icon="ellipsis"
        @click="($evt) => showMenu($evt, true)" />

      <div v-if="mode != 'EDITING'" class="label" tabindex="0" role="button" @click="isExpanded = !isExpanded" @keypress.enter="isExpanded = !isExpanded">
        {{ group.label }}
      </div>
      <div
        v-else
        :id="`navbar-group-label-${index}`"
        ref="labelInput"
        role="textbox"
        class="label label-editable"
        contenteditable="true"
        tabindex="0"
        @focus="onLabelFocus"
        @keydown="onLabelKeyDown"
        @blur="onLabelBlur">
        {{ group.label }}
      </div>
    </div>

    <Transition>
      <div v-if="showItems || mode == 'EDITING'" class="navbar-group-items" :class="{ editing: mode == 'EDITING' }" :style="navbarItemsStyle">
        <div v-if="!hasItems && mode == 'EDITING'" class="navbar-group-items-placeholder text-secondary mx-3 p-1">Drag item here</div>
        <draggable
          v-model="items"
          :disabled="mode != 'EDITING'"
          :animation="100"
          class="navbar-group-item-list"
          ghost-class="dragging"
          :group="{ name: 'nav-items' }"
          item-key="label"
          @change="onUpdateItems">
          <template #item="{ element }">
            <k-navbar-item
              v-if="mode == 'EDITING' || !element.hidden"
              :item="element"
              :mode
              @navigated="onNavigated"
              @right-click="($evt: MouseEvent) => showItemMenu($evt, element)"
              @toggle-hidden="toggleHidden(element)" />
          </template>
        </draggable>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import draggable from "vuedraggable";

import KButton from "@ui/button/KButton.vue";
import { selectContentEditableText } from "@ui/helpers/dom/ContentEditableTextSelection";

import KNavbarItem from "./KNavbarItem.vue";

import type { NavbarState } from "./NavbarState";
import type { NavbarGroup, NavbarItem } from "./NavbarStructure";

const props = defineProps<{
  /** Navbar group */
  group: NavbarGroup;
  /** Position of the group */
  index: number;
  /** Label for the add button */
  addButtonLabel?: string;
  /** Whether the navbar can be customised */
  canCustomise?: boolean;
}>();

const emit = defineEmits<{
  (event: "add", group: NavbarGroup): void;
  (event: "updateItems", items: NavbarItem[]): void;
  (event: "updateLabel", label: string): void;
  (event: "delete"): void;
  (event: "showMenu", evt: MouseEvent, group: NavbarGroup, buttonTrigger: boolean): void;
  (event: "showItemMenu", evt: MouseEvent, item: NavbarItem): void;
  (event: "navigated"): void;
}>();

const mode = defineModel<NavbarState>("mode", {
  required: true
});

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const items = ref(props.group.items);
watch(
  () => props.group.items,
  (value) => (items.value = value)
);

const isExpanded = ref(true);
const hasItems = computed(() => items.value.length > 0);
const totalVisibleItems = computed(() => items.value.filter((x) => !x.hidden).length);
const showItems = computed(() => (isExpanded.value || mode.value === "EDITING") && hasItems.value);
const showGroup = computed(() => totalVisibleItems.value > 0 || mode.value === "EDITING");

const toggleEditing = () => {
  mode.value = mode.value === "EDITING" ? "DEFAULT" : "EDITING";
};

const onAdd = () => {
  emit("add", props.group);
};

const onUpdateItems = () => {
  emit("updateItems", items.value);
};

const labelInput = ref<HTMLDivElement | null>(null);
const onLabelFocus = () => {
  if (!labelInput.value) return;
  selectContentEditableText(labelInput.value);
};

const onLabelBlur = () => {
  if (!labelInput.value) return;

  // Update label
  const label = labelInput.value.innerText;
  emit("updateLabel", label);

  // Clear selection
  const selection = window.getSelection();
  if (!selection) return;
  selection.removeAllRanges();
};

const onLabelKeyDown = (event: KeyboardEvent) => {
  const label = labelInput.value?.innerText.toLowerCase();

  // If user hasn't changed the label, and presses escape, delete the group
  if (event.key === "Escape" && (!label?.trim() || label === "new group") && items.value.length === 0) {
    event.preventDefault();
    labelInput.value?.blur();
    emit("delete");
    return;
  }

  // If user presses enter or escape, blur the input
  if (event.key === "Enter" || event.key === "Escape") {
    event.preventDefault();
    labelInput.value?.blur();
  }
};

const navbarItemsStyle = computed(() => ({
  height: mode.value === "EDITING" ? "auto" : `${totalVisibleItems.value > 1 ? totalVisibleItems.value * 30 : 30}px`
}));

const showMenu = (evt: MouseEvent, buttonTrigger: boolean) => {
  emit("showMenu", evt, props.group, buttonTrigger);
};

const showItemMenu = (evt: MouseEvent, item: NavbarItem) => {
  emit("showItemMenu", evt, item);
};

const toggleHidden = (item: NavbarItem) => {
  item.hidden = !item.hidden;
  emit("updateItems", items.value);
};

const onNavigated = () => {
  emit("navigated");
};
</script>

<style scoped>
.navbar-group-header {
  height: 26px;
}

.navbar-group-items.editing .navbar-group-item-list {
  min-height: 30px;
}

.navbar-group-items.editing .navbar-group-items-placeholder {
  height: 32px;
  margin-bottom: -32px;
  pointer-events: none;
  line-height: 22px;
  font-style: italic;
  transition: all 0.05s ease-in-out;
}

.navbar-group-items.editing :deep(.navbar-item) {
  cursor: grab;
}

.navbar-group-item-list :deep(a:only-child) {
  margin-bottom: 0;
}

.label {
  font-size: 0.75em;
  text-transform: uppercase;
  color: var(--k-color-secondary);
  font-weight: 600;
  display: block;
  line-height: 1;
  user-select: none;
  -webkit-user-select: none;
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px 0;
}

.label-editable {
  margin-right: 35px;
  cursor: text;
}

.label:focus-visible {
  outline: 2px solid var(--k-color-l-15-primary);
  border-radius: 1px;
}

.group-header-button {
  padding: 2px 4px !important;
  height: 24px;
  line-height: 1.1;
  margin-left: 2px;
}

.hover-buttons {
  float: right;
  margin-top: 0;
}

:deep(.hover-buttons button) {
  opacity: 0;
  visibility: hidden;
}

.navbar-group:hover :deep(.hover-buttons button) {
  opacity: 1;
  visibility: visible;
}

.navbar-group-items {
  overflow: hidden;
  opacity: 1;
  width: 100%;
  margin: 0;
  padding: 0;
}

.navbar-group-item-list,
.v-enter-active,
.v-leave-active {
  transition: all 0.15s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  height: 0 !important;
  opacity: 0;
}

.v-enter-from .navbar-group-item-list,
.v-leave-to .navbar-group-item-list {
  transform: translateY(-100%);
  opacity: 0;
}

.group-drag-handle {
  float: left;
  color: var(--k-color-secondary);
  margin-top: 3px;
  margin-left: -5px;
  padding: 0 5px;
  cursor: grab;
}

.navbar-group.sortable-chosen,
.group-drag-handle:active {
  cursor: grabbing;
  opacity: 0.6;
}

.navbar-group-items:has(.navbar-group-item-list .navbar-item) .navbar-group-items-placeholder {
  opacity: 0;
}

:deep(.navbar-item.dragging) {
  transition: 0.05s all ease-in-out;
  opacity: 0.5;
  cursor: grabbing;
}
</style>
