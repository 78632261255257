<template>
  <div class="form-control-container">
    <k-label v-if="!config.hideLabel" :id="uuid" :label />
    <div class="input-group input-group-sm">
      <span :class="spanClasses">
        <k-input-config no-container hide-label>
          <k-input-number v-model="manualInput" :min="0" :max="100" class="manual-input" placeholder="--" />%</k-input-config
        >
      </span>
      <div :class="classes">
        <input
          :id="uuid"
          ref="element"
          v-model="input"
          class="form-range"
          :class="{ isUndefined: numInput === undefined || input === null }"
          type="range"
          :aria-label="label"
          v-bind="commonBindings(config, label)"
          min="0"
          max="100"
          :step="resolution"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          @change="$emit('change', $event)" />
      </div>
      <span :class="`probability-indicator input-group-text bg-l-15-${probabilityColour} text-d-20-${probabilityColour}`">
        <span>{{ probabilityLabel }}</span>
      </span>
    </div>

    <issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { v4 } from "uuid";

import KInputConfig from "../KInputConfig.vue";
import IssueDisplay from "../KIssueDisplay.vue";
import { useInputConfig, commonBindings, getInputClasses } from "../inputConfig";

import KLabel from "@ui/label/KLabel.vue";

import KInputNumber from "./KInputNumber.vue";

import type { ColourVariant } from "@data/types/ColourVariant";

const props = withDefaults(
  defineProps<{
    /** Current value of the input (0-1) */
    modelValue?: number;
    /** Label for the input */
    label?: string;
    /** Step size for the probability slider (defaults to 5) */
    resolution?: number;
    /** Whether a higher value is better for colour scale (defaults to true) */
    higherIsBetter?: boolean;
  }>(),
  {
    modelValue: undefined,
    label: undefined,
    resolution: 5,
    higherIsBetter: true
  }
);

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", value: number | undefined): void;
}>();

const config = useInputConfig();

const element = ref<HTMLInputElement>();

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const input = ref<number | string | undefined>(props.modelValue && props.modelValue * 100);
const numInput = computed(() => {
  // Some browsers output number input values as strings, so convert if valid number, otherwise return undefined
  const parsed = typeof input.value === "string" ? parseFloat(input.value) : input.value;
  return parsed !== undefined && !isNaN(parsed) ? Math.round(parsed) : undefined;
});

//for k-input-number
const manualInput = ref<number>();

watch(numInput, (value) => {
  emit("update:modelValue", value && value / 100);
});

watch(
  () => props.modelValue,
  () => {
    input.value = props.modelValue && Math.round(props.modelValue * 100);
    manualInput.value = props.modelValue && Math.round(props.modelValue * 100);
  },
  { immediate: true }
);

watch(
  () => manualInput.value,
  (value) => {
    input.value = value;
  }
);
const colourThresholds: [number, ColourVariant][] = [
  [1, "purple"],
  [25, "red"],
  [50, "orange"],
  [75, "yellow"],
  [100, "light-green"]
];

const probabilityColour = computed((): ColourVariant => {
  let val = numInput.value;
  if (!val || val < 0 || val > 100) return "gray";
  if (!props.higherIsBetter) {
    val = 100 - val;
  }
  for (const [threshold, colour] of colourThresholds) {
    if (val < threshold) {
      return colour;
    }
  }
  return "green";
});

const probabilityLabel = computed(() => {
  const val = numInput.value;
  if (val === undefined || val < 0 || val > 100) {
    return "N/A";
  } else if (val === 0) {
    return "Impossible";
  } else if (val < 25) {
    return "Very unlikely";
  } else if (val < 50) {
    return "Unlikely";
  } else if (val < 75) {
    return "Likely";
  } else if (val < 100) {
    return "Very likely";
  } else {
    return "Certain";
  }
});

const classes = computed(() => ({
  "range-wrapper": true,
  // "probability-control": true,
  // "form-range": true,
  ...getInputClasses(config.value, "control")
}));
const spanClasses = computed(() => ({
  "input-group-text": true,
  "probability-value": true,
  disabled: config.value.disabled
}));
const uuid = v4();
</script>

<style scoped lang="scss">
.range-wrapper {
  display: flex;
  align-items: center;
}

.probability-value {
  background-color: var(--k-input-background);
  border-right: 2px solid var(--k-color-l-15-secondary);
  &.disabled {
    background-color: var(--k-input-disabled-background);
  }
  > span {
    text-align: center;
  }
}

.probability-indicator {
  color: white;
  transition: all ease-in-out 0.1s;
  border: none;
  &.bg-light-green,
  &.bg-yellow {
    color: black;
  }
  > span {
    width: 80px;
    text-align: center;
  }
}

.manual-input {
  width: 3.75rem;
}
:v-deep .manual-input .form-control-sm {
  .form-control-sm {
    padding: 0.1rem 0;
  }

  .form-control.disabled {
    border: none;
  }
}
.probability-value.input-group-text {
  padding: 0.1rem 0.5rem 0.1rem 0;
}
.form-range.isUndefined {
  &::-webkit-slider-thumb {
    background-color: var(--k-color-l-15-secondary);
  }

  &::-moz-range-thumb {
    background-color: var(--k-color-l-15-secondary);
  }
}
</style>
