<template>
  <div class="navbar-header">
    <k-toolbar v-if="mode != 'EDITING'" :hide-background="true" :title="mode != 'COMPACT' ? structure.product.name : ''" :enable-transitions="false">
      <template #start>
        <div class="d-block navbar-header-logo">
          <img v-if="isDark" :alt="structure.product.name" height="30" width="30" :src="structure.product.iconDark" />
          <img v-else :alt="structure.product.name" height="30" width="30" :src="structure.product.iconLight" />
        </div>
      </template>
      <template v-if="mode != 'COMPACT'" #end>
        <div class="float-end">
          <k-button
            v-if="notificationCount !== undefined"
            title="Notifications"
            variant="transparent"
            class="position-relative notification-centre-btn"
            @click="emit('openNotifications', $event)">
            <k-notification-badge :notification-count="notificationCount" />
            <i class="far fa-fw fa-bell" />
          </k-button>
          <k-button title="Hide sidebar" icon="sidebar" variant="transparent" @click="toggleState" />
        </div>
      </template>
    </k-toolbar>
    <k-toolbar v-else-if="mode == 'EDITING'" :slot-end-width="100" title="Customise" :hide-background="true">
      <template #end>
        <k-button variant="primary" class="float-end me-1" label="Done" @click="onDone" />
        <k-button variant="transparent" class="float-end me-1" title="Undo changes" icon="undo" @click="onUndo" />
      </template>
    </k-toolbar>
    <div v-if="mode == 'EDITING'" class="px-3 mb-2 help-text"><small>Click and drag to reorder items, and add groups.</small></div>
  </div>
  <Teleport to="body">
    <Transition>
      <div v-if="mode == 'EDITING'" class="backdrop-blur"></div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import KNotificationBadge from "../notifications/KNotificationBadge.vue";

import KButton from "@ui/button/KButton.vue";
import KToolbar from "@ui/toolbar/KToolbar.vue";

import { useNavbarState } from "./NavbarState";
import { useTheme } from "./theme";

import type { NavbarState } from "./NavbarState";
import type { NavbarStructure } from "./NavbarStructure";

defineProps<{
  /** Current product */
  structure: Pick<NavbarStructure, "product">;
  /** Number of notifications */
  notificationCount?: number;
  /** Navbar mode (with EDITING option) */
  mode: NavbarState;
}>();

const emit = defineEmits<{
  (e: "done"): void;
  (e: "undo"): void;
  (e: "openNotifications", evt?: MouseEvent): void;
}>();

const onDone = () => emit("done");
const onUndo = () => emit("undo");

const { toggleState } = useNavbarState();

const { isDark } = useTheme();
</script>

<style scoped>
.navbar-header {
  cursor: default;
}

.backdrop-blur {
  position: fixed;
  top: 0;
  left: calc(var(--k-navbar-width));
  bottom: 0;
  right: 0;
  z-index: 10000;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  pointer-events: all;
  border-radius: 4px;
  opacity: 1;
  background-color: var(--k-background-translucent-50);
}

.navbar-header-logo {
  padding-left: 2px;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.1s ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

:deep(.search-box input) {
  box-shadow: var(--k-navbar-item-active-box-shadow);
  background-color: var(--k-background-translucent-50);
}

.help-text {
  line-height: 1.1;
}
</style>
