<template>
  <div :class="{ 'form-control-container': true }">
    <k-label v-if="!config.hideLabel" :id="uuid" :label />

    <div v-if="showActual">
      <div class="d-flex w-100 mb-2 time-span-container planned">
        <p class="mb-0 time-span-label text-end pe-2">Planned:</p>
        <k-input-time-span-box v-model="plannedRange" :disabled="config.disabled" :classes label="Planned start date" :precision :required="config.required" />
      </div>
      <div class="d-flex w-100 time-span-container">
        <p class="mb-0 time-span-label text-end pe-2">Actual:</p>
        <k-input-time-span-box v-model="actualRange" :disabled="config.disabled" :classes label="Actual start date" :precision :required="config.required" />
        <k-button v-if="!config.disabled" variant="transparent" class="ms-1" icon="undo" title="Remove actual times" @click="clearActual" />
      </div>
    </div>
    <div v-else>
      <k-input-time-span-box v-model="plannedRange" :disabled="config.disabled" :classes :label :precision :required="config.required" />
      <a v-if="allowActual && !config.disabled" role="button" tabindex="0" class="actual-btn" @click="showActual = true" @keydown.enter="showActual = true"
        >Add actual times</a
      >
    </div>

    <k-issue-display :issues />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { v4 } from "uuid";
import dayjs from "dayjs";

import { useInputClasses, useInputConfig } from "../inputConfig";
import KIssueDisplay from "../KIssueDisplay.vue";

import KButton from "@ui/button/KButton.vue";
import KLabel from "@ui/label/KLabel.vue";

import KInputTimeSpanBox from "./KInputTimeSpanBox.vue";

import type { TimeSpanField, TimeSpanValue } from "@data/fields/complex/TimeSpanField";
import { deepEquals } from "@data/helpers/manipulation/Comparison";

import type { Dayjs } from "dayjs";
import type { InputStatus } from "../inputConfig";

const props = defineProps<{
  /** Label for the input */
  label?: string;
  /** Value of the input */
  modelValue?: TimeSpanValue;
  /** Precision of the input */
  precision?: TimeSpanField["precision"];
  /** Whether to allow the actual timespan to be entered */
  allowActual?: boolean;
}>();

const emit = defineEmits<(event: "update:modelValue", newValue?: TimeSpanValue) => void>();

interface Range {
  start?: Dayjs;
  end?: Dayjs;
}

const plannedRange = ref<Range>();

const actualRange = ref<Range>();

const uuid = v4();

const config = useInputConfig(undefined, true);

const classes = useInputClasses(config);

const showActual = ref(false);

const clearActual = () => {
  actualRange.value = {
    start: undefined,
    end: undefined
  };
  showActual.value = false;
};

const startStatus = computed<InputStatus>(() => {
  if (plannedRange.value?.end && !plannedRange.value.start) {
    return "INVALID";
  }
  return config.value.status;
});

const endStatus = computed<InputStatus>(() => {
  if (plannedRange.value?.start && !plannedRange.value.end?.isSameOrAfter(plannedRange.value.start)) {
    return "INVALID";
  }
  return config.value.status;
});

const actualStartStatus = computed<InputStatus>(() => {
  if (actualRange.value?.end && !actualRange.value.start) {
    return "INVALID";
  }
  return config.value.status;
});

const actualEndStatus = computed<InputStatus>(() => {
  if (actualRange.value?.start && !actualRange.value.end?.isSameOrAfter(actualRange.value.start)) {
    return "INVALID";
  }
  return config.value.status;
});

const issues = computed(() => {
  const result = [...config.value.issues];
  if (startStatus.value === "INVALID") {
    result.push({
      severity: "error",
      message: "Start and end must be set"
    });
  }
  if (endStatus.value === "INVALID") {
    result.push({
      severity: "error",
      message: "End must be after start"
    });
  }
  if (actualStartStatus.value === "INVALID") {
    result.push({
      severity: "error",
      message: "Actual start and end must be set"
    });
  }
  if (actualEndStatus.value === "INVALID") {
    result.push({
      severity: "error",
      message: "Actual end must be after actual start"
    });
  }
  return result;
});

watch(
  () => props.modelValue,
  (value) => {
    plannedRange.value = {
      start: value?.start,
      end: value?.end
    };

    if (value && "actualStart" in value) {
      actualRange.value = {
        start: value.actualStart,
        end: value.actualEnd
      };
      showActual.value = !!(value.actualEnd || value.actualStart);
    } else {
      actualRange.value = {
        start: undefined,
        end: undefined
      };
      showActual.value = false;
    }
  },
  { immediate: true }
);

const result = computed<TimeSpanValue | undefined>(() => {
  if (plannedRange.value?.start || plannedRange.value?.end) {
    if (actualRange.value?.start || actualRange.value?.end) {
      return {
        start: plannedRange.value.start ? dayjs(plannedRange.value.start) : undefined,
        end: plannedRange.value.end ? dayjs(plannedRange.value.end) : undefined,
        actualStart: actualRange.value.start ? dayjs(actualRange.value.start) : undefined,
        actualEnd: actualRange.value.end ? dayjs(actualRange.value.end) : undefined
      } satisfies TimeSpanValue;
    }
    return {
      start: plannedRange.value.start ? dayjs(plannedRange.value.start) : undefined,
      end: plannedRange.value.end ? dayjs(plannedRange.value.end) : undefined
    } satisfies TimeSpanValue;
  }
  return undefined;
});

watch(
  () => result.value,
  (value) => {
    if (!deepEquals(value, props.modelValue)) {
      emit("update:modelValue", value);
    }
  }
);
</script>

<style scoped>
.actual-btn {
  font-size: 0.75rem;
  margin-top: 4px;
  display: block;
  text-decoration: none;
  user-select: none;
}

.range-input {
  background-color: transparent;
  border: none;
  text-align: center;
  border-radius: 4px;
}

.time-span-label {
  width: 75px;
  flex-shrink: 0;
  font-size: 0.9em;
  line-height: 30px;
}

.time-span-container.planned :deep(.timespan-input-control) {
  margin-right: 38px;
}
</style>
