<template>
  <div class="form-control-container position-relative">
    <k-label v-if="!config.hideLabel" :id="uuid" :label />

    <k-button
      v-if="!config.disabled && allowMoreFiles && acceptsImages && !cameraActive"
      variant="secondary"
      size="sm"
      icon="camera"
      tabindex="-1"
      title="Use Camera"
      class="camera-button"
      @click="cameraActive = true" />

    <!--eslint-disable-next-line vuejs-accessibility/no-static-element-interactions-->
    <div
      :class="classes"
      :readonly="config.disabled"
      :data-active="dropZoneActive"
      @dragenter.prevent="setActive"
      @dragover.prevent="setActive"
      @dragleave.prevent="setInactive"
      @drop.prevent="onDrop">
      <input
        v-if="!config.disabled"
        :id="uuid"
        class="file-input"
        type="file"
        :accept
        :multiple
        data-testid="file-input"
        :disabled="!allowMoreFiles"
        @change="onInputChange"
        @focus="onFocus"
        @blur="onBlur" />
      <label v-if="!config.disabled && allowMoreFiles" :for="uuid" class="file-placeholder">
        <span v-if="dropZoneActive">Drop files here to add them</span>
        <span v-else-if="cameraActive" class="text-info"><i class="far fa-camera me-2"></i>Use your camera to take a photo</span>
        <span v-else>Drag files here, or click here to select files</span>
      </label>
      <ul v-show="fileList.length" class="file-list">
        <li v-for="file of files" :key="file.id" :file tag="li" :title="file.description">
          <div class="file-item-content">
            <k-file-icon :extension="file.extension" class="float-start me-2" />
            <span>{{ file.description }}</span>
            <div v-if="file.uploadProgress > 0" class="progress-indicator" :style="{ width: `${file.uploadProgress}%` }"></div>
            <div v-if="file.status == 'UPLOADED'" title="Uploaded" class="progress-indicator bg-success" :style="{ width: `100%` }"></div>
            <div v-if="file.status == 'ERROR'" title="Failed to upload" class="progress-indicator bg-danger" :style="{ width: `100%` }"></div>
          </div>
          <k-dropdown variant="transparent" icon="ellipsis" no-caret class="mt-n1 p-0">
            <k-dropdown-item label="View" icon="download" @click="onDownloadFile(file)" />
            <k-dropdown-item v-if="!config.disabled" label="Delete" icon="trash" @click="onRemoveFile(file)" />
          </k-dropdown>
        </li>
      </ul>
    </div>

    <k-camera v-if="acceptsImages" v-model:active="cameraActive" @photo="(photo) => tryAddFiles([photo])" />
    <issue-display :issues="config.issues.concat(fileIssues)" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch, computed } from "vue";

import { v4 } from "uuid";

import IssueDisplay from "@ui/inputs/KIssueDisplay.vue";
import { getInputClasses, useInputConfig } from "@ui/inputs/inputConfig";
import KButton from "@ui/button/KButton.vue";
import KDropdown from "@ui/dropdown/KDropdown.vue";
import KDropdownItem from "@ui/dropdown/KDropdownItem.vue";
import KLabel from "@ui/label/KLabel.vue";
import KFileIcon from "@ui/files/KFileIcon.vue";

import { useFileList } from "./FileUpload";
import KCamera from "./KCamera.vue";

import { hasErrors } from "@data/validation/Validation";
import { isFileAccepted } from "@data/helpers/files/Acceptance";

import type { FileUpload } from "./FileUpload";

const props = defineProps<{
  /** Value of the input */
  modelValue: FileUpload[];
  /** Label for the input */
  label?: string;
  /** Whether to allow multiple files */
  multiple?: boolean;
  /** Maximum size of input files (in bytes) */
  maxSize?: number;
  /** File types to accept (MIME format) */
  accept?: string;
}>();

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", value: FileUpload[]): void;
}>();

const config = useInputConfig();

const {
  files: fileList,
  addFiles,
  removeFile,
  issues: fileIssues
  // eslint-disable-next-line vue/no-setup-props-reactivity-loss
} = useFileList(
  props.modelValue,
  computed(() => props.maxSize)
);

const validate = () => (config.value.required && fileList.value.length > 0) || !config.value.required;

const files = computed(() => fileList.value.filter((f) => f.status !== "TO_DELETE" && f.status !== "DELETED"));
const allowMoreFiles = computed(() => {
  if (config.value.disabled || config.value.readonly) {
    return false;
  }
  return props.multiple || !files.value.length;
});

const tryAddFiles = async (filesToAdd: Parameters<typeof addFiles>[0]) => {
  // filter out files with incorrect MIME type
  if (props.accept) {
    filesToAdd = Array.from(filesToAdd).filter((file) => isFileAccepted(props.accept, file));
  }
  if (allowMoreFiles.value) {
    await addFiles(filesToAdd);
  }
};

const onInputChange = async (e: Event) => {
  const inputFiles = (e.target as HTMLInputElement).files;
  if (!inputFiles) {
    return;
  }
  await tryAddFiles(inputFiles);
  (e.target as HTMLInputElement).value = "";
};

watch(
  () => props.modelValue,
  () => {
    fileList.value = props.modelValue;
  }
);

watch(fileList, () => {
  emit("update:modelValue", fileList.value);
});

const dropZoneActive = ref(false);
let inActiveTimeout: ReturnType<typeof setTimeout>;

// setActive and setInactive use timeouts, so that when you drag an item over a child element,
// the dragleave event that is fired won't cause a flicker. A few ms should be plenty of
// time to wait for the next dragenter event to clear the timeout and set it back to active.
const setActive = (evt: DragEvent) => {
  // don't allow dragging files if all files are unacceptable
  if (evt.dataTransfer?.files && Array.from(evt.dataTransfer.files).every((file) => !isFileAccepted(props.accept, file))) {
    return;
  }
  dropZoneActive.value = true;
  clearTimeout(inActiveTimeout);
};

const setInactive = () => {
  inActiveTimeout = setTimeout(() => {
    dropZoneActive.value = false;
  }, 50);
};

const onDrop = async (e: DragEvent) => {
  if (e.dataTransfer?.files) {
    setInactive();
    await tryAddFiles(e.dataTransfer.files);
  }
};

const events = ["dragenter", "dragover", "dragleave", "drop"];
const preventDefault = (e: Event) => e.preventDefault();
const uuid = v4();

onMounted(() => {
  for (const eventName of events) {
    document.body.addEventListener(eventName, preventDefault);
  }
});

onUnmounted(() => {
  for (const eventName of events) {
    document.body.removeEventListener(eventName, preventDefault);
  }
});

const isFocused = ref(false);
const onFocus = () => {
  emit("focus");
  isFocused.value = true;
};

const onBlur = () => {
  emit("blur");
  isFocused.value = false;
};

const onRemoveFile = (file: FileUpload) => {
  if (file.status === "NOT_UPLOADED" || file.status === "ERROR") {
    removeFile(file);
  } else {
    const index = fileList.value.findIndex((f) => f.id === file.id && f.fileName === file.fileName);
    if (index < 0) return;
    fileList.value[index].status = "TO_DELETE";
  }
};

const onDownloadFile = (file: FileUpload) => {
  if (file.file) {
    window.open(window.URL.createObjectURL(file.file), "_blank");
  } else if (file.id) {
    window.open(file.url, "_blank");
  }
};

const acceptsImages = computed(() => !props.accept || !!props.accept.startsWith("image/"));

const cameraActive = ref(false);

const classes = computed(() => ({
  ...getInputClasses(config.value),
  "is-invalid": hasErrors(fileIssues.value) || config.value.status === "INVALID"
}));

defineExpose({
  validate
});
</script>

<style scoped lang="scss">
.form-control .file-placeholder {
  color: var(--k-placeholder-color);
  padding: 0.1rem 0;
  cursor: pointer;
}

.form-control .file-input {
  float: right;
  width: 0;
  opacity: 0;
  height: 0;
}

.form-control .file-list {
  margin: 0.1rem -0.25rem -0.2rem;
  padding: 0;
  list-style: none;
  & > li {
    display: inline-block;
    width: calc(33.3% - 0.3rem);
    margin-right: 0.4rem;
    position: relative;
    &:nth-child(3n) {
      margin-right: 0rem;
    }
  }
  .file-item-content {
    background: var(--k-background);
    border-radius: 6px;
    border: 1px solid var(--k-border);
    overflow: hidden;
    position: relative;
    height: 2rem;
    width: 100%;
    padding: 0.25rem;
    span {
      display: block;
      height: 100%;
      line-height: 22px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    i {
      line-height: 22px;
    }
  }
  .progress-indicator {
    height: 2px;
    position: absolute;
    bottom: 0;
    border-radius: 1px;
    transition: all ease-out 0.1s;
    left: 0;
    background: var(--k-color-primary);
    background: linear-gradient(270deg, var(--k-color-primary), transparent);
    &.bg-danger {
      background: var(--k-color-danger);
    }
    &.bg-success {
      background: var(--k-color-success);
    }
  }
}
.form-control .file-list > li > :deep(.dropdown) {
  float: right;
  position: absolute;
  right: 1px;
  top: 5px;
}

.form-control .file-list > li .dropdown {
  & > :deep(button) {
    background: var(--k-background);
    opacity: 0;
    padding: 0.1rem 0.25rem;
    height: 28px;
    line-height: 28px;
    transition: opacity 0.1s ease-in-out;
  }
  & > :deep(button:active) {
    background: var(--k-border);
  }
  & > :deep(button),
  & > :deep(button:focus) {
    opacity: 1;
  }
}

.camera-button {
  position: absolute;
  z-index: 10;
  right: 0;
  background: rgba(0, 0, 0, 0.025);
}
</style>
