<template>
  <label class="checkbox flex-shrink-0 position-relative" :class="{ interactable: canInteract }">
    <div :title="status === 'IN_PROGRESS' ? 'In progress' : isChecked ? 'Done' : 'Not started'">
      <input
        :disabled="!canInteract"
        :checked="isChecked"
        :indeterminate.prop="status == 'IN_PROGRESS'"
        type="checkbox"
        class="checkbox-cb"
        tabindex="0"
        @change="onInput" />
      <transition name="checkbox-sparkle">
        <span v-if="isChecked" class="checkbox-sparkle">
          <span v-for="i in 6" :key="i" class="checkbox-check-sparkle" :style="`transform: rotate( ${(i * 360) / 12}deg)`"></span>
        </span>
      </transition>
      <span class="checkbox-mark">
        <span v-if="status !== 'IN_PROGRESS'" class="checkbox-check checkbox-check-hover"></span>
        <span v-if="isChecked" class="checkbox-check"></span>
        <span v-else-if="status === 'IN_PROGRESS'" class="checkbox-started"></span>
      </span>
    </div>
  </label>
</template>

<script setup lang="ts">
import { computed } from "vue";

import type { TaskStatus } from "@data/data/Task";

const props = defineProps<{
  /** Whether the user can interact with the checkbox */
  canInteract?: boolean;
  /** Current status of the task */
  status: TaskStatus;
}>();

const emit = defineEmits<{
  /** Emitted when the checkbox is toggled */
  (event: "toggled", targetStatus: TaskStatus): void;
}>();

const isChecked = computed(() => props.status === "COMPLETED");

const onInput = () => {
  emit("toggled", isChecked.value ? "NOT_STARTED" : "COMPLETED");
};
</script>

<style scoped lang="scss">
.checkbox {
  width: 30px;
}

label.checkbox {
  width: 25px;
  height: 25px;
  display: inline-block;
  > div {
    transform: scale(1.15);
    width: 20px;
  }
}

.checkbox-cb {
  display: none;
}

.checkbox-mark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.1rem;
  width: 18px;
  height: 18px;
  border: 2px solid var(--k-color-l-05-secondary);
  border-radius: 12px;
  transition: all ease-in-out 0.05s;
  z-index: 1;
}

.checkbox.interactable:active .checkbox-mark,
.interactable .checkbox-mark:active {
  transform: scale(0.9);
}

.checkbox-check {
  position: absolute;
  top: 3px;
  left: 2px;
  height: 6px;
  width: 10px;
  border: solid var(--k-background);
  border-width: 0 0 2px 2px;
  transform: rotate(-45deg) scale(1);
}

.checkbox-started {
  position: absolute;
  top: 1px;
  left: 7px;
  height: 12px;
  width: 6px;
  background-color: var(--k-background);
  border-radius: 0 50px 50px 0;
}

.checkbox-cb:checked ~ .checkbox-mark {
  border-color: var(--k-color-success);
  background: var(--k-color-success);
}

.checkbox-cb:indeterminate ~ .checkbox-mark {
  border-color: var(--k-color-yellow);
  background: var(--k-color-yellow);
}

.checkbox-cb:checked ~ .checkbox-mark:before,
.checkbox-cb:indeterminate ~ .checkbox-mark:before {
  display: block;
}

.checkbox-sparkle {
  position: absolute;
  display: block;
  overflow: visible;
  height: 24px;
  margin-top: -3px;
  width: 16px;
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}

.checkbox-check-sparkle {
  position: absolute;
  width: 1px;
  height: 30px;
  top: 0;
  left: 50%;
  background: linear-gradient(
    0deg,
    transparent 0%,
    var(--k-color-l-10-green) 10%,
    transparent 20%,
    transparent 80%,
    var(--k-color-l-10-green) 90%,
    transparent 100%
  );
}

.checkbox-sparkle-enter-active {
  transition: all 0.3s ease-out;
  opacity: 1;
}

.checkbox-sparkle-enter-from {
  transform: scale(1);
}

.checkbox-sparkle-enter-to {
  transform: scale(1.5);
  opacity: 0;
}

.checkbox-check-hover {
  opacity: 0;
  border: solid var(--k-color-l-05-secondary);
  border-width: 0 0 2px 2px;
  transition: opacity 0.05s ease-in-out;
}

.checkbox.interactable:hover .checkbox-check-hover {
  opacity: 0.5;
}
</style>
