/* eslint-disable max-lines */
import { formatReasonableNumber } from "../helpers/strings/Precision";

import { SIPrefixes, SIPrefixNames, formatUnitPowers } from "./formatting";

import type { Dimensions } from "./dimensions";

const pmod = (n: number, m: number) => ((n % m) + m) % m;

const zeroOnly = /^[.0]+$/;
const formatDualUnit = (value: number, mainUnit: string, subsPerMain: number, subUnit: string): string => {
  const mainValue = Math.floor(value);
  const subValue = formatReasonableNumber((value * subsPerMain) % subsPerMain);
  if (zeroOnly.test(subValue)) {
    return `${mainValue} ${mainUnit}`;
  }
  return `${mainValue} ${mainUnit} ${subValue} ${subUnit}`;
};

/** Rounds numbers in the 0-1000 range to the given number of significant figures */
const sigFigs = (value: number, digits: number): string => {
  const absValue = Math.abs(value);
  if (absValue >= 100) return value.toFixed(digits - 3);
  if (absValue >= 10) return value.toFixed(digits - 2);
  if (absValue >= 1) return value.toFixed(digits - 1);
  return value.toFixed(digits);
};

/** Return the appropriate SI magnitude for a value (e.g. 10000 would return 3) */
export const getSIMagnitude = (value: number): keyof typeof SIPrefixes => {
  const magnitude = Math.floor(Math.log10(Math.abs(value)));
  if (magnitude < -24) {
    return -24;
  }
  if (magnitude > 24) {
    return 24;
  }
  return (magnitude - pmod(magnitude, 3)) as keyof typeof SIPrefixes;
};

type UnitConfig = {
  symbol: string;
  expressionSymbol?: string;
  name: string;
  description?: string;
  dimensions: Dimensions;
  conversion?: number; // multiply by this
  offset?: number; // and then add this to get to SI base unit
  subUnit?: MetricUnit;
  superUnit?: MetricUnit;
  fixedPlaces?: number; // fixed number of decimal places to use (0 for integer)
  si?: boolean; // whether SI prefixes are appropriate (e.g. kilomiles do not make sense)
  prefix?: boolean; // whether to put the unit before the number (e.g. money)
  space?: boolean; // whether to put a space between the number and the unit
  removable?: boolean; // whether the unit can be removed when combined with others
};

// map to prevent units having circular references
const unitMap = new Map<string, MetricUnit>();

export class MetricUnit {
  readonly symbol: string;

  /** Symbol without any difficult-to-type unicode for expression parsing (i.e. £ is ok, but Ω is not) */
  readonly expressionSymbol: string;

  readonly name: string;

  readonly dimensions: Dimensions;

  readonly conversion: number;

  readonly offset: number;

  private subUnitKey?: string;

  private superUnitKey?: string;

  get subUnit(): MetricUnit | undefined {
    return this.subUnitKey ? unitMap.get(this.subUnitKey) : undefined;
  }

  set subUnit(subUnit: MetricUnit | undefined) {
    this.subUnitKey = subUnit?.expressionSymbol;
    if (subUnit) {
      unitMap.set(subUnit.expressionSymbol, subUnit);
    }
  }

  get superUnit(): MetricUnit | undefined {
    return this.superUnitKey ? unitMap.get(this.superUnitKey) : undefined;
  }

  set superUnit(superUnit: MetricUnit | undefined) {
    this.superUnitKey = superUnit?.expressionSymbol;
    if (superUnit) {
      unitMap.set(superUnit.expressionSymbol, superUnit);
    }
  }

  get unitFamily(): MetricUnit[] {
    const units: MetricUnit[] = [this];
    for (let unit = this.subUnit; unit; unit = unit.subUnit) {
      units.push(unit);
    }
    for (let unit = this.superUnit; unit; unit = unit.superUnit) {
      units.push(unit);
    }
    return units;
  }

  readonly fixedPlaces?: number;

  readonly si: boolean;

  readonly prefix: boolean;

  readonly space: boolean;

  readonly removable: boolean;

  constructor(config: UnitConfig) {
    this.symbol = config.symbol;
    this.expressionSymbol = config.expressionSymbol ?? config.symbol;
    this.name = config.name;
    this.dimensions = config.dimensions;
    this.conversion = config.conversion ?? 1;
    this.offset = config.offset ?? 0;
    this.fixedPlaces = config.fixedPlaces;
    this.subUnit = config.subUnit;
    this.superUnit = config.superUnit;
    this.si = config.si ?? false;
    this.prefix = config.prefix ?? false;
    this.space = config.space ?? !this.prefix;
    this.removable = config.removable ?? false;
    //recursively generate SI sub/super units
    if (config.si) {
      this.generateSIUnits();
    }
    // set up circular references between sub/super units
    if (config.subUnit && !config.subUnit.superUnit) {
      config.subUnit.superUnit = this;
    }
    if (config.superUnit && !config.superUnit.subUnit) {
      config.superUnit.subUnit = this;
    }
  }

  private generateSIUnits(): void {
    const magnitude = getSIMagnitude(this.conversion);
    const originalSymbol = magnitude === 0 ? this.symbol : this.symbol.slice(1);
    const originalName = magnitude === 0 ? this.name : this.name.slice(SIPrefixNames[magnitude].length);
    if (!this.subUnit && this.fixedPlaces === undefined && magnitude !== -24) {
      const prefix = SIPrefixes[(magnitude - 3) as keyof typeof SIPrefixes];
      this.subUnit = new MetricUnit({
        symbol: (Array.isArray(prefix) ? prefix[0] : prefix) + originalSymbol,
        expressionSymbol: Array.isArray(prefix) ? prefix[1] + originalSymbol : undefined,
        name: SIPrefixNames[(magnitude - 3) as keyof typeof SIPrefixes] + originalName,
        conversion: 1e-3 * this.conversion,
        dimensions: this.dimensions,
        si: true,
        superUnit: this
      });
    }

    if (!this.superUnit && magnitude !== 24) {
      const prefix = SIPrefixes[(magnitude + 3) as keyof typeof SIPrefixes];
      this.superUnit = new MetricUnit({
        symbol: (Array.isArray(prefix) ? prefix[0] : prefix) + originalSymbol,
        expressionSymbol: Array.isArray(prefix) ? prefix[1] + originalSymbol : undefined,
        name: SIPrefixNames[(magnitude + 3) as keyof typeof SIPrefixes] + originalName,
        conversion: 1000 * this.conversion,
        dimensions: this.dimensions,
        si: true,
        subUnit: this
      });
    }
  }

  addSuperUnit(config: Omit<UnitConfig, "dimensions">, per?: number) {
    if (per) {
      config.conversion = this.conversion * per;
    }
    const superUnit = new MetricUnit({ ...config, dimensions: this.dimensions, subUnit: this });
    return superUnit;
  }

  addSubUnit(config: Omit<UnitConfig, "dimensions">, per?: number) {
    if (per) {
      config.conversion = this.conversion / per;
    }
    const subUnit = new MetricUnit({ ...config, dimensions: this.dimensions, superUnit: this });
    return subUnit;
  }

  /** Converts a value from the SI base unit to this unit */
  convertTo(value: number): number {
    return (value - this.offset) / this.conversion;
  }

  /** Converts a value from this unit to the SI base unit */
  convertFrom(value: number): number {
    return value * this.conversion + this.offset;
  }

  private formatWithSymbol = (stringValue: string): string => {
    if (this.prefix) {
      if (stringValue.startsWith("-")) {
        // put - sign before prefix
        return `-${this.symbol}${stringValue.slice(1)}${this.space ? " " : ""}`;
      }
      return `${this.symbol}${stringValue}${this.space ? " " : ""}`;
    }
    return `${stringValue}${this.space ? " " : ""}${this.symbol}`;
  };

  getAppropriateUnit(value: number): MetricUnit {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let actualUnit: MetricUnit = this;
    let wentDown = false;
    let absValue = Math.abs(value);
    // convert to smaller unit while appropriate
    for (let subUnit = actualUnit.subUnit; subUnit && absValue < 1; subUnit = subUnit.subUnit) {
      const per = actualUnit.conversion / subUnit.conversion;
      absValue *= per;
      actualUnit = subUnit;
      wentDown = true;
    }
    if (wentDown) {
      return actualUnit;
    }
    // convert to larger unit while appropriate
    for (let superUnit = actualUnit.superUnit; superUnit; superUnit = superUnit.superUnit) {
      const per = superUnit.conversion / actualUnit.conversion;
      if (absValue < per) break;
      absValue /= per;
      actualUnit = superUnit;
    }
    return actualUnit;
  }

  display(value: number, config?: { precision?: number; convert?: boolean; lockUnit?: boolean }): string {
    if (config?.convert) {
      value = this.convertTo(value);
    }
    const precision = config?.precision ?? 3;

    // put colossal values into scientific notation
    const magnitude = Math.floor(Math.log10(Math.abs(value)));
    if (magnitude > 24 || magnitude < -24) {
      return this.formatWithSymbol(value.toPrecision(precision));
    }
    const actualUnit = config?.lockUnit ? this : this.getAppropriateUnit(value);
    if (actualUnit !== this) {
      value = actualUnit.convertTo(this.convertFrom(value));
    }

    // if unit has non-SI subunit, format as dual unit (e.g. hours and minutes)
    // but only if the subunit is relatively large (e.g. 1 hr 30 min, not 1 pt 12 ml)
    const per = actualUnit.conversion / (actualUnit.subUnit?.conversion ?? 1);
    if (actualUnit.subUnit && per < 100) {
      return formatDualUnit(value, actualUnit.symbol, per, actualUnit.subUnit.symbol);
    }
    const stringValue = actualUnit.fixedPlaces === undefined ? sigFigs(value, precision) : value.toFixed(actualUnit.fixedPlaces);
    return actualUnit.formatWithSymbol(stringValue);
  }
}

/**
 * A unit that is a combination of multiple units, e.g. m/s
 *
 * Generally, you should use combineUnits unless you're sure you're not combining combined units (since they can cancel out)
 */
export class CombinedUnit extends MetricUnit {
  constructor(public readonly units: { unit: MetricUnit; power: number }[]) {
    if (units.some(({ unit }) => unit instanceof CombinedUnit)) {
      throw new Error("Cannot combine combined units - use combineUnits instead!");
    }
    const dimensions: Dimensions = {};
    let conversion = 1;
    for (const { unit, power } of units) {
      for (const d in unit.dimensions) {
        const dimension = d as keyof Dimensions;
        const newPower = (dimensions[dimension] ?? 0) + unit.dimensions[dimension]! * power;
        if (newPower === 0) {
          delete dimensions[dimension];
        } else {
          dimensions[dimension] = newPower;
        }
      }
      conversion *= unit.conversion ** power;
    }

    super({
      symbol: formatUnitPowers(
        units.map(({ unit, power }) => ({ symbol: unit.symbol, power })),
        "unicode"
      ),
      expressionSymbol: formatUnitPowers(
        units.map(({ unit, power }) => ({
          symbol: unit.expressionSymbol,
          power
        })),
        "expression"
      ),
      name: "combined unit",
      dimensions,
      conversion
    });
  }
}
