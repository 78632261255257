<template>
  <div :class="`alert alert-${variant} ${show ? 'active' : ''} ${type}`">
    <div class="d-flex">
      <div v-if="icon" class="ms-n1">
        <i :class="`fa fa-${icon} me-2`" />
      </div>
      <div>
        <k-button v-if="showClose" class="close-btn ms-2" variant="transparent" icon="close" @click="emit('close')" />
        <router-link v-if="to && title" class="alert-title" :to>{{ title }}</router-link>
        <div v-else-if="title" class="alert-title pe-3">{{ title }}</div>
        <div v-if="content" class="alert-content">{{ content }}</div>
        <slot></slot>
        <div v-if="actions && actions.length > 0" class="pt-1 mb-n1">
          <button
            v-for="action in actions"
            :key="action.label"
            :disabled="actionsLoading"
            :class="`btn alert-btn text-d-10-${getColourForAction(action.variant)} me-1 py-0 px-2`"
            @click="onActionClick(action)">
            {{ action.label }}
          </button>
          <k-spinner v-if="actionsLoading" size="sm" class="ms-1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { RouterLink } from "vue-router";

import "./Alert.scss";
import KSpinner from "@ui/progress/KSpinner.vue";
import KButton from "@ui/button/KButton.vue";

import type { UIColourVariant, ColourVariant } from "@data/types/ColourVariant";
import { UIToColourMap } from "@data/types/ColourVariant";

import type { AlertAction } from "./alert";

const props = withDefaults(
  defineProps<{
    /** Title of the alert */
    title?: string;
    /** String content to show */
    content?: string;
    /** Icon to show in the alert */
    icon?: string;
    /** Whether the alert should be shown (so animations work) */
    show?: boolean;
    /** Router destination for when the title is clicked */
    to?: string;
    /** Colour of the alert */
    variant?: UIColourVariant | ColourVariant;
    /** Actions to show in the alert */
    actions?: AlertAction[];
    /** Whether to show the close button */
    showClose?: boolean;
    /** Type of alert */
    type?: "raised" | "flat";
  }>(),
  {
    variant: "primary",
    to: undefined,
    title: undefined,
    show: true,
    content: undefined,
    icon: undefined,
    actions: undefined,
    showClose: false,
    type: "flat"
  }
);

const emit = defineEmits<{
  (event: "close"): void;
}>();

const actionsLoading = ref(false);
const onActionClick = async (action: AlertAction) => {
  actionsLoading.value = true;
  await Promise.resolve(action.onClick());
  emit("close");
  actionsLoading.value = false;
};

const getColourForAction = (variant?: UIColourVariant): ColourVariant => {
  const v = variant ?? props.variant;
  if (v in UIToColourMap) {
    return UIToColourMap[v as UIColourVariant];
  }
  return "blue";
};
</script>

<style scoped>
.alert-btn {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: var(--k-background-translucent-25);
  font-size: 0.9em;
}
.alert-btn:hover {
  background: var(--k-background-translucent-50);
}

.close-btn {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 2px 4px !important;
  height: 24px;
  color: rgba(0, 0, 0, 0.4);
}
</style>
