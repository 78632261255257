import type { Ref } from "vue";
import { ref, computed } from "vue";

import { createGlobalState, useStorage } from "@vueuse/core";
import { defineStore } from "pinia";

import { CollectionAPI } from "../collection";
import { ConfigurationAPI, type NavigationStructure } from "../configuration";

import type { NavbarGroup } from "@ui/navbar/NavbarStructure";

import { useCollectionQueries } from "./collectionStore/collectionQueries";
import { toServerField, useFieldMethods } from "./collectionStore/fieldMethods";
import { useConfigMethods } from "./collectionStore/configMethods";

import { Collection, restoreCollection, restoreFields } from "@data/data/Collection";
import type { ServerCollection } from "@data/data/Collection";
import type { KField } from "@data/fields/KField";
import { deepEquals } from "@data/helpers/manipulation/Comparison";
import type { KView } from "@data/data/View";

import { isUnitTest } from "@/tests/helpers/testFlag";
import { storageKeys } from "@/consts/storageKeys";

import type { AddCollectionInput, UpdateCollectionInput } from "../collection";
import type { ExtendedUINavbarItem } from "../configuration";

// Used to ensure collections load instantly rather than waiting for Apollo's cache to wake up on refresh
// Stored separately to guarantee performance as this is used for every collection and we don't want to flash "Page not found"
const useCollectionsLocalStorageCache = createGlobalState(() => useStorage<ServerCollection[]>(storageKeys.cache.collections, []));
const useNavLocalStorageCache = createGlobalState(() =>
  useStorage<NavigationStructure>(storageKeys.cache.navigation, { groups: [{ label: "Collections", items: [] }] })
);
const useDefaultViewCache = createGlobalState(() => useStorage<KView[]>(storageKeys.cache.defaultViews, []));

export const useCollectionStore = defineStore("collections", () => {
  const localCollectionsCache = useCollectionsLocalStorageCache();

  const collectionMap = ref<Map<string, Collection>>();
  const collections = computed(() => collectionMap.value && Array.from(collectionMap.value.values()));

  // Ensures that a freshly added collection is put in the correct group
  const tempNavAssignments = new Map<string, string>();
  const navigationStructure = useNavLocalStorageCache();
  const navigationGroups = computed(() => {
    const navbarGroups: NavbarGroup[] = [];
    const processedCollections = new Set<string>();
    for (const group of navigationStructure.value.groups) {
      const items: ExtendedUINavbarItem[] = [];
      for (const item of group.items) {
        const collection = collectionMap.value?.get(item.collectionId);
        if (!collection) {
          continue;
        }
        processedCollections.add(item.collectionId);
        items.push({
          icon: collection.icon,
          label: collection.plural,
          to: { path: `/c/${collection.slug}` },
          collectionId: item.collectionId,
          hidden: item.hidden
        });
      }

      navbarGroups.push({
        label: group.label,
        items
      });
    }

    // Add any collections that aren't in the nav structure
    if (collections.value)
      for (const collection of collections.value) {
        if (!processedCollections.has(collection.id)) {
          const targetGroupLabel = tempNavAssignments.get(collection.id);
          const targetGroup = navbarGroups.find((g) => g.label === targetGroupLabel) ?? navbarGroups.at(-1);
          targetGroup?.items.push({
            icon: collection.icon,
            label: collection.plural,
            to: { path: `/c/${collection.slug}` },
            collectionId: collection.id
          } as ExtendedUINavbarItem);
        }
      }

    return navbarGroups;
  });

  const defaultViews = useDefaultViewCache();

  const loadData = (data: ServerCollection[] | Collection[]) => {
    //compare the data to the local cache, if it's the same don't bother updating the UI
    // This caused a bug where the collection list would be refetched a second time after the first cache load and then server fetch
    if (!(data.at(0) instanceof Collection) && JSON.stringify(localCollectionsCache.value) === JSON.stringify(data) && collectionMap.value !== undefined) {
      return;
    }

    collectionMap.value = new Map();
    if (!(data.at(0) instanceof Collection)) {
      localCollectionsCache.value = data as ServerCollection[];
    }

    for (const stored of data) {
      if (stored instanceof Collection) {
        collectionMap.value.set(stored.id, stored);
      } else {
        const restored = restoreCollection(stored);
        collectionMap.value.set(restored.id, restored);
      }
    }
  };

  const loadNavData = (data: NavigationStructure) => {
    // Check if the data has changed, if not don't bother refreshing the UI etc
    if (!deepEquals(data, navigationStructure.value)) {
      navigationStructure.value = data;
    }
  };

  const loadViewData = (data: KView[]) => {
    if (!deepEquals(data, defaultViews.value)) {
      defaultViews.value = data;
    }
  };

  loadData(localCollectionsCache.value);

  const loading = ref(false);

  if (!isUnitTest) {
    loading.value = true;
    const { onResult, refetch } = CollectionAPI.useCollections();
    onResult((newResult) => {
      loading.value = newResult.loading;
      if ((newResult as unknown) == null) {
        return;
      }
      if (newResult.data?.collections) {
        loadData(newResult.data.collections);
      }
      if (newResult.data?.navigationStructure && !newResult.loading) {
        loadNavData(newResult.data.navigationStructure);
      }

      if (newResult.data?.views && !newResult.loading) {
        loadViewData(newResult.data.views);
      }
    });

    const navSub = ConfigurationAPI.onNavUpdateEvent();
    navSub.onResult(() => void refetch());
    const collectionSub = CollectionAPI.onCollectionUpdateEvent();
    collectionSub.onResult((evt) => {
      if (!evt.data) return;
      switch (evt.data.collectionUpdated.eventType) {
        case "COLLECTION_ADDED":
        case "COLLECTION_UPDATED": {
          const restored = restoreCollection(evt.data.collectionUpdated.collection);
          collectionMap.value?.set(restored.id, restored);
          break;
        }
        case "COLLECTION_DELETED":
          collectionMap.value?.delete(evt.data.collectionUpdated.collection.id);
          break;
      }
    });
  }

  const { mutate: updateNav } = ConfigurationAPI.updateNavStructure();
  const updateNavStructure = async (newStructure: NavigationStructure) => {
    loadNavData(newStructure);

    const input = {
      groups: newStructure.groups.map((group) => ({
        label: group.label,
        items: group.items.map((item) => ({
          collectionId: item.collectionId,
          hidden: item.hidden
        }))
      }))
    };
    await updateNav({ input });
  };

  const { mutate: addCollectionMutation } = CollectionAPI.useAddCollection();

  const addCollection = async (input: Omit<AddCollectionInput, "schema">, fields: KField[]) => {
    const schema = fields.map((f) => toServerField(f, true));

    const result = await addCollectionMutation({ value: { ...input, schema } });
    if (result?.data?.addCollection !== undefined) {
      collectionMap.value?.set(
        result.data.addCollection.id,
        new Collection(result.data.addCollection.id, input.displayNameSingular, restoreFields(result.data.addCollection.schema), {
          description: input.description,
          plural: input.displayNamePlural,
          slug: input.slug,
          icon: input.icon,
          activities: input.enableActivities
            ? {
                isEnabled: input.enableActivities
              }
            : undefined,
          tasks: input.enableTasks
            ? {
                isEnabled: input.enableTasks
              }
            : undefined,
          files: input.enableFiles
            ? {
                isEnabled: input.enableFiles
              }
            : undefined
        })
      );
      if (input.navbarGroup) {
        tempNavAssignments.set(result.data.addCollection.id, input.navbarGroup);
      }
      return result.data.addCollection.id;
    }
    return null;
  };

  const { mutate: updateCollectionMutation } = CollectionAPI.useUpdateCollection();

  const updateCollection = async (input: UpdateCollectionInput) => {
    const toUpdate = collectionMap.value?.get(input.id);
    if (toUpdate) {
      const result = await updateCollectionMutation({ value: input });
      const returned = result?.data?.updateCollection;
      if (returned !== undefined) {
        if (toUpdate.options !== undefined) {
          toUpdate.options.slug = input.slug;
        }
        collectionMap.value?.set(
          input.id,
          new Collection(input.id, input.displayNameSingular, toUpdate.fields, {
            description: input.description,
            ...toUpdate.options,
            ...result?.data?.updateCollection
          })
        );
      }
    } else {
      throw new Error(`Collection with ID ${input.id} not found`);
    }
  };

  const { mutate: deleteCollectionMutation } = CollectionAPI.useDeleteCollection();

  const deleteCollection = async (id: string) => {
    const result = await deleteCollectionMutation({ id });
    const success = result?.data?.deleteCollection;
    if (success === true) {
      // Remove from navbar
      loadNavData({
        groups: navigationStructure.value.groups.map((group) => ({
          items: group.items.filter((item) => item.collectionId !== id),
          label: group.label
        }))
      });

      collectionMap.value?.delete(id);
    }
    return success;
  };

  const useDefaultView = (collection: Ref<string | Collection | undefined>) => {
    const collectionId = computed(() => (collection.value instanceof Collection ? collection.value.id : collection.value));
    return computed(() => defaultViews.value.find((v) => v.collectionId === collectionId.value));
  };

  return {
    collections,
    loadData,
    addCollection,
    updateCollection,
    deleteCollection,
    ...useFieldMethods(collectionMap),
    ...useConfigMethods(collectionMap),
    ...useCollectionQueries(collections, collectionMap),
    navigationGroups,
    updateNavStructure,
    useDefaultView,
    loading
  };
});
