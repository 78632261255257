export const fullAddress = `title
line1
line2
city
county
country
postcode
lat
lng`;

export const fullRecord = `
id
createdAt
createdBy
updatedAt
updatedBy
data
collection
external {
  key
  value {
    id
    url
    status
    createdAt
    updatedAt
    deletedAt
    properties
  }
}
`;

export const fullCollection = `
  id
  nextId
  singular
  plural
  slug
  sortProperty
  defaultSortDirection
  primaryProperty
  secondaryProperty
  icon
  description
  extensionId
  idFormatConfig {
    visible
    prefix
    digits
  }
  uniqueFieldRestrictions {
    fieldIds
    severity
  }
  schema {
    id
    key
    type
    data
  }
  activities {
    isEnabled
    types {
      key
      label
      colour
      icon
      isEnabled
      isEditable
    }
  }
  tasks {
    isEnabled
  }
  files {
    isEnabled
  }
  associationTabs {
    collectionId
    fieldId
    order
    label
    hidden
  }
  permissions {
    read
    write
    bulk {
      import
      export
      bulkDelete
    }
    configure
    activities {
      read
      write
    }
    tasks {
      read
      write
    }
    listVisibility
  }
  conditionalValidators {
    message
    severity
    conditional
  }
`;
