<template>
  <k-input-number
    v-model="numInput"
    :label
    :step="1"
    placeholder="Year"
    @keydown="preventNonNumerical"
    @focus="$emit('focus')"
    @blur="
      expandTwoDigitYear();
      validateYear();
      $emit('blur');
    "
    @change="$emit('change', $event)" />
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { mergeInputConfig, useInputConfig } from "../inputConfig";
import KInputNumber from "../numeric/KInputNumber.vue";

import type { Dayjs } from "dayjs";

const props = defineProps<{
  /** Value of the input */
  modelValue?: Dayjs;
  /** Label for the input */
  label?: string;
}>();

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", newValue: Dayjs | undefined): void;
}>();

dayjs.extend(utc);

const config = useInputConfig();

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const numInput = ref(props.modelValue?.year());
const yearValid = ref<boolean>(true);
const validateYear = () => {
  if (numInput.value === undefined || numInput.value < 1000 || numInput.value >= 10000) {
    yearValid.value = false;
  } else yearValid.value = true;
};

mergeInputConfig(
  computed(() => ({ status: yearValid.value ? config.value.status : "INVALID" })),
  config
);

const ARBITRARY_YEAR_CUTOFF = 20;
const expandTwoDigitYear = () => {
  const currentYear = dayjs().year() % 100;
  if (numInput.value && numInput.value >= 0 && numInput.value < 100) {
    // 20 years in the future is the arbitrary cut off i've chosen for to start adding 1900 instead
    // so 45 goes to 1945 but say 40 will go to 2040
    numInput.value = numInput.value < currentYear + ARBITRARY_YEAR_CUTOFF ? 2000 + numInput.value : 1900 + numInput.value;
  }
};
watch(numInput, (newValue) => {
  if (newValue !== props.modelValue?.year()) {
    if (newValue === undefined) {
      emit("update:modelValue", undefined);
    } else if (newValue >= 1000 && newValue < 10000) {
      emit("update:modelValue", dayjs.utc().year(newValue).startOf("year"));
    }
  }
});

watch(
  () => props.modelValue,
  (newValue) => (numInput.value = newValue?.year())
);

const allowedKeys = new Set([
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "Backspace",
  "Delete",
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "ArrowDown",
  "Tab",
  "Enter"
]);

const preventNonNumerical = (e: KeyboardEvent) => {
  if (!allowedKeys.has(e.key)) {
    e.preventDefault();
  }
};
</script>
