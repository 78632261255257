import { getFieldOfType, BooleanAccessor, matchAccessor } from "../accessors";
import { BooleanConstant } from "../constants";
import { IntersectsExpression, IsEmptyExpression, SubsetOfExpression } from "../data/conditions";
import { DayjsCompareExpression } from "../datetime";
import { GreaterThanExpression, GreaterThanOrEqualExpression, LessThanExpression, LessThanOrEqualExpression } from "../maths";
import { AndExpression, OrExpression, XorExpression, NotExpression, EqualsExpression } from "../operators";
import { IsEditingExpression } from "../state";
import { ContainsExpression, StartsWithExpression, EndsWithExpression } from "../strings";
import { IsBlankExpression } from "../validation";
import { ParsingError, type BooleanExpression } from "../expressions";

import { checkOneOf, splitTokens } from "./operators";

import type { KContext } from "../context";
import type { ExpressionParser } from "../parser";

type NonNullCheck<T extends readonly string[]> = NonNullable<ReturnType<typeof checkOneOf<T>>>;

const booleanChainOperations = ["and", "or", "xor"] as const;

const handleBooleanChain = (parser: ExpressionParser, tokens: string[], context: KContext, checkResult: NonNullCheck<typeof booleanChainOperations>) => {
  const subexpressions = splitTokens(tokens, checkResult.indices).map((e) => parser.parseBoolean(e, context));
  switch (checkResult.present) {
    case "and":
      return new AndExpression(subexpressions);
    case "or":
      return new OrExpression(subexpressions);
    case "xor":
      return new XorExpression(subexpressions);
  }
};

const handleIsIsnt = (parser: ExpressionParser, tokens: string[], context: KContext, checkResult: NonNullCheck<["is", "isn't"]>) => {
  const [left, right] = splitTokens(tokens, checkResult.indices);
  const inverted = checkResult.present === "isn't";
  let expression: BooleanExpression;
  switch (right[0]) {
    case "blank":
      if (right.length !== 1) {
        throw new ParsingError(`Invalid right hand of blank expression: ${right.join(" ")}`);
      }
      expression = new IsBlankExpression(parser.parseString(left, context));
      break;
    case "empty":
      if (right.length !== 1) {
        throw new ParsingError(`Invalid right hand of empty expression: ${right.join(" ")}`);
      }
      expression = new IsEmptyExpression(parser.parseData(left, context));
      break;
    case "after":
    case "before":
    case "in": {
      const leftExpression = parser.parseDatetime(left, context);
      const rightExpression = parser.parseDatetime(right.slice(1), context);
      switch (right[0]) {
        case "after":
          expression = new DayjsCompareExpression(leftExpression, rightExpression, "isAfter");
          break;
        case "before":
          expression = new DayjsCompareExpression(leftExpression, rightExpression, "isBefore");
          break;
        case "in":
          expression = new DayjsCompareExpression(leftExpression, rightExpression, "isSame");
          break;
      }
      break;
    }
    case "subset":
    case "superset":
      if (right.length !== 3 || right[1] !== "of") {
        throw new ParsingError(`Invalid right hand of subset expression: ${right.join(" ")}`);
      }
      if (right[0] === "subset") {
        expression = new SubsetOfExpression(parser.parseData(left, context), parser.parseData(right.slice(2), context));
      } else {
        expression = new SubsetOfExpression(parser.parseData(right.slice(2), context), parser.parseData(left, context));
      }
      break;
    default:
      throw new ParsingError(`Invalid right hand of validation expression: ${right[0]}`);
  }
  return inverted ? new NotExpression(expression) : expression;
};

const numberComparisonOperations = ["=", ">", "<", ">=", "<="] as const;

const handleNumberComparison = (
  parser: ExpressionParser,
  tokens: string[],
  context: KContext,
  checkResult: NonNullCheck<typeof numberComparisonOperations>
) => {
  const [left, right] = splitTokens(tokens, checkResult.indices).map((e) => parser.parseNumber(e, context));
  switch (checkResult.present) {
    case "=":
      return new EqualsExpression(left, right);
    case ">":
      return new GreaterThanExpression(left, right);
    case ">=":
      return new GreaterThanOrEqualExpression(left, right);
    case "<":
      return new LessThanExpression(left, right);
    case "<=":
      return new LessThanOrEqualExpression(left, right);
  }
};

const stringComparisonOperations = ["matches", "contains", "starts", "ends"] as const;

const handleStringComparison = (
  parser: ExpressionParser,
  tokens: string[],
  context: KContext,
  checkResult: NonNullCheck<typeof stringComparisonOperations>
) => {
  // remove "with" for start with and end with
  if (checkResult.present === "starts" || checkResult.present === "ends") {
    const spliced = tokens.splice(checkResult.indices[0] + 1, 1);
    if (spliced[0] !== "with") {
      throw new ParsingError(`Invalid expression: ${checkResult.present} must be followed by "with"`);
    }
  }
  const [left, right] = splitTokens(tokens, checkResult.indices).map((e) => parser.parseString(e, context));
  switch (checkResult.present) {
    case "matches":
      return new EqualsExpression(left, right);
    case "contains":
      return new ContainsExpression(left, right);
    case "starts":
      return new StartsWithExpression(left, right);
    case "ends":
      return new EndsWithExpression(left, right);
  }
};

export function parseBoolean(this: ExpressionParser, expression: string | string[], context: KContext): BooleanExpression {
  return this.baseParse(expression, "boolean", context, {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    recurse: parseBoolean.bind(this),
    single: (token) => {
      switch (token) {
        case "true":
          return new BooleanConstant(true);
        case "false":
          return new BooleanConstant(false);
        case "null":
          return new BooleanConstant(undefined);
        case "editing":
          return new IsEditingExpression();
      }
      // check for record accessors
      const accessorMatch = matchAccessor(token);
      if (accessorMatch) {
        const field = getFieldOfType(context, accessorMatch, "boolean");
        return new BooleanAccessor(accessorMatch.type, field);
      }
      throw new ParsingError(`Unknown boolean constant: ${token}`);
    },
    multi: (tokens) => {
      // boolean operators
      const andOr = checkOneOf(tokens, ["and", "or", "xor"], false);
      if (andOr) {
        return handleBooleanChain(this, tokens, context, andOr);
      }

      // not
      if (tokens[0] === "not") {
        if (tokens[1] === "not") {
          // automatically simplify double negatives
          return this.parseBoolean(tokens.slice(2), context);
        }
        return new NotExpression(this.parseBoolean(tokens.slice(1), context));
      }

      // intersects operator
      const intersects = checkOneOf(tokens, ["intersects"], true);
      if (intersects) {
        const [left, right] = splitTokens(tokens, intersects.indices);
        if (left.length !== 1 || right.length !== 1) {
          throw new ParsingError(`Invalid intersects expression: ${tokens.join(" ")}`);
        }
        return new IntersectsExpression(this.parseData(left, context), this.parseData(right, context));
      }

      // is [x] operators
      const is = checkOneOf(tokens, ["is", "isn't"], true);
      if (is) {
        return handleIsIsnt(this, tokens, context, is);
      }

      // comparison
      const comparison = checkOneOf(tokens, ["=", ">", "<", ">=", "<="], true);
      if (comparison) {
        return handleNumberComparison(this, tokens, context, comparison);
      }

      // string comparison
      const stringComparison = checkOneOf(tokens, stringComparisonOperations, true);
      if (stringComparison) {
        return handleStringComparison(this, tokens, context, stringComparison);
      }

      throw new ParsingError(`Unknown expression: ${tokens.join(" ")}`);
    }
  });
}
