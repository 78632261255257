import { ref } from "vue";
import type { Ref } from "vue";

import imageCompression from "browser-image-compression";

import { byte } from "@data/units/basic/si";
import type { ValidationIssues } from "@data/validation/Validation";

export class FileUpload {
  id?: string;

  fileId = "";

  description = "";

  get fileName() {
    return this.file?.name ?? this.description;
  }

  get extension() {
    const name = this.fileName;
    return name.substring(name.lastIndexOf("."));
  }

  get size() {
    return this.file?.size ?? 0;
  }

  url = "";

  property?: string;

  uploadProgress = 0;

  status: "NOT_UPLOADED" | "UPLOADING" | "UPLOADED" | "ERROR" | "TO_DELETE" | "DELETED" = "NOT_UPLOADED";

  constructor(public readonly file?: File) {
    if (file) {
      this.fileId = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
      this.description = file.name;
      this.url = URL.createObjectURL(file);
    }
  }
}

const compressables = new Set(["image/jpeg", "image/png", "image/webp"]);
export const useFileList = (initialValue?: FileUpload[], maxSize?: Ref<number | undefined>) => {
  const files = ref(initialValue ?? []);
  const issues = ref<ValidationIssues>([]);

  async function addFiles(newFiles: FileList | File[]) {
    const currentMax = maxSize?.value ?? 2000000;
    const toUpload: FileUpload[] = [];
    const errors: { file: File; message: string }[] = [];
    const afterCompression: { file: File; compressed: boolean }[] = [];
    for (const f of Array.from(newFiles)) {
      if (f.size >= currentMax && compressables.has(f.type)) {
        try {
          const compressedFile = await imageCompression(f, { maxSizeMB: currentMax / (1024 * 1024) });
          afterCompression.push({ file: compressedFile, compressed: true });
        } catch (error) {
          console.error(error);
        }
      }
      afterCompression.push({ file: f, compressed: false });
    }

    for (const { file, compressed } of afterCompression) {
      const uploadFile = new FileUpload(file);
      if (files.value.some(({ fileId }) => fileId === uploadFile.fileId)) {
        errors.push({ file, message: `File ${uploadFile.fileName} already exists` });
      } else if (files.value.some(({ fileName }) => fileName === uploadFile.fileName)) {
        errors.push({ file, message: `File with name ${uploadFile.fileName} already exists` });
      } else if (uploadFile.size >= currentMax) {
        errors.push({ file, message: `File ${uploadFile.fileName} is too large (max size ${byte.display(currentMax)})` });
      } else {
        if (compressed) {
          errors.push({ file, message: `File ${uploadFile.fileName} was compressed` });
        }
        toUpload.push(uploadFile);
      }
    }
    files.value.push(...toUpload);
    issues.value = errors.map(({ message }) => ({ message, severity: "warning" }) as const);
    return errors;
  }

  function removeFile(file: FileUpload) {
    const index = files.value.indexOf(file);

    if (index > -1) files.value.splice(index, 1);
  }

  return { files, addFiles, removeFile, issues };
};
