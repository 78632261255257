import type { InjectionKey } from "vue";

import type { FieldType } from "@data/constants/fieldTypes";

export type FilterType = FieldType | "record" | "multiRecord";

export const fieldTypeFilterKey = Symbol("validFieldTypes") as InjectionKey<
  | {
      whiteList: Set<FilterType>;
    }
  | {
      blackList: Set<FilterType>;
    }
>;

export const testProvideFieldTypeFilter = (
  filter:
    | {
        whiteList: FilterType[];
      }
    | {
        blackList: FilterType[];
      }
) => ({
  provide: {
    [fieldTypeFilterKey as symbol]:
      "whiteList" in filter
        ? {
            whiteList: new Set(filter.whiteList)
          }
        : {
            blackList: new Set(filter.blackList)
          }
  }
});
