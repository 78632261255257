const categories = new Map([
  [/[a-z]/, 26], // Lowercase letters
  [/[A-Z]/, 26], // Uppercase letters
  [/\d/, 10], // Digits
  [/[^\d A-Za-z]/, 33] // Special characters
]);
/**
 * Estimate the entropy (in bits) for a password
 *
 * @param password Password to test
 * @returns Bits of entropy
 */
export const estimateEntropy = (password: string) => {
  if (!password) {
    return 0;
  }
  let charTypes = 0;
  for (const [test, chars] of categories.entries()) {
    if (test.test(password)) {
      charTypes += chars;
    }
  }
  return password.length * Math.log2(charTypes);
};
