export type Address = {
  title?: string;
  line1?: string;
  line2?: string;
  city?: string;
  county?: string;
  country?: string;
  postcode?: string;
  lat?: number;
  lng?: number;
};

export const addressDisplayOrder = ["title", "line1", "line2", "city", "county", "country", "postcode"] as const;

export interface SearchResult {
  id: string;
  name: string;
  highlight: { start: number; end: number; style?: string; content: string }[];
}

export interface HereAutosuggestResponse {
  id: string;
  title: string;
  address?: { label?: string };
  highlights: { address?: { label: { start: number; end: number }[] }; title: { start: number; end: number }[] };
  localityType: string;
  position: { lat: number; lng: number };
  distance: number;
}

export interface HereLocationResponse {
  id: string;
  title: string;
  position: { lat: number; lng: number };
  address: {
    label: string;
    countryCode: string;
    countryName: string;
    state: string;
    county: string;
    countyCode: string;
    city: string;
    district: string;
    subdistrict: string;
    street: string;
    streets?: string[];
    houseNumber?: string;
    building?: string;
    postalCode: string;
  };
}
