import { sortedBy } from "../helpers/manipulation/Ordering";
import { toSuperscriptUnicode } from "../helpers/strings/Superscript";

export const SIPrefixes = {
  [-24]: "y",
  [-21]: "z",
  [-18]: "a",
  [-15]: "f",
  [-12]: "p",
  [-9]: "n",
  [-6]: ["μ", "u"], // second option for expressions
  [-3]: "m",
  [0]: "",
  [3]: "k",
  [6]: "M",
  [9]: "G",
  [12]: "T",
  [15]: "P",
  [18]: "E",
  [21]: "Z",
  [24]: "Y"
} as const;

export const SIPrefixNames: Record<keyof typeof SIPrefixes, string> = {
  [-24]: "yocto",
  [-21]: "zepto",
  [-18]: "atto",
  [-15]: "femto",
  [-12]: "pico",
  [-9]: "nano",
  [-6]: "micro",
  [-3]: "milli",
  [0]: "",
  [3]: "kilo",
  [6]: "mega",
  [9]: "giga",
  [12]: "tera",
  [15]: "peta",
  [18]: "exa",
  [21]: "zetta",
  [24]: "yotta"
} as const;

export const formatUnitPowers = (
  symbols: Iterable<{ symbol: string; power: number }>,
  type: "html" | "unicode" | "expression" = "html",
  negativePowers = false
): string => {
  // order by powers
  const ordered = sortedBy(symbols, (s) => s.power, "DESC");
  let goneNegative = negativePowers;
  const units = ordered.map(({ symbol, power }, idx) => {
    if (power === 0) return "";
    let result = "";
    if (!goneNegative && power < 0) {
      goneNegative = true;
      result += "/";
    } else if (type === "expression" && idx) {
      result += "*";
    }
    power = negativePowers ? power : Math.abs(power);
    if (power === 1) return result + symbol;
    switch (type) {
      case "expression":
        return result + `${symbol}${power}`;
      case "html":
        return result + `${symbol}<sup>${power}</sup>`;
      case "unicode":
        return result + `${symbol}${toSuperscriptUnicode(power)}`;
    }
  });
  return units.join("");
};
