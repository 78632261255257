<template>
  <div :class="bgEnv && `navbar-${bgEnv}`">
    <k-navbar
      :config="structure"
      :can-customise="isAdmin"
      :add-button-label="isAdmin && !isExpired ? 'Add collection' : undefined"
      :notification-count="notificationStore.notificationCount"
      :context-menu-items="configContextItem"
      @open-notifications="toggleNotifications"
      @add="addCollection"
      @items-updated="onUpdated"
      @navigated="showNotifications = false">
      <template #footer="footerProps">
        <k-button
          v-if="footerProps.state != 'COMPACT' && isAdmin"
          variant="transparent"
          class="w-100 mb-2 mt-n2 navbar-hide-compact settings-nav"
          :active="settingsActive"
          icon="cog"
          label="Settings"
          @click="openSettings" />
        <billing-alert v-if="footerProps.state !== 'COMPACT'" />
      </template>
      <template v-if="isAuthenticated" #menu>
        <k-dropdown-divider />
        <k-dropdown-item label="Send feedback" @click="showFeedbackForm = true" />
        <k-dropdown-item v-if="planCode == 'starter'" label="Upgrade to Pro" @click="showUpgradeModal = true" />
        <k-dropdown-item label="Settings" to="/settings" />
        <k-dropdown-divider />
        <k-dropdown-item label="Sign out" @click="onSignOut" />
      </template>
    </k-navbar>
    <add-collection-modal v-if="isAdmin" v-model:visible="showAdd" />
    <feedback-form v-model:visible="showFeedbackForm" />
    <upgrade-modal v-model="showUpgradeModal" :reason="upgradeReason" />

    <k-notification-centre
      :show="showNotifications"
      :notifications
      :loading="notificationStore.loading"
      @close="showNotifications = false"
      @mark-as-read="(id: string) => notificationStore.markAsRead(id)"
      @dismiss="(id: string | string[]) => notificationStore.dismiss(id)">
      <template #footer>
        <small class="d-block border-top pt-2 pb-1 mx-2 text-end">
          <external-link class="me-3" url="https://www.kinabase.com/updates">What's new</external-link>
          <a v-if="notifications" href="#" @click="notificationStore.dismiss(notifications?.map((n) => n.id))">Clear all</a>
        </small>
      </template>
    </k-notification-centre>
  </div>
</template>

<script setup lang="ts">
import { computed, provide, ref } from "vue";
import { useRouter } from "vue-router";

import { useEventBus } from "@vueuse/core";

import KDropdownDivider from "@ui/dropdown/KDropdownDivider.vue";
import KDropdownItem from "@ui/dropdown/KDropdownItem.vue";
import KNavbar from "@ui/navbar/KNavbar.vue";
import KButton from "@ui/button/KButton.vue";
import type { NavbarGroup, NavbarStructure } from "@ui/navbar/NavbarStructure";
import KNotificationCentre from "@ui/notifications/KNotificationCentre.vue";
import type { ContextMenuItem } from "@ui/context-menu/ContextMenuItem";
import type { NavbarItem } from "@ui/navbar/NavbarStructure";

import UpgradeModal from "./settings/system/billing/components/UpgradeModal.vue";

import { peopleCollectionName } from "@data/constants/names";

import ExternalLink from "@/components/ExternalLink.vue";
import BillingAlert from "@/components/BillingAlert.vue";
import iconBeta from "@/assets/icon-beta.svg";
import iconDev from "@/assets/icon-dev.svg";
import iconProd from "@/assets/icon.svg";
import AddCollectionModal from "@/components/AddCollection.vue";
import FeedbackForm from "@/components/feedback/FeedbackForm.vue";
import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { navbarGroupKey } from "@/pages/collections/wizard/navbarInfo";
import { useAuth, signOut, useIsAdmin, useSubscriptionStatus } from "@/services/auth";
import { useCollectionsWithPermission, useHasPeoplePermission } from "@/helpers/permissions";
import { useNotificationStore } from "@/api/stores/useNotificationStore";
import { PlanUpgradeEvent } from "@/services/plan-upgrade-handler";

import type { NavigationStructure, ServerNavigationItem } from "@/api/configuration";

const { isAuthenticated, username, tenantName } = useAuth();
const isAdmin = useIsAdmin();

// Fetch the list of collections to show in the navbar
const collectionStore = useCollectionStore();

const router = useRouter();

const env = window.__ssr__?.env;
const iconMap = new Map([
  ["beta", iconBeta],
  ["dev", iconDev]
]);
const icon = (env && iconMap.get(env)) ?? iconProd;

const { status, planCode } = useSubscriptionStatus();
const isExpired = computed(() => status.value === "expired");

const bgEnv = computed(() => {
  if (status.value === "expired") {
    return "expired";
  }
  return env;
});

const onSignOut = async () => {
  await signOut();
  await router.replace("/");
};

const showAdd = ref(false);
const currentGroup = ref<{ label: string }>();

provide(navbarGroupKey, currentGroup);
const addCollection = (group?: { label: string }) => {
  currentGroup.value = group;
  showAdd.value = !showAdd.value;
};
const readCollections = useCollectionsWithPermission("read");
const configCollections = useCollectionsWithPermission("config");
const visibleCollections = computed(() => new Set([...readCollections.value.map((c) => c.id), ...configCollections.value.map((c) => c.id)]));

const filteredNavGroups = computed(() => {
  if (isAdmin.value) return collectionStore.navigationGroups;
  const output: NavbarGroup[] = [];
  for (const group of collectionStore.navigationGroups) {
    output.push({
      label: group.label,
      items: group.items.filter((item) => visibleCollections.value.has(item.collectionId ?? ""))
    });
  }
  return output;
});

const canReadPeople = useHasPeoplePermission("read");

const structure = computed(() => {
  const items = [
    {
      icon: "home",
      label: "Dashboard",
      to: { path: "/" },
      items: []
    }
  ];

  if (isExpired.value) {
    items.push({
      icon: "gear",
      label: "Settings",
      to: { path: "/settings?p=billing" },
      items: []
    });
  } else if (canReadPeople.value) {
    items.push({
      icon: "users",
      label: peopleCollectionName.plural,
      to: { path: `/${peopleCollectionName.slug}` },
      items: []
    });
  }

  return {
    user: {
      name: username.value ?? ""
    },
    product: {
      name: "Kinabase",
      iconLight: icon,
      iconDark: icon
    },
    items,
    groups: filteredNavGroups.value,
    workspace: {
      id: "",
      name: tenantName.value ?? ""
    },
    otherWorkspaces: []
  } satisfies NavbarStructure;
});

const onUpdated = async (groups: NavbarGroup[]) => {
  const updatedStructure: NavigationStructure = { groups: [] };
  for (const group of groups) {
    if (group.items.length === 0) continue;
    updatedStructure.groups.push({
      label: group.label,
      items: group.items as ServerNavigationItem[]
    });
  }
  await collectionStore.updateNavStructure(updatedStructure);
};

const showNotifications = ref(false);
const notificationStore = useNotificationStore();
const notifications = notificationStore.useKNotifications(showNotifications);
const toggleNotifications = () => {
  showNotifications.value = !showNotifications.value;
};

const showFeedbackForm = ref(false);

const configContextItem: ContextMenuItem<NavbarItem>[] = [
  {
    label: "Configure",
    onClick: async (item) => {
      if (!item?.to) return;
      await router.push(`${item.to.path}/configure`);
    }
  }
];

const openSettings = async () => {
  await router.push("/settings");
};
const settingsActive = computed(() => router.currentRoute.value.path.startsWith("/settings"));

const showUpgradeModal = ref(false);
const upgradeReason = ref();

const bus = useEventBus(PlanUpgradeEvent);
// Handle exceptions when the user tries to do something that requires a higher plan
bus.on((exception) => {
  upgradeReason.value = exception.reason;
  showUpgradeModal.value = true;
});
</script>

<style scoped lang="scss">
.navbar-dev,
.navbar-expired,
.navbar-beta {
  :deep(.navbar::before) {
    content: " ";
    position: fixed;
    left: 0;
    top: 50px;
    bottom: 0;
    width: var(--k-navbar-width);
    opacity: 0.2;
    pointer-events: none;
    transition: width 0.15s ease-in-out;
  }
  :deep(.navbar.navbar-compact::before) {
    width: var(--k-navbar-width-sm);
  }
  :deep(.navbar.navbar-hidden::before) {
    width: 0;
  }
}

.navbar-dev :deep(.navbar::before) {
  background: linear-gradient(5deg, #f8af1d, #f8af1d00);
}
.navbar-beta :deep(.navbar::before) {
  background: linear-gradient(5deg, #12975a, #12975a00);
}
.navbar-expired :deep(.navbar::before) {
  background: linear-gradient(5deg, #e53e3e, #e53e3e00);
}

.settings-nav {
  color: var(--k-navbar-item-color);
}
.settings-nav.active {
  color: var(--k-navbar-item-active-color);
}

:deep(i) {
  margin-right: 2px;
}
</style>
