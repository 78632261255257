<template>
  <div class="alert-box alert-box-top">
    <k-alert
      v-for="alert in alertDisplayTop"
      :key="`m-${alert.id}`"
      :variant="alert.variant"
      :title="alert.title"
      :content="alert.content"
      :icon="alert.icon"
      :actions="alert.actions"
      :show="alert.show ?? false"
      type="raised"
      :show-close="alert.duration === 'never'"
      @close="closeAlert(alert)" />
  </div>
  <div class="alert-box alert-box-bottom">
    <k-alert
      v-for="alert in alertDisplayBottom"
      :key="`m-${alert.id}`"
      :variant="alert.variant"
      :title="alert.title"
      :content="alert.content"
      :icon="alert.icon"
      :actions="alert.actions"
      :show="alert.show ?? false"
      type="raised"
      :show-close="alert.duration === 'never'"
      @close="closeAlert(alert)" />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from "vue";

import { useEventBus } from "@vueuse/core";

import KAlert from "./KAlert.vue";
import { AlertEvent } from "./alert-event";

import type { Alert } from "./alert";

import "./Alert.scss";

const alerts: Alert[] = reactive([]);
const alertDisplayTop = computed(() =>
  // return reversed array to show latest alerts first
  alerts
    .filter((x) => x.position === "top")
    .slice()
    .reverse()
);

const alertDisplayBottom = computed(() =>
  // return reversed array to show latest alerts first
  alerts
    .filter((x) => !x.position || x.position === "bottom")
    .slice()
    .reverse()
);

const bus = useEventBus(AlertEvent);

const nextId = ref(0);

const remove = (id: number) => {
  const idx = alerts.findIndex((alert) => alert.id === id);
  if (idx < 0) return;
  alerts.splice(idx, 1);
};

const fadeIn = (id: number) => {
  const alert = alerts.find((a) => a.id === id);
  if (alert) alert.show = true;
};

const fadeOut = (id: number) => {
  const alert = alerts.find((a) => a.id === id);
  if (!alert) return;
  alert.show = false;
};

const setTimer = (alert: Alert) => {
  // Check Alert has set duration
  if (!alert.duration) {
    alert.duration = 5000;
  }

  // Add timeout for fade-in, add 100ms delay for fade animation
  setTimeout((id: number) => fadeIn(id), 100, alert.id);

  if (typeof alert.duration !== "number") return;

  // Trigger fading out of Alert from UI
  setTimeout((id: number) => fadeOut(id), alert.duration, alert.id);

  // Remove Alert from queue and clear DOM elements after fade out is complete
  setTimeout((id: number) => remove(id), alert.duration + 1000, alert.id);
};

const closeAlert = (alert: Alert) => {
  if (alert.id === undefined) return; // Note, alert ID can be 0
  fadeOut(alert.id);
  setTimeout((id: number) => remove(id), 200, alert.id);
};

bus.on((alert) => {
  const prevAlert = alerts.at(-1);
  if (prevAlert && prevAlert.title === alert.title && prevAlert.content === alert.content) {
    return;
  }

  if (!alert.id) {
    alert.id = nextId.value;
    nextId.value = alert.id + 1;
  }
  alert.show = false;
  alerts.push(alert);

  // Set timer to remove Alert after duration has passed
  setTimer(alert);
});
</script>
