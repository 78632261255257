import dayjs from "dayjs";

import { isArray, isISimpleObject, type SimpleObject } from "./Copying";

const definedKeys = <O extends object>(obj: O): (keyof O)[] => {
  const keys = Object.keys(obj) as (keyof O)[];
  return keys.filter((key) => obj[key] !== undefined);
};

/**
 * Compare two objects recursively
 *
 * @param source
 * @param target
 * @returns True if objects are the same
 */
export function deepEquals(a: SimpleObject, b: SimpleObject): boolean {
  if (a === b) {
    return true;
  }
  if (a === null || b === null) {
    return false;
  }
  if (isArray(a)) {
    if (!isArray(b)) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }
    for (const [i, element] of a.entries()) {
      if (!deepEquals(element, b[i])) {
        return false;
      }
    }
    return true;
  }
  if (isArray(b)) return false;
  if (typeof a === "object" && typeof b === "object") {
    if (dayjs.isDayjs(a)) {
      if (!dayjs.isDayjs(b)) return false;
      return a.isSame(b);
    }
    if (dayjs.isDayjs(b)) return false;

    if (isISimpleObject(a)) {
      return a.deepEquals(b);
    }
    if (isISimpleObject(b)) return false;

    const aKeys = definedKeys(a);
    const bKeys = definedKeys(b);
    if (aKeys.length !== bKeys.length) {
      return false;
    }
    for (const key of aKeys) {
      if (!deepEquals(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export const getDeepChanges = <O extends SimpleObject & { id?: string | number }>(oldObjects: (O & { id: string | number })[], newObjects: O[]) => ({
  created: newObjects.filter((newObject) => newObject.id === undefined),
  updated: newObjects.filter((newObject): newObject is O & { id: string | number } => {
    if (newObject.id === undefined) return false;
    const oldObject = oldObjects.find((o) => o.id === newObject.id);
    if (!oldObject) return false;
    return !deepEquals(oldObject, newObject);
  }),
  deleted: oldObjects.filter((oldObject) => !newObjects.some((newObject) => newObject.id === oldObject.id))
});
