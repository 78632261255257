<template>
  <k-modal id="upgrade-modal" v-model:show="showModal" static-backdrop hide-header size="lg" hide-footer>
    <k-toolbar
      title="Upgrade your plan"
      class="position-relative wizard-toolbar mx-n3 mt-n3 mb-3"
      show-overflow-indicator
      rounded
      :structure="{
        end: [
          {
            title: 'Help',
            icon: 'question-circle',
            onClick: openHelp,
            type: 'button'
          },
          {
            title: 'Close',
            icon: 'close',
            onClick: () => (showModal = false),
            type: 'button'
          }
        ]
      }" />

    <div class="m-n3 mb-0 bg-light pricing-modal">
      <div v-if="!isBillingAdmin" class="p-5 text-center">
        <div class="mx-auto non-admin-screen">
          <i class="fa fa-lock fa-3x text-warning my-3" />
          <h4 v-if="reason" class="px-4 pt-4 mb-4">To {{ reason ?? "use this feature" }}, you'll need to upgrade your plan.</h4>
          <p class="mb-5">
            You need to be a billing administrator to upgrade your plan.<br /><br />
            To upgrade your plan, please contact your account administrator or our <a href="mailto:support@kinabase.com">account management team</a>.
          </p>
        </div>
      </div>
      <div v-else>
        <h4 v-if="reason" class="px-4 pt-4 pb-0">To {{ reason ?? "use this feature" }}, you'll need to upgrade your plan.</h4>

        <div v-if="canChangePlan && billingFrequency && planCode" class="d-flex p-3">
          <pricing-box
            :plan-code="planCode === 'charity' ? 'charity' : 'starter'"
            :current-plan-code="planCode"
            :current-frequency="billingFrequency"
            :can-change-plan="false"
            @plan-changed="planChanged" />
          <pricing-box plan-code="pro" :current-plan-code="planCode" :current-frequency="billingFrequency" can-change-plan @plan-changed="planChanged" />
        </div>
        <div v-if="canChangePlan" class="border-bottom pb-3 px-4 text-center">
          We also offer an <b>Enterprise</b> plan for larger organisations, with priority support and bespoke customisation.<br />For further information,
          please visit our <external-link url="https://www.kinabase.com/pricing">pricing page</external-link> or contact our
          <a href="mailto:support@kinabase.com">account management team</a>.
          <br />
        </div>
        <div v-else class="pt-5 pb-4 text-center">
          <h4>You are on the {{ planCode }} plan.</h4>
          <p>To make changes, please contact our <a href="mailto:support@kinabase.com">account management team</a>.</p>
        </div>
        <div class="py-3 text-center">
          <small class="text-secondary"
            >All prices exclude tax. By signing up you agree to our
            <external-link url="https://www.kinabase.com/terms">terms and conditions</external-link>.</small
          >
        </div>
      </div>
    </div>
  </k-modal>

  <k-modal id="upgrade-modal-thanks" v-model:show="showThanksModal" title="Thank you">
    <div class="text-center">
      <i class="fa fa-check-circle fa-3x text-success my-4" />
      <h4>Thank you for upgrading your plan!</h4>
      <p>Your new plan is now active.</p>
    </div>
    <template #footer>
      <k-button label="Done" variant="success" @click="showThanksModal = false" />
    </template>
  </k-modal>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import KModal from "@ui/modal/KModal.vue";
import KButton from "@ui/button/KButton.vue";
import KToolbar from "@ui/toolbar/KToolbar.vue";

import PricingBox from "./PricingBox.vue";

import ExternalLink from "@/components/ExternalLink.vue";
import { useAuth, useSubscriptionStatus } from "@/services/auth";

defineProps<{
  /** Reason for the upgrade */
  reason?: string;
}>();

const { user } = useAuth();
const isBillingAdmin = computed(() => user.value?.role === "BILLING_ADMINISTRATOR" || user.value?.role === "ADMINISTRATOR");

const { billingFrequency, planCode, refresh } = useSubscriptionStatus();

const showModal = defineModel<boolean>();

const openHelp = () => {
  window.open("https://www.kinabase.com/support", "_blank");
};

const canChangePlan = computed(() => planCode.value !== "charity");

const showThanksModal = ref(false);

const planChanged = async () => {
  await refresh();
  showThanksModal.value = true;
  showModal.value = false;
};
</script>

<style scoped>
.pricing-modal {
  -webkit-user-select: none;
  user-select: none;
  border-radius: 4px;
}

.pricing-button-container {
  margin: 0 auto;
  padding: 3px;
  border-radius: 6px;
  border: 1px solid #1488a9;
  text-align: center;
}

.non-admin-screen {
  max-width: 400px;
  margin: 0 auto;
}
</style>

<style>
#upgrade-modal,
#upgrade-modal-thanks {
  z-index: 1000000;
}
</style>
