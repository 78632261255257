<template>
  <teleport to="body">
    <div :id ref="element" class="modal" :class="classes" tabindex="-1">
      <div class="modal-dialog" :class="modalDialogClasses" :style="modalStyle">
        <div v-if="show || delayedShow" class="modal-content">
          <div v-if="!hideHeader" class="modal-header">
            <h5 class="modal-title">
              <slot name="title">
                {{ title }}
              </slot>
            </h5>
            <button v-if="!hideCancelAndClose" type="button" class="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close" @click="$emit('cancel')" />
          </div>
          <div class="modal-body" :class="bodyClass">
            <slot></slot>
          </div>
          <div v-if="!hideFooter" class="modal-footer">
            <div class="flex-grow-1">
              <slot name="footer-left"></slot>
            </div>
            <slot name="footer">
              <k-button
                v-for="button in modalButtons"
                :key="button.label"
                :label="button.label"
                :icon-right="button.iconRight"
                :icon="button.icon"
                :variant="button.variant ?? inferColour(button.label)"
                :loading="button.loading"
                :disabled="button.disabled"
                @click="onButtonClicked(button)" />
            </slot>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watchEffect } from "vue";
import type { StyleValue } from "vue";

import { debouncedRef, useEventListener } from "@vueuse/core";
import { Modal } from "bootstrap";

import KButton from "@ui/button/KButton.vue";

import type { UIColourVariantWithOutlines } from "@data/types/ColourVariant";

import type { ModalButton } from "./ModalButtons";

const props = withDefaults(
  defineProps<{
    /** Whether the modal should be showing */
    show?: boolean;
    /** Grey backdrop behind modal - PROP ONLY WORKS IF STATIC BACKDROP IS ALSO FALSE */
    noBackdrop?: boolean;
    /** Whether the modal should be centered on screen */
    centered?: boolean;
    /** Whether the fade animation should be played (defaults to true) */
    fade?: boolean;
    /** ID for labelling purposes */
    id?: string;
    /** Title of the modal */
    title?: string;
    /** Whether the modal can be scrolled */
    scrollable?: boolean;
    /** Size of the modal */
    size?: "sm" | "md" | "lg" | "xl";
    /** Style for the modal dialog */
    modalStyle?: StyleValue;
    /** If static backdrop is false, the modal will close if clicked outside it */
    staticBackdrop?: boolean;
    /** Buttons to show on the modal */
    buttons?: (string | ModalButton)[];
    /** Whether the OK button should show a loading spinner */
    loading?: boolean;
    /** Whether the Cancel and close buttons should be hidden */
    hideCancelAndClose?: boolean;
    /** Whether the header should be shown */
    hideHeader?: boolean;
    /** Whether the footer should be shown */
    hideFooter?: boolean;
    /** Label for the OK button */
    okLabel?: string;
    /** CSS class for the modal body */
    bodyClass?: string;
  }>(),
  {
    buttons: undefined,
    okLabel: "OK",
    fade: true,
    size: "md",
    id: undefined,
    title: undefined,
    modalStyle: undefined,
    bodyClass: undefined
  }
);

const emit = defineEmits<{
  (e: "update:show", value: boolean): void;
  (e: "ok"): void;
  (e: "cancel"): void;
  (e: "button", label: string): void;
  (e: "show", value: boolean): void;
  (e: "shown", value: boolean): void;
  (e: "hidden", value: boolean): void;
  (e: "hide-prevented"): void;
}>();

const element = ref<HTMLElement>();
const instance = ref<Modal>();
const classes = computed(() => ({
  fade: props.fade
}));

const delayedShow = debouncedRef(
  computed(() => props.show),
  300
);

const modalDialogClasses = computed(() => ({
  [`modal-${props.size}`]: props.size,
  "modal-dialog-centered": props.centered,
  "modal-dialog-scrollable": props.scrollable
}));

const primary = new Set(["ok", "continue", "yes"]);
const inferColour = (label: string): UIColourVariantWithOutlines => {
  const lower = label.toLowerCase();
  if (primary.has(lower)) {
    return "primary";
  }
  return "secondary";
};

const updateModel = (isShown: boolean, toEmit: "show" | "shown" | "hidden" | "hide-prevented") => {
  switch (toEmit) {
    case "show":
      emit("show", isShown);
      break;
    case "shown":
      emit("shown", isShown);
      break;
    case "hidden":
      emit("hidden", isShown);
      break;
    case "hide-prevented":
      emit("hide-prevented");
      break;
  }
  emit("update:show", isShown);
};

const modalButtons = computed<ModalButton[]>(() => {
  if (props.buttons) {
    return props.buttons.map((item) => (typeof item === "string" ? { label: item } : item));
  }
  // fallback to old style
  if (props.hideFooter) {
    return [];
  }
  const buttons: ModalButton[] = [];
  if (!props.hideCancelAndClose) {
    buttons.push({ label: "Cancel" });
  }
  buttons.push({ label: props.okLabel, loading: props.loading });
  return buttons;
});

const onButtonClicked = (button: ModalButton) => {
  emit("button", button.label);
  if (button.label.toLowerCase() === "ok") {
    emit("ok");
  }
  if (button.label.toLowerCase() === "cancel") {
    emit("cancel");
    updateModel(false, "hidden");
  }
};

watchEffect(
  () => {
    useEventListener(element, "show.bs.modal", () => updateModel(true, "show"));
    useEventListener(element, "shown.bs.modal", () => updateModel(true, "shown"));
    useEventListener(element, "hidden.bs.modal", () => updateModel(false, "hidden"));
    useEventListener(element, "hidePrevented.bs.modal", () => emit("hide-prevented"));
  },
  { flush: "post" }
);

onMounted(() => {
  instance.value = new Modal(element.value as HTMLElement, {
    backdrop: props.staticBackdrop ? "static" : !props.noBackdrop,
    keyboard: !props.staticBackdrop,
    focus: false
  });
});

watchEffect(() => {
  if (instance.value) {
    if (props.show) {
      instance.value.show();
    } else {
      instance.value.hide();
    }
  }
});
</script>

<style scoped lang="scss">
.modal-title {
  flex-grow: 1;
  -webkit-user-select: none;
  user-select: none;
}
.modal-header .btn-close {
  background: transparent var(--k-modal-close-btn) center/1em auto no-repeat;
}
</style>
