import nlp from "compromise";

import { toPlural } from "@data/helpers/strings/Plurals";

const excludedResults = new Set(["tracks", "activities", "users", "collections", "items", "fields", "records", "forms", "people", "colleagues"]);

export const extractCollectionNames = (text: string) => {
  const doc = nlp(text.replace(/[,.:;]/g, ""));

  doc.remove("#Determiner");

  let result = [];

  // Handle possessives (e.g. cat's toys or his dog's bone) TODO: Make this often not just remove the first result.
  const possessives = doc.match("#Possessive+ #Noun").out("array") as string[];
  for (const noun of possessives) {
    const temp = nlp(noun.replace(/'s|'/, "")).nouns().lastTerms().out("array") as string[];
    if (temp.length) result.push(temp[0]);
  }

  result.push(...(doc.remove("#Possessive").match("#Noun").out("array") as string[]));

  // convert to plurals
  result = result.map((noun) => toPlural(noun)).sort((a, b) => a.localeCompare(b));

  // remove duplicates
  result = [...new Set(result)];
  result = result.filter((noun) => noun.length > 3 && !excludedResults.has(noun));

  return result;
};
