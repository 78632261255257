import { ParsingError } from "../expressions/expressions";

import { allUnits } from "./allUnits";
import { CombinedUnit } from "./units";

import type { MetricUnit } from "./units";

const symbolMap = new Map<string, MetricUnit>();

for (const unit of Object.values(allUnits)) {
  symbolMap.set(unit.symbol, unit);
  symbolMap.set(unit.expressionSymbol, unit);
  for (let superUnit = unit.superUnit; superUnit; superUnit = superUnit.superUnit) {
    symbolMap.set(superUnit.symbol, superUnit);
    symbolMap.set(superUnit.expressionSymbol, superUnit);
  }
  for (let subUnit = unit.subUnit; subUnit; subUnit = subUnit.subUnit) {
    symbolMap.set(subUnit.symbol, subUnit);
    symbolMap.set(subUnit.expressionSymbol, subUnit);
  }
}

const powerRegex = /(\d+)$/;
/**
 * Looks up the unit in allUnits, then falls back to creating a combined unit from symbols
 *
 * @example
 *   parseUnit("metre"); // length
 *
 * @example
 *   parseUnit("kg*m/s2"); // force
 */
export const parseUnit = (input: string): MetricUnit => {
  if (input in allUnits) {
    return allUnits[input as keyof typeof allUnits];
  }
  const divSplit = input.split("/");
  if (divSplit.length > 2) {
    throw new ParsingError(`Only one / allowed in unit ${input}`);
  }
  const units: { unit: MetricUnit; power: number }[] = [];
  for (const [part, m] of divSplit.map((s, idx) => [s, idx ? -1 : 1] as const)) {
    if (part) {
      const suffixes = part.split("*");
      units.push(
        ...suffixes.map((s) => {
          const power = s.match(powerRegex);
          const symbol = power ? s.replace(powerRegex, "") : s;
          const unit = symbolMap.get(symbol);
          if (!unit) {
            throw new ParsingError(`Unknown unit: ${symbol}`);
          }
          return { unit, power: power ? parseInt(power[1], 10) * m : m };
        })
      );
    }
  }
  if (units.length === 0) {
    throw new ParsingError("Empty metric unit");
  }
  if (units.length === 1 && units[0].power === 1) {
    return units[0].unit;
  }
  return new CombinedUnit(units);
};

export const quantityRegex = /^([^\d.-]*)(-?\d+(?:\.\d+)?(?:e[+-]?\d+)?)(.*)$/;

/**
 * Parses a quantity string into a value and unit - must not contain spaces, but may have a prefix like "£"
 *
 * @example
 *   parseQuantity("1.2m"); // { value: 1.2, unit: length }
 *
 * @example
 *   parseQuantity("1.2m/s"); // { value: 1.2, unit: velocity }
 *
 * @example
 *   parseQuantity("£7.5/m2"); // { value: 7.5, unit: price per area }
 */
export const parseQuantity = (quantity: string): { value: number; unit?: MetricUnit } => {
  const match = quantity.match(quantityRegex);
  if (!match) {
    throw new Error(`Invalid quantity: ${quantity}`);
  }
  const [, prefix, value, suffix] = match;
  let effectiveUnit: string;
  if (prefix && suffix) {
    if (suffix.startsWith("/")) {
      effectiveUnit = `${prefix}${suffix}`;
    } else {
      effectiveUnit = `${prefix}*${suffix}`;
    }
  } else {
    effectiveUnit = prefix || suffix;
  }
  if (!effectiveUnit) {
    return { value: parseFloat(value) };
  }
  const unit = parseUnit(effectiveUnit);
  return { value: unit.convertFrom(parseFloat(value)), unit: parseUnit(effectiveUnit) };
};
