<template>
  <div ref="container" class="form-control-container">
    <template v-if="smallMode">
      <k-label v-if="label && !config.hideLabel" :id :label />
      <k-dropdown :id>
        <template #button>
          <k-badge :variant="selected" :label="colourLabels.get(selected) ?? 'None'" class="mb-n1" />
        </template>
        <k-dropdown-item v-for="col in colours" :key="col" :active="col === selected" @click="selectColour(col)"
          ><k-badge :label="colourLabels.get(col) ?? 'None'" :variant="col" class="dropdown-badge mb-n1"
        /></k-dropdown-item>
        <k-dropdown-item :active="!selected" @click="selectColour(undefined)"
          ><k-badge label="None" variant="gray" class="dropdown-badge mb-n1"
        /></k-dropdown-item>
      </k-dropdown>
    </template>
    <template v-else>
      <k-label v-if="label" :label />
      <button v-for="col in colours" :key="col" :title="colourLabels.get(col)" class="badge-button pt-1 px-2" @click="selectColour(col)">
        <k-badge
          :label="colourLabels.get(col) ?? 'None'"
          :variant="col"
          :style="selected === col ? { outline: `2px solid var(--k-color-l-15-${col})`, outlineOffset: '3px' } : undefined" />
      </button>
      <button v-if="!allowedColours" title="None" class="badge-button pt-1 px-2" @click="selectColour(undefined)">
        <k-badge label="None" variant="gray" :style="!selected ? { outline: `2px solid var(--k-color-l-15-gray)`, outlineOffset: '3px' } : undefined" />
      </button>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

import { useElementSize } from "@vueuse/core";
import { v4 } from "uuid";

import KBadge from "@ui/badge/KBadge.vue";
import KDropdown from "@ui/dropdown/KDropdown.vue";
import KDropdownItem from "@ui/dropdown/KDropdownItem.vue";
import KLabel from "@ui/label/KLabel.vue";
import { useInputConfig } from "@ui/inputs/inputConfig";

import { inputColours } from "./InputColours";

import { capitalise } from "@data/helpers/strings/Casing";
import type { ColourVariant } from "@data/types/ColourVariant";

const props = defineProps<{
  /** Label for the input */
  label?: string;
  /** Input configuration */
  allowedColours?: ColourVariant[];
  /** Whether to display as dropdown */
  displayAsDropdown?: boolean;
}>();

const selected = defineModel<ColourVariant>();
const config = useInputConfig();
const colours = computed<ColourVariant[]>(() => props.allowedColours ?? inputColours);
const colourLabels = computed(() => new Map<ColourVariant | undefined, string>(colours.value.map((col) => [col, capitalise(col)])));

const container = ref();
const { width } = useElementSize(container);
const smallMode = computed(() => props.displayAsDropdown || width.value < 300);
const id = v4();

const selectColour = (col: ColourVariant | undefined) => {
  selected.value = col === selected.value ? undefined : col;
};
</script>

<style scoped lang="scss">
.badge-button {
  border: none;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;

  span {
    min-width: 60px;
    text-align: center;
    box-sizing: border-box;
    &:hover {
      outline: 2px solid var(--k-color-l-15-secondary);
      outline-offset: 3px;
    }
  }
}

.dropdown-badge {
  width: 100%;
  text-align: center;
}
</style>
