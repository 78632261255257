import { joinChainedConditions, splitAnd } from "../../expressions/helpers";
import { deepEquals } from "../manipulation/Comparison";
import { PeopleField, PersonField } from "../../fields/relational/PeopleFields";

import type { ChainedConditions } from "../../expressions/helpers";
import type { Collection } from "../../data/Collection";
import type { KRecord } from "../../data/Record";

export type PermissionInfo =
  | { type: "everyone" }
  | { type: "role"; roles: string[] }
  | { type: "person"; fieldIds: string[]; roles: string[] }
  | { type: "custom"; split: ChainedConditions }
  | { type: "nobody" };

const rolegex = /^hasRole\("(.*)"\)$/;
const ownergex = /^record\.([\dA-Za-z-]+)\.id matches personId\(\)$/;
const multiOwnergex = /^record\.([\dA-Za-z-]+)\.id intersects \[personId\(\)]$/;

export const getPermissionInfo = (permissions: string[]): PermissionInfo => {
  if (!permissions.length) {
    return { type: "nobody" };
  }
  if (deepEquals(permissions, ["true"])) {
    return { type: "everyone" };
  }
  const roles: string[] = [];
  const ownerFields: string[] = [];
  for (const permission of permissions) {
    const roleMatch = permission.match(rolegex);
    const ownerMatch = permission.match(ownergex) ?? permission.match(multiOwnergex);
    if (roleMatch) {
      roles.push(roleMatch[1]);
    } else if (ownerMatch) {
      ownerFields.push(ownerMatch[1]);
    } else {
      return {
        type: "custom",
        split: {
          or: permissions.map((exp) => ({ and: splitAnd(exp) }))
        }
      };
    }
  }
  if (!ownerFields.length) {
    return { type: "role", roles };
  }
  return { type: "person", fieldIds: ownerFields, roles };
};

type FromNormalPermission =
  | "add" // write permission, but for empty records only
  | "config"
  | "import"
  | "bulkDelete"
  | "listVisibility"
  | "export";
type FromEffectivePermission = "activityRead" | "activityWrite" | "taskRead" | "taskWrite" | "fileRead" | "fileWrite" | "read" | "write";

export type PermissionsKey = FromNormalPermission | FromEffectivePermission;

const getFromEffectivePermission = (effectivePermissions: ReturnType<Collection["getEffectivePermissions"]>, permission: FromEffectivePermission) => {
  switch (permission) {
    case "activityRead":
      return effectivePermissions.activities?.read ?? effectivePermissions.read;
    case "activityWrite":
      return effectivePermissions.activities?.write ?? effectivePermissions.write;
    case "taskRead":
      return effectivePermissions.tasks?.read ?? effectivePermissions.read;
    case "taskWrite":
      return effectivePermissions.tasks?.write ?? effectivePermissions.write;
    case "fileRead":
      return effectivePermissions.files?.read ?? effectivePermissions.read;
    case "fileWrite":
      return effectivePermissions.files?.write ?? effectivePermissions.write;
    default:
      return effectivePermissions[permission];
  }
};

const adjustForPersonFilters = (collection: Collection, permissions: string[]) =>
  // idea: if a permission is for a person field with filters, we can swap it out for the person filters
  permissions.map((p) => {
    const ownerMatch = p.match(ownergex) ?? p.match(multiOwnergex);
    if (!ownerMatch) return p;
    const fieldId = ownerMatch[1];
    const field = collection.getField(fieldId);
    const filters = field instanceof PersonField || field instanceof PeopleField ? field.filters : undefined;
    if (!filters?.expressions.length) return p;
    return joinChainedConditions({ or: [{ and: filters.expressions }] });
  });
const switchCollectionPermissions = (collection: Collection, permission: PermissionsKey, record?: KRecord) => {
  switch (permission) {
    case "config":
      return collection.permissions.configure;
    case "import":
    case "bulkDelete":
      return collection.permissions.bulk?.[permission] ?? collection.permissions.write;
    case "export":
      return collection.permissions.bulk?.export ?? collection.permissions.read;
    case "listVisibility":
      return collection.permissions.listVisibility ?? collection.permissions.read;
    case "add":
      return collection.getEffectivePermissions({}).write;
  }
  const effectivePermissions = collection.getEffectivePermissions(record);
  return getFromEffectivePermission(effectivePermissions, permission);
};

export const getCollectionPermissions = (collection: Collection, permission: PermissionsKey, record?: KRecord) => {
  const permissions = switchCollectionPermissions(collection, permission, record);
  return adjustForPersonFilters(collection, permissions);
};

export type FilterablePermission = Extract<PermissionsKey, "read" | "export" | "import" | "config" | "write">;
