<template>
  <template v-if="commonCollectionOptions.length">
    <k-input-config description="Filters which people to show in this field">
      <k-input-select v-model="commonCollection" label="Filter by common collections" :options="commonCollectionOptions" null-label="No filter" />
    </k-input-config>
  </template>
  <filter-config
    v-model="customFilters"
    :description="!commonCollectionOptions.length ? 'Filters which people to show in this field' : undefined"
    :entity="personEntity"
    label="Custom Filter" />
</template>

<script setup lang="ts">
import { computed } from "vue";

import KInputSelect from "@ui/inputs/select/KInputSelect.vue";
import KInputConfig from "@ui/inputs/KInputConfig.vue";

import FilterConfig from "./FilterConfig.vue";
import { useRelationalFilterLogic } from "./relationalFilterLogic";

import type { Collection } from "@data/data/Collection";
import type { RelationalFilters } from "@data/fields/relational/RecordFields";
import { getRelationalFields } from "@data/fields/relational/RecordFields";

import { useKContext } from "@/expressions/context";
import { useInitialState } from "@/api/initial";

const props = defineProps<{
  /** Collection to configure relational filters for */
  collection?: Collection;
}>();

const modelValue = defineModel<RelationalFilters>();

const context = useKContext();
// load the collection from the context if not provided (and need different name to avoid conflict with prop)
const actualCollection = computed(() => props.collection ?? context.value.entity);

const { personEntity } = useInitialState();

const collectionRelationals = computed(() => (actualCollection.value ? getRelationalFields(actualCollection.value) : []));
const personRelationals = computed(() => getRelationalFields(personEntity.value));

const { commonCollectionOptions, commonCollection, customFilters } = useRelationalFilterLogic(modelValue, collectionRelationals, personRelationals);
</script>
