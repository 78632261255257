import { BulkData } from "./data";
import { BooleanExpression, DataExpression, DatetimeExpression, DurationExpression, NumberExpression, StringExpression } from "./expressions";

import type { KContext } from "./context";
import type { SingleExpression } from "./expressions";
import type { DatetimePrecision } from "../fields/basic/DatetimeField";
import type { MetricUnit } from "../units/units";
import type { Dayjs } from "dayjs";

export class NumberConstant extends NumberExpression {
  constructor(
    private readonly value: number | undefined,
    unit?: MetricUnit,
    private readonly displayAs?: string
  ) {
    super();
    this.unit = unit;
  }

  evaluate() {
    return this.value;
  }

  display(): string {
    if (this.displayAs) {
      return this.displayAs;
    }
    if (this.value !== undefined) {
      return this.unit?.display(this.value, { convert: true }) ?? this.value.toLocaleString();
    }
    return "null";
  }
}

export const mathsConstants = new Map<string, NumberExpression>([
  ["pi", new NumberConstant(Math.PI, undefined, "π")],
  ["e", new NumberConstant(Math.E, undefined, "e")],
  ["tau", new NumberConstant(Math.PI * 2, undefined, "τ")] // got to have the correct circle constant
]);

export class StringConstant extends StringExpression {
  constructor(private readonly value: string | undefined) {
    super();
  }

  evaluate() {
    return this.value;
  }

  display(): string {
    return this.value ? `"${this.value}"` : "null";
  }
}

export class RoleConstant extends StringExpression {
  constructor(private roleId: string) {
    super();
  }

  evaluate(context: KContext): string | undefined {
    return context.roleLabels?.get(this.roleId);
  }

  display(content: KContext): string {
    return content.roleLabels?.get(this.roleId) ?? "Unknown Role";
  }
}

export class BooleanConstant extends BooleanExpression {
  constructor(private readonly value: boolean | undefined) {
    super();
  }

  evaluate() {
    return this.value;
  }

  display(): string {
    switch (this.value) {
      case true:
        return "true";
      case false:
        return "false";
      default:
        return "null";
    }
  }
}

export class DatetimeConstant extends DatetimeExpression {
  constructor(
    private readonly value: Dayjs | undefined,
    precision: DatetimePrecision
  ) {
    super(precision);
  }

  evaluate() {
    return this.value;
  }

  display(): string {
    if (!this.value) {
      return "null";
    }
    switch (this.precision) {
      case "year":
        return this.value.format("YYYY");
      case "quarter":
        return this.value.format("YYYY [Q]Q");
      case "month":
        return this.value.format("MMM YYYY");
      case "day":
        return this.value.format("DD/MM/YYYY");
      case "hour":
        return this.value.local().format("DD/MM/YYYY HH:mm");
      case "minute":
        return this.value.local().format("DD/MM/YYYY HH:mm");
    }
  }
}

const SingleConstants = [NumberConstant, StringConstant, BooleanConstant, DatetimeConstant];
export class DataConstant extends DataExpression {
  private constantValue: ReturnType<DataExpression["evaluate"]>;

  constructor(private readonly elements: SingleExpression[] | undefined) {
    super();
    // check if all elements are constants
    if (elements?.every((element) => SingleConstants.some((c) => element instanceof c))) {
      this.constantValue = new BulkData(elements.map((element) => element.evaluate({})));
    }
  }

  /** Helper function for quickly creating constants in unit tests */
  static fromRawData(data: (string | number | boolean | Dayjs)[]): DataConstant {
    return new DataConstant(
      data.map((d) => {
        switch (typeof d) {
          case "string":
            return new StringConstant(d);
          case "number":
            return new NumberConstant(d);
          case "boolean":
            return new BooleanConstant(d);
          case "object":
            return new DatetimeConstant(d, "minute");
        }
      })
    );
  }

  evaluate(context: KContext) {
    if (!this.elements) {
      return undefined;
    }
    if (this.constantValue) {
      return this.constantValue;
    }
    const values = this.elements.map((element) => element.evaluate(context));
    return new BulkData(values);
  }

  display(context: KContext) {
    if (!this.elements) {
      return "null";
    }
    return `(${this.elements.map((element) => element.display(context)).join(", ")})`;
  }
}

export class DurationConstant extends DurationExpression {
  constructor(
    private readonly value: number | undefined,
    public readonly precision: DatetimePrecision
  ) {
    super();
  }

  evaluate() {
    return this.value;
  }

  display(): string {
    if (this.value !== undefined) {
      return `${this.value} ${this.precision}`;
    }
    return "null";
  }
}
