import { KField } from "../KField";

import type { KRecord, StoredKRecord } from "../../data/Record";
import type { KEntity } from "../../data/KEntity";
import type { KFieldSetup } from "../KField";

export class ObjectField<R extends KRecord = KRecord> extends KField<R> {
  readonly type = "object";

  constructor(
    setup: KFieldSetup | string,
    public entity: KEntity
  ) {
    super(setup);
  }

  getValue(record: KRecord): R | undefined {
    return record[this.key] as R | undefined;
  }

  private getRecordValue(record: KRecord): KRecord | undefined {
    const value = this.getValue(record);
    return value ? { ...this.getValue(record), id: NaN } : undefined;
  }

  getSortableValue(record: KRecord) {
    const recordValue = this.getRecordValue(record);
    if (!recordValue) return undefined;
    return this.entity.sortField.getSortableValue(recordValue);
  }

  getDisplayValue(record: KRecord): string {
    const recordValue = this.getRecordValue(record);
    if (!recordValue) return "";
    return this.entity.primaryField.getDisplayValue(recordValue);
  }

  validateType(value: unknown): value is R {
    return !!value && typeof value === "object" && this.entity.validateRecordType(value as KRecord);
  }

  storeValue(source: KRecord, target: StoredKRecord): void {
    const value = this.getValue(source);
    if (value) {
      target[this.key] = this.entity.storeRecord(value);
    }
  }

  restoreValue(source: StoredKRecord, target: KRecord): void {
    const value = source[this.key] as StoredKRecord | undefined;
    if (value) {
      const restored = this.entity.restoreRecord(value);
      delete restored.id;
      target[this.key] = restored;
    }
  }
}
