import { computed, ref, watchEffect } from "vue";

import { useDebounce } from "@vueuse/core";

import type { ReadonlyRef } from "@ui/helpers/refHelpers";

import { getRecordOptionFilters } from "./generateFilter";

import type { KRecordOption, RelationalFilters } from "@data/fields/relational/RecordFields";
import { deepEquals } from "@data/helpers/manipulation/Comparison";
import type { Collection } from "@data/data/Collection";
import type { KContext } from "@data/expressions/context";

import { useRecordOptions } from "@/helpers/collection";
import { isUnitTest } from "@/tests/helpers/testFlag";

export const useSearchedRecordOptions = ({
  collection,
  propFilters,
  context,
  selected,
  optionFn
}: {
  collection: ReadonlyRef<Collection | undefined>;
  propFilters?: ReadonlyRef<RelationalFilters | undefined>;
  context: ReadonlyRef<KContext>;
  selected: ReadonlyRef<KRecordOption[]>;
  optionFn?: typeof useRecordOptions;
}) => {
  optionFn ??= useRecordOptions;
  const filters = computed(() => (collection.value ? getRecordOptionFilters(propFilters?.value, context.value, collection.value) : []));

  const search = ref("");
  const debouncedSearch = ref("");
  const fullResult = ref(false);
  const recordOptions = optionFn(collection, filters, debouncedSearch);

  watchEffect(() => {
    if (!recordOptions.loading.value && !debouncedSearch.value) {
      fullResult.value = !recordOptions.hasMore.value;
    }
  });

  const shouldChangeSearch = computed(() => {
    if (fullResult.value) {
      return false;
    }
    // if we're adding more characters to an already-empty search, we don't need to re-search
    return !search.value.startsWith(debouncedSearch.value) || recordOptions.options.value.length;
  });

  const searchLoading = computed(() => shouldChangeSearch.value && debouncedSearch.value !== search.value);

  watchEffect(() => {
    if (!recordOptions.loading.value && shouldChangeSearch.value) {
      debouncedSearch.value = search.value;
    }
  });

  const selectedMap = computed(() => new Map(selected.value.map((r) => [r.id, r])));

  // ensure that record options are the same object as the input value
  const options = computed<KRecordOption[]>(() =>
    selectedMap.value.size
      ? recordOptions.options.value.map((o) => {
          const matching = selectedMap.value.get(o.id);
          return matching && deepEquals(matching, o) ? matching : o;
        })
      : recordOptions.options.value
  );
  const loading = computed(() => searchLoading.value || recordOptions.loading.value);
  const debouncedLoading = useDebounce(loading, 100);
  const hasMore = computed(() => !fullResult.value && recordOptions.hasMore.value);
  return { loading: isUnitTest ? loading : debouncedLoading, options, search, fullResult, hasMore };
};
