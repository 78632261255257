import { computed } from "vue";
import type { ComputedRef } from "vue";

import type { ReadonlyRef } from "@ui/helpers/refHelpers";

import type { Collection } from "@data/data/Collection";
import { PersonField, PeopleField } from "@data/fields/relational/PeopleFields";
import { getPermissionInfo } from "@data/helpers/data/Permissions";

import { usePeopleStore } from "@/api/stores/usePeopleStore";

import type { PrefilledField } from "@/components/form/FormTypes";

/**
 * Returns a list of fields that should be prefilled in forms based on the collection's write permissions.
 *
 * @param collection Collection to check
 * @returns List of fields to prefill (in KEntityForm's prop format)
 */
export const usePermissionPrefilledFields = (collection: ReadonlyRef<Collection>): ComputedRef<PrefilledField[]> => {
  const addPermissions = computed(() => collection.value.getEffectivePermissions({}).write);
  const writePermissionInfo = computed(() => getPermissionInfo(addPermissions.value));
  const peopleStore = usePeopleStore();
  const currentPerson = peopleStore.useCurrentPerson();
  return computed(() => {
    const p = currentPerson.value;
    const info = writePermissionInfo.value;
    if (info.type === "person" && p && !p.roles?.some((r) => info.roles.includes(r)) && info.fieldIds.length) {
      const targetFieldId = info.fieldIds[0];
      const targetField = collection.value.getField(targetFieldId);
      if (targetField instanceof PersonField) {
        return [{ key: targetField.key, value: { id: p.id, label: p.name }, mode: "hidden" }];
      } else if (targetField instanceof PeopleField) {
        return [{ key: targetField.key, value: [{ id: p.id, label: p.name }], mode: "editable" }];
      }
    }
    return [];
  });
};
