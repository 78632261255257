import type { ReadonlyRef } from "@ui/helpers/refHelpers";

import type { CollectionIdConfig, Collection } from "@data/data/Collection";
import { IdField } from "@data/fields/utility/IdField";
import type { ConditionalValidator } from "@data/validation/ConditionalValidation";

import { CollectionAPI } from "@/api/collection";
import { useUpdateCollectionValidators } from "@/api/validators";

export const useConfigMethods = (collectionMap: ReadonlyRef<Map<string, Collection> | undefined>) => {
  const { mutate: updateIdConfigMutation } = CollectionAPI.useUpdateCollectionIdConfig();

  const updateIdConfig = async (collectionId: string, nextId?: number, config?: CollectionIdConfig) => {
    const result = await updateIdConfigMutation({ value: { collectionId, nextId, format: config } });
    const success = result?.data?.updateCollectionIdConfig;
    if (success) {
      const collection = collectionMap.value?.get(collectionId);
      if (collection) {
        collection.nextId = nextId;
        collection.idFormatConfig = config;
        if (config) {
          const idField = collection.getField("id", IdField);
          if (idField) {
            idField.format = config;
          }
        }
        return true;
      } else {
        throw new Error(`Couldn't update local state: ID ${collectionId} not found.`);
      }
    }
  };

  const { mutate: updateUniqueFieldsMutation } = CollectionAPI.useUpdateCollectionUniqueFields();

  const updateUniqueFields = async (collectionId: string, uniqueFieldRestrictions: Collection["uniqueFieldRestrictions"]) => {
    const result = await updateUniqueFieldsMutation({ collectionId, uniqueFieldRestrictions });
    const success = result?.data?.updateCollectionUniqueFieldRestrictions;
    if (success) {
      const collection = collectionMap.value?.get(collectionId);
      if (collection) {
        collection.uniqueFieldRestrictions = uniqueFieldRestrictions;
        return true;
      } else {
        throw new Error(`Couldn't update local state: ID ${collectionId} not found.`);
      }
    }
  };

  const { mutate: updateValidatorsMutation } = useUpdateCollectionValidators();

  const updateValidators = async (collectionId: string, validators: (ConditionalValidator & { __typename?: "ConditionalValidator" })[]) => {
    // strip out the __typename
    validators = validators.map((v) => {
      const { __typename, ...rest } = v;
      return rest;
    });
    const result = await updateValidatorsMutation({ value: { collectionId, validators } });
    const success = result?.data?.updateCollectionValidators;
    if (success) {
      const collection = collectionMap.value?.get(collectionId);
      if (collection) {
        collection.validators = validators;
        return true;
      } else {
        throw new Error(`Couldn't update local state: ID ${collectionId} not found.`);
      }
    }
  };

  const { mutate: updatePermissionsMutation } = CollectionAPI.useUpdateCollectionPermissions();

  const updatePermissions = async (collectionId: string, permissions: Collection["permissions"]) => {
    const result = await updatePermissionsMutation({ input: { collectionId, permissions } });
    const success = result?.data?.updateCollectionPermissions;
    if (success) {
      const collection = collectionMap.value?.get(collectionId);
      if (collection) {
        collection.permissions = permissions;
        return true;
      } else {
        throw new Error(`Couldn't update local state: ID ${collectionId} not found.`);
      }
    }
  };

  const { mutate: bulkUpdateCollectionPermissions } = CollectionAPI.useBulkUpdateCollectionPermissions();

  const bulkUpdatePermissions = async (inputs: { collectionId: string; permissions: Collection["permissions"] }[]) => {
    const result = await bulkUpdateCollectionPermissions({ inputs });
    const success = result?.data?.bulkUpdateCollectionPermissions;
    if (success) {
      for (const input of inputs) {
        const collection = collectionMap.value?.get(input.collectionId);
        if (collection) {
          collection.permissions = input.permissions;
        } else {
          throw new Error(`Couldn't update local state: ID ${input.collectionId} not found.`);
        }
      }
    }
  };
  return {
    updateIdConfig,
    updateValidators,
    updateUniqueFields,
    updatePermissions,
    bulkUpdatePermissions
  };
};
