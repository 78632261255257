<template>
  <div class="form-control-container">
    <k-label :label="label" :description="description" />
    <filter-box v-model="filters" :entity="props.entity" class="ms-n2" />
  </div>
</template>

<script setup lang="ts">
import KLabel from "@ui/label/KLabel.vue";

import type { KEntity } from "@data/data/KEntity";

import FilterBox from "@/components/entity/table/FilterBox.vue";

const props = defineProps<{
  /** Entity to make filters for */
  entity: KEntity;
  /** Label for the filter input */
  label?: string;
  /** Description for the filter input */
  description?: string;
}>();

const filters = defineModel<string[]>({
  required: true
});
</script>
