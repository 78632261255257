<template>
  <k-display-phone :id :value :label @call-initiated="onCallInitiated" />
  <activity-form v-model:show="showActivityModal" :collection :record-id="recordId" default-type="phone" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import KDisplayPhone from "@ui/displays/KDisplayPhone.vue";

import type { PhoneNumber } from "@data/constants/countries/phone";
import type { Collection } from "@data/data/Collection";

import ActivityForm from "@/pages/collections/records/activities/ActivityForm.vue";

const props = defineProps<{
  /** ID of the display for labelling purposes */
  id: string;
  /** Phone number to display */
  value?: PhoneNumber;
  /** Link text to display */
  label?: string;
  /** Current collection */
  collection: Collection;
  /** Current record ID */
  recordId: string;
}>();
const hasPhoneActivity = computed(() => {
  if (props.collection.activities?.isEnabled) {
    return props.collection.activities.types?.some((t) => t.key === "phone" && t.isEnabled) !== false;
  }
  return false;
});

const showActivityModal = ref(false);

const onCallInitiated = () => {
  if (hasPhoneActivity.value) {
    showActivityModal.value = true;
  }
};
</script>
