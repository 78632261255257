<template>
  <k-input-multiselect v-model="input" :label :options="multiOptions ?? []" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import KInputMultiselect from "@ui/inputs/select/KInputMultiselect.vue";
import type { MultiselectItem } from "@ui/inputs/select/SelectItem";

import { useFilteredPeopleOptions } from "./generateFilter";

import type { KRecordOption, RelationalFilters } from "@data/fields/relational/RecordFields";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: KRecordOption[];
  /** Label for the input */
  label?: string;
  /** Filters to use */
  filters?: RelationalFilters;
}>();
const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", newValue: KRecordOption[] | undefined): void;
}>();

const input = ref<string[]>([]);
// tracks the last input so that it always appears in the options
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const previousValue = ref(props.modelValue);

const options = useFilteredPeopleOptions(computed(() => props.filters));

// include current value so that input isn't blank with deleted value
// and remove forbidden option
const fullOptions = computed(() => {
  const result = [...options.value];
  for (const lr of previousValue.value ?? []) {
    if (!options.value.some((o) => o.id === lr.id)) {
      result.push(lr);
    }
  }
  return result;
});

const multiOptions = computed<MultiselectItem[]>(() =>
  fullOptions.value.map((o) => ({ value: o.id.toString(), label: o.label, secondaryLabel: o.secondaryLabel }))
);

watch(
  () => props.modelValue,
  (newValue) => {
    const newInput = newValue?.map((r) => r.id.toString());
    if (newInput?.length !== input.value.length || !input.value.every((i, idx) => i === newInput[idx])) {
      input.value = newInput ?? [];
      previousValue.value = newValue;
    }
  },
  { immediate: true }
);
watch(
  input,
  (newValues) => {
    emit("update:modelValue", newValues.map((id) => fullOptions.value.find((o) => o.id === id)).filter((o) => o) as KRecordOption[]);
  },
  { deep: true }
);
</script>
