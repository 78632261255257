<template>
  <div class="d-block">
    <div class="d-flex p-0 ps-2 w-100 flex-wrap filter-container">
      <div v-for="(filter, i) in displayFilters" :key="i" class="filter d-flex p-0">
        <div class="px-2 py-0">{{ filter }}</div>
        <button class="filter-remove-btn" title="Remove filter" @click="deleteFilter(i)"><i class="fa fa-fw fa-close"></i></button>
      </div>
      <condition-builder :entity @complete="addFilter" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import ConditionBuilder from "./ConditionBuilder.vue";

import type { KEntity } from "@data/data/KEntity";
import { expressionParser } from "@data/expressions/parser";

import { useKContext } from "@/expressions/context";

const props = defineProps<{
  /** Entity to make filters for */
  entity: KEntity;
}>();

const modelValue = defineModel<readonly string[]>({ required: true });

const context = useKContext(computed(() => ({ entity: props.entity })));

const displayFilters = computed(() => modelValue.value.map((f) => expressionParser.parseBoolean(f, context.value).display(context.value)));

const addFilter = (newFilter: string) => {
  modelValue.value = [...modelValue.value, newFilter];
};

const deleteFilter = (index: number) => {
  if (index > -1) {
    modelValue.value = modelValue.value.filter((_, i) => i !== index);
  }
};
</script>

<style scoped lang="scss">
.filter-container {
  gap: 0.25rem 0.5rem;
}

.filter {
  border: 1px solid var(--k-border);
  border-radius: 5px;
  font-size: 0.9em;
  height: 30px;
  line-height: 28px;
  white-space: nowrap;
}

.filter-remove-btn {
  background-color: transparent;
  padding: 0 4px;
  color: var(--k-color-secondary);
  border: none;
}

.filter-remove-btn:hover {
  background: var(--k-navbar-item-hover-background);
}
</style>
