<template>
  <collection-form
    :collection-name="collectionName"
    :collection
    button-type="DONE"
    :loading
    @edit-property="editProperty"
    @add-field="addField"
    @edit-field="editField"
    @delete-field="deleteField"
    @toggle-activities="toggleFeature('activities')"
    @toggle-tasks="toggleFeature('tasks')"
    @toggle-files="toggleFeature('files')"
    @done="saveCollection" />
</template>

<script setup lang="ts">
import { inject, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { v4 as uuidv4 } from "uuid";

import CollectionForm from "./CollectionForm.vue";
import { navbarGroupKey } from "./navbarInfo";

import { Collection } from "@data/data/Collection";
import type { KField } from "@data/fields/KField";
import { toSlug } from "@data/helpers/strings/Casing";
import { toSingular, toPlural } from "@data/helpers/strings/Plurals";

import { toTitleCase } from "@/helpers/useCollectionNameVariants";
import { useCollectionStore } from "@/api/stores/useCollectionStore";

const props = defineProps<{
  /** User search string */
  prompt?: string;
}>();

const emit = defineEmits<{
  /** Emitted when the modal is closed */
  (event: "close"): void;
}>();

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const collection = ref<Collection>(new Collection(uuidv4(), toSingular(props.prompt ?? ""), []));
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const collectionName = ref(props.prompt ?? collection.value.plural);

watch(
  () => props.prompt,
  () => {
    collection.value = new Collection(uuidv4(), toSingular(props.prompt ?? ""), []);
    collectionName.value = props.prompt ?? collection.value.plural;
  }
);

const editProperty = (property: "name" | "description" | "icon", newString: string) => {
  if (property === "name") {
    collectionName.value = newString;
    collection.value.singular = toTitleCase(toSingular(newString));
    collection.value.plural = toTitleCase(toPlural(newString));
    collection.value.slug = toSlug(collection.value.plural);
  } else {
    collection.value[property] = newString;
  }
};

const addField = (field: KField) => {
  collection.value.fields.push(field);
};

const editField = (oldField: KField, newField: KField) => {
  const index = collection.value.fields.indexOf(oldField);
  if (index > -1) {
    collection.value.fields[index] = newField;
  }
};

const deleteField = (field: KField) => {
  const index = collection.value.fields.indexOf(field);
  if (index > -1) {
    collection.value.fields.splice(index, 1);
  }
};

const toggleFeature = (feature: "tasks" | "activities" | "files") => {
  if (!collection.value[feature]) {
    collection.value[feature] = { isEnabled: true };
    return;
  }
  collection.value[feature].isEnabled = !collection.value[feature].isEnabled;
};

const loading = ref(false);
const router = useRouter();
const navbarGroup = inject(navbarGroupKey, ref(undefined));
const collectionStore = useCollectionStore();

const saveCollection = async () => {
  loading.value = true;
  try {
    const collectionId = await collectionStore.addCollection(
      {
        displayNameSingular: collection.value.singular,
        displayNamePlural: collection.value.plural,
        slug: collection.value.slug,
        icon: collection.value.icon,
        description: collection.value.description,
        enableActivities: collection.value.activities?.isEnabled ?? false,
        enableTasks: collection.value.tasks?.isEnabled ?? false,
        enableFiles: collection.value.files?.isEnabled ?? false,
        navbarGroup: navbarGroup.value?.label
      },
      collection.value.fields
    );

    if (!collectionId) {
      throw new Error("Failed to create collection");
    }

    await router.push(`/c/${collection.value.slug}`);
    emit("close");
  } catch (err) {
    console.error(err);
  } finally {
    loading.value = false;
  }
  loading.value = false;
};
</script>
