<template>
  <div class="collection-form d-flex">
    <div class="stages flex-shrink-0 py-3 ps-3">
      <ul>
        <li
          v-for="(pageTitle, step) in pageTitlesInOrder"
          :key="step"
          :class="{ active: step === currentStep, clickable: !isDoneButton }"
          @click="!isDoneButton ? changeStep(step as Step) : undefined">
          {{ pageTitle }}
        </li>
      </ul>
    </div>
    <div class="p-3 mt-1 wizard-content">
      <div v-if="currentStep === Step.DETAILS">
        <h5>{{ pageTitlesInOrder[0] }}</h5>
        <p class="mb-2">Add collections to store information about the things you want to keep track of.</p>
        <div class="d-flex mt-4 mb-3">
          <div class="pe-2">
            <k-label label="Icon" />
            <k-icon-picker :model-value="collection.icon" @update:model-value="(newIcon: string) => $emit('editProperty', 'icon', newIcon)" />
          </div>
          <div class="w-100">
            <k-input-config placeholder="Collection Name" :status="nameVariants.issues.length > 0 ? 'INVALID' : 'NONE'">
              <k-input ref="nameInputEl" v-model="collectionName" label="Name" />
            </k-input-config>
            <k-issue-display :issues="nameVariants.issues" />
            <small class="d-block mt-2 text-secondary">
              Enter a short name describing what you'd like to track. This should be a noun, such as "companies" or "orders".
            </small>
          </div>
        </div>
        <div class="w-100 mt-4">
          <k-input-config description="Describe what this collection is used for. This is used to help other users learn how your system works.">
            <k-input :model-value="collection.description" label="Description" @update:model-value="onUpdateDescription" />
          </k-input-config>
        </div>

        <div class="pt-4 mt-2">
          <k-label label="Preview" />
          <p>
            This collection can be found at:
            <code>
              https://app.kinabase.com/c/<span>{{ nameVariants.slug }}</span>
            </code>
          </p>
          <p v-if="nameVariants.displayNameSingular">
            Kinabase will refer to each record in this collection as {{ nameVariants.indefiniteArticle }}
            <strong class="text-primary">{{ nameVariants.displayNameSingular?.toLowerCase() }}</strong
            >. This collection will contain a list of one or more <strong class="text-primary">{{ nameVariants.displayNamePlural?.toLowerCase() }}</strong
            >.
          </p>
        </div>
      </div>
      <div v-if="currentStep === Step.FIELDS" class="position-relative pb-3">
        <h5>{{ pageTitlesInOrder[1] }}</h5>
        <div>
          <p class="mb-2">
            Create fields to store information in each record. Each field is like a neatly labeled box that stores a particular detail about
            {{ nameVariants.indefiniteArticle }} {{ nameVariants.displayNameSingular.toLocaleLowerCase() }}.
          </p>
          <p class="text-secondary">For example, a company collection can have fields for name, address and website.</p>
          <div ref="fieldListDiv" class="field-list">
            <p v-if="collection.fields.length === 0" class="mt-4 text-secondary">Add a field to your collection to continue.</p>
            <k-item-list
              :items="collection.fields"
              :context-menu-items="fieldOptions"
              :disable-context-menu-evaluator="(field?: KField) => field === fieldToEdit">
              <template #default="{ item }">
                <div v-if="item === fieldToEdit" class="mx-n2">
                  <field-editor
                    :field="fieldToEdit"
                    :collection
                    :proposed-collections="proposedCollections"
                    :autofocus="!!selectedField"
                    @submit="onEditField"
                    @cancel="onCancelEditField" />
                </div>
                <field-list-item v-else :field="item" :proposed-collections="proposedCollections" />
              </template>
            </k-item-list>
          </div>
          <div v-if="!fieldToEdit" class="mb-2 mt-n2">
            <field-editor
              :key="fieldEditorKey"
              :collection
              hide-cancel-when-adding
              :proposed-collections="proposedCollections"
              :autofocus="!selectedField"
              @submit="addField" />
          </div>
        </div>
      </div>
      <enable-feature-step v-if="currentStep === Step.FEATURES" :enabled @toggle-feature="onToggleFeature" />
    </div>
  </div>
  <div class="text-end p-3">
    <span class="text-secondary float-start pt-2 ps-1"><small>You can change these settings at any time.</small></span>
    <k-button v-if="currentStep > 0" variant="secondary" class="mx-2" icon="chevron-left" label="Back" @click="changeStep((currentStep - 1) as Step)" />
    <k-button
      v-if="currentStep < lastStep"
      variant="primary"
      icon="chevron-right"
      icon-right
      :disabled="!canProgress"
      label="Next"
      @click="changeStep((currentStep + 1) as Step)" />
    <k-button
      v-if="currentStep === lastStep && buttonType === 'DONE'"
      variant="success"
      class="px-3"
      label="Done"
      :icon-right="!isDoneButton"
      :loading
      @click="$emit('done')" />
    <k-button
      v-if="currentStep === lastStep && buttonType === 'NEXT'"
      variant="primary"
      label="Next"
      icon="chevron-right"
      icon-right
      :loading
      @click="$emit('done')" />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, onMounted } from "vue";

import { watchDebounced } from "@vueuse/core";

import FieldEditor from "../configuration/fields/FieldEditor.vue";
import FieldListItem from "../configuration/fields/FieldListItem.vue";

import KButton from "@ui/button/KButton.vue";
import KItemList from "@ui/item-list/KItemList.vue";
import KLabel from "@ui/label/KLabel.vue";
import type { ContextMenuItem } from "@ui/context-menu/ContextMenuItem";
import KInputConfig from "@ui/inputs/KInputConfig.vue";
import KIssueDisplay from "@ui/inputs/KIssueDisplay.vue";
import KIconPicker from "@ui/inputs/icon/KIconPicker.vue";
import KInput from "@ui/inputs/strings/KInput.vue";

import EnableFeatureStep from "./EnableFeatureStep.vue";

import type { Collection } from "@data/data/Collection";
import type { KField } from "@data/fields/KField";
import { hasErrors } from "@data/validation/Validation";

import { useCollectionNameVariants } from "@/helpers/useCollectionNameVariants";

const props = withDefaults(
  defineProps<{
    /** The default collection name value being edited */
    collectionName?: string;
    /** The collection being created */
    collection: Collection;
    /** Indicates whether the collection form is open as a MANUAL-SINGLE page and thus should have a save button */
    buttonType: "DONE" | "NEXT";
    /** Indicates whether the collection is in the process of being saved */
    loading: boolean;
    /** A list of GeneratedCollections to show as record field types options (before they're created as actual collections) */
    proposedCollections?: Collection[];
    /** Field to edit initially */
    selectedField?: KField | null;
    /** Which step to open on initially */
    initialStep?: 0 | 1;
  }>(),
  {
    proposedCollections: () => [],
    selectedField: null,
    initialStep: 0,
    collectionName: undefined
  }
);

const emit = defineEmits<{
  (event: "editField", oldField: KField, newField: KField): void;
  (event: "addField", newField: KField): void;
  (event: "deleteField", field: KField): void;
  (event: "toggleActivities"): void;
  (event: "toggleTasks"): void;
  (event: "toggleFiles"): void;
  (event: "editProperty", property: "name" | "description" | "icon", newString: string): void;
  (event: "done"): void;
}>();

const enum Step {
  DETAILS = 0,
  FIELDS = 1,
  FEATURES = 2
}

type Feature = "activities" | "tasks" | "files";

const enabled = computed<Record<Feature, boolean>>(() => ({
  activities: props.collection.activities?.isEnabled ?? false,
  tasks: props.collection.tasks?.isEnabled ?? false,
  files: props.collection.files?.isEnabled ?? false
}));

const onToggleFeature = (feature: Feature) => {
  switch (feature) {
    case "activities":
      emit("toggleActivities");
      break;
    case "tasks":
      emit("toggleTasks");
      break;
    case "files":
      emit("toggleFiles");
      break;
  }
};

const isDoneButton = computed(() => props.buttonType === "DONE");
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const collectionName = ref(props.collectionName ?? "");

const pageTitlesInOrder = ["Details", "Fields", "Features"];
const lastStep = pageTitlesInOrder.length - 1;

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const currentStep = ref<Step>(props.selectedField === null ? props.initialStep : 1);
function changeStep(step: Step) {
  if (props.collectionName !== collectionName.value) {
    emit("editProperty", "name", collectionName.value);
  }
  currentStep.value = step;
}

const nameVariants = useCollectionNameVariants(collectionName);

const nameInputEl = ref<{ $el: HTMLElement }>();
onMounted(() => {
  if (nameInputEl.value) nameInputEl.value.$el.getElementsByTagName("input")[0].focus();
});

watchDebounced(collectionName, () => {
  if (props.collectionName !== collectionName.value) {
    emit("editProperty", "name", collectionName.value);
  }
});

const fieldEditorKey = ref(0);

const fieldListDiv = ref<HTMLDivElement>();
const addField = async (field: KField) => {
  emit("addField", field);
  // remount the field editor component to reset it
  fieldEditorKey.value++;
  await nextTick(() => {
    if (!fieldListDiv.value) return;
    fieldListDiv.value.scrollTop = fieldListDiv.value.scrollHeight;
  });
};

const canProgress = computed(() => {
  switch (currentStep.value) {
    case Step.DETAILS:
      return collectionName.value && !hasErrors(nameVariants.value.issues);
    case Step.FIELDS:
      return props.collection.fields.length > 0;
  }
  return false;
});

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const fieldToEdit = ref(props.selectedField === null ? undefined : props.selectedField);

const onEditField = (editedField: KField) => {
  if (fieldToEdit.value) {
    emit("editField", fieldToEdit.value, editedField);
    fieldToEdit.value = undefined;
  } else {
    throw new Error("Editing Field not defined");
  }
};

const onCancelEditField = () => {
  fieldToEdit.value = undefined;
};

const onUpdateDescription = (newDescription?: string) => {
  emit("editProperty", "description", newDescription ?? "");
};

// Field context menu
const fieldOptions: ContextMenuItem<KField>[] = [
  {
    label: "Edit",
    icon: "pencil",
    onClick: (field) => {
      if (field) {
        fieldToEdit.value = field;
      }
    },
    disableOption: () => !!fieldToEdit.value
  },
  {
    label: "Delete",
    icon: "trash",
    onClick: (field) => {
      if (!field) return;
      emit("deleteField", field);
    },
    variant: "danger"
  }
];
</script>

<style scoped lang="scss">
.collection-form {
  min-height: 450px;
}

.stages {
  width: 110px;
}

.stages ul {
  padding: 0;
  margin: 0;
  list-style: none;
  cursor: default;
}

.stages li {
  padding: 0.25rem 0.75rem;
  border-left: 3px solid transparent;
  color: var(--k-color-secondary);
  border-radius: 0 4px 4px 0;
  transition: all ease-in-out 0.1s;
}

.stages li.clickable {
  color: var(--k-color);
  &:hover {
    background-color: var(--k-navbar-item-hover-background);
  }
}

.stages li.active {
  color: var(--k-color-primary);
  font-weight: bold;
  border-left: 3px solid var(--k-color-primary);
}

:deep(.input-field-type) {
  transform: translateX(-70%);
}

.wizard-content {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
