<template>
  <component
    :is="tag"
    ref="element"
    :contenteditable="contenteditable"
    :role="contenteditable ? 'textbox' : undefined"
    @input="update"
    @blur="update"
    @paste="onPaste"
    @keypress="onKeypress">
  </component>
</template>

<script setup lang="ts">
// note: lovingly stolen from https://github.com/hl037/vue-contenteditable/blob/master/src/components/contenteditable.vue
import { defineProps, ref, onMounted, watch } from "vue";

const props = withDefaults(
  defineProps<{
    /** Tag to use for the element */
    tag?: "div" | "span" | `h${1 | 2 | 3 | 4 | 5 | 6}`;
    /** Whether the element is contenteditable */
    contenteditable?: boolean;
    /** Model value */
    modelValue: string;
    /** Whether to allow newlines */
    allowNewlines?: boolean;
  }>(),
  {
    tag: "span",
    contenteditable: true
  }
);

const emit = defineEmits<{
  (event: "returned", value: string): void;
  (event: "update:modelValue", value: string): void;
}>();

function replaceAll(str: string, search: string, replacement: string) {
  return str.split(search).join(replacement);
}

const element = ref<HTMLElement | null>();

function getCurrentContent() {
  return element.value?.textContent ?? "";
}

function updateContent(newcontent: string) {
  element.value!.textContent = newcontent;
}

function update() {
  emit("update:modelValue", getCurrentContent());
}

function onPaste(event: ClipboardEvent) {
  event.preventDefault();
  let text = event.clipboardData?.getData("text/plain");
  if (!text) return;
  if (!props.allowNewlines) {
    text = replaceAll(text, "\r\n", " ");
    text = replaceAll(text, "\n", " ");
    text = replaceAll(text, "\r", " ");
  }
  window.document.execCommand("insertText", false, text);
}

function onKeypress(event: KeyboardEvent) {
  if (event.key === "Enter" && !props.allowNewlines) {
    event.preventDefault();
    emit("returned", getCurrentContent());
  }
}

onMounted(() => {
  updateContent(props.modelValue);
});

watch(
  () => props.modelValue,
  (newval) => {
    if (newval !== getCurrentContent()) {
      updateContent(newval);
    }
  }
);

watch(
  () => props.tag,
  () => {
    updateContent(props.modelValue);
  },
  { flush: "post" }
);

defineExpose({
  focus: () => {
    if (!element.value) return;
    element.value.focus();
    const sel = window.getSelection();
    sel?.selectAllChildren(element.value);
    if (sel?.rangeCount) {
      sel.collapseToEnd();
    }
  }
});
</script>
