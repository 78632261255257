import Papa from "papaparse";

import { SimpleField } from "../basic/SimpleFields";
import { BulkData } from "../../expressions/data";
import { KeyField, KeysField } from "../sub/SubField";
import { checkIsObject } from "../../helpers/NullHelpers";

import type { KRecord } from "../../data/Record";
import type { ExpressionField } from "../../expressions/accessors";
import type { JsonSerialisable } from "../../helpers/serialisation/TypedJSON";
import type { KFieldSetup, Sortable, KField } from "../KField";
import type { KEntity } from "../../data/KEntity";

export type KRecordOption = { label: string; secondaryLabel?: string; id: string; deleted?: boolean };

export const validateRecordOption = (value: unknown): value is KRecordOption => {
  if (!checkIsObject(value)) return false;
  if (["label", "id"].some((k) => typeof value[k] !== "string")) return false;
  if (value.secondaryLabel != null && typeof value.secondaryLabel !== "string") return false;
  if (value.deleted != null && typeof value.deleted !== "boolean") return false;
  return true;
};

export type RelationalFilters = {
  /** Standard expressions to filter record options by */
  expressions: string[];
  /** Fields for relational expressions which will be generated on the fly (e.g. selecting a Contact from the selected Company) */
  relations: {
    /** Field from this collection */
    parentFieldId: string;
    /** Field from target collection */
    foreignFieldId: string;
  }[];
};

interface RelationalFieldSetup extends KFieldSetup {
  // parent = foreign collection (ie show tasks in linked collection)
  showParentActivities?: boolean;
  showParentTasks?: boolean;
  filters?: RelationalFilters;
  canCreateRecord?: boolean;
}

/** Abstract class for fields that link to another collection */
export abstract class RelationalField<V extends JsonSerialisable> extends SimpleField<V> {
  // foreign collection id
  collectionId: string;

  showParentActivities = false;

  showParentTasks = false;

  filters?: RelationalFilters;

  // whether users can create records on the fly
  canCreateRecord = true;

  constructor(setup: RelationalFieldSetup | string, collectionId: string) {
    super(setup);
    if (typeof setup !== "string") {
      this.showParentActivities = setup.showParentActivities ?? false;
      this.showParentTasks = setup.showParentTasks ?? false;
      this.canCreateRecord = setup.canCreateRecord ?? true;
      this.filters = setup.filters;
    }
    this.collectionId = collectionId;
  }

  override isAssignableTo(field: KField): boolean {
    if (field instanceof RelationalField) {
      return super.isAssignableTo(field) && this.collectionId === field.collectionId;
    }
    return false;
  }
}

/** Record field for adding a link to another record */
export class RecordField extends RelationalField<KRecordOption> implements ExpressionField<"string"> {
  readonly granular = true;

  readonly type = "record";

  readonly expressionType = "string";

  constructor(setup: string | RelationalFieldSetup, collectionId: string) {
    super(setup, collectionId);
    this.subfields = [new KeyField("id", () => this, { label: `${this.label} ID`, idFor: `record-${collectionId}` })];
  }

  getSortableValue(record: KRecord, toLowerCase?: boolean | undefined): Sortable {
    const label = this.getValue(record)?.label;
    return toLowerCase ? label?.toLowerCase() : label;
  }

  getDisplayValue(record: KRecord): string {
    return this.getValue(record)?.label ?? "";
  }

  getSearchValue(record: KRecord): string | undefined {
    return this.getDisplayValue(record);
  }

  getExpressionValue(r: KRecord): string | undefined {
    return this.getValue(r)?.label;
  }

  validateType(value: unknown): value is KRecordOption {
    return validateRecordOption(value);
  }
}

export class MultiRecordField extends RelationalField<KRecordOption[]> implements ExpressionField<"data"> {
  readonly expressionType = "data";

  readonly type = "multiRecord";

  constructor(setup: string | RelationalFieldSetup, collectionId: string) {
    super(setup, collectionId);

    this.subfields = [new KeysField("id", () => this, { label: `${this.label} IDs`, idFor: `record-${collectionId}` })];
  }

  getLabels(record: KRecord) {
    const values = this.getValue(record);
    return Array.isArray(values) ? values.map((v) => v.label) : undefined;
  }

  getSortableValue(record: KRecord): Sortable {
    return this.getValue(record)?.length;
  }

  getDisplayValue(record: KRecord): string {
    return this.getLabels(record)?.join(", ") ?? "";
  }

  getSearchValue(record: KRecord): string | undefined {
    return this.getDisplayValue(record);
  }

  getCSVValue(record: KRecord): string {
    const labels = this.getLabels(record);
    if (labels !== undefined) {
      return Papa.unparse([labels]);
    }
    return "";
  }

  getExpressionValue(r: KRecord) {
    return new BulkData(this.getLabels(r) ?? []);
  }

  validateType(value: unknown): value is KRecordOption[] {
    return Array.isArray(value) && value.every(validateRecordOption);
  }
}

export const getRelationalFields = (e: KEntity) =>
  e.getFieldsOfType<KField & { collectionId: string }>(RecordField).concat(e.getFieldsOfType(MultiRecordField));
