import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { BooleanExpression, DatetimeExpression } from "./expressions";

import type { KContext } from "./context";

dayjs.extend(utc);

export class IsEditingExpression extends BooleanExpression {
  constructor() {
    super();
  }

  evaluate(context: KContext) {
    return context.state?.editing ?? false;
  }

  display() {
    return "editing";
  }
}

export class NowExpression extends DatetimeExpression {
  constructor() {
    super("minute");
  }

  evaluate() {
    return dayjs.utc();
  }

  display() {
    return "now";
  }
}
