<template>
  <k-input-select :ref="inputOption" v-model="input" :label :options="inputOptions" :required />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import KInputSelect from "./KInputSelect.vue";

import type { KField } from "@data/fields/KField";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: KField;
  /** Label for the input */
  label?: string;
  /** Options to show in the dropdown */
  options: KField[];
  /** Whether the field is required */
  required?: boolean;
}>();
const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", newValue: KField | undefined): void;
}>();

const inputOption = ref();
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const input = ref(props.modelValue?.key);
const inputOptions = computed(() => props.options.map((f) => ({ value: f.key, label: f.label })));

watch(
  () => props.modelValue,
  () => {
    input.value = props.modelValue?.key;
  }
);
watch(input, (newId) => {
  emit(
    "update:modelValue",
    props.options.find((f) => f.key === newId)
  );
});
</script>
