import { type MaybeRef } from "vue";
import { computed, toValue } from "vue";

import { gql } from "@apollo/client/core";
import { useMutation, useSubscription } from "@vue/apollo-composable";

import { constructPaginatedQuery, usePaginatedQuery } from "../helpers/usePaginatedQuery";

import type { Activity } from "@data/data/Activity";
import type { Datelike } from "@data/types/Dates";
import type { Person } from "@data/data/Person";

import type { ImportResult } from "./import";

const activityProperties = `
id
createdAt
createdBy
updatedAt
updatedBy
content
timestamp
type
recordId
recordLabel
collectionId
relations {
  collectionId
  recordId
}
properties {
  field {
    type
    label
    key
    id
  }
  rawValue
  displayValue
  link
}
changes {
  fieldId
  fieldLabel
  fieldType
  oldValue
  newValue
}
email {
  recipients { displayName email recordId collectionId type }
  subject
  textBody
  source
  externalServiceId
  notes
  jsonLd
}
`;

/** Get a list of activities for a given collection */
const useActivitiesByRecord = (props: MaybeRef<{ collectionId: string; recordId: string }>) =>
  usePaginatedQuery<Activity>(
    "activitiesByRecordId",
    constructPaginatedQuery(
      "activitiesByRecordId",
      activityProperties,
      [
        { prop: "collectionId", type: "String!" },
        { prop: "recordId", type: "String!" }
      ],
      { pageSize: 20, timestamp: true }
    ),
    props
  );

/** Get a list of activities for a given user */
const useActivitiesByUser = (person: MaybeRef<Person>) =>
  usePaginatedQuery<Activity>(
    "activitiesByUser",
    constructPaginatedQuery(
      "activitiesByUser",
      activityProperties,
      [
        { prop: "userId", type: "String!" },
        { prop: "personId", type: "String!" }
      ],
      { pageSize: 20, timestamp: true }
    ),
    computed(() => ({
      userId: toValue(person).userId,
      personId: toValue(person).id
    }))
  );

/** Get a list of activities for a given user id */
const useRecentActivities = () =>
  usePaginatedQuery<Activity>("activities", constructPaginatedQuery("activities", activityProperties, [], { pageSize: 20, timestamp: true }), {});

/** Interface for adding an activity (used for both adding to a single record and bulk add) */
interface AddActivityInput {
  collectionId: string;
  recordIds: string[];
  content: string;
  type: string;
  timestamp: Datelike;
  tasks: {
    name: string;
    description?: string;
    assignees?: string[];
    dueDate?: Date;
  }[];
}

/** Add an identical activity to possibly multiple records */
const addActivities = () =>
  useMutation<{ addActivities: { id: string }[] }, { input: AddActivityInput }>(gql`
    mutation addActivities($input: AddActivityInput!) {
      addActivities(input: $input) {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        content
        timestamp
        type
        recordId
        collectionId
      }
    }
  `);

type UpdateActivityInput = Pick<Activity, "id" | "content" | "type" | "timestamp">;
/** Update an activity */
const updateActivity = () =>
  useMutation<{ updateActivity: { id: string } }, { input: UpdateActivityInput }>(gql`
    mutation updateActivity($input: UpdateActivityInput!) {
      updateActivity(input: $input) {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        content
        timestamp
        type
        recordId
        collectionId
      }
    }
  `);

/** Delete an activity */
const deleteActivity = () =>
  useMutation<{ deleteActivity: boolean }, { activityId: string }>(
    gql`
      mutation deleteActivity($activityId: String!) {
        deleteActivity(activityId: $activityId)
      }
    `,
    () => ({
      update(cache, data, options) {
        cache.evict({ id: `Activity:${options.variables?.activityId ?? ""}` });
        cache.gc();
      }
    })
  );

/**
 * Handle record update events (from subscriptions)
 *
 * @param collectionId
 */
const onActivityUpdateEvent = (collectionId: string, recordId: string) =>
  useSubscription<{ activityUpdated: ActivityUpdateEvent }>(
    gql`
    subscription onActivityUpdated {
      activityUpdated(collectionId: "${collectionId}", recordId: "${recordId}") {
        collectionId
        recordId
        eventType
      }
    }
  `
  );

const importCSVActivities = () =>
  useMutation<
    {
      importCsvActivities: ImportResult;
    },
    { input: { headerMap: { header: string; fieldId: string }[]; file: File }; requestId: string }
  >(gql`
    mutation importActivityCSV($input: ImportActivityCsvInput!, $requestId: String!) {
      importCsvActivities(input: $input, requestId: $requestId) {
        totalRows
        importedRows
        errorRows
        errors {
          fieldId
          message
          row
        }
      }
    }
  `);

const cancelImportCsvActivities = () =>
  useMutation<{ importCsvActivities: ImportResult }, { requestId: string }>(gql`
    mutation importCsvActivitiesCancel($requestId: String!) {
      importCsvActivitiesCancel(requestId: $requestId)
    }
  `);

export interface ActivityUpdateEvent {
  collectionId: string;
  recordId: string;
  activityId: string;
  eventType: "ActivityAdded" | "ActivityUpdated" | "ActivityDeleted";
}

export const ActivityAPI = {
  useActivitiesByRecord,
  useActivitiesByUser,
  useRecentActivities,
  addActivities,
  updateActivity,
  deleteActivity,
  onActivityUpdateEvent,
  importCSVActivities,
  cancelImportCsvActivities
};
