import type { MaybeRef } from "vue";
import { computed, toValue } from "vue";

import { gql } from "@apollo/client/core";
import { useQuery, useMutation, useSubscription } from "@vue/apollo-composable";

import type { ServerTask } from "@data/data/Task";

import type { ImportResult } from "./import";

export const taskProperties = `
id
createdAt
createdBy
updatedAt
updatedBy
recordId
recordLabel
collectionId
name
description
status
dueDate
scheduled {
  start
  end
}
sortOrder
assignees
class
completedBy
completedAt
relations {
  collectionId
  recordId
  label
}
`;

/** Get a list of tasks for a given collection */
const useTasksByRecord = (collectionId: string, recordId: string) =>
  useQuery<{ tasksByRecordId?: ServerTask[] | undefined } | undefined>(
    gql`
    query tasksByRecordId($collectionId: String!, $recordId: String!) {
      tasksByRecordId(collectionId: $collectionId, recordId: $recordId) {
        ${taskProperties}
      }
    }
  `,
    { collectionId, recordId },
    () => ({
      fetchPolicy: "cache-and-network"
    })
  );

/** Get a list of tasks for a given user id */
const useTasksByUser = (
  userId: MaybeRef<string | undefined>,
  completedTasks: boolean,
  groupBy?: MaybeRef<"collectionId" | "recordId" | "status" | "dueDate" | "scheduled">
) =>
  useQuery<{ tasksByUser?: ServerTask[] | undefined } | undefined>(
    gql`
    query tasksByUser($userId: String!, $completedTasks: Boolean!, $groupBy: String) {
      tasksByUser(userId: $userId, completedTasks: $completedTasks, groupBy: $groupBy) {
        ${taskProperties}
      }
    }
  `,
    { userId, completedTasks, groupBy },
    {
      fetchPolicy: "cache-and-network",
      enabled: computed(() => !!(toValue(userId) && toValue(groupBy)))
    }
  );

export type AddTaskInput = {
  collectionId: string;
  recordIds: string[];
  name: string;
  description?: string;
  assignees?: string[];
  dueDate?: Date;
  sortOrder?: string;
};

const addTasks = () =>
  useMutation<{ addTasks: ServerTask[] }, { tasks: AddTaskInput }>(gql`
    mutation addTasks($tasks: AddTasksInput!) {
      addTasks(tasks: $tasks) {
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        name
        description
        status
        dueDate
        sortOrder
        assignees
        recordId
        collectionId
      }
    }
  `);

export type UpdateTaskInput = { id: string } & Partial<
  Pick<ServerTask, "id" | "name" | "status" | "dueDate" | "scheduled" | "sortOrder" | "assignees" | "description" | "relations">
>;

/** Update an task */
const updateTask = () =>
  useMutation<{ updateTask: { id: string } }, { task: UpdateTaskInput }>(gql`
    mutation updateTask($task: UpdateTaskInput!) {
      updateTask(task: $task) {
        ${taskProperties}
      }
    }
  `);

export type BulkUpdateTaskInput = { ids: string[] } & Partial<
  Pick<ServerTask, "name" | "status" | "dueDate" | "scheduled" | "assignees" | "description" | "relations">
>;

/** Update lots of tasks */
const bulkUpdateTasks = () =>
  useMutation<{ bulkUpdateTasks: { id: string } }, { task: BulkUpdateTaskInput }>(gql`
    mutation bulkUpdateTasks($task: BulkUpdateTaskInput!) {
      bulkUpdateTasks(task: $task) {
        ${taskProperties}
      }
    }
  `);

/** Update an task */
const removeTaskDueDates = () =>
  useMutation<{ removeTaskDueDates: { id: string } }, { taskIds: string[] }>(gql`
    mutation removeTaskDueDates($taskIds: [String!]!) {
      removeTaskDueDates(taskIds: $taskIds) {
        id
        dueDate
      }
    }
  `);

/** Update an task */
const removeTaskScheduledDates = () =>
  useMutation<{ removeTaskScheduledDates: { id: string } }, { taskIds: string[] }>(gql`
    mutation removeTaskScheduledDates($taskIds: [String!]!) {
      removeTaskScheduledDates(taskIds: $taskIds) {
        id
        scheduled {
          start
          end
        }
      }
    }
  `);

/** Delete an task */
const deleteTask = () =>
  useMutation<{ deleteTask: boolean }, { taskId: string }>(
    gql`
      mutation deleteTask($taskId: String!) {
        deleteTask(taskId: $taskId)
      }
    `,
    () => ({
      update(cache, data, options) {
        cache.evict({ id: `Task:${options.variables?.taskId ?? ""}` });
        cache.gc();
      }
    })
  );

/** Delete lots of tasks */
const bulkDeleteTasks = () =>
  useMutation<{ bulkDeleteTasks: boolean }, { taskIds: string[] }>(
    gql`
      mutation bulkDeleteTasks($taskIds: [String!]!) {
        bulkDeleteTasks(taskIds: $taskIds)
      }
    `,
    () => ({
      update(cache, data, options) {
        if (!options.variables?.taskIds) return;
        for (const taskId of options.variables.taskIds) {
          cache.evict({ id: `Task:${taskId}` });
        }
        cache.gc();
      }
    })
  );

/**
 * Handle record update events (from subscriptions)
 *
 * @param collectionId
 */
const onTaskUpdateEvent = (collectionId: string, recordId: string) =>
  useSubscription<{ taskUpdated: TaskUpdateEvent }>(
    gql`
    subscription onTaskUpdated {
      taskUpdated(collectionId: "${collectionId}", recordId: "${recordId}") {
        collectionId
        recordId
        eventType
      }
    }
  `
  );

/** Import tasks from a Csv */
const importCSVTasks = () =>
  useMutation<
    {
      importCsvTasks: ImportResult;
    },
    { input: { headerMap: { header: string; fieldId: string }[]; file: File }; requestId: string }
  >(gql`
    mutation importTaskCSV($input: ImportTaskCsvInput!, $requestId: String!) {
      importCsvTasks(input: $input, requestId: $requestId) {
        totalRows
        importedRows
        errorRows
        errors {
          fieldId
          message
          row
        }
      }
    }
  `);

const cancelImportCsvTasks = () =>
  useMutation<{ importCsvTasks: ImportResult }, { requestId: string }>(gql`
    mutation importCsvTasksCancel($requestId: String!) {
      importCsvTasksCancel(requestId: $requestId)
    }
  `);

export interface TaskUpdateEvent {
  collectionId: string;
  recordId: string;
  taskId: string;
  eventType: "TaskAdded" | "TaskUpdated" | "TaskDeleted";
}

export const TaskAPI = {
  useTasksByRecord,
  useTasksByUser,
  addTasks,
  updateTask,
  bulkUpdateTasks,
  deleteTask,
  bulkDeleteTasks,
  onTaskUpdateEvent,
  importCSVTasks,
  cancelImportCsvTasks,
  removeTaskDueDates,
  removeTaskScheduledDates
};
