<template>
  <div ref="parent" class="context-menu">
    <teleport to="body">
      <ul
        ref="menu"
        :style="menuStyle"
        class="context-menu dropdown-menu"
        :class="{ show: isOpen, 'docked-bottom': dockedBottom, 'docked-right': dockedRight || dockedSubmenuRight }"
        @click.right.prevent>
        <slot v-if="isOpen">
          <template v-for="(item, index) of menuItems" :key="index">
            <k-dropdown-divider v-if="item.type == 'divider'" />
            <k-dropdown-item
              v-else
              :label="getValue(item.label) ?? ''"
              :active="!!getValue(item.active)"
              :disabled="isDisabled(item)"
              :icon="getValue(item.icon)"
              :loading="item.loading"
              :variant="getValue(item.variant)"
              :has-sub-menu="hasContextSubitems(item, selectedRecord) || item.type == 'date-picker' || item.type == 'date-time-picker'"
              @click="onClick(item, $event)"
              @hover="(evt, bounds) => onHover(item, index, evt, bounds)">
            </k-dropdown-item>

            <teleport v-if="(item.type == 'date-picker' || item.type == 'date-time-picker') && subMenuState.index === index" to="body">
              <ul class="dropdown-menu sub-menu show dropdown-date-picker" :style="subMenuState.style" @click.right.prevent>
                <k-dropdown-date-picker
                  :mode="item.type == 'date-time-picker' ? 'dateTime' : 'date'"
                  :model-value="item.value?.(selectedRecord)"
                  @update:model-value="item.setValue(selectedRecord, $event)" />
              </ul>
            </teleport>
            <teleport v-if="(!item.type || item.type == 'default') && hasContextSubitems(item, selectedRecord) && subMenuState.index === index" to="body">
              <ul :key="`${selectedRecord}`" class="dropdown-menu sub-menu show" :style="subMenuState.style" @click.right.prevent>
                <template v-for="(o, i) of getContextSubitems(item, selectedRecord)" :key="i">
                  <k-dropdown-divider v-if="o.type == 'divider'" />
                  <k-dropdown-item
                    v-else
                    :label="getValue(o.label) ?? ''"
                    :active="!!getValue(o.active)"
                    :icon="getValue(o.icon)"
                    :variant="getValue(o.variant)"
                    :loading="o.loading"
                    @click="onClick(o, $event)" />
                </template>
              </ul>
            </teleport>
          </template>
        </slot>
      </ul>
    </teleport>
  </div>
</template>

<script setup lang="ts" generic="T">
import { computed, ref, watch } from "vue";

import { computePosition, autoPlacement, shift, detectOverflow, offset } from "@floating-ui/dom";
import { onClickOutside, onKeyStroke, useEventBus, useScrollLock } from "@vueuse/core";
import { v4 as uuid } from "uuid";

import KDropdownDatePicker from "@ui/dropdown/KDropdownDatePicker.vue";
import KDropdownDivider from "@ui/dropdown/KDropdownDivider.vue";
import KDropdownItem from "@ui/dropdown/KDropdownItem.vue";
import { getScrollParent, isWithinParentClass } from "@ui/helpers/dom/GetScrollParent";

import { hasContextSubitems, getContextSubitems, filterContextItems } from "./contextMenuHelpers";

import type { ContextMenuDatePicker, ContextMenuItem, ContextMenuTextItem } from "./ContextMenuItem";
import type { MiddlewareState } from "@floating-ui/core";
import type { Dayjs } from "dayjs";

import "./ContextMenu.css";

const props = withDefaults(
  defineProps<{
    /** Context menu items */
    items: ContextMenuItem<T>[];
    /** (Optional) Blocks parent container from scrolling when the menu is open, defaults to true */
    preventOverflow?: boolean;
  }>(),
  {
    preventOverflow: true
  }
);

const emit = defineEmits<{
  /** Emitted when the menu is removed from screen */
  (e: "dismiss"): void;
}>();

const parent = ref<HTMLElement>();
const menu = ref<HTMLElement>();
const container = ref<HTMLElement | null>();
const isOpen = ref(false);
const hasOpened = ref(false);
const selectedRecord = ref<T>();

const subMenuState = ref<{ style: string; index: number }>({
  style: "",
  index: -1
});

const contextMenuId = uuid();

// Boolean indicating if parent container is scrollable
const isLocked = useScrollLock(container);

const menuStyle = ref<{ left?: string; right?: string; top?: string; bottom?: string }>({});

const dismissMenu = () => {
  subMenuState.value = {
    style: "",
    index: -1
  };
  if (isOpen.value) {
    isOpen.value = false;
    hasOpened.value = false;
    isLocked.value = false;
    emit("dismiss");
    // Reset position to prevent overflow scrolls
    setTimeout(() => {
      if (!isOpen.value) {
        // Only reset value if user hasn't already right clicked somewhere else during timeout
        menuStyle.value = {
          left: "0px",
          top: "0px"
        };
      }
    }, 500);
  }
};

/** Watch for changes in the parent container, ensure this is accurate */
watch(parent, () => {
  if (props.preventOverflow) {
    container.value = getScrollParent(parent.value?.parentElement);
  }
});

const dockedBottom = ref(false);
const dockedRight = ref(false);
const dockedSubmenuRight = ref(false);

// Close context menu if another context menu is opened elsewhere
const contextMenuEvents = useEventBus<string>("context-menu");
const contextMenuEvent = (event: string) => {
  if (contextMenuId !== event) {
    dismissMenu();
  }
};
contextMenuEvents.on(contextMenuEvent);
/**
 * Handles a mouse event to show the context menu
 *
 * @param event Mouse event
 * @param record (Optional) Record to pass to the context menu
 * @param attachToParent (Optional) Whether to attach the context menu to the parent element
 */
const show = async (
  evt: MouseEvent,
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  record: T | undefined,
  attachToParent?:
    | {
        alignment: "start" | "end";
        direction: "bottom" | "top";
      }
    | boolean
) => {
  isOpen.value = true;
  isLocked.value = true;
  subMenuState.value = {
    style: "",
    index: -1
  };
  selectedRecord.value = record;
  contextMenuEvents.emit(contextMenuId);

  const parentElement = menu.value ? menu.value.parentElement : null;
  if (!menu.value) {
    return;
  }

  // Calculate overflow, helpful for determining if submenu should be docked to the left or right
  const overflow = { x: 0, y: 0, right: 0, bottom: 0, left: 0, top: 0 };
  const middleware = {
    name: "middleware",
    async fn(middlewareState: MiddlewareState) {
      const overflowRes = await detectOverflow(middlewareState, {});
      overflow.bottom = overflowRes.bottom;
      overflow.right = overflowRes.right;
      overflow.left = overflowRes.left;
      overflow.top = overflowRes.top;
      return {};
    }
  };

  // If button is a trigger, attach to the button rather than pointer position
  if (attachToParent) {
    const alignment = attachToParent === true ? "end" : attachToParent.alignment;
    const direction = attachToParent === true ? "bottom" : attachToParent.direction;
    const res = await computePosition(evt.target as HTMLElement, menu.value, {
      middleware: [
        autoPlacement({
          alignment,
          allowedPlacements: direction === "bottom" ? ["bottom", "bottom-end", "bottom-start"] : ["top", "top-end", "top-start"]
        }),
        shift(),
        middleware
      ],
      placement: direction === "top" ? "top" : undefined,
      strategy: "fixed"
    });
    menuStyle.value = {
      left: `${res.x}px`,
      top: `${res.y}px`
    };
  } else {
    // Otherwise, attach to pointer position
    const parentRect = parentElement ? parentElement.getBoundingClientRect() : { x: 0, y: 0, left: 0, top: 0 };
    const x = evt.clientX - parentRect.x;
    const y = evt.clientY - parentRect.y;

    await computePosition(document.body, menu.value, {
      middleware: [
        offset({
          crossAxis: x
        }),
        middleware
      ],
      placement: "bottom-start"
    });

    const menuRect = menu.value.getBoundingClientRect();
    dockedBottom.value = document.body.clientHeight - (evt.target as HTMLElement).getBoundingClientRect().y < menuRect.height;
    dockedRight.value = overflow.right > 0;

    menuStyle.value = {
      left: `${x - (overflow.right > 0 ? overflow.right + 5 : 0)}px`,
      top: dockedBottom.value ? "auto" : `${y}px`,
      bottom: dockedBottom.value ? "0px" : "auto"
    };
  }

  // Add more space for calendar date picker
  const subMenuAdjustment = props.items.some((x) => x.type === "date-picker") ? 250 : 200;
  dockedSubmenuRight.value = overflow.right > -subMenuAdjustment;
  setTimeout(() => {
    hasOpened.value = true;
  }, 250);
};

const menuItems = computed(() => filterContextItems(props.items, selectedRecord.value));

function isDisabled(item: ContextMenuTextItem<T> | ContextMenuDatePicker<T>) {
  return !!item.disableOption?.(selectedRecord.value);
}

const onClick = async (option: ContextMenuTextItem<T> | ContextMenuDatePicker<T>, evt?: MouseEvent) => {
  if (hasContextSubitems(option, selectedRecord.value) || option.type === "date-picker" || option.type === "date-time-picker") {
    evt?.preventDefault();
    evt?.stopPropagation();
    return;
  }
  if (option.onClick) {
    await option.onClick(selectedRecord.value);
  }
  dismissMenu();
};

onClickOutside(menu, (evt) => {
  const el = evt.target as HTMLElement;
  const isInMenu = isWithinParentClass("dropdown-menu", el);

  if (!isInMenu) {
    dismissMenu();
  }
});

// Handle ESC key press to dismiss context menu
onKeyStroke(["Escape", "ContextMenu"], () => {
  dismissMenu();
});

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
const getValue = <V extends string | number | boolean | Dayjs>(value?: V | ((v: T | undefined) => V)) => {
  if (typeof value === "function") {
    return value(selectedRecord.value);
  } else {
    return value;
  }
};

const onHover = (item: ContextMenuTextItem<T> | ContextMenuDatePicker<T>, index: number, evt: MouseEvent, bounds: { x: number; y: number; width: number }) => {
  if (subMenuState.value.index !== -1 && subMenuState.value.index !== index) {
    subMenuState.value = {
      style: "",
      index: -1
    };
  }

  const isDatePicker = item.type === "date-picker" || item.type === "date-time-picker";
  if (hasContextSubitems(item, selectedRecord.value) || isDatePicker) {
    // check if the submenu can fit on the right
    if (!menu.value) return;

    const isRight = document.body.clientWidth - (bounds.x + bounds.width) < 200;

    const menuHeight = isDatePicker ? 320 : Math.min((getContextSubitems(item, selectedRecord.value)?.length ?? 2) * 28, 300);

    const isBottom = document.body.clientHeight - bounds.y < menuHeight;

    const style = {
      left: isRight ? "auto" : bounds.x + bounds.width + "px",
      right: isRight ? document.body.clientWidth - bounds.x + "px" : "auto",
      top: isBottom ? "auto" : bounds.y + "px",
      bottom: isBottom ? "0px" : "auto"
    };

    // convert to string
    const styleCss = Object.entries(style)
      .map(([key, value]) => `${key}: ${value};`)
      .join(" ");

    subMenuState.value = {
      style: styleCss,
      index
    };
  }
};

defineExpose({
  show,
  dismissMenu
});
</script>
