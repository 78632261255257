<template>
  <k-input-config v-bind="passedConfig" required :readonly="actualOptions.length < 2">
    <k-input-generic-select v-model="selected" :label :options="actualOptions" :get-id="(u) => u.expressionSymbol" :get-label="getLabel" />
  </k-input-config>
</template>

<script setup lang="ts">
import { computed } from "vue";

import KInputGenericSelect from "../select/KInputGenericSelect.vue";
import KInputConfig from "../KInputConfig.vue";
import { useInputConfig } from "../inputConfig";

import { deepDebounce } from "@ui/helpers/refHelpers";

import { toUnitInfo } from "./unitInfo";

import { capitalise } from "@data/helpers/strings/Casing";
import { parseUnit } from "@data/units/parseUnit";
import { sortedBy } from "@data/helpers/manipulation/Ordering";

import type { UnitInfo } from "./unitInfo";

const props = withDefaults(
  defineProps<{
    /** Label for the input */
    label?: string;
    /** List of possible units */
    options?: UnitInfo[];
    /** Format to show units */
    format?: "symbol" | "name";
  }>(),
  {
    label: undefined,
    options: undefined,
    format: "symbol"
  }
);

const selected = defineModel<UnitInfo>({ required: true });

const selectedUnit = computed(() => parseUnit(selected.value.expressionSymbol));

const actualOptions = deepDebounce(computed(() => sortedBy(props.options ?? selectedUnit.value.unitFamily, (u) => u.conversion).map(toUnitInfo)));

const config = useInputConfig();
const passedConfig = computed(() => ({
  status: config.value.status,
  sticky: config.value.sticky,
  disabled: config.value.disabled,
  readonly: config.value.readonly,
  noContainer: config.value.noContainer
}));

const getLabel = (unit: UnitInfo) => {
  switch (props.format) {
    case "symbol":
      return unit.symbol;
    case "name":
      return capitalise(unit.name);
  }
};
</script>
