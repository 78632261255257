/**
 * Gets reference to parent container recursively Used to prevent parent container from scrolling if context menu is open
 *
 * @param node Parent element to look within
 */
export const getScrollParent = (node?: HTMLElement | null): HTMLElement | null => {
  if (!node) {
    return window.document.body;
  }
  const style = window.getComputedStyle(node);
  if (node.scrollHeight > node.clientHeight || style.position === "absolute" || style.position === "fixed" || style.overflowY === "auto") {
    return node;
  } else {
    return getScrollParent(node.parentElement);
  }
};

export const isWithinParentClass = (className: string, element?: HTMLElement | null): boolean => {
  if (!element) return false;
  if (element.classList.contains(className)) return true;
  return isWithinParentClass(className, element.parentElement);
};
