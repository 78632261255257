<template>
  <k-modal
    :id="modalId"
    v-model:show="isVisible"
    :no-backdrop="isRelatedRecord"
    :title="modalTitle"
    :loading="saving"
    :static-backdrop="!isRelatedRecord"
    :modal-style="{ visibility: modalFocus.focused.at(-1)?.modalId === modalId ? 'visible' : 'hidden' }"
    @cancel="cancelForm">
    <template #title>
      <span>{{ modalTitle }}</span>
      <span v-if="mode === 'EDIT' && collection.idFormatConfig?.visible" class="float-end id-label text-mono text-secondary me-2">
        {{ collection.idField.getDisplayValue(formValue) }}
      </span>
      <p v-if="isRelatedRecord" class="breadcrumbs">
        {{ breadcrumbs }}
      </p>
    </template>
    <k-entity-form
      ref="entityForm"
      v-model="formValue"
      v-model:valid="valid"
      :entity="collection"
      :is-editing="mode === 'EDIT'"
      :prefill-fields="prefilledFields"
      :show-errors="showErrors"
      :extra-validators="uniquenessValidators" />

    <k-issue-display
      v-if="!canReadAfterwards"
      :issues="[{ severity: 'warning', message: 'You do not have permission to view this record after submitting' }]" />
    <template #footer>
      <div v-if="mode === 'ADD' && !isRelatedRecord" class="pe-2">
        <k-input-boolean v-model="createMore" class="create-more pt-1" inline-label="Add another?" size="sm" :hide-label="true" />
      </div>
      <k-button variant="secondary" @click="cancelForm">Cancel</k-button>
      <k-button variant="primary" :disabled="!valid && showErrors" :loading="saving" :label="okButton" @click="submitForm" />
    </template>
  </k-modal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { v4 as uuid } from "uuid";
import { useDebounce } from "@vueuse/core";

import KButton from "@ui/button/KButton.vue";
import KModal from "@ui/modal/KModal.vue";
import KInputBoolean from "@ui/inputs/boolean/KInputBoolean.vue";
import KIssueDisplay from "@ui/inputs/KIssueDisplay.vue";
import { AlertHandler } from "@ui/alerts/alert-handler";

import { usePermissionPrefilledFields } from "./prefill";
import { useRecordModalFocus } from "./useRecordModalFocus";

import type { Collection } from "@data/data/Collection";
import type { KBRecord, KRecord } from "@data/data/Record";
import type { ConditionalValidator } from "@data/validation/ConditionalValidation";
import { formatList } from "@data/helpers/strings/List";
import { toConstant } from "@data/expressions/helpers";
import type { KRecordOption } from "@data/fields/relational/RecordFields";
import type { ExternalRecord } from "@data/data/Extension";

import { useAddRecord, useUniquenessViolations, useUpdateRecord } from "@/helpers/collection";
import KEntityForm from "@/components/form/KEntityForm.vue";
import { useHasCollectionPermission, usePermissionFunction } from "@/helpers/permissions";
import { isUnitTest } from "@/tests/helpers/testFlag";

import type { PrefilledField } from "@/components/form/FormTypes";

const props = defineProps<{
  /** Entity for the record */
  collection: Collection;
  /** Record to edit */
  record?: KBRecord;
  /** Whether to show the modal */
  visible?: boolean;
  /** Title of the modal */
  title?: string;
  /** Disable the trigger button, used when entity data not loaded yet */
  disabled?: boolean;
  /** Pre-fill fields with values */
  prefillFields?: PrefilledField[];
  /** If creating related record on the fly */
  isRelatedRecord?: boolean;
  /** (optional) External record */
  externalRecord?: ExternalRecord;
  /** (optional) For when you want prefillFields to override recordToEdit data, used in extensions */
  overridePrefill?: boolean;
}>();

const emit = defineEmits<{
  (event: "saved", id?: string): void;
  (event: "update"): void;
  /** Emitted when the modal is closed */
  (event: "update:visible", value: boolean): void;
  /** Emitted when a related record is created */
  (event: "newOption", option?: KRecordOption): void;
}>();

type FormMode = "ADD" | "EDIT";

const alertHandler = new AlertHandler();

// Setup state of modal dialog
const isVisible = ref(false);

const modalFocus = useRecordModalFocus();

const modalId = uuid();

const permissionPrefilled = usePermissionPrefilledFields(computed(() => props.collection));
const writePermission = useHasCollectionPermission(
  computed(() => props.collection),
  "add"
);
const canEdit = usePermissionFunction(
  computed(() => props.collection),
  "write"
);

// Set state of form based on whether an entityId is provided
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const valid = ref(props.record !== undefined);

const mode = computed<FormMode>(() => (props.record === undefined ? "ADD" : "EDIT"));
const modalTitle = computed(() => props.title ?? (mode.value === "EDIT" ? "Edit" : "Add") + ` ${props.collection.singular}`);

const formValue = ref<KRecord>({});
const initialValue = ref<KRecord>();
const recordId = computed(() => props.record?.id);

const saving = ref(false);
const showErrors = ref(false);
const createMore = ref(false);

const entityForm = ref<{ focus: () => void } | null>(null);

const resetForm = () => {
  formValue.value = { ...initialValue.value };
  saving.value = false;
  showErrors.value = false;
};

const cancelForm = () => {
  isVisible.value = false;
  modalFocus.removeFocused(modalId);
  emit("update:visible", false);
  emit("newOption");
  resetForm();
};

const showForm = () => {
  if (props.disabled) {
    modalFocus.removeFocused(modalId);
    emit("update:visible", false);
    return;
  }
  if (props.record && mode.value === "EDIT" ? !canEdit(props.record) : !writePermission.value) {
    alertHandler.addAlert({
      variant: "danger",
      title: "Access Denied",
      content: `You do not have permission to add records in the ${props.collection.singular.toLowerCase()} collection.`,
      duration: 4000,
      position: "top"
    });
    modalFocus.removeFocused(modalId);
    emit("update:visible", false);
    return;
  }

  isVisible.value = true;
  modalFocus.addFocused(modalId, props.collection.singular, !props.isRelatedRecord && !!props.record?.id);
  emit("update:visible", true);
  resetForm();
};

watch(
  () => props.visible,
  (value) => {
    if (value) {
      showForm();
    } else {
      isVisible.value = false;
    }
  }
);
// Load initial data
watch(
  () => props.record,
  (res) => {
    // if we're passing in prefillFields, don't reset the form
    if (props.prefillFields && props.overridePrefill) return;

    // empty form
    if (!recordId.value) {
      formValue.value = {};
      initialValue.value = {};
      resetForm();
      return;
    }
    // fill in from record
    if (res) {
      formValue.value = { ...res };
      initialValue.value = { ...res, recordId: recordId.value };
    }
  },
  { immediate: true }
);

const readFunction = usePermissionFunction(
  computed(() => props.collection),
  "read"
);
const canReadAfterwards = computed(() => readFunction(formValue.value));
const okButton = computed(() => {
  if (!canReadAfterwards.value) {
    return "Submit";
  } else if (mode.value === "ADD") {
    return "Add";
  } else {
    return "Save";
  }
});

const debouncedRecord = isUnitTest ? formValue : useDebounce(formValue, 1000);

const { violated, invalidRecord } = useUniquenessViolations(
  computed(() => props.collection),
  debouncedRecord
);

const uniquenessValidators = computed<ConditionalValidator[]>(() => {
  const invalid = invalidRecord.value;
  if (!invalid) return [];
  return violated.value.map<ConditionalValidator>((v) => {
    const fields = props.collection.getFields(v.fieldIds);
    return {
      message: `${formatList(fields.map((f) => f.label))} ${v.severity === "warning" ? "should" : "must"} be unique`,
      severity: v.severity,
      conditional: fields.map((f) => `record.${f.id} matches ${toConstant(f.getDisplayValue(invalid))}`).join(" and ")
    };
  });
});

const { mutate: addRecord } = useAddRecord(computed(() => props.collection));
const { mutate: updateRecord } = useUpdateRecord(computed(() => props.collection));

const submitForm = async () => {
  showErrors.value = true;
  if (!valid.value) return;

  saving.value = true;

  let id = recordId.value;
  let success = false;
  switch (mode.value) {
    case "ADD": {
      const res = await addRecord(formValue.value, props.externalRecord?.services);
      if (res) {
        id = res.data?.addRecord.id;
        success = true;
        if (props.isRelatedRecord && id) {
          const primary = props.collection.primaryField.getDisplayValue(formValue.value);
          const secondary = props.collection.secondaryField?.getDisplayValue(formValue.value);
          const newOption = { id, label: primary, secondaryLabel: secondary };
          emit("newOption", newOption);
        }
      }
      break;
    }
    case "EDIT":
      if (recordId.value) {
        success = !!(await updateRecord({ ...formValue.value, id: recordId.value }, props.externalRecord?.services));
      } else {
        console.warn("No id specified in record prop while trying to save edited record!");
      }
  }

  saving.value = false;

  if (success) {
    if (createMore.value) {
      resetForm();
      entityForm.value?.focus(); // Focus on the first input
    } else {
      emit("saved", canReadAfterwards.value ? id : undefined);
      isVisible.value = false;
      modalFocus.removeFocused(modalId);
      emit("update:visible", false);
    }
  }
};

const prefilledFields = computed(() => permissionPrefilled.value.concat(props.prefillFields ?? []));

const breadcrumbs = computed(() => {
  if (props.isRelatedRecord) {
    const result = modalFocus.focused.slice(-4).map((m) => `${m.isEditing ? "Edit" : "Add"} ${m.collectionName}`);
    if (modalFocus.focused.length > 4) result.unshift("...");
    return result.join(" / ");
  } else {
    // ie don't show breadcrumbs at top level
    return undefined;
  }
});
</script>

<style scoped>
:deep(.create-more label) {
  font-size: 0.8rem;
  margin: -0.1rem 0 0 -0.25rem;
  padding: 0;
  color: var(--k-color-secondary);
}

.id-label {
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 25px;
}
.breadcrumbs {
  font-size: 0.65rem;
  color: var(--k-color-primary);
  margin-top: 0.5rem;
  margin-bottom: 0;
  line-height: 0;
}
</style>
