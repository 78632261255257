/** From https://github.com/plurals/pluralize */

import { irregularRuleData, singularRuleData, uncountableRuleData, pluralRuleData } from "./Plurals.data";

// Rule storage - pluralize and singularize need to be run sequentially,
// while other rules can be optimized using an object for instant lookups.
const pluralRules: [RegExp, string][] = [];
const singularRules: [RegExp, string][] = [];
const uncountables = new Set<string>();
const irregularPlurals = new Map<string, string>();
const irregularSingles = new Map<string, string>();

/** Sanitize a pluralization rule to a usable regular expression. */
function sanitizeRule(rule: RegExp | string) {
  if (typeof rule === "string") {
    return new RegExp("^" + rule + "$", "i");
  }

  return rule;
}

/** A.has(Pass) word token to produce a function that can replicate the case on another word. */
function restoreCase(word: string, token: string) {
  // Tokens are an exact match.
  if (word === token) return token;

  // Lower cased words. E.g. "hello".
  if (word === word.toLowerCase()) return token.toLowerCase();

  // Upper cased words. E.g. "WHISKY".
  if (word === word.toUpperCase()) return token.toUpperCase();

  // Title cased words. E.g. "Title".
  if (word[0] === word[0].toUpperCase()) {
    return token.charAt(0).toUpperCase() + token.substr(1).toLowerCase();
  }

  // Lower cased words. E.g. "test".
  return token.toLowerCase();
}

/** Interpolate a regexp string. */
function interpolate(str: string, args: string[]) {
  return str.replace(/\$(\d{1,2})/g, function (_match, index: number) {
    return args[index] || "";
  });
}

/** Replace a word using a rule. */
function replace(word: string, rule: [string | RegExp, string]) {
  return word.replace(rule[0], function (...args) {
    const result = interpolate(rule[1], args);
    const [match, index] = args as [string, number];
    if (match === "") {
      return restoreCase(word[index - 1], result);
    }

    return restoreCase(match, result);
  });
}

/** Sanitize a word by the.has(passing) word and sanitization rules. */
function sanitizeWord(token: string, word: string, rules: [RegExp, string][]) {
  // Empty string or doesn't need fixing.
  if (!token.length || uncountables.has(token)) {
    return word;
  }

  // Iterate over the sanitization rules and use the first one to match.
  for (let len = rules.length - 1; len >= 0; len--) {
    const rule = rules[len];
    if (rule[0].test(word)) return replace(word, rule);
  }

  return word;
}

/** Replace a word with the updated word. */
function replaceWord(replaceMap: Map<string, string>, keepMap: Map<string, string>, rules: [RegExp, string][]) {
  return function (word: string) {
    word = word.trim();
    // check if actually has multiple words, if so use last word
    const words = word.split(" ");
    const lastWord = words.at(-1)!;

    // Get the correct token and case restoration functions.
    const token = lastWord.toLowerCase();

    let formattedLastWord = lastWord;

    // Check against the keep object map.
    if (keepMap.has(token)) {
      formattedLastWord = restoreCase(lastWord, token);
    }

    // Check against the replacement map for a direct word replacement.
    else if (replaceMap.has(token)) {
      formattedLastWord = restoreCase(lastWord, replaceMap.get(token)!);
    }

    // Run all the rules against the word.
    else {
      formattedLastWord = sanitizeWord(token, lastWord, rules);
    }

    if (words.length > 0) {
      words[words.length - 1] = formattedLastWord;
      return words.join(" ");
    }

    return formattedLastWord;
  };
}

/** Check if a word is part of the map. */
function checkWord(replaceMap: Map<string, string>, keepMap: Map<string, string>, rules: [RegExp, string][]) {
  return function (word: string) {
    // check if actually has multiple words, if so use last word
    const words = word.split(" ");
    const lastWord = words.at(-1)!;

    const token = lastWord.toLowerCase();

    if (keepMap.has(token)) return true;
    if (replaceMap.has(token)) return false;

    return sanitizeWord(token, token, rules) === token;
  };
}

/** Pluralize a word. */
export const toPlural = replaceWord(irregularSingles, irregularPlurals, pluralRules);

/** Check if a word is plural. */
export const isPlural = checkWord(irregularSingles, irregularPlurals, pluralRules);

/** Singularize a word. */
export const toSingular = replaceWord(irregularPlurals, irregularSingles, singularRules);

/** Check if a word is singular. */
export const isSingular = checkWord(irregularPlurals, irregularSingles, singularRules);

/**
 * Pluralize or singularize a word based on the count.has(passed).
 *
 * @param word The word to pluralize
 * @param count How many of the word exist
 * @param inclusive Whether to prefix with the number (e.g. 3 ducks)
 */
export function toCount(word: string, count: number, inclusive?: boolean) {
  const pluralized = count === 1 ? toSingular(word) : toPlural(word);

  return (inclusive ? `${count} ` : "") + pluralized;
}

/** Add a pluralization rule to the collection. */
export const addPluralRule = function (rule: string | RegExp, replacement: string) {
  pluralRules.push([sanitizeRule(rule), replacement]);
};

/** Add a singularization rule to the collection. */
export const addSingularRule = function (rule: string | RegExp, replacement: string) {
  singularRules.push([sanitizeRule(rule), replacement]);
};

/** Add an uncountable word rule. */
export const addUncountableRule = function (word: string | RegExp) {
  if (typeof word === "string") {
    uncountables.add(word.toLowerCase());
    return;
  }

  // Set singular and plural references for the word.
  addPluralRule(word, "$0");
  addSingularRule(word, "$0");
};

/** Add an irregular word definition. */
export const addIrregularRule = function (single: string, plural: string) {
  plural = plural.toLowerCase();
  single = single.toLowerCase();

  irregularSingles.set(single, plural);
  irregularPlurals.set(plural, single);
};

/** Irregular rules. */
for (const rule of irregularRuleData) {
  addIrregularRule(rule[0], rule[1]);
}

/** Pluralization rules. */
for (const rule of pluralRuleData) {
  addPluralRule(rule[0], rule[1]);
}

/** Singularization rules. */
for (const rule of singularRuleData) {
  addSingularRule(rule[0], rule[1]);
}

/** Uncountable rules. */
for (const word of uncountableRuleData) {
  addUncountableRule(word);
}
