/**
 * Nicely formats a list of strings.
 *
 * @param things List of things to format
 * @param oxford Whether to use an o*ford comma (defaults to false since we're CAMBRIDGE Kinetics)
 * @returns Nicely formatted list of things (e.g. "apples, oranges, and bananas")
 */
export const formatList = (things: string[], options?: { oxford?: boolean; conjunction?: "and" | "or" }): string => {
  const total = things.length;
  const conjunction = options?.conjunction ?? "and";
  switch (total) {
    case 0:
      return "";
    case 1:
      return things[0];
    case 2:
      return `${things[0]} ${conjunction} ${things[1]}`;
    default:
      if (!options?.oxford) {
        return `${things.slice(0, -1).join(", ")} ${conjunction} ${things.at(-1)}`;
      }
      return `${things.slice(0, -1).join(", ")}, ${conjunction} ${things.at(-1)}`;
  }
};
