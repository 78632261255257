import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import { isAuthenticated } from "./services/auth";

import { peopleCollectionName } from "@data/constants/names";

const SignUp = () => import("./pages/auth/SignUpPage.vue");
const Dashboard = () => import("./pages/dashboard/DashboardPage.vue");
const People = () => import("./pages/people/PeoplePage.vue");
const PeopleConfig = () => import("./pages/people/configuration/PeopleConfiguration.vue");
const Settings = () => import("./pages/settings/SettingsPage.vue");
const PathMatch = () => import("./pages/PathMatch.vue");
const PrintableRecord = () => import("./pages/collections/records/print/PrintableRecord.vue");
const AcceptInvite = () => import("./pages/auth/AcceptInvitePage.vue");
const VerifyEmail = () => import("./pages/auth/VerifyEmailPage.vue");
const ForgotPassword = () => import("./pages/auth/ForgotPassword.vue");
const ResetPassword = () => import("./pages/auth/ResetPassword.vue");
const ErrorPage = () => import("./pages/ErrorPage.vue");
const NotFound = () => import("./pages/NotFound.vue");
const DataSources = () => import("./pages/settings/extensions/data-sources/DataSources.vue");

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { title: "Dashboard" }
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
    meta: { title: "Sign Up", anonymousOnly: true, useLoggedOutLayout: true, allowLoggedInLayout: true }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { title: "Forgot Password", anonymousOnly: true, useLoggedOutLayout: true }
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { title: "Reset Password", anonymousOnly: true, useLoggedOutLayout: true }
  },
  {
    path: `/${peopleCollectionName.slug}`,
    name: peopleCollectionName.plural.toLowerCase(),
    component: People,
    meta: { title: peopleCollectionName.plural }
  },
  {
    path: `/${peopleCollectionName.slug}/configure`,
    name: `${peopleCollectionName.singular}Configuration`,
    component: PeopleConfig,
    meta: { title: `${peopleCollectionName.singular} Configuration` }
  },
  {
    path: `/${peopleCollectionName.slug}/:id`,
    name: `${peopleCollectionName.singular}Details`,
    component: People,
    meta: { title: peopleCollectionName.plural }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { title: "Settings" }
  },
  {
    path: "/settings/extensions/:extensionId/data-sources",
    name: "Data Sources",
    component: DataSources,
    meta: { title: "Data Sources" }
  },
  {
    path: "/accept-invite/:token",
    name: "AcceptInvitation",
    component: AcceptInvite,
    meta: { title: "Accept Invitation", anonymousOnly: true, useLoggedOutLayout: true }
  },
  {
    path: "/verification/:token",
    name: "Verification",
    component: VerifyEmail,
    meta: { title: "Verification", useLoggedOutLayout: true }
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorPage,
    meta: { title: "Error", useLoggedOutLayout: true }
  },
  {
    path: "/c/:collection/:recordId/print",
    name: "PrintableRecord",
    component: PrintableRecord,
    meta: { printLayout: true }
  },
  {
    path: "/c/:pathMatch(.*)*",
    name: "Collections",
    component: PathMatch
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

/** Before navigating to each route, verify user is authorised */
router.beforeEach((to, from, next) => {
  // Remove any modals left behind when page navigates
  if (document.body.className.includes("modal-open")) {
    document.querySelector("body")?.classList.remove("modal-open");
    // eslint-disable-next-line unicorn/no-array-for-each
    document.querySelectorAll(".modal-backdrop").forEach((a) => a.remove());
  }
  if (isAuthenticated() && to.meta.anonymousOnly) {
    console.warn("User is already logged in, cannot navigate to anonymous only route");
    next({ name: "Dashboard" });
    return;
  }
  next();
});

router.afterEach(async (to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  if (isAuthenticated() && to.name !== "Collections") {
    await nextTick(() => {
      document.title = to.meta.title ? `${to.meta.title as string} - Kinabase` : "Kinabase";
    });
  }
});

export default router;
export { routes };
