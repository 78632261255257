import { isAccessible } from "../../expressions/accessors";
import { KField } from "../KField";
import { fieldRegistry } from "../FieldRegistry";
import { fromJson, toJson } from "../../helpers/serialisation/TypedJSON";
import { checkIsObject } from "../../helpers/NullHelpers";
import { SubField } from "../sub/SubField";

import type { JsonSerialisable } from "../../helpers/serialisation/TypedJSON";
import type { TableCell } from "../TableCell";
import type { RegisteredField } from "../FieldRegistry";
import type { KFieldValue, KRecord, StoredKRecord } from "../../data/Record";
import type { ExpressionField } from "../../expressions/accessors";
import type { Expression } from "../../expressions/expressions";
import type { MetricUnit } from "../../units/units";
import type { KFieldSetup, Sortable } from "../KField";
import type { DatetimePrecision } from "../basic/DatetimeField";

type FieldData = {
  type: string;
  data: string;
};
export type LookupFieldConfig = {
  targetCollection?: string;
  linkField?: string;
  targetField?: FieldData;
};
export type LookupFieldSetup = KFieldSetup & LookupFieldConfig;

export type LookupValue = {
  lookup?: KFieldValue;
  overridden?: boolean;
};

class LookupSubfield<V> extends SubField<V, LookupValue> implements ExpressionField {
  get lookupParent() {
    return this.parent as LookupField;
  }

  readonly expressionType: Expression["type"];

  constructor(
    getParent: () => LookupField,
    private targetSubfield: KField<V>
  ) {
    // remove the first part of the key
    const subFieldKey = targetSubfield.key.split(".").slice(1).join(".");
    // attempt to remove first part of the label
    const labelParts = targetSubfield.label.split(" - ");
    const targetLabel = labelParts.length > 1 ? `${getParent().label} - ${labelParts.slice(1).join(" - ")}` : targetSubfield.label;
    super(getParent, subFieldKey, targetLabel);
    this.expressionType = isAccessible(targetSubfield) ? targetSubfield.expressionType : "string";
  }

  getValue(record: KRecord): V | undefined {
    return this.lookupParent.withEffectiveRecord(record, (_tf, r) => this.targetSubfield.getValue(r));
  }

  getDisplayValue(record: KRecord): string {
    return this.lookupParent.withEffectiveRecord(record, (_tf, r) => this.targetSubfield.getDisplayValue(r)) ?? "";
  }

  getSortableValue(record: KRecord, toLowerCase?: boolean | undefined): Sortable {
    return this.lookupParent.withEffectiveRecord(record, (_tf, r) => this.targetSubfield.getSortableValue(r, toLowerCase));
  }

  validateType(value: unknown): value is V {
    return this.targetSubfield.validateType(value);
  }

  getExpressionValue(record: KRecord) {
    return this.lookupParent.withEffectiveRecord(record, (_tf, r) => {
      if (isAccessible(this.targetSubfield)) {
        return this.targetSubfield.getExpressionValue(r);
      }
      return this.targetSubfield.getDisplayValue(r);
    });
  }
}

export class LookupField extends KField<LookupValue> implements ExpressionField {
  readonly type = "lookup";

  readonly lookupConfig: LookupFieldSetup;

  get targetCollectionId() {
    return this.lookupConfig.targetCollection;
  }

  readonly expressionType: Expression["type"] = "string";

  granular?: boolean | undefined;

  unit?: MetricUnit | undefined;

  precision?: DatetimePrecision | undefined;

  idFor?: string | undefined;

  readonly targetField: KField | undefined;

  static storeFieldData(field: KField): FieldData {
    return { type: field.type, data: toJson(fieldRegistry.store(field as RegisteredField).data) };
  }

  static restoreFieldData(data: FieldData): KField {
    return fieldRegistry.restore({ type: data.type, data: fromJson(data.data) });
  }

  constructor(setup: string | LookupFieldSetup) {
    super(setup);
    this.lookupConfig = typeof setup === "string" ? this.setup : setup;
    if (this.lookupConfig.targetField instanceof KField) {
      this.targetField = this.lookupConfig.targetField;
    } else if (this.lookupConfig.targetField) {
      this.targetField = LookupField.restoreFieldData(this.lookupConfig.targetField);
    }
    if (this.targetField && isAccessible(this.targetField)) {
      this.expressionType = this.targetField.expressionType;
      this.granular = this.targetField.granular;
      this.unit = this.targetField.unit;
      this.precision = this.targetField.precision;
      this.idFor = this.targetField.idFor;
    }
    this.subfields = this.targetField?.subfields.map((sf) => new LookupSubfield(() => this, sf)) ?? [];
  }

  getValue(record: KRecord) {
    return record[this.key] as LookupValue | undefined;
  }

  /** Executes an operation on the target field safely */
  withEffectiveRecord<T>(record: KRecord, then: (tf: KField, r: KRecord) => T, value?: ReturnType<typeof this.getValue>): T | undefined {
    if (!this.targetField) {
      return undefined;
    }
    value ??= this.getValue(record);

    if (value != null && !this.targetField.validateType(value.lookup)) {
      console.error(`Invalid lookup value for ${this.targetField.type} field`, value.lookup);
      return undefined;
    }
    return then(this.targetField, { [this.targetField.key]: value?.lookup });
  }

  getSortableValue(record: KRecord, toLowerCase?: boolean | undefined): Sortable {
    return this.withEffectiveRecord(record, (tf, r) => tf.getSortableValue(r, toLowerCase));
  }

  getDisplayValue(record: KRecord): string {
    return this.withEffectiveRecord(record, (tf, r) => tf.getDisplayValue(r)) ?? "";
  }

  override getCSVColumns(): { subheader: string }[];

  override getCSVColumns(record: KRecord): { subheader: string; value: string }[];

  override getCSVColumns(record?: KRecord | undefined): { subheader: string; value: string }[] | { subheader: string }[] {
    if (record) {
      return this.withEffectiveRecord(record, (tf, r) => tf.getCSVColumns(r)) ?? [];
    }
    return this.targetField?.getCSVColumns() ?? [];
  }

  storeValue(source: KRecord, target: StoredKRecord): void {
    const value = this.getValue(source);
    if (value != null) {
      this.withEffectiveRecord(
        source,
        (tf, r) => {
          const blank: StoredKRecord = {};
          tf.storeValue(r, blank);
          if (blank[tf.key] != null) {
            target[this.key] = { lookup: blank[tf.key], overridden: value.overridden };
          }
        },
        value
      );
    }
  }

  restoreValue(source: StoredKRecord, target: KRecord): void {
    const value = source[this.key] as (Omit<LookupValue, "lookup"> & { lookup: JsonSerialisable }) | undefined;
    const lookup = value?.lookup;
    if (!lookup || !this.targetField) return;
    // can't use withEffectiveRecord here since the value's type won't be correct yet
    try {
      const blank: KRecord = {};
      this.targetField.restoreValue({ [this.targetField.key]: lookup }, blank);
      target[this.key] = { lookup: blank[this.targetField.key], overridden: value.overridden };
    } catch (e) {
      console.error(`Failed to restore lookup value for ${this.targetField.type} field`, e);
    }
  }

  getExpressionValue(record: KRecord) {
    return this.withEffectiveRecord(record, (tf, r) => {
      if (isAccessible(tf)) {
        return tf.getExpressionValue(r);
      }
      return tf.getDisplayValue(r);
    });
  }

  getTableCell(record: KRecord): TableCell | undefined {
    return this.withEffectiveRecord(record, (tf, r) => tf.getTableCell(r));
  }

  validateType(value: unknown): value is LookupValue {
    if (!checkIsObject(value)) return false;
    if (value.lookup != null && this.targetField && !this.targetField.validateType(value.lookup)) return false;
    if (value.overridden != null && typeof value.overridden !== "boolean") return false;
    return true;
  }
}
