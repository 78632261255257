// check for multiple operators and split by them e.g. "1 + 2 - 3" => [["1"], "+", ["2"], "-", ["3"]]
export const checkAndSplit = <const T extends readonly string[]>(tokens: string[], targets: T): (T[number] | string[])[] | undefined => {
  const result: (T[number] | string[])[] = [];
  let current: string[] = [];
  for (const token of tokens) {
    if (targets.includes(token)) {
      if (current.length > 0) {
        result.push(current);
        current = [];
      }
      if (result.length === 0) {
        throw new Error(`Invalid expression: ${token} cannot be used at start of expression`);
      }
      if (typeof result.at(-1) === "string") {
        throw new Error(`Invalid expression: operator ${targets.join(", ")} cannot be used more than once in a row`);
      }
      result.push(token);
    } else {
      current.push(token);
    }
  }
  if (current.length > 0) {
    result.push(current);
  } else {
    throw new Error(`Invalid expression: ${targets.join(", ")} cannot be used at end of expression`);
  }
  return result.length > 1 ? result : undefined;
};

// check whether exactly one of the target operations is present
export const checkOneOf = <const T extends readonly string[]>(
  tokens: string[],
  targets: T,
  exactlyOne = true
): { present: T[number]; indices: number[] } | undefined => {
  const indices: number[] = [];
  const present = new Set<T[number]>();
  for (const [i, token] of tokens.entries()) {
    if (targets.includes(token)) {
      indices.push(i);
      present.add(token as T[number]);
      if (present.size > 1) {
        throw new Error(`Invalid expression: ${targets.join(", ")} cannot be used at same time`);
      }
      if (exactlyOne && indices.length > 1) {
        throw new Error(`Invalid expression: ${targets.join(", ")} can only be used once`);
      }
    }
  }
  for (const p of present) {
    return { present: p, indices };
  }
};

export const splitTokens = (tokens: string[], indices: number[]): string[][] => {
  const result: string[][] = [];
  let start = 0;
  for (const i of indices) {
    result.push(tokens.slice(start, i));
    start = i + 1;
  }
  result.push(tokens.slice(start));
  return result;
};
