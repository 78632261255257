// Dimensionless units (e.g. radians, percentages...)

import { MetricUnit } from "../units";

export const dimensionless = {
  radians: new MetricUnit({
    symbol: "rad",
    name: "radians",
    dimensions: {},
    si: true
  }),
  degrees: new MetricUnit({
    symbol: "°",
    expressionSymbol: "deg",
    name: "degrees",
    dimensions: {},
    conversion: Math.PI / 180,
    space: false
  }),
  percentage: new MetricUnit({
    symbol: "%",
    name: "percent",
    dimensions: {},
    conversion: 0.01,
    space: false,
    removable: true
  }),
  ppm: new MetricUnit({
    symbol: "ppm",
    name: "parts per million",
    dimensions: {},
    conversion: 1e-6,
    space: false,
    removable: true
  })
};
