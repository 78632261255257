<template>
  <span v-if="plaintext" class="rich-text-viewer-plaintext mb-3">{{ actualContent }}</span>
  <div v-else-if="actualContent.length > 0" class="rich-text-viewer user-selectable mb-3">
    <editor-content :editor />
  </div>
  <p v-else class="use-selectable text-secondary">None</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import StarterKit from "@tiptap/starter-kit";
import { EditorContent, useEditor } from "@tiptap/vue-3";

const props = defineProps<{
  /** Rich text content to show */
  content?: string;
  /** Whether the rich text is in fact plaintext that we're pretending is rich text */
  plaintext?: boolean;
}>();

const actualContent = ref("");

const processValue = () => {
  if (props.plaintext && props.content) {
    const temp = document.createElement("div");
    temp.innerHTML = props.content;
    actualContent.value = temp.textContent || temp.innerText || "";
  } else {
    actualContent.value = props.content ?? "";
  }
};

processValue();

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const editor = useEditor({
  content: props.content,
  editable: false,
  extensions: [
    StarterKit.configure({
      heading: false,
      horizontalRule: false
    })
  ]
});

watch(
  () => props.content,
  (value) => {
    const isSame = editor.value?.getHTML() === value;
    if (isSame || !value) {
      return;
    }
    editor.value?.commands.setContent(value, false);
    processValue();
  }
);
</script>

<style scoped lang="scss">
.rich-text-viewer-plaintext {
  white-space: pre-wrap;
}

.rich-text-viewer :deep(p) {
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
}

.rich-text-viewer :deep(.ProseMirror) {
  -webkit-user-select: text;
  user-select: text;
  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: var(--bs-gray-600);
    pointer-events: none;
    height: 0;
  }
}

.rich-text-viewer :deep(blockquote) {
  padding-left: 0.5rem;
  border-left: 2px solid var(--k-color-secondary);
}
</style>
