export const capitalise = (word: string): string => {
  if (word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }
  return "";
};

export const toWords = (str: string) => str.replace(/[^\da-z]/gi, "|").split("|");

export const toCamelCase = (str: string) => {
  const words = toWords(str);
  return words.map((w, idx) => (idx ? capitalise(w) : w.toLowerCase())).join("");
};

export const toSlug = (name: string): string => {
  const words = toWords(name.toLowerCase());
  return words.join("-");
};
