import type { Ref } from "vue";

import { v4 as uuidv4 } from "uuid";

import type { OperationVariables } from "@apollo/client/core";
import type { UseMutationReturn, UseSubscriptionReturn } from "@vue/apollo-composable";

type OnResultCallback = (result: { data: { [key: string]: string } }) => void;
type APIGlobal = {
  currentGenerationIdGetter: () => string;
  onResultCallback: OnResultCallback | null;
};
type DataKey = "collectionNamesStreamUpdate" | "collectionStreamUpdate";
const apiGlobalsByDataKey: { [key in DataKey]: APIGlobal } = {
  collectionNamesStreamUpdate: {
    currentGenerationIdGetter: uuidv4,
    onResultCallback: null
  },
  collectionStreamUpdate: {
    currentGenerationIdGetter: uuidv4,
    onResultCallback: null
  }
};

function generate(fullJson: string, dataKey: DataKey, generationInterval: number) {
  const apiGlobal = apiGlobalsByDataKey[dataKey];
  return {
    mutate: async (argument: { generationId: string; prompt: string; proposedCollectionNames?: string[] }) =>
      new Promise((resolve) => {
        let i = 0;
        const interval = setInterval(() => {
          if (argument.generationId === apiGlobal.currentGenerationIdGetter() && apiGlobal.onResultCallback !== null) {
            apiGlobal.onResultCallback({ data: { [dataKey]: fullJson.substring(i, (i += 5)) } });
          }
          if (i > fullJson.length + 5) {
            clearInterval(interval);
            resolve(true);
          }
        }, generationInterval);
      })
  };
}

function onStreamUpdated(generationId: Ref<string>, dataKey: DataKey) {
  const apiGlobal = apiGlobalsByDataKey[dataKey];
  apiGlobal.currentGenerationIdGetter = () => generationId.value;
  return {
    onResult: (onResultCallback: OnResultCallback) => {
      apiGlobal.onResultCallback = onResultCallback;
    }
  };
}

// initialCollectionData MUTATION
const generateCollectionNames = () => {
  const namesJSON = JSON.stringify([
    { name: "my tasks", icon: "circle-check" },
    { name: "activities" },
    { name: "projects", icon: "rectangle-list" },
    { name: "simple field tests", icon: "circle" },
    { name: "bad data", icon: "face-disappointed" }
  ]);

  return generate(namesJSON, "collectionNamesStreamUpdate", 10) as UseMutationReturn<
    {
      prompt: string;
      generationId: string;
    },
    OperationVariables
  >;
};

// initialCollectionData SUBSCRIPTION
function onCollectionNamesStreamUpdated(generationId: Ref<string>) {
  return onStreamUpdated(generationId, "collectionNamesStreamUpdate") as UseSubscriptionReturn<
    {
      collectionNamesStreamUpdate: string;
    },
    OperationVariables
  >;
}

// collections MUTATION
function generateCollections() {
  const collectionsJSON = JSON.stringify([
    {
      name: "Projects",
      description: "adasjdaosf",
      fields: [
        { name: "name", type: "string", description: "its name" },
        { name: "tasks", type: "single-record" },
        {
          name: "colour",
          type: "option",
          options: [
            { label: "red", colour: "red" },
            { label: "green", colour: "green" },
            { label: "blue", colour: "blue" }
          ]
        }
      ]
    },
    {
      name: "My Tasks",
      description: "adasjdaosf",
      fields: [
        { name: "name", type: "string", description: "its name" },
        { name: "project", type: "single-record" },
        { name: "animal", type: "single-record" }
      ]
    },
    {
      name: "Bad Data",
      fields: [
        { name: "bad single-record", type: "single-record" },
        { name: "bad multiple-records", type: "multiple-records" },
        { name: "bad option field", type: "option" },
        {
          name: "bad option field option",
          type: "option",
          options: [{ label: "good option", colour: "green" }, { colour: "red" }]
        }
      ]
    },
    {
      name: "Simple Field Tests",
      fields: [
        { name: "string", type: "string" },
        { name: "richText", type: "richText" },
        { name: "number", type: "number" },
        { name: "probability", type: "probability" },
        { name: "currency", type: "currency" },
        { name: "boolean", type: "boolean" },
        { name: "date", type: "date" },
        { name: "month", type: "month" },
        { name: "year", type: "year" },
        { name: "datetime", type: "datetime" },
        { name: "location", type: "location" },
        { name: "email", type: "email" },
        { name: "website", type: "website" },
        { name: "phone", type: "phone" },
        { name: "person", type: "person" },
        { name: "people", type: "people" },
        { name: "image", type: "image" },
        { name: "file", type: "file" },
        { name: "percentage", type: "percentage" },
        { name: "degrees", type: "degrees" },
        { name: "length", type: "length" },
        { name: "mass", type: "mass" },
        { name: "time", type: "time" },
        { name: "data", type: "data" }
      ]
    }
  ]);
  return generate(collectionsJSON, "collectionStreamUpdate", 10) as UseMutationReturn<
    {
      prompt: string;
      generationId: string;
      proposedCollectionNames: string[];
    },
    OperationVariables
  >;
}

// collections SUBSCRIPTION
function onCollectionStreamUpdated(generationId: Ref<string>) {
  return onStreamUpdated(generationId, "collectionStreamUpdate") as UseSubscriptionReturn<
    {
      collectionStreamUpdate: string;
    },
    OperationVariables
  >;
}

export const MockCollectionGenerationAPI = {
  generateCollectionNames,
  onCollectionNamesStreamUpdated,
  generateCollections,
  onCollectionStreamUpdated
};
