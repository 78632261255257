<template>
  <div class="form-control-container currency-input">
    <k-label v-if="!config.hideLabel" :id="uuid" :label />
    <div class="input-group input-group-sm">
      <span :class="`input-group-text ${config.disabled ? 'disabled' : ''}`">{{ currencyUnit.symbol }}</span>
      <input
        :id="uuid"
        ref="inputRef"
        :class="classes"
        type="text"
        :aria-label="label"
        v-bind="commonBindings(config, label)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @change="$emit('change', $event)" />
    </div>
    <issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";
import { CurrencyDisplay, useCurrencyInput } from "vue-currency-input";
import type { NumberRange } from "vue-currency-input";

import { v4 } from "uuid";

import IssueDisplay from "../KIssueDisplay.vue";
import { useInputConfig, commonBindings, useInputClasses } from "../inputConfig";

import KLabel from "@ui/label/KLabel.vue";

import type { CurrencyType } from "@data/fields/basic/CurrencyField";
import { SIUnits } from "@data/units/basic/si";

const props = defineProps<{
  /** Label for the input */
  label?: string;
  /** Minimum value */
  min?: number;
  /** Maximum value */
  max?: number;
  /** Currency to use */
  currency?: CurrencyType;
}>();

defineEmits<{
  (e: "focus"): void;
  (e: "blur"): void;
  (e: "change", event: Event): void;
}>();

const config = useInputConfig();

const input = defineModel<number>();

const valueRange: NumberRange | undefined =
  // eslint-disable-next-line vue/no-setup-props-reactivity-loss
  props.min || props.max
    ? {
        min: props.min ?? undefined,
        max: props.max ?? undefined
      }
    : undefined;

const { inputRef, setValue } = useCurrencyInput({
  currency: "GBP",
  currencyDisplay: CurrencyDisplay.hidden,
  valueRange
});

watch(
  () => input.value,
  (value) => {
    setValue(value ?? null);
  }
);

onMounted(() => {
  if (inputRef.value && input.value) {
    (inputRef.value as HTMLInputElement).value = input.value.toString();
  }
});

//get currency
const currencyUnit = computed(() => SIUnits[props.currency ?? "sterling"]);

const classes = useInputClasses(config);
const uuid = v4();
</script>

<style scoped lang="scss">
.currency-input .input-group-text {
  background-color: var(--k-input-background);
  border-radius: 0.35rem 0 0 0.35rem;
}
.currency-input .input-group-text.disabled {
  background-color: var(--k-input-disabled-background);
}
</style>
