<template>
  <k-form
    ref="form"
    v-model="formValue"
    :fields="fieldInputs"
    :is-editing="isEditing"
    :readonly
    :entity
    :show-errors="showErrors"
    :prefill-fields="prefillFields"
    @update:valid="(isValid) => emit('update:valid', isValid)"
    @submit="onSubmit" />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { manageObjectVModel } from "../../helpers/vModel";

import KForm from "./KForm.vue";

import type { KEntity } from "@data/data/KEntity";
import type { KRecord } from "@data/data/Record";
import type { ConditionalValidator } from "@data/validation/ConditionalValidation";
import { groupValidatorsByField, getRequiredFieldIds } from "@data/validation/ConditionalValidation";

import type { KFormField, PrefilledField } from "./FormTypes";

const props = withDefaults(
  defineProps<{
    /** Entity to create a form from */
    entity: KEntity;
    /** Live value of the edited/new record */
    modelValue: KRecord;
    /** Whether the form is being edited */
    isEditing?: boolean;
    /** Sets all field inputs to readonly */
    readonly?: boolean;
    /** Whether to show validation errors */
    showErrors?: boolean;
    /** Prefill fields with values */
    prefillFields?: PrefilledField[];
    /** Extra validation rules to use */
    extraValidators?: ConditionalValidator[];
  }>(),
  {
    isEditing: true,
    prefillFields: () => [],
    extraValidators: () => []
  }
);

const emit = defineEmits<{
  (event: "update:modelValue", value: KRecord): void;
  (event: "update:valid", isValid: boolean): void;
  (event: "submit"): void;
}>();

// Filter list of fields based on whether they should be shown in a form
const filteredFields = computed(() =>
  props.entity.schema
    .filter((f) => !f.serverControlled && !f.form.ignore)
    .sort((a, b) => {
      if (a.form.fieldOrder !== undefined && b.form.fieldOrder !== undefined) {
        return a.form.fieldOrder - b.form.fieldOrder;
      }
      return 0;
    })
);

const fieldInputs = computed<KFormField[]>(() => {
  const allValidators = props.entity.validators.concat(props.extraValidators);
  const assigned = groupValidatorsByField(allValidators, props.entity, filteredFields.value);
  const requiredFieldIds = getRequiredFieldIds(allValidators, props.entity);
  const inputs = filteredFields.value.map<KFormField>((field) => ({
    field,
    props: {
      required: requiredFieldIds.has(field.id)
    },
    validator: assigned.get(field.id)
  }));
  return inputs;
});

// Init initial form values
const formValue = manageObjectVModel(
  computed(() => props.modelValue),
  emit
);

const onSubmit = () => {
  emit("submit");
};

const form = ref<{ focus: () => void } | null>(null);

onMounted(() => {
  form.value?.focus();
});

defineExpose({
  focus: () => form.value?.focus()
});
</script>
