import { ref } from "vue";

import { defineStore } from "pinia";

type RecordFocus = {
  modalId: string;
  collectionName: string; // used for breadcrumbs
  isEditing?: boolean; // used for breadcrumbs
};

export const useRecordModalFocus = defineStore("recordFocus", () => {
  // stack of modals with relevant info
  const focused = ref<RecordFocus[]>([]);
  const addFocused = (modalId: string, collectionName: string, isEditing?: boolean) => {
    focused.value.push({ modalId, collectionName, isEditing });
  };
  const removeFocused = (modalId: string) => {
    focused.value = focused.value.filter((f) => f.modalId !== modalId);
  };
  return { focused, addFocused, removeFocused };
});
