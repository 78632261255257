import { byte } from "../../units/basic/si";
import { SimpleField } from "../basic/SimpleFields";
import { toPlural } from "../../helpers/strings/Plurals";

import type { KRecord } from "../../data/Record";
import type { KFieldSetup, Sortable } from "../KField";

export type FileMetadata = {
  name: string;
  size: number;
  id: string;
};

interface FileFieldSetup extends KFieldSetup {
  accept?: string;
}

const fieldNameMap = new Map<string | undefined, string>([["image/*", "Image"]]);

const validateFileMetadata = (value: unknown): value is FileMetadata => {
  if (!value) {
    return false;
  }
  if (typeof value !== "object") {
    return false;
  }
  if (typeof (value as FileMetadata).name !== "string") {
    return false;
  }
  if (typeof (value as FileMetadata).size !== "number") {
    return false;
  }
  if (typeof (value as FileMetadata).id !== "string") {
    return false;
  }
  return true;
};

export class FileField extends SimpleField<FileMetadata> {
  readonly type = "file";

  accept: string | undefined;

  constructor(setup: FileFieldSetup | string) {
    super(setup);
    if (typeof setup !== "string") {
      this.accept = setup.accept;
    }
  }

  getSortableValue(record: KRecord, toLowerCase?: boolean | undefined): Sortable {
    const value = this.getValue(record);
    const name = value?.name ?? "";
    return toLowerCase ? name.toLowerCase() : name;
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    if (!value) {
      return "";
    }
    return `${value.name} (${byte.display(value.size)})`;
  }

  getDefaultColumnWidth() {
    return 90;
  }

  getFieldTypeName(): string {
    return fieldNameMap.get(this.accept) ?? "File";
  }

  validateType(value: unknown): value is FileMetadata {
    return validateFileMetadata(value);
  }
}

export class MultiFileField extends SimpleField<FileMetadata[]> {
  readonly type = "multiFile";

  accept: string | undefined;

  constructor(setup: FileFieldSetup | string) {
    super(setup);
    if (typeof setup !== "string") {
      this.accept = setup.accept;
    }
  }

  getSortableValue(record: KRecord): Sortable {
    const value = this.getValue(record);
    return value?.length || 0;
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    if (!value) {
      return "";
    }
    return value.map((file) => `${file.name} (${byte.display(file.size)})`).join(", ");
  }

  getDefaultColumnWidth() {
    return 120;
  }

  getFieldTypeName(): string {
    return toPlural(fieldNameMap.get(this.accept) ?? "File");
  }

  validateType(value: unknown): value is FileMetadata[] {
    return Array.isArray(value) && value.every(validateFileMetadata);
  }
}

export const isFileField = (field: unknown): field is FileField | MultiFileField => field instanceof FileField || field instanceof MultiFileField;
