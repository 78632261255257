import { useEventBus } from "@vueuse/core";

import type { EventBusKey } from "@vueuse/core";

export interface PlanUpgradeTrigger {
  reason?: string;
}

export const PlanUpgradeEvent: EventBusKey<PlanUpgradeTrigger> = Symbol();

export class PlanUpgradeHandler {
  /**
   * Function to add a new alert to the UI
   *
   * @param {Alert} alert Alert to show
   */
  public openUpgradeModal(reason: PlanUpgradeTrigger) {
    const bus = useEventBus(PlanUpgradeEvent);
    bus.emit(reason);
  }
}
