import { computed, inject, ref } from "vue";
import type { App, Ref } from "vue";

const navbarStateProvider = "navbarState";

export type NavbarState = "DEFAULT" | "EXPANDED" | "COMPACT" | "HIDDEN" | "OVERLAY" | "EDITING";

/**
 * Access the current navbar state and the function to update it
 *
 * @returns {NavbarState} The current navbar state
 */
export const useNavbarState = () => {
  const res = inject<{ state: Ref<NavbarState>; updateState: (state: NavbarState) => void }>(navbarStateProvider);

  const state = computed(() => res?.state.value ?? "DEFAULT");

  const expandedThreshold = 576;
  const compactThreshold = 576;
  const hiddenThreshold = 0;

  const canShowExpanded = () => document.body.clientWidth >= expandedThreshold;
  const canShowCompact = () => document.body.clientWidth >= compactThreshold;

  const getDefaultState = () => {
    if (canShowExpanded()) {
      return "EXPANDED";
    } else if (canShowCompact()) {
      return "COMPACT";
    } else {
      return "HIDDEN";
    }
  };

  const updateState = (s: NavbarState) => {
    if (s === "DEFAULT") {
      res?.updateState(getDefaultState());
    } else {
      res?.updateState(s);
    }
  };

  let prevWidth = document.body.clientWidth;

  const hasWidthCrossedThreshold = () => {
    const currWidth = document.body.clientWidth;

    const crossedUp =
      (prevWidth < expandedThreshold && currWidth >= expandedThreshold) ||
      (prevWidth < compactThreshold && currWidth >= compactThreshold) ||
      (prevWidth < hiddenThreshold && currWidth >= hiddenThreshold);

    const crossedDown =
      (prevWidth >= expandedThreshold && currWidth < expandedThreshold) ||
      (prevWidth >= compactThreshold && currWidth < compactThreshold) ||
      (prevWidth >= hiddenThreshold && currWidth < hiddenThreshold);

    prevWidth = currWidth;
    return crossedUp || crossedDown;
  };

  const onWindowResize = () => {
    if (hasWidthCrossedThreshold()) {
      updateState(getDefaultState());
    }
    return state.value;
  };

  const toggleState = () => {
    switch (state.value) {
      case "HIDDEN":
        updateState("OVERLAY");
        break;
      case "COMPACT":
        updateState(canShowCompact() ? "EXPANDED" : "OVERLAY");
        break;
      case "EXPANDED":
        updateState("COMPACT");
        break;
      case "OVERLAY":
        updateState("DEFAULT");
        break;
    }
  };

  return { state, updateState, getDefaultState, toggleState, onWindowResize };
};

/** Initialize the navbar state provider in the parent component, used in main.ts Must be called when creating the app */
export const initNavbarStateProvider = (defaultState?: NavbarState) => (app: App<Element>) => {
  const state = ref<NavbarState>(defaultState ?? "DEFAULT");

  function updateState(newState: NavbarState) {
    state.value = newState;
  }

  app.provide(navbarStateProvider, { state, updateState });

  return { state, updateState };
};
