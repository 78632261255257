<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="task-list mb-4">
    <!-- <k-table-selection-box v-if="visibleTasks.length > 1 && allowMultiselect" class="mt-1 mb-1 task-check" :state="selectionState" @input="checkboxChange" /> -->
    <k-label v-if="incompleteTasksToShow.length && title" class="pt-1 pb-2 mb-0 border-bottom" :label="title" />
    <vuedraggable
      v-if="allowReordering"
      :model-value="incompleteTasksToShow"
      item-key="id"
      :disabled="sortByDueDate || currentlyEditingTaskId != null || !allowReordering"
      :animation="100"
      group="task-list"
      @start="isDragging = true"
      @end="onReordered">
      <template #item="{ element }">
        <k-task-item v-bind="commonBindings(element)" v-on="commonEvents(element)" />
      </template>
    </vuedraggable>
    <template v-else>
      <k-task-item
        v-for="task in incompleteTasksToShow"
        :key="task.id"
        :draggable="draggable"
        v-bind="commonBindings(task)"
        v-on="commonEvents(task)"
        @dragstart="(evt: DragEvent) => startDrag(evt, task)"
        @dragend="endDrag"
    /></template>
    <k-task-item
      v-for="task in completeTasksToShow"
      :key="task.id"
      :draggable="draggable"
      v-bind="commonBindings(task)"
      v-on="commonEvents(task)"
      @dragstart="(evt: DragEvent) => startDrag(evt, task)"
      @dragend="endDrag" />
    <k-task-input v-if="allowAdding" :people :label="addTaskLabel" :margin-start="tasks.length > 0" :prefill @task-added="onAddTask" />
  </div>
  <k-context-menu ref="contextMenu" :items="contextMenuItems" />
  <k-context-menu ref="multiContextMenu" :items="multiMenuItems" />
  <k-task-selection-toolbar
    :selected-tasks="multiselectedTasks"
    :total-count="visibleTasks.length"
    :multi-context-menu-items="multiMenuItems"
    @clear="multiselection.clear()"
    @select-all="selectAll" />
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

import { v4 } from "uuid";
import vuedraggable from "vuedraggable";

import KContextMenu from "../context-menu/KContextMenu.vue";

import KLabel from "@ui/label/KLabel.vue";
import type { TableSelectionState } from "@ui/table/selection/TableSelectionManager";

import KTaskSelectionToolbar from "./KTaskSelectionToolbar.vue";
import KTaskInput from "./KTaskInput.vue";
import KTaskItem from "./KTaskItem.vue";

import type { KTask, KTaskToAdd, TaskStatus } from "@data/data/Task";
import type { PersonBadge } from "@data/fields/TableCell";
import { stringSorted, getSortOrder, getMovedSortOrder } from "@data/helpers/data/Reordering";
import { DefaultMap } from "@data/helpers/maps/DefaultMap";

import type { KContextMenuElement } from "../context-menu/ContextMenuElement";
import type { ContextMenuItem } from "../context-menu/ContextMenuItem";
import type { Dayjs } from "dayjs";

const props = defineProps<{
  /** Tasks to display */
  tasks: KTask[];
  /** People to display in the assignee dropdown */
  people?: PersonBadge[];
  /** Whether to show the "Add task" button */
  allowAdding?: boolean;
  /** Values to prefill in the add task form */
  prefill?: Partial<Pick<KTask, "name" | "description" | "dueDate" | "assignees">>;
  /** Whether to allow inline editing of tasks */
  allowInlineEditing?: boolean;
  /** Whether to allow reordering of tasks */
  allowReordering?: boolean;
  /** Whether to allow multiple selection / bulk actions */
  allowMultiselect?: boolean;
  /** Whether to show completed tasks */
  showCompleted?: boolean;
  /** Whether to sort tasks by due date */
  sortByDueDate?: boolean;
  /** Subtitles for tasks (with task ID keys) */
  subtitles?: Map<string, string>;
  /** Context menu items to show when right clicking on a row */
  extraContextMenuItems?: ContextMenuItem<KTask>[];
  /** Context menu items to show when right clicking on multiple rows */
  extraMultiContextMenuItems?: ContextMenuItem<KTask[]>[];
  /** Whether to show due dates */
  showDueDates?: boolean;
  /** Whether to show due dates */
  showScheduled?: boolean;
  /** Title for task lists (used in Dashboard) */
  title?: string;
  /** Whether the tasks are draggable outside (used for calendar views) */
  draggable?: boolean;
  /** Label for the "Add task" button */
  addTaskLabel?: string;
  /** Whether to hide the expand button */
  hideExpand?: boolean;
}>();

const emit = defineEmits<{
  (event: "taskChanged", value: KTask, changes: Partial<KTask>): void;
  (event: "bulkTaskChanged", value: KTask[], changes: Partial<KTask>): void;
  (event: "taskAdded", value: KTaskToAdd): void;
  (event: "taskRemoved", value: KTask): void;
  (event: "bulkTaskRemoved", value: KTask[]): void;
  (event: "taskReordered", value: KTask, newSortOrder: string): void;
  (event: "dragStart", evt: DragEvent, task: KTask): void;
  (event: "dragEnd"): void;
  (event: "selectTask", task: KTask): void;
}>();

// Display options
const sortedBySortOrder = computed(() => stringSorted(props.tasks, (t) => t.sortOrder));
const sortedTasks = computed(() => {
  if (props.sortByDueDate) {
    return [...sortedBySortOrder.value].sort((a, b) => {
      if (a.dueDate && b.dueDate) {
        return a.dueDate.diff(b.dueDate);
      }
      if (!a.dueDate && !b.dueDate) return 0;
      else if (a.dueDate) {
        return -1;
      } else {
        return 1;
      }
    });
  }
  return sortedBySortOrder.value;
});

const tempTasksToShow = ref<Set<string>>(new Set());

const selectionState = ref<TableSelectionState>("none");

const incompleteTasksToShow = computed(() => sortedTasks.value.filter((t) => tempTasksToShow.value.has(t.id) || t.status !== "COMPLETED"));

const completeTasks = computed(() => sortedTasks.value.filter((t) => t.status === "COMPLETED" && !tempTasksToShow.value.has(t.id)));

const completeTasksToShow = computed(() => (props.showCompleted ? completeTasks.value : []));

const visibleTasks = computed(() => incompleteTasksToShow.value.concat(completeTasksToShow.value));

// keep track of currently selected tasks
const multiselection = ref(new Map<string, boolean>());
const multiselectedTasks = computed(() => visibleTasks.value.filter((t) => multiselection.value.get(t.id) ?? false));

const selectAll = () => {
  for (const visible of visibleTasks.value) multiselection.value.set(visible.id, true);
};

/** Commenting this out because the checkbox is annoying Jason, and he has a plan for redesigning this. This code is still useful though! */
// const checkboxChange = () => {
//   // If all visible tasks selected then clear checkbox and therefore all selections.
//   if (visibleTasks.value.length === multiselection.value.size) multiselection.value.clear();
//   // Otherwise select All
//   else selectAll();
// };

/* Multiselection changes on user input by select
 * Visible tasks changes by added/removed/completed */
watch(
  [multiselection, visibleTasks],
  () => {
    const visibleSize = visibleTasks.value.length;
    switch (multiselection.value.size) {
      case 0:
        selectionState.value = "none";
        break;
      case visibleSize:
        selectionState.value = "all";
        break;
      default:
        selectionState.value = "some";
        break;
    }
  },
  { deep: true }
);

const onShiftClick = (task: KTask) => {
  const visible = visibleTasks.value;
  const isSelected = (t: KTask) => t.id === task.id || multiselection.value.get(t.id);
  const firstSelectedIndex = visible.findIndex(isSelected);
  if (firstSelectedIndex === -1) return;
  const lastSelectedIndex = visible.findLastIndex(isSelected);
  for (let i = firstSelectedIndex; i <= lastSelectedIndex; i++) {
    multiselection.value.set(visible[i].id, true);
  }
};

// Right clicking
const contextMenu = ref<KContextMenuElement<KTask>>();
const multiContextMenu = ref<KContextMenuElement<KTask[]>>();
const onRightClick = (evt: MouseEvent, task: KTask, attachedToParent?: boolean) => {
  evt.preventDefault();
  if (multiselectedTasks.value.length) {
    multiContextMenu.value?.show(evt, multiselectedTasks.value, attachedToParent);
  } else {
    contextMenu.value?.show(evt, task, attachedToParent);
  }
};

// Adding
const onAddTask = (task: { name: string; description: string; dueDate?: Dayjs; assignees: PersonBadge[] }) => {
  if (task.name.trim() === "") return;

  emit("taskAdded", {
    id: v4(),
    name: task.name,
    description: task.description,
    dueDate: task.dueDate,
    assignees: task.assignees,
    status: "NOT_STARTED",
    sortOrder: getSortOrder(sortedTasks.value.at(-1)?.sortOrder, undefined)
  } satisfies KTaskToAdd);
};

// Editing
const currentlyEditingTaskId = ref<string | null>(null);

const onTasksChanged = (tasks: KTask[], changes: Partial<KTask> & { dueDate?: Dayjs | null }) => {
  if (changes.status === "COMPLETED") {
    for (const task of tasks) {
      tempTasksToShow.value.add(task.id);
    }
    // Give it a while before hiding them from the UI
    setTimeout(() => {
      for (const task of tasks) {
        tempTasksToShow.value.delete(task.id);
        if (!props.showCompleted && multiselection.value.get(task.id)) multiselection.value.delete(task.id);
      }
    }, 500);
  }
  if (tasks.length === 1) {
    emit("taskChanged", tasks[0], changes);
  } else {
    emit("bulkTaskChanged", tasks, changes);
  }
};

// Draggable
const isDragging = ref(false);
const tasksById = computed(() => new Map(props.tasks.map((t) => [t.id, t])));
const onReordered = (e: { item: HTMLElement; newIndex: number; oldIndex: number }) => {
  if (props.sortByDueDate) return;
  const taskId = e.item.attributes.getNamedItem("data-tid")?.value;
  if (!taskId) return;
  const task = tasksById.value.get(taskId);
  if (!task) return;
  const newSortOrder = getMovedSortOrder(incompleteTasksToShow.value, (t) => t?.sortOrder, { old: e.oldIndex, new: e.newIndex }, completeTasks.value);
  emit("taskReordered", task, newSortOrder);
};

const startDrag = (evt: DragEvent, task: KTask) => {
  emit("dragStart", evt, task);
};

const endDrag = () => {
  emit("dragEnd");
};

const onSelectTask = (task: KTask) => {
  emit("selectTask", task);
};

const progressOptions: { status: TaskStatus; label: string; icon: string }[] = [
  { status: "NOT_STARTED", label: "Not started", icon: "circle" },
  { status: "IN_PROGRESS", label: "In progress", icon: "circle-half-stroke" },
  { status: "COMPLETED", label: "Completed", icon: "check-circle" }
];

// Context menu
const contextMenuItems = computed<ContextMenuItem<KTask>[]>(() => [
  {
    icon: "info-circle",
    label: "Show details",
    onClick: (task?: KTask) => {
      if (!task) return;
      onSelectTask(task);
    }
  },
  {
    type: "divider"
  },
  {
    icon: "calendar",
    label: "Due date",
    type: "date-time-picker",
    value: (task?: KTask) => task?.dueDate,
    setValue: (task?: KTask, date?: Dayjs) => {
      if (!task) return;
      emit("taskChanged", task, { dueDate: date });
    }
  },
  {
    icon: "check-circle",
    label: "Status",
    items: progressOptions.map(({ icon, label, status }) => ({
      icon,
      label,
      active: (task?: KTask) => task?.status === status,
      onClick: (task?: KTask) => {
        if (!task) return;
        onTasksChanged([task], { status });
      }
    }))
  },
  {
    icon: "users",
    label: "Assignee",
    items:
      props.people?.map((p) => ({
        label: p.fullName,
        active: (task?: KTask) => !!task?.assignees?.some((a) => a.id === p.id),
        onClick: (task?: KTask) => {
          if (!task) return;
          // check if the person is already assigned
          if (task.assignees?.some((a) => a.id === p.id)) {
            emit("taskChanged", task, {
              assignees: task.assignees.filter((a) => a.id !== p.id)
            });
          } else {
            emit("taskChanged", task, {
              assignees: [...(task.assignees ?? []), p]
            });
          }
        }
      })) ?? []
  },
  ...(props.extraContextMenuItems ?? []),
  {
    type: "divider",
    label: ""
  },
  {
    icon: "trash",
    label: "Delete",
    variant: "danger",
    onClick: (task?: KTask) => {
      if (!task) return;
      emit("taskRemoved", task);
    }
  }
]);

const multiMenuItems = computed(
  () =>
    [
      {
        icon: "calendar",
        label: "Due date",
        type: "date-time-picker",
        value: (tasks) => {
          const firstDate = tasks?.find((t) => t.dueDate)?.dueDate;
          if (tasks?.every((t) => !t.dueDate || t.dueDate.isSame(firstDate))) {
            return firstDate;
          }
          return undefined;
        },
        setValue: (tasks, date) => {
          if (!tasks) return;
          emit("bulkTaskChanged", tasks, { dueDate: date });
        }
      },
      {
        icon: "check-circle",
        label: "Status",
        items: progressOptions.map(({ icon, label, status }) => ({
          icon,
          label,
          active: (tasks) => !!tasks?.every((t) => t.status === status),
          onClick: (tasks) => {
            if (!tasks) return;
            onTasksChanged(tasks, { status });
          }
        }))
      },
      {
        icon: "users",
        label: "Assignee",
        items:
          props.people?.map((p) => ({
            label: p.fullName,
            active: (tasks) => !!tasks?.every((t) => t.assignees?.some((a) => a.id === p.id)),
            onClick: (tasks) => {
              if (!tasks) return;
              // note: can't do this in a single emit since tasks with different assignee sets will change differently
              // split into sets of tasks with the same assignees
              const adding = !tasks.every((t) => t.assignees?.some((a) => a.id === p.id));
              const tasksByAssignees = new DefaultMap<string, KTask[]>(() => []);
              for (const task of tasks) {
                const key =
                  task.assignees
                    ?.map((a) => a.id)
                    .sort()
                    .join(",") ?? "";
                tasksByAssignees.get(key).push(task);
              }
              for (const subtasks of tasksByAssignees.frozen().values()) {
                const first = subtasks[0];
                const hasTarget = !!first.assignees?.some((a) => a.id === p.id);
                if (hasTarget && !adding) {
                  emit("bulkTaskChanged", subtasks, {
                    assignees: subtasks[0].assignees?.filter((a) => a.id !== p.id)
                  });
                } else if (!hasTarget && adding) {
                  emit("bulkTaskChanged", subtasks, {
                    assignees: subtasks[0].assignees ? [...subtasks[0].assignees, p] : [p]
                  });
                }
              }
            }
          })) ?? []
      },
      ...(props.extraMultiContextMenuItems ?? []),
      {
        type: "divider"
      },
      {
        icon: "trash",
        label: "Delete all",
        variant: "danger",
        onClick: (tasks) => {
          if (!tasks) return;
          emit("bulkTaskRemoved", tasks);
        }
      }
    ] satisfies ContextMenuItem<KTask[]>[]
);

const commonBindings = (task: KTask) => ({
  task,
  subtitle: props.subtitles?.get(task.id),
  allowInlineEditing: props.allowInlineEditing,
  allowMultiselect: props.allowMultiselect,
  hideExpand: props.hideExpand,
  editable: (currentlyEditingTaskId.value == null || currentlyEditingTaskId.value === task.id) && props.allowInlineEditing,
  showDueDate: props.showDueDates,
  showScheduled: props.showScheduled,
  people: props.people,
  multiselected: multiselection.value.get(task.id) ?? false
});

const commonEvents = (task: KTask) => ({
  click: () => onSelectTask(task),
  rightClick: (evt: MouseEvent, buttonTrigger: boolean) => onRightClick(evt, task, buttonTrigger),
  shiftClick: () => onShiftClick(task),
  taskChanged: (change: Parameters<typeof onTasksChanged>[1]) => onTasksChanged([task], change),
  startEditing: () => (currentlyEditingTaskId.value = task.id),
  endEditing: () => (currentlyEditingTaskId.value = null),
  "update:multiselected": (value: boolean) => {
    if (value) multiselection.value.set(task.id, value);
    else multiselection.value.delete(task.id);
  }
});
</script>

<style scoped>
.task-list {
  max-width: 800px;
  margin: 0 auto;
}

.task-check {
  margin-left: 0.35em;
}

.task-list-form {
  display: block;
  line-height: 24px;
  padding: 0.35rem 0 0.35rem 0.25rem;
  font-size: 0.85rem;
  display: flex;
  position: relative;
}

.task-list-input {
  border: none;
  outline: none;
  background-color: var(--k-background);
  color: var(--k-color);
  padding: 0;
  margin: 0;
  margin-left: 14px;
  font-size: 0.85rem;
  line-height: 24px;
  flex-grow: 1;
}
</style>
