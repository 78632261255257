import type { Ref } from "vue";
import { computed, ref, watch, watchEffect } from "vue";

import type { SelectItem } from "@ui/inputs/select/SelectItem";

import type { getRelationalFields, RelationalFilters } from "@data/fields/relational/RecordFields";
import { deepEquals } from "@data/helpers/manipulation/Comparison";

type RelRef = Ref<ReturnType<typeof getRelationalFields>>;
export const useRelationalFilterLogic = (modelValue: Ref<RelationalFilters | undefined>, localRelationals: RelRef, foreignRelationals: RelRef) => {
  const commonCollectionOptions = computed(() => {
    const result: SelectItem[] = [];
    for (const thisField of localRelationals.value) {
      for (const otherField of foreignRelationals.value) {
        if (thisField.collectionId === otherField.collectionId) {
          result.push({
            label: thisField.label === otherField.label ? thisField.label : thisField.label + " = " + otherField.label,
            value: `${thisField.id} = ${otherField.id}`
          });
        }
      }
    }
    return result;
  });

  const commonCollection = ref<string>();
  const customFilters = ref<string[]>([]);

  watch(
    modelValue,
    (newValue) => {
      if (newValue) {
        customFilters.value = newValue.expressions;
        for (const relation of newValue.relations) {
          const oValue = `${relation.parentFieldId} = ${relation.foreignFieldId}`;
          if (commonCollectionOptions.value.some((option) => option.value === oValue)) {
            commonCollection.value = oValue;
            break;
          }
        }
      } else {
        customFilters.value = [];
        commonCollection.value = undefined;
      }
    },
    { immediate: true }
  );

  const parseRelation = (r: string): RelationalFilters["relations"][number] => {
    const [parentFieldId, foreignFieldId] = r.split(" = ");
    return { parentFieldId, foreignFieldId };
  };

  const targetFilter = computed<RelationalFilters | undefined>(() => {
    if (commonCollection.value) {
      return { expressions: customFilters.value, relations: [parseRelation(commonCollection.value)] };
    } else if (customFilters.value.length) {
      return { expressions: customFilters.value, relations: [] };
    }
    return undefined;
  });

  watchEffect(() => {
    if (!deepEquals(modelValue.value, targetFilter.value)) {
      modelValue.value = targetFilter.value;
    }
  });

  return {
    commonCollectionOptions,
    commonCollection,
    customFilters
  };
};
