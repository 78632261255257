<template>
  <div class="form-control-container">
    <k-label v-if="!config.hideLabel" :id :label />
    <div class="input-group input-group-sm password-input">
      <input
        :id
        ref="element"
        v-model="input"
        :class="classes"
        :type="showPassword ? 'text' : 'password'"
        :autocomplete
        :aria-label="label"
        v-bind="commonBindings(config, label)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @change="$emit('change', $event)" />
      <k-button :icon="showPassword ? 'eye-slash' : 'eye'" title="Show/hide password" tabindex="-1" @click="toggleShowPassword" />
      <span v-if="showPasswordStrength" :class="`password-indicator input-group-text bg-l-15-${securityLabel.variant} text-d-20-${securityLabel.variant}`">
        <span>{{ securityLabel.label }}</span>
      </span>
    </div>
    <issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { v4 } from "uuid";

import IssueDisplay from "../KIssueDisplay.vue";
import { useInputClasses, useInputConfig, commonBindings } from "../inputConfig";

import KLabel from "@ui/label/KLabel.vue";
import KButton from "@ui/button/KButton.vue";

import type { PasswordAutocompleteType } from "@data/fields/complex/PasswordField";
import { estimateEntropy } from "@data/helpers/strings/Entropy";

const props = withDefaults(
  defineProps<{
    /** ID override for labelling purposes */
    id?: string;
    /** Label for the input */
    label?: string;
    /** Whether to show the password strength indicator */
    showPasswordStrength?: boolean;
    /** Autocomplete property, as per https://www.chromium.org/developers/design-documents/create-amazing-password-forms/ */
    autocomplete?: PasswordAutocompleteType;
  }>(),
  {
    label: undefined,
    id: undefined,
    showPasswordStrength: true,
    autocomplete: "current-password"
  }
);

defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
}>();

const input = defineModel<string | undefined>();
const config = useInputConfig();

const element = ref<HTMLInputElement>();
const showPassword = ref<boolean>(false);

function toggleShowPassword(): void {
  showPassword.value = !showPassword.value;
}

const id = computed(() => props.id ?? v4());

const classes = useInputClasses(config);

const securityLabel = computed(() => {
  const password = input.value || "";
  const val = estimateEntropy(password);
  if (password.length === 0) {
    return { label: "No Password", variant: "gray" };
  } else if (val < 10 || password.length < 8) {
    return { label: "Very weak", variant: "red" };
  } else if (val < 50) {
    return { label: "Weak", variant: "orange" };
  } else if (val < 75) {
    return { label: "Medium", variant: "yellow" };
  } else if (val < 100) {
    return { label: "Strong", variant: "light-green" };
  } else {
    return { label: "Very strong", variant: "green" };
  }
});
</script>

<style scoped lang="scss">
/* styles text input to remove built-in show/hide password functionality on edge and IE */
.password-input {
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
}
.password-control {
  display: block;
  background-color: var(--k-input-background);
  color: var(--k-color);
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  padding: 0 0.75rem 0 0;
  :disabled {
    background-color: var(--k-input-disabled-background);
  }
}

.password-indicator {
  color: white;
  transition: all ease-in-out 0.1s;
  border: none;
  &.bg-light-green,
  &.bg-yellow {
    color: black;
  }
  > span {
    width: 80px;
    text-align: center;
  }
}
</style>
