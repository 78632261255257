<template>
  <i :class="iconClass" :title :data-testid="`icon-${parsedIcon.name}`"></i>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  /** Icon to show (e.g. "car") */
  icon: string;
  /** Title for the icon */
  title?: string;
}>();

const parsedIcon = computed(() => {
  if (props.icon.startsWith("fab-")) {
    return {
      type: "fab",
      name: props.icon.slice(4)
    };
  }
  return {
    type: "far",
    name: props.icon
  };
});

const iconClass = computed(() => `${parsedIcon.value.type} fa-fw fa-${parsedIcon.value.name}`);
</script>
