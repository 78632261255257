import { SimpleField } from "../basic/SimpleFields";

import type { UploadServerField, CollectionRecordPermissions } from "../../data/Collection";
import type { KRecord } from "../../data/Record";
import type { ExpressionField } from "../../expressions/accessors";
import type { ColourVariant } from "../../types/ColourVariant";
import type { KFieldSetup } from "../KField";
import type { TableCell } from "../TableCell";

export type NextStage = {
  stageId: string;
  actionLabel?: string;
  actionVariant?: ColourVariant;
  permissions: string[];
  requireSignature?: boolean;
  signatureDescription?: string; // rich text
  extraFields?: {
    schema: UploadServerField[];
    // validation?: ConditionalValidators
  };
};

export type Stage = {
  // can't be called id because C# doesn't like that
  stageId: string;
  label: string;
  variant?: ColourVariant;
  // whether this stage should be shown in tables by default
  active: boolean;
  nextStages: NextStage[];
  permissions?: CollectionRecordPermissions;
};

type StageFieldSetup = KFieldSetup & { enabled?: boolean };

export class StageField extends SimpleField<string> implements ExpressionField<"string"> {
  readonly type = "stage";

  stages: Stage[];

  stageMap: Map<string | undefined, Stage>;

  colourMap: Map<string, ColourVariant>;

  readonly expressionType = "string";

  readonly granular = true;

  enabled: boolean;

  sortFieldDirectionLabels = {
    ASC: "Start - End",
    DESC: "End - Start"
  };

  constructor(setup: StageFieldSetup | string, stages: Stage[]) {
    super(setup);
    this.stages = stages;
    this.enabled = (typeof setup === "string" ? undefined : setup.enabled) ?? true;
    this.setup.serverControlled = true;
    this.setup.table = { ...this.setup.table, hiddenByDefault: !this.enabled };
    this.stageMap = new Map(this.stages.map((o) => [o.stageId, o]));
    this.colourMap = new Map();
    for (const o of this.stages) {
      if (o.variant) {
        this.colourMap.set(o.stageId, o.variant);
        this.colourMap.set(o.label, o.variant);
      }
    }
  }

  get defaultStage() {
    return this.stages.find((o) => o.active);
  }

  getSortableValue(record: KRecord) {
    const stage = this.getStageValue(record);
    if (!stage) {
      return undefined;
    }
    return this.stages.indexOf(stage);
  }

  getStage(stageId: string) {
    return this.stageMap.get(stageId);
  }

  getStageName(stageId: string) {
    return this.getStage(stageId)?.label;
  }

  getStageValue(record: KRecord): Stage | undefined {
    const value = this.getValue(record);
    return value ? this.getStage(value) : this.defaultStage;
  }

  getDisplayValue(record: KRecord): string {
    return this.getStageValue(record)?.label ?? "";
  }

  getColourVariant(displayValue: string): ColourVariant | undefined {
    return this.colourMap.get(displayValue);
  }

  getExpressionValue(record: KRecord): string | undefined {
    return this.getDisplayValue(record);
  }

  getTableCell(record: KRecord): TableCell | undefined {
    const stage = this.getStageValue(record);
    if (stage) {
      return { type: "badge", text: stage.label, colour: stage.variant };
    }
    return undefined;
  }

  getFieldTypeName(): string {
    return this.enabled ? "Workflow Stage" : "Workflow Stage (disabled)";
  }

  validateType(value: unknown): value is string {
    return typeof value === "string" && this.stageMap.has(value);
  }

  override isAssignableTo(): boolean {
    return false;
  }
}
