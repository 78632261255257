<template>
  <div class="search-box-container ps-2" :class="state">
    <k-button
      v-if="state == 'compact'"
      :class="{ active: showSearch, 'search-button': true }"
      variant="transparent"
      icon="search"
      title="Search"
      @click="onShowSearch" />
    <Transition name="search-expansion" :css="shouldAnimate">
      <div v-if="showSearch || state == 'expanded' || input" class="search-box">
        <input
          :id="uuid"
          ref="searchInputElement"
          v-model="input"
          spellcheck="false"
          class="form-control"
          :class="`form-control-${size}`"
          :placeholder
          aria-label="Search"
          autocomplete="off"
          @focus="emit('focus')"
          @blur="emit('blur')"
          @change="emit('change', $event)" />
        <label :for="uuid" class="search-icon"></label>
        <button class="search-clear" :class="{ visible: showSearch || input }" @click="input = ''">
          <span class="visually-hidden">Clear search</span>
          <k-icon icon="close" />
        </button>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import { onClickOutside } from "@vueuse/core";
import { v4 } from "uuid";

import KButton from "@ui/button/KButton.vue";
import KIcon from "@ui/icon/KIcon.vue";

const props = withDefaults(
  defineProps<{
    /** Placeholder text for search box */
    placeholder?: string;
    /** Size of the search box */
    size?: "sm" | "md";
    /** Width of search component */
    state?: "compact" | "expanded";
  }>(),
  {
    modelValue: "",
    placeholder: "Search",
    size: "sm",
    state: "expanded"
  }
);

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:searchVisible", value: boolean): void;
}>();

const input = defineModel<string>();

const uuid = v4();

const showSearch = ref(false);
const searchInputElement = ref<HTMLElement>();

watch(
  () => props.state,
  () => {
    if (props.state === "compact" && input.value && !showSearch.value) {
      showSearch.value = true;
      emit("update:searchVisible", true);
    }
  }
);

const shouldAnimate = ref(false);

const onShowSearch = () => {
  showSearch.value = true;
  shouldAnimate.value = true;
  emit("update:searchVisible", true);
  setTimeout(() => {
    searchInputElement.value?.focus();
  }, 100);
};

onClickOutside(searchInputElement, () => {
  if (input.value) return;
  showSearch.value = false;
  emit("update:searchVisible", false);
  setTimeout(() => {
    shouldAnimate.value = false;
  }, 100);
});
</script>

<style lang="scss" scoped>
.search-box-container {
  position: relative;
  display: inline-block;
}

.search-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 180px;
}

.search-box-container.expanded .search-box {
  position: relative;
}

.search-box-container.compact {
  .search-expansion-enter-active {
    transition: all 0.15s ease-out;
  }

  .search-expansion-leave-active {
    transition: all 0.15s ease-in;
  }

  .search-expansion-enter-from,
  .search-expansion-leave-to {
    width: 30px;
    padding: 0;
    transform: translateX(-10px);
  }

  .search-expansion-leave-to {
    opacity: 0;
    transform: translateX(-5px);
  }
}

.search-box {
  input {
    padding-left: 28px;
  }

  input + label.search-icon {
    display: block;
    float: left;
  }

  input.form-control-sm + label.search-icon {
    margin-top: -1.78rem;
  }

  input.form-control-md + label.search-icon {
    margin-top: -2rem;
  }

  input + label.search-icon::before {
    transition: all 0.1s ease-in-out;
    content: "\f002";
    font-family: "Font Awesome 6 Pro";
    font-family: var(--fa-style-family, "Font Awesome 6 Pro");
    font-weight: 500;
    font-weight: var(--fa-style, 500);
    display: block;
    font-size: 0.7rem;
    color: var(--k-color-secondary);
    width: 28px;
    height: 28px;
    padding: 0 10px;
    line-height: 28px;
    z-index: 100;
    pointer-events: none;
  }
}

.search-clear {
  background: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  pointer-events: none;
}

.search-clear.visible {
  opacity: 1;
  pointer-events: all;
}
</style>
