<template>
  <button v-if="!isExpanded && !task" class="task-add-trigger py-2" @click="onExpand" @focus="onExpand">
    <span class="text-primary"><i class="fa fa-plus me-2 pe-1"></i> {{ label ?? "Add task..." }}</span>
  </button>
  <div v-else class="task-input border rounded mt-1" :class="{ 'ms-lg-4': marginStart }">
    <div class="d-flex">
      <k-content-editable ref="taskNameInput" v-model="taskName" class="task-name-input" @returned="onAddTask" />

      <span v-if="isPlaceholderVisible" class="text-secondary task-input-placeholder">Add task...</span>
      <k-button class="mt-1 me-1" tabindex="-1" variant="transparent" title="Cancel" icon="close" @click="onDismiss" />
    </div>
    <k-input-config hide-label placeholder="Description">
      <k-input-rich-text v-model="taskDescription" class="task-description-input" hide-grip />
    </k-input-config>
    <div class="d-flex px-2 py-2">
      <div>
        <k-button
          :variant="taskDueDate ? 'secondary' : 'dashed'"
          title="Schedule"
          :label="taskDueDate ? timestampDisplayValue : undefined"
          icon="calendar"
          class="me-2"
          @click="showTimePicker" />
        <k-context-menu ref="timePickerMenu" :items="timePickerOptions" />

        <k-button
          :variant="taskAssignees.length > 0 ? 'secondary' : 'dashed'"
          title="Assignee"
          :label="taskAssignees.length > 0 ? taskAssignees.length.toString() : undefined"
          icon="user"
          class="me-2"
          @click="showAssigneePicker" />
        <k-context-menu ref="assigneeMenu" :items="assigneeOptions" />
      </div>
      <k-people-badges size="sm" :people="taskAssignees" class="d-none d-lg-inline-block pt-1" />
      <div class="text-end flex-grow-1 ps-2">
        <k-button class="d-none d-lg-inline-block" variant="secondary" label="Cancel" @click="onDismiss" />

        <k-button class="ms-2" variant="primary" :disabled="!canSubmit" icon-right icon="arrow-turn-down-left" label="Save" @click="onAddTask" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from "vue";

import KInputRichText from "@ui/inputs/strings/KInputRichText.vue";
import KButton from "@ui/button/KButton.vue";
import KInputConfig from "@ui/inputs/KInputConfig.vue";
import type { KContextMenuElement } from "@ui/context-menu/ContextMenuElement";
import type { ContextMenuItem } from "@ui/context-menu/ContextMenuItem";
import KContextMenu from "@ui/context-menu/KContextMenu.vue";
import KPeopleBadges from "@ui/badge/KPeopleBadges.vue";
import KContentEditable from "@ui/inputs/strings/KContentEditable.vue";

import { useTaskDatePicker } from "./TaskDatePicker";

import type { PersonBadge } from "@data/fields/TableCell";
import type { KTask } from "@data/data/Task";
import { deepCopy } from "@data/helpers/manipulation/Copying";

import type { Dayjs } from "dayjs";

const props = defineProps<{
  /** People to show in dropdown */
  people?: PersonBadge[];
  /** Task to edit */
  task?: KTask;
  /** Values to prefill when adding */
  prefill?: Partial<Pick<KTask, "name" | "description" | "dueDate" | "assignees">>;
  /** Whether the input is expanded */
  expanded?: boolean;
  /** Label for the input */
  label?: string;
  /** Whether to show margin start, use with tasks above this */
  marginStart?: boolean;
}>();

const emit = defineEmits<{
  (
    event: "taskAdded",
    task: {
      name: string;
      description: string;
      dueDate?: Dayjs;
      assignees: PersonBadge[];
    }
  ): void;
  (event: "dismiss"): void;
}>();

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const isExpanded = ref(!!props.expanded);
const taskNameInput = ref<HTMLElement | null>(null);

const taskName = ref("");
const taskDescription = ref("");
const taskDueDate = ref<Dayjs>();
const taskAssignees = ref<PersonBadge[]>([]);

const isPlaceholderVisible = computed(() => !taskName.value.trim().length);
const canSubmit = computed(() => taskName.value.trim().length > 0);

const focusInput = () => {
  taskNameInput.value?.focus();
};

const clearTask = () => {
  taskName.value = props.prefill?.name ?? "";
  taskDescription.value = props.prefill?.description ?? "";
  taskDueDate.value = props.prefill?.dueDate;
  taskAssignees.value = deepCopy(props.prefill?.assignees ?? []);
};

onMounted(() => {
  if (props.task) {
    void nextTick(() => {
      focusInput();
    });
  } else {
    clearTask();
  }
});

watch(
  () => props.task,
  (newValue) => {
    if (newValue) {
      taskName.value = newValue.name;
      taskDescription.value = newValue.description ?? "";
      taskDueDate.value = newValue.dueDate;
      taskAssignees.value = deepCopy(newValue.assignees ?? []);
    }
  },
  { immediate: true }
);

const onExpand = async () => {
  isExpanded.value = true;
  await nextTick(() => {
    focusInput();
  });
};

const onAddTask = () => {
  if (!taskName.value || !canSubmit.value) return;
  emit("taskAdded", {
    name: taskName.value,
    description: taskDescription.value,
    dueDate: taskDueDate.value,
    assignees: deepCopy(taskAssignees.value)
  });

  if (!props.task) {
    clearTask();

    setTimeout(focusInput, 100);
  }
};

const onDismiss = () => {
  clearTask();
  isExpanded.value = false;
  emit("dismiss");
};

const timePickerMenu = ref<KContextMenuElement<Date>>();
const { timestampDisplayValue, showTimePicker, timePickerOptions } = useTaskDatePicker(taskDueDate, timePickerMenu);

const assigneeMenu = ref<KContextMenuElement<PersonBadge | undefined>>();
const showAssigneePicker = (evt: MouseEvent) => {
  assigneeMenu.value?.show(evt, undefined, { alignment: "start", direction: "bottom" });
};

const assigneeOptions = computed<ContextMenuItem<PersonBadge>[]>(
  () =>
    props.people?.map(
      (person) =>
        ({
          label: person.fullName,
          active: taskAssignees.value.some((a) => a.id === person.id),
          onClick: () => {
            if (taskAssignees.value.some((a) => a.id === person.id)) {
              taskAssignees.value = taskAssignees.value.filter((a) => a.id !== person.id);
            } else {
              taskAssignees.value.push(person);
            }
          }
        }) satisfies ContextMenuItem<PersonBadge>
    ) ?? []
);
</script>

<style scoped>
.task-input {
  background-color: var(--k-background);
  position: relative;
}

.task-add-trigger {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: text;
}

.task-name-input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 0.85rem;
  padding: 0.5rem 0.6rem 0.5rem;
  border-radius: 4px;
  background-color: var(--k-background);
  font-weight: 600;
  line-height: 1.25;
}

:deep(.form-control:focus) {
  box-shadow: none;
}

.task-description-input {
  width: auto;
}

.task-input-placeholder {
  position: absolute;
  top: 0.5rem;
  left: 0.6rem;
  font-size: 0.85rem;
  user-select: none;
  pointer-events: none;
}

.task-description-input :deep(.form-control) {
  border: none;
  border-radius: 4px;
  padding: 0 0.6rem 0.3rem;
  background-color: transparent;
  z-index: 1;
  min-height: auto;
  box-shadow: none;
}
</style>
