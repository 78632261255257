import { SimpleField } from "../basic/SimpleFields";

import type { ExpressionField } from "../../expressions/accessors";
import type { CollectionIdConfig } from "../../data/Collection";
import type { KRecord } from "../../data/Record";
import type { Sortable } from "../KField";

export class IdField extends SimpleField<string> implements ExpressionField<"number"> {
  readonly type = "id";

  readonly expressionType = "number";

  get visible(): boolean {
    return this.format.visible;
  }

  constructor(public format: CollectionIdConfig) {
    super({ label: "ID", key: "id", serverControlled: true });
  }

  getSortableValue(record: KRecord): Sortable {
    return this.getValue(record);
  }

  getDisplayValue(record: KRecord): string {
    const id = this.getValue(record);
    if (id === undefined) {
      // should never happen...
      return "";
    }
    return (this.format.prefix ?? "") + (this.format.digits ? id.padStart(this.format.digits, "0") : id);
  }

  getSearchValue(record: KRecord): string | undefined {
    return this.visible ? this.getDisplayValue(record) : undefined;
  }

  getDefaultColumnWidth(): number {
    let width = 30;
    if (this.format.prefix) {
      width += this.format.prefix.length * 8;
    }
    if (this.format.digits) {
      width += this.format.digits * 8;
    }
    return Math.max(width, 50);
  }

  getExpressionValue(record: KRecord): number | undefined {
    const value = this.getValue(record);
    if (value === undefined) {
      return undefined;
    }
    const intValue = parseInt(value);
    return isNaN(intValue) ? undefined : intValue;
  }

  validateType(value: unknown): value is string {
    return typeof value === "string";
  }
}

export class GuidField extends SimpleField<string> implements ExpressionField<"string"> {
  readonly type = "guid";

  readonly expressionType = "string";

  constructor(
    setup: ConstructorParameters<typeof SimpleField<string>>[0],
    public readonly idFor: string
  ) {
    super(setup);
    this.idFor = idFor;
  }

  getSortableValue(record: KRecord): Sortable {
    return this.getValue(record);
  }

  getDisplayValue(record: KRecord): string {
    return this.getValue(record) ?? "";
  }

  getExpressionValue(record: KRecord) {
    return this.getValue(record);
  }

  validateType(value: unknown): value is string {
    return typeof value === "string";
  }
}
