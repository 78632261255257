import { expressionParser } from "../../expressions/parser";

import { SimpleComputedField } from "./ComputedField";

import type { KContext } from "../../expressions/context";
import type { KRecord } from "../../data/Record";
import type { KFieldSetup, Sortable } from "../KField";
import type { Expression } from "../../expressions/expressions";

export class ComputedStringField extends SimpleComputedField<"string"> {
  readonly type = "computedString";

  readonly expressionType = "string";

  constructor(setup: string | KFieldSetup, expression: string | undefined) {
    super(setup, expression);
  }

  getSortableValue(record: KRecord, toLowerCase: boolean): Sortable {
    const value = this.getValue(record)?.lastValue;
    return toLowerCase ? value?.toLowerCase() : value;
  }

  getDisplayValue(record: KRecord): string {
    return this.getValue(record)?.lastValue ?? "";
  }

  getParsedExpression(context: KContext): Expression | undefined {
    return this.expression ? expressionParser.parseString(this.expression, context) : undefined;
  }

  getDefaultColumnWidth(): number {
    return 150;
  }
}
