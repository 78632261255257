<template>
  <div class="form-control-container">
    <k-label :label />
    <div class="d-flex mt-n2">
      <k-input-config :placeholder="quarter ? 'Quarter' : 'Month'" sticky="right" no-container required v-bind="passedConfig">
        <k-input-select v-model="monthInput" :options="quarter ? quarterOptions : monthOptions" class="flex-grow-1"
      /></k-input-config>
      <k-input-config placeholder="Year" sticky="left" v-bind="passedConfig" no-container>
        <k-input-year v-model="yearInput" class="flex-grow-1"
      /></k-input-config>
    </div>
    <k-issue-display />
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect, computed, watch } from "vue";

import KInputConfig from "../KInputConfig.vue";
import KIssueDisplay from "../KIssueDisplay.vue";
import { useInputConfig } from "../inputConfig";
import KInputSelect from "../select/KInputSelect.vue";

import KLabel from "@ui/label/KLabel.vue";

import KInputYear from "./KInputYear.vue";

import type { SelectItem } from "../select/SelectItem";
import type { Dayjs } from "dayjs";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: Dayjs;
  /** Label for the input */
  label?: string;
  /** Use Quarters instead of Months */
  quarter?: boolean;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", newValue: Dayjs | undefined): void;
}>();

const config = useInputConfig();

const passedConfig = computed(() => ({
  status: config.value.status,
  disabled: config.value.disabled,
  readonly: config.value.readonly
}));

const monthOptions: SelectItem[] = [
  { label: "January", value: "0" },
  { label: "February", value: "1" },
  { label: "March", value: "2" },
  { label: "April", value: "3" },
  { label: "May", value: "4" },
  { label: "June", value: "5" },
  { label: "July", value: "6" },
  { label: "August", value: "7" },
  { label: "September", value: "8" },
  { label: "October", value: "9" },
  { label: "November", value: "10" },
  { label: "December", value: "11" }
];
const quarterOptions: SelectItem[] = [
  { label: "Q1", value: "0" },
  { label: "Q2", value: "3" },
  { label: "Q3", value: "6" },
  { label: "Q4", value: "9" }
];
const yearInput = ref<Dayjs>();
const monthInput = ref<string>();

watchEffect(() => {
  yearInput.value = props.modelValue;
  if (props.quarter) {
    const month = props.modelValue?.month();
    monthInput.value = month === undefined ? undefined : (month - (month % 3)).toString();
  } else {
    monthInput.value = props.modelValue?.month().toString();
  }
});

const result = computed(() => {
  if (yearInput.value && monthInput.value) {
    return yearInput.value.month(parseInt(monthInput.value));
  }
  return undefined;
});

watch(result, (newVal, oldVal) => {
  if (!newVal?.isSame(oldVal)) {
    emit("update:modelValue", newVal);
  }
});
</script>
