import { KField } from "../KField";
import { BlankCell, type IconCell, type TableCell } from "../TableCell";
import { formatReasonableNumber } from "../../helpers/strings/Precision";

import type { ValidationIssues } from "../../validation/Validation";
import type { RatingRange } from "../../types/RatingRange";
import type { ExpressionField } from "../../expressions/accessors";
import type { KRecord, StoredKRecord } from "../../data/Record";
import type { JsonSerialisable } from "../../helpers/serialisation/TypedJSON";
import type { ColourVariant } from "../../types/ColourVariant";
import type { KFieldSetup, StaticFieldValue } from "../KField";

export abstract class SimpleField<V extends JsonSerialisable> extends KField<V> {
  constructor(setup: KFieldSetup | string) {
    super(setup);
  }

  getValue(record: KRecord): V | undefined {
    return record[this.key] as V | undefined;
  }

  getStaticValue(record: KRecord): StaticFieldValue {
    return {
      ...super.getStaticValue(record),
      rawValue: this.getValue(record)
    };
  }

  storeValue(source: KRecord, target: StoredKRecord): void {
    const value = this.getValue(source);
    if (value != null) {
      target[this.key] = value;
    }
  }

  restoreValue(source: StoredKRecord, target: KRecord): void {
    target[this.key] = source[this.key];
  }
}

type BooleanDisplayType = "TEXT" | "ICON" | "ICON_TRUE_ONLY" | "ICON_FALSE_ONLY";

interface BooleanSetup extends KFieldSetup {
  trueLabel?: string;
  falseLabel?: string;
  displayType?: BooleanDisplayType;
}

export class BooleanField extends SimpleField<boolean> implements ExpressionField<"boolean"> {
  readonly type = "boolean";

  trueLabel: string;

  falseLabel: string;

  // cache the icons for performance
  trueIcon: IconCell;

  falseIcon: IconCell;

  displayType: BooleanDisplayType;

  readonly expressionType = "boolean";

  constructor(setup: string | BooleanSetup) {
    super(setup);
    const booleanSetup = typeof setup === "string" ? { label: setup } : setup;
    this.trueLabel = booleanSetup.trueLabel ?? "Yes";
    this.falseLabel = booleanSetup.falseLabel ?? "No";
    this.trueIcon = {
      type: "icon",
      icon: "check",
      colour: "success",
      title: this.trueLabel
    };
    this.falseIcon = {
      type: "icon",
      icon: "xmark",
      colour: "danger",
      title: this.falseLabel
    };
    this.displayType = booleanSetup.displayType ?? "TEXT";
  }

  getValue(record: KRecord): boolean {
    return !!record[this.key];
  }

  getSortableValue(record: KRecord): boolean | undefined {
    return this.getValue(record);
  }

  getDisplayValue(record: KRecord): string {
    return this.getValue(record) ? this.trueLabel : this.falseLabel;
  }

  getExpressionValue(r: KRecord): boolean | undefined {
    return this.getValue(r);
  }

  getColourVariant(displayValue: string): ColourVariant | undefined {
    switch (displayValue) {
      case this.trueLabel:
        return "green";
      case this.falseLabel:
        return "red";
    }
  }

  getTableCell(record: KRecord): TableCell | undefined {
    const value = this.getValue(record);
    switch (this.displayType) {
      case "TEXT":
        return super.getTableCell(record);
      case "ICON":
        return value ? this.trueIcon : this.falseIcon;
      case "ICON_TRUE_ONLY":
        return value ? this.trueIcon : BlankCell;
      case "ICON_FALSE_ONLY":
        return value ? BlankCell : this.falseIcon;
    }
  }

  getDefaultColumnWidth() {
    return 80;
  }

  validateType(value: unknown): value is boolean {
    return typeof value === "boolean";
  }
}

export interface NumericFieldSetup extends KFieldSetup {
  defaultValue?: number;
}

export abstract class NumericField extends SimpleField<number> implements ExpressionField<"number"> {
  formatOptions?: Intl.NumberFormatOptions;

  defaultValue?: number;

  readonly expressionType = "number";

  constructor(setup: string | NumericFieldSetup) {
    super(setup);
    if (typeof setup === "object") {
      this.defaultValue = setup.defaultValue;
    }
  }

  getSortableValue(record: KRecord): number | undefined {
    return this.getValue(record);
  }

  getDisplayValue(record: KRecord): string {
    return this.getValue(record)?.toLocaleString("en-GB", this.formatOptions) ?? "";
  }

  getExpressionValue(r: KRecord): number | undefined {
    return this.getValue(r);
  }

  getDefaultValue(): number | undefined {
    return this.defaultValue;
  }

  getTableCell(record: KRecord): TableCell | undefined {
    const display = this.getDisplayValue(record);
    return display ? { type: "text", text: display, align: "right" } : undefined;
  }

  validateType(value: unknown): value is number {
    return typeof value === "number";
  }
}

export class NumberField extends NumericField {
  readonly type = "number";

  getDisplayValue(record: KRecord): string {
    return formatReasonableNumber(this.getValue(record));
  }
}

export type RatingFieldConfig = RatingRange & {
  inputType?: "range" | "radio";
};
type RatingSetup = NumericFieldSetup & RatingFieldConfig;

export class RatingField extends NumericField {
  readonly type = "rating";

  readonly range: RatingRange;

  readonly inputType: RatingFieldConfig["inputType"];

  constructor(setup: RatingSetup) {
    super(setup);
    this.range = setup;
    this.inputType = setup.inputType ?? "range";
  }

  validate(r: KRecord): ValidationIssues {
    const value = this.getValue(r);
    if (!value) return [];
    if (value < this.range.min || value > this.range.max) {
      return [{ severity: "error", message: `Rating must be between ${this.range.min} and ${this.range.max}` }];
    }
    const step = this.range.step ?? 1;
    if (step === 1 && value % 1 !== 0) {
      return [{ severity: "error", message: "Rating must be a whole number" }];
    } else if ((value - this.range.max) % step !== 0) {
      return [{ severity: "error", message: `Invalid - value must increment in steps of ${this.range.step} from ${this.range.min}` }];
    }
    return [];
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    const relevantLabel = this.range.labels?.find((l) => l.value === value)?.label;
    return relevantLabel ? `${value} (${relevantLabel})` : super.getDisplayValue(record);
  }

  validateType(value: unknown): value is number {
    return super.validateType(value) && Number.isInteger(value);
  }
}

// NOTE: v2 probabilities are 0-1 rather than 0-100
interface ProbabilitySetup extends NumericFieldSetup {
  /** Determines whether higher probabilities are better Affects the colour scale (red is bad, green is good) */
  higherIsBetter?: boolean;
}
export class ProbabilityField extends NumericField {
  readonly type = "probability";

  formatOptions = { style: "percent" } as const;

  higherIsBetter = true;

  constructor(setup: ProbabilitySetup | string) {
    super(setup);
    if (typeof setup === "object") {
      this.higherIsBetter = setup.higherIsBetter ?? true;
    }
  }

  validate(r: KRecord): ValidationIssues {
    const value = this.getValue(r);
    if (value == null) return [];
    if (value < 0 || value > 1) {
      return [{ severity: "error", message: "Probability must be between 0 and 100%" }];
    }
    return [];
  }

  validateType(value: unknown): value is number {
    return super.validateType(value) && value >= 0 && value <= 1;
  }
}
