import dayjs from "dayjs";

export const parseHistoricDate = (date: string) => {
  const dt = dayjs(date);
  // if year is before 1848, add 1 minute and 15 seconds FOR SOME BIZARRE REASON
  if (dt.year() < 1848) {
    return dt.add(1, "minute").add(15, "second");
  }
  return dt;
};
