import { computed, ref, watch } from "vue";
import type { Ref } from "vue";

import { useElementBounding, useScroll } from "@vueuse/core";

import { getScrollParent } from "./GetScrollParent";

/**
 * Gets the opacity of the scroll indicator based on the scroll position Opacity varies from 0 to 1 depending on the scroll position
 *
 * @param element An element to get the scroll indicator for
 * @returns OpacityTop is for the top scroll indicator OpacityBottom is for the bottom scroll indicator Top is the top position of the scroll indicator Bottom
 *   is the bottom position of the scroll indicator
 */
export const useScrollIndicator = (element: Ref<HTMLElement | undefined>) => {
  const parentElement = ref(getScrollParent(element.value));

  watch(element, () => {
    parentElement.value = getScrollParent(element.value);
  });

  const { y, arrivedState } = useScroll(parentElement);
  const { top, height, bottom } = useElementBounding(parentElement, { windowResize: true });

  const fadeThreshold = 20;
  const opacityTop = computed(() => {
    if (arrivedState.top) {
      return 0;
    }
    return Math.min(y.value / fadeThreshold, 1);
  });

  const opacityBottom = computed(() => {
    const yVal = Math.floor((element.value?.scrollHeight ?? 0) - height.value - y.value);
    if (yVal <= 0) {
      return 0;
    }
    return Math.min(yVal / fadeThreshold, 1);
  });

  return { opacityTop, opacityBottom, top, bottom, arrivedState: {} };
};
