import { ref, watch } from "vue";
import type { ComputedRef, Ref } from "vue";

import type { ReadonlyRef } from "@ui/helpers/refHelpers";

type ObjectLike = Record<string, unknown>;

const hasPrefilled = <O extends ObjectLike | undefined>(obj: O, prefill: O) => {
  if (!obj) {
    return !prefill;
  }
  return !prefill || Object.keys(prefill).every((k) => k in obj);
};

/** Maintain a reactive copy of an object, and emit changes to the parent component. */
export const manageObjectVModel = <O extends ObjectLike | undefined>(
  fromProps: ComputedRef<O>,
  emit: { (event: "update:modelValue", value: O): void },
  prefill?: ReadonlyRef<O>
) => {
  const state = ref<O>(fromProps.value) as Ref<O>;
  const lastEmitted = ref<O>();
  const justReset = ref(false);

  // initialise and reset state
  watch(
    fromProps,
    (value) => {
      if (value !== lastEmitted.value) {
        justReset.value = hasPrefilled(value, prefill?.value);
        state.value = value && { ...prefill?.value, ...value };
      }
    },
    { immediate: true }
  );

  if (prefill) {
    // also reset state when prefill changes
    watch(prefill, (value) => {
      justReset.value = hasPrefilled(state.value, value);
      state.value = value && { ...value, ...fromProps.value };
    });
  }

  watch(
    state,
    (value) => {
      if (justReset.value) {
        justReset.value = false;
        return;
      }
      const toEmit = value && { ...value };
      lastEmitted.value = toEmit;
      emit("update:modelValue", toEmit);
    },
    { immediate: true, deep: true }
  );

  return state;
};
