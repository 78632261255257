import { computed, ref, toValue } from "vue";
import type { MaybeRef, Ref } from "vue";

import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";

import type { DocumentNode } from "@apollo/client/core";

interface PaginatedQueryParam {
  prop: string;
  type: string;
}

const constructPaginatedQuery = (
  queryName: string,
  queryProperties: string,
  queryParams: PaginatedQueryParam[],
  options?: {
    pageSize?: number;
    timestamp?: boolean;
  }
) => {
  const formattedQueryParam = queryParams.map((p) => `$${p.prop}: ${p.type},`);
  const formattedResultParam = queryParams.map((p) => `${p.prop}: $${p.prop},`);
  return gql`
    query ${queryName}(${formattedQueryParam} $endCursor: String) {
      ${queryName}(${formattedResultParam}, ${options?.timestamp ? "order: { timestamp: DESC }" : ""}, first: ${options?.pageSize ?? 20}, after: $endCursor) {
        nodes {
          ${queryProperties}
        }
        pageInfo {
          hasNextPage
          startCursor
          endCursor
        }
      }
    }
  `;
};

function usePaginatedQuery<T extends { id: string }>(queryName: string, query: DocumentNode, props: MaybeRef<object>, enabled?: Ref<boolean>) {
  const nextPageRef = ref<string>();

  // Use map to store results, avoids duplicates and allows us to update existing records
  const activityMap = ref(new Map<string, T>()) as Ref<Map<string, T>>; // need cast to avoid unwrapped type
  const result = computed(() => Array.from(activityMap.value.values()));
  // Use query
  const { loading, onResult, refetch } = useQuery<{
    [queryName: string]:
      | {
          nodes: T[];
          pageInfo: {
            hasNextPage: boolean;
            startCursor: string;
            endCursor: string;
          };
        }
      | undefined;
  }>(
    query,
    props,
    computed(() => ({
      fetchPolicy: "cache-and-network",
      enabled: !enabled || enabled.value
    }))
  );

  // Handle new results
  onResult((res) => {
    if ((res.data as unknown) == null) {
      return;
    }
    const resultData = res.data[queryName];
    if (resultData?.pageInfo.hasNextPage) {
      nextPageRef.value = resultData.pageInfo.endCursor;
    } else {
      nextPageRef.value = "";
    }

    if (resultData?.nodes) {
      for (const a of resultData.nodes) {
        activityMap.value.set(a.id, a);
      }
    }
  });

  /**
   * Returns true if there is a next page of results
   *
   * @returns {boolean}
   * @readonly
   */
  const hasNextPage = computed(() => nextPageRef.value !== "");

  /** Loads the next page of results */
  const loadNextPage = async () => {
    if (!nextPageRef.value) return;
    await refetch({ endCursor: nextPageRef.value, ...toValue(props) });
  };

  const deleteById = (id: string) => {
    activityMap.value.delete(id);
  };

  return { result, loading, hasNextPage, loadNextPage, refetch, deleteById };
}

export { usePaginatedQuery, constructPaginatedQuery };
