import { MetricUnit } from "../units";

// note: ampere is in the SI base units
const voltage = new MetricUnit({
  symbol: "V",
  name: "volts",
  si: true,
  dimensions: { mass: 1, length: 2, time: -3, current: -1 }
});

const charge = new MetricUnit({
  symbol: "C",
  name: "couloumbs",
  si: true,
  dimensions: { current: 1, time: 1 }
});

const resistance = new MetricUnit({
  symbol: "Ω",
  name: "ohms",
  si: true,
  dimensions: { mass: 1, length: 2, time: -3, current: -2 }
});

const capacitance = new MetricUnit({
  symbol: "F",
  name: "farads",
  si: true,
  dimensions: { mass: -1, length: -2, time: 4, current: 2 }
});

const inductance = new MetricUnit({
  symbol: "H",
  name: "henrys",
  si: true,
  dimensions: { mass: 1, length: 2, time: -2, current: -2 }
});

// add energy / power here as well

const energy = new MetricUnit({
  symbol: "J",
  name: "joules",
  si: true,
  dimensions: { mass: 1, length: 2, time: -2 }
});

const power = new MetricUnit({
  symbol: "W",
  name: "watts",
  si: true,
  dimensions: { mass: 1, length: 2, time: -3 }
});

export const electricUnits = {
  voltage,
  charge,
  resistance,
  capacitance,
  inductance,
  energy,
  power
};
