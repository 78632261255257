<template>
  <k-input-multiselect
    v-model="input"
    :label
    :options="field.options ?? []"
    :can-add="true"
    :add-loading="addLoading"
    :search-filter="searchFilter"
    :has-more="hasMore"
    :loading
    @update-options="
      (newOpt: string) => {
        updateOptions(newOpt);
      }
    " />
</template>

<script setup lang="ts">
import { ref } from "vue";

import type { MultiselectItem } from "@ui/inputs/select/SelectItem";
import KInputMultiselect from "@ui/inputs/select/KInputMultiselect.vue";

import type { Collection } from "@data/data/Collection";
import type { MultiOptionField } from "@data/fields/basic/OptionFields";

import { useCollectionStore } from "@/api/stores/useCollectionStore";

const props = defineProps<{
  /** Field */
  field: MultiOptionField;
  /** Collection to use */
  collection: Collection;
  /** Label for the input */
  label?: string;
  /** Whether autocomplete results are loading */
  loading?: boolean;
  /** Whether more results are available via searching */
  hasMore?: boolean;
  /** Custom search filter to use */
  searchFilter?: (value: MultiselectItem, filterValue: string) => boolean;
}>();

const input = defineModel<readonly string[]>({ default: [] });
const addLoading = ref(false);

const collectionStore = useCollectionStore();

const updateOptions = async (newOpt: string) => {
  try {
    addLoading.value = true;
    const oldInput = input.value;
    const newFieldResponse = await collectionStore.addOptionToField({
      collectionId: props.collection.id,
      targetFieldId: props.field.id,
      optionLabel: newOpt
    });
    if (!newFieldResponse) throw new Error("Field Failed to Add");
    input.value = [...oldInput, newFieldResponse.value];
  } finally {
    addLoading.value = false;
  }
};
</script>
