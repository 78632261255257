import { v4 } from "uuid";

import type { ReadonlyRef } from "@ui/helpers/refHelpers";

import type { Collection, UploadServerField } from "@data/data/Collection";
import { restoreCollection } from "@data/data/Collection";
import type { RegisteredField } from "@data/fields/FieldRegistry";
import { fieldRegistry } from "@data/fields/FieldRegistry";
import type { KField } from "@data/fields/KField";
import { toJson } from "@data/helpers/serialisation/TypedJSON";
import { MultiOptionField } from "@data/fields/basic/OptionFields";

import {
  useFieldAdding,
  useFieldUpdating,
  useAddOptionToField,
  useFieldReordering,
  useFieldDeleting,
  useUpdatePrimaryField,
  useUpdateSecondaryField,
  useUpdateSortField,
  useUpdateSortDirection
} from "@/api/field";

import type { AddOptionToFieldInput } from "@/api/field";

export const toServerField = (f: KField, withId = false): UploadServerField => {
  const stored = fieldRegistry.store(f as RegisteredField);
  const id = f.id === f.key ? v4() : f.id;
  return {
    key: f.key,
    type: stored.type,
    data: toJson(stored.data),
    ...(withId ? { id } : {})
  };
};

export const useFieldMethods = (collectionMap: ReadonlyRef<Map<string, Collection> | undefined>) => {
  const { mutate: addFieldMutation } = useFieldAdding();

  const addField = async (collectionId: string, field: KField) => {
    const result = await addFieldMutation({ value: { collectionId, field: toServerField(field, true) } });
    const fieldId = result?.data?.addCollectionField.id;
    if (fieldId) {
      const collection = collectionMap.value?.get(collectionId);
      if (collection) {
        // check field has not already been added via subscription
        if (collection.getField(fieldId)) return;
        field.id = fieldId;
        collection.fields.push(field);
      } else {
        throw new Error(`Couldn't update local state: Collection with ID ${collectionId} not found.`);
      }
    }
  };

  const { mutate: updateFieldMutation } = useFieldUpdating();

  const updateField = async (collectionId: string, oldKey: string, field: KField) => {
    const result = await updateFieldMutation({ value: { collectionId, oldKey, newField: toServerField(field) } });
    const updatedCollection = result?.data?.updateCollectionField;
    if (updatedCollection) {
      collectionMap.value?.set(collectionId, restoreCollection(updatedCollection));
      return true;
    }
  };

  const { mutate: addOptionToFieldMutation } = useAddOptionToField();

  const addOptionToField = async (input: AddOptionToFieldInput) => {
    const response = await addOptionToFieldMutation({ input });
    const updatedCollection = response?.data?.addOptionToField;

    if (!updatedCollection) return;
    const restored = restoreCollection(updatedCollection);
    collectionMap.value?.set(input.collectionId, restored);
    const updatedField = restored.getFieldsOfType(MultiOptionField).find((x) => x.id === input.targetFieldId);
    return updatedField?.options.find((x) => x.label === input.optionLabel);
  };

  const { mutate: reorderFieldsMutation } = useFieldReordering();

  const reorderFields = async (collectionId: string, newIdOrder: string[]) => {
    const result = await reorderFieldsMutation({ value: { collectionId, newFieldIdOrder: newIdOrder } });
    const success = result?.data?.reorderCollectionFields;
    if (success) {
      const collection = collectionMap.value?.get(collectionId);
      if (collection) {
        const newFieldOrder: KField[] = [];
        for (const id of newIdOrder) {
          const field = collection.getField(id);
          if (field) {
            newFieldOrder.push(field);
          } else {
            throw new Error(`Couldn't update local state: Field with ID ${id} not found.`);
          }
        }
        collection.fields = newFieldOrder;
        return true;
      } else {
        throw new Error(`Couldn't update local state: Collection with ID ${collectionId} not found.`);
      }
    }
  };

  const { mutate: deleteFieldMutation } = useFieldDeleting();

  const deleteField = async (collectionId: string, field: KField) => {
    const result = await deleteFieldMutation({ value: { collectionId, key: field.key } });
    const success = result?.data?.deleteCollectionField;
    if (success) {
      const collection = collectionMap.value?.get(collectionId);
      if (collection) {
        const fieldIndex = collection.fields.findIndex((f) => f.key === field.key);
        // Don't throw error if not found since it may have already been updated.
        if (fieldIndex !== -1) collection.fields.splice(fieldIndex, 1);
        return true;
      } else {
        throw new Error(`Couldn't update local state: Collection with ID ${collectionId} not found.`);
      }
    }
  };

  const { mutate: updatePrimaryFieldMutation } = useUpdatePrimaryField();

  const updatePrimaryField = async (collectionId: string, field: KField) => {
    const result = await updatePrimaryFieldMutation({ collectionId, fieldId: field.id });
    const success = result?.data?.updateCollectionPrimaryField;
    if (success) {
      const collection = collectionMap.value?.get(collectionId);
      if (collection) {
        if (collection.secondaryField?.id === field.id) {
          collection.secondaryField = collection.primaryField;
        }
        collection.primaryField = field;
        return true;
      } else {
        throw new Error(`Couldn't update local state: Collection with ID ${collectionId} not found.`);
      }
    }
  };

  const { mutate: updateSecondaryFieldMutation } = useUpdateSecondaryField();

  const updateSecondaryField = async (collectionId: string, field: KField | undefined) => {
    const result = await updateSecondaryFieldMutation({ collectionId, fieldId: field?.id });
    const success = result?.data?.updateCollectionSecondaryField;
    if (success) {
      const collection = collectionMap.value?.get(collectionId);
      if (collection) {
        collection.secondaryField = field;
        return true;
      } else {
        throw new Error(`Couldn't update local state: Collection with ID ${collectionId} not found.`);
      }
    }
  };

  const { mutate: updateSortFieldMutation } = useUpdateSortField();

  const updateSortField = async (collectionId: string, field: KField) => {
    const result = await updateSortFieldMutation({ collectionId, fieldId: field.id });
    const success = result?.data?.updateCollectionSortField;
    if (success) {
      const collection = collectionMap.value?.get(collectionId);
      if (collection) {
        collection.sortField = field;
        return true;
      } else {
        throw new Error(`Couldn't update local state: Collection with ID ${collectionId} not found.`);
      }
    }
  };

  const { mutate: updateSortDirectionMutation } = useUpdateSortDirection();

  const updateSortDirection = async (collectionId: string, direction: "ASC" | "DESC") => {
    const result = await updateSortDirectionMutation({ collectionId, direction });
    const success = result?.data?.updateCollectionSortDirection;
    if (success) {
      const collection = collectionMap.value?.get(collectionId);
      if (collection) {
        collection.defaultSortDirection = direction;
        return true;
      } else {
        throw new Error(`Couldn't update local state: Collection with ID ${collectionId} not found.`);
      }
    }
  };

  return {
    addField,
    updateField,
    addOptionToField,
    reorderFields,
    deleteField,
    updatePrimaryField,
    updateSecondaryField,
    updateSortField,
    updateSortDirection
  };
};
