import { computed, ref, unref, watchEffect } from "vue";

import { defineStore } from "pinia";

import { useInitialState } from "../initial";

import { type Person } from "@data/data/Person";
import { globalUsers } from "@data/fields/relational/UserField";

import { isUnitTest } from "@/tests/helpers/testFlag";
import { useAuth } from "@/services/auth";

import type { MaybeRef } from "@vueuse/core";

export const usePeopleStore = defineStore("people", () => {
  const people = ref<Map<string, Person>>(new Map());
  const users = ref<Map<string, Person>>(new Map());
  const peopleList = computed(() => Array.from(people.value.values()).filter((p) => p.status !== "DEACTIVATED"));
  // should never have 0 people normally
  const loading = computed(() => people.value.size === 0);

  const loadData = (data: readonly Person[]) => {
    people.value = new Map(data.map((p) => [p.id, p]));
    users.value = new Map(data.map((p) => [p.userId, p]));
    globalUsers.clear();
    for (const p of data) globalUsers.set(p.userId, p);
  };

  if (!isUnitTest) {
    const { people: statePeople } = useInitialState();
    watchEffect(() => {
      if (statePeople.value) {
        loadData(statePeople.value);
      }
    });
  }

  const getPerson = (id: string | undefined) => {
    if (id === undefined) return undefined;
    return people.value.get(id);
  };

  const usePerson = (id: MaybeRef<string | undefined>) => computed(() => getPerson(unref(id)));

  const getPersonByUserId = (id: string | undefined) => {
    if (id === undefined) return undefined;
    return users.value.get(id);
  };

  const usePersonByUserId = (id: MaybeRef<string | undefined>) => computed(() => getPersonByUserId(unref(id)));

  const useCurrentPerson = () => {
    const { user } = useAuth();
    return computed(() => getPersonByUserId(user.value?.id));
  };

  return {
    loadData,
    peopleList,
    loading,
    getPerson,
    usePerson,
    getPersonByUserId,
    usePersonByUserId,
    useCurrentPerson
  };
});
