<template>
  <div class="px-4 pt-4 pb-3 pricing-box flex-fill m-2 rounded" :class="`pricing-${planCode}`">
    <div class="d-flex flex-column h-100">
      <h6 v-if="title === 'Current Plan'" :class="`text-${planCode == 'pro' ? 'info' : 'secondary'}`">Current Plan</h6>
      <h6 v-else :class="`text-${planCode == 'pro' ? 'info' : 'secondary'}`">{{ title }}</h6>
      <h4>{{ plan?.label ?? capitalise(planCode) }}</h4>
      <p>{{ planDescriptions[planCode] }}</p>
      <ul v-if="plan" class="mb-3">
        <li v-for="feature in planFeatureList" :key="feature">{{ feature }}</li>
      </ul>
      <hr class="mt-auto" />
      <p v-if="currentFrequency === 'MONTHLY' && pricing?.monthly" class="fw-bold">{{ sterling.display(pricing.monthly) }} / user per month</p>
      <p v-if="currentFrequency === 'YEARLY' && pricing?.yearly" class="fw-bold">{{ sterling.display(pricing.yearly) }} / user per year</p>
      <div v-if="planCode === currentPlanCode">
        <k-button
          v-if="currentFrequency === 'MONTHLY'"
          label="Switch to annual billing (and save 15%)"
          variant="secondary"
          :loading="switchFreqLoading"
          @click="switchFrequency('YEARLY')" />
        <k-button v-else label="Switch to monthly billing" :loading="switchFreqLoading" @click="switchFrequency('MONTHLY')" />
      </div>
      <div v-else-if="canChangePlan">
        <k-button
          :variant="isUpgrade ? 'success' : 'secondary'"
          :icon="isUpgrade ? 'chevron-right' : undefined"
          icon-right
          :label="getTitle(planCode)"
          :loading="switchPlanLoading"
          @click="switchToThis" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import KButton from "@ui/button/KButton.vue";

import { capitalise } from "@data/helpers/strings/Casing";
import { byte } from "@data/units/basic/si";
import { sterling } from "@data/units/basic/si";
import type { BillingFrequency } from "@data/data/tenant/Billing";
import type { PlanCode } from "@data/data/tenant/Tenant";

import { BillingAPI } from "@/api";

const props = defineProps<{
  /** The plan code to display */
  planCode: PlanCode;
  /** The current plan code */
  currentPlanCode: PlanCode;
  /** The current billing frequency */
  currentFrequency: BillingFrequency;
  /** Whether the user can change to this plan */
  canChangePlan?: boolean;
}>();

const emit = defineEmits<{
  /** Emitted after a user has made a plan change */
  (e: "plan-changed"): void;
}>();

const { result: details } = BillingAPI.getPlanDetails(computed(() => props.planCode));

const planHierachy: Record<PlanCode, number> = {
  starter: 0,
  charity: 1,
  pro: 2,
  enterprise: 3,
  infinite: 4
};

const planDescriptions: Record<PlanCode, string> = {
  starter: "Perfect for small businesses who want to get organised and stay in control.",
  charity: "Only available to registered charities. Has most of the features of the Pro plan, but at a reduced price.",
  pro: "Designed for growing businesses, with advanced functionality and unlimited records.",
  enterprise: "Designed for larger businesses, with priority support and a dedicated account manager.",
  infinite: "The ultimate plan. Includes all features, and a dedicated account manager."
};

const plan = computed(() => details.value?.plan);

const pricing = computed(() => details.value?.planPricing);

const getTitle = (targetPlan: PlanCode) => {
  if (targetPlan === props.currentPlanCode) {
    return "Current Plan";
  }
  if (planHierachy[targetPlan] > planHierachy[props.currentPlanCode]) {
    return "Upgrade to " + capitalise(targetPlan);
  }
  return "Downgrade to " + capitalise(targetPlan);
};

const title = computed(() => getTitle(props.planCode));

const isUpgrade = computed(() => planHierachy[props.planCode] > planHierachy[props.currentPlanCode]);

const planFeatureList = computed(() => {
  if (!plan.value) return undefined;
  const result = [
    `${plan.value.recordLimit ? `Up to ${plan.value.recordLimit}` : "Unlimited"} records, activities and tasks`,
    plan.value.automationLimit ? "Access to all core and advanced features" : "Access to all core features",
    `${byte.display(plan.value.storageLimit)} storage`
  ];
  if (plan.value.access.extensions) {
    result.push("Automations");
    result.push("Access to integrations");
    result.push("Microsoft Outlook add-in");
  }
  if (plan.value.access.eventLog) {
    result.push("Advanced security, with Microsoft 365 single sign-on and audit logging");
  }
  return result;
});

const { mutate: switchPlan, loading: switchPlanLoading } = BillingAPI.updateTenantPlan();
const switchToThis = async () => {
  await switchPlan({
    planCode: props.planCode
  });
  emit("plan-changed");
};

const { mutate: switchPlanFreq, loading: switchFreqLoading } = BillingAPI.updateTenantPlanFrequency();
const switchFrequency = async (frequency: BillingFrequency) => {
  await switchPlanFreq({
    newFrequency: frequency
  });
  emit("plan-changed");
};
</script>

<style scoped lang="scss">
.pricing-box {
  border-top: 4px solid #1488a9;
  background: var(--k-background);
  width: 300px;
}

.pricing-starter {
  border-color: #ccc;
}

.pricing-pro {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.pricing-box li {
  margin-bottom: 4px;
}
</style>
