import type { Ref } from "vue";

import type { TextColourVariant } from "@data/types/ColourVariant";

import type { Dayjs } from "dayjs";

type ContextMenuDivider = {
  type: "divider";
};

type BaseContextMenuItem<T> = {
  /** The text to display in the menu item */
  label: string | ((record?: T) => string);

  /**
   * Font awesome icon name (excluding fa- prefix) to show on menu item Can be looked up based on the record if a function is provided
   *
   * @default null
   */
  icon?: string | ((record?: T) => string);

  /** (Optional) Colour variant to use for the menu item */
  variant?: TextColourVariant | ((record?: T) => TextColourVariant);

  /**
   * Callback function for when the user clicks on the menu item
   *
   * @param record The currently selected record
   */
  onClick?: (record?: T) => Promise<void> | void;

  /** Function to determine if the item should be visible based on the record */
  showOption?: ((record?: T) => boolean) | "has-items";

  /** Function to determine if the item should be disabled (greyed out) based on the record */
  disableOption?: (record?: T) => boolean;

  /**
   * Boolean indicating if a loading indicator should be shown against the current item Note: This can be a Vue Ref for reactivity, or a boolean
   *
   * @type {boolean | Ref<boolean>}
   */
  loading?: boolean | Ref<boolean>;

  /** Boolean indicating whether current item should be highlighted in the menu */
  active?: boolean | ((record?: T) => boolean);

  /** List of sub items to show when hovering over the item */
  items?: ContextMenuItem<T>[] | ((record?: T) => ContextMenuItem<T>[]);
};

export type ContextMenuTextItem<T> = BaseContextMenuItem<T> & { type?: "default" };

export type ContextMenuDatePicker<T> = BaseContextMenuItem<T> & {
  type: "date-picker" | "date-time-picker";
  /**
   * (Optional) Getter function used to get value from record to configure context menu E.g. used for date picker to show selected date
   *
   * @param record Record to get value from
   */
  value?: (record?: T) => Dayjs | undefined;

  /**
   * Callback function used when context menu returns a value E.g. used for date picker
   *
   * @param value - Value returned by context menu
   * @param record - Record to update
   */
  setValue: (record?: T, value?: Dayjs) => unknown;
};

export type ContextMenuItem<T> = ContextMenuDivider | ContextMenuTextItem<T> | ContextMenuDatePicker<T>;
export type NonDividerItem<T> = ContextMenuItem<T> & { type?: "default" };

export function hasContextItems<T>(menuItems: ContextMenuItem<T>[] | null | undefined, item?: T, disableContextMenuEvaluator?: (item?: T) => boolean): boolean {
  return (
    !!(menuItems && menuItems.some((c) => c.type !== "divider" && (typeof c.showOption === "function" ? c.showOption(item) : true))) &&
    !disableContextMenuEvaluator?.(item)
  );
}
