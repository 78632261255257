import type { Person } from "../data/Person";
import type { Dayjs } from "dayjs";
import type { ColourVariant, TextColourVariant } from "../types/ColourVariant";

export type TextCell = {
  type: "text";
  text?: string;
  /** Sets numbers to equal widths (e.g. dates, numerical data) */
  tabular?: boolean;
  align?: "left" | "right";
  colour?: TextColourVariant;
  /** Text to show after the main text, used in collapsed views */
  secondaryText?: string;
};

export type Badge = {
  text: string;
  colour?: ColourVariant;
};

export type BadgeCell = {
  type: "badge";
} & Badge;

export type BadgesCell = {
  type: "badges";
  badges: Badge[];
};

export type IconCell = {
  type: "icon";
  /** Icon (without the fa- prefix) */
  icon: string;
  colour?: TextColourVariant;
  /** Title text for the icon */
  title?: string;
  /** Text to show after the icon */
  text?: string;
};

export type PersonBadge = {
  id?: string;
  fullName: string;
  image?: string;
  to?: string;
  deactivated?: boolean;
};

export const toPersonBadge = (p: Person) =>
  ({
    fullName: p.name,
    id: p.id,
    deactivated: p.status === "DEACTIVATED"
  }) satisfies PersonBadge;

export type PersonBadgesCell = {
  type: "personBadges";
  badges: PersonBadge[];
};

export type TimelineEvent = {
  start?: Dayjs;
  end?: Dayjs;
};

export type BaseCell = {
  events?: TimelineEvent[];
};

export type TableCell = (TextCell | BadgeCell | BadgesCell | IconCell | PersonBadgesCell) & BaseCell;

export const BlankCell: TableCell = { type: "text", text: "" } satisfies TextCell;
