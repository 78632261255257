<template>
  <k-input-multiselect v-model="input" :label :options="options ?? []" :loading="!options" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import type { MultiselectItem } from "@ui/inputs/select/SelectItem";
import KInputMultiselect from "@ui/inputs/select/KInputMultiselect.vue";

import { useInitialState } from "@/api/initial";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: string[];
  /** Label for the input */
  label?: string;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", newValue: string[] | undefined): void;
}>();

const input = ref<string[]>([]);

const { roles } = useInitialState();

const options = computed<MultiselectItem[] | undefined>(() => roles.value?.map((r) => ({ value: r.id, label: r.name })));

watch(
  () => props.modelValue,
  (ids) => {
    if (ids === undefined) {
      input.value = [];
    } else if (ids !== input.value) {
      input.value = ids;
    }
  },
  { immediate: true }
);

watch(input, (newRecord) => {
  emit("update:modelValue", newRecord);
});
</script>
