import { DatetimeField, type DatetimePrecision } from "../basic/DatetimeField";
import { expressionParser } from "../../expressions/parser";
import { checkIsObject } from "../../helpers/NullHelpers";

import { ComputedField } from "./ComputedField";

import type { ComputedFieldValue } from "./ComputedField";
import type { KContext } from "../../expressions/context";
import type { KFieldValue, KRecord, StoredKRecord } from "../../data/Record";
import type { KFieldSetup, Sortable } from "../KField";
import type { Expression } from "../../expressions/expressions";

type ComputedDatetimeSetup = KFieldSetup & { precision?: DatetimePrecision };
export class ComputedDatetimeField extends ComputedField<"datetime"> {
  readonly expressionType = "datetime";

  readonly type = "computedDatetime";

  precision: DatetimePrecision;

  private internalField: DatetimeField;

  constructor(setup: string | ComputedDatetimeSetup, expression: string | undefined) {
    super(setup, expression);
    if (typeof setup === "string") {
      this.precision = "day";
    } else {
      this.precision = setup.precision ?? "day";
    }
    this.internalField = new DatetimeField({ label: "Last Value", key: "lastValue", precision: this.precision });
  }

  getParsedExpression(context: KContext): Expression | undefined {
    return this.expression ? expressionParser.parseDatetime(this.expression, context) : undefined;
  }

  getSortableValue(record: KRecord): Sortable {
    const value = this.getValue(record);
    return this.internalField.getSortableValue(value ?? {});
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    return this.internalField.getDisplayValue(value ?? {});
  }

  validateType(value: KFieldValue): value is ComputedFieldValue<"datetime"> {
    if (!checkIsObject(value)) return false;
    if (value.lastValue != null && !this.internalField.validateType(value.lastValue)) return false;
    if (value.overridden != null && typeof value.overridden !== "boolean") return false;
    return true;
  }

  // idea here: reuse the datetime field storage logic so that datetime storage is consistent
  storeValue(source: KRecord, target: StoredKRecord): void {
    const value = this.getValue(source);
    if (value != null) {
      const fakeTarget: StoredKRecord = {};
      this.internalField.storeValue(value, fakeTarget);
      fakeTarget.overridden = value.overridden;
      target[this.key] = fakeTarget;
    }
  }

  restoreValue(source: StoredKRecord, target: KRecord): void {
    const value = source[this.key];
    if (checkIsObject(value)) {
      const fakeSource: KRecord = {};
      this.internalField.restoreValue(value, fakeSource);
      fakeSource.overridden = value.overridden;
      target[this.key] = fakeSource;
    }
  }
}
