<template>
  <p :id class="user-selectable" :class="classes">
    <s v-if="recordDeleted" title="This record was deleted" class="delete-info">{{ label }}</s>
    <router-link v-else-if="link" :to="link">{{ label }} </router-link>
    <template v-else>{{ label }}</template>
  </p>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { RouterLink } from "vue-router";

import type { KRecordOption } from "@data/fields/relational/RecordFields";
import { peopleCollectionName } from "@data/constants/names";

import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { useHasPeoplePermission } from "@/helpers/permissions";

const props = defineProps<{
  /** ID of the display for labelling purposes */
  id: string;
  /** Current record value */
  value?: KRecordOption;
  /** Target collection slug (for link) - include c/ prefix for dynamic collections */
  collectionSlug?: string;
  /** Target collection id (for link) */
  collectionId?: string;
}>();

const recordDeleted = computed(() => props.value?.deleted);

const classes = computed(() => ({ "text-secondary": !props.value || recordDeleted.value }));

const collectionStore = useCollectionStore();
const collection = collectionStore.useCollectionWithId(computed(() => props.collectionId));

const allowLinking = ref(true);
if (props.collectionSlug === peopleCollectionName.slug) {
  const hasPermission = useHasPeoplePermission("read");
  watch(hasPermission, (newValue) => (allowLinking.value = newValue), { immediate: true });
}

const slug = computed(() => props.collectionSlug ?? (collection.value?.slug ? `c/${collection.value.slug}` : undefined));

const label = computed(() => props.value?.label || "None");

const link = computed(() => {
  if (props.value?.id && !recordDeleted.value && slug.value && allowLinking.value) {
    return `/${slug.value}/${props.value.id}`;
  }
  return undefined;
});
</script>

<style scoped>
.delete-info {
  cursor: help;
}
</style>
