import { useMutation, useQuery, useSubscription } from "@vue/apollo-composable";
import gql from "graphql-tag";

import { useUpdateCollectionValidators } from "./validators";
import { fullCollection } from "./fragments";
import { fullView } from "./view";

import type { CollectionAssociationTabConfig, CollectionPermissions, ServerCollection, ServerField, UploadServerField } from "@data/data/Collection";
import type { ActivityType } from "@data/data/Activity";
import type { KView } from "@data/data/View";

import { useAuth } from "@/services/auth";

import type { NavigationStructure } from "./configuration";

const collectionQuery = gql`
  query collections {
    collections {
      ${fullCollection}
    }
    navigationStructure {
      groups {
        label
        items {
          collectionId
          hidden
        }
      }
    }

    views(where: {isDefault: {eq: true}}){
      ${fullView}
    }
  }
`;

const useCollections = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<{ collections?: ServerCollection[]; navigationStructure?: NavigationStructure; views?: KView[] } | null>(collectionQuery, {}, () => ({
    enabled: isAuthenticated.value,
    fetchPolicy: "cache-and-network"
  }));
};

export interface AddCollectionInput {
  displayNameSingular: string;
  displayNamePlural: string;
  slug: string;
  icon: string;
  description?: string;
  schema: UploadServerField[];
  enableActivities: boolean;
  enableTasks: boolean;
  enableFiles: boolean;
  /** The label of the navbar group to add the collection to */
  navbarGroup?: string;
}

const useAddCollection = () =>
  useMutation<{ addCollection: { id: string; schema: ServerField[] } }, { value: AddCollectionInput }>(gql`
    mutation addCollection($value: AddCollectionInput!) {
      addCollection(collection: $value) {
        id
        schema {
          id
          key
          type
          data
        }
      }
    }
  `);

export interface UpdateCollectionInput {
  id: string;
  displayNameSingular: string;
  displayNamePlural: string;
  slug: string;
  icon: string;
  description?: string;
}

const useUpdateCollection = () =>
  useMutation<
    {
      updateCollection: {
        id: string;
        singular: string;
        plural: string;
        slug: string;
        icon: string;
        description: string;
      };
    },
    { value: UpdateCollectionInput }
  >(gql`
    mutation updateCollection($value: UpdateCollectionInput!) {
      updateCollection(collection: $value) {
        id
        singular
        plural
        slug
        icon
        description
      }
    }
  `);

const useDeleteCollection = () =>
  useMutation<{ deleteCollection: boolean }, { id: string }>(
    gql`
      mutation deleteCollectionField($id: String!) {
        deleteCollection(id: $id)
      }
    `,
    {
      refetchQueries: [{ query: collectionQuery }],
      update(cache, _data, options) {
        cache.evict({ id: `Collection:${options.variables?.id ?? ""}` });
        cache.gc();
        // TODO: need to evict left behind ROOT_QUERY caches (e.g. records(collectionSlug: "collection-slug"))
      }
    }
  );

interface UpdateCollectionIdConfigInput {
  collectionId: string;
  nextId?: number;
  format?: {
    visible: boolean;
    prefix?: string;
    digits?: number;
  };
}

const useUpdateCollectionIdConfig = () =>
  useMutation<{ updateCollectionIdConfig: boolean }, { value: UpdateCollectionIdConfigInput }>(gql`
    mutation updateCollectionIdConfig($value: UpdateCollectionIdConfigInput!) {
      updateCollectionIdConfig(input: $value)
    }
  `);

interface UpdateCollectionActivitiesInput {
  id: string;
  isEnabled?: boolean;
  parentFields?: { key: string; value: boolean }[];
  types?: Partial<ActivityType>[];
}
const useUpdateCollectionActivities = () =>
  useMutation<
    {
      updateCollectionActivities: { id: string };
    },
    { value: UpdateCollectionActivitiesInput }
  >(
    gql`
      mutation updateCollectionActivities($value: UpdateCollectionActivitiesInput!) {
        updateCollectionActivities(config: $value) {
          id
        }
      }
    `,
    {
      refetchQueries: [{ query: collectionQuery }]
    }
  );

interface UpdateCollectionFilesInput {
  id: string;
  isEnabled?: boolean;
}

const useUpdateCollectionFiles = () =>
  useMutation<
    {
      updateCollectionFiles: { id: string };
    },
    { value: UpdateCollectionFilesInput }
  >(gql`
    mutation updateCollectionFiles($value: UpdateCollectionFilesInput!) {
      updateCollectionFiles(config: $value) {
        id
        files {
          isEnabled
        }
      }
    }
  `);

interface UpdateCollectionTasksInput {
  id: string;
  isEnabled?: boolean;
  parentFields?: { key: string; value: boolean }[];
}
const useUpdateCollectionTasks = () =>
  useMutation<
    {
      useUpdateCollectionTasks: { id: string };
    },
    { value: UpdateCollectionTasksInput }
  >(gql`
    mutation useUpdateCollectionTasks($value: UpdateCollectionTasksInput!) {
      updateCollectionTasks(config: $value) {
        id
        tasks {
          isEnabled
        }
      }
    }
  `);

const useUpdateCollectionAssociationTabs = () =>
  useMutation<
    {
      useUpdateCollectionAssociationTabs: Pick<ServerCollection, "id" | "associationTabs">;
    },
    {
      value: {
        id: string;
        associationTabs: CollectionAssociationTabConfig[];
      };
    }
  >(gql`
    mutation useUpdateCollectionAssociationTabs($value: UpdateCollectionAssociationTabsInput!) {
      updateCollectionAssociationTabs(input: $value) {
        id
        associationTabs {
          collectionId
          fieldId
          order
          label
          hidden
        }
      }
    }
  `);

const useUpdateCollectionUniqueFields = () =>
  useMutation<
    {
      updateCollectionUniqueFieldRestrictions: Pick<ServerCollection, "id" | "uniqueFieldRestrictions">;
    },
    {
      collectionId: string;
      uniqueFieldRestrictions: ServerCollection["uniqueFieldRestrictions"];
    }
  >(gql`
    mutation updateCollectionUniqueFieldRestrictions($collectionId: String!, $uniqueFieldRestrictions: [UniqueFieldRestrictionInput!]!) {
      updateCollectionUniqueFieldRestrictions(collectionId: $collectionId, restrictions: $uniqueFieldRestrictions) {
        id
        uniqueFieldRestrictions {
          fieldIds
          severity
        }
      }
    }
  `);

const useUpdateCollectionPermissions = () =>
  useMutation<
    { updateCollectionPermissions: ServerCollection["permissions"] },
    { input: { collectionId: string; permissions: ServerCollection["permissions"] } }
  >(gql`
    mutation updateCollectionPermissions($input: UpdateCollectionPermissionsInput!) {
      updateCollectionPermissions(input: $input) {
        read
        write
      }
    }
  `);

const useUpdateCollectionListVisibility = () =>
  useMutation<
    { updateCollectionPermissions: CollectionPermissions["listVisibility"] },
    { input: { collectionId: string; listVisibility: CollectionPermissions["listVisibility"] } }
  >(gql`
    mutation updateCollectionListVisibility($input: UpdateCollectionListVisibilityInput!) {
      updateCollectionListVisibility(input: $input)
    }
  `);

const useBulkUpdateCollectionPermissions = () =>
  useMutation<
    { bulkUpdateCollectionPermissions: ServerCollection["permissions"][] },
    { inputs: { collectionId: string; permissions: ServerCollection["permissions"] }[] }
  >(gql`
    mutation BulkPermissionsUpdate($inputs: [UpdateCollectionPermissionsInput!]!) {
      bulkUpdateCollectionPermissions(inputs: $inputs) {
        read
        write
        configure
      }
    }
  `);

const onCollectionUpdateEvent = () =>
  useSubscription<{ collectionUpdated: { eventType: "COLLECTION_ADDED" | "COLLECTION_UPDATED" | "COLLECTION_DELETED"; collection: ServerCollection } }>(
    gql`
      subscription {
        collectionUpdated {
          eventType
          collection{
            ${fullCollection}
          }
        }
      } 
      `
  );

export const CollectionAPI = {
  useCollections,
  useAddCollection,
  useUpdateCollection,
  useDeleteCollection,
  useUpdateCollectionIdConfig,
  useUpdateCollectionActivities,
  useUpdateCollectionTasks,
  useUpdateCollectionAssociationTabs,
  useUpdateCollectionValidators,
  useUpdateCollectionPermissions,
  useUpdateCollectionUniqueFields,
  useUpdateCollectionFiles,
  useBulkUpdateCollectionPermissions,
  useUpdateCollectionListVisibility,
  onCollectionUpdateEvent
};
