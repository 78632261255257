export type ColourVariant = "blue" | "indigo" | "purple" | "pink" | "red" | "orange" | "yellow" | "light-green" | "green" | "teal" | "cyan" | "gray";

export type TextColourVariant = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | "muted" | "white";

export type UIColourVariant = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | "link" | "transparent";

export type UIColourVariantWithOutlines =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "ai"
  | "light"
  | "dark"
  | "transparent"
  | "outline-primary"
  | "outline-secondary"
  | "outline-success"
  | "outline-danger"
  | "outline-warning"
  | "outline-info"
  | "outline-light"
  | "outline-dark";

export const ColourMap: Record<ColourVariant, string> = {
  blue: "#2478CD",
  indigo: "#6610f2",
  purple: "#6f42c1",
  pink: "#d63384",
  red: "#dc3545",
  orange: "#fd7e14",
  yellow: "#ffc107",
  "light-green": "#abce2f",
  green: "#1ca766",
  teal: "#158799",
  cyan: "#11bcdf",
  gray: "#343a40"
};

export const UIToColourMap: Record<UIColourVariant, ColourVariant> = {
  primary: "blue",
  secondary: "gray",
  success: "green",
  danger: "red",
  warning: "orange",
  info: "teal",
  light: "light-green",
  dark: "gray",
  link: "blue",
  transparent: "gray"
};

export const ColourVariants: ColourVariant[] = Object.keys(ColourMap) as ColourVariant[];
