<template>
  <div
    ref="toolbarElement"
    class="toolbar d-flex px-2"
    :class="{
      'no-bg': hideBackground,
      compact: toolbarSize == 'compact',
      expanded: toolbarSize == 'expanded',
      'has-items': !!structure?.end,
      rounded: rounded
    }">
    <div ref="toolbarStartElement" class="toolbar-start">
      <k-button v-if="backButton == 'BACK'" key="back" title="Back" icon="chevron-left" variant="transparent" @click="back" />
      <k-button v-if="backButton == 'CLOSE'" key="close" title="Close" icon="close" variant="transparent" @click="back" />
      <k-button
        v-if="showMenuButton && backButton == 'MENU'"
        key="menu"
        title="Open sidebar"
        icon="sidebar"
        variant="transparent"
        @click="navbarState.toggleState" />

      <slot name="start" :size="toolbarSize"></slot>

      <div key="title" class="toolbar-label ps-2" :style="{ maxWidth: `${maxTitleWidth}px` }">
        <div v-if="title" class="toolbar-title">{{ title }}</div>
        <div v-if="subtitle" class="toolbar-subtitle">{{ subtitle }}</div>
      </div>
    </div>

    <div class="toolbar-end" :style="totalEndWidth ? `max-width: ${totalEndWidth}px; width: ${endWidth}px` : `width: ${endWidth}px`">
      <div v-if="loading !== undefined" class="toolbar-spinner pe-2">
        <k-spinner v-if="loading" />
      </div>
      <k-input-search v-if="structure?.showSearch" v-model="search" v-model:search-visible="searchVisible" class="float-end" :state="toolbarSize" />
      <template v-if="!searchVisible && structure?.end">
        <k-toolbar-item v-for="(item, i) in visibleItems" :key="`${item.title}${i}`" :item />
      </template>
      <k-toolbar-item
        v-if="!searchVisible && overflowItems.length > 0 && structure?.end"
        key="overflow"
        :css-class="`overflow-button ${toolbarSize == 'expanded' ? 'ms-2' : ''} ${searchVisible ? 'search' : ''}`"
        :item="{
          title: 'Menu',
          type: 'select',
          hideDropdownCaret: true,
          items: overflowItems,
          icon: 'ellipsis'
        }" />
      <slot name="end"></slot>
    </div>
    <k-overflow-indicator v-if="!hideBackground" :opacity="showOverflowIndicator ? 1 : opacityTop" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, shallowRef, useSlots, watch } from "vue";

import { useElementSize } from "@vueuse/core";

import KButton from "@ui/button/KButton.vue";
import { useScrollIndicator } from "@ui/helpers/dom/UseScrollIndicator";
import KInputSearch from "@ui/inputs/search/KInputSearch.vue";
import { useNavbarState } from "@ui/navbar/NavbarState";
import KSpinner from "@ui/progress/KSpinner.vue";

import KOverflowIndicator from "./KOverflowIndicator.vue";
import KToolbarItem from "./KToolbarItem.vue";

import type { ToolbarItem } from "./ToolbarStructure";
import type { ToolbarStructure } from "./ToolbarStructure";

const props = withDefaults(
  defineProps<{
    /** Title for toolbar */
    title?: string;
    /** Subtitle for toolbar */
    subtitle?: string;
    /** Structure of toolbar */
    structure?: ToolbarStructure;
    /** Hide background of toolbar */
    hideBackground?: boolean;
    /** Show back button */
    backButton?: "BACK" | "MENU" | "CLOSE";
    /** Show shadow underneath toolbar */
    showOverflowIndicator?: boolean;
    /** Show loading indicator */
    loading?: boolean | undefined;
    /** Width of end element */
    slotEndWidth?: number;
    /** Indicates whether the toolbar should be rounded */
    rounded?: boolean;
  }>(),
  {
    title: undefined,
    subtitle: undefined,
    structure: undefined,
    backButton: undefined,
    hideBackground: false,
    search: undefined,
    showOverflowIndicator: undefined,
    loading: undefined,
    slotEndWidth: 0
  }
);

const emit = defineEmits<{
  (event: "back", evt?: Event): void;
}>();

const search = defineModel<string>("search");

const slots = useSlots();

const navbarState = useNavbarState();
const showMenuButton = computed(() => navbarState.state.value === "COMPACT" || navbarState.state.value === "HIDDEN" || navbarState.state.value === "OVERLAY");

const searchVisible = ref(false);

const back = (evt?: Event) => {
  emit("back", evt);
};

const toolbarElement = shallowRef<HTMLElement>();
const toolbarStartElement = shallowRef<HTMLElement>();
const { opacityTop } = useScrollIndicator(toolbarElement);

// Need default widths for testing purposes
const defaultToolbarWidth = window.VITEST_DEFAULT_TOOLBAR as { width: number; height: number } | undefined;
const { width } = useElementSize(toolbarElement, defaultToolbarWidth);
const { width: startWidth } = useElementSize(toolbarStartElement, { width: 300, height: 50 });

const toolbarSize = computed(() => {
  const w = width.value > 0 ? width.value : window.innerWidth;
  return w < 577 ? "compact" : "expanded";
});

const loadingSpinnerSpace = 36;
const dividerSpace = 25;
const defaultSelectSpace = 48;
const defaultButtonSpace = 36;
const toolbarMargin = 20; // Ensures search bar displays correctly on mobile

// Calculate total width of end items, used to set max-width of end element and left-align items
const endWidth = ref(0);
const totalEndWidth = ref(1000);
const calcTotalEndWidth = () => {
  if (!props.structure?.end) return 1000;

  let total = 5;

  if (props.structure.showSearch) {
    total += toolbarSize.value === "expanded" ? 180 : defaultButtonSpace;
  }

  if (slots.end) {
    total += props.slotEndWidth;
  }

  if (props.loading !== undefined) {
    total += loadingSpinnerSpace; // Add space for loading spinner, prevents jumpiness
  }

  for (const item of props.structure.end) {
    if (item.show === false) continue;

    if (item.width) {
      total += item.width;
    } else if (item.type === "select") {
      total += defaultSelectSpace;
    } else if (item.type === "divider") {
      total += dividerSpace;
    } else {
      total += defaultButtonSpace;
    }
  }

  return total;
};

const visibleItems = ref<ToolbarItem[]>([]);
const overflowItems = ref<ToolbarItem[]>([]);

// Calculate max width of title element, make sure it takes up enough space and text is readable
// But also doesn't hide too many of the toolbar items as that's annoying
const defaultMaxTitleWidth = 400;
const maxTitleWidth = computed(() => {
  if (toolbarSize.value === "compact") {
    return defaultMaxTitleWidth;
  }
  const itemCount = props.structure?.end?.length;
  if (!itemCount) return width.value;
  if (itemCount < 8) return width.value / 2;
  return Math.min(defaultMaxTitleWidth, width.value / 3);
});

// Calculate which items should be visible and which should be in the overflow menu
const calculateVisibleItems = () => {
  const updatedTotalEndWidth = calcTotalEndWidth();
  const updatedEndWidth = Math.min(Math.max(80, width.value - startWidth.value - toolbarMargin), updatedTotalEndWidth);

  if (!props.structure?.end) {
    if (slots.end) {
      endWidth.value = props.slotEndWidth;
    }
    return;
  }

  const items: ToolbarItem[] = props.structure.end;

  let maxWidth = updatedEndWidth - toolbarMargin;
  if (props.structure.showSearch) {
    maxWidth -= toolbarSize.value === "expanded" ? 200 : 80;
  }

  // Space for loading spinner
  if (props.loading !== undefined) {
    maxWidth -= loadingSpinnerSpace;
  }

  maxWidth = Math.max(maxWidth, 0);

  // Work out which items should be visible
  let total = 0;
  let itemsToShow = items.filter((item) => item.show !== false);
  // filter out double dividers
  itemsToShow = itemsToShow.filter((item, idx) => !(idx && item.type === "divider" && itemsToShow[idx - 1]?.type === "divider"));

  let itemsToOverflow: ToolbarItem[] = [];

  for (const [idx, item] of itemsToShow.entries()) {
    total += item.type === "divider" ? dividerSpace : (item.width ?? defaultButtonSpace);
    if (total > maxWidth) {
      // add remaining items to overflow, and remove them from visible items
      itemsToOverflow = itemsToShow.splice(idx, itemsToShow.length - idx);
      break;
    }
  }

  // Avoid situation where we have overflow menu with only one item
  if (itemsToOverflow.length === 1) {
    itemsToShow.push(itemsToOverflow[0]);
    itemsToOverflow = [];
  }

  if (itemsToShow.at(-1)?.type === "divider") {
    itemsToShow.pop();
  }

  visibleItems.value = itemsToShow;

  // Expand overflow items to include all items in select dropdowns
  overflowItems.value = itemsToOverflow
    .filter((item, idx) => !(item.type === "divider" && !idx) && item.shouldOverflow !== false)
    .flatMap((item) => (item.type === "select" ? (item.items ?? []) : item));

  endWidth.value = updatedEndWidth;
  totalEndWidth.value = updatedTotalEndWidth;
};

watch(() => props.structure, calculateVisibleItems, { immediate: true });
watch(() => props.loading, calculateVisibleItems);
watch(width, calculateVisibleItems);
watch(startWidth, calculateVisibleItems);

defineExpose({ width });
</script>

<style scoped lang="scss">
.toolbar {
  height: 52px;
  align-items: center;
  vertical-align: middle;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  background: var(--k-background-translucent-10);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 9000;
  user-select: none;
  -webkit-user-select: none;
  &.no-bg {
    background: transparent;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }
  & :deep(.btn) {
    font-size: 0.9rem;
    box-sizing: border-box;
  }
}

.toolbar-start {
  display: flex;
  vertical-align: middle;
  align-items: center;
  flex-shrink: 1;
}

.toolbar-label {
  cursor: default;
  position: relative;
  min-width: 50px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.expanded .toolbar-start {
  min-width: 200px;
}

.compact.has-items .toolbar-start {
  max-width: 50%;
}

.toolbar-spinner {
  width: 28px;
  display: inline-block;
}

.toolbar-title {
  font-size: 15px;
  color: var(--k-color);
  line-height: 20px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.toolbar-subtitle {
  font-size: 11px;
  color: var(--k-color-secondary);
  line-height: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.toolbar-end {
  white-space: nowrap;
  text-align: left;
  flex-shrink: 0;
}

:deep(.overflow-button.search) {
  transition: none !important;
  position: absolute;
  right: 194px;
}

.compact {
  :deep(.overflow-button) {
    position: relative;
    right: auto;
  }
  .toolbar-end {
    text-align: right;
  }
}
</style>
