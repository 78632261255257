<template>
  <div class="form-control-container location-input">
    <k-label v-if="!config.hideLabel" :id="uuid" :label />
    <div v-if="!config.disabled">
      <a
        v-if="displayMode == 'SEARCH'"
        role="button"
        tabindex="0"
        class="location-input-btn float-end"
        :class="{ 'me-3': !!config.description }"
        @click="enterManually"
        @keydown.enter="enterManually"
        >Enter manually</a
      >
      <a v-else role="button" tabindex="0" class="location-input-btn float-end" @click="searchAgain" @keydown.enter="searchAgain">Search again</a>
    </div>
    <div v-if="displayMode == 'MANUAL'" class="row text-end">
      <template v-for="key in addressDisplayOrder" :key>
        <div class="col col-sm-2 pt-1 pe-0">
          <label :for="`address-${key}-${uuid}`">{{ toLabel(key) }}</label>
        </div>
        <div class="col col-sm-10 mt-1">
          <k-input-config hide-label :disabled="config.disabled">
            <k-input :id="`address-${key}-${uuid}`" v-model="selectedAddress[key]" @change="onInputChange" />
          </k-input-config>
        </div>
      </template>
    </div>

    <div v-else ref="autocompleteElement" class="autocomplete" tabindex="-1">
      <input
        :id="uuid"
        ref="searchElement"
        v-model="search"
        :class="classes"
        :aria-label="label"
        autocomplete="off"
        v-bind="commonBindings(config, label)"
        @change="onChange"
        @input="onChange"
        @blur="onBlur"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keyup.enter="onEnter" />

      <ul ref="autocompleteResults" :class="{ show: isOpen }" class="dropdown-menu autocomplete-results">
        <li v-if="loading" class="loading dropdown-item"><k-spinner size="sm" class="me-2" show-instantly /> Loading results...</li>
        <li
          v-for="(result, i) in results"
          v-else
          :key="i"
          class="dropdown-item"
          :class="{ highlighted: i === arrowCounter, active: result.id == modelValue }"
          @click="selectResult(result, i)"
          @keydown.enter="selectResult(result, i)">
          <template v-if="result.highlight && result.highlight.length > 0">
            <span v-for="(highlight, j) in result.highlight" :key="j" :class="highlight.style">
              {{ highlight.content }}
            </span>
          </template>
          <span v-else>
            {{ result.name }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";

import { debouncedWatch, useEventListener } from "@vueuse/core";
import { v4 } from "uuid";

import KInputConfig from "../KInputConfig.vue";
import { useInputClasses, useInputConfig, commonBindings } from "../inputConfig";

import KInput from "@ui/inputs/strings/KInput.vue";
import KSpinner from "@ui/progress/KSpinner.vue";
import KLabel from "@ui/label/KLabel.vue";

import { capitalise } from "@data/helpers/strings/Casing";
import type { Address, SearchResult, HereAutosuggestResponse, HereLocationResponse } from "@data/types/Location";
import { addressDisplayOrder } from "@data/types/Location";

const props = withDefaults(
  defineProps<{
    /** Current value of the input */
    modelValue?: Address;
    /** Label for the input */
    label?: string;
    /** Location to search from */
    userPosition?: { lat: number; lng: number };
  }>(),
  {
    userPosition: () => ({ lat: 52.2354, lng: 0.1538 }),
    modelValue: undefined,
    label: undefined
  }
);

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "update:modelValue", newValue: Address | null): void;
}>();

const config = useInputConfig();

const autocompleteElement = ref<HTMLElement>();
const searchElement = ref<HTMLElement>();
const autocompleteResults = ref<HTMLElement>();
const search = ref("");
const isOpen = ref(false);
const arrowCounter = ref(-1);
const selectedIndex = ref<number>();
const selectedAddress = ref<Address>({});
const displayMode = ref<"SEARCH" | "MANUAL">("SEARCH");
const loading = ref(false);

const toLabel = (key: string) => {
  switch (key) {
    case "line1":
      return "Line 1:";
    case "line2":
      return "Line 2:";
    default:
      return capitalise(key) + ":";
  }
};

// Credentials for hereAPI
const hereAPI = {
  baseUrl: "https://autosuggest.search.hereapi.com/v1",
  apiKey: "8mQaPp31RJ0Ux5E9CsjFlMWJ7odsgNeQOgtGo-48yBU"
};

const results = ref<SearchResult[]>([]);

// Fetches autosuggested results using Here API
const fetchAutosuggest = async () => {
  if (!search.value.length) return;
  loading.value = true;

  const url =
    `${hereAPI.baseUrl}/autosuggest?at=${props.userPosition.lat}%2C${props.userPosition.lng}&apiKey=${hereAPI.apiKey}&result_types=address,place&q=` +
    encodeURIComponent(search.value);

  const res = await fetch(url);

  if (!res.ok) return;

  const { items: searchResults = [] } = (await res.json()) as { items: HereAutosuggestResponse[] };

  results.value = searchResults
    .filter((value) => value.address)
    .map((value) => {
      let lastEndIndex = 0;
      const highlightedSnippets: { start: number; end: number; style?: string; content: string }[] = [];
      if (value.highlights.address && value.highlights.address.label.length > 0) {
        const addressLabel = value.address?.label ?? "";
        // start chunk
        if (value.highlights.address.label[0].start > 0) {
          highlightedSnippets.push({
            start: 0,
            end: value.highlights.address.label[0].start,
            content: addressLabel.slice(0, value.highlights.address.label[0].start)
          });
        }

        highlightedSnippets.push(
          ...value.highlights.address.label.map((highlight) => {
            lastEndIndex = highlight.end;
            return {
              start: highlight.start,
              end: highlight.end,
              style: "fw-bold",
              content: addressLabel.slice(highlight.start, highlight.end)
            };
          })
        );

        highlightedSnippets.push({
          start: lastEndIndex,
          end: addressLabel.length,
          content: addressLabel.slice(lastEndIndex)
        });
      }

      return {
        id: value.id,
        name: value.address?.label ?? value.title,
        highlight: highlightedSnippets
      };
    }) satisfies SearchResult[];

  loading.value = false;
};

// Fetches from Here API using ID
const fetchAddress = async (input: SearchResult | undefined) => {
  if (!input?.id) {
    return;
  }
  const res = await fetch(`${hereAPI.baseUrl}/lookup?apiKey=${hereAPI.apiKey}&id=${input.id}`);
  if (!res.ok) return;

  const { title, address, position } = (await res.json()) as HereLocationResponse;
  const { houseNumber, street = "", state, subdistrict, district = "", county = "", countryName = "", postalCode = "", city = "" } = address;

  selectedAddress.value = {
    title,
    line1: `${houseNumber ? `${houseNumber} ` : ""}${street}`,
    line2: district === city ? subdistrict : district,
    city,
    county: county === city ? state : county,
    country: countryName,
    postcode: postalCode,
    lat: position.lat,
    lng: position.lng
  };
  emit("update:modelValue", selectedAddress.value);
};

// Identify initially selected ID value in list and highlight by default
const setInitialValue = () => {
  // check if any values are set in props.modelValue object
  const hasValues = Object.values(props.modelValue ?? {}).some((value) => !!value);

  // display initial values
  if (props.modelValue && hasValues) {
    selectedAddress.value = { ...props.modelValue };
    displayMode.value = "MANUAL";
  } else {
    selectedAddress.value = {};
    displayMode.value = "SEARCH";
  }
  selectedIndex.value = undefined;
  arrowCounter.value = -1;
  search.value = "";
};

// Monitor changes to modelValue at start to account for loading data
setInitialValue();

watch(
  () => props.modelValue,
  () => setInitialValue()
);

debouncedWatch(
  search,
  async () => {
    if (search.value === "") {
      results.value = [];
    }
    if (displayMode.value === "MANUAL") {
      return;
    }
    await fetchAutosuggest();
  },
  { debounce: 500 }
);

const readonly = computed(() => config.value.readonly);

// Handle user selection of a result in the dropdown
const selectResult = async (value: SearchResult | undefined, index: number) => {
  if (readonly.value) {
    return;
  }
  isOpen.value = false;
  if (value) {
    search.value = `${value.name}`;
    selectedIndex.value = index;
    arrowCounter.value = index;
    await fetchAddress(value);
    displayMode.value = "MANUAL";
  } else {
    search.value = "";
    selectedIndex.value = -1;
    emit("update:modelValue", null);
  }
};

// Handle search input changes
const onChange = () => {
  if (readonly.value) {
    return;
  }
  // Let's search our array
  const prevLength = results.value.length;
  //   filterResults();
  isOpen.value = true;
  if (prevLength !== results.value.length) {
    arrowCounter.value = 0;
  }
};

// clears field text, index value and calls onChange function
const clearField = () => {
  search.value = "";
  selectedIndex.value = -1;
  emit("update:modelValue", null);
  onChange();
};

const onInputChange = () => {
  emit("update:modelValue", selectedAddress.value);
};

// Handle arrow keys
const adjustment = 30;
const visibleItems = 4;

const onArrowDown = () => {
  if (readonly.value) {
    return;
  }

  if (arrowCounter.value < results.value.length - 1) {
    arrowCounter.value += 1;

    if (arrowCounter.value > visibleItems && autocompleteResults.value) {
      const pos = arrowCounter.value * adjustment - visibleItems * adjustment;
      autocompleteResults.value.scrollTop = pos > 0 ? pos : 0;
    }
  }
};

const onArrowUp = () => {
  if (readonly.value) {
    return;
  }

  if (arrowCounter.value > 0) {
    arrowCounter.value -= 1;

    if (autocompleteResults.value) {
      const pos = arrowCounter.value * adjustment - visibleItems * adjustment;
      autocompleteResults.value.scrollTop = pos > 0 ? pos : 0;
    }
  }
};

const enterManually = async () => {
  clearField();
  // Need to reset after next Vue tick to ensure the input is cleared and modelValue's watcher doesn't reset the displayMode
  await nextTick(() => (displayMode.value = "MANUAL"));
};

const searchAgain = () => {
  clearField();
  selectedAddress.value = {};
  displayMode.value = "SEARCH";
};

// Handle pressing "Enter" on a selected item
const onEnter = async () => {
  if (readonly.value) {
    return;
  }

  const index = arrowCounter.value;
  await selectResult(results.value[index], index);
  isOpen.value = false;
  arrowCounter.value = -1;
};

// Handle clicking outside the autocomplete element, dismiss dropdown
useEventListener(document, "click", (evt: MouseEvent) => {
  if (readonly.value) {
    return;
  }

  if (!autocompleteElement.value?.contains(evt.target as Node)) {
    isOpen.value = false;
    emit("blur");
  }
});

// Handle tabbing away from the current field
const onBlur = (evt: FocusEvent) => {
  if (!autocompleteElement.value?.contains(evt.relatedTarget as Node)) {
    isOpen.value = false;
    emit("blur");
  }
};

// Setup CSS classes to handle states
const classes = useInputClasses(config, "select");
const uuid = v4();
</script>

<style scoped lang="scss">
.location-input {
  .text-end {
    font-size: 0.9em;
    line-height: 30px;
  }

  .location-input-btn {
    font-size: 0.75rem;
    margin-top: -22px;
    display: block;
    float: right;
    text-decoration: none;
    user-select: none;
  }
}
</style>
