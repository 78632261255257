<template>
  <div :class="{ 'form-control-container': !config.noContainer }">
    <k-label v-if="!config.hideLabel" :id="id ?? uuid" :label />
    <div class="position-relative" :class="{ 'with-suffix': !!suffix }">
      <input
        :id="id ?? uuid"
        ref="element"
        v-model="input"
        :class="classes"
        type="number"
        :aria-label="label"
        v-bind="commonBindings(config, label)"
        :placeholder="placeholder ?? label"
        :min
        :max
        :step="step ?? 'any'"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @change="$emit('change', $event)" />
      <span v-if="suffix" class="input-number-suffix">{{ suffix }}</span>
    </div>
    <issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from "vue";

import { v4 } from "uuid";

import IssueDisplay from "../KIssueDisplay.vue";
import { useInputConfig, useInputClasses, commonBindings } from "../inputConfig";

import KLabel from "@ui/label/KLabel.vue";

const props = defineProps<{
  /** Label for the input */
  label?: string;
  /** Minimum value for the input */
  min?: number;
  /** Maximum value for the input */
  max?: number;
  /** Step size for the input */
  step?: number;
  /** ID for external labels */
  id?: string;
  /** Extra suffix text */
  suffix?: string;
  /** Placeholder text for the input */
  placeholder?: string;
}>();

defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
}>();

const modelValue = defineModel<number>();

const config = useInputConfig();

const element = ref<HTMLInputElement>();

onMounted(() => {
  if (element.value && modelValue.value != null) {
    element.value.value = modelValue.value.toString();
  }
});

const input = ref<number | string>();

watchEffect(() => {
  input.value = modelValue.value;
});
watchEffect(() => {
  // Some browsers output number input values as strings, so convert if valid number, otherwise return undefined
  const parsed = typeof input.value === "string" ? parseFloat(input.value) : input.value;
  const newValue = parsed != null && !isNaN(parsed) ? parsed : undefined;
  if (newValue !== modelValue.value) {
    modelValue.value = newValue;
  }
});

const classes = useInputClasses(config);
const uuid = v4();

const suffixCharLength = computed(() => (props.suffix?.length ?? 0) * 10 + 12 + "px");
</script>

<style scoped>
.input-number-suffix {
  position: absolute;
  display: inline-block;
  color: var(--text-color-secondary);
  top: 0;
  right: 14px;
  bottom: 0;
  font-size: 12px;
  line-height: 30px;
}

.with-suffix > input {
  padding-right: v-bind(suffixCharLength);
}
</style>
