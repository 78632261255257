import { computed, ref } from "vue";

import { createGlobalState, useStorage } from "@vueuse/core";
import dayjs from "dayjs";
import localforage from "localforage";

import { setVersion } from "./version";

import type { FileStorageProvider } from "@data/data/tenant/Integrations";
import type { PlanCode } from "@data/data/tenant/Tenant";
import { pickFrom } from "@data/helpers/manipulation/Objects";
import type { User } from "@data/data/User";
import type { BillingFrequency } from "@data/data/tenant/Billing";

import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { apolloClient } from "@/services/apollo";
import { storageKeys } from "@/consts/storageKeys";

import type { Dayjs } from "dayjs";

// Create a "kb-auth" local storage state for keeping track of auth status
const useAuthState = createGlobalState(() => useStorage("kb-auth", ""));

export type PaymentStatus = "trial" | "active" | "cancelled";

interface AuthState {
  authenticated: boolean;
  user?: Partial<User>;
  token?: string;
  tenantName?: string;
  subscriptionExpiry?: string;
  planCode?: PlanCode;
  billingFrequency?: BillingFrequency;
  paymentStatus?: PaymentStatus;
  initialUnreadNotifications: number;
  fileStorageProvider?: FileStorageProvider;
}

// Update the local storage state with new auth details
const setAuthState = (authData: AuthState): string =>
  // Store as base64 encoded string
  btoa(JSON.stringify(authData));
// Get the current auth state from local storage
const getAuthState = (state: string): AuthState => {
  if (!state) {
    return { authenticated: false, initialUnreadNotifications: 0 };
  }
  return JSON.parse(atob(state)) as AuthState;
};

// Returns whether the user is authenticated
export const isAuthenticated = () => getAuthState(useAuthState().value).authenticated;

// Returns the current user's id
export const getUserId = () => getAuthState(useAuthState().value).user?.id;

export const getUser = () => getAuthState(useAuthState().value).user;

// Function to sign out the current user
export const signOut = async () => {
  // hacky bit to remember the last sign in method
  const lastSignIn = localStorage.getItem(storageKeys.auth.lastSignInMethod) ?? "email";
  await Promise.all([
    // Make a request to the server to clear the session cookie
    fetch("/api/auth/sign-out"),
    // Clear local storage and localforage
    localStorage.clear(),
    localforage.clear()
  ]);

  // put the last sign in method back
  localStorage.setItem(storageKeys.auth.lastSignInMethod, lastSignIn);

  if (!isAuthenticated()) return;

  // Update the local storage auth state, reactivity of this signs user out from UI
  const state = useAuthState();
  state.value = setAuthState({ authenticated: false, initialUnreadNotifications: 0, user: undefined });
  setVersion(undefined);

  // Clear store in case of stale data and previous user's info left behind
  await apolloClient.resetStore();

  // Set the document title back to the default
  document.title = "Kinabase";
};

// Helper functions to get the current authentication state
export const useAuth = () => {
  const state = useAuthState();
  const authState = computed(() => getAuthState(state.value));

  const checkAuth = async () => {
    const response = await fetch("/api/auth/user", {
      method: "GET",
      cache: "no-cache"
    });
    const res = (await response.json()) as SignInResult | undefined;
    if (res && res.authenticated === true) {
      state.value = setAuthState({
        authenticated: true,
        initialUnreadNotifications: res.unreadNotifications,
        ...pickFrom(res, "user", "tenantName", "billingFrequency", "subscriptionExpiry", "planCode", "paymentStatus", "fileStorageProvider")
      });
      setVersion(res.version);
      return true;
    } else {
      await signOut();
      return false;
    }
  };

  const subscriptionExpiry = computed(() => {
    const exp = authState.value.subscriptionExpiry;
    return exp ? dayjs(exp) : undefined;
  });

  return {
    isAuthenticated: computed(() => authState.value.authenticated),
    user: computed(() => authState.value.user),
    firstName: computed(() => authState.value.user?.firstName),
    username: computed(() => authState.value.user?.name),
    tenantName: computed(() => authState.value.tenantName),
    subscriptionExpiry,
    billingFrequency: computed(() => authState.value.billingFrequency),
    paymentStatus: computed(() => authState.value.paymentStatus),
    planCode: computed(() => authState.value.planCode),
    checkAuth,
    initialUnreadNotifications: computed(() => authState.value.initialUnreadNotifications)
  };
};

export const useIsAdmin = () => {
  const state = useAuth();
  return computed(() => state.user.value?.role === "ADMINISTRATOR");
};

export const getSubscriptionStatus = (subscriptionExpiry?: Dayjs, paymentStatus?: PaymentStatus) => {
  const now = dayjs();
  // Add one day grace period if payment is active, also see TenantBilling.cs
  if (!subscriptionExpiry || (subscriptionExpiry.add(1, "day").isAfter(now) && paymentStatus === "active") || subscriptionExpiry.isAfter(now)) {
    return "active";
  } else if (subscriptionExpiry.add(7, "day").isAfter(now)) {
    return "locked-down";
  } else {
    return "expired";
  }
};

export const useSubscriptionStatus = () => {
  const { subscriptionExpiry, paymentStatus, planCode, billingFrequency, checkAuth: refresh } = useAuth();

  return {
    subscriptionExpiry,
    paymentStatus,
    planCode,
    billingFrequency,
    refresh,
    status: computed(() => getSubscriptionStatus(subscriptionExpiry.value, paymentStatus.value))
  };
};

// Helper functions to allow the user to sign in, and track whether it's finished loading
export const useSignIn = () => {
  const state = useAuthState();

  const loading = ref(false);
  const signIn = async (email: string, password: string): Promise<boolean> => {
    loading.value = true;
    const response = await fetch("/api/auth/sign-in", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email, password: btoa(password) })
    }).catch(() => {
      loading.value = false;

      // Trigger loading of collection store to ensure it's ready asap
      useCollectionStore();
    });
    if (response) {
      loading.value = false;

      // Clear store in case of stale data and previous user's info left behind
      // Shouldn't happen but a possibility if sign out interrupted
      await apolloClient.resetStore();

      const res = (await response.json()) as SignInResult | undefined;
      if (res && res.authenticated === true) {
        state.value = setAuthState({
          authenticated: true,
          initialUnreadNotifications: res.unreadNotifications,
          ...pickFrom(res, "user", "tenantName", "billingFrequency", "subscriptionExpiry", "planCode", "paymentStatus", "fileStorageProvider")
        });
        return true;
      }
    }
    return false;
  };

  return { signIn, loading };
};

export const useFileStorageProvider = () => {
  const state = useAuthState();
  return computed(() => getAuthState(state.value).fileStorageProvider ?? "KINABASE");
};

/*
  Helper function for signing in during the onboarding process,
  necessary as onboarding process returns the user's auth token without the need for a password
*/
export const useOnboardingSignIn = () => {
  const state = useAuthState();

  const signIn = (res: SignInResult | null): boolean => {
    if (res && res.authenticated === true) {
      state.value = setAuthState({
        authenticated: true,
        initialUnreadNotifications: res.unreadNotifications,
        ...pickFrom(res, "user", "tenantName", "billingFrequency", "subscriptionExpiry", "planCode", "paymentStatus", "fileStorageProvider")
      });
      setVersion(res.version);
      return true;
    } else {
      return false;
    }
  };

  return { signIn };
};

export interface SignInResult {
  authenticated: boolean;
  token: string;
  expiresAt: Date;
  user: Partial<User>;
  userId: string;
  message: string;
  tenantName: string;
  subscriptionExpiry: string;
  billingFrequency: BillingFrequency;
  planCode: PlanCode;
  paymentStatus: PaymentStatus;
  unreadNotifications: number;
  fileStorageProvider: FileStorageProvider;
  version: string;
}
