<template>
  <label v-if="id" :for="id" :class="labelClass">{{ label }}<span v-if="label && actuallyRequired" class="required-asterisk"> &lowast;</span></label>
  <div v-else :class="labelClass">{{ label }}</div>
  <k-button
    v-if="description && !showInline"
    variant="transparent"
    icon="question-circle"
    class="info-icon"
    :class="{ 'text-primary': showDescription }"
    role="button"
    title="Help"
    @click="toggleDescription" />
  <p v-if="description && showDescription" class="description mt-1">
    {{ description }}
  </p>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

import { useInputConfig } from "@ui/inputs/inputConfig";
import KButton from "@ui/button/KButton.vue";

const props = defineProps<{
  /** ID of element to link to */
  id?: string;
  /** Label for the input */
  label?: string;
  /** Override required */
  required?: { override: boolean };
  /** Description for the input */
  description?: string;
  /** Whether the description should be shown inline */
  inlineDescription?: boolean;
  /** Class to apply to the label */
  class?: string;
  /** Size of the label */
  size?: "sm" | "md" | "lg";
}>();

const config = useInputConfig();
const description = computed(() => props.description ?? config.value.description);
const showInline = computed(() => props.inlineDescription || config.value.inlineDescription);
const actuallyRequired = computed(() => props.required?.override ?? config.value.required);
const showDescription = ref(!!description.value && showInline.value);
const toggleDescription = () => {
  showDescription.value = !showDescription.value;
};

const labelClass = computed(() => {
  const classes: Record<string, boolean> = { label: true };
  if (props.class) {
    for (const cls of props.class.split(" ")) {
      classes[cls] = true;
    }
  }
  if (props.size) {
    classes[`label-${props.size}`] = true;
  }
  return classes;
});
</script>

<style scoped lang="scss">
.label {
  font-size: 0.75em;
  text-transform: uppercase;
  color: var(--k-label-color);
  font-weight: 600;
  display: block;
  margin-bottom: 0.5rem;
  line-height: 1;
  user-select: none;
  -webkit-user-select: none;
}

.label-sm {
  font-size: 0.75em;
}

.label-md {
  font-size: 0.9em;
}

.label-lg {
  font-size: 1.1em;
}

.description {
  font-size: 0.85em;
  color: var(--k-color-secondary);
  display: block;
  margin: -0.25rem 0 0.5rem;
  line-height: 1.2;
  user-select: none;
  -webkit-user-select: none;
}

.info-icon {
  float: right;
  color: var(--k-color-secondary);
  margin-top: -1.25rem;
  font-size: 0.85em;
  height: 1em;
  padding: 0 !important; // needed to stop button from assuming that an icon needs padding
}

.required-asterisk {
  color: var(--k-color-d-05-danger);
}
</style>
