<template>
  <k-input-config :issues="inputTypeIssues">
    <k-input-select v-model="inputType" label="Input Type" :options="inputTypeOptions" required />
  </k-input-config>
  <div class="minmax-wrapper">
    <k-input-config :issues="minIssues">
      <k-input-number v-model="input.min" label="Minimum" />
    </k-input-config>
    <k-input-config :issues="minLabelIssue">
      <k-input v-model="minLabel" class="mt-0" label="Minimum Label" />
    </k-input-config>
  </div>
  <div class="minmax-wrapper">
    <k-input-config :issues="maxIssues">
      <k-input-number v-model="input.max" label="Maximum" />
    </k-input-config>
    <k-input-config :issues="maxLabelIssue">
      <k-input v-model="maxLabel" class="mt-0" label="Maximum Label" />
    </k-input-config>
  </div>

  <k-input-config :issues="stepIssues">
    <k-input-number v-model="input.step" label="Step" />
  </k-input-config>

  <div class="input-preview mb-3">
    <k-label label="Preview" />
    <k-input-config :disabled="true" :issues="inputTypeIssues">
      <k-input-range v-if="inputType === 'range'" :min="input.min" :max="input.max" :step="input.step" :labels="input.labels" :is-preview="true" />
      <k-input-radio v-else :min="input.min" :max="input.max" :step="input.step" :labels="input.labels" :is-preview="true" />
    </k-input-config>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";

import KInputNumber from "@ui/inputs/numeric/KInputNumber.vue";
import KInput from "@ui/inputs/strings/KInput.vue";
import type { SelectItem } from "@ui/inputs/select/SelectItem";
import KInputSelect from "@ui/inputs/select/KInputSelect.vue";
import KInputConfig from "@ui/inputs/KInputConfig.vue";
import KInputRadio from "@ui/inputs/numeric/KInputRadio.vue";
import KInputRange from "@ui/inputs/numeric/KInputRange.vue";
import KLabel from "@ui/label/KLabel.vue";

import type { RatingFieldConfig } from "@data/fields/basic/SimpleFields";
import type { ValidationIssues } from "@data/validation/Validation";

import { manageObjectVModel } from "@/helpers/vModel";

const props = defineProps<{
  /** Current config value */
  modelValue?: RatingFieldConfig;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: RatingFieldConfig): void;
  (event: "update:valid", newValue: boolean): void;
}>();

const input = manageObjectVModel<RatingFieldConfig>(
  computed(() => props.modelValue ?? { min: 1, max: 5, step: 1 }),
  emit
);

const minIssues = computed<ValidationIssues>(() => {
  if (Math.floor(input.value.min) !== input.value.min) {
    return [{ severity: "error", message: "Minimum must be an integer." }];
  }
  return [];
});

const maxIssues = computed<ValidationIssues>(() => {
  const issues: ValidationIssues = [];
  if (Math.floor(input.value.max) !== input.value.max) {
    issues.push({ severity: "error", message: "Maximum must be an integer." });
  }
  if (input.value.max < input.value.min) {
    issues.push({ severity: "error", message: "Maximum must be greater than the minimum." });
  }
  return issues;
});

const stepIssues = computed<ValidationIssues>(() => {
  if (input.value.step === undefined) {
    return [];
  }
  if (input.value.step <= 0) {
    return [{ severity: "error", message: "Step must be greater than 0." }];
  }
  if (Math.floor(input.value.step) !== input.value.step) {
    return [{ severity: "error", message: "Step must be an integer" }];
  }
  // check if step is a divisor of the range
  const range = input.value.max - input.value.min;
  if (range % input.value.step !== 0) {
    return [{ severity: "error", message: "Step must evenly divide the range" }];
  }
  return [];
});

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const minLabel = ref(props.modelValue?.labels?.find((label) => label.value === input.value.min)?.label);
//eslint-disable-next-line vue/no-setup-props-reactivity-loss
const maxLabel = ref(props.modelValue?.labels?.find((label) => label.value === input.value.max)?.label);

watchEffect(() => {
  const labels = [];
  if (minLabel.value) {
    labels.push({ value: input.value.min, label: minLabel.value });
  }
  if (maxLabel.value) {
    labels.push({ value: input.value.max, label: maxLabel.value });
  }
  input.value.labels = labels;
});

const MAX_LABEL_LENGTH = 12;
const maxLabelIssue = computed<ValidationIssues>(() => {
  if (maxLabel.value && maxLabel.value.length > MAX_LABEL_LENGTH) {
    return [{ severity: "warning", message: "Label is too long" }];
  }
  return [];
});
const minLabelIssue = computed<ValidationIssues>(() => {
  if (minLabel.value && minLabel.value.length > MAX_LABEL_LENGTH) {
    return [{ severity: "warning", message: "Label is too long" }];
  }
  return [];
});

const inputTypeOptions: (SelectItem & { value: RatingFieldConfig["inputType"] })[] = [
  { value: "range", label: "Slider" },
  { value: "radio", label: "Radio Buttons" }
];

const inputType = ref<NonNullable<RatingFieldConfig["inputType"]>>("range");

watchEffect(() => {
  inputType.value = input.value.inputType ?? "range";
});

const MAX_RADIO_BUTTONS = 11;

const inputTypeIssues = computed<ValidationIssues>(() => {
  if (inputType.value === "radio") {
    const totalSteps = (input.value.max - input.value.min) / (input.value.step ?? 1) + 1;
    if (totalSteps > MAX_RADIO_BUTTONS) {
      return [
        {
          severity: "error",
          message: `Too many radio buttons (${totalSteps}), maximum is ${MAX_RADIO_BUTTONS}.`
        }
      ];
    }
  }
  return [];
});

watchEffect(() => {
  input.value.inputType = inputType.value;
});

const valid = computed(() => !minIssues.value.length && !maxIssues.value.length && !stepIssues.value.length && !inputTypeIssues.value.length);

watchEffect(() => {
  emit("update:valid", valid.value);
});
</script>

<style>
.input-preview {
  margin-top: 1rem;
  border: 1px solid var(--bs-border-color);
  padding: 0.75rem;
  border-radius: 3px;
}
.minmax-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin: var(--k-form-el-margin) 0;
}
</style>
