import { DatetimeField } from "../fields/basic/DatetimeField";
import { StringField } from "../fields/basic/StringFields";
import { StageField } from "../fields/complex/StageField";
import { UserField } from "../fields/relational/UserField";
import { IdField } from "../fields/utility/IdField";
import { MultiObjectField } from "../fields/utility/MultiObjectField";
import { ObjectField } from "../fields/utility/ObjectField";
import { fromJson, toJson } from "../helpers/serialisation/TypedJSON";
import { fieldRegistry } from "../fields/FieldRegistry";

import { KEntity } from "./KEntity";

import type { JsonSerialisable } from "../helpers/serialisation/TypedJSON";
import type { Datelike } from "../types/Dates";
import type { ActivityType } from "./Activity";
import type { EntityOptions } from "./KEntity";
import type { KRecord } from "./Record";
import type { KField } from "../fields/KField";
import type { ConditionalValidator } from "../validation/ConditionalValidation";

export interface CollectionAssociation {
  collectionId: string;
  collectionSlug: string;
  collectionPlural: string;
  field: KField & { collectionId?: string; showParentActivities?: boolean; showParentTasks?: boolean };
  multi: boolean;
  icon?: string;
}

interface CollectionActivityConfig {
  isEnabled: boolean;
  types?: ActivityType[];
}

interface CollectionTaskConfig {
  isEnabled: boolean;
}

interface CollectionFilesConfig {
  isEnabled: boolean;
}

export type CollectionAssociationTabConfig = {
  collectionId: string;
  fieldId: string;
  order: number;
  label?: string;
  hidden: boolean;
};

export type CollectionRecordPermissions = {
  read: string[];
  write: string[];
  activities?: {
    read: string[];
    write: string[];
  };
  tasks?: {
    read: string[];
    write: string[];
  };
  files?: {
    read: string[];
    write: string[];
  };
};

export type CollectionPermissions = CollectionRecordPermissions & {
  configure: string[];
  bulk?: { import: string[]; export: string[]; bulkDelete: string[] };
  listVisibility?: string[];
};

export type UniqueFieldRestriction = {
  fieldIds: string[];
  severity?: ConditionalValidator["severity"];
};

export type CollectionIdConfig = {
  visible: boolean;
  prefix?: string;
  digits?: number;
};

export type ServerField = { key: string; type: string; data: Record<string, JsonSerialisable>; id: string };

// Server expects JSON string for field data, but returns object so need two different types
// Have created issue to fix this (KB-237)
export type UploadServerField = Omit<ServerField, "data" | "id"> & { data: string; id?: string };

export type ServerCollection = {
  id: string;
  nextId?: number;
  createdAt?: Datelike;
  updatedAt?: Datelike;
  singular: string;
  plural: string;
  slug: string;
  description?: string;
  icon?: string;
  primaryProperty: string;
  secondaryProperty?: string;
  sortProperty: string;
  schema: ServerField[];
  extensionId?: string;
  defaultSortDirection: "ASC" | "DESC";
  idFormatConfig?: CollectionIdConfig;
  conditionalValidators?: ConditionalValidator[];
  activities?: CollectionActivityConfig;
  uniqueFieldRestrictions?: UniqueFieldRestriction[];
  tasks?: CollectionTaskConfig;
  files?: CollectionFilesConfig;
  associationTabs?: CollectionAssociationTabConfig[];
  // union type for backwards compatibility
  permissions?: Collection["permissions"] | CollectionRecordPermissions;
};

interface CollectionOptions extends Partial<EntityOptions> {
  icon?: string;
  description?: string;
  nextId?: number;
  activities?: CollectionActivityConfig;
  tasks?: CollectionTaskConfig;
  files?: CollectionFilesConfig;
  associationTabs?: CollectionAssociationTabConfig[];
  permissions?: CollectionPermissions;
  uniqueFieldRestrictions?: UniqueFieldRestriction[];
  idFormatConfig?: CollectionIdConfig;
  extensionId?: string;
}

export class Collection extends KEntity {
  id: string;

  /** Next ID to use when creating a new record */
  nextId?: number;

  /** Icon for collection */
  icon: string;

  /** Description of collection (user facing) */
  description?: string;

  /** ID of extension that owns this collection */
  extensionId?: string;

  /** ID Format configuration for records */
  idFormatConfig?: CollectionIdConfig;

  /** Configuration for unique field restrictions */
  uniqueFieldRestrictions?: UniqueFieldRestriction[];

  /** Configuration for collection activities */
  activities?: CollectionActivityConfig;

  /** Configuration for collection tasks */
  tasks?: CollectionTaskConfig;

  /** Configuration for collection files */
  files?: CollectionFilesConfig;

  /** How association tabs are displayed */
  associationTabs?: CollectionAssociationTabConfig[];

  /** String[] for collection */
  permissions: CollectionPermissions;

  constructor(id: string, singular: string, schema: KField[], options?: CollectionOptions) {
    const idField = new IdField(options?.idFormatConfig ?? { visible: false });
    super(singular, schema, options, [
      idField,
      new DatetimeField({ label: "Created At", key: "createdAt", serverControlled: true, precision: "minute" }),
      new DatetimeField({ label: "Updated At", key: "updatedAt", serverControlled: true, precision: "minute" }),
      new UserField({ label: "Created By", key: "createdBy", serverControlled: true }),
      new UserField({ label: "Updated By", key: "updatedBy", serverControlled: true }),
      new MultiObjectField(
        { label: "External", key: "external", form: { ignore: true }, serverControlled: true },
        new KEntity("ExternalService", [new StringField("Key"), new ObjectField("Value", new KEntity("service", [new StringField("Url")]))])
      )
    ]);
    this.id = id;
    this.icon = options?.icon ?? "folder";
    this.nextId = options?.nextId;
    this.description = options?.description;
    this.idFormatConfig = options?.idFormatConfig;
    this.uniqueFieldRestrictions = options?.uniqueFieldRestrictions;
    this.activities = options?.activities;
    this.tasks = options?.tasks;
    this.files = options?.files;
    this.associationTabs = options?.associationTabs;
    this.permissions = options?.permissions ?? { read: ["true"], write: ["true"], configure: [] };
    this.extensionId = options?.extensionId;
  }

  get stageField(): StageField | undefined {
    const possible = this.getFieldsOfType(StageField);
    switch (possible.length) {
      case 0:
        return undefined;
      case 1:
        return possible[0];
      default:
        throw new Error(`Collection ${this.singular} has multiple stage fields`);
    }
  }

  getEffectivePermissions(r?: KRecord): CollectionRecordPermissions {
    if (!this.stageField?.enabled || !this.stageField.stages.some((s) => s.permissions)) return this.permissions;
    if (r === undefined) {
      // combine permissions of all stages
      const permissions: CollectionRecordPermissions = { read: [], write: [] };
      for (const stage of this.stageField.stages) {
        const stagePermissions = stage.permissions ?? this.permissions;
        permissions.read.push(...stagePermissions.read.map((p) => `record.${this.stageField!.id} matches ${toJson(stage.label)} and (${p})`));
        permissions.write.push(...stagePermissions.write.map((p) => `record.${this.stageField!.id} matches ${toJson(stage.label)} and (${p})`));
      }
      return permissions;
    }
    const stage = this.stageField.getStageValue(r);
    return stage?.permissions ?? this.permissions;
  }

  getStage(r: KRecord) {
    return this.stageField?.getStageValue(r);
  }

  get isReadOnly(): boolean {
    return !!this.extensionId;
  }
}

export const restoreFields = (stored: (ServerField | UploadServerField)[]): KField[] => {
  try {
    return fieldRegistry.bulkRestore(
      stored.map((s) => {
        const data = typeof s.data === "string" ? fromJson<Record<string, JsonSerialisable>>(s.data) : s.data;
        return { type: s.type, data: { ...data, id: s.id ?? s.key, key: s.key } };
      })
    );
  } catch (e) {
    console.error("Failed to restore fields", e);
    return [];
  }
};

/**
 * Convert a server collection into a full Collection
 *
 * @returns Collection instance created from record data
 */
export const restoreCollection = (sc: ServerCollection): Collection => {
  if (sc.permissions && !("configure" in sc.permissions)) {
    sc.permissions = { ...sc.permissions, configure: [] };
  }
  const result = new Collection(sc.id, sc.singular, restoreFields(sc.schema), {
    nextId: sc.nextId,
    description: sc.description,
    plural: sc.plural,
    slug: sc.slug,
    icon: sc.icon,
    primaryKey: sc.primaryProperty,
    secondaryKey: sc.secondaryProperty,
    sortKey: sc.sortProperty,
    sortDirection: sc.defaultSortDirection,
    idFormatConfig: sc.idFormatConfig,
    uniqueFieldRestrictions: sc.uniqueFieldRestrictions,
    activities: sc.activities,
    tasks: sc.tasks,
    files: sc.files,
    associationTabs: sc.associationTabs,
    extensionId: sc.extensionId,
    permissions: sc.permissions
  });
  result.validators = sc.conditionalValidators ?? [];
  return result;
};
