<template>
  <k-input-config :disabled="noLinks">
    <input-collection v-model="input.targetCollection" label="Target collection" :collections="possibleTargets" />
  </k-input-config>
  <k-issue-display :issues="noLinks ? [{ message: 'No other collections link to this collection', severity: 'error' }] : []" />
  <k-input-config :disabled="linkingFields.length === 1">
    <k-input-field-select
      v-if="targetCollection"
      label="Link field"
      :model-value="currentLinkField?.field"
      :options="linkingFields.map((f) => f.field)"
      required
      @update:model-value="setLinkField" />
  </k-input-config>
  <template v-if="targetCollection">
    <filter-config v-model="nonNullFilters" label="Filter" :entity="targetCollection" />
    <k-input-select v-model="input.mode" label="Mode" :options="validModeOptions" required />
    <k-input-config v-if="currentLinkField && input.mode !== 'count'" :issues="targetFieldIssues">
      <k-input-field-select v-model="targetField" label="Target field" :options="possibleTargetFields" required />
    </k-input-config>
    <k-input-config v-if="input.mode === 'delta'" :issues="timeFieldIssues">
      <k-input-field-select v-model="timeField" label="Time field" :options="possibleTimeFields" required />
    </k-input-config>
  </template>
</template>

<script setup lang="ts">
import { computed, watch, watchEffect } from "vue";

import KInputFieldSelect from "@ui/inputs/select/KInputFieldSelect.vue";
import type { SelectItem } from "@ui/inputs/select/SelectItem";
import KInputSelect from "@ui/inputs/select/KInputSelect.vue";
import KInputConfig from "@ui/inputs/KInputConfig.vue";
import KIssueDisplay from "@ui/inputs/KIssueDisplay.vue";

import FilterConfig from "./FilterConfig.vue";

import type { ValidationIssues } from "@data/validation/Validation";
import type { Collection } from "@data/data/Collection";
import { isAccessible } from "@data/expressions/accessors";
import { expressionParser } from "@data/expressions/parser";
import type { KField } from "@data/fields/KField";
import type { AggregationFieldConfig } from "@data/fields/relational/AggregationField";
import { MultiRecordField, getRelationalFields } from "@data/fields/relational/RecordFields";
import { DefaultMap } from "@data/helpers/maps/DefaultMap";

import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { manageObjectVModel } from "@/helpers/vModel";
import InputCollection from "@/components/inputs/InputCollection.vue";

const props = defineProps<{
  /** Value of the input */
  modelValue: AggregationFieldConfig | undefined;
  /** Collection to configure aggregation for */
  collection: Collection;
  /** Proposed collections to also show as link options */
  proposedCollections?: Collection[];
}>();

const emit = defineEmits<{
  (event: "update:modelValue", newValue: AggregationFieldConfig | undefined): void;
  (event: "update:valid", newValue: boolean): void;
}>();

const input = manageObjectVModel(
  computed<AggregationFieldConfig>(() => props.modelValue ?? { link: {}, mode: "count" }),
  emit
);

const nonNullFilters = computed({
  get: () => input.value.filters ?? [],
  set: (value) => {
    input.value.filters = value.length ? value : undefined;
  }
});

const modeOptions: ({ value: AggregationFieldConfig["mode"] } & SelectItem)[] = [
  { value: "count", label: "Count" },
  { value: "sum", label: "Sum" },
  { value: "average", label: "Average" },
  { value: "min", label: "Minimum" },
  { value: "max", label: "Maximum" },
  { value: "delta", label: "Change over time (Latest - Earliest)" }
];

const collectionStore = useCollectionStore();
const allCollections = computed(() => (collectionStore.collections ?? []).concat(props.proposedCollections ?? []));
const collectionMap = computed(() => new Map<string | undefined, Collection>(allCollections.value.map((c) => [c.id, c])));
const targetCollection = computed(() => collectionMap.value.get(input.value.targetCollection));

const possibleLinks = computed(() => {
  const result = new DefaultMap<string, { field: KField; type: "foreign" | "local" }[]>(() => []);

  const localFields = props.collection.getFieldsOfType(MultiRecordField);
  for (const field of localFields) {
    result.get(field.collectionId).push({ field, type: "local" });
  }
  for (const collection of collectionMap.value.values()) {
    const foreignFields = getRelationalFields(collection);
    for (const field of foreignFields) {
      if (field.collectionId === props.collection.id) {
        result.get(collection.id).push({ field, type: "foreign" });
      }
    }
  }

  return result;
});

const noLinks = computed<boolean>(() => !allCollections.value.some((c) => possibleLinks.value.get(c.id).length));

const possibleTargets = computed(() => allCollections.value.filter((c) => c.id !== props.collection.id && possibleLinks.value.get(c.id).length));

const linkingFields = computed(() => {
  if (!targetCollection.value) return [];
  return possibleLinks.value.get(targetCollection.value.id);
});

const currentLinkField = computed(() => {
  const currentLink = input.value.link.localField ?? input.value.link.foreignField;
  return linkingFields.value.find((f) => f.field.id === currentLink);
});

const setLinkField = (field?: KField) => {
  const newLinkField = linkingFields.value.find((f) => f.field.id === field?.id);
  if (!newLinkField) {
    input.value.link = { localField: undefined, foreignField: undefined };
  } else if (newLinkField.type === "local") {
    input.value.link = { localField: field!.id, foreignField: undefined };
  } else {
    input.value.link = { localField: undefined, foreignField: field!.id };
  }
};

watch(
  linkingFields,
  (links) => {
    if (links.length === 1) {
      setLinkField(links[0].field);
    }
  },
  { immediate: true }
);

const possibleTargetFields = computed(() => {
  if (!targetCollection.value) return [];
  return targetCollection.value.fields.filter((f) => isAccessible(f, "number") || isAccessible(f, "duration"));
});

const targetField = computed<KField | undefined>({
  get: () => {
    if (targetCollection.value && input.value.expression) {
      const dependencies = expressionParser.getDependencies(input.value.expression);
      if (dependencies.size !== 1) return undefined;
      for (const dependency of dependencies) {
        return targetCollection.value.getField(dependency);
      }
    }
    return undefined;
  },
  set: (field) => {
    if (!targetCollection.value) return;
    input.value.expression = field ? `record.${field.id}` : undefined;
    if (field && isAccessible(field, "duration")) {
      input.value.durationUnit = field.precision;
    }

    input.value.unit = input.value.expression
      ? expressionParser.parseNumber(input.value.expression, { entity: targetCollection.value }).unit?.expressionSymbol
      : undefined;
  }
});

const targetFieldIssues = computed<ValidationIssues>(() => {
  if (input.value.mode !== "count" && !targetField.value) {
    return [{ message: "Target field is required", severity: "error" }];
  }
  return [];
});

const possibleTimeFields = computed(() => {
  if (!targetCollection.value) return [];
  return targetCollection.value.fields.filter((f) => isAccessible(f, "datetime"));
});

const timeField = computed<KField | undefined>({
  get: () => {
    if (targetCollection.value && input.value.timeField) {
      return targetCollection.value.getField(input.value.timeField);
    }
    return undefined;
  },
  set: (field) => {
    if (!targetCollection.value) return;
    input.value.timeField = field?.id;
  }
});

const timeFieldIssues = computed<ValidationIssues>(() => {
  if (input.value.mode === "delta" && !timeField.value) {
    return [{ message: "Time field is required", severity: "error" }];
  }
  return [];
});

const validModes = computed(() => {
  const result: AggregationFieldConfig["mode"][] = ["count"];
  if (possibleTargetFields.value.length) {
    result.push("sum", "average", "min", "max");
    if (possibleTimeFields.value.length) {
      result.push("delta");
    }
  }
  return result;
});

const validModeOptions = computed(() => modeOptions.filter((o) => validModes.value.includes(o.value)));

watchEffect(() => {
  if (!validModes.value.includes(input.value.mode)) {
    input.value.mode = "count";
  }
});

watchEffect(() => {
  if (input.value.mode === "count") {
    input.value.expression = undefined;
    input.value.unit = undefined;
  }
  if (input.value.mode !== "delta") {
    input.value.timeField = undefined;
  }
});

const valid = computed(() => !targetFieldIssues.value.length && !timeFieldIssues.value.length);

watch(valid, (newValue) => emit("update:valid", newValue), { immediate: true });
</script>
