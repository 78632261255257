<template>
  <Transition>
    <div v-if="selectedTasks.length" class="table-selection-toolbar-container">
      <div class="table-selection-toolbar ps-3 pe-1">
        <div class="float-end pt-1">
          <k-button variant="transparent" title="Menu" icon="ellipsis" @click="onMenuClick" />
          <k-button variant="transparent" title="Cancel (Esc)" icon="close" @click="emit('clear')" />
        </div>
        <div v-if="selectedCount === totalCount" class="selection-label text-primary">Everything selected</div>
        <div v-else class="selection-label text-primary">{{ selectedCount }} of {{ totalCount }} selected</div>
      </div>
      <k-context-menu v-if="multiMenuOptions.length > 0" ref="multiContextMenu" :items="multiMenuOptions" />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

import KButton from "@ui/button/KButton.vue";
import KContextMenu from "@ui/context-menu/KContextMenu.vue";
import { hasContextItems, type ContextMenuItem } from "@ui/context-menu/ContextMenuItem";
import type { KContextMenuElement } from "@ui/context-menu/ContextMenuElement";

import type { KTask } from "@data/data/Task";

const props = defineProps<{
  /** Selected tasks */
  selectedTasks: KTask[];
  /** Total number of records */
  totalCount: number;
  /** Context menu items */
  multiContextMenuItems?: ContextMenuItem<KTask[]>[];
}>();

const emit = defineEmits<{
  (event: "select-all"): void;
  (event: "clear"): void;
}>();

const selectedCount = computed(() => props.selectedTasks.length);

const selectionMenuOptions: ContextMenuItem<KTask[]> = {
  label: "Selection",
  items: [
    {
      label: "Select all",
      showOption() {
        return selectedCount.value !== props.totalCount;
      },
      onClick: () => emit("select-all")
    },
    {
      label: "Unselect all",
      showOption() {
        return selectedCount.value > 0;
      },
      onClick: () => emit("clear")
    }
  ]
};

// Initialise context menus
const multiContextMenu = ref<KContextMenuElement<KTask[]>>();
const multiMenuOptions = computed<ContextMenuItem<KTask[]>[]>(() => {
  const results: ContextMenuItem<KTask[]>[] = [];

  if (props.multiContextMenuItems) {
    results.push(...props.multiContextMenuItems, {
      type: "divider"
    });
  }
  results.push(selectionMenuOptions);
  return results;
});

const onMenuClick = (evt: MouseEvent) => {
  evt.preventDefault();
  evt.stopPropagation();
  const selection = props.selectedTasks;
  if (hasContextItems(props.multiContextMenuItems, selection)) {
    multiContextMenu.value?.show(evt, selection, { alignment: "end", direction: "top" });
  }
};
</script>

<style scoped>
.table-selection-toolbar-container {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 9002;
  pointer-events: none;
}
.table-selection-toolbar {
  background: var(--k-dropdown-background);
  border-radius: 8px;
  box-shadow: var(--k-dropdown-shadow);
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  height: 40px;
  border: 1px solid var(--k-color-primary);
  pointer-events: all;
}

.selection-label {
  font-size: 0.9em;
  line-height: 38px;
  font-weight: 500;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.1s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  bottom: 0px;
}
</style>
