import { unref } from "vue";
import type { MaybeRef } from "vue";

import { gql } from "@apollo/client/core";
import { useMutation, useSubscription } from "@vue/apollo-composable";

import { ImportAPI } from "./import";
import { recordQueries } from "./recordQueries";
import { fullRecord } from "./fragments";

import type { RecordUpdateEvent, StoredKRecord } from "@data/data/Record";
import type { StaticFieldValue } from "@data/fields/KField";
import type { ServiceMetadata } from "@data/data/Extension";

import type { ApolloCache } from "@apollo/client/core";

/** Add a record */
const addRecord = () =>
  useMutation<{ addRecord: { id: string } }, { value: { collectionId: string; record: string; external: ServiceMetadata[] | undefined } }>(gql`
    mutation addRecord($value: AddRecordInput!) {
      addRecord(record: $value) {
        id
      }
    }
  `);

/** Upload a file to pending folder */
const uploadPendingFile = () =>
  useMutation<{ uploadPendingFile: { id: string } }, { file: File }>(gql`
    mutation uploadPendingFile($file: Upload!) {
      uploadPendingFile(file: $file) {
        id
      }
    }
  `);

/** Update a record */
const updateRecord = () =>
  useMutation<
    { updateRecord: { id: string; data: StoredKRecord } },
    { value: { collectionId: string; id: string; data: string; external: ServiceMetadata[] | undefined } }
  >(gql`
    mutation updateRecord($value: UpdateRecordInput!) {
      updateRecord(record: $value) {
        id
        data
        createdAt
        createdBy
        updatedAt
        updatedBy
        collection
      }
    }
  `);

/** Update lots of records */
const bulkUpdateRecords = () =>
  useMutation<
    { bulkUpdateRecords: { id: string; data: StoredKRecord }[] },
    { update: { collectionId: string; selection: ServerRecordSelection; data: string; fields: string[] } }
  >(gql`
    mutation bulkUpdateRecords($update: BulkUpdateRecordInput!) {
      bulkUpdateRecords(update: $update) {
        id
        data
        createdAt
        createdBy
        updatedAt
        updatedBy
        collection
      }
    }
  `);

type UpdateStageInput = {
  collectionId: string;
  stageId: string;
  signature?: string;
  extraData?: StaticFieldValue[];
};
/** Advance record stage */
const advanceRecordStage = () =>
  useMutation<{ advanceRecordStage: boolean }, { value: UpdateStageInput & { recordId: string } }>(gql`
    mutation advanceRecordStage($value: AdvanceRecordStageInput!) {
      advanceRecordStage(input: $value)
    }
  `);

export type ServerRecordSelection = {
  include?: string[];
  exclude?: string[];
  expressionFilters?: string[];
};
/** Advance lots of records' stages */
const bulkAdvanceRecordStage = () =>
  useMutation<{ bulkAdvanceRecordStage: boolean }, { value: UpdateStageInput & { selection: ServerRecordSelection } }>(gql`
    mutation bulkAdvanceRecordStage($value: BulkAdvanceRecordStageInput!) {
      bulkAdvanceRecordStage(input: $value)
    }
  `);

const removeCachedRecord = (cache: ApolloCache<unknown>, collectionId: string, recordId: string) => {
  // Handle both potential formats of the cache key (see apollo.ts, dataIdFromObject)
  cache.evict({ id: `Record:${collectionId}:${recordId}` });
  cache.evict({ id: `Record:"${collectionId}":${recordId}` });
  cache.gc();
};
/** Merge two (or more) records together */
const mergeRecords = () =>
  useMutation<{ mergeRecords: { id: string; data: StoredKRecord } }, { input: { collectionId: string; targetId: string; otherIds: string[]; data: string } }>(
    gql`
      mutation mergeRecords($input: MergeRecordsInput!) {
        mergeRecords(input: $input) {
          id
          data
        }
      }
    `,
    () => ({
      update(cache, data, options) {
        if (options.variables) {
          for (const otherId of options.variables.input.otherIds) {
            removeCachedRecord(cache, options.variables.input.collectionId, otherId);
          }
          cache.gc();
        }
      }
    })
  );

/** Delete a record */
const deleteRecord = () =>
  useMutation<{ deleteRecord: boolean }, { collectionId: string; recordId: string }>(
    gql`
      mutation deleteRecord($recordId: String!, $collectionId: String!) {
        deleteRecord(recordId: $recordId, collectionId: $collectionId)
      }
    `,
    () => ({
      update(cache, data, options) {
        if (options.variables) {
          removeCachedRecord(cache, options.variables.collectionId, options.variables.recordId);
          cache.gc();
        }
      }
    })
  );
/** Delete multiple records */
const bulkDeleteRecords = () =>
  useMutation<{ bulkDeleteRecords: boolean }, { collectionId: string; selection: ServerRecordSelection }>(
    gql`
      mutation bulkDeleteRecords($selection: RecordSelectionInput!, $collectionId: String!) {
        bulkDeleteRecords(selection: $selection, collectionId: $collectionId)
      }
    `,
    () => ({
      update(cache, _data, options) {
        for (const recordId of options.variables?.selection.include ?? []) {
          cache.evict({ id: `Record:${options.variables?.collectionId ?? ""}:${recordId}` });
        }
        cache.gc();
      }
    })
  );

/**
 * Handle record update events (from subscriptions)
 *
 * @param collectionId
 */
const onRecordUpdateEvent = (collectionId: MaybeRef<string | undefined>, filters: MaybeRef<string[]>) =>
  useSubscription<{ recordUpdated: RecordUpdateEvent }>(
    gql`
      subscription onRecordUpdated($collectionId: String!, $filters: [String!]!) {
        recordUpdated(collectionId: $collectionId, filters: $filters) {
          collectionId
          recordIds
          recordData {
            ${fullRecord}
          }
          eventType
        }
      }
    `,
    () => ({ collectionId: unref(collectionId), filters: unref(filters) }),
    () => ({ fetchPolicy: "no-cache", enabled: !!unref(collectionId) })
  );

export const RecordAPI = {
  ...recordQueries,
  addRecord,
  // bulkAddRecords,
  advanceRecordStage,
  bulkAdvanceRecordStage,
  updateRecord,
  bulkUpdateRecords,
  mergeRecords,
  deleteRecord,
  bulkDeleteRecords,
  onRecordUpdateEvent,
  uploadPendingFile,
  ...ImportAPI
};
