import type { Ref } from "vue";

import { gql } from "@apollo/client/core";
import { useMutation, useSubscription } from "@vue/apollo-composable";

const generateCollectionNames = () =>
  useMutation<{ prompt: string; generationId: string }>(gql`
    mutation generateCollectionNames($prompt: String!, $generationId: String!) {
      generateCollectionNames(prompt: $prompt, generationId: $generationId)
    }
  `);

const onCollectionNamesStreamUpdated = (generationId: Ref<string>) =>
  useSubscription<{ collectionNamesStreamUpdate: string }>(
    gql`
      subscription onCollectionNamesStreamUpdated($generationId: String!) {
        collectionNamesStreamUpdate(generationId: $generationId)
      }
    `,
    () => ({
      generationId: generationId.value
    })
  );

const generateCollections = () =>
  useMutation<{ prompt: string; generationId: string; proposedCollectionNames: string[] }>(gql`
    mutation generateCollections($prompt: String!, $generationId: String!, $proposedCollectionNames: [String!]!) {
      generateCollections(prompt: $prompt, generationId: $generationId, proposedCollectionNames: $proposedCollectionNames)
    }
  `);

const onCollectionStreamUpdated = (generationId: Ref<string>) =>
  useSubscription<{ collectionStreamUpdate: string }>(
    gql`
      subscription onCollectionStreamUpdated($generationId: String!) {
        collectionStreamUpdate(generationId: $generationId)
      }
    `,
    () => ({
      generationId: generationId.value
    })
  );

const cancelCollectionGeneration = () =>
  useMutation<{ generateCollectionCancel: boolean }, { generationId: string }>(gql`
    mutation generateCollectionCancel($generationId: String!) {
      generateCollectionCancel(generationId: $generationId)
    }
  `);

export const CollectionGenerationAPI = {
  generateCollectionNames,
  onCollectionNamesStreamUpdated,
  generateCollections,
  onCollectionStreamUpdated,
  cancelCollectionGeneration
};
