import type { Ref } from "vue";
import { computed } from "vue";

import { useTimeAgo } from "@vueuse/core";
import dayjs from "dayjs";

import type { KContextMenuElement } from "@ui/context-menu/ContextMenuElement";
import type { ContextMenuItem } from "@ui/context-menu/ContextMenuItem";

import type { Dayjs } from "dayjs";

export const useTaskDatePicker = (taskDueDate: Ref<Dayjs | undefined>, timePickerMenu: Ref<KContextMenuElement<Date> | undefined>) => {
  const timestampTimeAgo = useTimeAgo<"day">(computed(() => taskDueDate.value?.toDate() ?? dayjs().toDate()));
  const timestampDisplayValue = computed(() => {
    if (!taskDueDate.value) return undefined;
    if (taskDueDate.value.isSame(dayjs(), "day")) return "Due today";
    if (taskDueDate.value.isSame(dayjs().add(1, "day"), "day")) return "Due tomorrow";
    return timestampTimeAgo.value === "just now" ? "Due today" : `Due ${timestampTimeAgo.value}`;
  });

  const setTimestampToNearest15Mins = (date: Dayjs) => {
    // Round down to nearest 15 minutes, to make the display look tidier when using 'hours ago'
    // User can always override using the time picker
    taskDueDate.value = date.minute(Math.floor(date.minute() / 15) * 15);
  };

  const timePickerOptions: ContextMenuItem<Date>[] = [
    {
      label: "Select date/time",
      type: "date-time-picker",
      value: () => taskDueDate.value,
      setValue: (record, value) => {
        if (value?.isValid()) taskDueDate.value = value;
      }
    },
    { type: "divider" },
    {
      label: "Due today",
      onClick: () => {
        taskDueDate.value = dayjs();
      }
    },
    {
      label: "Due tomorrow",
      onClick: () => {
        setTimestampToNearest15Mins(dayjs().add(1, "day"));
      }
    },
    {
      label: "Due in 2 days",
      onClick: () => {
        setTimestampToNearest15Mins(dayjs().add(2, "days"));
      }
    },
    {
      label: "Due next week",
      onClick: () => {
        setTimestampToNearest15Mins(dayjs().add(7, "days"));
      }
    },
    {
      type: "divider"
    },
    {
      label: "Reset",
      onClick: () => {
        taskDueDate.value = undefined;
      }
    }
  ];

  const showTimePicker = (evt: MouseEvent) => {
    timePickerMenu.value?.show(evt, undefined, { alignment: "start", direction: "bottom" });
  };

  return {
    timestampDisplayValue,
    showTimePicker,
    timePickerMenu,
    timePickerOptions
  };
};
