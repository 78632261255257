<template>
  <div class="form-control-container">
    <div class="d-flex gap-2 align-items-end">
      <k-input-config :status="unknownField ? 'INVALID' : 'NONE'" :issues="parsed.issues">
        <k-input-select v-model="selectedField" :label :options="fieldOptions" invalid-label="Unknown Field" />
      </k-input-config>
      <k-button :title="delta ? 'Remove Offset' : 'Add Offset'" icon="plus-minus" @click="toggleDelta" />
    </div>
    <div v-if="delta" class="d-flex gap-2 align-items-end">
      <k-input-config :status="deltaIssues?.length ? 'INVALID' : undefined">
        <k-input-number v-model="delta.amount" label="Offset" />
      </k-input-config>
      <k-input-config>
        <k-input-select v-model="delta.precision" label="Unit" :options="precisionOptions" required />
      </k-input-config>
    </div>
    <k-issue-display :issues="deltaIssues" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import KInputSelect from "@ui/inputs/select/KInputSelect.vue";
import { useInputConfig, mergeInputConfig } from "@ui/inputs/inputConfig";
import type { SelectItem } from "@ui/inputs/select/SelectItem";
import KInputNumber from "@ui/inputs/numeric/KInputNumber.vue";
import KButton from "@ui/button/KButton.vue";
import KInputConfig from "@ui/inputs/KInputConfig.vue";
import KIssueDisplay from "@ui/inputs/KIssueDisplay.vue";

import { toFormula } from "./convertInput";

import { hasCircularReference, toConstant } from "@data/expressions/helpers";
import { expressionParser } from "@data/expressions/parser";
import type { MetricUnit } from "@data/units/units";
import type { ValidationIssues } from "@data/validation/Validation";
import type { KEntity } from "@data/data/KEntity";
import { isAccessible } from "@data/expressions/accessors";
import { datetimePrecisionOptions, type DatetimePrecision } from "@data/fields/basic/DatetimeField";
import { capitalise } from "@data/helpers/strings/Casing";
import { toPlural } from "@data/helpers/strings/Plurals";
import { isHigherPrecision } from "@data/expressions/datetime";

import { useKContext } from "@/expressions/context";
import { isComputedField } from "@/expressions/computed";

const props = defineProps<{
  /** Label for the input */
  label: string;
  /** Current field ID (required to detect circular references) */
  fieldId?: string;
  /** Collection to write formulas for */
  collection?: KEntity;
  /** Target precision for the formula */
  precision?: DatetimePrecision;
}>();

const expression = defineModel<string>();

const context = useKContext();
// load the collection from the context if not provided (and need different name to avoid conflict with prop)
const actualCollection = computed(() => props.collection ?? context.value.entity);

const fieldOptions = computed(() => {
  const options: SelectItem[] = [
    {
      value: "now()",
      label: "Current Time"
    }
  ];
  if (actualCollection.value) {
    options.push(
      ...actualCollection.value.allFields
        .filter((f) => isAccessible(f, "datetime") && f.id !== props.fieldId)
        .map((f) => ({
          value: `record.${f.id}`,
          label: f.label
        }))
    );
  }
  return options;
});

const selectedField = ref<string>();
const unknownField = computed(() => selectedField.value && !fieldOptions.value.some((f) => f.value === selectedField.value));
// const fieldPrecision = computed(() => {
//   if (!selectedField.value || !actualCollection.value) {
//     return undefined;
//   }
//   const parsed = expressionParser.parseDatetime(selectedField.value, { entity: actualCollection.value });
//   return parsed.precision;
// });

const delta = ref<{ amount?: number; precision?: DatetimePrecision }>();
const toggleDelta = () => {
  delta.value = delta.value ? undefined : {};
};
const deltaIssues = computed(() => {
  if (!delta.value) {
    return [];
  }
  const issues: ValidationIssues = [];
  if (delta.value.amount && delta.value.amount % 1) {
    issues.push({ message: "Offset must be a whole number", severity: "error" });
  }
  return issues;
});
const precisionOptions = computed(() =>
  datetimePrecisionOptions
    .filter((p) => p !== "quarter" && !(props.precision && isHigherPrecision(p, props.precision)))
    .map((p) => ({ value: p, label: capitalise(toPlural(p)) }))
);

const deltaRegex = /^(year|month|day|hour|minute)s\((\d+)\) (before|after) (.+)$/;

const parsed = computed<{ valid?: string; unit?: MetricUnit; issues?: ValidationIssues }>(() => {
  if (!selectedField.value || deltaIssues.value.length) {
    return {};
  }
  const newExpression =
    delta.value?.amount && delta.value.precision
      ? `${toConstant({
          value: Math.abs(delta.value.amount),
          precision: delta.value.precision
        })} ${delta.value.amount < 0 ? "before" : "after"} ${selectedField.value}`
      : selectedField.value;
  try {
    if (
      props.fieldId &&
      actualCollection.value &&
      hasCircularReference(
        newExpression,
        props.fieldId,
        actualCollection.value.fields.filter((f) => isComputedField(f))
      )
    ) {
      return { issues: [{ message: "This would create a circular reference", severity: "error" }] };
    }
    expressionParser.parseDatetime(newExpression, { entity: actualCollection.value });
    return { valid: newExpression };
  } catch (e) {
    if (e instanceof Error) {
      return { issues: [{ message: toFormula(e.message, actualCollection.value), severity: "error" }] };
    }
  }
  return {};
});

watch(
  expression,
  (newValue) => {
    if (parsed.value.valid && newValue === parsed.value.valid) {
      return;
    }
    if (newValue) {
      const match = newValue.match(deltaRegex);
      if (match) {
        const sign = match[3] === "before" ? -1 : 1;
        delta.value = {
          amount: parseFloat(match[2]) * sign,
          precision: match[1] as DatetimePrecision
        };
        selectedField.value = match[4];
        return;
      }
    }
    delta.value = undefined;
    selectedField.value = newValue;
  },
  { immediate: true }
);

const config = useInputConfig();

mergeInputConfig(
  computed(() => ({
    issues: config.value.issues.concat(parsed.value.issues ?? [])
  }))
);

watch(
  () => parsed.value.valid,
  (newValue) => {
    if (newValue) {
      expression.value = newValue;
    }
  }
);
</script>
