<template>
  <navbar v-if="!route.meta.printLayout" />
  <div v-if="showLoggedInRouterContent" :id="route.meta.printLayout ? 'content-print' : 'content'">
    <router-view />
  </div>
  <router-view v-if="showLoggedOutRouterContent" v-slot="{ Component }">
    <logged-out-layout :key="route.fullPath">
      <component :is="Component" />
    </logged-out-layout>
  </router-view>
  <logged-out-layout v-else-if="showLoggedOutContent && useAnonymousLayout === false" data-test="show-login">
    <login />
  </logged-out-layout>
  <logged-out-layout v-else-if="showLoggedOutContent" />
  <k-alert-box />
</template>

<script setup lang="ts">
/*
 * Note: Multiple LoggedOutLayouts are used for:
 *  - Router View > LoggedOutLayout is for pages such as sign-up / reset password where users are logged out
 *  - LoggedOutLayout > Login is for the login page (applies regardless of route when useAnonymousLayout is false)
 *  - LoggedOutLayout (!isAuthenticated) is used to fade out the background overlay faster than the login box when user signs in.
 *      This background gets faded out first when isAuthenticated becomes true, followed by the
 *      showLoggedOutContent (containing the login form) being faded out later (using the timeout).
 *      This effect works because the v-if triggers the exit animation inside the LoggedOutComponent in succession.
 */
import { computed, ref, watch, onBeforeMount } from "vue";
import { useRoute, RouterView } from "vue-router";

import KAlertBox from "@ui/alerts/KAlertBox.vue";

import { provideKBInfo } from "./expressions/context";
import LoggedOutLayout from "./pages/LoggedOut.vue";
import Login from "./pages/auth/SignInPage.vue";
import { provideInitialState } from "./api/initial";
import { provideVoipState } from "./api/voip";
import Navbar from "./pages/NavigationBar.vue";

import { TransitionDurations } from "@/consts/transitions";
import { useAuth } from "@/services/auth";

const { isAuthenticated, checkAuth } = useAuth();

const route = useRoute();

// Avoid janky login screen flash when user signs in via external provider, e.g. Microsoft
const isSignInRedirect = route.query["signed-in"] === "true";
const showLoggedOutContent = ref(!isAuthenticated.value && !isSignInRedirect);
const loaded = ref(false);

onBeforeMount(async () => {
  await checkAuth();
  loaded.value = true;
  // Update showLoggedOutContent once we know if user is actually logged in
  // Handles case where ?signed-in=true is passed in URL but user isn't signed in
  showLoggedOutContent.value = !isAuthenticated.value;
});

// Some pages (e.g. sign-up) require anonymous users only
const useAnonymousLayout = computed(() => !!route.meta.useLoggedOutLayout);

const showLoggedInRouterContent = computed(
  () =>
    // Show logged in content if user is logged in, or if it's not fully loaded yet but ?signed-in=true is passed in URL
    (isAuthenticated.value || (!loaded.value && isSignInRedirect)) &&
    // AND: Show logged in content if the page does not need the logged out layout
    (!route.meta.useLoggedOutLayout || route.meta.allowLoggedInLayout)
);

// Show most logged out routes (e.g. forgot password, sign up) in the logged out layout
const showLoggedOutRouterContent = computed(() => (showLoggedOutContent.value && useAnonymousLayout.value) || route.meta.useLoggedOutLayout);

const state = provideInitialState();
// Provide global context information at the top level
provideKBInfo(state);
// Provide VoIP function
provideVoipState(state);

// After user logs in, delay hiding the login screen div for a few milliseconds to give
// content loading a head start and to give time for login screen to animate out with a cross-fade
watch(isAuthenticated, () => {
  if (isAuthenticated.value === true) {
    setTimeout(() => {
      showLoggedOutContent.value = false;
    }, TransitionDurations.SIGN_IN_FADE_DURATION);
  } else {
    showLoggedOutContent.value = !isAuthenticated.value;
  }
});
</script>
