<!-- eslint-disable vuejs-accessibility/media-has-caption -->
<template>
  <Transition>
    <div v-if="active" class="camera-container mt-n1">
      <k-spinner v-if="!cameraActive" size="lg" class="camera-spinner" />
      <video ref="cameraVideo" class="camera-feed" autoplay playsinline muted></video>
      <div class="camera-buttons text-end py-2 pe-2">
        <k-dropdown variant="secondary" dropup size="sm" class="float-start ms-2" icon="camera-rotate" title="Switch Camera">
          <k-dropdown-item
            v-for="camera in videoDeviceList"
            :key="camera.deviceId"
            :label="camera.label || camera.deviceId"
            @click="switchCamera(camera.deviceId)" />
        </k-dropdown>
        <k-button variant="secondary" size="sm" class="float-start ms-2" icon="bolt-auto" title="Switch Camera" @click="cancel" />
        <k-button variant="secondary" size="sm" class="me-2" label="Cancel" @click="cancel" />
        <k-button variant="info" size="sm" icon="camera" class="take-photo" label="Take Photo" @click="takePicture" />
      </div>
    </div>
  </Transition>
  <!-- hidden canvas for processing image-->
  <canvas ref="canvas" class="d-none"></canvas>
</template>

<script setup lang="ts">
import { nextTick, onBeforeUnmount, ref, watch } from "vue";

import dayjs from "dayjs";

import { AlertHandler } from "@ui/alerts/alert-handler";
import KButton from "@ui/button/KButton.vue";
import KDropdown from "@ui/dropdown/KDropdown.vue";
import KDropdownItem from "@ui/dropdown/KDropdownItem.vue";
import KSpinner from "@ui/progress/KSpinner.vue";

const props = defineProps<{
  /** Whether the camera is active (use v-model:active) */
  active: boolean;
}>();

const emit = defineEmits<{
  (event: "photo", file: File): void;
  (event: "update:active", value: boolean): void;
}>();

const alertHandler = new AlertHandler();

let cameraSrc: MediaStream | null = null;
const cameraVideo = ref<HTMLVideoElement>();
const cameraActive = ref(false);
const canvas = ref<HTMLCanvasElement>();

const currentDeviceId = ref<string>("");

const startCamera = async () => {
  if (!cameraVideo.value) {
    console.warn("Camera video element not found!");
    return;
  }
  try {
    const videoConstraints = { video: currentDeviceId.value ? { deviceId: { exact: currentDeviceId.value } } : true };
    cameraSrc = await navigator.mediaDevices.getUserMedia(videoConstraints);
    cameraVideo.value.srcObject = cameraSrc;
    void cameraVideo.value.play();
    cameraActive.value = true;
  } catch (e) {
    console.warn(e);
    if (e instanceof DOMException) {
      if (e.name === "NotAllowedError") {
        alertHandler.addAlert({ title: "Camera Access Denied", content: "Please allow camera access to take photos.", variant: "warning" });
      } else if (e.name === "NotFoundError") {
        alertHandler.addAlert({ title: "Camera Not Found", content: "No camera found on this device.", variant: "warning" });
      }
    }
  }
};

const videoDeviceList = ref<MediaDeviceInfo[]>([]);

const switchCamera = async (deviceId: string) => {
  if (!cameraActive.value) return;

  try {
    // Start the new camera stream
    if (deviceId) {
      currentDeviceId.value = deviceId;
      await startCamera();
    }
  } catch (error) {
    console.error("Error switching cameras: ", error);
  }
};

const resetCamera = () => {
  if (cameraSrc) {
    for (const track of cameraSrc.getTracks()) track.stop();
    cameraSrc = null;
  }
  cameraActive.value = false;
};

watch(
  () => props.active,
  async (active) => {
    if (active) {
      await nextTick();
      await startCamera();

      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === "videoinput");
      videoDeviceList.value = videoDevices;
    } else {
      await nextTick();
      resetCamera();
    }
  },
  { immediate: true }
);

const takePicture = async () => {
  if (!cameraVideo.value || !canvas.value) {
    console.warn("Camera video or canvas element not found!");
    return;
  }
  const context = canvas.value.getContext("2d");
  if (!context) {
    console.warn("Canvas context not found!");
    return;
  }
  const { width, height } = cameraVideo.value.getBoundingClientRect();
  // set canvas width and height to match video
  canvas.value.width = width;
  canvas.value.height = height;
  context.drawImage(cameraVideo.value, 0, 0, width, height);
  const dataUrl = canvas.value.toDataURL("image/jpeg");
  const blob = await (await fetch(dataUrl)).blob();
  const currentDateTime = dayjs().format("-YYYY-MM-DD HH-mm-ss");
  const file = new File([blob], "photo" + currentDateTime + ".jpg", { type: "image/jpeg" });
  emit("photo", file);
  // make sure camera light is turned off
  cameraVideo.value.srcObject = null;
  cameraActive.value = false;
  emit("update:active", false);
};

const cancel = () => {
  emit("update:active", false);
};

onBeforeUnmount(() => {
  resetCamera();
});
</script>

<style scoped lang="scss">
.camera-feed {
  margin: 0.5rem 0 0;
  max-height: 350px;
}
.camera-buttons {
  z-index: 1;
  background-color: var(--k-input-background);
  width: 100%;
  position: absolute;
  bottom: 0;
}

.camera-container {
  overflow: hidden;
  height: 400px;
  border-radius: 6px;
  text-align: center;
  background-color: var(--k-input-background);
  position: relative;
}

.camera-spinner {
  position: absolute;
  top: 40%;
  left: 45%;
}

.v-enter-active,
.v-leave-active,
.v-enter-active .camera-buttons,
.v-leave-active .camera-feed {
  transition: all 0.2s ease-out;
}

.v-leave-to .camera-buttons,
.v-leave-to .camera-feed {
  opacity: 0;
}

.v-enter-from,
.v-leave-to {
  height: 0;
}
</style>
