<template>
  <ul v-if="files.length" class="file-list pt-1 mb-2">
    <li v-for="file of files" :key="file.id" :file tag="li" :title="file.description">
      <div class="file-item-content">
        <k-file-icon :extension="file.extension" class="float-start me-2" />
        <span>{{ file.description }}</span>
      </div>
      <k-dropdown variant="transparent" icon="ellipsis" no-caret class="mt-n1 p-0">
        <k-dropdown-item label="View" icon="eye" @click="viewFile(file)" />
        <k-dropdown-item label="Download" icon="download" @click="downloadFile(file)" />
      </k-dropdown>
    </li>
  </ul>
  <p v-else class="use-selectable text-secondary">No files uploaded</p>
</template>

<script setup lang="ts">
import { computed } from "vue";

import KDropdown from "@ui/dropdown/KDropdown.vue";
import KDropdownItem from "@ui/dropdown/KDropdownItem.vue";
import KFileIcon from "@ui/files/KFileIcon.vue";

import type { FileUpload } from "../inputs/file/FileUpload";

const props = defineProps<{
  /** Files to show */
  modelValue?: FileUpload[];
}>();

const viewFile = (file: FileUpload) => {
  if (file.file) {
    window.open(window.URL.createObjectURL(file.file), "_blank");
  } else if (file.id) {
    window.open(file.url, "_blank");
  }
};

const downloadFile = (file: FileUpload) => {
  if (file.id) {
    // Pass optional download parameter to force download rather than a provider redirect link
    window.open(`/api/v1/files/${file.id}?download=true`, "_blank");
  }
};

const files = computed(() => props.modelValue?.filter((f) => f.status !== "TO_DELETE" && f.status !== "DELETED") ?? []);
</script>

<style scoped>
.file-list {
  margin: 0.25rem 0 0;
  padding: 0;
  list-style: none;
}

.file-list > li {
  display: inline-block;
  width: calc(33.3% - 0.3rem);
  margin-right: 0.4rem;
  position: relative;
}

.file-list .file-item-content {
  background: var(--k-background);
  border-radius: 6px;
  border: 1px solid var(--k-border);
  overflow: hidden;
  position: relative;
  height: 2rem;
  width: 100%;
  padding: 0.25rem;
}

.file-list > li:nth-child(3n) {
  margin-right: 0rem;
}

.file-list .file-item-content span {
  display: block;
  height: 100%;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.file-list .file-item-content i {
  line-height: 22px;
}

.file-list :deep(.progress-indicator) {
  height: 2px;
  position: absolute;
  bottom: 0;
  border-radius: 1px;
  transition: all ease-out 0.1s;
  left: 0;
  background: var(--k-color-primary);
  background: linear-gradient(270deg, var(--k-color-primary), transparent);
}

.file-list :deep(.progress-indicator.bg-danger) {
  background: var(--k-color-danger);
}

.form-control .file-list :deep(.progress-indicator.bg-success) {
  background: var(--k-color-success);
}

.file-list > li > :deep(.dropdown) {
  float: right;
  position: absolute;
  right: 2px;
  top: 6px;
}

.file-list > li .dropdown > :deep(button) {
  background: var(--k-background);
  opacity: 0;
  padding: 0.1rem 0.25rem;
  height: 28px;
  line-height: 28px;
  transition: opacity 0.1s ease-in-out;
}

.file-list > li .dropdown > :deep(button:active) {
  background: var(--k-border);
}

.file-list > li:hover .dropdown > :deep(button),
.file-list > li .dropdown > :deep(button:focus) {
  opacity: 1;
}
</style>
