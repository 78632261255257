import dayjs from "dayjs";

import { KField } from "../KField";
import { refine } from "../../helpers/NullHelpers";
import { BulkData } from "../../expressions/data";
import { datetimeDisplayFormats, type DatetimePrecision } from "../basic/DatetimeField";
import { formatList } from "../../helpers/strings/List";

import type { ExpressionField } from "../../expressions/accessors";
import type { Dayjs } from "dayjs";
import type { KRecord } from "../../data/Record";
import type { KFieldSetup, Sortable } from "../KField";

export abstract class SubField<V, PV> extends KField<V> {
  readonly type = "subfield";

  protected get parent(): KField<PV> {
    return this.getParent();
  }

  constructor(
    private getParent: () => KField<PV>,
    subId: string,
    setup: KFieldSetup | string
  ) {
    super(setup);
    this.key = `${this.parent.key}.${subId}`;
    this.id = `${this.parent.id}.${subId}`;
  }

  storeValue(): void {
    throw new Error("Method not implemented.");
  }

  restoreValue(): void {
    throw new Error("Method not implemented.");
  }
}

export class KeyField<const K extends string> extends SubField<string, Partial<Record<K, string>>> implements ExpressionField<"string"> {
  readonly expressionType = "string";

  readonly idFor?: string;

  constructor(
    public readonly objectKey: K,
    getParent: () => KField<Partial<Record<K, string>>>,
    setup?: string | (KFieldSetup & Pick<ExpressionField, "idFor">)
  ) {
    const parent = getParent();
    if (typeof setup === "string") {
      setup = `${parent.label} - ${setup}`;
    }
    super(getParent, objectKey, setup ?? `${parent.label} - ${objectKey}`);
    if (typeof setup === "object") {
      this.idFor = setup.idFor;
    }
  }

  getValue(record: KRecord): string | undefined {
    return this.parent.getValue(record)?.[this.objectKey];
  }

  getSortableValue(r: KRecord): Sortable {
    return this.getValue(r);
  }

  getExpressionValue(r: KRecord): string | undefined {
    return this.getValue(r);
  }

  getDisplayValue(record: KRecord): string {
    return this.getValue(record) ?? "";
  }

  validateType(value: unknown): value is string {
    return typeof value === "string";
  }
}

export class KeysField<const K extends string> extends SubField<string[], Partial<Record<K, string>[]>> implements ExpressionField<"data"> {
  readonly expressionType = "data";

  readonly idFor?: string;

  constructor(
    public readonly objectKey: K,
    getParent: () => KField<Partial<Record<K, string>[]>>,
    setup?: string | (KFieldSetup & Pick<ExpressionField, "idFor">)
  ) {
    const parent = getParent();
    if (typeof setup === "string") {
      setup = `${parent.label} - ${setup}`;
    }
    super(getParent, objectKey, setup ?? `${parent.label} - ${objectKey}`);
    if (typeof setup === "object") {
      this.idFor = setup.idFor;
    }
  }

  getValue(record: KRecord): string[] | undefined {
    const value = this.parent.getValue(record);
    return value && refine(value, (v) => v?.[this.objectKey]);
  }

  getExpressionValue(r: KRecord): BulkData<string> | undefined {
    return new BulkData(this.getValue(r) ?? []);
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    return value ? formatList(value) : "";
  }

  getSortableValue(record: KRecord): Sortable {
    return this.getValue(record)?.length;
  }

  validateType(value: unknown): value is string[] {
    return Array.isArray(value) && value.every((v) => typeof v === "string");
  }
}

export class NumberKeyField<const K extends string> extends SubField<number, Partial<Record<K, number>>> implements ExpressionField<"number"> {
  readonly expressionType = "number";

  constructor(
    public readonly objectKey: K,
    getParent: () => KField<Partial<Record<K, number>>>,
    setup?: string | KFieldSetup
  ) {
    const parent = getParent();
    if (typeof setup === "string") {
      setup = `${parent.label} - ${setup}`;
    }
    super(getParent, objectKey, setup ?? `${parent.label} - ${objectKey}`);
  }

  getValue(record: KRecord): number | undefined {
    return this.parent.getValue(record)?.[this.objectKey];
  }

  getExpressionValue(r: KRecord): number | undefined {
    return this.getValue(r);
  }

  getDisplayValue(record: KRecord): string {
    return this.getValue(record)?.toString() ?? "";
  }

  getSortableValue(record: KRecord): Sortable {
    return this.getValue(record);
  }

  validateType(value: unknown): value is number {
    return typeof value === "number";
  }
}

export class DatetimeKeyField<const K extends string> extends SubField<Dayjs, Partial<Record<K, Dayjs>>> implements ExpressionField<"datetime"> {
  readonly expressionType = "datetime";

  readonly precision: DatetimePrecision;

  constructor(
    public readonly objectKey: K,
    getParent: () => KField<Partial<Record<K, Dayjs>>> & { precision: DatetimePrecision },
    setup?: string | KFieldSetup
  ) {
    const parent = getParent();
    if (typeof setup === "string") {
      setup = `${parent.label} - ${setup}`;
    }
    super(getParent, objectKey, setup ?? `${parent.label} - ${objectKey}`);
    this.precision = parent.precision;
  }

  getValue(record: KRecord): Dayjs | undefined {
    return this.parent.getValue(record)?.[this.objectKey];
  }

  getExpressionValue(r: KRecord) {
    return this.getValue(r);
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    return value?.format(datetimeDisplayFormats[this.precision]) ?? "";
  }

  getSortableValue(record: KRecord): Sortable {
    return this.getValue(record)?.toDate();
  }

  validateType(value: unknown): value is Dayjs {
    return dayjs.isDayjs(value);
  }
}
