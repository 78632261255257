import { ref } from "vue";

import { v4 as uuidv4 } from "uuid";

import { fixGeneratedCollection, getKFieldFromGeneratedField } from "./AICollectionGenerationUtils";
import { MockCollectionGenerationAPI } from "./AICollectionGenerationAPI.mock";

import type { Collection } from "@data/data/Collection";
import type { KField } from "@data/fields/KField";
import { OptionField } from "@data/fields/basic/OptionFields";
import { UnknownField } from "@data/fields/utility/UnknownField";
import { createField } from "@data/constants/fieldTypes";
import { untruncateJson } from "@data/helpers/serialisation/untruncateJson";

import { CollectionGenerationAPI } from "@/api/collectionGeneration";

export class CollectionGenerator {
  /** Unique ID representing the current request to generate collection names */
  private generationId = ref(uuidv4());

  /** Whether or not the collection names are currently being generated */
  public isGenerating = ref(false);

  private generateMutation = CollectionGenerationAPI.generateCollections();

  private generationStream = CollectionGenerationAPI.onCollectionStreamUpdated(this.generationId);

  private cancelMutation = CollectionGenerationAPI.cancelCollectionGeneration();

  private existingCollections: Collection[] = [];

  private proposedCollections: Collection[] = [];

  private allCollections: Collection[] = [];

  constructor(existingCollections?: Collection[], useMock?: boolean) {
    if (useMock) {
      this.generateMutation = MockCollectionGenerationAPI.generateCollections();
      this.generationStream = MockCollectionGenerationAPI.onCollectionStreamUpdated(this.generationId);
    }

    if (existingCollections) {
      this.existingCollections = existingCollections;
    }

    // Setup the stream event handler
    this.generationStream.onResult((result) => this.onStreamUpdate(result.data?.collectionStreamUpdate));
  }

  public proposedFields = ref<{ [key: string]: KField[] }>({});

  /**
   * Start fetching collection names for the given search term from the API.
   *
   * @param prompt Search prompt to use for fetching collection names.
   */
  public async generateCollectionFields(prompt: string, proposedCollections: Collection[], attemptsLeft = 3) {
    if (this.isGenerating.value) {
      await this.cancelMutation.mutate({ generationId: this.generationId.value });
    }

    const proposedCollectionNames = proposedCollections.map((collection) => collection.plural);
    if (proposedCollectionNames.length === 0) return;

    const currentGenerationId = uuidv4();
    this.generationId.value = currentGenerationId;
    this.isGenerating.value = true;
    this.rawStreamValue = "";
    this.proposedFields.value = {};
    this.isStopped = false;

    this.proposedCollections = proposedCollections;
    this.allCollections = [...this.existingCollections, ...proposedCollections];

    await this.generateMutation.mutate({ prompt, generationId: currentGenerationId, proposedCollectionNames });

    if (this.generationId.value === currentGenerationId) {
      this.isGenerating.value = false;

      const fieldCount = Object.values(this.proposedFields.value).reduce((sum, fields) => sum + fields.length, 0);
      // If no fields were generated, try again up to 3 times
      // (eslint is being stupid since isStopped could change during the await)
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (fieldCount === 0 && attemptsLeft > 1 && !this.isStopped) {
        await this.generateCollectionFields(prompt, proposedCollections, attemptsLeft - 1);
        return;
      }
    }

    this.postGenerationCleanup();
  }

  /** Raw collection names result from the API, may be incomplete */
  private rawStreamValue = "";

  /**
   * Handle additional text streaming in from the API, process and update the collection names if possible.
   *
   * @param result New additions to the collection names result.
   */
  public onStreamUpdate(result?: string) {
    if (!result) return;

    this.rawStreamValue += result;

    const generatedCollections = untruncateJson(this.rawStreamValue);

    if (generatedCollections === null) return;
    if (!Array.isArray(generatedCollections)) return;

    const collectionNames = this.allCollections.map(({ plural }) => plural);

    const update = this.proposedFields.value;

    for (const generatedCollection of generatedCollections) {
      if (typeof generatedCollection !== "object" || Array.isArray(generatedCollection) || !generatedCollection || !generatedCollection.name) {
        continue;
      }

      const matchingCollection = this.proposedCollections.find((collection) => collection.plural === generatedCollection.name);
      if (matchingCollection) {
        const fixedGeneratedCollection = fixGeneratedCollection(generatedCollection, collectionNames);
        const standardFields: KField[] = [];
        for (const generatedField of fixedGeneratedCollection.fields) {
          standardFields.push(
            getKFieldFromGeneratedField(
              generatedField,
              standardFields.map(({ key }) => key),
              this.allCollections
            )
          );
        }

        update[matchingCollection.id] = standardFields;
      }
    }

    this.proposedFields.value = update;
  }

  public postGenerationCleanup() {
    const proposedFields = this.proposedFields.value;

    for (const fields of Object.values(proposedFields)) {
      // identify bad fields
      const fieldsToDelete = [];
      for (const field of fields) {
        if (field.label === "" || field instanceof UnknownField) {
          fieldsToDelete.push(field);
        } else if (field instanceof OptionField) {
          let { options } = field;
          // identify and delete empty options
          options = options.filter((option) => option.label !== "");
          // turn empty option fields into string fields
          if (options.length === 0) {
            fields[fields.indexOf(field)] = createField(
              "string",
              field.label,
              fields.filter(({ id }) => id !== field.id).map(({ key }) => key),
              { description: field.description }
            ) as KField;
          }
        }
      }

      // delete bad fields
      for (const field of fieldsToDelete) {
        fields.splice(fields.indexOf(field), 1);
      }
    }

    this.proposedFields.value = proposedFields;
  }

  private isStopped = false;

  public stopGenerating() {
    if (this.isGenerating.value) {
      this.isStopped = true;
      void this.cancelMutation.mutate({ generationId: this.generationId.value });
    }
  }

  public stop() {
    if (this.isGenerating.value) this.stopGenerating();

    this.isGenerating.value = false;
    if (this.generationStream.stop as (() => void) | undefined) {
      this.generationStream.stop();
    }
  }
}
