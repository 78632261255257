<template>
  <div class="form-control-container">
    <fieldset>
      <legend v-if="!isPreview"><k-label :label /></legend>
      <k-button v-if="!isPreview" variant="link" label="Clear" class="clear-button float-end" @click="input = undefined" />
      <div class="d-flex flex-md-row justify-content-between align-items-end radio-container">
        <label v-if="startAndEndLabels" :for="`${uuid}-${startAndEndLabels.start.value}`">{{ startAndEndLabels.start.label }}</label>
        <div v-for="o in options" :key="o.value" class="d-flex flex-column align-items-center">
          <label :for="`${uuid}-${o.value}`">{{ min && max && o.label && o.value > min && o.value < max ? o.label : o.value }}</label>
          <input
            :id="`${uuid}-${o.value}`"
            v-model="input"
            type="radio"
            :value="o.value"
            v-bind="commonBindings(config, label)"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @change="$emit('change', $event)" />
        </div>
        <label v-if="startAndEndLabels" :for="`${uuid}-${startAndEndLabels.end.value}`">{{ startAndEndLabels.end.label }}</label>
      </div>
    </fieldset>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { v4 } from "uuid";

import { commonBindings, useInputConfig } from "../inputConfig";

import KButton from "@ui/button/KButton.vue";
import KLabel from "@ui/label/KLabel.vue";

const props = defineProps<{
  /** Min is undefined in config preview */
  min?: number;
  /** Max is undefined in config preview */
  max?: number;
  /** Step for range slider */
  step?: number;
  /** Min max labels */
  labels?: { value: number; label: string }[];
  /** Label for the input */
  label?: string;
  /** Whether it is being used in field editor preview */
  isPreview?: boolean;
}>();
defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
}>();
const input = defineModel<number>();

const config = useInputConfig();

const uuid = v4();

const options = computed<{ value: number; label?: string }[]>(() => {
  const ticks: { value: number; label?: string }[] = [];
  const labelsMap = new Map(props.labels?.map((l) => [l.value, l.label] as const) ?? []);
  if (props.min === undefined || props.max === undefined) {
    return ticks;
  }
  for (let i = props.min; i <= props.max; i += props.step ?? 1) {
    ticks.push({ value: i, label: labelsMap.get(i) });
  }
  return ticks;
});

const startAndEndLabels = computed(() => {
  const minOption = options.value.find((o) => o.value === props.min);
  const maxOption = options.value.find((o) => o.value === props.max);
  if (minOption?.label && maxOption?.label) {
    return { start: minOption, end: maxOption };
  }
  return undefined;
});
</script>

<style scoped lang="scss">
legend {
  font-size: 1em;
  width: fit-content;
  margin-bottom: 0;
}
.clear-button {
  height: 1rem;
  text-decoration: none;
  margin-top: -22px;
  padding: 0;
  :deep(.btn-text) {
    font-size: 0.75rem;
  }
}
.radio-container {
  background-color: var(--k-input-background);
  border-radius: 0.35rem;
  padding: 0.25rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
}
</style>
