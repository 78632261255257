<template>
  <button
    type="button"
    :class="classes"
    :disabled="disabled || loading"
    :data-bs-toggle="openModal ? 'modal' : undefined"
    :aria-disabled="disabled || loading"
    :aria-pressed="pressed"
    :title="title ? title : label"
    :data-bs-target="openModal"
    @click="onClick">
    <slot>
      <span class="btn-content">
        <k-spinner v-if="loading" size="sm" class="me-1" variant="light" show-instantly />
        <k-icon v-else-if="!!icon && !iconRight" :icon />
        <span v-if="label" class="btn-text">{{ label }}</span>
        <k-icon v-if="!!icon && iconRight" :icon />
        <i v-if="showCaret" class="ms-1 fa fa-caret-down btn-caret" />
      </span>
    </slot>
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";

import KIcon from "@ui/icon/KIcon.vue";
import KSpinner from "@ui/progress/KSpinner.vue";

import type { UIColourVariantWithOutlines } from "@data/types/ColourVariant";

const props = withDefaults(
  defineProps<{
    /** Displayed label for button */
    label?: string;
    /** Variant colour of button */
    variant?: UIColourVariantWithOutlines | "link" | "dashed";
    /** Size of button */
    /** Size of the input element */
    size?: "sm" | "md" | "lg";
    /** Boolean determining whether button is active */
    active?: boolean;
    /** Boolean determining whether button can be clicked */
    disabled?: boolean;
    /** Boolean indicating whether button should be shown as pressed */
    pressed?: boolean;
    /** Boolean indicating whether a loading spinner should be shown (replaces icon if true) */
    loading?: boolean;
    /** Icon to display in the button */
    icon?: string;
    /** Whether the icon should be displayed on the right of the text instead */
    iconRight?: boolean;
    /** Tooltip to display in the button (if not set, defaults to label) */
    title?: string;
    /** HTML element ID of modal to open on click (optional) */
    openModal?: string;
    /** Custom CSS class to apply to */
    cssClass?: string;
    /** Whether to show the caret icon */
    showCaret?: boolean;
  }>(),
  {
    label: undefined,
    variant: "secondary",
    size: "sm",
    title: undefined,
    icon: undefined,
    openModal: undefined,
    cssClass: undefined
  }
);

const emit = defineEmits<{
  (event: "click", evt: MouseEvent): void;
}>();

const classes = computed(() => {
  const res = {
    btn: true,
    [`btn-${props.size}`]: true,
    [`btn-${props.variant}`]: true,
    active: props.pressed || props.active,
    disabled: props.disabled || props.loading,
    "ps-2": (!!props.icon && !props.iconRight) || props.loading,
    "pe-2": (!!props.icon && !!props.iconRight) || props.loading
  };

  if (props.cssClass) {
    res[props.cssClass] = true;
  }

  return res;
});

const onClick = (evt: MouseEvent) => {
  if (!props.disabled && !props.openModal) {
    emit("click", evt);
  } else {
    evt.preventDefault();
  }
};
</script>

<style scoped>
.btn-content {
  display: flex;
  align-items: center;
  position: relative;
  gap: 4px;
}

.btn-content :deep(.spinner-sm > svg) {
  height: 18px;
  width: 18px;
}

.btn-content {
  /** Fixes issue where context menu moves depending on where you click in button */
  pointer-events: none;
}

.btn-text {
  font-size: 0.8rem;
}

.btn-caret {
  font-size: 0.7rem;
}
</style>
