import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { refine } from "../helpers/NullHelpers";

import { AllExpression, AnyExpression } from "./data/conditions";
import { FilterExpression, IntersectionExpression, UnionExpression } from "./data/manipulation";
import { SumDataExpression, AverageExpression, CumulativeSumExpression, CountExpression } from "./data/numerical";
import { checkAndSplit } from "./parsing/operators";
import { parseBoolean } from "./parsing/parseBoolean";
import { parseData } from "./parsing/parseData";
import { parseDatetime, registerDatetimeFunctions } from "./parsing/parseDatetime";
import { parseNumber } from "./parsing/parseNumber";
import { parseString } from "./parsing/parseString";
import { stringRegex } from "./parsing/regexes";
import { toTokens } from "./tokeniser";
import { ParsingError, type Expression, type SingleExpression } from "./expressions";
import { parseDuration, registerDurationFunctions } from "./parsing/parseDuration";
import { personLookupExpression, ternaryExpression } from "./generic";
import { NowExpression } from "./state";
import { DurationToMetricCast } from "./duration";

import type { KContext } from "./context";

dayjs.extend(utc);

const functionStartRegex = /^(\w+)\((.*)\)/;

export class ExpressionParser {
  private functions = new Map<string, (tokens: string[], context: KContext, type: Expression["type"]) => Expression>();

  registerFunction(name: string, expand: (tokens: string[], context: KContext, type: Expression["type"]) => Expression): void {
    name = name.toLowerCase();
    if (this.functions.has(name)) {
      throw new ParsingError(`Function ${name} already registered`);
    }
    this.functions.set(name, expand);
  }

  /** Clears all registered functions. Should only be used in unit tests. */
  clearFunctions(): void {
    this.functions.clear();
  }

  baseParse<E extends Expression>(
    expression: string | string[],
    type: E["type"],
    context: KContext,
    parse: {
      recurse: (sub: string, context: KContext) => E;
      single: (token: string, context: KContext) => E;
      multi: (tokens: string[], context: KContext) => E;
    }
  ): E {
    const tokens = typeof expression === "string" ? toTokens(expression) : expression;
    if (tokens.length === 0) {
      throw new ParsingError("Empty expression");
    }

    if (tokens.length === 1) {
      const token = tokens[0];
      // check for brackets
      if (token.startsWith("(")) {
        return parse.recurse(token.slice(1, -1), context);
      }
      // check for function calls
      const fnMatch = token.match(functionStartRegex);
      if (fnMatch) {
        const name = fnMatch[1].toLowerCase();
        const expander = this.functions.get(name);
        if (!expander) {
          throw new ParsingError(`Unknown function: ${name}`);
        }
        // split arguments
        const args = toTokens(fnMatch[2], true);
        const expanded = expander(args, context, type);
        if (expanded.type !== type) {
          if (type === "number" && expanded.type === "duration") {
            return new DurationToMetricCast(expanded) as unknown as E;
          }
          throw new ParsingError(`Invalid function of type ${expanded.type} in ${type} expression`);
        }
        return expander(args, context, type) as E;
      }
      return parse.single(token, context);
    }
    return parse.multi(tokens, context);
  }

  parseBoolean = parseBoolean;

  parseString = parseString;

  parseNumber = parseNumber;

  parseDatetime = parseDatetime;

  parseDuration = parseDuration;

  parseData = parseData;

  parseGenericExpression<E extends Expression>(expression: string, context: KContext, type: E["type"]): E {
    switch (type) {
      case "boolean":
        return this.parseBoolean(expression, context) as E;
      case "string":
        return this.parseString(expression, context) as E;
      case "number":
        return this.parseNumber(expression, context) as E;
      case "datetime":
        return this.parseDatetime(expression, context) as E;
      case "duration":
        return this.parseDuration(expression, context) as E;
      case "data":
        return this.parseData(expression, context) as E;
      default:
        throw new ParsingError(`Unknown expression type: ${type}`);
    }
  }

  /** Parse a non-data expression (for data elements) */
  parseSingleExpression(expression: string, context: KContext): SingleExpression {
    for (const parseFunction of ["parseNumber", "parseDatetime", "parseBoolean", "parseString"] as const) {
      try {
        const parsed = this[parseFunction](expression, context);
        return parsed;
      } catch (e) {
        if (!(e instanceof ParsingError)) {
          throw e;
        }
      }
    }
    throw new ParsingError(`Unknown expression: ${expression}`);
  }

  private searchExpression(expression: string, target: RegExp): string[] {
    // remove string constants from expression
    expression = expression.replace(stringRegex, "");
    const matches = expression.matchAll(target);
    return Array.from(matches).map((m) => m[1]);
  }

  /**
   * Split an expression by a single top level operator
   *
   * @example
   *   `splitByOperator("1 + 2 - 3 + (4 + 5)", "+")` => `["1", "2 - 3", "(4 + 5)"]`
   *
   * @param expression Expression to split
   * @param target Single operator to split by
   * @returns List of expression parts
   */
  splitByOperator(expression: string, target: string): string[] {
    const tokens = toTokens(expression);
    const split = checkAndSplit(tokens, [target]);
    if (!split) {
      return [expression];
    }
    return refine(split, (s) => (typeof s === "string" ? undefined : s.join(" ")));
  }

  // returns list of field ids that this expression depends on
  getDependencies(expression: string): Set<string> {
    const accessors = this.searchExpression(expression, /record\.(\S*)/g);
    return new Set(accessors);
  }
}

export const expressionParser = new ExpressionParser();

for (const [name, cls] of [
  ["any", AnyExpression],
  ["all", AllExpression],
  ["filter", FilterExpression]
] as const) {
  expressionParser.registerFunction(name, (tokens, context) => {
    if (tokens.length !== 2) {
      throw new ParsingError(`Invalid ${name} expression: must have exactly two arguments`);
    }
    const data = expressionParser.parseData(tokens[0], context);
    const condition = expressionParser.parseBoolean(tokens[1], context);
    return new cls(data, condition);
  });
}

for (const [name, cls] of [
  ["intersect", IntersectionExpression],
  ["union", UnionExpression]
] as const) {
  expressionParser.registerFunction(name, (tokens, context) => {
    if (tokens.length < 2) {
      throw new ParsingError(`Invalid ${name} expression: must have at least two arguments`);
    }
    const subexpressions = tokens.map((t) => expressionParser.parseData(t, context));
    return new cls(...subexpressions);
  });
}

for (const [name, cls] of [
  ["sum", SumDataExpression],
  ["average", AverageExpression],
  ["count", CountExpression],
  ["accumulate", CumulativeSumExpression]
] as const) {
  expressionParser.registerFunction(name, (tokens, context) => {
    if (tokens.length !== 1) {
      throw new ParsingError(`Invalid ${name} expression: must have exactly one argument`);
    }
    const data = expressionParser.parseData(tokens[0], context);
    return new cls(data);
  });
}

expressionParser.registerFunction("if", (tokens, context, type) => {
  if (tokens.length !== 3) {
    throw new ParsingError(`Invalid if expression: must have exactly three arguments`);
  }
  return ternaryExpression(
    expressionParser.parseBoolean(tokens[0], context),
    expressionParser.parseGenericExpression(tokens[1], context, type),
    expressionParser.parseGenericExpression(tokens[2], context, type)
  );
});

expressionParser.registerFunction("now", (tokens) => {
  if (tokens.length === 0) {
    return new NowExpression();
  } else {
    throw new ParsingError(`Invalid now expression: must have no arguments`);
  }
});

expressionParser.registerFunction("personLookup", (tokens, context, type) => {
  if (tokens.length !== 2) {
    throw new ParsingError(`Invalid personLookup expression: must have exactly two arguments`);
  }
  return personLookupExpression(expressionParser.parseString(tokens[0], context), expressionParser.parseGenericExpression(tokens[1], context, type));
});
registerDatetimeFunctions(expressionParser);
registerDurationFunctions(expressionParser);
