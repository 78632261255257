<template>
  <div v-if="billingAlert" :class="`billing-alert d-flex ${billingAlert.variant}`">
    <div class="float-start pe-1 billing-icon"><i :class="`fa ${billingAlert.icon} fa-lg  text-${billingAlert.variant}`"></i></div>
    <div class="ps-1">
      <h6 v-if="billingAlert.title" class="mb-1">{{ billingAlert.title }}</h6>
      <p v-if="billingAlert.content" class="mb-0 content" :class="{ 'text-secondary': !!billingAlert.title }">{{ billingAlert.content }}</p>
      <a v-if="isAdmin" href="#" variant="success" class="mt-2 d-inline-block content" @click="updatePayment">{{ buttonLabel }}</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import type { UIColourVariant } from "@data/types/ColourVariant";

import { useIsAdmin, useSubscriptionStatus } from "@/services/auth";

dayjs.extend(relativeTime);

const { subscriptionExpiry, paymentStatus, status, planCode } = useSubscriptionStatus();
const isAdmin = useIsAdmin();

const buttonLabel = computed(() => {
  if (paymentStatus.value === "cancelled") {
    return "Restart";
  }
  return "Upgrade";
});

const state = computed(() => {
  switch (status.value) {
    case "active": {
      if (paymentStatus.value === "cancelled") {
        return "cancelled";
      }
      const trialEndDate = paymentStatus.value === "trial" ? subscriptionExpiry.value : undefined;
      const currrentPlanLabel = planCode.value;
      if (currrentPlanLabel && trialEndDate?.isAfter(dayjs())) {
        return "active_trial";
      }
      return "none";
    }
    case "locked-down": {
      if (!subscriptionExpiry.value) {
        return "none";
      }
      return paymentStatus.value === "trial" ? "trial_expired" : "subscription_expired";
    }
    default: {
      return "none";
    }
  }
});

const billingAlert = computed<{ title?: string; content: string; variant: UIColourVariant; icon: string } | undefined>(() => {
  let dataVisibilityLabel = subscriptionExpiry.value?.add(7, "day").fromNow(true);
  if (dataVisibilityLabel?.startsWith("a")) {
    // don't say next-a day
    dataVisibilityLabel = dataVisibilityLabel.slice(2);
  }

  const expiryFromNowInDays = subscriptionExpiry.value?.diff(dayjs(), "day");
  const expiryTimeLabel = expiryFromNowInDays === 0 ? "today" : `in ${expiryFromNowInDays} day${expiryFromNowInDays === 1 ? "" : "s"}`;

  switch (state.value) {
    case "cancelled": {
      return {
        title: `Subscription cancelled`,
        content: `Your subscription ends ${subscriptionExpiry.value ? expiryTimeLabel : "soon"}`,
        variant: "warning",
        icon: "fa-exclamation-triangle"
      };
    }
    case "active_trial": {
      if (!subscriptionExpiry.value) return undefined;
      return {
        content: `Your trial ends ${expiryTimeLabel}`,
        variant: subscriptionExpiry.value.diff(dayjs(), "day") < 7 ? "warning" : "info",
        icon: subscriptionExpiry.value.diff(dayjs(), "day") < 7 ? "fa-exclamation-triangle" : "fa-info-circle"
      };
    }
    case "trial_expired": {
      if (!dataVisibilityLabel) return undefined;
      return {
        title: `${paymentStatus.value === "trial" ? "Trial" : "Subscription"} expired`,
        content: `You can view your data for the next ${dataVisibilityLabel}`,
        variant: "danger",
        icon: "fa-exclamation-circle"
      };
    }
    case "subscription_expired": {
      return {
        title: "Subscription expired",
        content: `You can view your data for the next ${dataVisibilityLabel}`,
        variant: "danger",
        icon: "fa-exclamation-circle"
      };
    }
  }
  return undefined;
});

const router = useRouter();

const updatePayment = async () => {
  await router.push("/settings?p=billing");
};
</script>

<style scoped>
.billing-alert {
  min-width: 208px;
  background-color: var(--k-background);
  border-radius: 6px;
  padding: 10px;
}

.billing-alert.info {
  border: 1px solid var(--k-color-info);
}
.billing-alert.danger {
  border: 1px solid var(--k-color-danger);
}
.billing-alert.warning {
  border: 1px solid var(--k-color-warning);
}

.billing-alert .content {
  font-size: 0.8rem;
  line-height: 1.2;
}

.billing-icon {
  margin-top: -2px;
}
</style>
