<template>
  <div v-if="mode != 'EDITING'" class="navbar-footer px-2 pt-2 pb-1">
    <k-button
      v-if="mode != 'COMPACT' && addButtonLabel"
      variant="transparent"
      class="w-100 mb-2 text-start add-button navbar-hide-compact"
      icon="add"
      :label="addButtonLabel"
      @click="emit('add')" />
    <slot />

    <div class="border-top pb-1"></div>
    <k-dropdown dropup class="footer-btn mx-n1" variant="transparent" no-caret hide-label label="Options">
      <template #button>
        <i v-if="mode != 'COMPACT'" class="fa fa-chevron-up float-end mt-2 pt-1"></i>
        <div class="d-flex me-4">
          <k-people-badge v-if="structure.user.name" :badge="{ image: structure.user.image, fullName: structure.user.name }" />
          <div v-if="mode != 'COMPACT'" class="text-start ps-2 user-details navbar-hide-compact">
            <span class="user-name">{{ structure.user.name }}</span>
            <br />
            <span class="workspace-name">{{ structure.workspace.name }}</span>
          </div>
        </div>
      </template>
      <k-dropdown-header :label="structure.user.name ? structure.user.name : ''" />
      <k-dropdown-divider />
      <k-dropdown-item v-if="canCustomise" label="Customise sidebar" role="button" @click="toggleEditing" />
      <k-dropdown-item :label="isDark ? `Theme: Dark` : `Theme: Light`" role="button" @click="toggleDark()" />
      <slot name="menu"></slot>
    </k-dropdown>
  </div>
</template>

<script setup lang="ts">
import { useToggle } from "@vueuse/core";

import KPeopleBadge from "@ui/badge/KPeopleBadge.vue";
import KButton from "@ui/button/KButton.vue";
import KDropdown from "@ui/dropdown/KDropdown.vue";
import KDropdownDivider from "@ui/dropdown/KDropdownDivider.vue";
import KDropdownHeader from "@ui/dropdown/KDropdownHeader.vue";
import KDropdownItem from "@ui/dropdown/KDropdownItem.vue";

import { useTheme } from "./theme";

import type { NavbarState } from "./NavbarState";
import type { NavbarStructure } from "./NavbarStructure";

defineProps<{
  /** Navbar structure */
  structure: NavbarStructure;
  /** Label for the add button */
  addButtonLabel?: string;
  /** Whether the navbar can be customised */
  canCustomise?: boolean;
}>();

const emit = defineEmits<{
  (event: "add"): void;
}>();

const mode = defineModel<NavbarState>("mode", {
  required: true
});

const toggleEditing = () => {
  mode.value = mode.value === "EDITING" ? "DEFAULT" : "EDITING";
};

const { isDark } = useTheme();
const toggleDark = useToggle(isDark);
</script>

<style scoped>
.navbar-footer {
  width: 100%;
  background: var(--k-navbar-background);
}

.user-name {
  color: var(--k-color);
  font-weight: 600;
  line-height: 14px;
  padding-top: 2px;
}

.workspace-name {
  font-size: 11px;
  color: var(--k-color-secondary);
}

:deep(.footer-btn .dropdown-toggle) {
  width: 100%;
  height: 44px;
  position: relative;
  padding: 0 10px 0 5px;
}

:deep(.footer-btn .person-badge) {
  min-width: 32px;
}

.user-details {
  width: calc(100% - 32px);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 2px;
}
.add-button {
  color: var(--k-color-link);
}
</style>
