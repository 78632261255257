<template>
  <ul class="item-list mt-2 mb-3">
    <draggable
      v-if="reorderable"
      :model-value="items"
      item-key="id"
      :component-data="{ tag: 'transition-group', style: 'display: contents' }"
      handle=".row-handle"
      :animation="100"
      @end="stopReorder">
      <template #item="{ element }">
        <li
          :key="element.id"
          v-bind="listBinds(element)"
          @click="onClick($event, element)"
          @click.right="onRightClick($event, element)"
          @keydown.enter="onClick($event, element)">
          <div v-if="reorderable" class="row-handle text-left ms-n1">
            <i class="fa-regular fa-grip-dots-vertical text-secondary"></i>
          </div>
          <slot :item="element">{{ element }}</slot>
          <k-button
            v-if="hasContextItems(contextMenuItems, element, disableContextMenuEvaluator)"
            v-bind="buttonBinds"
            @click="onRightClick($event, element, true)"
            @keydown.enter="onRightClick($event, element, true)">
            <i class="far fa-ellipsis"></i>
          </k-button>
        </li>
      </template>
    </draggable>
    <template v-else>
      <li
        v-for="(item, i) of items"
        :key="i"
        v-bind="listBinds(item)"
        @click="onClick($event, item)"
        @click.right="onRightClick($event, item)"
        @keydown.enter="onClick($event, item)">
        <slot :item>{{ item }}</slot>
        <k-button
          v-if="hasContextItems(contextMenuItems, item, disableContextMenuEvaluator)"
          v-bind="buttonBinds"
          @click="onRightClick($event, item, true)"
          @keydown.enter="onRightClick($event, item, true)">
          <i class="far fa-ellipsis"></i>
        </k-button>
      </li>
    </template>
  </ul>

  <k-context-menu v-if="items?.some((item) => hasContextItems(contextMenuItems, item))" ref="contextMenu" :items="contextMenuItems!" />
</template>

<script setup lang="ts" generic="T">
import { ref } from "vue";

import draggable from "vuedraggable";

import KButton from "@ui/button/KButton.vue";
import { hasContextItems, type ContextMenuItem } from "@ui/context-menu/ContextMenuItem";
import KContextMenu from "@ui/context-menu/KContextMenu.vue";
import type { KContextMenuElement } from "@ui/context-menu/ContextMenuElement";

type SortInfo = {
  oldIndex: number;
  newIndex: number;
  item?: HTMLElement;
  element?: T;
};

const props = withDefaults(
  defineProps<{
    /** Array of items to show in the item list */
    items?: T[];
    /** Context menu items to show when right clicking on a row */
    contextMenuItems?: ContextMenuItem<T>[];
    /** Boolean indicating if hover and active mouse effects should be used */
    clickable?: boolean;
    /** Whether items are reorderable */
    reorderable?: boolean;
    /** Function which returns true if the context menu should be disabled (for a given item) */
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    disableContextMenuEvaluator?: (record: T | undefined) => boolean;
    /** Active item */
    activeItem?: T;
  }>(),
  {
    contextMenuItems: undefined,
    items: undefined,
    disableContextMenuEvaluator: () => false,
    activeItem: undefined
  }
);

const emit = defineEmits<{
  (e: "click", item: T): void;
  (e: "reordered", info: SortInfo): void;
}>();

const onClick = (evt: MouseEvent | KeyboardEvent, item: T) => {
  emit("click", item);
};

const contextMenu = ref<KContextMenuElement<T>>();

const onRightClick = (evt: MouseEvent | KeyboardEvent, item: T, buttonTrigger?: boolean) => {
  evt.preventDefault();
  evt.stopPropagation();
  if (hasContextItems(props.contextMenuItems, item, props.disableContextMenuEvaluator)) {
    contextMenu.value?.show(evt as MouseEvent, item, buttonTrigger);
  } else {
    contextMenu.value?.dismissMenu();
  }
};

const stopReorder = (info: SortInfo) => {
  emit("reordered", info);
};

const listBinds = (item: T) => ({
  role: props.clickable ? "button" : "listitem",
  tabIndex: -1,
  class: {
    clickable: !!props.clickable,
    "has-context-items": hasContextItems(props.contextMenuItems, item, props.disableContextMenuEvaluator),
    active: props.activeItem === item
  }
});

const buttonBinds = {
  variant: "transparent" as const,
  class: "item-list-options px-2",
  title: "Actions"
};
</script>

<style scoped lang="scss">
.item-list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding: 0.35rem 0.5rem;
    list-style: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 6px;
    position: relative;
    cursor: default;
    &.has-context-items {
      cursor: pointer;
    }
    &.has-context-items:hover {
      background: var(--k-navbar-item-hover-background);
    }
    &::after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      border-bottom: 1px dashed var(--k-border);
    }
    &:last-child::after {
      border-bottom: none;
    }
    &.clickable:active {
      background: var(--k-navbar-item-pressed-background);
    }
    &.active {
      color: white;
      background: var(--k-color-d-05-primary);
    }
  }
  .row-handle {
    float: left;
    margin-right: 0.5rem;
    cursor: ns-resize;
  }
  .item-list-options {
    margin: -0.25rem -0.45rem;
    float: right;
    pointer-events: all;
  }
}
</style>
