<template>
  <div class="form-control-container">
    <k-label :id :label :description="config.description" />
    <div class="d-flex mt-n2">
      <k-input-config no-container sticky="right" :disabled="config.disabled" :readonly="config.readonly" placeholder="Code">
        <k-input-autocomplete
          v-model="countryCode"
          secondary-property="country"
          show-inline-secondary
          class="me-n2 phone-prefix-input"
          :items="countryOptions" />
      </k-input-config>
      <div class="flex-grow-1 ms-2">
        <k-input-config no-container sticky="both" :disabled="config.disabled" :readonly="config.readonly">
          <k-input :id v-model="number" placeholder="Number" />
        </k-input-config>
      </div>
      <div class="d-flex">
        <k-input-config no-container sticky="left" :disabled="config.disabled" :readonly="config.readonly">
          <k-input v-model="extensionNew" placeholder="Ext" />
        </k-input-config>
      </div>
    </div>
    <k-issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from "vue";

import { v4 } from "uuid";

import KInputConfig from "../KInputConfig.vue";
import { mergeInputConfig, useInputConfig } from "../inputConfig";
import KIssueDisplay from "../KIssueDisplay.vue";

import KInput from "@ui/inputs/strings/KInput.vue";
import KInputAutocomplete from "@ui/inputs/autocomplete/KInputAutocomplete.vue";
import KLabel from "@ui/label/KLabel.vue";

import { countries } from "@data/constants/countries/countries";
import { getFlagEmoji } from "@data/constants/countries/emoji";
import type { PhoneNumber } from "@data/constants/countries/phone";
import { countryPrefixes, specialPrefixes, getPrefixInfo } from "@data/constants/countries/phone";
import type { KRecord } from "@data/data/Record";

const props = defineProps<{
  /** Label for the input */
  label?: string;
  /** Default country code */
  defaultPrefix?: string;
}>();

const modelValue = defineModel<PhoneNumber>();

const id = v4();

const countryOptions = countries.map((country, idx) => {
  const prefix = countryPrefixes.get(country.code);
  if (!prefix) {
    throw new Error(`No prefix for country ${country.code} found!`);
  }
  return {
    id: idx,
    prefix,
    name: `${getFlagEmoji(country.code)} ${prefix}`,
    country: country.name
  };
}) satisfies KRecord[];

countryOptions.push(
  ...Array.from(specialPrefixes.entries()).map(([prefix, info], idx) => ({
    id: countryOptions.length + idx,
    prefix,
    name: `${info.emoji} ${prefix}`,
    country: info.name
  }))
);
countryOptions.push({
  id: countryOptions.length,
  prefix: "+1",
  name: "🇺🇸 +1",
  country: "North America"
});

countryOptions.sort((a, b) => a.prefix.localeCompare(b.prefix));

const countryCode = ref<(typeof countryOptions)[number]>();
const number = ref(modelValue.value?.number);
const extensionNew = ref(modelValue.value?.extension);
const config = useInputConfig();
mergeInputConfig(
  computed(() => ({ sticky: "left", description: undefined })),
  config
);

const setFromPrefix = (prefix?: string) => {
  if (!prefix) {
    return;
  }
  const info = getPrefixInfo(prefix);
  const name = `${info.emoji} ${prefix}`;
  countryCode.value = countryOptions.find((c) => c.name === name);
};

watchEffect(() => {
  setFromPrefix(modelValue.value?.prefix ?? props.defaultPrefix);
  number.value = modelValue.value?.number;
});

const value = computed<PhoneNumber | undefined>(() => {
  if (countryCode.value && number.value) {
    const base = {
      prefix: countryCode.value.prefix,
      number: number.value
    };

    if (extensionNew.value !== undefined) {
      return {
        ...base,
        extension: extensionNew.value
      };
    }
    return base;
  }
  return undefined;
});

watch(value, (newValue) => {
  modelValue.value = newValue;
});
</script>

<style scoped>
:deep(.phone-prefix-input .form-select-sm) {
  max-width: 90px;
  padding-right: 0;
}

:deep(.autocomplete-results) {
  min-width: 250px;
}

:deep(.phone-prefix-input .clear-field) {
  display: none;
}
</style>
