import { BooleanExpression, StringExpression } from "./expressions";

import type { KContext } from "./context";
import type { InvertableExpression } from "./expressions";

export class ConcatExpression extends StringExpression {
  constructor(private readonly subexpressions: StringExpression[]) {
    super();
  }

  evaluate(context: KContext): string | undefined {
    return this.subexpressions.map((e) => e.evaluate(context)).join("");
  }

  display(context: KContext): string {
    // not very nice looking, but it'll do for now
    return `concat(${this.subexpressions.map((e) => e.display(context)).join(", ")})`;
  }
}

export class JoinExpression extends StringExpression {
  constructor(private readonly subexpressions: StringExpression[]) {
    super();
  }

  evaluate(context: KContext): string | undefined {
    return this.subexpressions.map((e) => e.evaluate(context)).join(" ");
  }

  display(context: KContext): string {
    // not very nice looking, but it'll do for now
    return `join(${this.subexpressions.map((e) => e.display(context)).join(", ")})`;
  }
}

export class ContainsExpression extends BooleanExpression implements InvertableExpression {
  constructor(
    private readonly target: StringExpression,
    private readonly substring: StringExpression
  ) {
    super();
  }

  evaluate(context: KContext): boolean {
    const target = this.target.evaluate(context);
    const substring = this.substring.evaluate(context);
    if (target === undefined || substring === undefined) {
      return false;
    }
    return target.includes(substring);
  }

  display(context: KContext): string {
    return `${this.target.display(context)} contains ${this.substring.display(context)}`;
  }

  displayInverted(context: KContext): string {
    return `${this.target.display(context)} doesn't contain ${this.substring.display(context)}`;
  }
}

export class StartsWithExpression extends BooleanExpression implements InvertableExpression {
  constructor(
    private readonly target: StringExpression,
    private readonly substring: StringExpression
  ) {
    super();
  }

  evaluate(context: KContext): boolean {
    const target = this.target.evaluate(context);
    const substring = this.substring.evaluate(context);
    if (target === undefined || substring === undefined) {
      return false;
    }
    return target.startsWith(substring);
  }

  display(context: KContext): string {
    return `${this.target.display(context)} starts with ${this.substring.display(context)}`;
  }

  displayInverted(context: KContext): string {
    return `${this.target.display(context)} doesn't start with ${this.substring.display(context)}`;
  }
}

export class EndsWithExpression extends BooleanExpression implements InvertableExpression {
  constructor(
    private readonly target: StringExpression,
    private readonly substring: StringExpression
  ) {
    super();
  }

  evaluate(context: KContext): boolean {
    const target = this.target.evaluate(context);
    const substring = this.substring.evaluate(context);
    if (target === undefined || substring === undefined) {
      return false;
    }
    return target.endsWith(substring);
  }

  display(context: KContext): string {
    return `${this.target.display(context)} ends with ${this.substring.display(context)}`;
  }

  displayInverted(context: KContext): string {
    return `${this.target.display(context)} doesn't end with ${this.substring.display(context)}`;
  }
}
