<!-- Component for the selection of a record of an unloaded entity -->
<template>
  <div class="form-control-container">
    <k-input-autocomplete
      v-model="input"
      v-model:search="search"
      :label
      :items="fullOptions"
      :loading
      property="label"
      :secondary-property="secondary"
      :has-more="hasMore"
      :can-create-new-options="showCreateRecordButton"
      :search-filter="fullResult ? undefined : () => true"
      @show-related-record-form="(value) => openModal(value)" />
    <record-form
      v-if="collection"
      v-model:visible="showRelatedRecordForm"
      :collection
      :prefill-fields="prefillField"
      is-related-record
      @new-option="(option) => selectNewOption(option)">
    </record-form>
  </div>
</template>

<script setup lang="ts">
import { computed, provide, ref, watch, watchEffect } from "vue";

import { useInputConfig, inputConfigKey } from "@ui/inputs/inputConfig";
import KInputAutocomplete from "@ui/inputs/autocomplete/KInputAutocomplete.vue";

import { useSearchedRecordOptions } from "./useSearchedRecordOptions";

import type { Collection } from "@data/data/Collection";
import type { KBRecord } from "@data/data/Record";
import type { KRecordOption, RelationalFilters } from "@data/fields/relational/RecordFields";
import type { ValidationIssues } from "@data/validation/Validation";

import RecordForm from "@/pages/collections/records/form/RecordForm.vue";
import { useKContext } from "@/expressions/context";
import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { useHasCollectionPermission } from "@/helpers/permissions";
import { useRecordSubscription } from "@/helpers/collection";

import type { PrefilledField } from "@/components/form/FormTypes";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: KRecordOption;
  /** Id of the collection to show options for */
  collectionId: string;
  /** Filters to use */
  filters?: RelationalFilters;
  /** Label for the input */
  label?: string;
  /** Allow users to create on the fly - config option */
  canCreateRecord?: boolean;
}>();

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", newValue: KRecordOption | undefined): void;
}>();

const collectionStore = useCollectionStore();
const collection = collectionStore.useCollectionWithId(computed(() => props.collectionId));

const context = useKContext();

const collectionDeleted = computed(() => !collection.value);

const config = useInputConfig();
const issues = computed<ValidationIssues>(() =>
  collectionDeleted.value ? [{ message: "This collection was deleted", severity: "warning" }] : config.value.issues
);

// manually providing these to avoid Vue throwing a warning about setting the class on KInputConfig
provide(
  inputConfigKey,
  computed(() => ({
    ...config.value,
    issues: issues.value,
    disabled: collectionDeleted.value
  }))
);

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const input = ref(props.modelValue);
// tracks the last input so that always it appears in the options
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const previousValue = ref(props.modelValue);

// if the current record is in the collection, it should not be an option
const forbiddenId = computed(() => {
  const entity = context.value.entity as Collection | undefined;
  const record = context.value.record as KBRecord | undefined;
  if (entity && record && props.collectionId === entity.id) {
    return record.id;
  }
  return undefined;
});

const { options, loading, search, hasMore, fullResult } = useSearchedRecordOptions({
  collection,
  propFilters: computed(() => props.filters),
  context,
  selected: computed(() => (input.value ? [input.value] : []))
});

const secondary = computed(() => (options.value.some((o) => o.secondaryLabel) ? "secondaryLabel" : undefined));

watch(input, (newRecord) => emit("update:modelValue", newRecord));

watch(
  () => props.modelValue,
  (newValue) => {
    if (input.value !== newValue) {
      input.value = newValue;
      previousValue.value = newValue;
    }
  }
);
const newOptions = ref<KRecordOption[]>([]);

if (collection.value) {
  useRecordSubscription(
    computed(() => collection.value!),
    (event) => {
      if (event.eventType === "RECORD_ADDED" && collection.value) {
        for (const record of event.recordData ?? []) {
          const restoredRecord = collection.value.restoreRecord(record.data);
          newOptions.value.push({
            id: record.id,
            label: collection.value.primaryField.getDisplayValue(restoredRecord),
            secondaryLabel: collection.value.secondaryField?.getDisplayValue(restoredRecord)
          });
        }
      }
    }
  );
}

// include previous value so that input isn't blank with deleted value
// and remove forbidden option
const fullOptions = ref<KRecordOption[]>([]);
watchEffect(() => {
  let result = [...options.value];
  if (previousValue.value && !options.value.some((o) => o.id === previousValue.value?.id)) {
    result.push(previousValue.value);
  }
  if (forbiddenId.value !== undefined) {
    result = result.filter((o) => o.id !== forbiddenId.value);
  }
  if (newOptions.value.length > 0) {
    result = [...newOptions.value, ...result];
  }
  fullOptions.value = result;
});

const selectNewOption = (option?: KRecordOption) => {
  if (option) {
    input.value = option;
  } else {
    input.value = undefined;
    search.value = "";
  }
};

// only show if configured *and* user has permission
const hasAddPermission = useHasCollectionPermission(collection, "add");
const showCreateRecordButton = computed(() => props.canCreateRecord && !collection.value?.isReadOnly && hasAddPermission.value && !collectionDeleted.value);

const showRelatedRecordForm = ref(false);
const prefillField = ref<PrefilledField[]>([]);

const openModal = (value: string) => {
  if (!collection.value) return;
  showRelatedRecordForm.value = true;
  const primaryField = collection.value.primaryField;
  prefillField.value.push({ key: primaryField.key, value, mode: "editable" });
};
</script>
