<template>
  <nav class="navbar" :class="`navbar-${mode.toLowerCase()} ${transitionClasses}`">
    <div class="navbar-body d-flex flex-column">
      <k-navbar-header
        :mode
        :structure="config"
        :notification-count="notificationCount"
        @open-notifications="emit('openNotifications', $event)"
        @done="onDoneEditing"
        @undo="onReset" />

      <div ref="contentElement" class="navbar-content flex-grow-1 pt-1">
        <template v-if="mode != 'COMPACT'">
          <div v-if="mode != 'EDITING'" class="navbar-group-items pb-2">
            <k-navbar-item
              v-for="(item, i) in config.items"
              :key="i"
              :mode
              :item
              @right-click="($evt) => onShowItemContextMenu($evt, item)"
              @navigated="emit('navigated')" />
          </div>

          <div class="navbar-groups pt-1">
            <draggable v-model="groups" :disabled="mode != 'EDITING'" :animation="100" ghost-class="dragging" item-key="label" handle=".group-drag-handle">
              <template #item="{ element, index }">
                <k-navbar-group
                  v-model:mode="mode"
                  :group="element"
                  :index
                  :can-customise="canCustomise"
                  :add-button-label="addButtonLabel"
                  @show-menu="onShowGroupContextMenu"
                  @show-item-menu="onShowItemContextMenu"
                  @navigated="onNavigated"
                  @add="onAdd(element)"
                  @update-label="(label) => onGroupLabelUpdate(index, label)"
                  @update-items="(items) => onGroupItemUpdate(index, items)"
                  @delete="onGroupDelete(index)" />
              </template>
            </draggable>
          </div>
          <div v-if="mode == 'EDITING'" class="px-2">
            <k-button variant="transparent" class="w-100 mt-n2 text-primary text-start" icon="add" label="Add group" @click="onGroupAdd" />
          </div>
        </template>
        <template v-else>
          <div class="navbar-group-items pb-3">
            <k-navbar-item class="mb-2" :mode :item="{ label: 'Dashboard', icon: 'home', to: { path: '/' } }" @navigated="onNavigated" />
            <k-navbar-item
              v-if="notificationCount !== undefined"
              class="mb-2"
              :mode
              :item="{ label: 'Notifications', icon: 'bell' }"
              @click="emit('openNotifications', $event)">
              <k-notification-badge :notification-count="notificationCount" in-nav-item />
            </k-navbar-item>
            <k-navbar-item class="mb-2" :mode :item="{ label: 'Show all', icon: 'list' }" @click="navbarState.toggleState" />
          </div>
        </template>
      </div>
      <k-overflow-indicator direction="up" :opacity="opacityTop" :style="{ top: `${top}px` }" />
      <k-overflow-indicator direction="down" :opacity="opacityBottom" :style="{ top: `${bottom - 3}px` }" />

      <k-navbar-footer
        ref="footerElement"
        v-model:mode="mode"
        :add-button-label="addButtonLabel"
        :can-customise="canCustomise"
        :structure="config"
        @add="onAdd">
        <slot name="footer" :state="navbarState.state.value" />
        <template #menu><slot name="menu"> </slot></template>
      </k-navbar-footer>
    </div>
  </nav>

  <k-context-menu ref="groupContextMenu" :items="groupContextMenuItems" />
  <k-context-menu ref="itemContextMenu" :items="itemContextMenuItems" />
</template>

<script setup lang="ts">
import { nextTick, onBeforeMount, ref, watch, watchEffect } from "vue";
import { useRouter } from "vue-router";

import { useCssVar, useResizeObserver } from "@vueuse/core";
import draggable from "vuedraggable";

import KNotificationBadge from "../notifications/KNotificationBadge.vue";

import KButton from "@ui/button/KButton.vue";
import KContextMenu from "@ui/context-menu/KContextMenu.vue";
import { selectContentEditableText } from "@ui/helpers/dom/ContentEditableTextSelection";
import { useScrollIndicator } from "@ui/helpers/dom/UseScrollIndicator";
import KOverflowIndicator from "@ui/toolbar/KOverflowIndicator.vue";
import type { KContextMenuElement } from "@ui/context-menu/ContextMenuElement";
import type { ContextMenuItem } from "@ui/context-menu/ContextMenuItem";

import KNavbarFooter from "./KNavbarFooter.vue";
import KNavbarGroup from "./KNavbarGroup.vue";
import KNavbarHeader from "./KNavbarHeader.vue";
import KNavbarItem from "./KNavbarItem.vue";
import { useNavbarState } from "./NavbarState";

import { deepCopy } from "@data/helpers/manipulation/Copying";

import type { NavbarState } from "./NavbarState";
import type { NavbarGroup, NavbarItem, NavbarStructure } from "./NavbarStructure";

const props = defineProps<{
  /** Navigation structure */
  config: NavbarStructure;
  /** Notification count */
  notificationCount?: number;
  /** Label for the add button */
  addButtonLabel?: string;
  /** Whether the navbar can be customised */
  canCustomise?: boolean;
  /** Custom context menu items when right clicking */
  contextMenuItems?: ContextMenuItem<NavbarItem>[];
}>();

const emit = defineEmits<{
  (event: "itemsUpdated", value: NavbarGroup[]): void;
  (event: "add", group?: NavbarGroup): void;
  (event: "openNotifications", evt?: MouseEvent): void;
  (event: "navigated"): void;
}>();

const navbarState = useNavbarState();
const mode = ref<NavbarState>(navbarState.state.value);

// Ensure the navbar always has enough space and doesn't overlap the content
const contentLeft = useCssVar("--k-content-left", ref(document.body));
const setContentLeft = () => {
  switch (navbarState.state.value) {
    case "COMPACT":
      contentLeft.value = "var(--k-navbar-width-sm)";
      break;
    case "EXPANDED":
    case "EDITING":
      contentLeft.value = "var(--k-navbar-width)";
      break;
    case "OVERLAY":
    case "HIDDEN":
      contentLeft.value = "0";
      break;
  }
};

// Ensure navbar has correct state
onBeforeMount(() => {
  navbarState.updateState(navbarState.getDefaultState());
  setContentLeft();
});

useResizeObserver(document.body, () => {
  navbarState.onWindowResize();
});

// Apply transition classes when navbar state changes so it looks cool
const transitionClasses = ref("");
watch(
  () => navbarState.state.value,
  (newVal, oldVal) => {
    mode.value = newVal;

    setContentLeft();

    const stateTransistion = `transition-from-${oldVal.toLowerCase()}-to-${newVal.toLowerCase()}`;
    transitionClasses.value = `transition ${stateTransistion} ${stateTransistion}-start`;
    setTimeout(() => {
      transitionClasses.value = `transition ${stateTransistion}`;
    }, 10);
    setTimeout(() => {
      transitionClasses.value = "";
    }, 150); // Note this needs to match the transition durations below and in app.css
  }
);

// Add the scroll indicators at top and bottom
const contentElement = ref<HTMLElement>();
const { opacityTop, opacityBottom, top, bottom } = useScrollIndicator(contentElement);

// Main add button
const onAdd = (group?: NavbarGroup) => {
  emit("add", group);
};

const groups = ref<NavbarGroup[]>([]);

watchEffect(() => (groups.value = deepCopy(props.config.groups)));

const onGroupRename = (index: number) => {
  const label = document.getElementById(`navbar-group-label-${index}`);
  if (label) {
    label.focus();
    // Select all text in label
    selectContentEditableText(label);
  }
};

const onGroupAdd = async () => {
  groups.value.push({
    label: "New group",
    items: []
  });

  // Wait for next tick to focus on the new group label so users can start typing right away
  await nextTick(() => {
    onGroupRename(groups.value.length - 1);
  });
};

const onGroupItemUpdate = (index: number, items: NavbarItem[]) => {
  groups.value[index].items = items;
};

const onGroupLabelUpdate = (index: number, label: string) => {
  groups.value[index].label = label;
};

const onGroupDelete = (index: number) => {
  groups.value.splice(index, 1);
};

const onDoneEditing = () => {
  mode.value = navbarState.state.value;
  emit("itemsUpdated", deepCopy(groups.value));
};

const onReset = () => {
  mode.value = navbarState.state.value;
  groups.value = deepCopy(props.config.groups);
};

const groupContextMenu = ref<KContextMenuElement<NavbarGroup>>();
const groupContextMenuItems: ContextMenuItem<NavbarGroup>[] = [
  {
    label: "Customise menu",
    showOption: () => mode.value !== "EDITING" && !!props.canCustomise,
    onClick: () => {
      mode.value = "EDITING";
    }
  },
  {
    label: "Rename",
    icon: "pencil",
    showOption: () => mode.value === "EDITING" && !!props.canCustomise,
    onClick: (group?: NavbarGroup) => {
      if (!group) return;
      onGroupRename(groups.value.indexOf(group));
    }
  },
  {
    label: "Delete",
    icon: "trash",
    variant: "danger",
    showOption: (group) => mode.value === "EDITING" && !!props.canCustomise && group?.items.length === 0,
    onClick: (group?: NavbarGroup) => {
      if (!group) return;
      const index = groups.value.indexOf(group);
      if (index === -1 || group.items.length > 0) return;
      onGroupDelete(index);
    }
  }
];

const onShowGroupContextMenu = (evt: MouseEvent, group: NavbarGroup, buttonTrigger: boolean) => {
  if (!props.canCustomise) return;
  evt.preventDefault();
  groupContextMenu.value?.show(evt, group, buttonTrigger);
};

/** Hide the navbar when the user navigates to a new page */
const onNavigated = () => {
  if (navbarState.state.value === "OVERLAY") {
    navbarState.updateState("HIDDEN");
  }
  emit("navigated");
};

/** Item context menu */
const router = useRouter();
const itemContextMenu = ref<KContextMenuElement<NavbarItem>>();
const itemContextMenuItems: ContextMenuItem<NavbarItem>[] = [
  {
    label: "Open",
    showOption: (item) => item?.to !== undefined,
    onClick: async (item) => {
      if (!item?.to) return;
      await router.push(item.to);
    }
  },
  {
    label: "Open in new tab",
    showOption: (item) => item?.to !== undefined,
    onClick: (item) => {
      if (!item?.to) return;
      const route = router.resolve(item.to);
      window.open(route.fullPath, "_blank");
    }
  },
  { type: "divider" },
  {
    label: "Customise menu",
    showOption: () => mode.value !== "EDITING" && !!props.canCustomise,
    onClick: () => {
      mode.value = "EDITING";
    }
  }
];

if (props.contextMenuItems) {
  itemContextMenuItems.push(...props.contextMenuItems);
}

const onShowItemContextMenu = (evt: MouseEvent, item: NavbarItem) => {
  if (!props.canCustomise) return;
  evt.preventDefault();
  itemContextMenu.value?.show(evt, item, false);
};
</script>

<style scoped>
.navbar {
  position: fixed;
  left: 0;
  top: 0;
  top: calc(0px + env(safe-area-inset-top));
  bottom: 0;
  width: var(--k-navbar-width);
  max-width: 100%;

  padding: 0;
  padding-left: env(safe-area-inset-left);
  margin: 0;

  transition:
    width 0.15s ease-in-out,
    left 0.15s ease-in-out,
    opacity 0.15s ease-in-out;

  background: var(--k-navbar-background);
  z-index: 0;

  text-align: left;

  user-select: none;
  -webkit-user-select: none;
}

.navbar.transition {
  overflow: hidden !important;
}

.navbar::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.05);
}

.navbar:has(.dropdown-menu.show) {
  z-index: 10000;
}

.navbar.navbar-compact {
  width: var(--k-navbar-width-sm);
  transform: translateX(0);
}

.navbar.navbar-hidden {
  width: var(--k-navbar-width);
  left: var(--k-navbar-width-neg);
}

.navbar.navbar-overlay,
.navbar.navbar-editing {
  width: var(--k-navbar-width);
  left: 0;
  box-shadow: 2px 0 5px 0px rgb(0 0 0 / 25%);
  z-index: 10001;
}

.navbar.navbar-overlay::after,
.navbar.navbar-editing::after {
  opacity: 0;
}

.navbar.transition-from-hidden-to-compact {
  z-index: 0;
  width: var(--k-navbar-width-sm);
}

.navbar.transition-from-hidden-to-compact-start {
  left: calc(0px - var(--k-navbar-width-sm));
}

.navbar.transition-from-overlay-to-hidden,
.navbar.transition-from-overlay-to-compact {
  z-index: 10000;
}

.navbar.transition-from-compact-to-hidden {
  overflow: hidden !important;
  opacity: 0;
  width: var(--k-navbar-width-sm);
}

.navbar.transition-from-compact-to-hidden .navbar-body {
  opacity: 0;
  width: var(--k-navbar-width-sm);
}

:deep(.navbar-header),
.navbar-content {
  width: var(--k-navbar-width);
}

.navbar-compact :deep(.navbar-header),
.navbar-compact .navbar-content {
  width: var(--k-navbar-width-sm);
}

:deep(.navbar-hide-compact) {
  transition: opacity 0.06s ease-in;
}

.navbar.transition-from-compact-to-expanded :deep(.navbar-hide-compact) {
  opacity: 0;
}

.navbar.transition-from-compact-to-expanded-end :deep(.navbar-hide-compact) {
  opacity: 1;
}

.navbar-content {
  overflow-y: auto;
  position: relative;
}

.navbar-body {
  height: 100%;
  min-height: 400px;
  overflow-x: visible;
}

.navbar-group-items.disabled {
  opacity: 0.3;
}

.navbar {
  /* Firefox */
  scrollbar-color: #84848490 transparent;
  scrollbar-width: thin;
}

.navbar ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.navbar ::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 10px;
  background-color: #84848490;
}

.navbar ::-webkit-scrollbar-thumb:hover {
  background-color: #848484;
}

.navbar-compact :deep(.navbar-item) {
  overflow: hidden;
  text-overflow: clip;
}
</style>
