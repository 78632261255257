import { BulkData } from "../data";
import { DataExpression } from "../expressions";

import type { KContext } from "../context";
import type { BooleanExpression } from "../expressions";

export class FilterExpression extends DataExpression {
  constructor(
    private readonly data: DataExpression,
    private readonly condition: BooleanExpression
  ) {
    super();
  }

  evaluate(context: KContext) {
    const data = this.data.evaluate(context);
    if (data === undefined) {
      return undefined;
    }
    const valid = new Set();
    for (const item of data.unique) {
      const items = [...(context.items ?? []), item];
      if (this.condition.evaluate({ ...context, items })) {
        valid.add(item);
      }
    }
    return new BulkData(data.ordered.filter((item) => valid.has(item)));
  }

  display(context: KContext): string {
    return `filter ${this.data.display(context)} by ${this.condition.display(context)}`;
  }
}

export class IntersectionExpression extends DataExpression {
  private readonly subexpressions: DataExpression[];

  constructor(...subexpressions: DataExpression[]) {
    super();
    this.subexpressions = subexpressions;
  }

  evaluate(context: KContext) {
    const data = this.subexpressions[0].evaluate(context);
    if (data === undefined) {
      return undefined;
    }
    const valid = new Set(data.unique);
    for (const subexpression of this.subexpressions.slice(1)) {
      const subdata = subexpression.evaluate(context);
      if (subdata === undefined) {
        return undefined;
      }
      for (const item of data.unique) {
        if (!subdata.unique.has(item)) {
          valid.delete(item);
        }
      }
    }
    return new BulkData([...valid]);
  }

  display(context: KContext): string {
    return `intersect(${this.subexpressions.map((expr) => expr.display(context)).join(", ")})`;
  }
}

export class UnionExpression extends DataExpression {
  private readonly subexpressions: DataExpression[];

  constructor(...subexpressions: DataExpression[]) {
    super();
    this.subexpressions = subexpressions;
  }

  evaluate(context: KContext) {
    const data = this.subexpressions[0].evaluate(context);
    if (data === undefined) {
      return undefined;
    }
    const valid = new Set(data.unique);
    for (const subexpression of this.subexpressions.slice(1)) {
      const subdata = subexpression.evaluate(context);
      if (subdata === undefined) {
        return undefined;
      }
      for (const item of subdata.unique) {
        valid.add(item);
      }
    }
    return new BulkData([...valid]);
  }

  display(context: KContext): string {
    return `union(${this.subexpressions.map((expr) => expr.display(context)).join(", ")})`;
  }
}

export class ConcatDataExpression extends DataExpression {
  private readonly subexpressions: DataExpression[];

  constructor(...subexpressions: DataExpression[]) {
    super();
    this.subexpressions = subexpressions;
  }

  evaluate(context: KContext) {
    const data = this.subexpressions.map((expr) => expr.evaluate(context));
    if (data.includes(undefined)) {
      return undefined;
    }
    return new BulkData(data.flatMap((d) => d!.ordered));
  }

  display(context: KContext): string {
    return this.subexpressions.map((expr) => expr.display(context)).join(" + ");
  }
}
