<template>
  <div class="form-control-container">
    <k-label :id="uuid" :label />
    <div class="input-group input-group-sm">
      <span :class="spanClasses">
        <k-input-config no-container hide-label :disabled="isPreview">
          <k-input-number v-model="manualInput" :step :min :max class="manual-input" placeholder="--"
        /></k-input-config>
      </span>
      <div :class="classes">
        <span>
          {{ minLabel ?? min }}
        </span>
        <input
          :id="uuid"
          v-model="input"
          :class="{ isUndefined: input === undefined || input === null || issues }"
          class="form-range"
          type="range"
          v-bind="commonBindings(config, label)"
          :min
          :max
          :step
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          @change="$emit('change', $event)" />
        <span>
          {{ maxLabel ?? max }}
        </span>
      </div>
    </div>
    <issue-display />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";

import { v4 } from "uuid";

import KInputConfig from "../KInputConfig.vue";
import { commonBindings, useInputConfig, getInputClasses } from "../inputConfig";
import IssueDisplay from "../KIssueDisplay.vue";

import KLabel from "@ui/label/KLabel.vue";

import KInputNumber from "./KInputNumber.vue";

const props = defineProps<{
  /** Whether it is being used in field editor preview */
  isPreview?: boolean;
  /** Min is undefined in config preview */
  min?: number;
  /** Max is undefined in config preview */
  max?: number;
  /** Step for range slider */
  step?: number;
  /** Min max labels */
  labels?: { value: number; label: string }[];
  /** Label for the input */
  label?: string;
  /** Value of the input */
  modelValue?: number | undefined;
}>();

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", value: number | undefined): void;
}>();

const input = ref<number>();
const manualInput = ref<number>();
watch(
  () => props.modelValue,
  (value) => {
    input.value = value;
    manualInput.value = value;
  },
  { immediate: true }
);

watch(
  () => input.value,
  (value) => {
    if (value == null) {
      emit("update:modelValue", undefined);
    } else {
      const number = Number(value);
      if (!Number.isNaN(number)) {
        emit("update:modelValue", number);
      }
    }
  },
  { immediate: true }
);

const issues = computed<boolean>(() => {
  if (input.value === undefined || !props.max || !props.min) {
    return false;
  }
  if (input.value < props.min || input.value > props.max) {
    return true;
  }
  const step = props.step ?? 1;
  if (step === 1 && input.value % 1 !== 0) {
    return true;
  } else if ((props.max - input.value) % step !== 0) {
    return true;
  }
  return false;
});

watch(
  () => manualInput.value,
  (value) => {
    input.value = value;
  }
);

const config = useInputConfig();

const classes = computed(() => ({
  "range-wrapper": true,
  ...getInputClasses(config.value, "control")
}));

const spanClasses = computed(() => ({
  "input-group-text": true,
  "range-value": true,
  disabled: config.value.disabled
}));

const uuid = v4();

// const ticks = computed<{ value: number; label?: string }[]>(() => {
//   const ticks: { value: number; label?: string }[] = [];
//   const labelsMap = new Map(props.labels?.map((l) => [l.value, l.label] as const) ?? []);
//   for (let i = props.min; i <= props.max; i += props.step ?? 1) {
//     ticks.push({ value: i, label: labelsMap.get(i) });
//   }
//   return ticks;
// });

const minLabel = computed(() => props.labels?.find((l) => l.value === props.min)?.label);
const maxLabel = computed(() => props.labels?.find((l) => l.value === props.max)?.label);
</script>

<style scoped lang="scss">
.range-value {
  background-color: var(--k-input-background);
  border-right: 2px solid var(--k-color-l-15-secondary);
  &.disabled {
    background-color: var(--k-input-disabled-background);
  }
  > span {
    text-align: center;
    width: 40px;
  }
}
.form-range {
  background-color: var(--k-input-background);
  color: var(--k-color);
  position: relative;
  flex: 1 1 auto;

  min-width: 0;
  padding: 0 0.75rem;
  :disabled {
    background-color: var(--k-input-disabled-background);
  }
}
.range-wrapper {
  display: flex;
  align-items: center;
}
.manual-input {
  width: 4rem;
}
::v-deep(.manual-input .form-control.disabled) {
  border: none;
}

.form-range.isUndefined {
  &::-webkit-slider-thumb {
    background-color: var(--k-color-l-15-secondary);
  }

  &::-moz-range-thumb {
    background-color: var(--k-color-l-15-secondary);
  }
}
</style>
