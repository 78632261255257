<template>
  <div class="multi-collection-item mb-3 border rounded border-bottom">
    <div class="d-flex bg-light border-bottom p-1">
      <k-icon-picker :model-value="collection.icon" @update:model-value="iconChanged" />
      <div class="p-1 flex-grow-1">
        {{ name }}
      </div>
      <k-button variant="info" icon="pencil" label="Edit" title="Edit collection" class="me-1" :disabled @click="editCollection" />
      <k-button variant="transparent" icon="trash" title="Remove collection" :disabled @click="deleteCollection" />
    </div>
    <div v-if="fields && fields.length > 0" class="px-2 mb-n3">
      <k-item-list :items="fields" :context-menu-items="contextMenuItems" :disable-context-menu-evaluator="() => disabled">
        <template #default="{ item }">
          <field-list-item :field="item" :proposed-collections="collections" show-options />
        </template>
      </k-item-list>
    </div>
    <div class="m-1">
      <k-button icon="plus" variant="transparent" label="Add field" :disabled @click="addField" />
    </div>
  </div>
  <k-issue-display v-if="issues" class="mt-n3 pt-1 mb-3" :issues />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import FieldListItem from "../configuration/fields/FieldListItem.vue";

import KButton from "@ui/button/KButton.vue";
import KItemList from "@ui/item-list/KItemList.vue";
import type { ContextMenuItem } from "@ui/context-menu/ContextMenuItem";
import KIssueDisplay from "@ui/inputs/KIssueDisplay.vue";
import KIconPicker from "@ui/inputs/icon/KIconPicker.vue";

import type { Collection } from "@data/data/Collection";
import type { KField } from "@data/fields/KField";
import { RelationalField } from "@data/fields/relational/RecordFields";
import type { Severity } from "@data/validation/Validation";

const props = defineProps<{
  /** Collection to display */
  collection: Collection;
  /** Name of collection (user-entered, may not be normalised to plural yet) */
  name: string;
  /** Whether to disable buttons */
  disabled: boolean;
  /** Fields for this collection */
  fields?: KField[];
  /** All collections (including proposed, to ensure correct label is shown for field type) */
  collections: Collection[];
}>();

const emit = defineEmits<{
  (event: "delete"): void;
  (event: "icon-changed", icon: string): void;
  (event: "edit"): void;
  (event: "add-field"): void;
  (event: "edit-field", field: KField): void;
  (event: "delete-field", field: KField): void;
}>();

const editCollection = () => emit("edit");
const iconChanged = (icon: string) => emit("icon-changed", icon);
const addField = () => emit("add-field");

const issues = ref<{ message: string; severity: Severity }[]>([]);

const deleteCollection = () => {
  const isReferenced = props.collections.some((c) => c.fields.some((f: KField) => f instanceof RelationalField && f.collectionId === props.collection.id));

  if (isReferenced) {
    issues.value = [{ message: "This collection is referenced by other collections and cannot be deleted", severity: "error" }];
    return;
  }

  // TODO: confirm delete if more than one field

  emit("delete");
};

const contextMenuItems = computed<ContextMenuItem<KField>[]>(() => [
  {
    label: "Edit",
    icon: "pencil",
    onClick: (field?: KField) => {
      if (field) {
        emit("edit-field", field);
      }
    }
  },
  {
    label: "Delete",
    icon: "trash",
    onClick: (field?: KField) => {
      if (field) {
        emit("delete-field", field);
      }
    },
    variant: "danger"
  }
]);
</script>

<style scoped>
.multi-collection-item {
  overflow: hidden;
}

.bg-light {
  background: var(--k-color-light) !important;
}
</style>
