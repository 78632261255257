import type { InjectionKey, MaybeRef } from "vue";
import { computed, inject, provide, toValue, watch } from "vue";

import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { stripTypename } from "@apollo/client/utilities";

import type { ReadonlyRef } from "@ui/helpers/refHelpers";

import type { Role } from "@data/data/Role";
import type { TenantVoipDetails } from "@data/data/tenant/Integrations";
import { restoreFields, type ServerField } from "@data/data/Collection";
import type { Person } from "@data/data/Person";
import { personSchema, type ServerPerson } from "@data/data/Person";
import { KEntity } from "@data/data/KEntity";
import { peopleCollectionName } from "@data/constants/names";
import { DatetimeField } from "@data/fields/basic/DatetimeField";
import type { KRecord } from "@data/data/Record";
import type { KField } from "@data/fields/KField";
import { GuidField } from "@data/fields/utility/IdField";
import type { TenantFileStorage } from "@data/data/tenant/Integrations";
import type { TenantPlanDetails } from "@data/data/tenant/Tenant";
import { globalRoles } from "@data/fields/utility/RolesField";

import { useAuth } from "@/services/auth";
import { provideTestKContext } from "@/expressions/context";

type InitialState = {
  roles?: readonly (Role & { __typename?: "Role" })[];
  allPeople?: readonly ServerPerson[];
  tenant?: {
    id: string;
    plan?: Omit<TenantPlanDetails["plan"], "label" | "variant">;
    integrations?: {
      voip?: {
        type?: TenantVoipDetails["type"];
      };
    };

    extraPeopleFields?: {
      fields: ServerField[];
    };

    people?: {
      permissions?: {
        read: string[];
      };
    };

    fileStorage?: Pick<TenantFileStorage["fileStorage"], "maxFileSize">;
  };
};

const useInitialStateQuery = () => {
  const { isAuthenticated } = useAuth();

  return useQuery<InitialState>(
    gql`
      query initialState {
        roles {
          id
          name
          description
        }
        allPeople {
          id
          userId
          name
          status
          firstName
          lastName
          jobTitle
          email
          emails
          roles
          extraData
        }
        tenant {
          id
          plan {
            planCode
            recordLimit
            storageLimit
            automationLimit
            access {
              api
              eventLog
              extensions
            }
          }
          integrations {
            voip {
              type
            }
          }
          extraPeopleFields {
            fields {
              id
              key
              type
              data
            }
          }
          people {
            permissions {
              read
            }
          }
          fileStorage {
            maxFileSize
          }
        }
      }
    `,
    {},
    () => ({
      enabled: isAuthenticated.value,
      fetchPolicy: "cache-and-network"
    })
  );
};

const initialStateKey = Symbol("initialState") as InjectionKey<{
  loading: ReadonlyRef<boolean>;
  extraPeopleFields: ReadonlyRef<KField[]>;
  personEntity: ReadonlyRef<KEntity>;
  roles: ReadonlyRef<InitialState["roles"]>;
  people: ReadonlyRef<Person[] | undefined>;
  tenant: ReadonlyRef<InitialState["tenant"]>;
  peoplePermissions: {
    read: ReadonlyRef<string[]>;
  };
}>;

export const useInitialState = () => {
  const provided = inject(initialStateKey);
  if (provided) return provided;
  throw new Error("No initial state provided");
};

const createPersonEntity = (extraFields: KField[]) =>
  new KEntity(
    peopleCollectionName.singular,
    personSchema.concat(extraFields),
    {
      primaryKey: "name",
      sortKey: "lastName",
      plural: peopleCollectionName.plural,
      icon: "users",
      expressionPrefix: "person"
    },
    [
      new GuidField({ label: "ID", key: "id", serverControlled: true }, "person"),
      new DatetimeField({ label: "Created At", serverControlled: true }),
      new DatetimeField({ label: "Updated At", serverControlled: true })
    ]
  );
export const provideInitialState = () => {
  const { result, loading } = useInitialStateQuery();

  const extraFields = computed(() => restoreFields(result.value?.tenant?.extraPeopleFields?.fields ?? []));
  const personEntity = computed(() => createPersonEntity(extraFields.value));
  const people = computed(() =>
    result.value?.allPeople?.map((p) => {
      const storedData = p.extraData;
      if (!storedData) {
        return { ...p, extraData: undefined };
      }
      const data: KRecord = {};
      for (const field of extraFields.value) {
        field.restoreValue(storedData, data);
      }
      return { ...p, extraData: data };
    })
  );
  watch(
    () => result.value?.roles,
    (newRoles) => {
      if (newRoles) {
        globalRoles.clear();
        for (const role of newRoles) {
          globalRoles.set(role.id, role);
        }
      }
    }
  );

  const state = {
    roles: computed(() => result.value?.roles),
    tenant: computed(() => stripTypename(result.value?.tenant)),
    loading,
    extraPeopleFields: extraFields,
    personEntity,
    people,
    peoplePermissions: {
      read: computed(() => result.value?.tenant?.people?.permissions?.read ?? ["true"])
    }
  };
  provide(initialStateKey, state);
  return state;
};

export const testProvideInitialState = (state?: InitialState & { extraPeopleFields?: KField[]; showPeopleList?: boolean }, loading?: MaybeRef<boolean>) => {
  const extraPeopleFields = computed(() => (toValue(loading) ? [] : (state?.extraPeopleFields ?? [])));
  const personEntity = computed(() => createPersonEntity(extraPeopleFields.value));
  const defaultPermissions = state?.showPeopleList === false ? [] : ["true"];
  return {
    provide: {
      [initialStateKey as symbol]: {
        roles: computed(() => (toValue(loading) ? [] : state?.roles)),
        extraPeopleFields,
        personEntity,
        people: computed(() => (toValue(loading) ? [] : state?.allPeople)),
        peoplePermissions: {
          read: computed(() => (toValue(loading) ? ["true"] : (state?.tenant?.people?.permissions?.read ?? defaultPermissions)))
        },
        tenant: computed(() => (toValue(loading) ? undefined : state?.tenant)),
        loading: computed(() => toValue(loading) ?? false)
      },
      ...provideTestKContext({ personEntity: personEntity.value })
    }
  };
};
