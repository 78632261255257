// matches "<anything>://" or "<anything>:" or "<anything>:///////" at the start of a URL, be careful with localhost:port, as this will match that and you might not want it to
const protocolRegex = /^(\w+:\/*)/g;

// strips the protocol from a URL
export const stripProtocol = (url: string) => url.replace(protocolRegex, "");

// appends http:// to the start of a URL if it doens't match the protocolRegex
export const appendDefaultProtocol = (url: string) => (url.match(protocolRegex) ? url : "http://" + url);

// strip protocol, www. and trailing slash
export const displayURL = (url: string) => {
  url = stripProtocol(url);
  if (url.startsWith("www.")) url = url.substring(4);
  if (url.endsWith("/")) url = url.substring(0, url.length - 1);
  return url;
};

// "validates" a URL by checking if it can be parsed by the typescript built-in URL class
// this seems to basically allow <protocol>:<anything> as valid
export const validateURL = (url: string | undefined) => {
  if (!url) return false;

  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

// helper function to get a valid URL | undefined from a string using validateURL() and appendDefaultProtocol()
export const getValidURL = (input: string | undefined) => {
  if (!input) return undefined;

  input = appendDefaultProtocol(input.trim());

  return validateURL(input) ? input : undefined;
};
