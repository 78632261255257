import { peopleCollectionName } from "./names";

import type { KFieldValue } from "../data/Record";

export interface FieldTypeDefinition {
  label: string;
  description?: string;
  secondaryDescription?: string;
  icon?: string;
  searchTerms?: readonly string[];
  examples?: readonly KFieldValue[];
}

export const standardFieldDefinitions = {
  string: {
    label: "Text",
    description: "A simple text input where you can write any characters, numbers, and spaces.",
    examples: ["John Doe", "example text", "hello world"],
    searchTerms: ["text", "string", "characters"],
    icon: "font"
  },
  richText: {
    label: "Rich Text",
    description: "A text input where you can add formatting like bold, italics, underline, and bullet points.",
    examples: ["Rich Text Example", "<b>Rich</b> <em>Text</em>"],
    searchTerms: ["formatted text", "rich text", "HTML", "comments", "notes", "description"],
    icon: "align-left"
  },
  number: {
    label: "Number",
    description: "A numeric input that accepts only numbers.",
    examples: [42, 3.14, -10],
    searchTerms: ["number", "numeric"],
    icon: "hashtag"
  },
  rating: {
    label: "Rating",
    description: "A rating input for whole numbers. Can be displayed as slider or radio buttons.",
    examples: [2, 10, 0],
    searchTerms: ["rating", "score", "integer", "number", "numeric", "whole", "radio", "slider"],
    icon: "star-sharp-half-stroke"
  },
  probability: {
    label: "Probability",
    description: "A numeric input representing the likelihood of an event occurring, expressed as a percentage.",
    examples: ["Certain (100%)", "Likely (75%)", "Unlikely (25%)", "Impossible (0%)"],
    searchTerms: ["probability", "likelihood", "chance"],
    icon: "dice"
  },
  currency: {
    label: "Currency",
    description: "A numeric input representing money or monetary value.",
    examples: ["£100"],
    searchTerms: ["currency", "money", "value", "cost", "price", "sale", "dollar", "pound", "euro", "GBP"],
    icon: "sterling-sign"
  },
  boolean: {
    label: "Yes / No",
    description: "A true/false value or a binary choice",
    examples: ["True", "False", "Yes", "No"],
    searchTerms: ["boolean", "binary", "yes or no", "checkbox", "switch", "toggle"],
    icon: "check-square"
  },
  date: {
    label: "Date",
    description: "A date input with a calendar picker.",
    examples: ["20/12/2020", "04/07/2021", "31/10/1995"],
    searchTerms: ["date", "calendar", "day"],
    icon: "calendar-alt"
  },
  month: {
    label: "Month",
    description: "A date, but only the month and year.",
    examples: ["December 2020", "July 2021", "October 1995"],
    searchTerms: ["month", "calendar"],
    icon: "calendar-week"
  },
  year: {
    label: "Year",
    description: "A date, but only the year.",
    examples: ["1857", "2021", "1985"],
    searchTerms: ["year", "calendar"],
    icon: "calendar"
  },
  datetime: {
    label: "Date + Time",
    description: "A date and time input with a combined calendar and time-picker.",
    examples: ["20/12/2020 14:30", "04/07/2021 09:15", "31/10/1995 23:45"],
    searchTerms: ["datetime", "timestamp", "calendar"],
    icon: "calendar-clock"
  },
  duration: {
    label: "Duration",
    description: "Time in days, hours or minutes, representing a duration and often used for billing or scheduling.",
    examples: ["1 hour 45 minutes", "30 minutes"],
    searchTerms: ["duration", "time", "length", "period", "interval", "timespan", "man hours", "billing"],
    icon: "timer"
  },
  timeSpan: {
    label: "Date Range",
    description: "Two date and time inputs, representing a start and end time.",
    examples: ["20/12/2020, 14:30 to 04/07/2021, 09:15", "31/10/1995, 13:45 to 16:00"],
    searchTerms: ["span", "time", "appointment", "schedule", "range", "duration", "time period", "calendar"],
    icon: "chess-clock"
  },
  location: {
    label: "Location",
    description: "An input for geographic or physical addresses.",
    examples: ["St John's Innovation Centre, Cowley Road, Cambridge, CB4 0WS", "1600 Pennsylvania Ave NW, Washington, DC 20500"],
    searchTerms: ["location", "latitude and longitude", "postcode", "state", "country", "county", "street", "road", "address"],
    icon: "map-marker-alt"
  },
  email: {
    label: "Email Address",
    description: "An input specifically for email addresses, allowing you to use one click to send an email.",
    examples: ["john.doe@example.com", "jane@example.co"],
    searchTerms: ["email address", "email"],
    icon: "envelope"
  },
  website: {
    label: "Website",
    description:
      "An input specifically for website URLs. This will automatically add a link to the website, and verifies you've entered the address correctly.",
    examples: ["https://www.example.com", "www.example.org"],
    searchTerms: ["website", "URL", "link"],
    icon: "globe"
  },
  phone: {
    label: "Phone Number",
    description: "An input specifically for phone numbers.",
    examples: ["01223 626261", "(123) 456-7890", "+1-555-1234", "+44 07343012 # 123"],
    searchTerms: ["phone number", "telephone"],
    icon: "phone"
  },
  option: {
    label: "Select",
    description: "A dropdown menu where you can choose one option from a predetermined list.",
    examples: ["Red", "Blue", "Green"],
    searchTerms: ["select", "dropdown", "choice", "picklist", "pick list", "drop down", "menu", "category"],
    icon: "caret-square-down"
  },
  multiOption: {
    label: "Multi-Select",
    description: "A dropdown menu where you can choose multiple options from a predetermined list.",
    examples: ["Red, Blue, Green", "Green, Blue"],
    searchTerms: ["multi", "select", "choice", "multiple", "tags"],
    icon: "tags"
  },
  person: {
    label: peopleCollectionName.singular,
    description: "A user in your Kinabase organisation",
    examples: ["John Doe", "Jane Smith", "Bob Johnson"],
    searchTerms: ["person", "individual", "user", "contact", "colleague", "employee", "team", "member"],
    icon: "user"
  },
  people: {
    label: peopleCollectionName.plural,
    description: "A list of users in your Kinabase organisation",
    examples: ["John Doe, Jane Smith, Bob Johnson"],
    searchTerms: ["people", "contacts", "users", "group", "colleagues", "employees", "team", "members", "organisation"],
    icon: "users"
  },
  image: {
    label: "Image",
    description: "A single image file, with the option to take a photo with your device's camera.",
    examples: ["photo.jpg"],
    searchTerms: ["image", "picture", "photo"],
    icon: "image"
  },
  file: {
    label: "File",
    description: "A single file, like a document, image, or spreadsheet, that can be uploaded and stored.",
    examples: ["example.docx", "image.png", "spreadsheet.xlsx"],
    searchTerms: ["file", "document", "upload"],
    icon: "file"
  },
  multiImage: {
    label: "Images",
    description: "Multiple images that can be uploaded and stored.",
    examples: ["photo1.jpg, photo2.png, photo3.jpg"],
    searchTerms: ["images", "photos", "upload", "multiple"],
    icon: "images"
  },
  multiFile: {
    label: "Files",
    description: "Multiple files, such as documents, images, or spreadsheets, that can be uploaded and stored.",
    examples: ["example.docx, image.png, spreadsheet.xlsx"],
    searchTerms: ["files", "documents", "upload", "multiple"],
    icon: "files"
  },
  computedNumber: {
    label: "Computed (Number)",
    description: "A number that is calculated from other fields in the record using a formula.",
    searchTerms: ["computed", "calculated", "formula", "number", "sum", "add", "subtract", "multiply", "divide", "maths", "calculation"],
    icon: "function"
  },
  computedString: {
    label: "Computed (Text)",
    description: "Text that can include other fields in the record using a template.",
    searchTerms: ["computed", "formula", "string", "text", "concatenate", "join", "combine", "template", "text"],
    icon: "square-ellipsis"
  },
  computedDatetime: {
    label: "Computed (Date)",
    description: "A date that is calculated from other fields in the record using a formula.",
    searchTerms: ["computed", "calculated", "formula", "date", "time", "datetime", "timestamp"],
    icon: "calendar-plus"
  },
  lookup: {
    label: "Lookup",
    description: "A field that copies its value from another record in a linked collection.",
    searchTerms: ["lookup", "reference", "copy", "related", "linked", "record", "foreign"],
    icon: "search"
  },
  aggregation: {
    label: "Aggregation",
    description: "A number that is calculated from records in a linked collection",
    searchTerms: ["aggregation", "count", "sum", "average", "min", "max", "delta", "total"],
    icon: "merge"
  },
  risk: {
    label: "Risk Score",
    description: "A risk score calculated from the likelihood and impact of a risk.",
    examples: ["Low", "Medium", "High", "Critical"],
    searchTerms: ["probability", "risk", "impact", "likelihood", "residual risk", "score", "rating", "assessment", "level", "severity", "discoverability"],
    icon: "triangle-exclamation"
  }
};
