import type { ISimpleObject, SimpleObject } from "../helpers/manipulation/Copying";
import type { ColourVariant } from "./ColourVariant";
import type { JsonSerialisable } from "../helpers/serialisation/TypedJSON";

export type RiskObject = {
  likelihood: number;
  impact: number;
};

export class RiskScore implements ISimpleObject {
  public readonly likelihood: number;

  public readonly impact: number;

  public readonly score: number;

  constructor(obj: RiskObject) {
    this.likelihood = obj.likelihood;
    this.impact = obj.impact;
    this.score = obj.likelihood * obj.impact;
  }

  toJSON() {
    return {
      impact: this.impact,
      likelihood: this.likelihood,
      score: this.score
    } satisfies JsonSerialisable;
  }

  deepEquals(other: SimpleObject): boolean {
    return other instanceof RiskScore && this.likelihood === other.likelihood && this.impact === other.impact;
  }

  deepCopy(): ISimpleObject {
    return new RiskScore({ likelihood: this.likelihood, impact: this.impact });
  }
}

export const riskImpactScale: { label: string; value: number; color: ColourVariant }[] = [
  { label: "Very Low", value: 1, color: "green" },
  { label: "Low", value: 2, color: "green" },
  { label: "Medium", value: 3, color: "yellow" },
  { label: "High", value: 4, color: "orange" },
  { label: "Critical", value: 5, color: "red" }
];

export const riskLikelihoodScale: { label: string; value: number; color: ColourVariant }[] = [
  { label: "Very Likely", value: 5, color: "red" },
  { label: "Likely", value: 4, color: "orange" },
  { label: "Possible", value: 3, color: "yellow" },
  { label: "Unlikely", value: 2, color: "green" },
  { label: "Rare", value: 1, color: "green" }
];

export const riskScoreScale: { label: string; value: number; color: ColourVariant }[] = [
  { label: "Critical", value: 20, color: "red" },
  { label: "High", value: 10, color: "orange" },
  { label: "Medium", value: 5, color: "yellow" },
  { label: "Low", value: 0, color: "green" }
];

export const getRiskScore = (risk: RiskScore | number): { label: string; value: number; variant: ColourVariant } => {
  const score = typeof risk === "number" ? risk : risk.score;
  for (const scale of riskScoreScale) {
    if (score >= scale.value) {
      return { label: scale.label, value: score, variant: scale.color };
    }
  }
  throw new Error(`Invalid risk score: ${score}`);
};
