/* eslint-disable max-lines */
import type { Ref } from "vue";
import { computed, ref, toValue, watch, watchEffect } from "vue";

import { gql } from "@apollo/client/core";
import { useQuery, useMutation } from "@vue/apollo-composable";

import { deepDebounce } from "@ui/helpers/refHelpers";

import { fullRecord } from "./fragments";

import type { Collection } from "@data/data/Collection";
import type { KBRecord, RecordUpdateEvent, ServerRecord } from "@data/data/Record";
import type { KRecordOption } from "@data/fields/relational/RecordFields";

import type { MaybeRef } from "@vueuse/core";

export type SortOptions = {
  groupByField?: string;
  search?: string;
  sortByField?: string;
  sortDescending: boolean;
};

export type RecordCountResult = {
  count: number;
  approximate: boolean;
};

// in-memory cache for record counts because apollo sucks
const recordCountCache = new Map<string, RecordCountResult>();

const toCacheKey = (collectionId: string, filters: string[]) => `${collectionId}:${filters.join(",")}`;
const getCachedCount = (collectionId: string | undefined, filters: string[]) => {
  if (!collectionId) return undefined;
  return recordCountCache.get(toCacheKey(collectionId, filters));
};
const setCachedCount = (collectionId: string, filters: string[], count: RecordCountResult) => {
  if (recordCountCache.size > 100) {
    // clear cache if it gets too big
    recordCountCache.clear();
  }
  recordCountCache.set(toCacheKey(collectionId, filters), count);
};
const useCachedCount = (actualCount: Ref<RecordCountResult | undefined>, collection: MaybeRef<Collection | undefined>, filters: MaybeRef<string[]>) => {
  watch(actualCount, (newValue) => {
    const cId = toValue(collection)?.id;
    if (newValue && cId) {
      setCachedCount(cId, toValue(filters), newValue);
    }
  });

  return computed(() => actualCount.value ?? getCachedCount(toValue(collection)?.id, toValue(filters)));
};

/**
 * Get a list of records for a given collection
 *
 * @param collection Collection to get records for
 * @param baseFilters Base expression filters to apply to the query (will affect the total count)
 * @param filters Extra expression filters to apply to the query (will not affect the total count)
 * @returns List of records, loading state, refetch function, error state, and total count
 */
const useRecordsQuery = (
  collection: MaybeRef<Collection | undefined>,
  baseFilters: MaybeRef<string[]>,
  filters: MaybeRef<string[]>,
  sort: MaybeRef<SortOptions>
) => {
  const allFilters = computed(() => toValue(baseFilters).concat(toValue(filters)));

  // remove sortByField if it is the same as groupByField
  const dedupedSort = deepDebounce(
    computed(() => {
      let s = toValue(sort);
      if (s.groupByField && s.sortByField === s.groupByField) {
        s = { ...s, sortByField: undefined };
      }
      if (s.search === "") {
        s = { ...s, search: undefined };
      }
      return s;
    })
  );

  // initially assume that all records are loaded to avoid unnecessary count queries
  const assumedFullResult = ref(true);
  const fullResult = ref(false);

  // difference in actual count to account for subscription updates
  const countDelta = ref(0);
  // Fetch total count from server
  const {
    result: recordCountResult,
    loading: recordCountLoading,
    refetch: recordCountRefetch,
    onResult: onRecordCountResult
  } = useQuery<{ recordCount: RecordCountResult }, { collectionId?: string; baseFilters: string[] }>(
    gql`
      query getRecordCount($collectionId: String!, $baseFilters: [String!]!) {
        recordCount(collectionId: $collectionId, filters: $baseFilters) {
          count
          approximate
        }
      }
    `,
    () => ({ collectionId: toValue(collection)?.id, baseFilters: toValue(baseFilters) }),
    () => ({ enabled: toValue(collection) !== undefined && !assumedFullResult.value, fetchPolicy: "cache-and-network" })
  );
  const cachedRecordCount = useCachedCount(
    computed(() => recordCountResult.value?.recordCount),
    collection,
    baseFilters
  );

  onRecordCountResult(() => {
    countDelta.value = 0;
  });

  const hasExtraFilters = computed(() => toValue(filters).length > 0 || !!toValue(sort).search);

  // second count query if non-base filters are present
  const {
    result: filteredRecordCount,
    loading: filteredRecordCountLoading,
    refetch: filteredRecordCountRefetch
  } = useQuery<
    { recordCount: RecordCountResult },
    {
      collectionId?: string;
      filters: string[];
      search: string | undefined;
    }
  >(
    gql`
      query getFilteredRecordCount($collectionId: String!, $filters: [String!]!, $search: String) {
        recordCount(collectionId: $collectionId, filters: $filters, search: $search) {
          count
          approximate
        }
      }
    `,
    () => ({ collectionId: toValue(collection)?.id, filters: allFilters.value, search: toValue(sort).search }),
    () => ({ enabled: toValue(collection) !== undefined && !assumedFullResult.value && hasExtraFilters.value, fetchPolicy: "cache-and-network" })
  );
  const cachedFilteredRecordCount = useCachedCount(
    computed(() => filteredRecordCount.value?.recordCount),
    collection,
    allFilters
  );

  // Fetch paginated results from server
  type PageRequest = { from: number; nextPageId: string };
  const currentPage = ref<PageRequest>();
  const nextPage = ref<PageRequest>();
  const pageVariables = computed(() => ({
    collectionId: toValue(collection)?.id,
    baseFilters: toValue(baseFilters),
    filters: allFilters.value,
    sortOptions: dedupedSort.value,
    pageRequest: currentPage.value
  }));
  const {
    result,
    loading,
    refetch: refetchRecordQuery
  } = useQuery<
    {
      records:
        | { full: readonly ServerRecord[]; page: undefined }
        | { full: undefined; page: { data: readonly ServerRecord[]; nextPageId?: string; currentCount: number } };
    },
    {
      collectionId?: string;
      baseFilters: string[];
      filters: string[];
      sortOptions: SortOptions;
      pageRequest?: PageRequest;
    }
  >(
    gql`
      query getRecords($collectionId: String!, $filters: [String!]!, $sortOptions: SortOptionsInput, $pageRequest: PageRequestOfRecordInput) {
        records(collectionId: $collectionId, filters: $filters, sortOptions: $sortOptions, request: $pageRequest) {
          full {
            ${fullRecord}
          }
          page {
            data {
              ${fullRecord}
            }
            nextPageId
            currentCount
          }
        }
      }
    `,
    pageVariables,
    () => ({ enabled: toValue(collection) !== undefined, fetchPolicy: "cache-and-network" })
  );

  const { mutate: dropPage } = useMutation<{ dropNextPage: boolean }, { pageId: string }>(gql`
    mutation dropPage($pageId: String!) {
      dropNextPage(nextPageId: $pageId)
    }
  `);

  const loadedRecords = ref<ServerRecord[]>([]);

  watchEffect(() => {
    // only update fullResult if the records are fully loaded (since the cache could be incorrect)
    if (!loading.value) {
      fullResult.value = !!result.value?.records.full;
      assumedFullResult.value = fullResult.value;
    }
  });

  watch(
    result,
    (newValue) => {
      if (!newValue) return;
      const { records } = newValue;
      if (records.full) {
        loadedRecords.value = [...records.full];
        nextPage.value = undefined;
        return;
      }
      const { page } = records;
      if (page.currentCount - page.data.length >= loadedRecords.value.length) {
        loadedRecords.value.push(...page.data);
      } else {
        loadedRecords.value = [...page.data];
      }
      nextPage.value = page.nextPageId ? { from: page.currentCount, nextPageId: page.nextPageId } : undefined;
    },
    { immediate: true }
  );

  const clearPage = () => {
    currentPage.value = undefined;
    if (nextPage.value) void dropPage({ pageId: nextPage.value.nextPageId });
    nextPage.value = undefined;
  };

  watch(
    () => toValue(collection)?.id,
    () => {
      loadedRecords.value = [];
      clearPage();
    }
  );

  watch([allFilters, dedupedSort], () => {
    clearPage();
  });

  const loadNextPage = () => {
    if (nextPage.value && !loading.value) currentPage.value = nextPage.value;
  };

  // Combine results from server and cache
  const list = computed<KBRecord[] | undefined>(() => {
    const collectionVal = toValue(collection);
    if (!collectionVal) return [];
    // Note: order of { ...r.data, id: r.id } is important because otherwise if the data object has an id it overwrites the record's actual id
    const restored = loadedRecords.value.map((r) => {
      const record = collectionVal.restoreRecord({
        ...r.data,
        id: r.id,
        createdAt: r.createdAt,
        updatedAt: r.updatedAt,
        createdBy: r.createdBy ?? null,
        updatedBy: r.updatedBy ?? null
      }) as KBRecord;
      record.external = r.external;
      return record;
    });
    return restored;
  });

  const addDelta = (recordCount: RecordCountResult | undefined): RecordCountResult | undefined =>
    recordCount && { count: recordCount.count + countDelta.value, approximate: recordCount.approximate };

  // Total count of records
  const totalCount = computed<RecordCountResult | undefined>(() => {
    if (!loading.value) {
      if (fullResult.value) {
        return { count: loadedRecords.value.length, approximate: false };
      }
      // check if all records loaded from paginated query
      if (result.value?.records && !result.value.records.full && !result.value.records.page.nextPageId && !hasExtraFilters.value) {
        return addDelta({ count: result.value.records.page.currentCount, approximate: false });
      }
    }
    return addDelta(cachedRecordCount.value);
  });

  const filteredCount = computed<RecordCountResult | undefined>(() => {
    if (!hasExtraFilters.value) return totalCount.value;
    // check if all records loaded
    if (!loading.value && result.value?.records && !result.value.records.full && !result.value.records.page.nextPageId) {
      return addDelta({ count: result.value.records.page.currentCount, approximate: false });
    }
    return addDelta(cachedFilteredRecordCount.value);
  });

  const refetch = async () => {
    loadedRecords.value = [];
    clearPage();
    await Promise.all([recordCountRefetch(), filteredRecordCountRefetch(), refetchRecordQuery({ ...pageVariables.value, pageRequest: undefined })]);
  };

  const handleRecordUpdate = async (event: RecordUpdateEvent) => {
    const idSet = new Set(event.recordIds);
    const data = event.recordData;
    switch (event.eventType) {
      case "RECORD_ADDED":
        // add new record iff we have all records loaded
        if (fullResult.value && data) {
          loadedRecords.value.push(...data);
        } else {
          // otherwise, don't bother refetching
          countDelta.value += idSet.size;
        }
        break;
      case "RECORD_UPDATED": {
        if (loadedRecords.value.some((r) => idSet.has(r.id))) {
          if (data) {
            const rMap = new Map(data.map((r) => [r.id, r]));
            // update loaded records in place
            loadedRecords.value = loadedRecords.value.map((r) => rMap.get(r.id) ?? r);
          } else {
            await refetch();
          }
        }
        break;
      }
      case "RECORD_DELETED":
        loadedRecords.value = loadedRecords.value.filter((r) => !idSet.has(r.id));
        countDelta.value -= idSet.size;
    }
  };

  return {
    list,
    loading: computed(() => loading.value || recordCountLoading.value || filteredRecordCountLoading.value),
    refetch,
    handleRecordUpdate,
    fullResult,
    loadNextPage,
    totalCount,
    filteredCount
  };
};

export type RecordOptionQueryResult = { recordOptions: { options: KRecordOption[]; more: boolean } };
const useRecordOptionsQuery = (collection: MaybeRef<Collection | undefined>, filters?: MaybeRef<string[]>, search?: MaybeRef<string | undefined>) =>
  useQuery<{ recordOptions: { options: KRecordOption[]; more: boolean } }>(
    gql`
      query getRecordOptions($collectionId: String!, $filters: [String!]!, $search: String) {
        recordOptions(collectionId: $collectionId, filters: $filters, search: $search) {
          options {
            id
            label
            collectionId
            secondaryLabel
          }
          more
        }
      }
    `,
    () => ({ collectionId: toValue(collection)?.id, filters: toValue(filters), search: toValue(search) }),
    () => ({ fetchPolicy: "cache-and-network", enabled: toValue(collection) !== undefined })
  );

/**
 * Get a record
 *
 * @param collectionId Collection ID
 * @param id Record ID
 */
const useRecordQuery = (collectionId: MaybeRef<string | undefined>, id: MaybeRef<string | undefined>) =>
  useQuery<{ record: ServerRecord }>(
    gql`
      query getRecord($id: String!, $collectionId: String!) {
        record(id: $id, collectionId: $collectionId) {
          ${fullRecord}
        }
      }
    `,
    () => ({ id: toValue(id), collectionId: toValue(collectionId) }),
    () => ({ enabled: toValue(collectionId) !== undefined && toValue(id) !== undefined, fetchPolicy: "cache-and-network" })
  );

const emptyRecords = new Set([undefined, "", "{}"]);
const useUniquenessViolations = (
  collection: MaybeRef<Collection | undefined>,
  serialisedRecord: MaybeRef<string | undefined>,
  recordId: MaybeRef<string | undefined>,
  fieldsToCheck?: Ref<string[]>
) =>
  useQuery<
    { recordUniquenessViolations: NonNullable<Collection["uniqueFieldRestrictions"]> },
    { input: { collectionId: string; record: string; recordId?: string; fieldsToCheck?: string[] } }
  >(
    gql`
      query getUniquenessViolations($input: UniquenessViolationInput!) {
        recordUniquenessViolations(input: $input) {
          fieldIds
          severity
        }
      }
    `,
    () => ({
      input: { collectionId: toValue(collection)!.id, record: toValue(serialisedRecord)!, recordId: toValue(recordId), fieldsToCheck: toValue(fieldsToCheck) }
    }),
    () => ({
      enabled:
        toValue(collection)?.id !== undefined &&
        !!toValue(collection)?.uniqueFieldRestrictions?.length &&
        (!fieldsToCheck || !!fieldsToCheck.value.length) &&
        !emptyRecords.has(toValue(serialisedRecord)),
      fetchPolicy: "no-cache"
    })
  );

export const recordQueries = {
  useRecordsQuery,
  useRecordOptionsQuery,
  useRecordQuery,
  useUniquenessViolations
};
