<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 140" class="kb-logo" alt="Kinabase">
    <g fill-rule="evenodd">
      <g id="text" fill="#2a2725" fill-rule="nonzero">
        <path
          d="M210.07 100L186.4 62.92l19.62-25.02h-12.78l-22.86 29.97V37.9h-10.44V100h10.44V83.44l9.18-11.79 18 28.35zm4.29 0h9.63V55h-9.63v45zm-.45-52.29h10.53V37.9h-10.53v9.81zM232.6 100h9.63V72.82c0-6.03 3.33-10.08 9.27-10.08 4.95 0 8.55 2.25 8.55 10.17V100h9.54V71.83c0-12.96-7.11-17.64-16.38-17.64-5.94 0-9.18 2.16-11.16 4.86V55h-9.45v45z" />
        <use xlink:href="#B" />
        <path
          d="M327.13 37.9h-9.27V100h9.27v-3.24c2.25 2.7 5.76 4.05 10.8 4.05 12.06 0 17.91-8.55 17.91-20.52v-5.67c0-13.23-6.93-20.43-17.91-20.43-4.77 0-8.37 1.35-10.8 4.32V37.9zm9.54 54.63c-7.02 0-9.54-5.49-9.54-12.51v-5.76c.09-7.38 3.33-11.7 9.54-11.7 6.48 0 9.54 4.41 9.54 12.24v5.22c0 7.02-2.61 12.51-9.54 12.51z" />
        <use xlink:href="#B" x="84.27" />
        <path
          d="M434.17 61.66c-3.51-5.58-9.36-7.47-16.74-7.47-10.98 0-16.83 5.4-16.83 13.41 0 9.45 7.2 13.05 15.75 14.13 7.02.9 9.36 1.98 9.36 5.67 0 3.15-2.88 4.95-7.83 4.95-5.94 0-8.64-1.44-10.71-4.23l-7.29 5.22c3.69 4.95 8.91 7.47 18.09 7.47 11.61 0 17.28-5.4 17.28-13.68 0-9.54-6.48-13.05-16.47-14.22-5.22-.63-8.55-1.8-8.55-5.58 0-3.24 2.97-4.59 7.02-4.59 5.4 0 7.65 1.26 9.81 4.05l7.11-5.13zm33.54 26.46c-1.8 2.79-3.96 4.41-9.27 4.41-7.11 0-9.81-5.49-9.81-12.51v-.18h26.91v-4.95c0-13.32-5.85-20.7-18.18-20.7-11.79 0-18.27 6.93-18.27 20.16v6.12c0 11.97 6.84 20.34 19.71 20.34 7.92 0 12.96-2.16 16.11-7.65l-7.2-5.04zm-10.35-25.56c5.49 0 8.19 3.33 8.55 9.9h-17.19c.45-6.12 3.06-9.9 8.64-9.9z" />
      </g>
      <g fill="#1488a9">
        <path
          d="M84.275 13.199l36.749 21.295c5.059 2.931 8.175 8.349 8.175 14.212v42.59c0 5.863-3.116 11.28-8.175 14.212l-36.749 21.295a16.3 16.3 0 0 1-16.351 0l-36.749-21.295C26.116 102.575 23 97.158 23 91.295v-42.59c0-5.863 3.116-11.28 8.175-14.212l36.749-21.295a16.3 16.3 0 0 1 16.351 0z"
          fill-opacity=".4" />
        <path
          d="M83.064 20.772l31.305 18.456c4.309 2.541 6.964 7.236 6.964 12.317v36.911c0 5.081-2.655 9.776-6.964 12.317l-31.305 18.456a13.68 13.68 0 0 1-13.928 0l-31.305-18.456c-4.309-2.541-6.964-7.236-6.964-12.317V51.544c0-5.081 2.655-9.776 6.964-12.317l31.305-18.456a13.68 13.68 0 0 1 13.928 0z" />
      </g>
      <path
        d="M70.012 99.551L62.66 86.818h0L53.903 71.65l-6.487-11.235a.8.8 0 0 1 .916-1.168l29.714 8.615a1.2 1.2 0 0 1 .831 1.439l-7.396 30.04a.8.8 0 0 1-1.47.209zm24-15L86.66 71.818h0L77.903 56.65l-6.487-11.235a.8.8 0 0 1 .916-1.168l29.714 8.615a1.2 1.2 0 0 1 .831 1.439l-7.396 30.04a.8.8 0 0 1-1.47.209z"
        fill="#f7f3f0" />
    </g>
    <g v-if="env === 'dev'" id="dev-rectange">
      <path
        d="M70.012 99.551L62.66 86.818h0L53.903 71.65l-6.487-11.235a.8.8 0 0 1 .916-1.168l29.714 8.615a1.2 1.2 0 0 1 .831 1.439l-7.396 30.04a.8.8 0 0 1-1.47.209zm24-15L86.66 71.818h0L77.903 56.65l-6.487-11.235a.8.8 0 0 1 .916-1.168l29.714 8.615a1.2 1.2 0 0 1 .831 1.439l-7.396 30.04a.8.8 0 0 1-1.47.209z"
        fill="#f7f3f0" />
      <use fill="#000" filter="url(#D)" xlink:href="#A" />
      <use fill="#f8b71e" xlink:href="#A" />
      <use fill="#000" filter="url(#C)" xlink:href="#A" />
      <path
        d="M94.697 113c4.274 0 7.303-2.767 7.303-10.133v-2.733C102 92.767 98.971 90 94.697 90H88v23h6.697zm0-3.333h-2.793V93.333h2.793c2.558 0 3.399 2.067 3.399 6.8v2.733c0 4.733-.841 6.8-3.399 6.8zM105 90v23h12v-3.333h-8.034v-6.9h7.179v-3.333h-7.179v-6.067H117V90zm13 0l6.67 23h3.626L135 90h-4.31l-4.207 16.567L122.276 90z"
        fill="#2a2725"
        fill-rule="nonzero" />
    </g>
    <g v-if="env === 'beta'" id="beta-rectange">
      <path
        d="M70.012 99.551L62.66 86.818h0L53.903 71.65l-6.487-11.235a.8.8 0 0 1 .916-1.168l29.714 8.615a1.2 1.2 0 0 1 .831 1.439l-7.396 30.04a.8.8 0 0 1-1.47.209zm24-15L86.66 71.818h0L77.903 56.65l-6.487-11.235a.8.8 0 0 1 .916-1.168l29.714 8.615a1.2 1.2 0 0 1 .831 1.439l-7.396 30.04a.8.8 0 0 1-1.47.209z"
        fill="#f7f3f0" />
      <use fill="#000" filter="url(#D)" xlink:href="#A" />
      <use fill="#14a965" xlink:href="#A" />
      <use fill="#000" filter="url(#C)" xlink:href="#A" />
      <path
        d="M84 90v21h6c4.34 0 6-2.861 6-6.026 0-2.648-.99-4.291-2.709-4.961 1.311-.67 2.184-2.404 2.184-4.565 0-3.622-2.126-5.448-6-5.448H84zm3.379 11.626h2.039c2.243 0 3.204.943 3.204 3.165 0 2.009-.874 3.165-2.883 3.165H87.38v-6.33zm0-8.583h1.806c2.417 0 2.913.974 2.913 2.617 0 1.735-.699 2.922-2.563 2.922H87.38v-5.539zM99 90v21h11v-3.043h-7.365v-6.3h6.581v-3.043h-6.581v-5.539H110V90zm12 0v3.074h4.73V111h3.54V93.074H124V90zm16.653 21l1.048-4.352h5.539l1.078 4.352H139l-5.808-21h-3.413L124 111h3.653zm3.862-15.674l1.976 8.278h-4.012l2.036-8.278z"
        fill="#fff"
        fill-rule="nonzero" />
    </g>
    <defs>
      <path
        id="B"
        d="M274.69 86.77c0 9 6.12 14.04 16.02 14.04 5.49 0 8.28-1.44 10.44-4.95V100h9.45V69.76c0-11.97-6.21-15.57-17.73-15.57-8.91 0-13.77 2.16-17.01 7.47l6.93 4.86c2.16-3.51 4.59-4.05 9.45-4.05 6.75 0 8.91 1.53 8.91 7.2v3.78h-9.72c-9.63 0-16.74 3.69-16.74 13.32zm26.46-3.78c0 7.83-3.51 9.63-9.36 9.63-4.59 0-7.65-1.71-7.65-5.85 0-3.96 2.34-5.94 8.01-5.94h9v2.16z" />
      <rect id="A" x="76" y="84" width="70.061" height="34" rx="9.362" />
      <filter id="D" x="-23.6%" y="-39.7%" width="147.1%" height="197.1%">
        <feOffset dy="3" in="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.6 0" />
      </filter>
      <filter id="C" x="-31.4%" y="-55.9%" width="162.8%" height="229.4%">
        <feGaussianBlur stdDeviation="8.5" in="SourceAlpha" />
        <feOffset dy="-7" />
        <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.179359703 0" />
      </filter>
    </defs>
  </svg>
</template>

<script setup lang="ts">
const env = window.__ssr__?.env;
</script>

<style scoped>
.kb-logo {
  height: 55px;
}

.kb-logo #text {
  fill: var(--k-color);
}
</style>
