import dayjs from "dayjs";

import type { Dayjs } from "dayjs";

export interface ISimpleObject {
  deepEquals(other: SimpleObject): boolean;
  deepCopy(): ISimpleObject;
}
export type SimpleObject =
  | undefined
  | null
  | string
  | number
  | boolean
  | Dayjs
  | SimpleObject[]
  | readonly SimpleObject[]
  | { [key: string]: SimpleObject }
  | ISimpleObject;

/** Helper wrapper since Array.isArray does not narrow correctly with readonly arrays */
export const isArray = <T>(toCheck: T | T[] | readonly T[]): toCheck is T[] | readonly T[] => Array.isArray(toCheck);

export const isISimpleObject = (toCheck: object): toCheck is ISimpleObject => "deepEquals" in toCheck && typeof toCheck.deepEquals === "function";

export const isSimpleObject = (a: unknown): a is SimpleObject => {
  if (typeof a !== "object") {
    return true;
  }
  if (a === null) {
    return true;
  }
  if (Array.isArray(a)) {
    return a.every(isSimpleObject);
  }
  if (dayjs.isDayjs(a)) {
    return true;
  }
  if (isISimpleObject(a)) {
    return true;
  }
  return Object.getPrototypeOf(a) === Object.prototype && Object.values(a).every(isSimpleObject);
};

export const deepCopy = <C extends SimpleObject>(toCopy: C): C => {
  if (toCopy === null) {
    return toCopy;
  }
  if (isArray(toCopy)) {
    return toCopy.map(deepCopy) as C;
  }
  if (typeof toCopy === "object") {
    if (dayjs.isDayjs(toCopy)) {
      return toCopy;
    }
    if (isISimpleObject(toCopy)) {
      return toCopy.deepCopy() as C;
    }
    return Object.fromEntries(
      Object.entries(toCopy)
        .filter(([_, value]) => value !== undefined)
        .map(([key, value]) => [key, deepCopy(value)])
    ) as C;
  }
  return toCopy;
};
