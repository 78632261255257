import { refine } from "../NullHelpers";
import { BiMap } from "../maps/BiMap";

// sort by ASCII rather than locale
// (.sort() sorts strings like this by default, but not with objects)
export const stringSorted = <T>(arr: T[], map: (item: T) => string) =>
  [...arr].sort((a, b) => {
    const sa = map(a);
    const sb = map(b);
    if (sa === sb) {
      return 0;
    }
    return sa < sb ? -1 : 1;
  });

/**
 * Implementation of fractional indexing using alphanumeric strings see https://www.figma.com/blog/realtime-editing-of-ordered-sequences/
 *
 * Explanation (using numbers instead of alphanumeric characters for readability):
 *
 * Consider all sort indexes to be between 0 and 1 (exclusive) when inserting a new item between two existing items, the sort index is the average of the two
 * e.g. inserting between 0.1 and 0.2 gives 0.15
 *
 * The start and end of the list have "indexes" of 0 and 1 respectively e.g. inserting at the start of the list (before 0.1) gives 0.05
 *
 * To save space, the 0. is implied, so 0.05 becomes 05
 *
 * To save even more space, we round towards the middle when inserting at the start or end e.g. repeatedly inserting at the start gives 5, 3, 2, 1, 05 rather
 * than the 5, 2, 1, 05 you'd get with floor
 */

// Alphanumeric characters in ascending order
const alphaNumerics = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const alphaNumericMap = new BiMap<string, number>();
for (let i = 0; i < alphaNumerics.length; i++) {
  alphaNumericMap.set(alphaNumerics[i], i);
}
const max = alphaNumerics.length;

// Get the sort order number for a character in a sortable string
const getN = (s: string | undefined, idx: number, top: boolean): number => {
  const char = s?.charAt(idx);
  if (char === undefined || char === "") {
    return top ? 0 : max;
  }
  const n = alphaNumericMap.get(char);
  if (n === undefined) {
    throw new Error(`Invalid character ${char} in sortable string ${s}`);
  }
  return n;
};
const halfMax = Math.floor(max / 2);

/**
 * Get sort order for a new item to be inserted between two existing items
 *
 * @param above Item above the new item
 * @param lower Item below the new item
 * @param avoid List of existing sort orders to avoid collisions (e.g. avoiding completed tasks in a task list)
 * @returns UNIQUE sort order for the new item
 */
export const getSortOrder = (above: string | undefined, lower: string | undefined, avoid?: string[]): string => {
  const result: string[] = [];
  if (avoid) {
    for (const u of avoid) {
      if ((above === undefined || u > above) && (lower === undefined || u < lower)) {
        // reduce the range of possible sort orders to avoid collisions
        lower = u;
      }
    }
  }
  if (above === lower && above !== undefined) {
    throw new Error("Cannot insert between two identical sort orders");
  }
  for (let i = 0; ; i++) {
    const a = getN(above, i, true);
    const l = getN(lower, i, false);
    if (a === l || a === l - 1) {
      result.push(alphaNumericMap.getBack(a)!);
    } else {
      const targetFloat = (a + l) / 2;
      // round towards the middle to optimise string length when inserting at front or back
      const targetIndex = targetFloat > halfMax ? Math.floor(targetFloat) : Math.ceil(targetFloat);
      result.push(alphaNumericMap.getBack(targetIndex)!);
      break;
    }
  }
  return result.join("");
};

export const defaultSortOrder = getSortOrder(undefined, undefined);

/**
 * Get sort order for a new item to be inserted between two existing items
 *
 * @param arr Array of items (the ones being reordered, should be sorted by sort order)
 * @param map Function to get the sort order of an item
 * @param oldIndex Index of the item being moved
 * @param newIndex Index of the item after it's been moved
 * @param avoid List of existing sort orders to avoid collisions (e.g. avoiding completed tasks in a task list)
 */
export const getMovedSortOrder = <T>(arr: T[], map: (item?: T) => string | undefined, indexes: { old: number; new: number }, avoid?: T[]) => {
  // adjust for the effect of removing the task from the list
  const delta = indexes.new < indexes.old ? -1 : 0;
  const above = map(arr[indexes.new + delta]);
  const below = map(arr[indexes.new + delta + 1]);
  return getSortOrder(above, below, avoid && refine(avoid, map));
};

export const getBottomSortOrder = <T>(arr: T[], map: (item?: T) => string | undefined) => {
  // arr might not be sorted by sort order, so we need to find the max
  let maxOrder: string | undefined = undefined;
  for (const item of arr) {
    const order = map(item);
    if (order === undefined) {
      continue;
    }
    if (maxOrder === undefined || order > maxOrder) {
      maxOrder = order;
    }
  }
  return getSortOrder(maxOrder, undefined);
};
