<template>
  <div class="login-container">
    <div class="pt-2">
      <div class="login-box">
        <div class="text-center pt-2 pb-4">
          <theme-aware-logo />
        </div>
        <div v-if="formType === 'microsoft'">
          <div class="text-center pt-3 mt-1">
            <k-button variant="primary" label="Sign in with Microsoft" size="md" :loading icon="fab-microsoft" @click="signInWithMicrosoft" />
          </div>
          <div class="text-center mb-4 mt-3">or sign in with <a href="#" @click="formType = 'email'">email and password</a></div>
        </div>
        <form v-else @submit.prevent="signInWithEmail">
          <k-input-config placeholder="user@example.com" :status="emailFieldState">
            <k-input v-model="email" label="Email" autofocus @keyup.enter="signInWithEmail" />
          </k-input-config>
          <k-input-config :status="passwordFieldState">
            <k-input-password v-model="password" label="Password" type="password" :show-password-strength="false" @keyup.enter="signInWithEmail" />
          </k-input-config>
          <p v-if="message" class="error-message text-danger pt-2 mb-n1">{{ message }}</p>
          <div class="text-center pt-3 mt-1">
            <k-button variant="primary" label="Sign in" size="md" :loading @click="signInWithEmail" />
          </div>
          <div class="text-center mt-3">or sign in with <a :href="microsoftUrl" @click="lastSignInMethod = 'microsoft'">Microsoft</a></div>
        </form>
      </div>
      <div class="text-center login-footer mb-5">
        <router-link to="/forgot-password">Forgot password?</router-link>
        <router-link :to="{ path: '/sign-up', query: email ? { src: 'login', email: email } : { src: 'login' } }" class="ms-4">Sign up</router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { RouterLink, useRoute } from "vue-router";

import { useLocalStorage } from "@vueuse/core";

import KButton from "@ui/button/KButton.vue";
import KInput from "@ui/inputs/strings/KInput.vue";
import KInputPassword from "@ui/inputs/strings/KInputPassword.vue";
import KInputConfig from "@ui/inputs/KInputConfig.vue";
import type { InputStatus } from "@ui/inputs/inputConfig";

import { useSignIn } from "@/services/auth";
import ThemeAwareLogo from "@/components/graphics/ThemeAwareLogo.vue";
import { storageKeys } from "@/consts/storageKeys";

const email = ref("");
const password = ref("");
const message = ref("");
const emailFieldState = ref<InputStatus>("NONE");
const passwordFieldState = ref<InputStatus>("NONE");
type SignInMethod = "email" | "microsoft";
const lastSignInMethod = useLocalStorage<SignInMethod | undefined>(storageKeys.auth.lastSignInMethod, undefined);

const { signIn, loading } = useSignIn();

const signInWithEmail = async () => {
  lastSignInMethod.value = "email";
  if (!password.value) {
    emailFieldState.value = "NONE";
    passwordFieldState.value = "INVALID";
    message.value = "Password is required";
    return;
  }
  // If no response from server in 10 seconds, show error message
  const timeout = setTimeout(() => {
    if (!loading.value) {
      return;
    }
    loading.value = false;
    message.value = "Something went wrong. Please try again.";
  }, 10000);

  const result = await signIn(email.value, password.value);
  if (result) {
    message.value = "";
    emailFieldState.value = "NONE";
    passwordFieldState.value = "NONE";
  } else {
    message.value = "Email address or password is incorrect";
    emailFieldState.value = "INVALID";
    passwordFieldState.value = "INVALID";
  }

  clearTimeout(timeout);
};

const route = useRoute();
const formType = ref(lastSignInMethod.value ?? "email");
const microsoftUrl = computed(() => `/api/auth/sign-in/microsoft?redirect=${encodeURIComponent(route.fullPath)}`);

const signInWithMicrosoft = () => {
  lastSignInMethod.value = "microsoft";
  // navigate to Microsoft sign in page
  window.location.replace(microsoftUrl.value);
};
</script>

<style scoped>
.login-container {
  display: grid;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-box {
  background: var(--k-background);
  padding: 1.5rem 1rem;
  margin: 0 auto 2rem;
  width: 100%;
  box-shadow: 0px 3px 80px -30px rgba(20, 137, 169, 0.7);
}

form {
  min-height: 220px;
}

@media screen and (min-width: 500px) {
  .login-box {
    max-width: 350px;
    padding: 1.5rem;
    border-radius: 6px;
    margin: 0 auto 4rem;
    border: 1px solid var(--k-border);
  }
}

.login-box .error-message {
  font-size: 12px;
}

.login-footer {
  font-size: 0.8rem;
}
</style>
