<template>
  <k-input v-model="value" :label />
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from "vue";

import KInput from "@ui/inputs/strings/KInput.vue";
import { useInputConfig, mergeInputConfig } from "@ui/inputs/inputConfig";

import { fromFormatString, toFormatString } from "./convertInput";

import { hasCircularReference } from "@data/expressions/helpers";
import { expressionParser } from "@data/expressions/parser";
import type { ValidationIssues } from "@data/validation/Validation";
import type { KEntity } from "@data/data/KEntity";

import { useKContext } from "@/expressions/context";
import { isComputedField } from "@/expressions/computed";

const props = defineProps<{
  /** Value of the input */
  modelValue?: string;
  /** Label for the input */
  label?: string;
  /** Current field ID (required to detect circular references) */
  fieldId?: string;
  /** Collection to write formulas for */
  collection?: KEntity;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: string | undefined): void;
  (event: "update:hasExpressionErrors", value: boolean): void;
}>();

const value = ref<string>("");

const context = useKContext();
// load the collection from the context if not provided (and need different name to avoid conflict with prop)
const actualCollection = computed(() => props.collection ?? context.value.entity);

watchEffect(() => {
  value.value = props.modelValue ? toFormatString(props.modelValue, actualCollection.value) : "";
});

const parsed = computed<{ valid?: string; issues?: ValidationIssues }>(() => {
  try {
    if (value.value) {
      const expression = fromFormatString(value.value, actualCollection.value);
      if (
        props.fieldId &&
        actualCollection.value &&
        hasCircularReference(
          expression,
          props.fieldId,
          actualCollection.value.fields.filter((f) => isComputedField(f))
        )
      ) {
        return {
          issues: [
            {
              message: "Formula contains a circular reference",
              severity: "error"
            }
          ]
        };
      }
      expressionParser.parseString(expression, {
        entity: actualCollection.value
      });
      return {
        valid: expression
      };
    }
    return {};
  } catch (e) {
    if (e instanceof Error) {
      return {
        issues: [
          {
            message: e.message,
            severity: "error"
          }
        ]
      };
    }
  }
  return {};
});

const config = useInputConfig();

mergeInputConfig(
  computed(() => ({
    issues: config.value.issues.concat(parsed.value.issues ?? [])
  }))
);
watch(
  () => parsed.value.valid,
  (newValue) => {
    if (newValue) {
      emit("update:modelValue", newValue);
    }
    emit("update:hasExpressionErrors", !!parsed.value.issues?.length);
  }
);
watch(value, (newValue) => {
  if (newValue === "") {
    emit("update:modelValue", undefined);
  }
});
</script>
