import { BooleanExpression } from "./expressions";

import type { KContext } from "./context";
import type { StringExpression, InvertableExpression } from "./expressions";

export class IsBlankExpression extends BooleanExpression implements InvertableExpression {
  constructor(private readonly target: StringExpression) {
    super();
  }

  evaluate(context: KContext): boolean {
    const target = this.target.evaluate(context);
    return target === undefined || target === "";
  }

  display(context: KContext): string {
    return `${this.target.display(context)} is blank`;
  }

  displayInverted(context: KContext): string {
    return `${this.target.display(context)} is not blank`;
  }
}
