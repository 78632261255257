<template>
  <transition name="fade" :duration="fadeDuration">
    <div v-if="showContent" class="logged-out-container" :class="{ register: isRegistrationForm }">
      <div class="logged-out-bg-container">
        <div class="logged-out-bg" :class="env && `bg-${env}`"></div>
      </div>
      <div class="logged-out-content">
        <div class="w-100 flex-grow-1 position-relative d-grid">
          <transition name="fade-box" :duration="fadeDuration">
            <template v-if="isLoaded">
              <slot></slot>
            </template>
          </transition>
        </div>
        <div class="logged-out-footer py-3">
          <span>Powered by <external-link url="https://www.cambridgekinetics.com">Cambridge Kinetics</external-link></span>
          <span class="px-4 px-md-5 d-none d-sm-inline"></span>
          <br class="d-inline d-sm-none" />
          <span class="pe-4"><external-link url="https://www.kinabase.com/updates">What's new</external-link></span>
          <span class="pe-4"><external-link url="https://www.kinabase.com/support">Help & support</external-link></span>
          <span><external-link url="https://www.kinabase.com/terms">Terms of use</external-link></span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

import ExternalLink from "@/components/ExternalLink.vue";
import { TransitionDurations } from "@/consts/transitions";
import { useAuth } from "@/services/auth";

const isLoaded = ref(false);
const showContent = ref(true);
const { isAuthenticated } = useAuth();
const route = useRoute();
const isRegistrationForm = computed(() => route.name === "SignUp");

// Fade in the login screen after a short delay to ensure animation renders
setTimeout(() => (isLoaded.value = true), TransitionDurations.SIGN_IN_ENTRANCE_DELAY);

// After user logs in, delay hiding the login screen div for a few milliseconds to give
// content loading a head start and to give time for login screen to animate out with a cross-fade
watch(isAuthenticated, () => {
  if (isAuthenticated.value === true) {
    isLoaded.value = false;
    setTimeout(() => (showContent.value = false), TransitionDurations.SIGN_IN_EXIT_DELAY);
  }
});

const fadeDuration = TransitionDurations.SIGN_IN_FADE_DURATION;

const env = window.__ssr__?.env;
</script>

<style>
.logged-out-container {
  top: 0;
  right: 0;
  left: 0;
  min-height: 100dvh;
  z-index: 100001;
}

.logged-out-content {
  z-index: 100005;
  position: absolute;
  width: 100%;
  min-height: 100dvh;
  flex-direction: column;
  display: flex;
}

.logged-out-bg-container {
  content: " ";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: var(--k-background);
  z-index: 10001;
}

.logged-out-bg {
  content: " ";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: transparent;
  background-image: url("@/assets/backdrop.svg");
  background-position: 50% 50%;
  background-size: cover;
  z-index: 10001;
}
.logged-out-bg.bg-beta {
  background-image: url("@/assets/backdrop-beta.svg");
}
.logged-out-bg.bg-dev {
  background-image: url("@/assets/backdrop-dev.svg");
}

.logged-out-footer {
  text-align: center;
  font-size: 0.8rem;
  color: var(--k-color-secondary);
  width: 100%;
}

.logged-out-footer a {
  text-decoration: underline;
  text-underline-offset: 2px;
  color: var(--k-color-secondary);
}

.fade-leave-active {
  transition: background-color 0.25s ease-in;
}

.fade-leave-to,
.fade-leave-to::before {
  background: transparent;
}
.fade-leave-to .logged-out-footer {
  opacity: 0;
}

.fade-box-enter-active > div {
  transition:
    opacity 0.1s ease-out,
    transform 0.2s ease-out;
}

.fade-box-leave-active > div {
  transition:
    opacity 0.25s ease-in,
    transform 0.35s ease-in;
}

.fade-box-enter-from > div,
.fade-leave-to .logged-out-bg {
  opacity: 0;
  box-shadow: none;
  transform: scale(0.96) translateY(-5px);
}

.fade-box-leave-to > div,
.fade-leave-to .logged-out-bg {
  opacity: 0;
  box-shadow: none;
  transform: scale(1.3) translateY(5px);
}
</style>
