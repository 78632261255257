<template>
  <k-modal :id="modalId" v-model:show="visible" :title="modalTitle" :loading="saving" :static-backdrop="true" @hidden="cancelForm">
    <k-input-select v-model="editedActivity.type" label="Type" :options="activityTypeOptions" required />
    <k-input-rich-text v-model="editedActivity.content" label="Content" :rows="mode === 'ADD' ? 2 : undefined" />

    <div v-if="mode == 'ADD' && showFollowUpTasks && !props.hideTasks" class="mt-3 mb-n4">
      <k-label v-if="tasks.length > 0" label="Follow-up tasks" />
      <k-task-list
        :people="badges"
        :tasks
        allow-inline-editing
        allow-adding
        show-due-dates
        add-task-label="Add follow-up task..."
        hide-expand
        @task-added="addTask"
        @name-changed="onNameChange"
        @due-date-changed="onDueDateChange"
        @assignee-added="onAssigneeAdded"
        @assignee-removed="onAssigneeRemoved"
        @description-changed="onDescriptionChange"
        @task-removed="onTaskRemoved" />
    </div>

    <template #footer>
      <k-button variant="secondary" icon="calendar-clock" title="Change date" class="me-auto" :label="timestampDisplayValue" @click="showTimePicker" />
      <k-context-menu ref="timePickerMenu" :items="timePickerOptions" />

      <div v-if="mode === 'ADD'" class="pe-2">
        <k-input-boolean v-model="createMore" class="create-more pt-1" inline-label="Add another?" size="sm" :hide-label="true" />
      </div>

      <k-button variant="secondary" label="Cancel" @click="cancelForm" />
      <k-button variant="primary" :label="mode === 'ADD' ? 'Add' : 'Save'" :loading="saving" @click="submitForm" />
    </template>
  </k-modal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { useTimeAgo } from "@vueuse/core";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";

import KInputBoolean from "@ui/inputs/boolean/KInputBoolean.vue";
import KButton from "@ui/button/KButton.vue";
import KContextMenu from "@ui/context-menu/KContextMenu.vue";
import KModal from "@ui/modal/KModal.vue";
import type { KContextMenuElement } from "@ui/context-menu/ContextMenuElement";
import type { ContextMenuItem } from "@ui/context-menu/ContextMenuItem";
import type { SelectItem } from "@ui/inputs/select/SelectItem";
import KInputSelect from "@ui/inputs/select/KInputSelect.vue";
import KInputRichText from "@ui/inputs/strings/KInputRichText.vue";
import KTaskList from "@ui/tasks/KTaskList.vue";
import KLabel from "@ui/label/KLabel.vue";

import type { Activity } from "@data/data/Activity";
import type { Collection } from "@data/data/Collection";
import { toPersonBadge, type PersonBadge } from "@data/fields/TableCell";
import type { KTask, KTaskToAdd } from "@data/data/Task";
import { filterEmpty } from "@data/helpers/NullHelpers";
import { getBottomSortOrder } from "@data/helpers/data/Reordering";

import { ActivityAPI } from "@/api/activity";
import { getActivityStringContent } from "@/helpers/formatActivityContent";
import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { usePeopleStore } from "@/api/stores/usePeopleStore";
import { useHasCollectionPermission } from "@/helpers/permissions";

import type { Dayjs } from "dayjs";

type UserEditableActivity = Activity & { content: string };

const props = defineProps<{
  /** Entity for the record (either ID or collection) */
  collection: Collection;
  /** ID of the record */
  recordId?: string;
  /** IDs of the records */
  recordIds?: string[];
  /** Activity to edit */
  activity?: Activity;
  /** Default type for the activity */
  defaultType?: string;
  /** Show the modal dialog */
  show: boolean;
  /** Show follow-up tasks */
  hideTasks?: boolean;
}>();

const emit = defineEmits<{
  (event: "saved", id?: string): void;
  (event: "update:show", value: boolean): void;
}>();

type FormMode = "ADD" | "EDIT";

const store = useCollectionStore();
const activityTypes = store.useCollectionActivityTypes(computed(() => props.collection));
const editableActivityTypes = computed(() => activityTypes.value.filter((x) => x.isEditable && x.isEnabled));

// Setup state of modal dialog
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const visible = ref(props.show);
const editedActivity = ref<Partial<UserEditableActivity>>({});
const initialValue = ref<UserEditableActivity>();
const timestamp = ref(dayjs());

const tasks = ref<KTask[]>([]);

const activityTypeOptions = computed<SelectItem[]>(() => editableActivityTypes.value.map((x) => ({ value: x.key, label: x.label, variant: x.colour })));
const defaultActivityType = computed(() => props.defaultType ?? editableActivityTypes.value.at(0)?.key);

const resetForm = () => {
  editedActivity.value = initialValue.value ? { ...initialValue.value } : { type: defaultActivityType.value };
  tasks.value = [];
};

watch(
  () => props.show,
  () => {
    initialValue.value = props.activity ? { ...props.activity, content: getActivityStringContent(props.activity) } : undefined;
    timestamp.value = dayjs(props.activity?.timestamp ?? new Date());
    resetForm();
    visible.value = props.show;
  },
  { immediate: true }
);

// Set state of form based on whether an entityId is provided
const valid = ref(true);

const mode = computed<FormMode>(() => (props.activity ? "EDIT" : "ADD"));
const modalTitle = computed(() => (mode.value === "EDIT" ? "Edit" : "Add") + " activity");

const { mutate: addMutation, loading: isAdding } = ActivityAPI.addActivities();
const { mutate: updateMutation, loading: isUpdating } = ActivityAPI.updateActivity();

const saving = computed(() => isAdding.value || isUpdating.value);

const collectionId = computed(() => props.collection.id);
const collection = computed(() => props.collection);

const modalId = uuid();

// Handle form submission
const createMore = ref(false);
const submitForm = async () => {
  if (!valid.value) return;

  // handle both single and multiple record IDs
  let inputRecordIds: string[];
  if (props.recordIds) inputRecordIds = props.recordIds;
  else if (props.recordId) inputRecordIds = [props.recordId];
  else return;

  if (mode.value === "ADD") {
    const result = await addMutation({
      input: {
        collectionId: collectionId.value,
        recordIds: inputRecordIds,
        content: editedActivity.value.content ?? "",
        type: editedActivity.value.type ?? editableActivityTypes.value[0].key,
        timestamp: timestamp.value,
        tasks: tasks.value.map((t) => ({
          name: t.name,
          description: t.description,
          assignees: t.assignees ? filterEmpty(t.assignees.map((x) => x.id)) : undefined,
          dueDate: t.dueDate?.toDate()
        }))
      }
    });
    if (result?.errors) {
      return;
    }
  } else {
    if (!props.activity?.id) return;
    const result = await updateMutation({
      input: {
        content: editedActivity.value.content ?? props.activity.content,
        timestamp: timestamp.value,
        type: editedActivity.value.type ?? props.activity.type,
        id: props.activity.id
      }
    });
    if (result?.errors) return;
  }

  if (createMore.value) {
    resetForm();
  } else {
    emit("saved", inputRecordIds[0]); // depending on how this is used, we may need to update this to that it emits the correct record ID(s)
    visible.value = false;
    emit("update:show", visible.value);
  }
};

const cancelForm = () => {
  resetForm();
  visible.value = false;
  emit("update:show", visible.value);
};

const timestampTimeAgo = useTimeAgo(computed(() => timestamp.value.toDate()));
const timestampDisplayValue = computed(() => (timestampTimeAgo.value === "just now" ? "Now" : timestampTimeAgo.value));

const setTimestampToNearest15Mins = (date: Dayjs) => {
  // Round down to nearest 15 minutes, to make the display look tidier when using 'hours ago'
  // User can always override using the time picker
  timestamp.value = date.minute(Math.floor(date.minute() / 15) * 15);
};

const timePickerMenu = ref<KContextMenuElement<Date>>();
const timePickerOptions: ContextMenuItem<Date>[] = [
  {
    label: "Select date/time",
    type: "date-time-picker",
    value: () => timestamp.value,
    setValue: (_record, value) => {
      if (value?.isValid()) timestamp.value = value;
    }
  },
  { type: "divider" },
  {
    label: "Now",
    onClick: () => {
      timestamp.value = dayjs();
    }
  },
  {
    label: "1 hour ago",
    onClick: () => {
      setTimestampToNearest15Mins(dayjs().subtract(1, "hour"));
    }
  },
  {
    label: "2 hours ago",
    onClick: () => {
      setTimestampToNearest15Mins(dayjs().subtract(2, "hours"));
    }
  },
  {
    label: "4 hours ago",
    onClick: () => {
      setTimestampToNearest15Mins(dayjs().subtract(4, "hours"));
    }
  }
];

const showTimePicker = (evt: MouseEvent) => {
  timePickerMenu.value?.show(evt, undefined, { alignment: "start", direction: "bottom" });
};

/** Tasks */
const hasTaskWritePermission = useHasCollectionPermission(collection, "taskWrite");
const showFollowUpTasks = computed(() => collection.value.tasks?.isEnabled && hasTaskWritePermission.value);

const peopleStore = usePeopleStore();

const badges = computed(() => peopleStore.peopleList.map(toPersonBadge));

const addTask = (task: KTaskToAdd) => {
  const _recordId = props.recordIds ? props.recordIds[0] : props.recordId;
  if (_recordId) {
    tasks.value.push({
      name: task.name,
      description: task.description,
      dueDate: task.dueDate,
      assignees: task.assignees,
      collectionId: collectionId.value,
      recordId: _recordId,
      status: "NOT_STARTED",
      id: uuid(),
      /** This is used to order adding on the client side, not actually passed to server side. */
      sortOrder: getBottomSortOrder(tasks.value, (t) => t?.sortOrder)
    });
  }
};

const onTaskRemoved = (task: KTask) => {
  tasks.value = tasks.value.filter((t) => t.id !== task.id);
};

const onNameChange = (task: KTask, name: string) => {
  task.name = name;
};

const onDueDateChange = (task: KTask, dueDate: Dayjs | undefined) => {
  task.dueDate = dueDate;
};

const onAssigneeAdded = (task: KTask, assignee: PersonBadge) => {
  if (!task.assignees) task.assignees = [];
  task.assignees.push(assignee);
};

const onAssigneeRemoved = (task: KTask, assignee: PersonBadge) => {
  task.assignees = task.assignees?.filter((a) => a.id !== assignee.id);
};

const onDescriptionChange = (task: KTask, description: string) => {
  task.description = description;
};
</script>

<style scoped>
:deep(.create-more label) {
  font-size: 0.8rem;
  margin: -0.1rem 0 0 -0.25rem;
  padding: 0;
  color: var(--k-color-secondary);
}
</style>
