import { SimpleField } from "../basic/SimpleFields";

import type { JsonSerialisable } from "../../helpers/serialisation/TypedJSON";
import type { KFieldSetup } from "../KField";

export class UnknownField<R extends JsonSerialisable = JsonSerialisable> extends SimpleField<R> {
  readonly type = "unknown";

  constructor(
    setup: KFieldSetup | string,
    private typeValidator?: (value: unknown) => value is R
  ) {
    super(setup);
  }

  getSortableValue() {
    return "";
  }

  getDisplayValue(): string {
    return "";
  }

  validateType(value: unknown): value is R {
    return this.typeValidator?.(value) ?? true;
  }
}
