/** A readonly map that lazily creates values for keys. */
export class LazyMap<K, V> {
  protected map: Map<K, V> = new Map();

  private keyFn: (k: K) => V;

  constructor(dft: ((k: K) => V) | V) {
    if (typeof dft === "function") {
      // need cast here as V could be a function if you're really trying to break it
      this.keyFn = dft as (k: K) => V;
    } else {
      this.keyFn = () => dft;
    }
  }

  get(k: K): V {
    let v = this.map.get(k);
    if (v === undefined) {
      v = this.keyFn(k);
      this.map.set(k, v);
    }
    return v;
  }

  clear() {
    this.map.clear();
  }
}
