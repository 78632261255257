<template>
  <k-input v-model="value" :label />
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from "vue";

import KInput from "@ui/inputs/strings/KInput.vue";
import { useInputConfig, mergeInputConfig } from "@ui/inputs/inputConfig";

import { fromFormula, toFormula } from "./convertInput";

import { hasCircularReference } from "@data/expressions/helpers";
import { expressionParser } from "@data/expressions/parser";
import type { MetricUnit } from "@data/units/units";
import type { ValidationIssues } from "@data/validation/Validation";
import type { KEntity } from "@data/data/KEntity";
import { deepEquals } from "@data/helpers/manipulation/Comparison";
import { toPlural } from "@data/helpers/strings/Plurals";

import { useKContext } from "@/expressions/context";
import { isComputedField } from "@/expressions/computed";

import type { Formula } from "./formula";

const props = defineProps<{
  /** Value of the input */
  modelValue?: Formula;
  /** Label for the input */
  label: string;
  /** Current field ID (required to detect circular references) */
  fieldId?: string;
  /** Collection to write formulas for */
  collection?: KEntity;
  /** Target unit for the formula */
  targetUnit?: MetricUnit;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: Formula | undefined): void;
  (event: "update:hasExpressionErrors", value: boolean): void;
}>();

const value = ref<string>("= ");

const context = useKContext();
// load the collection from the context if not provided (and need different name to avoid conflict with prop)
const actualCollection = computed(() => props.collection ?? context.value.entity);

watchEffect(() => {
  if (actualCollection.value) {
    value.value = props.modelValue ? "= " + toFormula(props.modelValue.expression, actualCollection.value) : "= ";
  }
});

const parsed = computed<{ valid?: string; unit?: MetricUnit; issues?: ValidationIssues }>(() => {
  if (!actualCollection.value) {
    console.error("No collection provided for formula input");
    return {
      issues: [
        {
          message: "No collection provided for formula input",
          severity: "error"
        }
      ]
    };
  }
  try {
    if (value.value.startsWith("= ")) {
      const expression = fromFormula(value.value.slice(2), actualCollection.value, true);
      if (
        props.fieldId &&
        hasCircularReference(
          expression,
          props.fieldId,
          actualCollection.value.fields.filter((f) => isComputedField(f))
        )
      ) {
        return {
          issues: [
            {
              message: "Formula contains a circular reference",
              severity: "error"
            }
          ]
        };
      }
      const parsedExpression = expressionParser.parseNumber(expression, { entity: actualCollection.value });
      if (props.targetUnit && !deepEquals(parsedExpression.unit?.dimensions, props.targetUnit.dimensions)) {
        return {
          issues: [
            {
              message: parsedExpression.unit
                ? `Formula should return a value in ${toPlural(props.targetUnit.name)}, but returns a value in ${toPlural(parsedExpression.unit.name)}`
                : `Formula should return a value in ${toPlural(props.targetUnit.name)}, but returns a unitless value`,
              severity: "error"
            }
          ]
        };
      }
      return {
        valid: expression,
        unit: parsedExpression.unit
      };
    }
    return {};
  } catch (e) {
    if (e instanceof Error) {
      return {
        issues: [
          {
            message: toFormula(e.message, actualCollection.value),
            severity: "error"
          }
        ]
      };
    }
  }
  return {};
});

watchEffect(() => {
  if (!value.value.startsWith("= ")) {
    value.value = "= ";
  }
});
const config = useInputConfig();

mergeInputConfig(
  computed(() => ({
    issues: config.value.issues.concat(parsed.value.issues ?? [])
  }))
);

watch(
  () => parsed.value.valid,
  (newValue) => {
    if (newValue) {
      emit("update:modelValue", { expression: newValue, unit: parsed.value.unit?.expressionSymbol });
    }
    emit("update:hasExpressionErrors", !!parsed.value.issues?.length);
  }
);
</script>
