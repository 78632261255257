import dayjs from "dayjs";

import { toJson } from "../helpers/serialisation/TypedJSON";
import { LazyMap } from "../helpers/maps/LazyMap";
import { toPlural } from "../helpers/strings/Plurals";

import { isAccessible } from "./accessors";
import { BooleanConstant } from "./constants";
import { expressionParser } from "./parser";
import { BulkData } from "./data";
import { InsufficientContextError, type KContext } from "./context";
import { AndExpression, OrExpression } from "./operators";

import type { MetricUnit } from "../units/units";
import type { DatetimePrecision } from "../fields/basic/DatetimeField";
import type { BooleanExpression, Expression, ExpressionData } from "./expressions";
import type { KField } from "../fields/KField";
import type { ComputedField } from "../fields/computed/ComputedField";
import type { Dayjs } from "dayjs";

const falseExpression = new BooleanConstant(false);

const trueExpression = new BooleanConstant(true);
export const safeParseBooleanExpression = (expression: string, context: KContext, defaultTo: boolean): BooleanExpression => {
  try {
    return expressionParser.parseBoolean(expression, context);
  } catch (e) {
    if (e instanceof InsufficientContextError) {
      console.warn(e.message);
      return defaultTo ? trueExpression : falseExpression;
    }
    throw e;
  }
};

export const safeEvaluateExpression = <E extends Expression>(
  expression: E,
  context: KContext,
  defaultTo: ReturnType<E["evaluate"]>,
  warn = true
): ReturnType<E["evaluate"]> => {
  try {
    return expression.evaluate(context) as ReturnType<E["evaluate"]>;
  } catch (e) {
    if (e instanceof InsufficientContextError) {
      if (warn) console.warn(e.message);
      return defaultTo;
    }
    throw e;
  }
};

export const combineBooleanExpressions = (expressions: string[], context: KContext, operator: "and" | "or"): BooleanExpression | undefined => {
  switch (expressions.length) {
    case 0:
      return undefined;
    case 1:
      return expressionParser.parseBoolean(expressions[0], context);
    default: {
      const Combiner = operator === "and" ? AndExpression : OrExpression;
      const parsed = expressions.map((e) => expressionParser.parseBoolean(e, context));
      return new Combiner(parsed);
    }
  }
};

/** Get a BooleanExpression for whether a field should be shown */
export const getFieldVisibleExpression = (context: KContext, field: KField): BooleanExpression => {
  const readPermissions = field.permissions.read.length ? `(${field.permissions.read.join(") or (")})` : undefined;
  if (field.include && readPermissions) {
    return expressionParser.parseBoolean(`(${field.include}) and (${readPermissions})`, context);
  } else if (readPermissions) {
    return expressionParser.parseBoolean(readPermissions, context);
  }
  return falseExpression;
};

export const toConstant = (value: ExpressionData | { value: Dayjs | number; precision: DatetimePrecision }, options?: { unit?: MetricUnit }): string => {
  if (value === undefined) {
    return "null";
  }
  if (typeof value === "number") {
    if (options?.unit) {
      const appropriate = options.unit.getAppropriateUnit(value);
      return `${toJson(appropriate.convertTo(options.unit.convertFrom(value)))}${appropriate.expressionSymbol}`;
    }
    return toJson(value);
  }
  if (typeof value === "string" || typeof value === "boolean") {
    return toJson(value);
  }
  if (dayjs.isDayjs(value)) {
    return value.format("YYYY-MM-DDTHH:mm");
  }
  if (value instanceof BulkData) {
    return `[${value.ordered.map((v) => toConstant(v, options)).join(", ")}]`;
  }
  if (dayjs.isDayjs(value.value)) {
    switch (value.precision) {
      case "year":
        return value.value.format("YYYY");
      case "quarter":
        return value.value.format("YYYY-MM");
      case "month":
        return value.value.format("YYYY-MM");
      case "day":
        return value.value.format("YYYY-MM-DD");
      case "hour":
        return value.value.format("YYYY-MM-DDTHH");
      case "minute":
        return value.value.format("YYYY-MM-DDTHH:mm");
    }
  }
  return `${toPlural(value.precision)}(${toConstant(value.value)})`;
};

export const hasGranularFields = (fields: readonly KField[]): boolean => fields.some((f) => isAccessible(f, "string") && f.granular);

/**
 * Check for circular references involving the current field
 *
 * @param expression Expression to check (for the current field)
 * @param currentFieldId Field id of the current field
 * @param otherComputed Other computed fields in the entity to check against
 */
export const hasCircularReference = (expression: string, currentFieldId: string, otherComputed: ComputedField[]): boolean => {
  const depMap = new LazyMap((fid: string) => {
    if (fid === currentFieldId) return expressionParser.getDependencies(expression);
    const otherExpression = otherComputed.find((f) => f.id === fid)?.expression;
    return otherExpression ? expressionParser.getDependencies(otherExpression) : undefined;
  });
  // recursively check for circular references
  const checked = new Set<string>();
  const check = (fieldId: string): boolean => {
    // prevent infinite recursion
    if (checked.has(fieldId)) {
      return false;
    }
    checked.add(fieldId);
    const deps = depMap.get(fieldId);
    if (!deps) {
      return false;
    }
    if (deps.has(currentFieldId)) {
      return true;
    }
    for (const dep of deps) {
      if (check(dep)) {
        return true;
      }
    }
    return false;
  };
  return check(currentFieldId);
};

export type ChainedConditions = {
  or: {
    and: string[];
  }[];
};

const deBracket = (condition: string): string => {
  while (condition.startsWith("(") && condition.endsWith(")")) {
    condition = condition.slice(1, -1);
  }
  return condition;
};

export const splitAnd = (condition: string): string[] =>
  // TODO: handle "and" in strings
  condition.split(" and ");

export const splitChainedConditions = (condition: string): ChainedConditions => {
  const ors = condition.split(" or ").map(deBracket);
  return {
    or: ors.map((or) => ({ and: splitAnd(or) }))
  };
};

export const joinChainedConditions = (chained: ChainedConditions): string => {
  const anded = chained.or.map((or) => or.and.join(" and "));
  if (anded.length === 1) {
    return anded[0];
  }
  return anded.map((a) => `(${a})`).join(" or ");
};
