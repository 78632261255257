import { MetricUnit } from "../units";
import { spaceUnits } from "../compound/space";

export const ukUnits = {
  // Miles
  miles: new MetricUnit({
    symbol: "mi",
    name: "miles",
    dimensions: { length: 1 },
    conversion: 1609.344
  }),

  // Pints
  pints: new MetricUnit({
    symbol: "pt",
    name: "pints",
    dimensions: { length: 3 },
    conversion: 0.00056826125,
    subUnit: spaceUnits.millilitre
  })
};
