<template>
  <div :class="{ 'form-control-container': !config.noContainer }">
    <k-label v-if="!config.hideLabel" :id="uuid" :label />
    <template v-if="!config.readonly">
      <span v-show="!input" class="form-select-placeholder">{{ config.placeholder || label }}</span>
      <select
        :id="uuid"
        ref="element"
        v-model="input"
        autocomplete="off"
        :class="selectClasses"
        :aria-label="label"
        v-bind="commonBindings(config, label)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @change="$emit('change', $event)">
        <option v-if="showNullOption" :value="null" :hidden="!showNullOption"></option>
        <option v-for="(option, i) in options" :key="i" :value="option.value">{{ option.label ?? option.value }}</option>
        <option v-if="otherLabel" :value="otherValue">{{ otherLabel }}</option>
      </select>
      <div v-if="input === otherValue" class="d-flex gap-2 mt-2">
        <input v-model="otherInput" :class="controlClasses" :placeholder="otherInstructions ?? 'Enter a value'" />
      </div>
    </template>
    <input v-else v-model="displayValue" :class="controlClasses" v-bind="commonBindings(config, label)" />
    <k-issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { v4 as getUuid } from "uuid";

import KIssueDisplay from "../KIssueDisplay.vue";
import { getInputClasses, useInputConfig, commonBindings } from "../inputConfig";

import KLabel from "@ui/label/KLabel.vue";

import type { SelectItem } from "./SelectItem";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: string | { other: string };
  /** Label for the input */
  label?: string;
  /** Whether a value is required (i.e. if a null option shouldn't be shown) */
  required?: boolean;
  /** Options to show */
  options: SelectItem[];
  /** Label to show for an other option - if undefined, other is disabled */
  otherLabel?: string;
  /** Instructions to show for an other option */
  otherInstructions?: string;
}>();

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", newValue: typeof props.modelValue): void;
}>();

const config = useInputConfig(props);

// shouldn't overlap with any other values
const otherValue = getUuid();

//input validation
const input = ref<string>();
const otherInput = ref<string>();
const trimmedOther = computed(() => otherInput.value?.trim());

watch(
  () => props.modelValue,
  (mv) => {
    if (mv == null) {
      input.value = undefined;
      otherInput.value = undefined;
      return;
    }
    switch (typeof mv) {
      case "string":
        input.value = mv;
        otherInput.value = undefined;
        break;
      case "object":
        input.value = otherValue;
        if (trimmedOther.value !== mv.other) {
          otherInput.value = mv.other;
        }
        break;
      default:
        input.value = undefined;
        otherInput.value = undefined;
        break;
    }
  },
  { immediate: true }
);

const output = computed(() => {
  if (input.value === otherValue) {
    return { other: trimmedOther.value ?? "" };
  } else {
    return input.value;
  }
});

const displayValue = computed(() => {
  if (input.value === otherValue) {
    return otherInput.value;
  } else {
    const label = props.options.find((o) => o.value === input.value)?.label;
    return label ?? input.value;
  }
});

watch(output, (value) => {
  emit("update:modelValue", value);
});

const uuid = getUuid();
const showNullOption = computed(() => !(config.value.required || props.required));

const selectClasses = computed(() => ({
  ...getInputClasses(config.value, "select"),
  placeholder: !props.modelValue
}));

const controlClasses = computed(() => ({
  ...getInputClasses(config.value, "control"),
  placeholder: !props.modelValue
}));
</script>
