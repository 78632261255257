// tokeniser, splits an expression into tokens of the same level
// e.g. `"hello world" .. (1 + (2 + 4)) & test(3, 4)` => ['"hello world"', "..", "(1 + 2)", "+", "test(3, 4)"]

const bracketDeltas = new Map<string, { type: "normal" | "data"; delta: -1 | 1 }>([
  ["(", { type: "normal", delta: 1 }],
  [")", { type: "normal", delta: -1 }],
  ["[", { type: "data", delta: 1 }],
  ["]", { type: "data", delta: -1 }]
]);

export const toTokens = (expression: string, commas = false): string[] => {
  const tokens: string[] = [];
  let current = "";
  let inString = false;
  let escaping = false;
  const bracketLevels = { normal: 0, data: 0 };
  const addCurrent = () => {
    if (current) {
      if (commas) {
        // trim whitespace
        tokens.push(current.trim());
      } else {
        tokens.push(current);
      }
    }
    current = "";
  };
  for (const c of expression) {
    const bracketDelta = bracketDeltas.get(c);
    if (c === (commas ? "," : " ") && !inString && bracketLevels.normal + bracketLevels.data === 0) {
      addCurrent();
      continue;
    } else if (bracketDelta && !inString) {
      bracketLevels[bracketDelta.type] += bracketDelta.delta;
      if (bracketLevels[bracketDelta.type] < 0) {
        throw new Error("Unmatched closing bracket");
      }
    } else if (c === '"' && !escaping) {
      inString = !inString;
    } else if (inString && !escaping && c === "\\") {
      escaping = true;
      continue;
    }
    // maintain escaped characters for later JSON.parse
    current += escaping ? `\\${c}` : c;
    escaping = false;
  }
  addCurrent();
  if (bracketLevels.normal + bracketLevels.data > 0) {
    throw new Error("Unmatched opening bracket");
  }
  return tokens;
};
