<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div
    v-if="!isEditing"
    class="task-list-item"
    :data-tid="task.id"
    :class="{ completed: task.status == 'COMPLETED', editable: canEdit, multiselected: multiselected }"
    @click.right="onRightClick"
    @click.shift.left="onShiftClick"
    @click.ctrl.left="onCtrlClick"
    @click.meta.left="onCtrlClick"
    @dragstart="onDragStart"
    @dragend="onDragEnd">
    <div>
      <k-table-selection-box v-if="allowMultiselect" :selected="multiselected" class="me-3 task-checkbox" @click="multiselected = !multiselected" />
      <k-task-checkbox :status="task.status" :can-interact="editable" @toggled="onChangeStatus" />
      <div class="task-name-container ms-1" @click.left.exact="onEditStart">
        <div ref="taskNameInput" class="task-list-name py-1 pe-2">
          {{ task.name }}
        </div>
        <div v-if="subtitle" class="task-list-subtitle">
          {{ subtitle }}
        </div>
        <div v-if="showScheduled && task.scheduled" class="task-list-scheduled mb-n1">
          Scheduled for {{ formatDateRange(task.scheduled.start, task.scheduled.end, "day") }}
          <span v-if="task.dueDate" :class="dueDateClass"> <br />{{ timestampDisplayValue }} </span>
        </div>
      </div>
      <div v-if="task.dueDate && showDueDate" class="flex-shrink-0 ms-auto ps-2" :class="dueDateClass">
        <small class="task-list-date" :title="task.dueDate.toString()">{{ timestampDisplayValue }}</small>
      </div>
      <div v-if="task.assignees && showAssignees" class="text-secondary ms-auto ps-2 flex-shrink-0">
        <k-people-badges :people="task.assignees" size="sm" />
      </div>
      <div class="flex-shrink-0 ms-auto">
        <k-button
          v-if="allowInlineEditing && !hideExpand"
          :disabled="!editable"
          variant="transparent"
          icon="arrow-up-right-and-arrow-down-left-from-center"
          title="Menu"
          class="task-menu-button ms-1 px-2 me-n1"
          @click.stop="onClick" />
        <!-- <k-button
          :disabled="!editable"
          variant="transparent"
          icon="ellipsis"
          title="Menu"
          class="task-menu-button me-n2 px-2"
          @click="onRightClick($event, true)" /> -->
      </div>
    </div>
  </div>
  <div v-else class="task-item-editor">
    <k-task-input :task :people margin-start @dismiss="onEditCancelled" @task-added="onEditEnd" />
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watchEffect } from "vue";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useTimeAgo } from "@vueuse/core";

import KPeopleBadges from "@ui/badge/KPeopleBadges.vue";
import KButton from "@ui/button/KButton.vue";
import KTableSelectionBox from "@ui/table/cell/KTableSelectionBox.vue";

import KTaskCheckbox from "./KTaskCheckbox.vue";
import KTaskInput from "./KTaskInput.vue";

import { formatDateRange } from "@data/helpers/strings/Dates";
import type { KTask, TaskStatus } from "@data/data/Task";
import type { PersonBadge } from "@data/fields/TableCell";
import { deepEquals } from "@data/helpers/manipulation/Comparison";

import type { Dayjs } from "dayjs";

const props = withDefaults(
  defineProps<{
    /** Task */
    task: KTask;
    /** Whether the task is editable */
    editable?: boolean;
    /** Subtitle to show under the task name */
    subtitle?: string;
    /** Whether to show the due date */
    showDueDate?: boolean;
    /** Whether to show scheduled dates */
    showScheduled?: boolean;
    /** Whether to show assignees */
    showAssignees?: boolean;
    /** People to show in dropdown */
    people?: PersonBadge[];
    /** Whether to allow inline editing */
    allowInlineEditing?: boolean;
    /** Whether to allow multiple selection */
    allowMultiselect?: boolean;
    /** Whether to hide the expand button */
    hideExpand?: boolean;
  }>(),
  {
    editable: false,
    subtitle: undefined,
    showDueDate: true,
    showScheduled: false,
    showAssignees: true,
    people: undefined
  }
);

const emit = defineEmits<{
  (event: "startEditing"): void;
  (event: "endEditing"): void;
  (event: "taskChanged", value: Partial<KTask>): void;
  (event: "rightClick", value: MouseEvent, buttonTrigger?: boolean): void;
  (event: "dragstart", value: DragEvent): void;
  (event: "dragend"): void;
  (event: "click"): void;
  (event: "shiftClick"): void;
}>();

const multiselected = defineModel<boolean>("multiselected");

const onDragStart = (evt: DragEvent) => {
  emit("dragstart", evt);
};

const onDragEnd = () => {
  emit("dragend");
};

dayjs.extend(relativeTime);

const isEditing = ref(false);
watchEffect(() => {
  isEditing.value = props.task.status === "COMPLETED" ? false : isEditing.value;
});

const dueDateClass = computed(() => {
  if (props.task.dueDate) {
    const isOverdue = props.task.dueDate.isBefore(dayjs());
    if (isOverdue) {
      return "text-red";
    }

    const isCloseToDue = props.task.dueDate.isBefore(dayjs().add(2, "days"));
    if (isCloseToDue) {
      return "text-yellow";
    }
  }
  return "text-secondary";
});
const timestampTimeAgo = useTimeAgo<"day">(computed(() => props.task.dueDate?.toDate() ?? dayjs().toDate()));
const timestampDisplayValue = computed(() => {
  if (!props.task.dueDate) return undefined;
  if (props.task.dueDate.isSame(dayjs(), "day")) return "Due today";
  if (props.task.dueDate.isSame(dayjs().add(1, "day"), "day")) return "Due tomorrow";
  return timestampTimeAgo.value === "just now" ? "Due today" : `Due ${timestampTimeAgo.value}`;
});

const canEdit = computed(() => props.editable && props.task.status !== "COMPLETED");
const taskNameInput = ref<HTMLElement>();

const onClick = () => {
  emit("click");
};

const onShiftClick = () => {
  if (props.allowMultiselect) {
    multiselected.value = true;
    emit("shiftClick");
  }
};

const onCtrlClick = () => {
  if (props.allowMultiselect) {
    multiselected.value = !multiselected.value;
  }
};

const onEditStart = async (e: MouseEvent) => {
  if (!props.allowInlineEditing) {
    emit("click");
    return;
  }
  if (isEditing.value || !props.editable) {
    return;
  }
  e.preventDefault();
  if (props.task.status !== "COMPLETED") {
    emit("startEditing");
    isEditing.value = true;
    await nextTick(() => {
      if (!taskNameInput.value) return;
      taskNameInput.value.focus();

      const sel = window.getSelection();
      sel?.selectAllChildren(taskNameInput.value);
      if (sel?.rangeCount) {
        sel.collapseToEnd();
      }
    });
  }
};

const onEditEnd = (updatedTask: { name: string; description: string; dueDate?: Dayjs | undefined; assignees: PersonBadge[] }) => {
  if (props.editable) {
    const changes: Partial<KTask> = {};
    if (updatedTask.name !== props.task.name) {
      changes.name = updatedTask.name;
    }
    if (updatedTask.description !== props.task.description) {
      changes.description = updatedTask.description;
    }
    if (updatedTask.dueDate?.toDate() !== props.task.dueDate?.toDate()) {
      changes.dueDate = updatedTask.dueDate;
    }
    if (!deepEquals(updatedTask.assignees, props.task.assignees)) {
      changes.assignees = updatedTask.assignees;
    }
    if (Object.keys(changes).length > 0) {
      emit("taskChanged", changes);
    }

    emit("endEditing");
    isEditing.value = false;
  }
};

const onEditCancelled = () => {
  if (!props.editable) return;
  emit("endEditing");
  isEditing.value = false;
};

const onRightClick = (evt: MouseEvent, buttonTrigger?: boolean) => {
  emit("rightClick", evt, buttonTrigger);
};

const onChangeStatus = (s: TaskStatus) => {
  emit("taskChanged", { status: s });
};
</script>

<style scoped lang="scss">
.task-name-container {
  width: 100%;
}

.task-list-item {
  position: relative;
  line-height: 24px;
  padding: 0.25rem;
  width: 100%;
  border-radius: 6px;
  user-select: none;
  border: 1px solid transparent;
  border-bottom: none;
  overflow: hidden;
  background-clip: padding-box;

  > div {
    display: flex;
    align-items: center;
  }

  &.multiselected {
    background: var(--k-dropdown-item-active-background);
  }
}

.task-list-item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 0.5rem);
  height: 1px;
  border-bottom: 1px dashed var(--k-border);
  margin: 0 0.25rem;
}

.task-item-editor {
  border-bottom: 1px dashed var(--k-border);
  padding: 0 0 0.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.task-list-name {
  color: var(--k-color);
  font-size: 0.85rem;
  transition: all ease-in-out 0.05s;
  flex-grow: 1;
  overflow: hidden;
  line-height: 1.3;
  :deep(br) {
    display: none;
  }
  *,
  div {
    display: inline;
    white-space: nowrap;
  }
}

.task-list-subtitle {
  color: var(--k-color-secondary);
  font-size: 0.65rem;
  overflow: hidden;
  line-height: 0.6rem;
  padding-bottom: 0.5rem;
  margin-top: -1px;
}

.task-list-scheduled {
  color: var(--k-color-secondary);
  font-size: 0.65rem;
  overflow: hidden;
  line-height: 1rem;
  padding-bottom: 0.5rem;
  margin-top: -1px;
}

.task-list-item.editable .task-list-name {
  cursor: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.task-list-item.completed .task-list-name {
  color: var(--k-color-secondary);
  text-decoration: line-through;
  cursor: default;
}

.task-checkbox {
  min-width: 12px;
}
</style>
