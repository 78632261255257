import dayjs from "dayjs";

import { getFieldOfType, DatetimeAccessor, matchAccessor } from "../accessors";
import { DatetimeConstant } from "../constants";
import { ParsingError } from "../expressions";
import { BeforeAfterExpression, StartOfExpression } from "../datetime";
import { datetimePrecisionOptions, type DatetimePrecision } from "../../fields/basic/DatetimeField";
import { capitalise } from "../../helpers/strings/Casing";

import { checkOneOf, splitTokens } from "./operators";

import type { DatetimeExpression } from "../expressions";
import type { KContext } from "../context";
import type { ExpressionParser } from "../parser";

// matches ISO 8601 datetimes
const datetimeRegexes: [DatetimePrecision, RegExp][] = [
  ["year", /^(\d{4})$/],
  ["year", /^Y(\d{4})$/],
  ["month", /^(\d{4}-\d{2})$/],
  ["day", /^(\d{4}-\d{2}-\d{2})$/],
  ["hour", /^(\d{4}-\d{2}-\d{2}T\d{2})$/],
  ["minute", /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})$/]
];

export function parseDatetime(this: ExpressionParser, expression: string | string[], context: KContext): DatetimeExpression {
  return this.baseParse<DatetimeExpression>(expression, "datetime", context, {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    recurse: parseDatetime.bind(this),
    single: (token) => {
      if (token === "null") {
        return new DatetimeConstant(undefined, "minute");
      }
      // check for accessor
      const accessor = matchAccessor(token);
      if (accessor) {
        const field = getFieldOfType(context, accessor, "datetime");
        return new DatetimeAccessor(accessor.type, field);
      }
      for (const [precision, regex] of datetimeRegexes) {
        const match = token.match(regex);
        if (match) {
          return new DatetimeConstant(dayjs.utc(match[1]), precision);
        }
      }
      throw new ParsingError(`Unknown datetime constant: ${token}`);
    },
    multi: (tokens) => {
      const beforeAfter = checkOneOf(tokens, ["before", "after"]);
      if (beforeAfter) {
        const [left, right] = splitTokens(tokens, beforeAfter.indices);
        const duration = this.parseDuration(left, context);
        const datetime = this.parseDatetime(right, context);
        return new BeforeAfterExpression(duration, datetime, beforeAfter.present);
      }
      throw new ParsingError(`Unknown datetime expression: ${tokens.join(" ")}`);
    }
  });
}

export const registerDatetimeFunctions = (parser: ExpressionParser): void => {
  for (const precision of datetimePrecisionOptions) {
    parser.registerFunction(`startOf${capitalise(precision)}`, (tokens, context) => {
      const datetime = parser.parseDatetime(tokens, context);
      return new StartOfExpression(datetime, precision);
    });
  }
};
