import { toValue, type MaybeRef } from "vue";

import { useQuery, useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";

import type { KView } from "@data/data/View";

import type { Reference } from "@apollo/client";

export const fullView = `id
name
icon
collectionId
filters
sortOrder
isDefault
table {
  columns {
    fieldId
  }
  sortFieldId
  sortDirection
}`;

const useCollectionViews = (collectionId: MaybeRef<string | undefined>) =>
  useQuery<{ views: KView[] }, { collectionId?: string }>(
    gql`
      query getCollectionViews($collectionId: String!) {
        views(
          where: { collectionId: { eq: $collectionId } }
        ) {
          ${fullView}
        }
      }
    `,
    () => ({
      collectionId: toValue(collectionId)
    }),
    () => ({
      fetchPolicy: "cache-and-network",
      enabled: !!toValue(collectionId)
    })
  );

type AddViewInput = Omit<KView, "id">;

const useAddView = () =>
  useMutation<{ addView: KView }, { view: AddViewInput }>(
    gql`
    mutation addView($view: AddViewInput!) {
      addView(view: $view) {
        ${fullView}
      }
    }
  `,
    {
      update: (cache, { data }) => {
        if (!data) return;
        const { addView } = data;
        cache.modify({
          fields: {
            // @ts-expect-error stupid apollo types
            views(existingViews: readonly KView[] = []) {
              return [...existingViews, addView];
            }
          }
        });
      }
    }
  );

const useUpdateView = () =>
  useMutation<{ updateView: KView }, { view: KView }>(gql`
    mutation updateView($view: UpdateViewInput!) {
      updateView(input: $view) {
        ${fullView}
      }
    }
  `);

const useDeleteView = () =>
  useMutation<{ deleteView: boolean }, { id: string }>(
    gql`
      mutation deleteView($id: String!) {
        deleteView(id: $id)
      }
    `,
    {
      update: (cache, _data, { variables }) => {
        if (!variables) return;
        const { id } = variables;
        cache.modify({
          fields: {
            views(existingViews: readonly Reference[] = [], { readField }) {
              return existingViews.filter((view) => readField("id", view) !== id);
            }
          }
        });
      }
    }
  );

const useReorderViews = () =>
  useMutation<{ reorderViews: Pick<KView, "id" | "sortOrder"> }, { input: Pick<KView, "id" | "sortOrder">[] }>(gql`
    mutation reorderViews($input: [ReorderViewInput!]!) {
      reorderViews(input: $input) {
        id
        sortOrder
      }
    }
  `);

export const ViewAPI = {
  useCollectionViews,
  useAddView,
  useUpdateView,
  useReorderViews,
  useDeleteView
};
