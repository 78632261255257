import dayjs from "dayjs";

import { KField } from "../KField";
import { datetimeDisplayFormats, datetimeIsoFormats } from "../basic/DatetimeField";
import { DatetimeKeyField, SubField } from "../sub/SubField";
import { parseHistoricDate } from "../../helpers/datetimes/parseHistoricDate";
import { formatDuration } from "../../helpers/strings/Durations";
import { checkIsObject } from "../../helpers/NullHelpers";

import type { ExpressionField } from "../../expressions/accessors";
import type { TableCell } from "../TableCell";
import type { StoredKRecord } from "../../data/Record";
import type { Dayjs } from "dayjs";
import type { KFieldSetup, Sortable } from "../KField";
import type { KRecord } from "../../data/Record";
import type { DatetimePrecision } from "../basic/DatetimeField";

type TimeSpan = {
  start?: Dayjs;
  end?: Dayjs;
};

type TimeSpanWithActual = TimeSpan & {
  actualStart?: Dayjs;
  actualEnd?: Dayjs;
};

const csvSubheaders = {
  start: "Start",
  end: "End",
  actualStart: "Actual Start",
  actualEnd: "Actual End"
} satisfies Record<keyof TimeSpanWithActual, string>;

export type TimeSpanPrecision = Exclude<DatetimePrecision, "year" | "quarter" | "month">;

export interface TimeSpanSetup extends KFieldSetup {
  precision?: TimeSpanPrecision;
  allowActual?: boolean;
}

export type TimeSpanValue = TimeSpan | TimeSpanWithActual;

class DurationSubField extends SubField<number, TimeSpanValue> implements ExpressionField<"duration"> {
  readonly expressionType = "duration";

  precision: DatetimePrecision;

  constructor(
    getParent: () => TimeSpanField,
    private actual: boolean
  ) {
    super(getParent, actual ? "actualDuration" : "duration", `${getParent().label} - ${actual ? "Actual Duration" : "Duration"}`);
    this.precision = getParent().precision;
  }

  getValue(record: KRecord): number | undefined {
    const value = this.parent.getValue(record);
    let timeSpan: TimeSpan | undefined = value;
    if (this.actual) {
      timeSpan = value && "actualStart" in value ? { start: value.actualStart, end: value.actualEnd } : undefined;
    }
    if (!timeSpan || !timeSpan.start || !timeSpan.end) {
      return undefined;
    }
    return timeSpan.end.diff(timeSpan.start, this.precision);
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    if (value === undefined) return "";
    return formatDuration(value, this.precision);
  }

  getExpressionValue(r: KRecord): number | undefined {
    return this.getValue(r);
  }

  getSortableValue(record: KRecord): Sortable {
    return this.getValue(record);
  }

  validateType(value: unknown): value is number {
    return typeof value === "number";
  }
}

export class TimeSpanField extends KField<TimeSpanValue> {
  readonly type = "timeSpan";

  readonly precision: TimeSpanPrecision;

  readonly allowActual: boolean;

  constructor(setup: string | TimeSpanSetup) {
    super(setup);
    this.precision = (typeof setup === "object" && setup.precision) || "minute";
    this.allowActual = (typeof setup === "object" && setup.allowActual) || false;
    this.subfields = [
      new DatetimeKeyField("start", () => this, "Start"),
      new DatetimeKeyField("end", () => this, "End"),
      new DurationSubField(() => this, false)
    ];
    if (this.allowActual) {
      this.subfields.push(
        new DatetimeKeyField("actualStart", () => this, "Actual Start"),
        new DatetimeKeyField("actualEnd", () => this, "Actual End"),
        new DurationSubField(() => this, true)
      );
    }
  }

  getValue(record: KRecord) {
    return record[this.key] as TimeSpanValue | undefined;
  }

  getActualSlot(record: KRecord): TimeSpan | undefined {
    const value = this.getValue(record);
    if (!value) {
      return undefined;
    }
    if ("actualStart" in value) {
      return {
        start: value.actualStart,
        end: value.actualEnd
      };
    }
    return value;
  }

  getSortableValue(record: KRecord) {
    const slot = this.getActualSlot(record);
    if (!slot) {
      return undefined;
    }
    return `${slot.start?.toISOString()}>${slot.end?.toISOString()}`;
  }

  getDisplayValue(record: KRecord): string {
    const slot = this.getActualSlot(record);
    if (!slot) {
      return "";
    }

    if (slot.start && slot.end && dayjs(slot.start).isSame(slot.end, "day")) {
      return `${slot.start.format(datetimeDisplayFormats[this.precision])} - ${slot.end.format("HH:mm")}`;
    }

    const startDisplay = slot.start?.format(datetimeDisplayFormats[this.precision]) ?? "";
    const endDisplay = slot.end?.format(datetimeDisplayFormats[this.precision]) ?? "";
    // if the bit before the comma is the same, don't repeat it
    const commaIndex = startDisplay.indexOf(",");
    if (commaIndex >= 0 && startDisplay.slice(0, commaIndex) === endDisplay.slice(0, commaIndex)) {
      return `${startDisplay} to ${endDisplay.slice(commaIndex + 2)}`;
    }
    return `${startDisplay} to ${endDisplay}`;
  }

  getCSVSubheaders(): string[] {
    if (this.allowActual) {
      return Object.values(csvSubheaders);
    }
    return [csvSubheaders.start, csvSubheaders.end];
  }

  override getCSVColumns(): { subheader: string }[];

  override getCSVColumns(record: KRecord): { subheader: string; value: string }[];

  override getCSVColumns(record?: KRecord | undefined) {
    if (!record) return this.getCSVSubheaders().map((subheader) => ({ subheader }));
    const value = this.getValue(record);
    if (!value) {
      return this.getCSVSubheaders().map((subheader) => ({ subheader, value: "" }));
    }
    const subheaders = [
      { subheader: csvSubheaders.start, value: value.start?.format(datetimeIsoFormats[this.precision]) },
      { subheader: csvSubheaders.end, value: value.end?.format(datetimeIsoFormats[this.precision]) }
    ];
    if (this.allowActual) {
      if ("actualStart" in value) {
        subheaders.push(
          { subheader: csvSubheaders.actualStart, value: value.actualStart?.format(datetimeIsoFormats[this.precision]) ?? "" },
          { subheader: csvSubheaders.actualEnd, value: value.actualEnd?.format(datetimeIsoFormats[this.precision]) ?? "" }
        );
      } else {
        subheaders.push({ subheader: csvSubheaders.actualStart, value: "" }, { subheader: csvSubheaders.actualEnd, value: "" });
      }
    }
    return subheaders;
  }

  validateType(value: unknown): value is TimeSpanValue {
    if (!checkIsObject(value)) return false;
    return ["start", "end", "actualStart", "actualEnd"].every((key) => !value[key] || dayjs.isDayjs(value[key]));
  }

  storeValue(source: KRecord, target: StoredKRecord): void {
    const value = this.getValue(source);
    if (value) {
      const toStore: Partial<Record<keyof TimeSpanWithActual, string>> = {};
      toStore.start = value.start?.toISOString();
      toStore.end = value.end?.toISOString();
      if ("actualStart" in value) {
        toStore.actualStart = value.actualStart?.toISOString();
        toStore.actualEnd = value.actualEnd?.toISOString();
      }
      target[this.key] = toStore;
    }
  }

  restoreValue(source: StoredKRecord, target: KRecord): void {
    const value = source[this.key] as Partial<Record<keyof TimeSpanWithActual, string>> | undefined;
    if (value) {
      const toRestore: Partial<TimeSpanWithActual> = {
        start: value.start ? parseHistoricDate(value.start) : undefined,
        end: value.end ? parseHistoricDate(value.end) : undefined
      };
      if (this.allowActual) {
        if (value.actualStart) toRestore.actualStart = parseHistoricDate(value.actualStart);
        if (value.actualEnd) toRestore.actualEnd = parseHistoricDate(value.actualEnd);
      }
      target[this.key] = toRestore;
    }
  }

  getDefaultColumnWidth(): number {
    return 230;
  }

  getTableCell(record: KRecord): TableCell | undefined {
    const slot = this.getActualSlot(record);
    if (!slot) {
      return undefined;
    }
    return { type: "text", text: this.getDisplayValue(record), events: [slot] };
  }
}
