import type { VNode, RendererNode, RendererElement } from "vue";

type VNodeWithProps<P> = VNode<RendererNode, RendererElement, P>;
export const getChildrenOfType = <P extends object>(slotContent: VNode[], componentType: unknown): VNodeWithProps<P>[] =>
  slotContent.flatMap((slot) => {
    if (slot.type === componentType) {
      return slot as VNodeWithProps<P>;
    }
    if ("children" in slot && Array.isArray(slot.children)) {
      return getChildrenOfType(slot.children as VNode[], componentType);
    }
    return [];
  });
