import { computed, provide } from "vue";

import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";

import { makeVoipCallKey } from "@ui/displays/voipCall";

import type { provideInitialState } from "./initial";

const useInitiateVoipCall = () =>
  useMutation<
    {
      initiateVoipCall: boolean;
    },
    {
      numberToCall: string;
    }
  >(gql`
    mutation initiateVoipCall($numberToCall: String!) {
      initiateVoipCall(numberToCall: $numberToCall)
    }
  `);

export const provideVoipState = (state: ReturnType<typeof provideInitialState>) => {
  const { tenant } = state;
  const { mutate } = useInitiateVoipCall();
  const call = computed(() => {
    if (!tenant.value?.integrations?.voip) return undefined;
    return async (numberToCall: string) => {
      const result = await mutate({ numberToCall });
      return result?.data?.initiateVoipCall ?? false;
    };
  });

  provide(makeVoipCallKey, call);
};
