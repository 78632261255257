<template>
  <div :class="{ 'form-control-container': !config.noContainer }">
    <k-label v-if="!config.hideLabel" :id :label />
    <input
      :id
      ref="element"
      v-model.trim="input"
      :class="classes"
      :type
      :autocomplete="autocomplete ? 'on' : 'off'"
      :aria-label="label"
      v-bind="commonBindings(config, label)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @change="$emit('change', $event)" />
    <issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { v4 } from "uuid";

import IssueDisplay from "../KIssueDisplay.vue";
import { useInputConfig, commonBindings, useInputClasses } from "../inputConfig";

import KLabel from "@ui/label/KLabel.vue";

const props = withDefaults(
  defineProps<{
    /** ID override for labelling purposes */
    id?: string;
    /** Label for the input */
    label?: string;
    /** HTML input type */
    type?: "text" | "password" | "email" | "number" | "tel" | "url" | "search";
    /** Whether the input should remember past values (HTML autocomplete prop) */
    autocomplete?: boolean;
    /** Placeholder text */ //eslint disabled here because it's actually used
    // eslint-disable-next-line vue/no-unused-properties, vue/require-prop-comment
    placeholder?: string;
  }>(),
  {
    id: undefined,
    type: "text",
    label: undefined,
    placeholder: undefined
  }
);

defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
}>();

const input = defineModel<string>();

const config = useInputConfig(props);

const id = computed(() => props.id ?? v4());

const classes = useInputClasses(config);

const element = ref<HTMLInputElement | null>(null);

defineExpose({
  element
});
</script>
