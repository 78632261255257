<template>
  <div class="form-control-container">
    <k-label v-if="!config.hideLabel" :id="uuid" :label />
    <div class="form-check form-switch">
      <input
        :id="uuid"
        ref="element"
        v-model="input"
        class="form-check-input"
        :class="classes"
        type="checkbox"
        role="switch"
        :title="title ?? label ?? inlineLabel"
        :required="config.required"
        :aria-required="config.required"
        :aria-invalid="config.status === 'INVALID'"
        :disabled="config.disabled"
        :aria-disabled="config.disabled"
        :aria-checked="input"
        @click.stop
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @change="$emit('change', $event)" />
      <label v-if="inlineLabel ?? label" :id="uuid" class="form-check-label ps-1" :for="uuid">{{ inlineLabel ?? label }}</label>
    </div>
    <issue-display />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { v4 } from "uuid";

import IssueDisplay from "../KIssueDisplay.vue";
import { useInputClasses, useInputConfig } from "../inputConfig";

import KLabel from "@ui/label/KLabel.vue";

defineProps<{
  /** Label for the input */
  label?: string;
  /** Label to show inline (defaults to normal label) */
  inlineLabel?: string;
  /** Title for the input */
  title?: string;
}>();

defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
}>();

const input = defineModel<boolean>();

const config = useInputConfig();

const element = ref<HTMLInputElement>();

const classes = useInputClasses(config, "check-input");
const uuid = v4();
</script>

<style scoped lang="scss">
.form-check-input {
  -webkit-appearance: none;
  appearance: none;
}
</style>
