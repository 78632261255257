<template>
  <div :class="{ 'form-control-container': !config.noContainer }">
    <k-label v-if="!config.hideLabel" :id="uuid" :label :required="{ override: !!required && hasBeenNull }" />
    <span v-show="!!currentPlaceholder" class="form-select-placeholder">{{ currentPlaceholder }}</span>
    <select
      v-if="!config.readonly"
      :id="id ?? uuid"
      ref="element"
      v-model="input"
      autocomplete="off"
      :class="classes"
      :aria-label="label"
      v-bind="commonBindings(config, label)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @change="$emit('change', $event)">
      <option v-if="showNullOption" :value="undefined" :hidden="!showNullOption">{{ nullLabel ?? "" }}</option>
      <option v-for="(option, i) in options" :key="i" :value="option.value">{{ option.label ?? option.value }}</option>
    </select>
    <input v-else :id="id ?? uuid" :value="currentLabel" :class="classes" v-bind="commonBindings(config, label)" />
    <issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from "vue";

import { v4 as getUuid } from "uuid";

import IssueDisplay from "../KIssueDisplay.vue";
import { getInputClasses, useInputConfig, commonBindings } from "../inputConfig";

import KLabel from "@ui/label/KLabel.vue";

import type { SelectItem } from "./SelectItem";

const props = defineProps<{
  /** Label for the input */
  label?: string;
  /** Whether a value is required (i.e. if a null option shouldn't be shown) */
  required?: boolean;
  /** Options to show */
  options: readonly SelectItem[];
  /** What label to show for the null option */
  nullLabel?: string;
  /** What label to show for an invalid option */
  invalidLabel?: string;
  /** External id for the input */
  id?: string;
}>();

defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
}>();

const input = defineModel<string>();

const config = useInputConfig(props);

const element = ref<HTMLSelectElement>();

onMounted(() => {
  if (element.value && input.value) {
    element.value.value = input.value;
  }
});

const uuid = getUuid();
const showNullOption = computed(() => !(config.value.required || props.required));

const currentPlaceholder = computed(() => {
  if (!input.value && !props.nullLabel) {
    return config.value.placeholder || props.label;
  }
  if (input.value && !props.options.some((o) => o.value === input.value)) {
    return props.invalidLabel;
  }
  return undefined;
});

const classes = computed(() => ({
  ...getInputClasses(config.value, config.value.readonly ? "control" : "select"),
  placeholder: !!currentPlaceholder.value
}));

const currentLabel = computed(() => {
  if (!input.value) {
    return "";
  }
  const selected = props.options.find((o) => o.value === input.value);
  if (selected) {
    return selected.label || selected.value;
  } else {
    return props.invalidLabel || input.value;
  }
});

const hasBeenNull = ref(false);
watchEffect(() => {
  if (input.value == null) {
    hasBeenNull.value = true;
  }
});
</script>
