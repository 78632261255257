<template>
  <k-input-config :disabled="noLinks">
    <input-collection v-model="input.targetCollection" label="Target collection" :collections="possibleTargets" />
  </k-input-config>
  <k-issue-display :issues="noLinks ? [{ message: 'No other collections link to this collection', severity: 'error' }] : []" />
  <k-input-config :disabled="linkingFields.length === 1">
    <k-input-field-select
      v-if="targetCollection"
      label="Link field"
      :model-value="currentLinkField"
      :options="linkingFields"
      required
      @update:model-value="setLinkField" />
  </k-input-config>
  <k-input-config v-if="targetCollection && currentLinkField" :issues="targetFieldIssues">
    <k-input-field-select v-model="targetField" label="Target field" :options="possibleTargetFields" required />
  </k-input-config>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

import KInputFieldSelect from "@ui/inputs/select/KInputFieldSelect.vue";
import KInputConfig from "@ui/inputs/KInputConfig.vue";
import KIssueDisplay from "@ui/inputs/KIssueDisplay.vue";

import type { ValidationIssues } from "@data/validation/Validation";
import type { Collection } from "@data/data/Collection";
import type { KField } from "@data/fields/KField";
import { RecordField } from "@data/fields/relational/RecordFields";
import { DefaultMap } from "@data/helpers/maps/DefaultMap";
import { LookupField, type LookupFieldConfig } from "@data/fields/relational/LookupField";

import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { manageObjectVModel } from "@/helpers/vModel";
import InputCollection from "@/components/inputs/InputCollection.vue";
import { isLinkedField } from "@/expressions/computed";

const props = defineProps<{
  /** Value of the input */
  modelValue: LookupFieldConfig | undefined;
  /** Collection to configure lookup for */
  collection: Collection;
  /** Proposed collections to also show as link options */
  proposedCollections?: Collection[];
}>();

const emit = defineEmits<{
  (event: "update:modelValue", newValue: LookupFieldConfig | undefined): void;
  (event: "update:valid", newValue: boolean): void;
}>();

const input = manageObjectVModel(
  computed<LookupFieldConfig>(() => props.modelValue ?? {}),
  emit
);

const collectionStore = useCollectionStore();
const allCollections = computed(() => (collectionStore.collections ?? []).concat(props.proposedCollections ?? []));
const collectionMap = computed(() => new Map<string | undefined, Collection>(allCollections.value.map((c) => [c.id, c])));
const targetCollection = computed(() => collectionMap.value.get(input.value.targetCollection));

const possibleLinks = computed(() => {
  const result = new DefaultMap<string, KField[]>(() => []);

  const localFields = props.collection.getFieldsOfType(RecordField);
  for (const field of localFields) {
    result.get(field.collectionId).push(field);
  }

  return result.frozen();
});

const noLinks = computed<boolean>(() => !allCollections.value.some((c) => possibleLinks.value.get(c.id)?.length));

const possibleTargets = computed(() => allCollections.value.filter((c) => c.id !== props.collection.id && possibleLinks.value.get(c.id)?.length));

const linkingFields = computed(() => {
  if (!targetCollection.value) return [];
  return possibleLinks.value.get(targetCollection.value.id) ?? [];
});

const currentLinkField = computed(() => (input.value.linkField ? linkingFields.value.find((f) => f.id === input.value.linkField) : undefined));

const setLinkField = (field?: KField) => {
  const newLinkField = linkingFields.value.find((f) => f.id === field?.id);
  input.value.linkField = newLinkField?.id;
};

watch(
  linkingFields,
  (links) => {
    if (links.length === 1) {
      setLinkField(links[0]);
    }
  },
  { immediate: true }
);

const possibleTargetFields = computed(() => {
  if (!targetCollection.value) return [];
  return targetCollection.value.fields.filter((f) => !isLinkedField(f));
});

const targetField = computed<KField | undefined>({
  get: () => {
    if (targetCollection.value && input.value.targetField) {
      return LookupField.restoreFieldData(input.value.targetField);
    }
    return undefined;
  },
  set: (field) => {
    if (!targetCollection.value || !field) return;
    input.value.targetField = LookupField.storeFieldData(field);
  }
});

const targetFieldIssues = computed<ValidationIssues>(() => {
  if (!targetField.value) {
    return [{ message: "Target field is required", severity: "error" }];
  }
  return [];
});

const valid = computed(() => !targetFieldIssues.value.length);

watch(valid, (newValue) => emit("update:valid", newValue), { immediate: true });
</script>
