import { BulkData } from "../data";
import { NumberExpression } from "../expressions";
import { DataExpression } from "../expressions";

import type { KContext } from "../context";

export class AverageExpression extends NumberExpression {
  constructor(private readonly data: DataExpression) {
    super();
  }

  evaluate(context: KContext) {
    const data = this.data.evaluate(context);
    if (data === undefined) {
      return undefined;
    }
    let sum = 0;
    let count = 0;
    for (const [item, n] of data.counts.entries()) {
      if (typeof item === "number") {
        sum += item * n;
        count += n;
      }
    }
    return sum / count;
  }

  display(context: KContext): string {
    return `average of ${this.data.display(context)}`;
  }
}

export class CountExpression extends NumberExpression {
  constructor(private readonly data: DataExpression) {
    super();
  }

  evaluate(context: KContext) {
    const data = this.data.evaluate(context);
    if (data === undefined) {
      return undefined;
    }
    return data.ordered.length;
  }

  display(context: KContext): string {
    return `total ${this.data.display(context)}`;
  }
}

export class SumDataExpression extends NumberExpression {
  constructor(private readonly data: DataExpression) {
    super();
  }

  evaluate(context: KContext) {
    const data = this.data.evaluate(context);
    if (data === undefined) {
      return undefined;
    }
    let sum = 0;
    for (const [item, n] of data.counts.entries()) {
      if (typeof item === "number") {
        sum += item * n;
      }
    }
    return sum;
  }

  display(context: KContext): string {
    return `sum of ${this.data.display(context)}`;
  }
}

export class CumulativeSumExpression extends DataExpression {
  constructor(private readonly data: DataExpression) {
    super();
  }

  evaluate(context: KContext) {
    const data = this.data.evaluate(context);
    if (data === undefined) {
      return undefined;
    }
    let sum = 0;
    const cumulative: number[] = [];
    for (const [item, n] of data.counts.entries()) {
      if (typeof item === "number") {
        sum += item * n;
      }
      cumulative.push(sum);
    }
    return new BulkData(cumulative);
  }

  display(context: KContext): string {
    return `cumulative sum of ${this.data.display(context)}`;
  }
}
