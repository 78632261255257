import { filterEmpty } from "../helpers/NullHelpers";
import { deepEquals } from "../helpers/manipulation/Comparison";
import { prioritised } from "../helpers/manipulation/Ordering";
import { combineUnits } from "../units/combine";

import { BooleanExpression, NumberExpression } from "./expressions";
import { hasPrecedence } from "./operators";

import type { KContext } from "./context";
import type { WithPrecedence } from "./operators";

export class NegationExpression extends NumberExpression {
  constructor(public readonly subexpression: NumberExpression) {
    super();
    this.unit = subexpression.unit;
  }

  evaluate(context: KContext): number | undefined {
    const value = this.subexpression.evaluate(context);
    return value === undefined ? undefined : -value;
  }

  display(context: KContext): string {
    return `-${this.subexpression.display(context)}`;
  }
}

export class SumExpression extends NumberExpression implements WithPrecedence {
  readonly precedence = 0;

  constructor(private readonly subexpressions: NumberExpression[]) {
    super();
    const first = subexpressions[0];
    this.unit = subexpressions.every((e) => deepEquals(e.unit?.dimensions, first.unit?.dimensions)) ? first.unit : undefined;
  }

  evaluate(context: KContext) {
    let result = 0;
    for (const e of this.subexpressions) {
      const value = e.evaluate(context);
      if (value === undefined) {
        return undefined;
      }
      result += value;
    }
    return result;
  }

  display(context: KContext): string {
    const result: string[] = [];
    for (const e of this.subexpressions) {
      if (hasPrecedence(e) && e.precedence <= this.precedence) {
        if (result.length) {
          result.push(`+ (${e.display(context)})`);
        } else {
          result.push(`(${e.display(context)})`);
        }
      } else if (e instanceof NegationExpression && result.length) {
        if (hasPrecedence(e.subexpression) && e.subexpression.precedence <= this.precedence) {
          result.push(`- (${e.subexpression.display(context)})`);
        } else {
          result.push(`- ${e.subexpression.display(context)}`);
        }
      } else if (result.length) {
        result.push(`+ ${e.display(context)}`);
      } else {
        result.push(e.display(context));
      }
    }
    return result.join(" ");
  }
}

export class ReciprocalExpression extends NumberExpression {
  constructor(public readonly subexpression: NumberExpression) {
    super();
    this.unit = subexpression.unit ? combineUnits([{ unit: subexpression.unit, power: -1 }]) : undefined;
  }

  evaluate(context: KContext): number | undefined {
    const value = this.subexpression.evaluate(context);
    return value === undefined ? undefined : 1 / value;
  }

  display(context: KContext): string {
    return `1 / ${this.subexpression.display(context)}`;
  }
}

export class ProductExpression extends NumberExpression implements WithPrecedence {
  readonly precedence = 2;

  private evalutationOrder: NumberExpression[];

  constructor(private readonly subexpressions: NumberExpression[]) {
    super();
    const units = filterEmpty(subexpressions.map((e) => e.unit));
    if (units.length) {
      this.unit = combineUnits(units.map((u) => ({ unit: u, power: 1 })));
    }
    // evaluate reciprocals last to avoid floating point errors
    this.evalutationOrder = prioritised(subexpressions, (e) => !(e instanceof ReciprocalExpression));
  }

  evaluate(context: KContext) {
    let result = 1;
    for (const e of this.evalutationOrder) {
      const value = e.evaluate(context);
      if (value === undefined) {
        return undefined;
      }
      result *= value;
    }
    return result;
  }

  display(context: KContext): string {
    const result: string[] = [];
    for (const e of this.subexpressions) {
      if (hasPrecedence(e) && e.precedence <= this.precedence) {
        if (result.length) {
          result.push(`× (${e.display(context)})`);
        } else {
          result.push(`(${e.display(context)})`);
        }
      } else if (e instanceof ReciprocalExpression && result.length) {
        if (hasPrecedence(e.subexpression) && e.subexpression.precedence <= this.precedence) {
          result.push(`÷ (${e.subexpression.display(context)})`);
        } else {
          result.push(`÷ ${e.subexpression.display(context)}`);
        }
      } else if (result.length) {
        result.push(`× ${e.display(context)}`);
      } else {
        result.push(e.display(context));
      }
    }
    return result.join(" ");
  }
}

abstract class NumericalComparisonExpression extends BooleanExpression {
  protected abstract readonly operator: string;

  constructor(
    private readonly left: NumberExpression,
    private readonly right: NumberExpression
  ) {
    super();
  }

  evaluate(context: KContext): boolean {
    // handle undefined values
    const left = this.left.evaluate(context);
    const right = this.right.evaluate(context);
    if (left === undefined || right === undefined) {
      return false;
    }
    return this.compare(left, right);
  }

  abstract compare(left: number, right: number): boolean;

  display(context: KContext): string {
    return `${this.left.display(context)} ${this.operator} ${this.right.display(context)}`;
  }
}

export class GreaterThanExpression extends NumericalComparisonExpression {
  readonly operator = ">";

  compare(left: number, right: number): boolean {
    return left > right;
  }
}

export class GreaterThanOrEqualExpression extends NumericalComparisonExpression {
  readonly operator = ">=";

  compare(left: number, right: number): boolean {
    return left >= right;
  }
}

export class LessThanExpression extends NumericalComparisonExpression {
  readonly operator = "<";

  compare(left: number, right: number): boolean {
    return left < right;
  }
}

export class LessThanOrEqualExpression extends NumericalComparisonExpression {
  readonly operator = "<=";

  compare(left: number, right: number): boolean {
    return left <= right;
  }
}
