import { useDark } from "@vueuse/core";

// note: using a cheeky global variable here since useDark is surprisingly performance heavy
let isDark: ReturnType<typeof useDark> | undefined;

export const useTheme = () => {
  isDark ??= useDark({
    valueDark: "dark",
    valueLight: "light",
    storageKey: "colour-scheme"
  });

  return { isDark };
};
