import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import type { Datelike } from "../../types/Dates";

dayjs.extend(relativeTime);

export const formatDate = (value: Datelike) => dayjs(value).format("DD/MM/YYYY");
export const formatDateTime = (value: Datelike, includeSeconds = false) => {
  if (includeSeconds) {
    return dayjs(value).format("DD/MM/YYYY HH:mm:ss");
  }
  return dayjs(value).format("DD/MM/YYYY HH:mm");
};
export const formatTime = (value: Datelike) => dayjs(value).format("HH:mm");

export const formatFromNow = (value: Datelike) => dayjs(value).fromNow();

export const formatDateRange = (start: Datelike, end: Datelike, precision: "day" | "min" = "min") => {
  const startDay = dayjs(start);
  const endDay = dayjs(end);

  if (precision === "min") {
    if (startDay.isSame(endDay, "day")) {
      return `${startDay.format("DD/MM/YYYY HH:mm")} - ${endDay.format("HH:mm")} (${endDay.from(startDay, true)})`;
    }
    return `${startDay.format("DD/MM/YYYY HH:mm")} - ${endDay.format("DD/MM/YYYY HH:mm")} (${endDay.from(startDay, true)})`;
  } else {
    if (startDay.isSame(endDay, "day")) {
      return `${startDay.format("DD/MM/YYYY")}`;
    }
    return `${startDay.format("DD/MM/YYYY")} - ${endDay.format("DD/MM/YYYY")}`;
  }
};

export const formatDateRangeBreakdown = (
  start: Datelike,
  end: Datelike,
  precision: "day" | "min" = "min"
): {
  dateRange: string;
  duration: string;
} => {
  const startDay = dayjs(start);
  const endDay = dayjs(end);

  if (precision === "min") {
    if (startDay.isSame(endDay, "day")) {
      return {
        dateRange: `${startDay.format("DD/MM/YYYY HH:mm")} - ${endDay.format("HH:mm")}`,
        duration: endDay.from(startDay, true)
      };
    }

    return {
      dateRange: `${startDay.format("DD/MM/YYYY HH:mm")} - ${endDay.format("DD/MM/YYYY HH:mm")}`,
      duration: endDay.from(startDay, true)
    };
  }

  if (startDay.isSame(endDay, "day")) {
    return {
      dateRange: `${startDay.format("DD/MM/YYYY")}`,
      duration: "All day"
    };
  }
  return {
    dateRange: `${startDay.format("DD/MM/YYYY")} - ${endDay.format("DD/MM/YYYY")}`,
    duration: endDay.from(startDay, true)
  };
};
