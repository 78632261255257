<template>
  <div class="field-item d-inline-block">
    <div class="d-flex">
      <span class="field-label col-9">{{ field.label }} </span>
      <span class="col-3 d-flex justify-content-end">
        <k-icon v-if="primary" icon="circle-1" title="Primary" class="text-primary m-1 ms-0" />
        <k-icon v-if="secondary" icon="circle-2" title="Secondary" class="text-secondary m-1 ms-0" />
        <k-icon v-if="sortable" icon="arrow-up-arrow-down" title="Default sort" class="text-secondary m-1 ms-0" />
        <k-badge :label="fieldType" :variant="getBadgeColour(field)" />
      </span>
    </div>
    <div v-if="showOptions && options" class="mb-n1">
      <small v-for="option in options" :key="option.value" :class="`text-${option.variant ?? 'light'} me-2`">
        {{ option.label }}
      </small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import KBadge from "@ui/badge/KBadge.vue";
import KIcon from "@ui/icon/KIcon.vue";

import type { Collection } from "@data/data/Collection";
import type { KField } from "@data/fields/KField";
import { OptionField, MultiOptionField } from "@data/fields/basic/OptionFields";
import { RecordField, MultiRecordField } from "@data/fields/relational/RecordFields";
import { capitalise } from "@data/helpers/strings/Casing";
import { fieldTypes, getBadgeColour } from "@data/constants/fieldTypes";

import { useCollectionStore } from "@/api/stores/useCollectionStore";

const props = withDefaults(
  defineProps<{
    /** Field to display */
    field: KField;
    /** Collection the field is part of */
    collection?: Collection | undefined;
    /** The list of GeneratedCollections used for showing the collection name on the badge for Record and MultiRecord fields */
    proposedCollections?: Collection[];
    /** Whether to show the options */
    showOptions?: boolean;
  }>(),
  {
    collection: undefined,
    proposedCollections: () => []
  }
);

const collectionStore = useCollectionStore();

const fieldType = computed(() => {
  const f = props.field;
  if (f instanceof RecordField || f instanceof MultiRecordField) {
    const proposedCollection = props.proposedCollections.find(({ id }) => id.toString() === f.collectionId);
    if (f instanceof RecordField) {
      return collectionStore.getCollectionWithId(f.collectionId)?.singular ?? proposedCollection?.singular ?? "Record";
    } else {
      return collectionStore.getCollectionWithId(f.collectionId)?.plural ?? proposedCollection?.plural ?? "Records";
    }
  }

  if (f.getFieldTypeName) {
    return f.getFieldTypeName();
  }

  const result = f.type in fieldTypes ? fieldTypes[f.type as keyof typeof fieldTypes] : undefined;
  return result?.label ?? capitalise(f.type);
});

const options = computed(() => {
  if (props.field instanceof OptionField || props.field instanceof MultiOptionField) {
    return props.field.options;
  }
  return null;
});

const primary = computed(() => props.field === props.collection?.primaryField);
const sortable = computed(() => props.field === props.collection?.sortField);
const secondary = computed(() => props.field === props.collection?.secondaryField);
</script>

<style scoped lang="scss">
.field-item {
  width: calc(100% - 30px);
}

.field-label {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
}
</style>
