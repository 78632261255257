import { deepEquals } from "../helpers/manipulation/Comparison";

import {
  StringExpression,
  type Expression,
  BooleanExpression,
  NumberExpression,
  DurationExpression,
  DatetimeExpression,
  DataExpression,
  ParsingError
} from "./expressions";
import { bracketAndJoin } from "./operators";
import { InsufficientContextError, type KContext } from "./context";

import type { DatetimePrecision } from "../fields/basic/DatetimeField";
import type { MetricUnit } from "../units/units";
import type { Dayjs } from "dayjs";

// need the generic expression return type to be of the correct subclass unfortunately, so there's a lot of repetition here

class GenericBooleanExpression extends BooleanExpression {
  constructor(
    public readonly evaluate: (context: KContext) => boolean | undefined,
    public readonly display: (context: KContext) => string
  ) {
    super();
  }
}

class GenericStringExpression extends StringExpression {
  constructor(
    public readonly evaluate: (context: KContext) => string | undefined,
    public readonly display: (context: KContext) => string
  ) {
    super();
  }
}

class GenericNumberExpression extends NumberExpression {
  constructor(
    public readonly evaluate: (context: KContext) => number | undefined,
    public readonly display: (context: KContext) => string,
    public readonly unit: MetricUnit | undefined
  ) {
    super();
  }
}

class GenericDatetimeExpression extends DatetimeExpression {
  constructor(
    public readonly evaluate: (context: KContext) => Dayjs | undefined,
    public readonly display: (context: KContext) => string,
    public readonly precision: DatetimePrecision
  ) {
    super(precision);
  }
}

class GenericDurationExpression extends DurationExpression {
  constructor(
    public readonly evaluate: (context: KContext) => number | undefined,
    public readonly display: (context: KContext) => string,
    public readonly precision: DatetimePrecision
  ) {
    super();
  }
}

class GenericDataExpression extends DataExpression {
  constructor(
    public readonly evaluate: (context: KContext) => ReturnType<DataExpression["evaluate"]>,
    public readonly display: (context: KContext) => string
  ) {
    super();
  }
}

/** Given an evalutation and display function, return a generic expression of the correct subclass */
const genericise = <E extends Expression>(
  evaluate: (context: KContext) => ReturnType<E["evaluate"]> | undefined,
  display: (context: KContext) => string,
  genericExpressions: E[]
): Expression & { type: E["type"] } => {
  switch (genericExpressions[0].type) {
    case "boolean":
      return new GenericBooleanExpression(evaluate as (context: KContext) => boolean | undefined, display) as Expression & { type: E["type"] };
    case "string":
      return new GenericStringExpression(evaluate as (context: KContext) => string | undefined, display) as Expression & { type: E["type"] };
    case "number": {
      const firstUnit = genericExpressions[0].unit;
      const otherExpressions = genericExpressions.slice(1) as NumberExpression[];
      const commonUnit = firstUnit && otherExpressions.every((e) => deepEquals(e.unit?.dimensions, firstUnit.dimensions)) ? firstUnit : undefined;
      return new GenericNumberExpression(evaluate as (context: KContext) => number | undefined, display, commonUnit) as Expression & { type: E["type"] };
    }
    case "datetime": {
      const firstPrecision = genericExpressions[0].precision;
      const otherExpressions = genericExpressions.slice(1) as DatetimeExpression[];
      if (!otherExpressions.every((e) => e.precision === firstPrecision)) {
        throw new ParsingError("Mismatched precisions in datetime expression");
      }
      return new GenericDatetimeExpression(evaluate as (context: KContext) => Dayjs | undefined, display, firstPrecision) as Expression & { type: E["type"] };
    }
    case "duration": {
      const firstPrecision = genericExpressions[0].precision;
      const otherExpressions = genericExpressions.slice(1) as DurationExpression[];
      if (!otherExpressions.every((e) => e.precision === firstPrecision)) {
        throw new ParsingError("Mismatched precisions in duration expression");
      }
      return new GenericDurationExpression(evaluate as (context: KContext) => number | undefined, display, firstPrecision) as Expression & { type: E["type"] };
    }
    case "data":
      return new GenericDataExpression(
        evaluate as (context: KContext) => ReturnType<DataExpression["evaluate"]>,
        display as (context: KContext) => string
      ) as Expression & { type: E["type"] };
  }
};

export const coalesceExpression = <E extends Expression>(expressions: E[]): Expression & { type: E["type"] } => {
  const evaluate = (context: KContext): ReturnType<E["evaluate"]> | undefined => {
    for (const e of expressions) {
      const value = e.evaluate(context);
      if (value !== undefined) {
        return value as ReturnType<E["evaluate"]>;
      }
    }
    return undefined;
  };
  const display = (context: KContext): string => bracketAndJoin(expressions, context, 3, "??");

  return genericise(evaluate, display, expressions);
};

export const ternaryExpression = <E extends Expression>(
  condition: BooleanExpression,
  trueExpression: E,
  falseExpression: E
): Expression & { type: E["type"] } => {
  const evaluate = (context: KContext): ReturnType<E["evaluate"]> | undefined => {
    const conditionValue = condition.evaluate(context);
    return (conditionValue ? trueExpression.evaluate(context) : falseExpression.evaluate(context)) as ReturnType<E["evaluate"]>;
  };

  const display = (context: KContext): string =>
    `if ${condition.display(context)} then ${trueExpression.display(context)} otherwise ${falseExpression.display(context)}`;

  return genericise(evaluate, display, [trueExpression, falseExpression]);
};

export const personLookupExpression = <E extends Expression>(idExpression: StringExpression, lookup: E): Expression & { type: E["type"] } => {
  const evaluate = (context: KContext): ReturnType<E["evaluate"]> | undefined => {
    const id = idExpression.evaluate(context);
    if (id === undefined) {
      return undefined;
    }
    if (!context.people) {
      throw new InsufficientContextError("people");
    }
    const relevantPerson = context.people.find((p) => p.id === id);
    if (!relevantPerson) {
      return undefined;
    }
    return lookup.evaluate({ ...context, person: relevantPerson }) as ReturnType<E["evaluate"]>;
  };

  const display = (context: KContext): string => {
    const idDisplay = idExpression.display(context);
    let lookupDisplay = lookup.display(context);
    if (lookupDisplay.startsWith("Current User's ")) {
      lookupDisplay = lookupDisplay.slice(15);
    }
    if (idDisplay.endsWith(" ID")) {
      return `${idDisplay.slice(0, -3)}'s ${lookupDisplay}`;
    }
    return `personLookup(id = ${idExpression.display(context)}, ${lookupDisplay})`;
  };

  return genericise(evaluate, display, [lookup]);
};
