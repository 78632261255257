import { formatDuration } from "../../helpers/strings/Durations";

import { SimpleField } from "./SimpleFields";

import type { KRecord } from "../../data/Record";
import type { ExpressionField } from "../../expressions/accessors";
import type { KFieldSetup, Sortable } from "../KField";
import type { DatetimePrecision } from "./DatetimeField";

export type MinutePrecision =
  | 30
  | 15
  | 10
  | 6 // for lawyers
  | 5;
interface DurationFieldSetup extends KFieldSetup {
  precision?: DatetimePrecision;
  minutePrecision?: MinutePrecision;
}

export class DurationField extends SimpleField<number> implements ExpressionField<"duration"> {
  readonly type = "duration";

  readonly expressionType = "duration";

  precision: DatetimePrecision = "minute";

  minutePrecision?: MinutePrecision;

  constructor(setup: DurationFieldSetup | string) {
    super(setup);
    if (typeof setup !== "string") {
      this.precision = setup.precision ?? "minute";
      this.minutePrecision = setup.minutePrecision;
    }
  }

  getExpressionValue(r: KRecord): number | undefined {
    return this.getValue(r);
  }

  getSortableValue(record: KRecord): Sortable {
    return this.getValue(record);
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    if (value === undefined) return "";
    return formatDuration(value, this.precision);
  }

  validateType(value: unknown): value is number {
    return typeof value === "number";
  }
}
