// Defines types for serializable JSON objects and provides a function to fix/validate a given value against a given type definition.

type JsonWithUndefinedSerialisable =
  | string
  | number
  | boolean
  | null
  | undefined
  | {
      [property: string]: JsonWithUndefinedSerialisable;
    }
  | JsonWithUndefinedSerialisable[];

type PrimitiveTypeDef = "string" | "boolean" | string[];

type Property = string;
type TypeDef = PrimitiveTypeDef | ObjectTypeDef | ArrayTypeDef;
// defaultVal = undefined indicates that a property isn't required and that it shouldn't be created if absent nor reset if invalid
type DefaultVal = string | boolean | undefined;

export type ObjectTypeDef = {
  category: "object";
  type: [Property, TypeDef, DefaultVal][];
};
export type ArrayTypeDef = {
  category: "array";
  itemType: TypeDef;
};

/**
 * Fix & validate a given value against a given type definition.
 *
 * Limits of current functionality:
 *
 * - Can only define (and validate against) the following types (as/within parentTypeDef):
 *
 *   - Primitives: boolean, string, string literal union
 *   - Arrays with homogenous value types Note: you can't validate for these types but they can be passed in as/within parentVal.
 *
 * @param {JsonWithUndefinedSerialisable} parentVal - The value being fixed/validated by the function
 * @param {TypeDef} parentTypeDef - The TypeDef which the parentVal value is fixed/validated against
 * @param {DefaultVal} parentDefaultVal - The default value to return if the value passed is not of the same type as that in the parentTypeDef at the highest
 *   level. e.g. if the parentTypeDef is an ArrayTypeDef and you pass a string as the parentVal, the parentDefaultVal will be returned
 * @returns {any} {JsonWithUndefinedSerialisable}
 * @export
 */
export function fixValAgainstTypeDef(
  parentVal: JsonWithUndefinedSerialisable,
  parentTypeDef: TypeDef,
  parentDefaultVal: DefaultVal
): JsonWithUndefinedSerialisable {
  // PRIMITIVE TYPES
  if (parentTypeDef === "string") {
    if (typeof parentVal === "string") {
      return parentVal;
    } else {
      return parentDefaultVal as string | undefined;
    }
  } else if (parentTypeDef === "boolean") {
    if (typeof parentVal === "boolean") {
      return parentVal;
    } else {
      return parentDefaultVal as boolean | undefined;
    }
    // type is string-literal
  } else if (Array.isArray(parentTypeDef)) {
    if (typeof parentVal !== "string") {
      return parentDefaultVal as string | undefined;
    } else if (parentTypeDef.includes(parentVal)) {
      return parentVal;
    } else {
      return parentDefaultVal as string | undefined;
    }
    // OTHER TYPES
  } else if (parentTypeDef.category === "object") {
    let fixedObjVal: { [key: string]: JsonWithUndefinedSerialisable };
    if (typeof parentVal === "object" && !Array.isArray(parentVal) && parentVal !== null) {
      fixedObjVal = { ...parentVal };
    } else {
      fixedObjVal = {};
    }
    for (const [property, childTypeDef, childDefaultVal] of parentTypeDef.type) {
      const childVal = fixedObjVal[property];
      fixedObjVal[property] = fixValAgainstTypeDef(childVal, childTypeDef, childDefaultVal);
    }
    return fixedObjVal;
  } else {
    if (Array.isArray(parentVal)) {
      return parentVal.map((childVal) => fixValAgainstTypeDef(childVal, parentTypeDef.itemType, undefined));
    } else {
      return [];
    }
  }
}
