<template>
  <div ref="conditionBuilder" class="condition-builder p-0">
    <k-button
      v-show="menuState == 'closed'"
      variant="transparent"
      icon="plus"
      label="Add filter"
      title="Add filter"
      class="filter-btn"
      @click="menuState = 'field-picker'" />
    <div class="dropdown-menu" :class="{ show: menuState == 'field-picker' }">
      <a
        v-for="(field, i) in validFields"
        :key="i"
        class="dropdown-item"
        tabindex="0"
        role="button"
        @click="selectField(field)"
        @keydown.enter="selectField(field)">
        {{ field.label }}
      </a>
    </div>
    <div class="dropdown-menu" :class="{ show: menuState == 'operation-picker' }">
      <k-label class="p-2 filter-dropdown-header" :label="selectedField?.label" />
      <a
        v-for="(operation, i) in operationOptions"
        :key="i"
        class="dropdown-item"
        tabindex="0"
        role="button"
        @click="selectOperation(operation)"
        @keydown.enter="selectOperation(operation)"
        >{{ operation.label }}</a
      >
    </div>
    <div class="dropdown-menu value-input p-2" :class="{ show: menuState == 'value-picker' }">
      <k-label class="p-1 filter-dropdown-header" label="Target" />
      <k-input-config :placeholder="selectedField?.label">
        <k-field-input
          v-if="targetField && selectedOperation && !isSingleOperation"
          v-model="genericTarget"
          :field="targetField"
          :entity
          no-container
          mode="filter"
          hide-label />
      </k-input-config>
      <div v-if="menuState == 'value-picker'" class="border-top text-end mt-2 pt-2">
        <k-button icon="plus" variant="primary" :disabled="!isValid" label="Add" @click="generateCondition" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";

import { onClickOutside } from "@vueuse/core";

import KButton from "@ui/button/KButton.vue";
import KLabel from "@ui/label/KLabel.vue";
import KInputConfig from "@ui/inputs/KInputConfig.vue";
import type { SelectItem } from "@ui/inputs/select/SelectItem";

import { useConditionHelpers } from "./ConditionHelpers";

import type { KEntity } from "@data/data/KEntity";
import type { ExpressionField } from "@data/expressions/accessors";
import { isAccessible } from "@data/expressions/accessors";
import type { KField } from "@data/fields/KField";

import KFieldInput from "@/components/inputs/KFieldInput.vue";

const props = defineProps<{
  /** Entity to define conditions for */
  entity: KEntity;
  /** Default field to select */
  defaultField?: KField & ExpressionField;
}>();

const emit = defineEmits<(e: "complete", result: string) => void>();

const menuState = ref<"closed" | "field-picker" | "operation-picker" | "value-picker">("closed");

const conditionBuilder = ref();
onClickOutside(conditionBuilder, () => {
  menuState.value = "closed";
});

const { selectedField, targetField, selectedOperation, operationOptions, isSingleOperation, genericTarget, getCondition, validFields, isValid } =
  useConditionHelpers(
    computed(() => props.entity.allFields.filter((f) => isAccessible(f))),
    computed(() => props.entity.expressionPrefix)
  );

watchEffect(() => {
  selectedField.value = props.defaultField;
});

const selectField = (field: KField) => {
  if (isAccessible(field)) {
    selectedField.value = field;
    menuState.value = "operation-picker";
  }
};

const generateCondition = () => {
  const generated = getCondition();
  if (generated) {
    // reset fields
    selectedField.value = props.defaultField;
    selectedOperation.value = undefined;
    genericTarget.value = undefined;
    emit("complete", generated);
  } else {
    throw new Error("Condition not generated");
  }
  menuState.value = "closed";
};

const selectOperation = (operation: SelectItem) => {
  selectedOperation.value = operation.value;
  if (isSingleOperation.value) {
    generateCondition();
  } else {
    menuState.value = "value-picker";
  }
};
</script>

<style scoped>
.condition-builder {
  min-width: 200px;
  min-height: 30px;
}
.filter-btn {
  border: 1px dashed var(--k-border);
}

.filter-btn :deep(.btn-content) {
  padding: 0;
}

:deep(.filter-dropdown-header) {
  position: sticky;
  top: 0px;
  background-color: var(--k-dropdown-background);
  z-index: 1;
}

.value-input {
  width: 280px;
  overflow: visible;
}
</style>
