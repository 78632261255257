<template>
  <span v-if="limitedCount" class="notification-count" :class="{ 'navbar-item-count': inNavItem }">{{ limitedCount }}</span>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  /** Notification count */
  notificationCount?: number;
  /** Whether the badge is in a navbar item */
  inNavItem?: boolean;
}>();

// server doesn't return counts above 100
const limitedCount = computed(() => {
  if (props.notificationCount && props.notificationCount > 99) {
    return "99+";
  }
  return props.notificationCount;
});
</script>

<style scoped>
.notification-count {
  position: absolute;
  top: -4px;
  right: -2px;
  background: var(--k-color-danger);
  color: white;
  line-height: 12px;
  text-align: center;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 8px;
  min-width: 16px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.notification-count.navbar-item-count {
  top: -2px;
  right: 4px;
  text-align: right;
}
</style>
