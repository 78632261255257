import { toCount } from "./Plurals";

import type { DatetimePrecision } from "../../fields/basic/DatetimeField";

/**
 * Format duration values into human-readable strings
 *
 * @param value Number (from DurationField)
 * @param precision Precision (from DurationField)
 */
export const formatDuration = (value: number, precision: DatetimePrecision) => {
  switch (precision) {
    case "minute":
      if (value >= 60) {
        if (value % 60 === 0) return toCount("hour", value / 60, true);
        return `${toCount("hour", Math.floor(value / 60), true)} ${toCount("minute", value % 60, true)}`;
      }
      return toCount("minute", value, true);
    default:
      return toCount(precision, value, true);
  }
};
