import type { MaybeRef } from "vue";
import { computed, ref, toValue, watch } from "vue";

import dayjs from "dayjs";

import type { SelectItem } from "@ui/inputs/select/SelectItem";

import { isAccessible, type ExpressionField } from "@data/expressions/accessors";
import { KField } from "@data/fields/KField";
import { toConstant } from "@data/expressions/helpers";
import { DatetimeField } from "@data/fields/basic/DatetimeField";
import { MetricField } from "@data/fields/basic/MetricField";
import { NumberField } from "@data/fields/basic/SimpleFields";
import { StringField } from "@data/fields/basic/StringFields";
import type { MetricUnit } from "@data/units/units";
import type { KFieldValue } from "@data/data/Record";
import { LookupField } from "@data/fields/relational/LookupField";
import type { KEntity } from "@data/data/KEntity";

const isBlank = (value: unknown) => value === undefined || value === "" || (Array.isArray(value) && !value.length);

export type ConditionField = (KField & ExpressionField) | { label: string; expression: string; targetField: KField & ExpressionField };
export type FullConditionField = Extract<ConditionField, { expression: string }>;

type ExpressionPrefix = KEntity["expressionPrefix"];

const normaliseField = (field: ConditionField, prefix: ExpressionPrefix): FullConditionField => {
  if (field instanceof KField) {
    return {
      label: field.label,
      expression: `${prefix}.${field.id}`,
      targetField: field
    };
  }
  return field;
};

export const useConditionHelpers = <F extends ConditionField>(availableFields: Readonly<MaybeRef<F[]>>, prefix: MaybeRef<ExpressionPrefix> = "record") => {
  const selectedField = ref<F>();

  const selectedOperation = ref<string>();

  const singleOperations = new Set(["is blank", "isn't blank", "bool", "!bool", "unique", "!unique"]);
  const isSingleOperation = computed(() => selectedOperation.value && singleOperations.has(selectedOperation.value));

  const operationOptions = computed<SelectItem[]>(() => {
    const result: SelectItem[] = [];
    if (selectedField.value) {
      const normalised = normaliseField(selectedField.value, toValue(prefix));
      const field = normalised.targetField;
      if (field.expressionType === "boolean") {
        // note: "bool" gets handled separately to normal operations
        return [
          { label: "is true", value: "bool" },
          { label: "is false", value: "!bool" }
        ];
      }
      result.push({ label: "is blank", value: "is blank" }, { label: "is not blank", value: "isn't blank" });
      switch (field.expressionType) {
        case "string":
          result.push({ label: "is", value: "matches" }, { label: "is not", value: "!matches" });
          if (!field.granular && !field.idFor) {
            result.push(
              { label: "contains", value: "contains" },
              { label: "does not contain", value: "!contains" },
              { label: "starts with", value: "starts with" },
              { label: "does not start with", value: "!starts with" },
              { label: "ends with", value: "ends with" },
              { label: "does not end with", value: "!ends with" }
            );
          }
          // result.push({ label: "is unique", value: "unique" }, { label: "isn't unique", value: "!unique" });
          break;
        case "number":
          result.push(
            { label: "is greater than", value: ">" },
            { label: "is less than", value: "<" },
            { label: "is greater than or equal to", value: ">=" },
            { label: "is less than or equal to", value: "<=" },
            { label: "is equal to", value: "=" },
            { label: "is not equal to", value: "!=" }
          );
          break;
        case "datetime":
          result.push(
            { label: "is after", value: "is after" },
            { label: "is before", value: "is before" },
            { label: "is not after", value: "!is after" },
            { label: "is not before", value: "!is before" },
            { label: "is equal to", value: "is in" },
            { label: "is not equal to", value: "!is in" }
          );
          break;
        case "data":
          result.push(
            { label: "includes all of", value: "is superset of" },
            { label: "includes some of", value: "intersects" },
            { label: "includes none of", value: "!intersects" }
          );
          break;
      }
    }
    return result;
  });

  const operationDisplayName = computed(() => operationOptions.value.find((o) => o.value === selectedOperation.value)?.label);

  watch(selectedField, () => {
    selectedOperation.value = undefined;
  });

  const targetField = computed(() => {
    if (!selectedField.value) return undefined;
    const normalised = normaliseField(selectedField.value, toValue(prefix));
    const label = normalised.label;
    let field = normalised.targetField;
    if (field instanceof LookupField && field.targetField && isAccessible(field.targetField)) {
      field = field.targetField;
    }
    switch (field.expressionType) {
      case "string":
        if (field.granular) {
          return field;
        }
        return new StringField(label);
      case "number":
        return "unit" in field && field.unit ? new MetricField(label, field.unit.expressionSymbol) : new NumberField(label);
      case "datetime":
        return new DatetimeField({ label, precision: field.precision });
    }
    return field;
  });

  const getComparisonFields = (normalised: FullConditionField): FullConditionField[] => {
    const field = normalised.targetField;
    const validNormalised = toValue(availableFields)
      .map((f) => normaliseField(f, toValue(prefix)))
      .filter((f) => normalised.expression !== f.expression && f.targetField.expressionType === field.expressionType);
    if (field.idFor) {
      return validNormalised.filter((f) => f.targetField.idFor === field.idFor);
    }
    switch (field.expressionType) {
      case "string":
        if (field.granular) {
          return validNormalised.filter((f) => f.targetField.granular && field.isAssignableTo(f.targetField));
        }
        return validNormalised.filter((f) => !f.targetField.granular);
      case "number":
        return validNormalised.filter((f) => field.unit?.expressionSymbol === f.targetField.unit?.expressionSymbol);
      default:
        return validNormalised.filter((f) => field.isAssignableTo(f.targetField));
    }
  };

  const validFields = computed(() =>
    toValue(availableFields).filter((f) => {
      const normalised = normaliseField(f, toValue(prefix));
      if (normalised.targetField.expressionType === "duration") return false; // durations not supported yet
      if (normalised.targetField.idFor && normalised.targetField.idFor !== "role") {
        return getComparisonFields(normalised).length > 0;
      }
      return true;
    })
  );

  const comparisonFields = computed(() => {
    if (!(selectedField.value && selectedOperation.value)) return [];
    return getComparisonFields(normaliseField(selectedField.value, toValue(prefix)));
  });

  const comparisonTarget = ref<FullConditionField>();

  const genericTarget = ref<KFieldValue>();

  watch(selectedOperation, () => {
    genericTarget.value = undefined;
    comparisonTarget.value = undefined;
  });

  const targetValue = computed<string | undefined>(() => {
    if (comparisonTarget.value) {
      return comparisonTarget.value.expression;
    }
    let dValue: Parameters<typeof toConstant>[0] | undefined;
    let unit: MetricUnit | undefined;
    const target = genericTarget.value;
    switch (typeof target) {
      case "number":
        dValue = target;
        unit = targetField.value && isAccessible(targetField.value, "number") ? targetField.value.unit : undefined;
        if (unit) {
          dValue = unit.convertTo(dValue);
        }
        break;
      case "boolean":
        dValue = target;
        break;
      default: {
        if (targetField.value && isAccessible(targetField.value)) {
          if (targetField.value.idFor === "role") {
            if (typeof target === "string") {
              return `role.${target}`;
            } else if (Array.isArray(target)) {
              return `[${target.map((t) => `role.${t}`).join(", ")}]`;
            }
          }
          dValue = targetField.value.getExpressionValue({ [targetField.value.key]: target });
          if (dayjs.isDayjs(dValue) && targetField.value.expressionType === "datetime") {
            dValue = { value: dValue, precision: targetField.value.precision ?? "minute" };
          }
        }
      }
    }
    if (dValue !== undefined) {
      return toConstant(dValue, { unit });
    }
    return undefined;
  });

  const getCondition = (): string | undefined => {
    if (!selectedOperation.value) {
      throw new Error("Operation not selected!");
    }
    if (!operationDisplayName.value) {
      throw new Error("Operation name not found!");
    }
    const field = selectedField.value;
    if (!field) {
      throw new Error("Field not selected!");
    }
    const accessor = "expression" in field ? field.expression : `${toValue(prefix)}.${field.id}`;
    if (isSingleOperation.value) {
      switch (selectedOperation.value) {
        case "bool":
          return accessor;
        case "!bool":
          return `not ${accessor}`;
      }
      return `${accessor} ${selectedOperation.value}`;
    }
    if (!targetValue.value) {
      throw new Error("Target value not set!");
    }
    if (selectedOperation.value.startsWith("!")) {
      return `not ${accessor} ${selectedOperation.value.slice(1)} ${targetValue.value}`;
    }
    return `${accessor} ${selectedOperation.value} ${targetValue.value}`;
  };

  const isValid = computed<boolean>(() => isSingleOperation.value || !!comparisonTarget.value || !isBlank(genericTarget.value));

  return {
    selectedField,
    targetField,
    validFields,
    selectedOperation,
    operationOptions,
    isSingleOperation,
    comparisonFields,
    comparisonTarget,
    genericTarget,
    getCondition,
    isValid
  };
};
