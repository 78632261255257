import { parseQuantity, quantityRegex } from "../../units/parseUnit";
import { DurationConstant, NumberConstant } from "../constants";
import { DiffExpression, DurationSumExpression, MetricToDurationCast, NumberToDurationExpression, PrecisionDiffExpression } from "../duration";
import { ParsingError, type DurationExpression } from "../expressions";
import { datetimePrecisionOptions } from "../../fields/basic/DatetimeField";
import { toPlural } from "../../helpers/strings/Plurals";
import { refine } from "../../helpers/NullHelpers";
import { coalesceExpression } from "../generic";
import { isLowerPrecision } from "../datetime";

import { checkAndSplit } from "./operators";

import type { KContext } from "../context";
import type { ExpressionParser } from "../parser";

export function parseDuration(this: ExpressionParser, expression: string | string[], context: KContext): DurationExpression {
  return this.baseParse(expression, "duration", context, {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    recurse: parseDuration.bind(this),
    single: (token) => {
      if (token === "null") {
        return new DurationConstant(undefined, "year");
      }
      // check for accessors
      // const match = token.match(accessorRegex);
      // if (match) {
      //   const field = getFieldOfType(context, match[1], "number");
      //   return new DurationAccessor(field);
      // }
      // const itemMatch = token.match(itemRegex);
      // if (itemMatch) {
      //   const depth = itemMatch[1] ? parseInt(itemMatch[1]) - 1 : 0;
      //   return new NumberItemExpression(depth);
      // }
      if (quantityRegex.test(token)) {
        const { value, unit } = parseQuantity(token);
        return new MetricToDurationCast(new NumberConstant(value, unit));
      }
      throw new Error(`Unknown duration constant: ${token}`);
    },
    multi: (tokens) => {
      const addsub = checkAndSplit(tokens, ["+"]);
      if (addsub) {
        const subexpressions: DurationExpression[] = refine(addsub, (s) => (typeof s === "string" ? undefined : this.parseDuration(s, context)));
        if (subexpressions.length === 1) {
          return subexpressions[0];
        }
        if (!subexpressions.every((s) => s.precision === subexpressions[0].precision)) {
          throw new Error("Can't add durations of different precisions");
        }
        return new DurationSumExpression(subexpressions);
      }
      const coalesce = checkAndSplit(tokens, ["??"]);
      if (coalesce) {
        return coalesceExpression(refine(coalesce, (s) => (Array.isArray(s) ? this.parseDuration(s, context) : undefined)));
      }
      throw new Error(`Unknown duration expression: ${tokens.join(" ")}`);
    }
  });
}

// register functions for converting numbers to durations
export const registerDurationFunctions = (parser: ExpressionParser): void => {
  for (const precision of datetimePrecisionOptions) {
    parser.registerFunction(toPlural(precision), (tokens, context) => {
      if (tokens.length !== 1) {
        throw new ParsingError(`Invalid ${precision} function: must have exactly one argument`);
      }
      return new NumberToDurationExpression(precision, parser.parseNumber(tokens[0], context));
    });
    if (isLowerPrecision(precision, "hour")) {
      // add extra diff_ functions for lower precisions
      const name = `diff_${toPlural(precision)}`;
      parser.registerFunction(name, (tokens, context) => {
        if (tokens.length !== 2) {
          throw new ParsingError(`Invalid ${name} function: must have exactly two arguments`);
        }
        return new PrecisionDiffExpression(parser.parseDatetime(tokens[0], context), parser.parseDatetime(tokens[1], context), precision);
      });
    }
  }
  parser.registerFunction("diff", (tokens, context) => {
    if (tokens.length !== 2) {
      throw new ParsingError(`Invalid diff function: must have exactly two arguments`);
    }
    return new DiffExpression(parser.parseDatetime(tokens[0], context), parser.parseDatetime(tokens[1], context));
  });
};
