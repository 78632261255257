import { StringField } from "../basic/StringFields";

import type { Person } from "../../data/Person";
import type { KRecord } from "../../data/Record";

// cheeky global variable to get userField display values to work correctly
export const globalUsers = new Map<string, Person>();

/** Used for createdBy/updatedBy fields - stores the user ID */
export class UserField extends StringField {
  serialisationKey = "user";

  // TODO KB-182: add proper display functionality
  getDisplayValue(r: KRecord): string {
    const value = this.getValue(r);
    if (value === undefined) return "";
    return globalUsers.get(value)?.name ?? "Unknown User";
  }
}
