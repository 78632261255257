<template>
  <k-input-autocomplete v-model="input" :label :items="options ?? []" :loading="!options" />
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from "vue";

import KInputAutocomplete from "@ui/inputs/autocomplete/KInputAutocomplete.vue";

import type { Collection } from "@data/data/Collection";
import type { FilterablePermission } from "@data/helpers/data/Permissions";

import { useCollectionsWithPermission } from "@/helpers/permissions";

const props = defineProps<{
  /** Current value of the input (Collection ID) */
  modelValue?: string;
  /** Label for the input */
  label?: string;
  /** Condition for the collections list */
  permission?: FilterablePermission;
  /** Collections to pick from (if not passed, defaults to all collections) */
  collections?: Collection[];
}>();
const emit = defineEmits<{
  (event: "update:modelValue", newValue: string | undefined): void;
}>();

const input = ref<{ id: string; name?: string }>();
const withPermission = useCollectionsWithPermission(
  computed(() => props.permission),
  computed(() => props.collections)
);
const options = computed(() => withPermission.value.map((c) => ({ id: c.id, name: c.plural })));
watch(
  () => props.modelValue,
  (id) => {
    if (id === undefined) {
      input.value = undefined;
    } else if (id !== input.value?.id) {
      input.value = { id };
    }
  },
  { immediate: true }
);
watch(input, (newRecord) => {
  emit("update:modelValue", newRecord?.id);
});
// Fill out name once options load
watchEffect(() => {
  const currentInput = input.value;
  if (options.value.length && currentInput && !currentInput.name) {
    input.value = options.value.find((o) => o.id === currentInput.id);
  }
});
</script>
