import { v4 } from "uuid";

import { KEntity } from "../data/KEntity";
import { DatetimeField } from "../fields/basic/DatetimeField";
import { DurationField } from "../fields/basic/DurationField";
import { MetricField } from "../fields/basic/MetricField";
import { OptionField, MultiOptionField } from "../fields/basic/OptionFields";
import { BooleanField, NumberField, ProbabilityField, RatingField } from "../fields/basic/SimpleFields";
import { CurrencyField, isCurrencyType } from "../fields/basic/CurrencyField";
import { StringField, RichTextField } from "../fields/basic/StringFields";
import { FileField, MultiFileField } from "../fields/complex/FileField";
import { LocationField } from "../fields/complex/LocationField";
import { RiskField } from "../fields/complex/RiskField";
import { TimeSpanField } from "../fields/complex/TimeSpanField";
import { ComputedNumberField } from "../fields/computed/ComputedNumberField";
import { ComputedStringField } from "../fields/computed/ComputedStringField";
import { EmailField } from "../fields/contact/EmailField";
import { PhoneField } from "../fields/contact/PhoneField";
import { WebsiteField } from "../fields/contact/WebsiteField";
import { AggregationField } from "../fields/relational/AggregationField";
import { PeopleField, PersonField } from "../fields/relational/PeopleFields";
import { RecordField, MultiRecordField } from "../fields/relational/RecordFields";
import { ColourField } from "../fields/utility/ColourField";
import { UnknownField } from "../fields/utility/UnknownField";
import { toCamelCase } from "../helpers/strings/Casing";
import { LookupField } from "../fields/relational/LookupField";
import { ComputedDatetimeField } from "../fields/computed/ComputedDatetimeField";

import { createMetricField, metricFieldTypes } from "./metricFieldTypes";
import { standardFieldDefinitions } from "./fieldDefinitions";

import type { LookupFieldConfig } from "../fields/relational/LookupField";
import type { Option } from "../fields/basic/OptionFields";
import type { ColourVariant } from "../types/ColourVariant";
import type { RelationalFilters } from "../fields/relational/RecordFields";
import type { AggregationFieldConfig } from "../fields/relational/AggregationField";
import type { TimeSpanPrecision } from "../fields/complex/TimeSpanField";
import type { Stage } from "../fields/complex/StageField";
import type { RatingFieldConfig } from "../fields/basic/SimpleFields";
import type { MinutePrecision } from "../fields/basic/DurationField";
import type { DatetimePrecision } from "../fields/basic/DatetimeField";
import type { KField, KFieldSetup } from "../fields/KField";
import type { MetricFieldType } from "./metricFieldTypes";
import type { FieldTypeDefinition } from "./fieldDefinitions";

export const fieldTypes = {
  ...standardFieldDefinitions,
  ...metricFieldTypes
} satisfies Record<string, FieldTypeDefinition>;
export type FieldType = keyof typeof fieldTypes | `record-${string}` | `multiRecord-${string}` | "unknown";

// START exporting fieldTypeCategories
const commonTypesOrderedByUseFreq: FieldType[] = [
  "string",
  "richText",
  "option",
  "multiOption",
  "number",
  "probability",
  "currency",
  "percentage",
  "boolean",
  "date",
  "datetime",
  "timeSpan",
  "location",
  "email",
  "website",
  "phone",
  "file"
];
const incompleteFieldTypeCategories = new Map<string, FieldType[]>([
  ["All", []],
  ["Text", ["string", "richText", "email", "website", "phone", "computedString"]],
  ["Numeric", ["number", "rating", "percentage", "probability", "currency", "phone", "computedNumber"]],
  [
    "Measurements",
    [
      "length",
      "miles",
      "area",
      "volume",
      "mass",
      "duration",
      "time",
      "data",
      "degrees",
      "current",
      "voltage",
      "charge",
      "energy",
      "power",
      "resistance",
      "capacitance",
      "inductance"
    ]
  ],
  ["Date & Time", ["date", "datetime", "month", "year", "duration", "timeSpan"]],
  ["Location", ["location"]],
  ["Choice", ["boolean", "option", "multiOption", "rating"]],
  ["People", ["person", "people"]],
  ["File", ["file", "image", "multiFile", "multiImage"]],
  ["Compliance", ["risk"]],
  ["Computed", ["computedString", "computedNumber", "computedDatetime", "lookup", "aggregation"]]
]);
const allTypes = [...commonTypesOrderedByUseFreq, ...(Object.keys(fieldTypes) as FieldType[]).filter((type) => !commonTypesOrderedByUseFreq.includes(type))];
export const fieldTypeCategories = incompleteFieldTypeCategories.set("All", allTypes);
// END exporting fieldTypeCategories

export const optionEntity = new KEntity(
  "Option",
  [new StringField("Label"), new ColourField({ key: "variant", label: "Colour" }), new StringField("Secondary Label")],
  {
    primaryKey: "label",
    secondaryKey: "variant"
  }
);

// Only add optional fields here this affects all fields.
export interface ExtraFieldOptions {
  options?: { label: string; id: string; variant?: ColourVariant; secondaryLabel?: string }[];
  permissions?: KField["permissions"];
  stages?: Stage[];
  precision?: DatetimePrecision;
  range?: RatingFieldConfig;
  minutePrecision?: MinutePrecision;
  defaultValue?: string | number | string[];
  description?: string;
  showAsBadge?: boolean;
  booleanDisplay?: BooleanField["displayType"];
  allowOther?: boolean;
  allowActual?: boolean;
  canAdd?: boolean;
  expression?: string;
  unit?: string;
  lockUnit?: boolean;
  relationalFilters?: RelationalFilters;
  canCreateRecord?: boolean;
  id?: string;
  lookupOptions?: LookupFieldConfig;
  aggregationOptions?: AggregationFieldConfig;
}

// get a unique key for the field to avoid conflicts with existing fields (especially intrinsic fields like "Created At")
const getUniqueKey = (name: string, existingKeys: string[]): string | undefined => {
  const normalKey = toCamelCase(name);
  const keySet = new Set(existingKeys);
  if (!keySet.has(normalKey)) {
    return undefined;
  }
  // start at 2 since the "first" key is already taken
  for (let i = 2; ; i++) {
    const key = `${normalKey}${i}`;
    if (!keySet.has(key)) {
      return key;
    }
    i++;
  }
};

const basicFields = new Map<FieldType, new (setup: KFieldSetup) => KField>([
  ["string", StringField],
  ["richText", RichTextField],
  ["location", LocationField],
  ["phone", PhoneField],
  ["email", EmailField],
  ["website", WebsiteField],
  ["file", FileField],
  ["multiFile", MultiFileField],
  ["unknown", UnknownField],
  ["risk", RiskField]
]);

const numberFields = new Map<FieldType, new (setup: KFieldSetup & { defaultValue?: number }) => KField>([
  ["number", NumberField],
  ["probability", ProbabilityField]
]);

const dateTimePrecisionMap = {
  date: "day",
  month: "month",
  year: "year"
} satisfies Partial<Record<FieldType, DatetimePrecision>>;

const toTimeSpanPrecision = (precision: DatetimePrecision): TimeSpanPrecision => {
  switch (precision) {
    case "year":
    case "quarter":
    case "month":
      return "day";
    default:
      return precision;
  }
};

// eslint-disable-next-line complexity
const switchField = (type: FieldType, baseSetup: KFieldSetup, extra: ExtraFieldOptions) => {
  const BasicField = basicFields.get(type);
  if (BasicField) {
    return new BasicField(baseSetup);
  }
  const NField = numberFields.get(type);
  if (NField) {
    return new NField({ ...baseSetup, defaultValue: typeof extra.defaultValue === "number" ? extra.defaultValue : undefined });
  }
  switch (type) {
    case "rating": {
      const range = extra.range ?? { min: 1, max: 10 };
      return new RatingField({ ...range, ...baseSetup, defaultValue: typeof extra.defaultValue === "number" ? extra.defaultValue : undefined });
    }
    case "boolean":
      return new BooleanField({
        ...baseSetup,
        displayType: extra.booleanDisplay ?? "TEXT"
      });
    case "currency":
      return new CurrencyField({ ...baseSetup, currency: extra.unit && isCurrencyType(extra.unit) ? extra.unit : undefined });
    case "datetime":
      return new DatetimeField({ ...baseSetup, precision: extra.precision ?? "minute", default: extra.defaultValue === "now" ? "now" : undefined });
    case "date":
    case "month":
    case "year":
      return new DatetimeField({ ...baseSetup, precision: dateTimePrecisionMap[type], default: extra.defaultValue === "now" ? "now" : undefined });
    case "duration":
      return new DurationField({ ...baseSetup, precision: extra.precision, minutePrecision: extra.minutePrecision });
    case "timeSpan":
      return new TimeSpanField({ ...baseSetup, precision: toTimeSpanPrecision(extra.precision ?? "minute"), allowActual: extra.allowActual ?? false });
    case "person":
      return new PersonField({ ...baseSetup, filters: extra.relationalFilters, defaultValue: extra.defaultValue === "current" ? "current" : undefined });
    case "people":
      return new PeopleField({ ...baseSetup, filters: extra.relationalFilters });
    case "option":
    case "multiOption": {
      const options = extra.options ?? [];
      const converted: Option[] = options.map((nr, idx) => ({
        label: nr.label,
        value: nr.id,
        variant: nr.variant,
        sortOrder: idx
      }));
      if (type === "option")
        return new OptionField(
          { ...baseSetup, allowOther: extra.allowOther, defaultOption: typeof extra.defaultValue === "string" ? extra.defaultValue : undefined },
          converted
        );
      return new MultiOptionField(
        {
          ...baseSetup,
          canAdd: extra.canAdd,
          defaultOptions: Array.isArray(extra.defaultValue) && extra.defaultValue.every((d) => typeof d === "string") ? extra.defaultValue : undefined
        },
        converted
      );
    }
    case "image":
      return new FileField({ ...baseSetup, accept: "image/*" });
    case "multiImage":
      return new MultiFileField({ ...baseSetup, accept: "image/*" });
    case "computedNumber":
      return new ComputedNumberField({ ...baseSetup, unit: extra.unit }, extra.expression);
    case "computedString":
      return new ComputedStringField(baseSetup, extra.expression);
    case "computedDatetime":
      return new ComputedDatetimeField({ ...baseSetup, precision: extra.precision }, extra.expression);
    case "aggregation": {
      const options = extra.aggregationOptions ?? { link: {}, mode: "count" };
      return new AggregationField({ ...baseSetup, ...options });
    }
    case "lookup":
      return new LookupField({ ...baseSetup, ...extra.lookupOptions });
  }
  if (type in metricFieldTypes) {
    return createMetricField(baseSetup, type as MetricFieldType, {
      ...extra,
      defaultValue: typeof extra.defaultValue === "number" ? extra.defaultValue : undefined
    });
  }
  if (type.startsWith("record-")) {
    const collectionId = type.replace("record-", "");
    return new RecordField({ ...baseSetup, filters: extra.relationalFilters, canCreateRecord: extra.canCreateRecord }, collectionId);
  }
  if (type.startsWith("multiRecord-")) {
    const collectionId = type.replace("multiRecord-", "");
    return new MultiRecordField({ ...baseSetup, filters: extra.relationalFilters, canCreateRecord: extra.canCreateRecord }, collectionId);
  }
  throw new Error(`Unknown field type: ${type}`);
};

export const createField = (type: FieldType, name: string, existingKeys: string[], extra: ExtraFieldOptions = {}): KField | undefined => {
  const baseSetup: KFieldSetup = { label: name, permissions: extra.permissions };
  const uniqueKey = getUniqueKey(name, existingKeys);
  if (uniqueKey) {
    baseSetup.key = uniqueKey;
  }
  if (extra.description) {
    baseSetup.description = extra.description;
  }
  const result = switchField(type, baseSetup, extra);
  result.id = extra.id ?? v4();
  return result;
};

export const getBadgeColour = (field: KField): ColourVariant => {
  // do not ask how I am assigning these - Thomas
  if (field instanceof StringField) return "yellow";
  if (field instanceof RichTextField) return "orange";
  if (field instanceof OptionField || field instanceof MultiOptionField) return "red";
  if (field instanceof NumberField || field instanceof MetricField) return "blue";
  if (field instanceof BooleanField) return "green";
  if (field instanceof DatetimeField || field instanceof TimeSpanField) return "cyan";
  if (field instanceof PersonField || field instanceof PeopleField) return "pink";
  if (field instanceof RecordField) return "purple";
  if (field instanceof MultiRecordField) return "indigo";
  return "gray";
};
