import { filterEmpty } from "../../helpers/NullHelpers";
import { toCamelCase } from "../../helpers/strings/Casing";
import { BulkData } from "../../expressions/data";

import { SimpleField } from "./SimpleFields";

import type { ExpressionField } from "../../expressions/accessors";
import type { KRecord } from "../../data/Record";
import type { ColourVariant } from "../../types/ColourVariant";
import type { KField, KFieldSetup } from "../KField";
import type { BadgeCell, TableCell, Badge } from "../TableCell";

/** Option for a OptionField, used to define how this option is displayed */
export type Option = {
  label: string;
  secondaryLabel?: string;
  value: string; // should be unique across all options in an OptionField
  icon?: string;
  variant?: ColourVariant;
  sortOrder?: number;
};

type OtherOption = Omit<Option, "value">;

/** Value of an OptionField */
export type OptionValue = string | { other: string };

interface OptionFieldSetup extends KFieldSetup {
  allowOther?: boolean;
  otherLabel?: string;
  canAdd?: boolean;
}

/** Helper function to create Options without specifying the name twice */
export const simpleOption = (label: string, secondaryLabel?: string): Option => {
  if (secondaryLabel) {
    return { label, secondaryLabel, value: toCamelCase(label) };
  }
  return { label, value: toCamelCase(label) };
};

const validateOptionValue = (value: unknown, options: Option[]): value is OptionValue => {
  if (!value) return false;
  if (typeof value === "string") {
    return options.some((o) => o.value === value);
  }
  if (typeof value === "object" && "other" in value) return true;
  return false;
};

export class OptionField extends SimpleField<OptionValue> implements ExpressionField<"string"> {
  readonly type = "option";

  options: Option[];

  optionMap: Map<string, Option>;

  colourMap: Map<string, ColourVariant>;

  showAsBadge = false;

  otherLabel?: string;

  sortFieldDirectionLabels = {
    ASC: "Default",
    DESC: "Inverted"
  };

  readonly defaultOption?: string;

  // Cache badge cell values for memory efficiency
  cellMap = new Map<string, BadgeCell>();

  readonly expressionType = "string";

  readonly granular = true;

  constructor(setup: (OptionFieldSetup & { defaultOption?: string }) | string, options: Option[]) {
    super(setup);
    if (typeof setup === "object") {
      this.otherLabel = setup.allowOther ? (setup.otherLabel ?? "Other") : undefined;
      // only set default option if it is one of the options
      this.defaultOption = setup.defaultOption ? options.find((o) => o.value === setup.defaultOption)?.value : undefined;
    }
    this.options = options;
    this.optionMap = new Map(this.options.map((o) => [o.value, o]));
    this.colourMap = new Map();
    this.showAsBadge = options.some((o) => o.variant);
    for (const o of this.options) {
      this.cellMap.set(o.value, {
        type: "badge",
        text: o.label,
        colour: o.variant
      });
      if (o.variant) {
        this.colourMap.set(o.value, o.variant);
        this.colourMap.set(o.label, o.variant);
      }
    }
  }

  getDefaultValue() {
    return this.defaultOption;
  }

  getSortableValue(record: KRecord, toLowerCase = false): number | string | undefined {
    const option = this.getOptionValue(record);
    if (!option) {
      return undefined;
    }
    if (option.sortOrder !== undefined) {
      return option.sortOrder;
    }
    return toLowerCase ? option.label.toLowerCase() : option.label;
  }

  getOptionValue(record: KRecord): Option | OtherOption | undefined {
    const value = this.getValue(record);
    switch (typeof value) {
      case "string":
        return this.optionMap.get(value);
      case "object":
        if ("other" in value) {
          return { label: value.other };
        }
    }
  }

  getDisplayValue(record: KRecord): string {
    return this.getOptionValue(record)?.label ?? "";
  }

  getColourVariant(displayValue: string): ColourVariant | undefined {
    return this.colourMap.get(displayValue);
  }

  getExpressionValue(record: KRecord): string | undefined {
    return this.getDisplayValue(record);
  }

  getTableCell(record: KRecord): TableCell | undefined {
    if (!this.showAsBadge) return super.getTableCell(record);
    const value = this.getValue(record);
    switch (typeof value) {
      case "string":
        return this.cellMap.get(value);
      case "object":
        if ("other" in value) {
          return { type: "badge", text: value.other, colour: "gray" };
        }
    }
  }

  getDefaultColumnWidth() {
    const maxLength = Math.max(...this.options.map((o) => o.label.length));
    return Math.min(180, Math.max(80, maxLength * 8));
  }

  validateType(value: unknown): value is OptionValue {
    return validateOptionValue(value, this.options);
  }

  override isAssignableTo(otherField: KField): boolean {
    if (otherField instanceof OptionField) {
      return this.options.every((o) => otherField.options.some((oo) => oo.value === o.value));
    }
    return false;
  }
}

export class MultiOptionField extends SimpleField<OptionValue[]> implements ExpressionField<"data"> {
  readonly type = "multiOption";

  options: Option[];

  optionMap: Map<string, Option>;

  badgeMap: Map<string, Badge> = new Map();

  showAsBadge: boolean;

  canAdd?: boolean;

  otherLabel?: string;

  sortFieldDirectionLabels = {
    ASC: "Default",
    DESC: "Inverted"
  };

  readonly defaultOptions: string[] = [];

  readonly expressionType = "data";

  constructor(setup: string | (OptionFieldSetup & { defaultOptions?: string[] }), options: Option[]) {
    super(setup);
    if (typeof setup === "object") {
      this.otherLabel = setup.allowOther ? (setup.otherLabel ?? "Other") : undefined;
      // only set default options if they are in the options
      const actualOptions = new Set(options.map((o) => o.value));
      this.defaultOptions = setup.defaultOptions ? setup.defaultOptions.filter((o) => actualOptions.has(o)) : [];
      this.canAdd = setup.canAdd ?? false;
    }
    this.options = options;
    this.optionMap = new Map(options.map((o) => [o.value, o]));
    this.showAsBadge = options.some((o) => o.variant);
    this.badgeMap = new Map(options.map((o) => [o.value, { text: o.label, colour: o.variant }]));
  }

  getDefaultValue() {
    return this.defaultOptions;
  }

  getSortableValue(record: KRecord, toLowerCase = false): string | undefined {
    const options = this.getOptionValue(record);
    const sortValue = options
      .map((o) => o.label)
      .sort()
      .join(", ");
    return toLowerCase ? sortValue.toLowerCase() : sortValue;
  }

  getOptionValue(record: KRecord): (Option | OtherOption)[] {
    const values = this.getValue(record);
    if (values !== undefined) {
      return values.map((v) => {
        if (typeof v === "object" && "other" in v) {
          return { label: v.other };
        }
        const option = this.optionMap.get(v);
        if (option === undefined) {
          throw new Error(`Option with value ${v} not found in field ${this.label}`);
        }
        return option;
      });
    }
    return [];
  }

  getDisplayValue(record: KRecord): string {
    return this.getOptionValue(record)
      .sort((a, b) => {
        if (a.sortOrder !== undefined && b.sortOrder !== undefined) {
          return a.sortOrder - b.sortOrder;
        }
        return a.label.localeCompare(b.label);
      })
      .map((o) => o.label)
      .join(", ");
  }

  getExpressionValue(r: KRecord): BulkData<string> | undefined {
    return new BulkData(this.getOptionValue(r).map((o) => o.label));
  }

  getTableCell(record: KRecord): TableCell | undefined {
    if (!this.showAsBadge) return super.getTableCell(record);
    const values = this.getValue(record);
    return (
      values && {
        type: "badges",
        badges: filterEmpty(
          values.map((v) => {
            switch (typeof v) {
              case "string":
                return this.badgeMap.get(v);
              case "object":
                if ("other" in v) {
                  return { text: v.other, colour: "gray" };
                }
            }
          })
        )
      }
    );
  }

  validateType(value: unknown): value is OptionValue[] {
    if (!Array.isArray(value)) return false;
    return value.every((v) => validateOptionValue(v, this.options));
  }

  override isAssignableTo(otherField: KField): boolean {
    if (otherField instanceof MultiOptionField) {
      return this.options.every((o) => otherField.options.some((oo) => oo.value === o.value));
    }
    return false;
  }
}
