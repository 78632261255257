import type { Component } from "vue";

import { FileUpload } from "../inputs/file/FileUpload";

import KBadge from "@ui/badge/KBadge.vue";
import KIcon from "@ui/icon/KIcon.vue";
import KDisplayLink from "@ui/displays/KDisplayLink.vue";
import KDisplayLocation from "@ui/displays/KDisplayLocation.vue";
import KDisplayPhone from "@ui/displays/KDisplayPhone.vue";
import KDisplayRichText from "@ui/displays/KDisplayRichText.vue";
import KDisplayRisk from "@ui/displays/KDisplayRisk.vue";

import KDisplayFile from "./KDisplayFile.vue";
import MultiRecordDisplay from "./MultiRecordDisplay.vue";
import RecordDisplay from "./RecordDisplay.vue";
import PhoneDisplay from "./PhoneDisplay.vue";

import type { KEntity } from "@data/data/KEntity";
import type { KRecord } from "@data/data/Record";
import { PartialFunctionRegistry } from "@data/helpers/serialisation/Registry";
import { Collection } from "@data/data/Collection";
import { peopleCollectionName } from "@data/constants/names";

import type { extendedFieldRegistry } from "../inputs/fieldInputs";

export const getFieldDisplay = new PartialFunctionRegistry<
  typeof extendedFieldRegistry,
  { component: Component; props: Record<string, unknown> } | undefined,
  [KRecord, KEntity]
>({
  richText: (f, r) => ({ component: KDisplayRichText, props: { content: f.getValue(r) } }),
  icon: (f, r) => ({ component: KIcon, props: { icon: f.getValue(r) } }),
  website: (f, r) => ({ component: KDisplayLink, props: { link: f.getValue(r) } }),
  phone: (f, r, e) => {
    if (e instanceof Collection) {
      return { component: PhoneDisplay, props: { value: f.getValue(r), collection: e, recordId: r.id } };
    }
    return { component: KDisplayPhone, props: { value: f.getValue(r) } };
  },
  risk: (f, r) => ({ component: KDisplayRisk, props: { value: f.getValue(r) } }),
  email: (f, r) => {
    const value = f.getValue(r);
    return { component: KDisplayLink, props: { label: value, link: value ? `mailto:${value}` : "" } };
  },
  file: (f, r) => {
    const value = f.getValue(r);
    if (!value) {
      return { component: KDisplayFile, props: {} };
    }
    const upload = new FileUpload();
    upload.description = value.name;
    upload.url = "/api/v1/files/" + value.id;
    upload.id = value.id;
    return { component: KDisplayFile, props: { modelValue: [upload] } };
  },
  multiFile: (f, r) => {
    const value = f.getValue(r);
    if (!value) {
      return { component: KDisplayFile, props: {} };
    }
    const uploads = value.map((file) => {
      const upload = new FileUpload();
      upload.description = file.name;
      upload.url = "/api/v1/files/" + file.id;
      upload.id = file.id;
      return upload;
    });
    return { component: KDisplayFile, props: { modelValue: uploads } };
  },
  location: (f, r, e) => ({ component: KDisplayLocation, props: { value: f.getValue(r), title: e.primaryField.getDisplayValue(r) } }),
  record: (f, r) => ({ component: RecordDisplay, props: { value: f.getValue(r), collectionId: f.collectionId } }),
  multiRecord: (f, r) => ({ component: MultiRecordDisplay, props: { value: f.getValue(r), collectionId: f.collectionId } }),
  person: (f, r) => ({ component: RecordDisplay, props: { value: f.getValue(r), collectionSlug: peopleCollectionName.slug } }),
  people: (f, r) => ({ component: MultiRecordDisplay, props: { value: f.getValue(r), collectionSlug: peopleCollectionName.slug } }),
  colour: (f, r) => ({ component: KBadge, props: { label: f.getDisplayValue(r) || "None", variant: f.getValue(r) } })
});
