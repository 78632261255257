<template>
  <slot></slot>
</template>

<script setup lang="ts">
import { computed, provide } from "vue";

import { inputConfigKey } from "./inputConfig";

import type { ValidationIssues } from "@data/validation/Validation";

import type { InputSize, InputStatus, InputGroupPositition } from "./inputConfig";

const props = withDefaults(
  defineProps<{
    /** Placeholder value */
    placeholder?: string;
    /** Size of the input element */
    size?: InputSize;
    /** The status of the input (e.g. valid/invalid) */
    status?: InputStatus;
    /** Whether this input can be edited */
    readonly?: boolean;
    /** Whether this input is disabled */
    disabled?: boolean;
    /** Whether a value is required */
    required?: boolean;
    /** Validation issues to show */
    issues?: ValidationIssues;
    /** Extra CSS classes for the input to use */
    inputClass?: string;
    /** Hide label for input, must be placed elsewhere */
    hideLabel?: boolean;
    /** Description for the input */
    description?: string;
    /** Whether the description should be inline */
    inlineDescription?: boolean;
    /** Whether the collection should not have a form-control-container */
    noContainer?: boolean;
    /** Whether the input should visually combine with adjacent inputs */
    sticky?: InputGroupPositition;
    /** Indicates whether to focus itself when it mounts */
    autofocus?: boolean;
  }>(),
  {
    placeholder: undefined,
    size: "sm",
    status: "NONE",
    issues: () => [],
    inputClass: undefined,
    description: undefined,
    inlineDescription: true,
    sticky: "none",
    autofocus: false
  }
);

// don't think there's a neater way to do this while maintaining reactivity
provide(
  inputConfigKey,
  computed(() => ({
    placeholder: props.placeholder,
    size: props.size,
    status: props.status,
    readonly: props.readonly,
    disabled: props.disabled,
    required: props.required,
    issues: props.issues,
    inputClass: props.inputClass,
    hideLabel: props.hideLabel,
    description: props.description,
    inlineDescription: props.inlineDescription,
    noContainer: props.noContainer,
    sticky: props.sticky,
    autofocus: props.autofocus
  }))
);
</script>
