import { StringField } from "../fields/basic/StringFields";
import { RolesField } from "../fields/utility/RolesField";

import type { KField } from "../fields/KField";
import type { KRecord, StoredKRecord } from "./Record";

export interface Person extends KRecord {
  id: string;
  name: string;
  status: "ACTIVE" | "INVITED" | "DEACTIVATED";
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  email: string;
  emails: string[];
  roles?: string[];
  userId: string;
  extraData?: KRecord;
  __typename: "Person";
}

export type ServerPerson = Person & { extraData?: StoredKRecord };

export const personSchema: KField[] = [
  new StringField("Name"),
  new StringField({ label: "First Name", table: { hiddenByDefault: true } }),
  new StringField({ label: "Last Name", table: { hiddenByDefault: true } }),
  new StringField("Job Title"),
  new StringField("Email"),
  new RolesField("Roles")
];

/** Helper function to create people in unit tests */
export const createPerson = (id: string, name: string, emails: string[] = []): ServerPerson & Person => {
  if (!emails.length) {
    emails = [`${name.toLowerCase().replace(" ", ".")}@example.com`];
  }
  return {
    id,
    name,
    firstName: name.split(" ")[0],
    lastName: name.split(" ").at(1),
    email: emails[0],
    emails,
    roles: [],
    userId: `user-${id}`,
    status: "ACTIVE",
    __typename: "Person"
  };
};
