import type { KContext } from "./context";
import type { BulkData } from "./data";
import type { DatetimePrecision } from "../fields/basic/DatetimeField";
import type { MetricUnit } from "../units/units";
import type { Dayjs } from "dayjs";

abstract class BaseExpression<R> {
  abstract evaluate(context: KContext): R | undefined;

  abstract display(context: KContext): string;
}

export abstract class BooleanExpression extends BaseExpression<boolean> {
  readonly type = "boolean";
}

export abstract class StringExpression extends BaseExpression<string> {
  readonly type = "string";
}

export abstract class NumberExpression extends BaseExpression<number> {
  readonly type = "number";

  unit: MetricUnit | undefined;
}

export abstract class DatetimeExpression extends BaseExpression<Dayjs> {
  readonly type = "datetime";

  readonly precision: DatetimePrecision;

  constructor(precision: DatetimePrecision) {
    super();
    this.precision = precision;
  }
}

export abstract class DurationExpression extends BaseExpression<number> {
  readonly type = "duration";

  abstract precision: DatetimePrecision;
}

export type SingleExpression = BooleanExpression | StringExpression | NumberExpression | DatetimeExpression | DurationExpression;

export abstract class DataExpression extends BaseExpression<BulkData<ReturnType<SingleExpression["evaluate"]>>> {
  readonly type = "data";
}

export type Expression = SingleExpression | DataExpression;
export type ExpressionData = ReturnType<Expression["evaluate"]>;
export type ExpressionReturnType<V extends Expression["type"] = Expression["type"]> =
  | {
      boolean: boolean;
      string: string;
      number: number;
      datetime: Dayjs;
      duration: number;
      data: BulkData<ReturnType<SingleExpression["evaluate"]>>;
    }[V]
  | undefined;

/** For expressions that change their display value when inside a not expression */
export interface InvertableExpression {
  displayInverted(context: KContext): string;
}

export const isInvertable = (e: Expression): e is Expression & InvertableExpression => "displayInverted" in e;

export class ParsingError extends Error {
  constructor(message: string) {
    super(message);
  }
}
