import { computed, inject, provide, unref } from "vue";
import type { InjectionKey, Ref } from "vue";

import type { ValidationIssues } from "@data/validation/Validation";
import { getMaxSeverity } from "@data/validation/Validation";

import type { MaybeRef } from "@vueuse/core";

export type InputSize = "sm" | "md" | "lg";
export type InputStatus = "NONE" | "VALID" | "INVALID";
export type InputSubtype = "control" | "select" | "check-input";

// Defines the position of input parts relative to the input (e.g. used for month and year in a date input)
export type InputGroupPositition = "none" | "left" | "right" | "both";

export interface InputConfig {
  placeholder?: string;
  size: InputSize;
  status: InputStatus;
  readonly: boolean;
  disabled: boolean;
  required: boolean;
  inputClass: string;
  issues: ValidationIssues;
  hideLabel: boolean;
  description?: string;
  inlineDescription: boolean;
  noContainer: boolean;
  sticky: InputGroupPositition;
  autofocus: boolean;
}

export const inputConfigKey = Symbol() as InjectionKey<MaybeRef<Partial<InputConfig>>>;

export const defaultConfig: InputConfig = {
  size: "sm",
  status: "NONE",
  required: false,
  inputClass: "",
  issues: [],
  hideLabel: false,
  inlineDescription: true,
  readonly: false,
  disabled: false,
  noContainer: false,
  sticky: "none",
  autofocus: false
};

/** Properties that should be overwritten by prop properties if present */
const propKeys = ["placeholder", "size", "status", "readonly", "disabled", "required", "inputClass"] as const;

/**
 * Use the input config that has been provided by a higher-level component
 *
 * @param props Props of the current component, overwrites config values
 * @param consume Set true to reset the config to default values for child components (e.g. if you have an input with multiple sub-inputs)
 */
export const useInputConfig = (props?: Partial<InputConfig>, consume = false): Ref<InputConfig> => {
  const config = inject(inputConfigKey, defaultConfig);
  const reactiveConfig = computed(() => {
    const result = { ...defaultConfig, ...unref(config) };
    if (props) {
      for (const key of propKeys) {
        if (props[key] !== undefined && props[key] !== false) {
          // Need to cast to never otherwise TS complains about the type not being assignable to itself
          result[key] = props[key] as never;
        }
      }
    }
    return result;
  });

  if (consume) {
    provide(inputConfigKey, defaultConfig);
  } else {
    provide(inputConfigKey, reactiveConfig);
  }
  return reactiveConfig;
};

/**
 * Overwrite provided fields in the config while leaving the rest unchanged
 *
 * @param extra Partial config containing the fields to overwrite
 * @param config Current config (optional, prevents additional useInputConfig() call)
 */
export const mergeInputConfig = (extra: Ref<Partial<InputConfig>>, config?: Ref<InputConfig>) => {
  const actualConfig = config ?? useInputConfig();
  provide(
    inputConfigKey,
    computed(() => ({ ...actualConfig.value, ...extra.value }))
  );
};

/** Returns common input CSS classes for input components */
export const getInputClasses = (config: InputConfig, elementType: InputSubtype = "control") => ({
  [`form-${elementType}`]: true,
  [`form-${elementType}-${config.size}`]: true,
  disabled: config.disabled || config.readonly,
  "is-invalid": config.status === "INVALID" || getMaxSeverity(config.issues) === "error",
  "is-valid": config.status === "VALID",
  [`sticky-${config.sticky}`]: config.sticky !== "none",
  [`${config.inputClass || ""}`]: true
});

export const useInputClasses = (config: Ref<InputConfig>, elementType: InputSubtype = "control") => computed(() => getInputClasses(config.value, elementType));

/** Returns common v-binds for <input> elements */
export const commonBindings = (config: InputConfig, label: string | undefined) => ({
  placeholder: config.placeholder || label,
  required: config.required,
  readonly: config.readonly || undefined,
  "aria-required": config.required,
  "aria-invalid": config.status === "INVALID",
  disabled: config.disabled || config.readonly || undefined,
  ariaDisabled: config.disabled || config.readonly || undefined,
  autofocus: config.autofocus
});

/** Helper to provide a config for testing purposes (in global option of render function) */
export const testProvideConfig = (config: Partial<InputConfig>) => ({ provide: { [inputConfigKey as symbol]: config } });
