import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";

import type { ConditionalValidator } from "@data/validation/ConditionalValidation";

interface UpdateCollectionValidatorsInput {
  collectionId: string;
  validators: ConditionalValidator[];
}

export const useUpdateCollectionValidators = () =>
  useMutation<
    {
      updateCollectionValidators: {
        __typename: string;
      };
    },
    { value: UpdateCollectionValidatorsInput }
  >(gql`
    mutation updateCollectionValidators($value: UpdateCollectionValidatorsInput!) {
      updateCollectionValidators(input: $value) {
        __typename
      }
    }
  `);
