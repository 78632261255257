<template>
  <button v-if="showAsLink" role="button" class="btn btn-link p-0" href="#" @click="isVisible = true">Send feedback</button>
  <k-modal v-model:show="isVisible" title="Send Feedback" :loading @ok="submitForm">
    <k-input-select v-model="formValue.type" label="Type" :options="feedbackOptions" />
    <k-input-config :issues="descriptionIssues">
      <k-input-rich-text v-model="formValue.description" label="Message" />
    </k-input-config>
  </k-modal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { FeedbackAPI } from "../../api/feedback";

import { AlertHandler } from "@ui/alerts/alert-handler";
import KModal from "@ui/modal/KModal.vue";
import type { SelectItem } from "@ui/inputs/select/SelectItem";
import KInputRichText from "@ui/inputs/strings/KInputRichText.vue";
import KInputSelect from "@ui/inputs/select/KInputSelect.vue";
import KInputConfig from "@ui/inputs/KInputConfig.vue";

import type { ValidationIssues } from "@data/validation/Validation";

import type { FeedbackInput } from "../../api/feedback";

defineProps<{
  /** Whether to show as link inline */
  showAsLink?: boolean;
}>();

const isVisible = defineModel<boolean>("visible");

const formValue = ref<FeedbackInput>({ type: "SUGGESTION", description: "" });

const feedbackOptions: { label: string; value: FeedbackInput["type"] }[] & SelectItem[] = [
  {
    label: "Idea or Suggestion",
    value: "SUGGESTION"
  },
  {
    label: "Question",
    value: "QUESTION"
  },
  {
    label: "Testimonial or Review",
    value: "TESTIMONIAL"
  },
  {
    label: "Problem",
    value: "PROBLEM"
  },
  {
    label: "General Feedback",
    value: "OTHER"
  }
];

const { mutate, loading } = FeedbackAPI.useAddFeedback();

const alertHandler = new AlertHandler();

const attemptedSubmit = ref(false);

watch(isVisible, (val) => {
  if (val) {
    formValue.value = { type: "SUGGESTION", description: "" };
  } else {
    attemptedSubmit.value = false;
  }
});

const descriptionIssues = computed<ValidationIssues>(() => {
  if (!attemptedSubmit.value) return [];
  return formValue.value.description.length ? [] : [{ message: "Please enter a message", severity: "error" }];
});

const submitForm = async () => {
  attemptedSubmit.value = true;
  if (!formValue.value.description.length || !formValue.value.type.length) return;
  const val = { item: formValue.value };
  await mutate(val);

  alertHandler.addAlert({
    variant: "success",
    title: "Feedback sent successfully!",
    content: "Thank you for your feedback. We will get back to you as soon as possible.",
    duration: 4000
  });

  isVisible.value = false;
};
</script>
