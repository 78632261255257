<template>
  <li ref="menuItem" :class="{ 'has-submenu': hasSubMenu }" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
    <router-link v-if="!!to" class="dropdown-item" :class="classes" active-class="active" :to :disabled>
      <slot>
        <k-spinner v-if="unref(loading)" show-instantly size="sm" />
        <k-icon v-else-if="icon" :icon :title="label" />
        <span class="dropdown-item-label">{{ label }}</span>
        <i v-if="hasSubMenu" class="fa-regular fa-chevron-right me-n1 ps-1"></i>
      </slot>
    </router-link>
    <a
      v-else
      class="dropdown-item"
      :class="classes"
      :href
      :aria-disabled="disabled || unref(loading)"
      :data-bs-toggle="openModal ? 'modal' : undefined"
      :data-bs-target="openModal"
      @click="onClick">
      <slot>
        <k-spinner v-if="unref(loading)" show-instantly size="sm" />
        <k-icon v-else-if="icon" :icon :title="label" />
        <span class="dropdown-item-label">{{ label }}</span>
        <i v-if="hasSubMenu" class="fa-regular fa-chevron-right me-n1 ps-1"></i>
      </slot>
    </a>
  </li>
</template>

<script setup lang="ts">
import { computed, unref, ref } from "vue";
import { RouterLink } from "vue-router";
import type { Ref } from "vue";

import KIcon from "@ui/icon/KIcon.vue";
import KSpinner from "@ui/progress/KSpinner.vue";

import type { TextColourVariant } from "@data/types/ColourVariant";

const props = withDefaults(
  defineProps<{
    /** Display label for dropdown item */
    label?: string;
    /** Boolean indicating if item can be clicked */
    disabled?: boolean;
    /** Boolean indicating if item should be highlighted (in blue usually!) */
    active?: boolean;
    /** Font awesome icon name (without fa-) */
    icon?: string;
    /** Destination route (for internal link) */
    to?: string;
    /** Destination URL (if for external link) */
    href?: string;
    /** HTML element ID of modal to open */
    openModal?: string;
    /** (Optional) Boolean indicating whether item is loading & should be clickable */
    loading?: boolean | Ref<boolean>;
    /** Text colour variant for the item */
    variant?: TextColourVariant;
    /** Boolean indicating if item has a submenu */
    hasSubMenu?: boolean;
  }>(),
  {
    disabled: false,
    active: false,
    loading: false,
    openModal: undefined,
    href: undefined,
    to: undefined,
    icon: undefined,
    variant: undefined,
    label: undefined
  }
);

const emit = defineEmits<{
  (event: "click"): void;
  (event: "hover", evt: MouseEvent, bounds: { x: number; y: number; width: number }): void;
  (event: "leave", evt: MouseEvent): void;
}>();

const classes = computed(() => ({
  active: props.active,
  disabled: props.disabled || unref(props.loading),
  [`text-${props.variant ?? "none"}`]: !!props.variant
}));

const menuItem = ref<HTMLElement | null>(null);

const getBounds = () => {
  const bounds = menuItem.value?.getBoundingClientRect() ?? { x: 0, y: 0 };
  const width = menuItem.value?.offsetWidth ?? 0;

  return { x: bounds.x, y: bounds.y, width };
};

const onClick = (evt: MouseEvent) => {
  if (!props.disabled) emit("click");
  emit("hover", evt, getBounds());
};

const onMouseOver = (evt: MouseEvent) => {
  emit("hover", evt, getBounds());
};

const onMouseLeave = (evt: MouseEvent) => {
  emit("leave", evt);
};
</script>

<style scoped>
.dropdown-item {
  display: flex;
  align-items: center;
}

.dropdown-item-label {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
}
</style>
