import { refine } from "../helpers/NullHelpers";
import { DefaultMap } from "../helpers/maps/DefaultMap";
import { InsufficientContextError } from "../expressions/context";
import { expressionParser } from "../expressions/parser";

import type { KContext } from "../expressions/context";
import type { Severity, ValidationIssues } from "./Validation";
import type { KEntity } from "../data/KEntity";
import type { KField } from "../fields/KField";
import type { BooleanExpression } from "../expressions/expressions";
import type { KRecord } from "../data/Record";

export type ConditionalValidator = {
  severity?: Severity;
  conditional: string;
  message: string;
};

export type ParsedValidator = {
  severity?: Severity;
  conditional: BooleanExpression;
  message: string;
};

/**
 * Assign multiple conditional validators to their relevant fields
 *
 * @param validators Validators to combine
 * @param entity Entity the validators are being used on
 * @returns Map of field id to validator
 */
export const groupValidatorsByField = (validators: ConditionalValidator[], entity: KEntity, fields?: KField[]): ReadonlyMap<string, ConditionalValidator[]> => {
  const result = new DefaultMap<string, ConditionalValidator[]>(() => []);
  const fieldOrder = new Map<string, number>();
  for (const [i, f] of (fields ?? entity.schema).entries()) fieldOrder.set(f.id, i);
  for (const v of validators) {
    const fieldIds = Array.from(expressionParser.getDependencies(v.conditional));
    let highest = -Infinity;
    for (const id of fieldIds) {
      const order = fieldOrder.get(id) ?? -Infinity;
      highest = Math.max(highest, order);
    }
    const highestField = entity.schema.find((f) => fieldOrder.get(f.id) === highest);
    if (highestField) {
      result.get(highestField.id).push(v);
    }
  }
  return result.frozen();
};

const emptyRecord: KRecord = {};

export const getRequiredFieldIds = (validators: ConditionalValidator[], entity: KEntity): Set<string> => {
  const fieldIds = new Set<string>();
  for (const v of validators) {
    if (v.severity === "warning") continue;
    const deps = expressionParser.getDependencies(v.conditional);
    if (deps.size !== 1) continue;
    const fieldId = deps.values().next().value as string;
    const field = entity.getField(fieldId);
    if (!field) continue;
    // attempt to run the validator on an empty record
    const parsedExpression = expressionParser.parseBoolean(v.conditional, { entity });
    try {
      const result = parsedExpression.evaluate({ entity, record: emptyRecord });
      if (result) {
        fieldIds.add(fieldId);
      }
    } catch (e) {
      if (!(e instanceof InsufficientContextError)) {
        throw e;
      }
    }
  }
  return fieldIds;
};

export const parseValidators = (validators: ConditionalValidator[], entity: KEntity | undefined): ParsedValidator[] =>
  validators.map(({ conditional, message, severity }) => ({ conditional: expressionParser.parseBoolean(conditional, { entity }), message, severity }));

export const runValidators = (validators: ParsedValidator[], context: KContext): ValidationIssues => {
  const results = refine(validators, ({ conditional, message, severity }) => {
    try {
      if (conditional.evaluate(context)) {
        return { message, severity: severity ?? "error" };
      }
    } catch (e) {
      if (e instanceof InsufficientContextError) {
        // ignore for the moment
      } else {
        throw e;
      }
    }
    return undefined;
  });
  return results;
};
