<template>
  <k-input-phone v-model="value" :label :default-prefix="tenantPrefix" />
</template>

<script setup lang="ts">
/** Phone number input with country prefix loaded from tenant details */

import { computed } from "vue";

import KInputPhone from "@ui/inputs/phone/KInputPhone.vue";

import { countries } from "@data/constants/countries/countries";
import type { PhoneNumber } from "@data/constants/countries/phone";
import { countryPrefixes } from "@data/constants/countries/phone";

import { TenantAPI } from "@/api";

defineProps<{
  /** Label for the input */
  label?: string;
}>();

const value = defineModel<PhoneNumber>();

const { result } = TenantAPI.getTenantDetails();

const tenantPrefix = computed(() => {
  const country = result.value?.tenant?.details.country;
  if (country) {
    const code = countries.find((c) => c.name === country)?.code;
    if (code) {
      const prefix = countryPrefixes.get(code);
      if (prefix) return prefix;
      console.warn(`Country prefix not found for ${code}`);
    } else {
      console.warn(`Country code not found for ${country}`);
    }
  }
  return undefined;
});
</script>
