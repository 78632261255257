const unicodeSuperScript = new Map([
  ["0", "⁰"],
  ["1", "¹"],
  ["2", "²"],
  ["3", "³"],
  ["4", "⁴"],
  ["5", "⁵"],
  ["6", "⁶"],
  ["7", "⁷"],
  ["8", "⁸"],
  ["9", "⁹"],
  ["-", "⁻"]
]);

export const toSuperscriptUnicode = (num: number): string => {
  const digits = num.toString().split("");
  const superscriptDigits = digits.map((digit) => unicodeSuperScript.get(digit) ?? digit);
  return superscriptDigits.join("");
};
