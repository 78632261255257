import { createVNode, render } from "vue";
import type { App, Component, InjectionKey, Ref } from "vue";

import KMessageBox from "./KMessageBox.vue";

import type { UIColourVariant } from "@data/types/ColourVariant";

export class MessageBoxHandler {
  private app: App<Element>;

  constructor(app: App<Element>) {
    this.app = app;
  }

  /**
   * Show message box with the given properties
   *
   * @param props Message box properties
   *
   *   Usage: const messageBox = inject(MessageBox); const loading = ref(false);
   *
   *   MessageBox.show({ title: "Are you sure you want to delete this entity?", okVariant: "danger", okLabel: "Delete", loading, submit: (result: boolean,
   *   dismiss: Function) => { if (result) { loading.value = true; // Trigger loading wheel performSuccessLogic().then(dismiss); } else { dismiss(); } } });
   */
  public show(props: MessageBoxProps | undefined) {
    let el: HTMLElement | null = document.createElement("div");

    let vNode: ReturnType<typeof createVNode> | undefined = createVNode(KMessageBox as Component, {
      destroy: () =>
        setTimeout(() => {
          if (el) {
            render(null, el);
          }
          el = null;
          vNode = undefined;
        }, 1000),
      show: true,
      title: props?.title,
      content: props?.content,
      okVariant: props?.okVariant,
      okLabel: props?.okLabel,
      loading: props?.loading,
      onSubmit: props?.onSubmit,
      hideCancel: props?.hideCancel
    });
    vNode.appContext = this.app._context;
    render(vNode, el);
  }
}

export interface MessageBoxProps {
  /** Title of message box */
  title?: string;
  /** (Optional) Content of message box */
  content?: string;
  /** (Optional) Boolean or Ref<Boolean> indicating whether primary message box action is loading */
  loading?: Ref<boolean> | boolean;
  /** Button variant colour of the primary OK button */
  okVariant?: UIColourVariant;
  /** Label for the primary button */
  okLabel?: string;
  /** Callback to handle message box result, will wait for promise before dismissing */
  onSubmit?: (ok: boolean) => void | Promise<void>;
  /** Boolean indicating whether to hide the cancel button */
  hideCancel?: boolean;
}

export const MessageBox: InjectionKey<MessageBoxHandler> = Symbol("MessageBox");
