import { computed, unref, ref, toValue, watch } from "vue";
import type { MaybeRef, Ref } from "vue";

import { v4 } from "uuid";

import type { ReadonlyRef } from "@ui/helpers/refHelpers";

import type { Collection, UniqueFieldRestriction } from "@data/data/Collection";
import type { KBRecord, KFieldValue, KRecord, RecordUpdateEvent, StoredKRecord } from "@data/data/Record";
import type { KRecordOption } from "@data/fields/relational/RecordFields";
import type { JsonSerialisable } from "@data/helpers/serialisation/TypedJSON";
import { toJson } from "@data/helpers/serialisation/TypedJSON";
import type { KField, StaticFieldValue } from "@data/fields/KField";
import type { ServiceMetadata } from "@data/data/Extension";

import { RecordAPI } from "@/api";

import type { ServerRecordSelection } from "@/api/record";
import type { RecordCountResult, SortOptions } from "@/api/recordQueries";
import type { MockCollection, SelectionLike } from "@/tests/helpers/mockCollection";
import type { ApolloError } from "@apollo/client";

const isMock = (collection: MaybeRef<Collection | MockCollection | undefined>): MockCollection | undefined => {
  const c = unref(collection);
  if (c && "mock" in c) {
    return c;
  }
  return undefined;
};

export const useRecordList = (
  collection: MaybeRef<Collection | undefined>,
  baseFilters: MaybeRef<string[]>,
  filters: MaybeRef<string[]>,
  sort: MaybeRef<SortOptions>
): {
  list: Readonly<Ref<KBRecord[] | undefined>>;
  loading: Readonly<Ref<boolean>>;
  refetch: () => Promise<void>;
  nextPage: () => void;
  handleRecordUpdate: (event: RecordUpdateEvent) => Promise<void>;
  fullResult: Readonly<Ref<boolean>>;
  totalCount: Readonly<Ref<RecordCountResult | undefined>>;
  filteredCount: Readonly<Ref<RecordCountResult | undefined>>;
} => {
  const mock = isMock(collection);
  if (mock) return mock.useRecordList(computed(() => toValue(baseFilters).concat(toValue(filters))));
  const result = RecordAPI.useRecordsQuery(collection, baseFilters, filters, sort);
  return {
    ...result,
    nextPage: result.loadNextPage
  };
};

export const useRecordOptions = (
  collection: MaybeRef<Collection | undefined>,
  filters?: MaybeRef<string[]>,
  search?: MaybeRef<string>
): {
  options: Readonly<Ref<KRecordOption[]>>;
  hasMore: Readonly<Ref<boolean>>;
  loading: Readonly<Ref<boolean>>;
  refetch: () => Promise<void>;
} => {
  const mock = isMock(collection);
  if (mock) return mock.useRecordOptions();

  const { result, loading, refetch } = RecordAPI.useRecordOptionsQuery(collection, filters, search);
  const lastLoadedOptions = ref<KRecordOption[]>([]);
  watch(result, (newValue) => {
    if (newValue) {
      lastLoadedOptions.value = newValue.recordOptions.options;
    }
  });

  return {
    options: lastLoadedOptions,
    hasMore: computed(() => !!result.value?.recordOptions.more),
    loading,
    refetch: async () => {
      await refetch();
    }
  };
};

export const useRecord = (
  collection: MaybeRef<Collection | undefined>,
  id: MaybeRef<string | undefined>
): {
  record: Readonly<Ref<KBRecord | undefined>>;
  loading: Readonly<Ref<boolean>>;
  error: Readonly<Ref<ApolloError | null>>;
  refetch: () => Promise<void>;
} => {
  const mock = isMock(collection);
  if (mock) return mock.useRecord(id);
  const query = RecordAPI.useRecordQuery(
    computed(() => unref(collection)?.id),
    id
  );
  return {
    record: computed(() => {
      const record = query.result.value?.record;
      if (!record || record.id !== unref(id)) return undefined;
      return unref(collection)?.restoreRecord({
        ...record.data,
        id: record.id,
        updatedAt: record.updatedAt,
        createdAt: record.createdAt,
        updatedBy: record.updatedBy ?? null,
        createdBy: record.createdBy ?? null,
        external: (record.external as unknown as JsonSerialisable) ?? null
      }) as KBRecord;
    }),
    loading: query.loading,
    error: query.error,
    refetch: async () => {
      await query.refetch();
    }
  };
};

export const useUniquenessViolations = (
  collection: MaybeRef<Collection>,
  formRecord: MaybeRef<KRecord>
): {
  violated: ReadonlyRef<UniqueFieldRestriction[]>;
  invalidRecord: ReadonlyRef<KRecord | undefined>;
} => {
  const mock = isMock(collection);
  if (mock) return { violated: mock.useUniquenessViolations(formRecord), invalidRecord: computed(() => unref(formRecord)) };
  const uniqueFields = computed(() => {
    const c = unref(collection);
    return c.fields.filter((f) => c.uniqueFieldRestrictions?.some((u) => u.fieldIds.includes(f.id)));
  });
  const serialisedRecord = computed(() => {
    const sr: StoredKRecord = {};
    const r = unref(formRecord);
    for (const field of unref(uniqueFields)) {
      field.storeValue(r, sr);
    }
    return toJson(sr);
  });
  const recordId = computed(() => unref(collection).getId(unref(formRecord)) || undefined);
  const lastChecked = ref<KRecord>();
  const query = RecordAPI.useUniquenessViolations(collection, serialisedRecord, recordId);
  watch(query.loading, (newValue) => {
    if (!newValue) {
      lastChecked.value = unref(formRecord);
    }
  });
  return {
    violated: computed(() => query.result.value?.recordUniquenessViolations ?? []),
    invalidRecord: lastChecked
  };
};

export const useRecordSubscription = (
  collection: MaybeRef<Collection | undefined>,
  callback: (event: RecordUpdateEvent) => void,
  filters?: MaybeRef<string[]>
) => {
  const mock = isMock(collection);
  if (mock) return mock.useRecordSubscription(callback);
  const subscription = RecordAPI.onRecordUpdateEvent(
    computed(() => unref(collection)?.id),
    filters ?? []
  );
  subscription.onResult((result) => {
    if (result.data?.recordUpdated) {
      callback(result.data.recordUpdated);
    }
  });
};

export const useAddRecord = (collection: MaybeRef<Collection>) => {
  const mock = isMock(collection);
  if (mock) return mock.useAddRecord();
  const mutation = RecordAPI.addRecord();
  // consume error to stop apollo from throwing
  mutation.onError(console.error);
  return {
    loading: mutation.loading,
    mutate: async (newRecord: KRecord, external?: ServiceMetadata[] | undefined) => {
      if (unref(collection).isReadOnly) throw new Error("This collection is readonly");
      return await mutation.mutate({
        value: {
          collectionId: unref(collection).id,
          record: toJson(unref(collection).storeRecord(newRecord)),
          external
        }
      });
    }
  };
};

// useBulkAddRecords() {
//   if (unref(collection).isReadOnly) throw new Error("This collection is readonly");

//   const mutation = RecordAPI.bulkAddRecords();
//   return {
//     loading: mutation.loading,
//     mutate: async (newRecords: KRecord[], total?: number) => {
//       const records = newRecords.map((r) => toJson(unref(collection).storeRecord(r)));
//       return await mutation.mutate({ value: { collectionId: unref(collection).id, records, totalCount: total ?? 0 } });
//     }
//   };
// }

export const useImportCSV = (collection: MaybeRef<Collection>) => {
  const guid = ref(v4());
  const mutation = RecordAPI.importCSVRecords();
  const cancellation = RecordAPI.cancelImportCsvRecords();

  return {
    loading: mutation.loading,
    mutate: async (headerMap: { header: string; fieldId: string; subheader: string }[], file: File, skipDuplicates: boolean) => {
      if (unref(collection).isReadOnly) throw new Error("This collection is readonly");
      return await mutation.mutate({ input: { collectionId: unref(collection).id, headerMap, file, skipDuplicates }, requestId: guid.value });
    },
    cancel: async () => await cancellation.mutate({ requestId: guid.value }),
    resetImportId: () => (guid.value = v4()),
    requestId: guid
  };
};

export const useUpdateCSV = (collection: MaybeRef<Collection>) => {
  const guid = ref(v4());
  const mutation = RecordAPI.updateCSVRecords();
  const cancellation = RecordAPI.cancelImportCsvRecords();

  return {
    loading: mutation.loading,
    mutate: async (headerMap: { header: string; fieldId: string; subheader: string }[], file: File, matchField: KField, matchFieldHeader: string) => {
      if (unref(collection).isReadOnly) throw new Error("This collection is readonly");
      return await mutation.mutate({
        input: { collectionId: unref(collection).id, headerMap, file, matchFieldId: matchField.id, matchFieldHeader },
        requestId: guid.value
      });
    },
    cancel: async () => await cancellation.mutate({ requestId: guid.value }),
    resetUpdateId: () => (guid.value = v4()),
    requestId: guid
  };
};

export const useAdvanceStage = (collection: MaybeRef<Collection>) => {
  const mock = isMock(collection);
  if (mock) return mock.useAdvanceStage();
  const mutation = RecordAPI.advanceRecordStage();
  return {
    loading: mutation.loading,
    mutate: async (record: KBRecord | string, nextStageId: string, signature?: string, extraData?: StaticFieldValue[]) => {
      if (unref(collection).isReadOnly) throw new Error("This collection is readonly");
      return await mutation.mutate({
        value: { collectionId: unref(collection).id, recordId: typeof record === "string" ? record : record.id, stageId: nextStageId, signature, extraData }
      });
    }
  };
};

export const toServerSelection = (selection: SelectionLike): ServerRecordSelection => {
  if (Array.isArray(selection)) {
    return { include: selection.map((r) => (typeof r === "string" ? r : r.id)) };
  }
  return {
    include: selection.include && Array.from(selection.include),
    exclude: selection.exclude && Array.from(selection.exclude),
    expressionFilters: selection.expressionFilters
  };
};

export const useBulkAdvanceStage = (collection: MaybeRef<Collection>) => {
  const mock = isMock(collection);
  if (mock) return mock.useBulkAdvanceStage();
  const mutation = RecordAPI.bulkAdvanceRecordStage();
  return {
    loading: mutation.loading,
    mutate: async (selection: SelectionLike, nextStageId: string, extraData?: StaticFieldValue[]) => {
      if (unref(collection).isReadOnly) throw new Error("This collection is readonly");
      return await mutation.mutate({ value: { collectionId: unref(collection).id, selection: toServerSelection(selection), stageId: nextStageId, extraData } });
    }
  };
};

export const useUpdateRecord = (collection: MaybeRef<Collection>) => {
  const mock = isMock(collection);
  if (mock) return mock.useUpdateRecord();
  const mutation = RecordAPI.updateRecord();
  // consume error to stop apollo from throwing
  mutation.onError(console.error);
  return {
    loading: mutation.loading,
    mutate: async (updatedRecord: KBRecord, external?: ServiceMetadata[] | undefined) => {
      if (unref(collection).isReadOnly) throw new Error("This collection is readonly");
      return await mutation.mutate({
        value: { collectionId: unref(collection).id, data: toJson(unref(collection).storeRecord(updatedRecord)), id: updatedRecord.id, external }
      });
    }
  };
};

export const useBulkUpdateRecords = (collection: MaybeRef<Collection>) => {
  const mock = isMock(collection);
  if (mock) return mock.useBulkUpdateRecords();
  const mutation = RecordAPI.bulkUpdateRecords();
  return {
    loading: mutation.loading,
    mutate: async (selection: SelectionLike, updates: { field: KField; value: KFieldValue }[]) => {
      if (unref(collection).isReadOnly) throw new Error("This collection is readonly");
      const data: KRecord = {};
      for (const { field, value } of updates) {
        data[field.key] = value;
      }
      return await mutation.mutate({
        update: {
          collectionId: unref(collection).id,
          data: toJson(unref(collection).storeRecord(data)),
          selection: toServerSelection(selection),
          fields: updates.map(({ field }) => field.id)
        }
      });
    }
  };
};

export const useMergeRecords = (collection: MaybeRef<Collection>) => {
  const mock = isMock(collection);
  if (mock) {
    // pseudo-merge by updating + deleting
    const update = mock.useUpdateRecord();
    const del = mock.useDeleteRecord();
    return {
      loading: computed(() => update.loading.value || del.loading.value),
      mutate: async (target: KBRecord, others: KBRecord[], data: KRecord) => {
        await update.mutate({ ...target, ...data });
        for (const r of others) {
          await del.mutate(r);
        }
      }
    };
  }
  const mutation = RecordAPI.mergeRecords();
  return {
    loading: mutation.loading,
    mutate: async (target: KBRecord, others: KBRecord[], data: KRecord) => {
      if (unref(collection).isReadOnly) throw new Error("This collection is readonly");
      return await mutation.mutate({
        input: { collectionId: unref(collection).id, targetId: target.id, otherIds: others.map((r) => r.id), data: toJson(unref(collection).storeRecord(data)) }
      });
    }
  };
};

export const useDeleteRecord = (collection: MaybeRef<Collection | undefined>) => {
  const mock = isMock(collection);
  if (mock) return mock.useDeleteRecord();
  const mutation = RecordAPI.deleteRecord();
  return {
    loading: mutation.loading,
    mutate: async (toDelete: KBRecord) => {
      const c = unref(collection);
      if (!c) return;
      if (c.isReadOnly) throw new Error("This collection is readonly");
      return await mutation.mutate({ collectionId: c.id, recordId: toDelete.id });
    }
  };
};

export const useBulkDeleteRecords = (collection: MaybeRef<Collection | undefined>) => {
  const mock = isMock(collection);
  if (mock) return mock.useBulkDeleteRecords();
  const mutation = RecordAPI.bulkDeleteRecords();
  return {
    loading: mutation.loading,
    mutate: async (toDelete: SelectionLike) => {
      const c = unref(collection);
      if (!c) return;
      if (c.isReadOnly) throw new Error("This collection is readonly");
      return await mutation.mutate({ collectionId: c.id, selection: toServerSelection(toDelete) });
    }
  };
};
