import { dimensionless } from "./basic/dimensionless";
import { electricUnits } from "./compound/electric";
import { imperialUnits } from "./basic/imperial";
import { SIUnits, celcius } from "./basic/si";
import { spaceUnits } from "./compound/space";
import { ukUnits } from "./basic/uk";

export const allUnits = {
  ...SIUnits,
  ...dimensionless,
  celcius,
  ...ukUnits,
  area: spaceUnits.squareMetre,
  volume: spaceUnits.litre,
  ...imperialUnits,
  ...electricUnits
};

// units with sufficiently distinct dimensions that they can be cancelled into
export const autoConvertUnits = Object.values(electricUnits);
