import { gql } from "@apollo/client/core";
import { useMutation, useQuery, useSubscription } from "@vue/apollo-composable";

import type { NavbarItem } from "@ui/navbar/NavbarStructure";

import { taskProperties } from "./tasks";

import type { ServerTask } from "@data/data/Task";

export type NavigationStructure = {
  groups: {
    label: string;
    items: ServerNavigationItem[];
  }[];
};

export type ServerNavigationItem = {
  collectionId: string;
  hidden?: boolean;
};

export type ExtendedUINavbarItem = NavbarItem & {
  collectionId: string;
};

const updateNavStructure = () =>
  useMutation<{ updateNavigationStructure: NavigationStructure }, { input: NavigationStructure }>(gql`
    mutation updateNavigationStructure($input: NavigationStructureInput!) {
      updateNavigationStructure(input: $input) {
        groups {
          label
          items {
            collectionId
            hidden
          }
        }
      }
    }
  `);

/**
 * Handle record update events (from subscriptions)
 *
 * @param collectionId
 */
const onNavUpdateEvent = () =>
  useSubscription<{ navigationUpdated: NavigationStructure }>(gql`
    subscription onNavigationUpdated {
      navigationUpdated
    }
  `);

export interface PersonDashboardState {
  onboardingComplete: boolean;
  dismissedOnboardingTasks: string[];

  hasCreatedTask: boolean;
  hasCreatedActivity: boolean;
  hasCreatedRecord: boolean;

  tasks?: ServerTask[];

  hasM365?: boolean;
  hasTenantDetails?: boolean;

  logoAssetId?: string;
}

const usePersonDashboardState = () =>
  useQuery<{
    dashboardState: PersonDashboardState;
    whatsNewContent: string;
  }>(
    gql`
      query dashboardState {
        dashboardState {
          onboardingComplete
          dismissedOnboardingTasks
          hasCreatedTask
          hasCreatedActivity
          hasCreatedRecord
          hasM365
          hasTenantDetails
          tasks {
            ${taskProperties}
          }
          logoAssetId
        }
        whatsNewContent
      }
    `,
    {},
    { fetchPolicy: "cache-and-network" }
  );

const updateDismissedOnboardingTasks = () =>
  useMutation<{ updateDismissedOnboardingTasks: boolean }, { input: string[] }>(gql`
    mutation updateDismissedOnboardingTasks($input: [String!]!) {
      updateDismissedOnboardingTasks(taskIds: $input)
    }
  `);

//mark onboarding complete
const updateOnboardingCompletion = () =>
  useMutation<{ updateOnboardingCompletion: boolean }>(gql`
    mutation updateOnboardingCompletion {
      updateOnboardingCompletion
    }
  `);

export const ConfigurationAPI = {
  updateNavStructure,
  onNavUpdateEvent,
  usePersonDashboardState,
  updateDismissedOnboardingTasks,
  updateOnboardingCompletion
};
