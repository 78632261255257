<template>
  <Transition>
    <div v-if="show" ref="navElement" class="notifications">
      <div class="d-flex flex-column h-100">
        <div class="d-flex mb-0">
          <h5 class="mb-0 p-3 pb-0 flex-grow-1">Notifications</h5>
          <div class="pt-2 pe-2 text-end">
            <!-- <k-button variant="transparent" icon="up-right-and-down-left-from-center" /> -->
            <k-button variant="transparent" icon="close" @click="emit('close')" />
          </div>
        </div>
        <div class="flex-grow-1">
          <k-tab-view :update-route="false">
            <k-tab id="all" title="All">
              <div v-if="loading && !notifications" class="py-3 text-center">
                <k-spinner />
              </div>
              <div v-else-if="!notifications || notifications.length == 0" class="text-secondary mt-3 py-3 text-center">
                <div class="py-2"><i class="far fa-bell-slash"></i></div>
                No notifications found
              </div>
              <div v-else class="me-n3 pb-3 pt-1 pe-3 notification-container">
                <k-notification-item
                  v-for="notification in notifications"
                  :key="notification.id"
                  :notification
                  @mark-as-read="emit('markAsRead', notification.id)"
                  @dismiss="emit('dismiss', notification.id)" />
              </div>
            </k-tab>
            <k-tab id="unread" :title="unreadCount !== undefined ? `Unread (${unreadCount})` : 'Unread'">
              <div v-if="loading && !notifications" class="py-3 text-center">
                <k-spinner />
              </div>
              <div v-else-if="unreadCount" class="me-n3 pb-3 pt-1 pe-3 notification-container">
                <k-notification-item
                  v-for="notification in notifications?.filter((n) => !n.isRead)"
                  :key="notification.id"
                  :notification
                  @mark-as-read="emit('markAsRead', notification.id)"
                  @dismiss="emit('dismiss', notification.id)" />
              </div>
              <div v-else class="text-secondary mt-3 py-3 text-center">
                <div class="py-2"><i class="far fa-bell-slash"></i></div>
                No unread notifications found
              </div>
            </k-tab>
          </k-tab-view>
        </div>
        <div v-if="$slots.footer" class="px-2 pb-2">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

import { onClickOutside } from "@vueuse/core";

import KTab from "@ui/tabs/KTab.vue";
import KTabView from "@ui/tabs/KTabView.vue";
import KButton from "@ui/button/KButton.vue";
import KSpinner from "@ui/progress/KSpinner.vue";

import KNotificationItem from "./KNotificationItem.vue";

import type { KNotification } from "@data/data/Notification";

const props = defineProps<{
  /** Show notifications */
  show: boolean;
  /** Notifications to show */
  notifications?: KNotification[];
  /** Loading state */
  loading?: boolean;
}>();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "markAsRead", id: string): void;
  (e: "dismiss", id: string): void;
}>();

const navElement = ref<HTMLElement | null>(null);
onClickOutside(
  navElement,
  () => {
    if (props.show) {
      emit("close");
    }
  },
  {
    ignore: [".navbar-item", ".notification-centre-btn", ".notification-count"]
  }
);

const unreadCount = computed(() => props.notifications?.filter((n) => !n.isRead).length);
</script>

<style scoped>
.notifications {
  display: block;
  position: fixed;
  top: 5px;
  height: 400px;
  left: calc(var(--k-content-left) + 5px);
  width: min(350px, calc(100% - var(--k-content-left) - 10px));
  z-index: 100000;
  background: var(--k-background);
  box-shadow: var(--k-dropdown-shadow);
  border-radius: 6px;
  transition: all 0.15s ease-in-out;
  transform-origin: top left;
}

.notification-container {
  height: 280px;
  overflow: auto;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.05s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: scale(0.95) translate(-5px, 0);
}
</style>
