import { capitalise } from "../../helpers/strings/Casing";
import { SimpleField } from "../basic/SimpleFields";
import { ColourVariants, type ColourVariant } from "../../types/ColourVariant";

import type { KRecord } from "../../data/Record";
import type { Sortable } from "../KField";

export class ColourField extends SimpleField<ColourVariant> {
  readonly type = "colour";

  getSortableValue(record: KRecord, toLowerCase?: boolean | undefined): Sortable {
    const value = this.getValue(record);
    return toLowerCase ? value?.toLowerCase() : value;
  }

  getDisplayValue(record: KRecord): string {
    return capitalise(this.getValue(record) ?? "");
  }

  validateType(value: unknown): value is ColourVariant {
    return ColourVariants.includes(value as ColourVariant);
  }
}
