import { formatList } from "../helpers/strings/List";
import { toPlural } from "../helpers/strings/Plurals";
import { hour, minute } from "../units/basic/si";

import { minPrecision } from "./datetime";
import { DurationExpression } from "./expressions";
import { NumberExpression } from "./expressions";

import type { DatetimeExpression } from "./expressions";
import type { MetricUnit } from "../units/units";
import type { DatetimePrecision } from "../fields/basic/DatetimeField";
import type { KContext } from "./context";

const minPrecisionOf = (precisions: DatetimePrecision[]): DatetimePrecision =>
  // eslint-disable-next-line unicorn/no-array-reduce
  precisions.reduce(minPrecision);
const multFactors = new Map<DatetimePrecision, number>([
  //["second", 1],
  ["minute", 60],
  ["hour", 60 * 60]
]);

export class MetricToDurationCast extends DurationExpression {
  readonly precision = "minute"; //"second"

  constructor(public readonly expression: NumberExpression) {
    super();
    if (!expression.unit) {
      throw new Error("Can't cast a unitless number to a duration");
    }
  }

  evaluate(context: KContext): number | undefined {
    const value = this.expression.evaluate(context);
    return value ? Math.round(value / 60) : undefined;
    // return this.expression.evaluate(context);
  }

  display(context: KContext): string {
    return `${this.expression.display(context)}`;
  }
}

export class DurationToMetricCast extends NumberExpression {
  static precisionMap = new Map<DatetimePrecision, MetricUnit>([
    ["minute", minute],
    ["hour", hour]
  ]);

  constructor(public readonly expression: DurationExpression) {
    super();
    const unit = DurationToMetricCast.precisionMap.get(expression.precision);
    if (!unit) {
      throw new Error(`Can't cast a ${expression.precision} duration to a number`);
    }
    this.unit = unit;
  }

  evaluate(context: KContext): number | undefined {
    const value = this.expression.evaluate(context);
    if (value === undefined) {
      return undefined;
    }
    return value * this.unit!.conversion;
  }

  display(context: KContext): string {
    return this.expression.display(context);
  }
}

export class NumberToDurationExpression extends DurationExpression {
  constructor(
    public readonly precision: DatetimePrecision,
    private readonly expression: NumberExpression
  ) {
    super();
  }

  evaluate(context: KContext): number | undefined {
    const value = this.expression.evaluate(context);
    return value;
  }

  display(context: KContext): string {
    return `${this.expression.display(context)} ${toPlural(this.precision)}`;
  }
}

export class DurationSumExpression extends DurationExpression {
  precision: DatetimePrecision;

  private multiPrecision: boolean;

  constructor(public readonly expressions: DurationExpression[]) {
    super();
    const precisionSet = new Set(expressions.map((e) => e.precision));
    this.multiPrecision = precisionSet.size > 1;
    if (this.multiPrecision) {
      for (const p of precisionSet) {
        if (!multFactors.has(p)) {
          throw new Error(`Can't mix ${formatList([...precisionSet])} precision durations`);
        }
      }
    }

    this.precision = minPrecisionOf(expressions.map((e) => e.precision));
  }

  evaluate(context: KContext): number | undefined {
    let sum = 0;
    for (const e of this.expressions) {
      const value = e.evaluate(context);
      if (value === undefined) {
        return undefined;
      }
      sum += value * (this.multiPrecision ? multFactors.get(e.precision)! : 1);
    }
    if (this.multiPrecision) {
      return sum / multFactors.get(this.precision)!;
    }
    return sum;
  }

  display(context: KContext): string {
    return this.expressions.map((e) => e.display(context)).join(" + ");
  }
}

export class DiffExpression extends DurationExpression {
  precision: DatetimePrecision;

  constructor(
    private readonly left: DatetimeExpression,
    private readonly right: DatetimeExpression
  ) {
    super();
    this.precision = minPrecision(left.precision, right.precision);
  }

  evaluate(context: KContext): number | undefined {
    const left = this.left.evaluate(context);
    const right = this.right.evaluate(context);
    if (left === undefined || right === undefined) {
      return undefined;
    }
    return Math.abs(left.diff(right, this.precision));
  }

  display(context: KContext): string {
    return `${this.left.display(context)} to ${this.right.display(context)}`;
  }
}

export class PrecisionDiffExpression extends NumberExpression {
  constructor(
    private readonly left: DatetimeExpression,
    private readonly right: DatetimeExpression,
    private readonly precision: DatetimePrecision
  ) {
    super();
  }

  evaluate(context: KContext): number | undefined {
    const left = this.left.evaluate(context);
    const right = this.right.evaluate(context);
    if (left === undefined || right === undefined) {
      return undefined;
    }
    return Math.abs(left.diff(right, this.precision));
  }

  display(context: KContext): string {
    return `${toPlural(this.precision)}(${this.left.display(context)} to ${this.right.display(context)})`;
  }
}
