import type { CollectionAssociation } from "@data/data/Collection";

// make sure all labels are unique
export const labelAssociations = (associations: CollectionAssociation[]): (CollectionAssociation & { label: string })[] => {
  const labels = new Set<string>();
  const duplicates = new Set<string>();

  const firstPass = associations.map((a) => {
    const label = a.collectionPlural;
    if (labels.has(label)) {
      duplicates.add(label);
    }
    labels.add(label);
    return { ...a, label };
  });

  if (duplicates.size > 0) {
    return firstPass.map((a) => {
      if (duplicates.has(a.label)) {
        return { ...a, label: `${a.label} (${a.field.label})` };
      }
      return a;
    });
  }

  return firstPass;
};
