import { capitalise } from "../helpers/strings/Casing";

import { BooleanExpression, DatetimeExpression, ParsingError } from "./expressions";

import type { KContext } from "./context";
import type { DurationExpression } from "./expressions";
import type { DatetimePrecision } from "../fields/basic/DatetimeField";

const precisionOrder = ["year", "quarter", "month", "day", "hour", "minute"] as const;
export const isLowerPrecision = (target: DatetimePrecision, than: DatetimePrecision): boolean => precisionOrder.indexOf(target) < precisionOrder.indexOf(than);
export const isHigherPrecision = (target: DatetimePrecision, than: DatetimePrecision): boolean => precisionOrder.indexOf(target) > precisionOrder.indexOf(than);
export const minPrecision = (a: DatetimePrecision, b: DatetimePrecision): DatetimePrecision => (isHigherPrecision(a, b) ? b : a);
export const maxPrecision = (a: DatetimePrecision, b: DatetimePrecision): DatetimePrecision => (isLowerPrecision(a, b) ? b : a);

type DayjsCompareOperator = "isSame" | "isAfter" | "isBefore";
const toDisplay: Record<DayjsCompareOperator, string> = {
  isSame: "on",
  isAfter: "after",
  isBefore: "before"
};
export class DayjsCompareExpression extends BooleanExpression {
  constructor(
    private readonly left: DatetimeExpression,
    private readonly right: DatetimeExpression,
    private readonly operator: "isSame" | "isAfter" | "isBefore"
  ) {
    super();
  }

  evaluate(context: KContext): boolean {
    const left = this.left.evaluate(context);
    const right = this.right.evaluate(context);
    if (left === undefined || right === undefined) {
      return false;
    }
    const precision = minPrecision(this.left.precision, this.right.precision);
    return left[this.operator](right, precision);
  }

  display(context: KContext): string {
    return `${this.left.display(context)} is ${toDisplay[this.operator]} ${this.right.display(context)}`;
  }
}

export class BeforeAfterExpression extends DatetimeExpression {
  constructor(
    private readonly left: DurationExpression,
    private readonly right: DatetimeExpression,
    private readonly beforeAfter: "before" | "after"
  ) {
    super(maxPrecision(left.precision, right.precision));
  }

  override evaluate(context: KContext) {
    const right = this.right.evaluate(context);
    if (!right) return undefined;
    const left = this.left.evaluate(context);
    if (!left) return undefined;
    return right[this.beforeAfter === "after" ? "add" : "subtract"](left, this.left.precision);
  }

  override display(context: KContext) {
    return `${this.left.display(context)} after ${this.right.display(context)}`;
  }
}

export class StartOfExpression extends DatetimeExpression {
  constructor(
    private readonly expression: DatetimeExpression,
    public readonly precision: DatetimePrecision
  ) {
    if (isHigherPrecision(precision, expression.precision)) {
      throw new ParsingError("Cannot increase precision of a datetime");
    }
    super(precision);
  }

  override evaluate(context: KContext) {
    const value = this.expression.evaluate(context);
    if (!value) return undefined;
    return value.startOf(this.precision);
  }

  override display(context: KContext) {
    return `startOf${capitalise(this.precision)}(${this.expression.display(context)})`;
  }
}
