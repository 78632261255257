<template>
  <div v-if="shouldShow" class="field">
    <k-label v-if="!hideLabel && field.label" :id :label="field.label" class="mb-1" />
    <component :is="display.component" v-bind="display.props" :id />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { v4 as uuid } from "uuid";

import KLabel from "@ui/label/KLabel.vue";
import KDisplaySimple from "@ui/displays/KDisplaySimple.vue";

import { getFieldDisplay } from "./fieldDisplays";

import type { KEntity } from "@data/data/KEntity";
import type { KRecord } from "@data/data/Record";
import { getFieldVisibleExpression } from "@data/expressions/helpers";
import type { RegisteredField } from "@data/fields/FieldRegistry";
import type { KField } from "@data/fields/KField";
import { LookupField } from "@data/fields/relational/LookupField";

import { useKContext } from "@/expressions/context";
import { useCollectionStore } from "@/api/stores/useCollectionStore";

const props = defineProps<{
  /** Record to display */
  record: KRecord;
  /** Entity of the record (used for some display components) */
  entity: KEntity;
  /** Key of field to display, component performs looks up inside entity schema */
  field: KField;
  /** Whether to hide the field label */
  hideLabel?: boolean;
}>();

const parseContext = useKContext(computed(() => ({ entity: props.entity })));
const context = useKContext(computed(() => ({ ...props })));
const visibleExpression = computed(() => getFieldVisibleExpression(parseContext.value, props.field));
const shouldShow = computed(() => visibleExpression.value.evaluate(context.value));

const collectionStore = useCollectionStore();

const defaultDisplay = computed(() => ({
  component: KDisplaySimple,
  props: { value: props.field.getDisplayValue(props.record) }
}));

const validValue = computed(() => {
  const value = props.field.getValue(props.record);
  return value == null || props.field.validateType(value);
});

const display = computed(() => {
  if (!validValue.value) {
    return {
      component: KDisplaySimple,
      props: { value: "Invalid value" }
    };
  }
  if (props.field instanceof LookupField) {
    const targetCollection = collectionStore.getCollectionWithId(props.field.targetCollectionId);
    if (targetCollection) {
      return props.field.withEffectiveRecord(props.record, (f, r) => getFieldDisplay.call(f as RegisteredField, r, targetCollection)) ?? defaultDisplay.value;
    }
  }
  return getFieldDisplay.call(props.field as RegisteredField, props.record, props.entity) ?? defaultDisplay.value;
});

const id = uuid();
</script>
