import { fromJson } from "./TypedJSON";

import type { JsonSerialisable } from "./TypedJSON";

// for completing true/false/null
const finishers: [RegExp, string][] = [
  [/\W(t|tr|tru)$/, "true"],
  [/\W(f|fa|fal|fals)$/, "false"],
  [/\W(n|nu|nul)$/, "null"]
];
// eslint-disable-next-line complexity
const fixJson = (truncatedJson: string): string | undefined => {
  truncatedJson = truncatedJson.trim();
  const toAdd: string[] = [];
  let inString = false;
  // current type of thing we're in (i.e. property key or value)
  let mode: "key" | "value" = "value";
  // last property key found - used for truncating
  let lastKey: string | undefined;
  let escaping = false;
  for (const char of truncatedJson) {
    if (inString) {
      if (escaping) {
        escaping = false;
      } else if (char === "\\") {
        escaping = true;
      } else if (char === '"') {
        inString = false;
      }
      if (mode === "key") {
        lastKey += char;
      }
    } else {
      switch (char) {
        case "{":
          toAdd.push("}");
          mode = "key";
          break;
        case "[":
          toAdd.push("]");
          break;
        case '"':
          inString = true;
          if (mode === "key") {
            lastKey = '"';
          }
          break;
        case "}":
          if (toAdd.pop() !== "}") {
            return undefined;
          }
          break;
        case "]":
          if (toAdd.pop() !== "]") {
            return undefined;
          }
          break;
        case ":":
          if (mode === "key") {
            mode = "value";
          }
          break;
        case ",":
          // if we're in an object, switch back to key mode
          if (toAdd.at(-1) === "}") {
            mode = "key";
          }
          break;
      }
    }
  }
  if (inString && mode === "value") {
    toAdd.push('"');
  } else {
    // remove any invalid trailing things
    if (truncatedJson.endsWith(":")) {
      // switch back to key mode, and remove colon
      mode = "key";
      truncatedJson = truncatedJson.slice(0, -1).trim();
    }
    if (mode === "key" && lastKey && truncatedJson.endsWith(lastKey)) {
      // remove the last key
      truncatedJson = truncatedJson.slice(0, -lastKey.length).trim();
    }

    if (truncatedJson.endsWith(",")) {
      truncatedJson = truncatedJson.slice(0, -1).trim();
      mode = "value";
    }

    // finish any incomplete true/false/null
    if (mode === "value") {
      for (const [finisher, value] of finishers) {
        truncatedJson = truncatedJson.replace(finisher, value);
      }
    }
  }

  // need to reverse the array to add the brackets in the correct order
  return truncatedJson + toAdd.reverse().join("");
};

export const untruncateJson = (truncatedJson: string): JsonSerialisable => {
  // Just try to parse it first
  try {
    return fromJson(truncatedJson);
  } catch {
    const fixedJson = fixJson(truncatedJson);
    if (!fixedJson) return null;
    try {
      return fromJson(fixedJson);
    } catch {
      // uncomment for debugging
      // console.log(`Fixed JSON is not valid: ${fixedJson}`);
      return null;
    }
  }
};
