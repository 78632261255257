import { computed, unref } from "vue";
import type { MaybeRef } from "vue";

import { toValue } from "@vueuse/core";

import type { ReadonlyRef } from "@ui/helpers/refHelpers";

import { labelAssociations } from "./labelAssociations";

import type { Collection, CollectionAssociation } from "@data/data/Collection";
import type { ActivityType } from "@data/data/Activity";
import { ActivityTypes } from "@data/data/Activity";
import type { KField } from "@data/fields/KField";
import { PeopleField, PersonField } from "@data/fields/relational/PeopleFields";
import { getRelationalFields, RecordField, MultiRecordField } from "@data/fields/relational/RecordFields";

export const useCollectionQueries = (collections: ReadonlyRef<Collection[] | undefined>, collectionMap: ReadonlyRef<Map<string, Collection> | undefined>) => {
  const slugMap = computed(() => collectionMap.value && new Map(Array.from(collectionMap.value.values(), (c) => [c.slug, c])));

  // Non-reactive, avoid using if possible
  const getCollectionWithId = (id: string | undefined) => (id === undefined ? undefined : collectionMap.value?.get(id));

  const useCollectionWithId = (id: MaybeRef<string | undefined>) => computed(() => getCollectionWithId(unref(id)));

  const useCollectionWithSlug = (slug: MaybeRef<string | undefined>) =>
    computed(() => {
      const slugValue = unref(slug);
      if (slugValue) return slugMap.value?.get(slugValue);
      return undefined;
    });

  const useChildCollections = (collectionId: MaybeRef<string | undefined>) =>
    computed(() => {
      if (!collections.value) return [];

      const targetId = unref(collectionId);
      if (targetId === undefined) {
        return [];
      }

      const result: CollectionAssociation[] = [];
      for (const oc of collections.value) {
        for (const f of getRelationalFields(oc)) {
          if (f.collectionId === targetId) {
            result.push({
              collectionId: oc.id,
              collectionPlural: oc.plural,
              collectionSlug: oc.slug,
              field: f,
              multi: f instanceof MultiRecordField,
              icon: oc.icon
            });
          }
        }
      }

      return labelAssociations(result);
    });

  const useParentCollections = (collectionId: MaybeRef<string | undefined>) =>
    computed(() => {
      if (!collections.value) return [];

      const targetId = unref(collectionId);
      if (targetId === undefined) {
        return [];
      }

      const result: CollectionAssociation[] = [];
      const collection = getCollectionWithId(targetId);
      if (!collection) return [];

      const fields = collection.getFieldsOfType<KField & { collectionId: string }>([RecordField, MultiRecordField]);

      for (const f of fields) {
        const oc = getCollectionWithId(f.collectionId);
        if (!oc) continue;
        result.push({
          collectionId: oc.id,
          collectionPlural: oc.plural,
          collectionSlug: oc.slug,
          field: f,
          multi: f instanceof MultiRecordField,
          icon: oc.icon
        });
      }

      return labelAssociations(result);
    });

  const useCollectionsWithPersonFields = () =>
    computed(() => {
      if (!collections.value) return [];

      const result: CollectionAssociation[] = [];
      for (const oc of collections.value) {
        for (const f of oc.getFieldsOfType<KField>([PersonField, PeopleField])) {
          result.push({
            collectionId: oc.id,
            collectionPlural: oc.plural,
            collectionSlug: oc.slug,
            field: f,
            multi: f instanceof PeopleField,
            icon: oc.icon
          });
        }
      }

      return result;
    });

  const getActivityTypesForCollection = (defaultTypes: ActivityType[], collection?: Collection) => {
    if (!collection) return defaultTypes;
    const customTypes =
      collection.activities?.types?.map((t) => ({
        ...t,
        collectionId: collection.id,
        isEditable: ActivityTypes.get(t.key)?.isEditable ?? true
      })) ?? [];

    return customTypes.length > 0 ? customTypes : defaultTypes;
  };

  const useCollectionActivityTypes = (collection?: MaybeRef<string | Collection | undefined>) => {
    const builtInActivityTypes = Array.from(ActivityTypes.values());

    return computed<ActivityType[]>(() => {
      const cValue = toValue(collection);
      const actualCollection = typeof cValue === "string" ? getCollectionWithId(cValue) : cValue;
      if (!actualCollection) {
        // Map all activity types from all collections, add collection id to each to avoid showing wrong icons/colours if they differ
        return [
          ...builtInActivityTypes,
          ...(collections.value?.flatMap((c) =>
            // Note default types is empty as built in types are already added
            getActivityTypesForCollection([], c)
          ) ?? [])
        ];
      }

      return getActivityTypesForCollection(builtInActivityTypes, actualCollection);
    });
  };

  return {
    getCollectionWithId,
    useCollectionWithId,
    useCollectionWithSlug,
    useChildCollections,
    useParentCollections,
    useCollectionsWithPersonFields,
    useCollectionActivityTypes
  };
};
