import { computed } from "vue";
import type { Ref } from "vue";

import nlp from "compromise/three";
import isIndefinite from "indefinite";

import { useCollectionStore } from "../api/stores/useCollectionStore";

import { toWords, toSlug } from "@data/helpers/strings/Casing";
import { toSingular, toPlural } from "@data/helpers/strings/Plurals";
import type { ValidationIssues } from "@data/validation/Validation";

export const toTitleCase = (str: string) =>
  toWords(str)
    .map((word) => {
      const char = word.at(0);
      if (!char) {
        return "";
      }
      return char.toUpperCase() + word.substring(1);
    })
    .join(" ");

export type CollectionNameVariants = {
  displayNameSingular: string;
  displayNamePlural: string;
  slug: string;
  indefiniteArticle: string;
  issues: ValidationIssues;
};

// Deleted and Pending are reserved as they are special sharepoint folders which need to be separate from collection folders
const reservedCollectionNames = new Set(["Deleted", "Pending"]);

const invalidCharaterRegex = /[^\d A-Za-z-]/;
export const useCollectionNameVariants = (name: Ref<string>, id?: Ref<string>) => {
  const collectionStore = useCollectionStore();
  return computed<CollectionNameVariants>(() => {
    const val = name.value.trim();
    const singular = toTitleCase(toSingular(val));
    const plural = toTitleCase(toPlural(val));
    const slug = toSlug(toPlural(val));
    const issues: ValidationIssues = [];
    if (name.value.length > 0 && val.length === 0) {
      issues.push({ message: "Collection name cannot be blank", severity: "error" });
    } else if (val) {
      if (plural.length < 3) {
        issues.push({ message: "Collection name must be at least 3 letters long", severity: "error" });
      }
      if (singular.length > 30 || plural.length > 30) {
        issues.push({ message: "Collection name must be at most 30 letters long", severity: "error" });
      }
      if (val.includes("  ")) {
        issues.push({ message: "Collection name must not include multiple spaces in a row", severity: "error" });
      }
      if (val.includes("--")) {
        issues.push({ message: "Collection name must not include multiple dashes in a row", severity: "error" });
      }
      if (val[0] === "-") {
        issues.push({ message: "Collection name cannot start with a dash", severity: "error" });
      }
      if (val.at(-1) === "-") {
        issues.push({ message: "Collection name cannot end with a dash", severity: "error" });
      }
      if (collectionStore.collections?.find((x) => x.id !== id?.value && x.slug === slug)) {
        issues.push({ message: "Another collection exists with this name", severity: "error" });
      }
      if (invalidCharaterRegex.test(val)) {
        issues.push({ message: "Collection name cannot contain special characters", severity: "error" });
      }
      if (singular.endsWith("ing")) {
        const doc = nlp(singular);
        if (doc.nouns().length === 0) {
          issues.push({ message: "We recommend using a noun for collection names, e.g. 'Companies'", severity: "warning" });
        }
      }
      if (reservedCollectionNames.has(singular)) {
        issues.push({ message: "This collection name is reserved, please choose another", severity: "error" });
      }
    }
    return {
      displayNameSingular: singular,
      displayNamePlural: plural,
      slug,
      indefiniteArticle: isIndefinite(val, { articleOnly: true }),
      issues
    };
  });
};
