<template>
  <template v-if="mode != 'EDITING' && props.item.to">
    <a class="navbar-item" :href :title="item.label" :class="{ active, enabled: true }" @click="onClick" @click.right="onRightClick">
      <i :class="`far fa-fw fa-${item.icon}`" :title="item.label"></i>
      <span class="navbar-item-title">{{ item.label }}</span>
    </a>
  </template>

  <template v-else-if="mode != 'EDITING'">
    <a
      class="navbar-item"
      tabindex="0"
      role="button"
      :title="item.label"
      :class="{ active, enabled: true }"
      @click="onClick"
      @touchend="onClick"
      @keydown.enter="onClick"
      @click.right="onRightClick">
      <i :class="`far fa-fw fa-${item.icon}`" :title="item.label"></i>
      <span class="navbar-item-title">{{ item.label }}</span>
    </a>
  </template>

  <template v-else-if="mode == 'EDITING'">
    <div class="navbar-item" :title="item.label" :class="{ active, editing: true, hidden: item.hidden }">
      <k-button
        :icon="item.hidden ? 'eye-slash' : 'eye'"
        :title="item.hidden ? 'Show' : 'Hide'"
        variant="transparent"
        class="float-end"
        @click="toggleHidden" />
      <i :class="`far fa-fw fa-${item.icon}`" :title="item.label"></i>
      <span class="navbar-item-title">{{ item.label }}</span>
    </div>
  </template>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute, useLink } from "vue-router";

import { useMagicKeys } from "@vueuse/core";

import KButton from "@ui/button/KButton.vue";

import type { NavbarState } from "./NavbarState";
import type { NavbarItem } from "./NavbarStructure";

const props = defineProps<{
  /** The item to display */
  item: NavbarItem;
  /** Whether the navbar is being edited */
  mode: NavbarState;
}>();

const emit = defineEmits<{
  (event: "click"): void;
  (event: "navigated"): void;
  (event: "right-click", e: MouseEvent): void;
  (event: "toggle-hidden"): void;
}>();

const currentRoute = useRoute();

const to = computed(() => props.item.to ?? "");
const link = useLink({ to });
const { href } = link;

const { current } = useMagicKeys();

const onClick = async (e: KeyboardEvent | MouseEvent | TouchEvent) => {
  e.preventDefault();
  if (props.mode === "EDITING") return;

  emit("click");

  // Handle cmd+click or middle click to open in new tab
  if ((current.has("ctrl") || current.has("meta") || ("button" in e && e.button === 1)) && href.value) {
    window.open(href.value, "_blank");
    return;
  }

  if (props.item.to) {
    await link.navigate();
    emit("navigated");
  }
};

const onRightClick = (e: MouseEvent) => {
  emit("right-click", e);
};

const toggleHidden = () => {
  emit("toggle-hidden");
};

const active = computed(() => {
  if (props.mode === "EDITING") return false;
  if (!props.item.to) return false;

  if (currentRoute.path === "/" && props.item.to.path === "/") {
    return true;
  }

  const strippedCurrentPath = currentRoute.path.replace(/(\/c\/[^/?]*).*/, "$1");
  return strippedCurrentPath === props.item.to.path;
});
</script>

<style scoped lang="scss">
.navbar-item {
  display: block;
  box-sizing: border-box;
  padding: 0 4px;
  margin: 0 8px 2px;
  border-radius: 0.4rem;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  overflow: hidden;
  cursor: default;

  white-space: nowrap;
  text-overflow: ellipsis;

  text-align: left;
  text-decoration: none;
  color: var(--k-navbar-item-color);

  > i.fa-fw {
    margin: 0 0.5rem 0 0.25rem;
    font-size: 0.85rem;
    opacity: 0.85;
  }

  &.enabled {
    transition: 0.05s all ease-in-out;

    &:hover {
      text-decoration: none;
      background-color: var(--k-navbar-item-hover-background);
      color: var(--k-navbar-item-color);
      cursor: default;
    }
    &:active {
      text-decoration: none;
      background-color: var(--k-navbar-item-pressed-background);
      color: var(--k-navbar-item-color);
      cursor: default;
    }
    &.active {
      background-color: var(--k-navbar-item-active-background);
      color: var(--k-navbar-item-active-color);
      box-shadow: var(--k-navbar-item-active-box-shadow);
    }
    &:focus-visible {
      outline-offset: 0px;
    }
  }

  &.hidden {
    opacity: 0.5;
    text-decoration: line-through;
  }
}
</style>
