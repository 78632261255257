import type { MaybeRef, Ref } from "vue";
import { computed, toValue, unref } from "vue";

import { useHasCollectionPermission, useHasPeoplePermission } from "./permissions";
import { useRecord } from "./collection";

import { peopleCollectionId, peopleCollectionName } from "@data/constants/names";
import type { PermissionsKey } from "@data/helpers/data/Permissions";
import type { KContext } from "@data/expressions/context";
import type { Person } from "@data/data/Person";
import type { KBRecord } from "@data/data/Record";

import { useInitialState } from "@/api/initial";
import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { usePeopleStore } from "@/api/stores/usePeopleStore";

export const useEntityLookup = () => {
  const collectionStore = useCollectionStore();
  const initialState = useInitialState();

  return (id: string | undefined) => {
    switch (id) {
      case undefined:
        return;
      case peopleCollectionId:
        return initialState.personEntity.value;
    }
    return collectionStore.getCollectionWithId(id);
  };
};

export const useEntityWithId = (id: MaybeRef<string | undefined>) => {
  const lookup = useEntityLookup();

  return computed(() => lookup(unref(id)));
};

export const flattenPerson = (person: Person) => ({ ...person, extraData: undefined, ...person.extraData });

export const useEntityRecord = (collectionId: MaybeRef<string | undefined>, recordId: MaybeRef<string | undefined>) => {
  const collectionStore = useCollectionStore();
  const peopleStore = usePeopleStore();
  const collection = collectionStore.useCollectionWithId(collectionId);
  const { record: standardRecord } = useRecord(collection, recordId);
  const person = peopleStore.usePerson(recordId);
  const flatPerson = computed(() => person.value && flattenPerson(person.value));

  return computed<KBRecord | undefined>(() => standardRecord.value ?? flatPerson.value);
};

export const useLinkLookup = () => {
  const collectionStore = useCollectionStore();

  return ({ collectionId, recordId, taskId }: { collectionId?: string; recordId?: string; taskId?: string }) => {
    if (collectionId && recordId) {
      let collectionBase: string;
      if (collectionId === peopleCollectionId) {
        collectionBase = `/${peopleCollectionName.slug}`;
      } else {
        const slug = collectionStore.getCollectionWithId(collectionId)?.slug;
        collectionBase = `/c/${slug}`;
      }
      if (taskId) {
        return `${collectionBase}/${recordId}?t=tasks`;
      }
      return `${collectionBase}/${recordId}`;
    }
  };
};

export const useHasEntityPermission = (collectionId: MaybeRef<string | undefined>, permission: PermissionsKey, context?: Ref<KContext>, defaultTo = true) => {
  const collectionStore = useCollectionStore();

  const collection = collectionStore.useCollectionWithId(collectionId);

  const standardPermission = useHasCollectionPermission(collection, permission, context);
  const peopleRead = useHasPeoplePermission("read");
  return computed(() => {
    if (toValue(collectionId) === peopleCollectionId) {
      return permission === "read" ? peopleRead.value : defaultTo;
    }
    return standardPermission.value;
  });
};
