import { toValue, computed } from "vue";
import type { MaybeRef } from "vue";

import { gql } from "@apollo/client/core";
import { useQuery, useMutation } from "@vue/apollo-composable";

import type { KFile } from "@data/data/File";

export const maxFileTransferSize = 29_000_000; // found via experimentation

const getFilesForRecord = (collectionId: MaybeRef<string>, recordId: MaybeRef<string>) =>
  useQuery<{ filesForRecord: KFile[]; folderForRecord: string }>(
    gql`
      query filesForRecord($collectionId: String!, $recordId: String!) {
        folderForRecord(collectionId: $collectionId, recordId: $recordId)
        filesForRecord(collectionId: $collectionId, recordId: $recordId) {
          name
          webUrl
          directUrl
          id
          lastModified
          fieldId
          isFolder
        }
      }
    `,
    computed(() => ({ collectionId: toValue(collectionId), recordId: toValue(recordId) })),
    { fetchPolicy: "cache-and-network" }
  );

/** Upload a file directly to record folder */
const uploadFile = () =>
  useMutation<{ uploadFile: { id: string } }, { file: File; collectionId: string; recordId: string }>(gql`
    mutation uploadFile($file: Upload!, $collectionId: String!, $recordId: String!) {
      uploadFile(file: $file, collectionId: $collectionId, recordId: $recordId) {
        id
      }
    }
  `);

const deleteFile = () =>
  useMutation<{ deleteFile: { success: boolean } }, { fileId: string }>(
    gql`
      mutation deleteFile($fileId: String!) {
        deleteFile(fileId: $fileId)
      }
    `,
    {
      update(cache, data, options) {
        cache.evict({ id: `FileListItem:${options.variables?.fileId ?? ""}` });
        cache.gc();
      }
    }
  );

const renameFile = () =>
  useMutation<{ renameFile: { success: boolean } }, { fileId: string; newFileName: string }>(
    gql`
      mutation renameFile($fileId: String!, $newFileName: String!) {
        renameFile(fileId: $fileId, newFileName: $newFileName)
      }
    `,
    {
      update(cache, data, options) {
        cache.modify({
          id: `FileListItem:${options.variables?.fileId ?? ""}`,
          fields: {
            name() {
              return options.variables?.newFileName ?? "";
            }
          }
        });
      }
    }
  );

const getFileStorageSites = () =>
  useQuery<{
    fileStorageSites?: {
      sites?: { driveName: string; driveId: string; siteName: string; siteId: string; url: string }[];
      status: "UNKNOWN" | "CONNECTED" | "MISSING_PERMISSION";
      consentUrl?: string;
    };
  }>(
    gql`
      query fileStorageSites {
        fileStorageSites {
          sites {
            driveName
            driveId
            siteName
            siteId
            url
          }
          status
          consentUrl
        }
      }
    `,
    {},
    { fetchPolicy: "cache-and-network" }
  );

export const FileAPI = {
  getFilesForRecord,
  uploadFile,
  deleteFile,
  getFileStorageSites,
  renameFile
};
