import { SimpleField } from "../basic/SimpleFields";
import { checkIsObject } from "../../helpers/NullHelpers";

import type { ValidationIssues } from "../../validation/Validation";
import type { PhoneNumber } from "../../constants/countries/phone";
import type { KRecord } from "../../data/Record";
import type { Sortable } from "../KField";

// remove whitespace, /, \, ., - from phone number
const removeSeparators = (value: string) => value.replace(/[\s-./\\]/g, "");

export class PhoneField extends SimpleField<PhoneNumber> {
  readonly type = "phone";

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    return value ? `${value.prefix} ${value.number}${value.extension ? "#" + value.extension : ""}` : "";
  }

  getSortableValue(record: KRecord): Sortable {
    const value = this.getValue(record);
    return value ? `${value.prefix}${removeSeparators(value.number)}` : undefined;
  }

  getSearchValue(record: KRecord): string {
    return this.getDisplayValue(record);
  }

  getDefaultColumnWidth() {
    return 150;
  }

  validate(record: KRecord): ValidationIssues {
    const value = this.getValue(record);
    if (!value) return [];
    if (value.number.length < 3) {
      return [{ severity: "warning", message: "Number might be too short" }];
    }
    if (value.number.length > 25) {
      return [{ severity: "warning", message: "Number might be too Long" }];
    }
    if (value.number.match(/[A-Za-z]/g)) {
      return [{ severity: "error", message: "Phone number should not contain letters" }];
    }
    if (value.extension) {
      if (value.extension.match(/[A-Za-z]/g)) return [{ severity: "error", message: "Extension should not contain letters" }];
      else if (value.extension.length > 6) return [{ severity: "error", message: "Extension should not be longer than 6 characters" }];
    }
    return [];
  }

  validateType(value: unknown): value is PhoneNumber {
    if (!checkIsObject(value)) return false;
    return ["prefix", "number"].every((key) => typeof value[key] === "string");
  }
}
