import { getFieldOfType, DataAccessor, MultiRecordAccessor, matchAccessor, matchMultiAccessor } from "../accessors";
import { DataConstant } from "../constants";
import { toTokens } from "../tokeniser";
import { ParsingError } from "../expressions";
import { ConcatDataExpression } from "../data/manipulation";

import { checkAndSplit } from "./operators";

import type { DataExpression } from "../expressions";
import type { KContext } from "../context";
import type { ExpressionParser } from "../parser";

export function parseData(this: ExpressionParser, expression: string | string[], context: KContext): DataExpression {
  return this.baseParse<DataExpression>(expression, "data", context, {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    recurse: parseData.bind(this),
    single: (token) => {
      if (token === "null") {
        return new DataConstant(undefined);
      }
      // check for data constants
      if (token.startsWith("[")) {
        // split by commas
        const tokens = toTokens(token.slice(1, -1), true);
        return new DataConstant(tokens.map((t) => this.parseSingleExpression(t, context)));
      }
      const singleMatch = matchAccessor(token);
      if (singleMatch) {
        const field = getFieldOfType(context, singleMatch, "data");
        return new DataAccessor(singleMatch.type, field);
      }
      const multiMatch = matchMultiAccessor(token);
      if (multiMatch) {
        const field = getFieldOfType(context, multiMatch, ["string", "number", "datetime"]);
        return new MultiRecordAccessor(multiMatch.type, field);
      }
      throw new ParsingError(`Unknown data constant: ${token}`);
    },
    multi: (tokens) => {
      const concat = checkAndSplit(tokens, ["+"]);
      if (concat) {
        return new ConcatDataExpression(...concat.filter((t) => t !== "+").map((t) => this.parseData(t, context)));
      }
      throw new ParsingError(`Unknown data expression: ${tokens.join(" ")}`);
    }
  });
}
