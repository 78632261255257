import { deepEquals } from "../helpers/manipulation/Comparison";
import { KeyedMap } from "../helpers/maps/KeyedMap";

import { autoConvertUnits } from "./allUnits";
import { CombinedUnit } from "./units";

import type { MetricUnit } from "./units";

/**
 * Generate a combined unit from multiple units, cancelling out units as appropriate (e.g. m/s * s = m)
 *
 * @param units The units to combine
 * @returns Usually a CombinedUnit, but may be a single unit or undefined if units cancel out
 */
export const combineUnits = (units: { unit: MetricUnit; power: number }[]): MetricUnit | undefined => {
  const actualUnits = new KeyedMap<MetricUnit, number>((m) => m.expressionSymbol);
  for (const { unit, power } of units) {
    if (unit instanceof CombinedUnit) {
      for (const { unit: subUnit, power: subPower } of unit.units) {
        actualUnits.set(subUnit, (actualUnits.get(subUnit) ?? 0) + subPower * power);
      }
    } else {
      actualUnits.set(unit, (actualUnits.get(unit) ?? 0) + power);
    }
  }
  let combinedUnits: { unit: MetricUnit; power: number }[] = [];
  for (const [unit, power] of actualUnits) {
    if (power === 0) continue;
    // combine units with the same dimensions
    const idx = combinedUnits.findIndex((u) => deepEquals(u.unit.dimensions, unit.dimensions));
    if (idx === -1) {
      combinedUnits.push({ unit, power });
    } else {
      // remove the old unit
      const old = combinedUnits[idx];
      if (old.power === -power) {
        combinedUnits.splice(idx, 1);
      } else if (old.unit.conversion < unit.conversion) {
        old.power += power;
      } else {
        combinedUnits.splice(idx, 1);
        combinedUnits.push({ unit, power: power + old.power });
      }
    }
  }
  if (combinedUnits.length === 0) {
    return undefined;
  }
  if (combinedUnits.length === 1 && combinedUnits[0].power === 1) {
    return combinedUnits[0].unit;
  }
  // remove percentage and ppm
  combinedUnits = combinedUnits.filter(({ unit }) => !unit.removable);
  if (combinedUnits.length === 0) {
    return undefined;
  }
  if (combinedUnits.length === 1 && combinedUnits[0].power === 1) {
    return combinedUnits[0].unit;
  }
  const combined = new CombinedUnit(combinedUnits);
  return autoConvertUnits.find((u) => deepEquals(u.dimensions, combined.dimensions)) ?? combined;
};
