import { refine } from "../NullHelpers";
import { sortedBy } from "../manipulation/Ordering";

import type { KEntity } from "../../data/KEntity";
import type { KRecord } from "../../data/Record";
import type { KField } from "../../fields/KField";

type SearchableField = KField & { getSearchValue: (r: KRecord) => string | undefined };

export const findSearchableFields = (fields: readonly KField[]) => refine(fields, (f) => (f.getSearchValue === undefined ? undefined : (f as SearchableField)));

const isMatch = (field: SearchableField, r: KRecord, search: string) => {
  const sValue = field.getSearchValue(r);
  return typeof sValue === "string" && sValue.toLowerCase().includes(search.toLowerCase());
};
/**
 * Searches a list of data by primary fields
 *
 * @param data Input data to search
 * @returns Searched array of data
 */
export const searchRecords = (data: KRecord[], entity: KEntity, search: string | undefined, fields?: KField[]): KRecord[] => {
  if (!search) {
    return data;
  }

  const searchFields = findSearchableFields(fields ?? entity.fields);

  // Support searching multiple properties
  if (searchFields.length > 0) {
    // Filter array by matched search fields, returning if any of the fields contain the search string
    return data.filter((r) => {
      for (const s of searchFields) {
        if (isMatch(s, r, search)) {
          return true;
        }
      }
      return false;
    });
  }
  return data;
};

/**
 * Search properties, prioritising those that start with the search string
 *
 * @param data Records to search
 * @param search Search string
 * @param properties String properties to search
 * @returns List of records that match the search string, ordered by priority
 */
export const prioritySearch = <T extends KRecord>(data: T[], search: string, properties: (keyof T)[]): T[] => {
  const scores = new Map<T, number>();
  search = search.toLowerCase();
  for (const r of data) {
    for (const p of properties) {
      const value = r[p];
      if (typeof value === "string") {
        const lower = value.toLowerCase();
        if (lower.startsWith(search)) {
          scores.set(r, 2);
          break;
        }
        if (lower.includes(search)) {
          scores.set(r, 1);
        }
      }
    }
  }
  return sortedBy(
    data.filter((r) => scores.has(r)),
    (r) => scores.get(r)!,
    "DESC"
  );
};
