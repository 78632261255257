<template>
  <k-button
    v-if="item.type === 'button'"
    :title="item.title"
    :icon="item.icon"
    :label="item.badge ?? item.label"
    :active="item.active"
    :disabled="item.disabled"
    :variant
    :css-class="variant !== 'transparent' ? 'mx-2' : undefined"
    @click="item.onClick" />
  <div v-else-if="item.type === 'divider'" class="toolbar-divider"></div>
  <k-button
    v-if="item.type === 'select'"
    :title="item.title"
    :icon="item.icon"
    :label="item.badge ?? item.label"
    :active="item.active"
    :disabled="item.disabled"
    :variant
    :show-caret="item.icon !== 'ellipsis'"
    :css-class="cssClass ?? (variant !== 'transparent' ? 'mx-2' : undefined)"
    @click="($evt) => openContextMenu($evt, item)" />
  <div v-else-if="item.type !== 'divider' && item.type !== 'button'"></div>

  <div v-if="item.type === 'select' && subitems.length > 0" class="d-inline">
    <k-context-menu ref="contextMenuRef" :items="subitems" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import KButton from "@ui/button/KButton.vue";
import KContextMenu from "@ui/context-menu/KContextMenu.vue";
import type { KContextMenuElement } from "@ui/context-menu/ContextMenuElement";
import type { ContextMenuItem } from "@ui/context-menu/ContextMenuItem";

import type { ToolbarItem } from "./ToolbarStructure";

const props = defineProps<{
  /** Toolbar item */
  item: ToolbarItem;
  /** Toolbar item class */
  cssClass?: string;
}>();

const variant = computed(() => props.item.variant ?? "transparent");

const recursiveConversion = (i: ToolbarItem): ContextMenuItem<ToolbarItem> => ({
  label: (i.label ?? i.title ?? "") + (i.badge ? ` (${i.badge})` : ""),
  icon: i.icon,
  onClick: () => {
    i.onClick?.();
  },
  type: i.type === "divider" ? "divider" : undefined,
  active: i.active,
  items: i.items?.map(recursiveConversion)
});

const subitems = computed<ContextMenuItem<ToolbarItem>[]>(
  () => props.item.items?.filter((s) => s.show !== false).map((i) => recursiveConversion(i)) ?? [] // todo convert to contextmenuitem
);

const contextMenuRef = ref<KContextMenuElement<ToolbarItem>>();

const openContextMenu = (evt: MouseEvent, item: ToolbarItem) => {
  contextMenuRef.value?.show(evt, item, true);
};

// Note: empty div is needed when item.type is not defined because of TransitionGroup in KToolbar
</script>

<style scoped>
.toolbar-divider {
  border-left: 1px solid var(--k-border);
  height: 20px;
  margin: 0 12px;
  margin-bottom: -6px;
  display: inline-block;
}

.sub-menu.docked-right {
  left: 100%;
}
</style>
