<template>
  <p :id class="user-selectable">
    <template v-for="(option, idx) in options" :key="idx">
      <!-- needs to be all on -->
      <s v-if="option.deleted" title="This record was deleted" class="delete-info text-secondary">{{ option.label }}</s
      ><router-link v-else-if="allowLinking" :to="`/${slug}/${option.id}`">{{ option.label }} </router-link><span v-else>{{ option.label }}</span
      >{{ idx !== options.length - 1 ? ", " : "" }}
    </template>
    <span v-if="options.length === 0" class="text-secondary"> None </span>
  </p>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { RouterLink } from "vue-router";

import type { KRecordOption } from "@data/fields/relational/RecordFields";
import { peopleCollectionName } from "@data/constants/names";

import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { useHasPeoplePermission } from "@/helpers/permissions";

const props = defineProps<{
  /** ID of the display for labelling purposes */
  id: string;
  /** Current record value */
  value?: KRecordOption[];
  /** Target collection slug (for link) - include c/ prefix for dynamic collections */
  collectionSlug?: string;
  /** Target collection id (for link) */
  collectionId?: string;
}>();

const options = computed(() => props.value ?? []);

const collectionStore = useCollectionStore();
const collection = collectionStore.useCollectionWithId(computed(() => props.collectionId));
const slug = computed(() => props.collectionSlug ?? (collection.value?.slug ? `c/${collection.value.slug}` : undefined));

const allowLinking = ref(true);
if (props.collectionSlug === peopleCollectionName.slug) {
  const hasPermission = useHasPeoplePermission("read");
  watch(hasPermission, (newValue) => (allowLinking.value = newValue), { immediate: true });
}
</script>

<style scoped>
.delete-info {
  cursor: help;
}
</style>
