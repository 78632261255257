<template>
  <k-input-option v-model="input" :label :options="inputOptions" :required />
</template>

<script setup lang="ts" generic="T">
import { computed, ref, watch, watchEffect } from "vue";

import KInputOption from "./KInputSelect.vue";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: T;
  /** Label for the input */
  label?: string;
  /** Generic options to show */
  options: T[];
  /** ID function for the options */
  getId: (t: T) => string;
  /** Label function for the options */
  getLabel: (t: T) => string;
  /** Whether a value is required (i.e. if a null option shouldn't be shown) */
  required?: boolean;
}>();

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  (event: "update:modelValue", newValue: T | undefined): void;
}>();

const input = ref<string>();
const inputOptions = computed(() => props.options.map((t) => ({ value: props.getId(t), label: props.getLabel(t), item: t })));

watchEffect(() => {
  input.value = props.modelValue == null ? undefined : props.getId(props.modelValue);
});

watch(input, (newId) => {
  emit("update:modelValue", inputOptions.value.find((o) => o.value === newId)?.item);
});
</script>
