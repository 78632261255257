import type { KContext } from "@data/expressions/context";
import { InsufficientContextError } from "@data/expressions/context";
import { BooleanExpression, StringExpression } from "@data/expressions/expressions";
import { refine } from "@data/helpers/NullHelpers";

export class HasRoleExpression extends BooleanExpression {
  private roleSet: Set<string>;

  constructor(private roleIds: string[]) {
    super();
    this.roleSet = new Set(roleIds);
  }

  evaluate(context: KContext): boolean | undefined {
    if (!context.person) {
      throw new InsufficientContextError("person");
    }
    return context.person.roles?.some((role) => this.roleSet.has(role));
  }

  display(context: KContext): string {
    return `user's role is one of: ${refine(this.roleIds, (id) => context.roleLabels?.get(id)).join(", ")}`;
  }
}

export class PersonIdExpression extends StringExpression {
  evaluate(context: KContext): string | undefined {
    if (!context.person) {
      throw new InsufficientContextError("person");
    }
    return context.person.id;
  }

  display(): string {
    return "current person id";
  }
}
