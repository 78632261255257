// units with only length dimension

import { MetricUnit } from "../units";

// Area
const squareMetre = new MetricUnit({
  symbol: "m²",
  expressionSymbol: "m2",
  name: "square metres",
  dimensions: { length: 2 }
});
const hectare = new MetricUnit({
  symbol: "ha",
  name: "hectares",
  dimensions: { length: 2 },
  conversion: 10000,
  subUnit: squareMetre
});
const squareKilometre = new MetricUnit({
  symbol: "km²",
  expressionSymbol: "km2",
  name: "square kilometres",
  dimensions: { length: 2 },
  conversion: 1e6,
  subUnit: hectare
});

const squareCentimetre = new MetricUnit({
  symbol: "cm²",
  expressionSymbol: "cm2",
  name: "square centimetres",
  dimensions: { length: 2 },
  conversion: 0.0001,
  superUnit: squareMetre
});

// Volume
const cubicMetre = new MetricUnit({
  symbol: "m³",
  name: "cubic metres",
  dimensions: { length: 3 }
});
const litre = new MetricUnit({
  symbol: "l",
  name: "litres",
  dimensions: { length: 3 },
  conversion: 0.001,
  superUnit: cubicMetre
});
const millilitre = new MetricUnit({
  symbol: "ml",
  name: "millilitres",
  dimensions: { length: 3 },
  conversion: 0.000001,
  superUnit: litre
});

export const spaceUnits = {
  squareCentimetre,
  squareMetre,
  hectare,
  squareKilometre,
  cubicMetre,
  litre,
  millilitre
};
