<template>
  <div ref="menuElement" class="dropdown-date-picker" @click.right.prevent>
    <date-picker v-model="data" :attributes is24hr :mode />
  </div>
</template>

<script setup lang="ts">
// Note: this currently only works in a KContextMenu

import { ref, watch } from "vue";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DatePicker } from "v-calendar";

import { getDatePickerAttributes } from "@ui/inputs/date-time/DatePickerAttributes";

import type { Dayjs } from "dayjs";

const props = defineProps<{
  /** Date picker value */
  modelValue: Dayjs | undefined;
  /** Date picker mode, defaults to date */
  mode?: "date" | "dateTime";
}>();

const emit = defineEmits<{
  /** Callback when date changes */
  (event: "update:modelValue", date: Dayjs | undefined): void;
}>();

dayjs.extend(utc);

const data = ref<Date>();

// const menuElement = ref<HTMLElement>();
// const { bottom } = useElementBounding(menuElement);

// onMounted(() => {
//   if (bottom.value > window.innerHeight) {
//     style.value = {
//       top: "0",
//       bottom: "-140px",
//       left: `${props.x}px`
//     };
//   }
// });

watch(
  () => props.modelValue,
  (newValue) => {
    if (!newValue) {
      data.value = undefined;
      return;
    } else if (!data.value || !newValue.isSame(data.value)) {
      data.value = newValue.toDate();
    }
  },
  { immediate: true }
);

watch(
  () => data.value,
  () => {
    if (data.value) {
      emit("update:modelValue", dayjs.utc(data.value).startOf(props.mode === "dateTime" ? "minute" : "day"));
    } else {
      emit("update:modelValue", undefined);
    }
  }
);

const attributes = getDatePickerAttributes();
</script>

<style scoped>
.dropdown-date-picker :deep(.v-calendar),
.dropdown-date-picker :deep(.vc-container) {
  border: none;
}
</style>
