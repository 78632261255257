/* eslint-disable import/order */
import { createApp, h } from "vue";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { registerKineticUI } from "@ui";
import { createPinia } from "pinia";

import App from "./App.vue";

import { registerKinabaseExpressions } from "./expressions/register";
import router from "./router";
import { apolloClient } from "./services/apollo";

const pinia = createPinia();
const app = createApp({
  render: () => h(App)
});

app.provide(DefaultApolloClient, apolloClient);
app.use(pinia);
registerKineticUI(app);
app.use(router);

// Get current environment from SSR, set up in vite.config.ts
const environment = window.__ssr__?.env ?? "production";

const isDevMode = environment.startsWith("dev");
if (!isDevMode) {
  Sentry.init({
    app,
    environment,
    dsn: "https://15655a7a5dcb482d82e88bf2f2ba0b4f@sentry.cambridgekinetics.com/23",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "kinabase.com", /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

registerKinabaseExpressions();

router
  .isReady()
  .then(() => {
    app.mount("#app");
  })
  .catch((err) => {
    console.error(err);
    Sentry.captureException(err);
  });
