import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";

import { KField } from "../KField";
import { capitalise } from "../../helpers/strings/Casing";
import { parseHistoricDate } from "../../helpers/datetimes/parseHistoricDate";

import type { KRecord, StoredKRecord } from "../../data/Record";
import type { ExpressionField } from "../../expressions/accessors";
import type { Datelike } from "../../types/Dates";
import type { KFieldSetup } from "../KField";
import type { TableCell } from "../TableCell";
import type { Dayjs } from "dayjs";

dayjs.extend(utc);
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export type DatetimePrecision = "year" | "quarter" | "month" | "day" | "hour" | "minute";

export const dateTimePrecisionLabels: Record<DatetimePrecision, string> = {
  year: "Year",
  quarter: "Quarter",
  month: "Month",
  day: "Day",
  hour: "Hour",
  minute: "Minute"
};

export const datetimePrecisionOptions: readonly DatetimePrecision[] = ["year", "quarter", "month", "day", "hour", "minute"];

export const datetimeDisplayFormats: Record<DatetimePrecision, string> = {
  year: "YYYY",
  quarter: "[Q]Q YYYY",
  month: "MM/YYYY",
  day: "DD/MM/YYYY",
  hour: "DD/MM/YYYY ha",
  minute: "DD/MM/YYYY HH:mm"
};

export const datetimeIsoFormats: Record<DatetimePrecision, string> = {
  year: "YYYY",
  quarter: "YYYY-MM",
  month: "YYYY-MM",
  day: "YYYY-MM-DD",
  hour: "YYYY-MM-DDTHH",
  minute: "YYYY-MM-DDTHH:mm"
};

export interface DatetimeSetup extends KFieldSetup {
  precision?: DatetimePrecision;
  default?: "now";
}

export class DatetimeField extends KField<Dayjs> implements ExpressionField<"datetime"> {
  readonly type: "date" | "datetime" = "datetime";

  readonly expressionType = "datetime";

  precision: DatetimePrecision;

  default: DatetimeSetup["default"];

  constructor(setup: DatetimeSetup | string) {
    super(setup);
    this.precision = (typeof setup === "object" && setup.precision) || "day";
    this.default = (typeof setup === "object" && setup.default) || undefined;
  }

  getValue(record: KRecord): Dayjs | undefined {
    const value = record[this.key];
    if (value) {
      return dayjs(value as Datelike);
    }
  }

  getSortableValue(record: KRecord): Date | undefined {
    return this.getValue(record)?.toDate();
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    return value?.format(datetimeDisplayFormats[this.precision]) ?? "";
  }

  getDefaultValue(): Dayjs | undefined {
    if (this.default === "now") {
      return dayjs.utc().startOf(this.precision);
    }
  }

  getExpressionValue(r: KRecord): Dayjs | undefined {
    return this.getValue(r);
  }

  getCSVValue(record: KRecord): string {
    const value = this.getValue(record);
    return value?.format(datetimeIsoFormats[this.precision]) ?? "";
  }

  getTableCell(record: KRecord): TableCell | undefined {
    const display = this.getDisplayValue(record);
    return display ? { type: "text", text: display, tabular: true } : undefined;
  }

  getFieldTypeName(): string {
    switch (this.precision) {
      case "day":
        return "Date";
      case "hour":
      case "minute":
        return "Date + Time";
      default:
        return capitalise(this.precision);
    }
  }

  validateType(value: unknown): value is Dayjs {
    return dayjs.isDayjs(value);
  }

  storeValue(source: KRecord, target: StoredKRecord): void {
    const value = this.getValue(source);
    if (value) {
      target[this.key] = value.toISOString();
    }
  }

  restoreValue(source: StoredKRecord, target: KRecord): void {
    const value = source[this.key] as string | undefined;
    target[this.key] = value ? parseHistoricDate(value) : undefined;
  }

  getDefaultColumnWidth() {
    switch (this.precision) {
      case "year":
        return 60;
      case "quarter":
        return 80;
      case "month":
        return 100;
      case "day":
        return 100;
      case "hour":
        return 140;
      case "minute":
        return 140;
    }
  }
}
