import type { KRecord, KFieldValue } from "../data/Record";
import type { ZodTypeAny } from "zod";

export type Severity = "warning" | "error";

export type ValidationIssues = { message: string; severity: Severity }[];

export type CustomValidator<V> = (value: V, record?: KRecord) => ValidationIssues;

export interface InputValidator<V extends KFieldValue = KFieldValue> {
  z?: ZodTypeAny;
  custom?: CustomValidator<V>;
}

/**
 * Run a validator on an input value
 *
 * @param validator Validator to use
 * @param value Value to validate
 * @returns List of validation issues the value has
 */
export const validate = <V>(validator: InputValidator<V> | undefined, value: V, record?: KRecord): ValidationIssues => {
  if (validator) {
    if (validator.custom) {
      return validator.custom(value, record);
    }
    if (validator.z) {
      const zResult = validator.z.safeParse(value);
      if (!zResult.success) {
        return zResult.error.errors.map(({ message, code }) => {
          if (code === "invalid_type" && value == null) {
            return { message: "Required", severity: "error" };
          }
          return { message, severity: "error" };
        });
      }
    }
  }
  return [];
};

export const hasErrors = (issues: ValidationIssues) => issues.some(({ severity }) => severity === "error");

export const getMaxSeverity = (issues: ValidationIssues) => {
  if (hasErrors(issues)) {
    return "error";
  }
  if (issues.length) {
    return "warning";
  }
  return undefined;
};
