<template>
  <div v-if="cleanedNumber" class="d-flex align-items-center mb-3">
    <span v-if="emoji" class="pe-2">{{ emoji }}</span>
    <k-dropdown v-if="makeVoipCall" :id variant="link" :label class="text-secondary phone-dropdown">
      <k-dropdown-item icon="phone-office" label="Call via VoIP" @click="makeCall(cleanedNumber!)" />
      <k-dropdown-item icon="phone" label="Call via Phone" @click="makePhoneCall(cleanedNumber!)" />
    </k-dropdown>
    <a v-else :href="`tel:${cleanedNumber}`" class="user-selectable" @click="emit('callInitiated', cleanedNumber)">{{ label }}</a>
  </div>
  <p v-else :id class="use-selectable text-secondary">None</p>
</template>

<script setup lang="ts">
import { computed, inject } from "vue";

import { AlertHandler } from "@ui/alerts/alert-handler";
import KDropdown from "@ui/dropdown/KDropdown.vue";
import KDropdownItem from "@ui/dropdown/KDropdownItem.vue";

import { makeVoipCallKey } from "./voipCall";

import type { PhoneNumber } from "@data/constants/countries/phone";
import { getPrefixInfo, toTelNumber } from "@data/constants/countries/phone";

const props = defineProps<{
  /** ID of the display for labelling purposes */
  id: string;
  /** Phone number to display */
  value?: PhoneNumber;
  /** Link text to display */
  label?: string;
}>();

const emit = defineEmits<{
  /** Emitted when a phone call is initiated */
  (e: "callInitiated", number: string): void;
}>();

const isEmpty = computed(() => !(props.value?.number && props.value.prefix));

const label = computed(() => {
  if (props.label || !props.value) {
    return props.label;
  }
  return `${props.value.prefix} ${props.value.number}${props.value.extension ? "#" + props.value.extension : ""}`;
});

const emoji = computed(() => {
  if (!props.value || isEmpty.value) {
    return "";
  }
  const prefixInfo = getPrefixInfo(props.value.prefix);
  return prefixInfo.emoji;
});

const cleanedNumber = computed(() => {
  if (!props.value || isEmpty.value) {
    return;
  }

  return toTelNumber(props.value);
});

const alertHandler = new AlertHandler();

const makeVoipCall = inject(makeVoipCallKey, undefined);

const makeCall = async (number: string) => {
  const result = await makeVoipCall?.value?.(number);
  if (result) {
    emit("callInitiated", number);
  } else {
    alertHandler.addAlert({
      variant: "danger",
      title: "Failed to initiate VoIP call",
      content: "Please check your VoIP settings and try again."
    });
  }
};

const makePhoneCall = (number: string) => {
  window.open(`tel:${number}`);
  emit("callInitiated", number);
};
</script>

<style scoped>
.phone-dropdown,
.phone-dropdown :deep(button) {
  padding: 0;
  height: 24px;
}

.phone-dropdown :deep(.btn-text) {
  padding: 0;
  text-decoration: underline;
}
</style>
