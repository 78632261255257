import { StringlikeField } from "../basic/StringFields";

import type { KRecord } from "../../data/Record";
import type { ValidationIssues } from "../../validation/Validation";

export class EmailField extends StringlikeField {
  readonly type = "email";

  validate(r: KRecord): ValidationIssues {
    const value = this.getValue(r);
    if (value && !value.match(/^[^@]+@[^@]+\.[^@]+$/)) {
      // this matches <anything>@<anything>.<anything>
      return [{ severity: "warning", message: "Invalid email address" }];
    }
    return [];
  }
}
