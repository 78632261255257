<template>
  <k-toolbar
    :title="pageTitle"
    class="position-relative wizard-toolbar"
    show-overflow-indicator
    rounded
    back-button="BACK"
    :structure="{
      end: [
        {
          title: 'Help',
          icon: 'question-circle',
          onClick: () => openHelp('/getting-started/setting-up'),
          type: 'button'
        },
        {
          title: 'Close',
          icon: 'close',
          onClick: onClose,
          type: 'button'
        }
      ]
    }"
    @back="onBack" />

  <wizard-multi-collection-list
    v-if="currentState === 'LIST'"
    :collections
    :prompt
    :isai
    :saving
    @add="addCollection"
    @delete="deleteCollection"
    @update="updateCollection"
    @edit="editCollection"
    @save="saveCollections" />

  <collection-form
    v-else-if="currentState === 'EDITING_COLLECTION' && selectedCollection"
    :collection-name="selectedCollection.plural"
    :collection="selectedCollection"
    :proposed-collections="collections"
    button-type="NEXT"
    :initial-step="editCollectionStep"
    :selected-field="selectedField"
    :loading="false"
    @edit-property="updateSelectedCollection"
    @add-field="addField"
    @edit-field="editField"
    @delete-field="deleteField"
    @toggle-activities="toggleFeature('activities')"
    @toggle-tasks="toggleFeature('tasks')"
    @toggle-files="toggleFeature('files')"
    @done="saveCollection" />
</template>

<script setup lang="ts">
import { computed, inject, nextTick, ref } from "vue";
import { useRouter } from "vue-router";

import KToolbar from "@ui/toolbar/KToolbar.vue";

import CollectionForm from "./CollectionForm.vue";
import WizardMultiCollectionList from "./WizardMultiCollectionList.vue";
import { navbarGroupKey } from "./navbarInfo";

import { AggregationField } from "@data/fields/relational/AggregationField";
import type { Collection } from "@data/data/Collection";
import type { KField } from "@data/fields/KField";
import { RecordField, MultiRecordField } from "@data/fields/relational/RecordFields";
import { toSlug } from "@data/helpers/strings/Casing";
import { toSingular, toPlural } from "@data/helpers/strings/Plurals";
import type { FieldType } from "@data/constants/fieldTypes";
import { createField } from "@data/constants/fieldTypes";
import { LookupField } from "@data/fields/relational/LookupField";

import { openHelp } from "@/consts/openHelp";
import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { toTitleCase } from "@/helpers/useCollectionNameVariants";
import { isComputedField } from "@/expressions/computed";

const props = defineProps<{
  /** Initial search value from user */
  prompt?: string;
  /** Suggested collections from previous step */
  collections?: Collection[];
  /** If AI generated collection names then suggest ai fields */
  isai?: boolean;
}>();

const emit = defineEmits<{
  /** Emitted when the modal is closed */
  (event: "close"): void;
  (event: "back"): void;
}>();

// Navigation
type EditorState = "LIST" | "EDITING_COLLECTION";
const currentState = ref<EditorState>("LIST");
const selectedCollection = ref<Collection>();

const pageTitle = computed(() => {
  switch (currentState.value) {
    case "LIST":
      return "Add Collections";
    case "EDITING_COLLECTION":
      return `Edit ${selectedCollection.value?.plural}`;
    default:
      return "";
  }
});

const onClose = () => emit("close");
const onBack = () => {
  if (currentState.value === "EDITING_COLLECTION") {
    selectedCollection.value = undefined;
    currentState.value = "LIST";
  } else {
    emit("back");
  }
};

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const collections = ref<Collection[]>(props.collections ?? []);

// Managing collections
const addCollection = (collection: Collection) => {
  collections.value.push(collection);
};

const deleteCollection = (collectionId: string) => {
  const index = collections.value.findIndex((c) => c.id === collectionId);
  if (index > -1) {
    collections.value.splice(index, 1);
  }
};

const updateCollection = (collectionId: string, property: "name" | "icon" | "description" | "fields", value: string | KField[]) => {
  const collection = collections.value.find((c) => c.id === collectionId);
  if (!collection) return;

  switch (property) {
    case "name":
      collection.singular = toTitleCase(toSingular(value as string));
      collection.plural = toTitleCase(toPlural(value as string));
      collection.slug = toSlug(collection.plural);
      break;
    case "fields":
      collection.fields = value as KField[];
      break;
    case "description":
      collection.description = value as string;
      break;
    case "icon":
      collection.icon = value as string;
      break;
  }
};

// Updating collection properties
const updateSelectedCollection = (property: "name" | "description" | "icon", newString: string) => {
  if (!selectedCollection.value) return;
  updateCollection(selectedCollection.value.id, property, newString);
};

// Editing collections
const selectedField = ref<KField>();
const editCollectionStep = ref<0 | 1>(0);
const editCollection = (collectionId: string, step: "general" | "fields", field?: KField) => {
  const collection = collections.value.find((c) => c.id === collectionId);
  if (!collection) return;

  selectedCollection.value = collection;
  selectedField.value = field;
  currentState.value = "EDITING_COLLECTION";
  editCollectionStep.value = step === "fields" ? 1 : 0;
};

// Field management
const addField = (field: KField) => {
  if (!selectedCollection.value) return;
  selectedCollection.value.fields.push(field);
};

const editField = (oldField: KField, newField: KField) => {
  if (!selectedCollection.value) return;
  const index = selectedCollection.value.fields.indexOf(oldField);
  if (index > -1) {
    selectedCollection.value.fields[index] = newField;
  }
};

const deleteField = (field: KField) => {
  if (!selectedCollection.value) return;
  const index = selectedCollection.value.fields.indexOf(field);
  if (index > -1) {
    selectedCollection.value.fields.splice(index, 1);
  }
};

const toggleFeature = (ft: "activities" | "tasks" | "files") => {
  if (!selectedCollection.value) return;
  if (!selectedCollection.value[ft]) {
    selectedCollection.value[ft] = { isEnabled: true };
    return;
  }
  selectedCollection.value[ft].isEnabled = !selectedCollection.value[ft].isEnabled;
};

const saveCollection = () => {
  selectedCollection.value = undefined;
  currentState.value = "LIST";
};

const saving = ref(false);
const router = useRouter();
const collectionStore = useCollectionStore();
const navbarGroup = inject(navbarGroupKey, ref(undefined));

// (relation fields and the old ids of their (new) related collections) by the new ids of the (relation) fields' collections
type RelationalFieldAssignments = { [newParentId: string]: { field: RecordField | MultiRecordField | AggregationField | LookupField; oldRelatedId: string }[] };
const addCollectionsWithoutRelations = async () => {
  const savedCollections: Collection[] = [];
  const oldToNewIds: { [oldId: string]: string } = {};
  const relationFieldAssignments: RelationalFieldAssignments = {};
  for (const proposedCollection of collections.value) {
    const relationFields: { field: RecordField | MultiRecordField | AggregationField | LookupField; oldRelatedId: string }[] = [];
    const normalFields: KField[] = [];
    // record and remove the relation fields which refer to proposed collections
    for (const field of proposedCollection.fields) {
      if ((field instanceof RecordField || field instanceof MultiRecordField) && collections.value.map(({ id }) => id).includes(field.collectionId)) {
        relationFields.push({ field, oldRelatedId: field.collectionId });
      } else if (
        field instanceof AggregationField &&
        field.aggregationSetup.targetCollection &&
        collections.value.map(({ id }) => id).includes(field.aggregationSetup.targetCollection)
      ) {
        relationFields.push({ field, oldRelatedId: field.aggregationSetup.targetCollection });
      } else if (
        field instanceof LookupField &&
        field.lookupConfig.targetCollection &&
        collections.value.map(({ id }) => id).includes(field.lookupConfig.targetCollection)
      ) {
        relationFields.push({ field, oldRelatedId: field.lookupConfig.targetCollection });
      } else if (!isComputedField(field)) {
        // computed fields also need to be added back in later since they might refer to the relation fields...
        normalFields.push(field);
      }
    }
    // add the collection to kinabase
    try {
      const properCollectionId = await collectionStore.addCollection(
        {
          displayNameSingular: proposedCollection.singular,
          displayNamePlural: proposedCollection.plural,
          slug: proposedCollection.slug,
          icon: proposedCollection.icon,
          description: proposedCollection.description,
          enableActivities: !!proposedCollection.activities?.isEnabled,
          enableTasks: !!proposedCollection.tasks?.isEnabled,
          enableFiles: !!proposedCollection.files?.isEnabled,
          navbarGroup: navbarGroup.value?.label
        },
        normalFields
      );

      if (properCollectionId) {
        savedCollections.push(proposedCollection);
        oldToNewIds[proposedCollection.id] = properCollectionId;
        relationFieldAssignments[properCollectionId] = relationFields;
      } else {
        throw new Error(`Failed to create the "${proposedCollection.plural}" collection`);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return {
    savedCollections,
    oldToNewIds,
    relationFieldAssignments
  };
};

const addRelationalFields = async (relationFieldAssignments: RelationalFieldAssignments, idMap: Record<string, string>) => {
  for (const newParentId in relationFieldAssignments) {
    const assignments = relationFieldAssignments[newParentId];
    for (const { field, oldRelatedId } of assignments) {
      const newRelatedId = idMap[oldRelatedId];
      // (only add relation fields for which the related collection was successfully created)
      if (newRelatedId) {
        const existingKeys =
          collectionStore
            .getCollectionWithId(newParentId)
            ?.schema.filter((f) => f.id !== field.id)
            .map((f) => f.key) ?? [];
        let newField: KField | undefined;
        if (field instanceof AggregationField) {
          const aggregationOptions = { ...field.aggregationSetup, targetCollection: newRelatedId };
          newField = createField("aggregation", field.label, existingKeys, {
            description: field.description,
            id: field.id,
            aggregationOptions
          });
        } else if (field instanceof LookupField) {
          const lookupConfig = { ...field.lookupConfig, targetCollection: newRelatedId };
          newField = createField("lookup", field.label, existingKeys, { description: field.description, id: field.id, ...lookupConfig });
        } else {
          const newFieldType: FieldType = field instanceof RecordField ? `record-${newRelatedId}` : `multiRecord-${newRelatedId}`;
          newField = createField(newFieldType, field.label, existingKeys, { description: field.description, id: field.id });
        }
        if (newField) {
          await collectionStore.addField(newParentId, newField);
        }
      }
    }
  }
};

const saveCollections = async () => {
  saving.value = true;

  // create all the collections without their relation fields
  const { savedCollections, oldToNewIds, relationFieldAssignments } = await addCollectionsWithoutRelations();

  // add back in the relation fields
  await addRelationalFields(relationFieldAssignments, oldToNewIds);

  // and the computed fields
  for (const collection of savedCollections) {
    const computedFields = collection.fields.filter(isComputedField);
    for (const computedField of computedFields) {
      await collectionStore.addField(oldToNewIds[collection.id], computedField);
    }
  }

  await nextTick(() => {
    onClose();
    // redirect the user
    if (savedCollections.length > 0) {
      void router.push(`/c/${savedCollections[0].slug}`);
    }
  });
};
</script>
