// Load all CSS styles
import "@popperjs/core";
import "bootstrap";
import "focus-visible";

import "../styles";

import type { App } from "vue";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isoWeek from "dayjs/plugin/isoWeek";
import localeData from "dayjs/plugin/localeData";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";

import { MessageBoxHandler, MessageBox } from "@ui/alerts/message-box";
import { initNavbarStateProvider } from "@ui/navbar/NavbarState";

export const useDayjs = () => {
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isBetween);
  dayjs.extend(utc);
  dayjs.extend(quarterOfYear);
  dayjs.extend(isoWeek);
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(relativeTime);
};

export const registerKineticUI = (app: App<Element>) => {
  useDayjs();

  const messageBoxHandler = new MessageBoxHandler(app);
  app.provide(MessageBox, messageBoxHandler);

  initNavbarStateProvider()(app);
};
