<template>
  <div class="p-2 border rounded mb-4">
    <div class="d-flex mt-1 align-items-start">
      <div>
        <k-label label="Icon" />
        <k-icon-picker v-model="currentValue.icon" />
      </div>
      <div class="mx-2 flex-grow-1">
        <k-input-config :status="nameVariants.issues.length > 0 ? 'INVALID' : 'NONE'" placeholder="Enter a collection name">
          <k-input ref="nameInput" v-model="currentValue.name" label="Collection Name" @keydown="onAddCollectionNameKeyDown" />
          <k-issue-display v-if="nameVariants.issues.length > 0" :issues="nameVariants.issues" class="issue-display" />
        </k-input-config>
      </div>
      <div class="button-padding d-flex align-items-start">
        <k-button label="Cancel" class="me-2" @click="emit('cancel')" />
        <k-button icon="plus" variant="primary" label="Add" @click="submit" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from "vue";

import { v4 as uuidv4 } from "uuid";

import KButton from "@ui/button/KButton.vue";
import KLabel from "@ui/label/KLabel.vue";
import KInputConfig from "@ui/inputs/KInputConfig.vue";
import KIssueDisplay from "@ui/inputs/KIssueDisplay.vue";
import KIconPicker from "@ui/inputs/icon/KIconPicker.vue";
import KInput from "@ui/inputs/strings/KInput.vue";

import { Collection } from "@data/data/Collection";
import { deepCopy } from "@data/helpers/manipulation/Copying";
import { toSingular } from "@data/helpers/strings/Plurals";

import { toTitleCase, useCollectionNameVariants } from "@/helpers/useCollectionNameVariants";

const emit = defineEmits<{
  (event: "add", collection: Collection): void;
  (event: "cancel"): void;
}>();

// Adding new collections
const nameInput = ref<{ $el: HTMLElement }>();
const defaultValue = { name: "", icon: "folder" };
const currentValue = ref(deepCopy(defaultValue));

const name = computed(() => currentValue.value.name);
const nameVariants = useCollectionNameVariants(name);

const showIssues = ref(false);

const focusInput = () => {
  nameInput.value?.$el.getElementsByTagName("input")[0].focus();
};

// Submit add collection form
const submit = async () => {
  if (nameVariants.value.issues.length > 0 || currentValue.value.name === "") {
    showIssues.value = true;
    return;
  }

  const newCollection = new Collection(uuidv4(), toTitleCase(toSingular(currentValue.value.name)), [], {
    icon: currentValue.value.icon,
    activities: { isEnabled: true }
  });
  emit("add", newCollection);
  showIssues.value = false;

  currentValue.value = deepCopy(defaultValue);

  await nextTick(focusInput);
};

// Handle enter key on input
const onAddCollectionNameKeyDown = async (e: KeyboardEvent) => {
  if (e.key !== "Enter") return;
  await submit();
};

// Expose focusInput function to parent, used to focus input when add form is opened
defineExpose({ focusInput });
</script>

<style scoped>
.issue-display {
  flex-shrink: 1;
  overflow-y: auto;
}
.button-padding {
  flex-shrink: 0;
  padding-top: 18px;
}
</style>
