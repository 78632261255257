import Papa from "papaparse";

import { SimpleField } from "../basic/SimpleFields";
import { KeyField, KeysField } from "../sub/SubField";
import { BulkData } from "../../expressions/data";

import { validateRecordOption, type KRecordOption } from "./RecordFields";

import type { RelationalFilters } from "./RecordFields";
import type { KContext } from "../../expressions/context";
import type { KRecord } from "../../data/Record";
import type { ExpressionField } from "../../expressions/accessors";
import type { KFieldSetup, Sortable } from "../KField";

interface PeopleFieldSetup extends KFieldSetup {
  // note: the expressions here will evaluate in a people context
  // i.e. will use person.[fieldId] rather than record.[fieldId]
  filters?: RelationalFilters;
}

interface PersonFieldSetup extends PeopleFieldSetup {
  /** Default value - "current" for current user */
  defaultValue?: "current";
}

export class PersonField extends SimpleField<KRecordOption> implements ExpressionField<"string"> {
  readonly granular = true;

  readonly type = "person";

  readonly expressionType = "string";

  readonly defaultValue?: string;

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly idSubfield = new KeyField("id", () => this, { label: `${this.label} ID`, idFor: "person" });

  filters?: RelationalFilters;

  constructor(setup: string | PersonFieldSetup) {
    super(setup);
    if (typeof setup === "object") {
      this.defaultValue = setup.defaultValue;
      this.filters = setup.filters;
    }

    this.subfields = [this.idSubfield];
  }

  override getDefaultValue(context: KContext): KRecordOption | undefined {
    if (this.defaultValue === "current" && context.person) {
      return {
        id: context.person.id,
        label: context.person.name
      };
    }
  }

  getSortableValue(record: KRecord, toLowerCase?: boolean | undefined): Sortable {
    const label = this.getValue(record)?.label;
    return toLowerCase ? label?.toLowerCase() : label;
  }

  getDisplayValue(record: KRecord): string {
    return this.getValue(record)?.label ?? "";
  }

  getExpressionValue(r: KRecord): string | undefined {
    return this.getValue(r)?.label;
  }

  validateType(value: unknown): value is KRecordOption {
    return validateRecordOption(value);
  }
}

export class PeopleField extends SimpleField<KRecordOption[]> implements ExpressionField<"data"> {
  readonly type = "people";

  readonly expressionType = "data";

  // eslint-disable-next-line unicorn/consistent-function-scoping
  readonly idsSubfield = new KeysField("id", () => this, { label: `${this.label} IDs`, idFor: "person" });

  filters?: RelationalFilters;

  constructor(setup: string | PeopleFieldSetup) {
    super(setup);
    if (typeof setup === "object") {
      this.filters = setup.filters;
    }
    this.subfields = [this.idsSubfield];
  }

  getLabels(record: KRecord) {
    const values = this.getValue(record);
    return Array.isArray(values) ? values.map((v) => v.label) : undefined;
  }

  getSortableValue(record: KRecord, toLowerCase?: boolean | undefined): Sortable {
    const labels = this.getLabels(record);
    return toLowerCase ? labels?.join(", ").toLowerCase() : labels?.join(", ");
  }

  getDisplayValue(record: KRecord): string {
    return this.getLabels(record)?.join(", ") ?? "";
  }

  getCSVValue(record: KRecord): string {
    const labels = this.getLabels(record);
    if (labels !== undefined) {
      return Papa.unparse([labels]);
    }
    return "";
  }

  getExpressionValue(r: KRecord) {
    return new BulkData(this.getLabels(r) ?? []);
  }

  validateType(value: unknown): value is KRecordOption[] {
    return Array.isArray(value) && value.every(validateRecordOption);
  }
}
