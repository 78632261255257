import { fromJson, toJson, type JsonSerialisable } from "./TypedJSON";

export interface Typed {
  readonly type: string;
}

// Record of functions that take an object of a specifc type, returns a value of type D, and also takes extra arguments of type A
export type FunctionRecord<T extends Typed, D, A extends unknown[] = []> = {
  [K in T["type"]]: (f: T & { readonly type: K }, ...args: A) => D;
};

export type DeepPartial<T> =
  T extends Record<string, JsonSerialisable>
    ? {
        [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T;

export type StoredObject = {
  type: string;
  data: JsonSerialisable;
};

export class Registry<const T extends Typed = never> {
  private sDict: Map<string, { toData: unknown; fromData: unknown }> = new Map();

  constructor(serialisers: Registry<T>["sDict"] = new Map()) {
    this.sDict = serialisers;
  }

  register<S extends Typed, J extends JsonSerialisable>(
    type: S["type"] | S["type"][],
    toData: (obj: S) => J,
    fromData: (data: DeepPartial<J>) => S
  ): Registry<T | S> {
    const types = Array.isArray(type) ? type : [type];
    for (const t of types) {
      if (this.sDict.has(t)) {
        throw new Error(`Serialiser for ${t} registered twice!`);
      }
    }
    return new Registry<T | S>(new Map([...this.sDict, ...types.map((t) => [t, { toData, fromData }] as const)]));
  }

  has(type: string): type is T["type"] {
    return this.sDict.has(type);
  }

  isRegistered(obj: Typed): obj is T {
    return this.has(obj.type);
  }

  /**
   * Convert an object into a JSON-serialisable structure
   *
   * @param obj Object to store
   * @returns Object that can be recovered using this class's .restore() method
   */
  store(obj: T): StoredObject {
    const serialiser = this.sDict.get(obj.type)?.toData as ((data: T) => JsonSerialisable) | undefined;
    if (serialiser) {
      return { type: obj.type, data: serialiser(obj) };
    }
    throw Error(`No serialiser found for key ${obj.type}`);
  }

  /**
   * Serialise an object (that has been registered using register() beforehand)
   *
   * @memberof Serialiser
   * @template S
   * @param {S} obj Object which class has been registered using register() beforehand
   * @returns {string} Serialised data
   */
  serialise(obj: T): string {
    return toJson(this.store(obj));
  }

  /**
   * Restore an object from its stored from
   *
   * @param parsed Object that has previously been created using .store()
   * @returns Restored object
   */
  restore(parsed: StoredObject) {
    const deserialiser = this.sDict.get(parsed.type)?.fromData as ((data: JsonSerialisable) => T) | undefined;
    if (deserialiser) {
      return deserialiser(parsed.data);
    }
    throw Error(`No deserialiser found for key ${parsed.type}`);
  }

  /**
   * Deserialise an object
   *
   * @memberof Serialiser
   * @template T
   * @param {string} s
   * @returns {any} {T} deserialised object (one of the subclasses of T)
   */
  deserialise(s: string): T {
    const parsed = fromJson<StoredObject>(s);
    return this.restore(parsed);
  }

  /** .store() for an array of objects */
  bulkStore(objs: T[]): StoredObject[] {
    return objs.map((obj) => {
      const serialiser = this.sDict.get(obj.type)?.toData as ((data: T) => JsonSerialisable) | undefined;
      if (serialiser) {
        return { type: obj.type, data: serialiser(obj) };
      }
      throw Error(`No serialiser found for key ${obj.type}`);
    });
  }

  /** .serialise() for an array of objects */
  bulkSerialise(objs: T[]): string {
    const toSerialise = this.bulkStore(objs);
    return toJson(toSerialise);
  }

  /** .restore() for an array of stored objects */
  bulkRestore(parsed: StoredObject[]): T[] {
    return parsed.map((data) => {
      const deserialiser = this.sDict.get(data.type)?.fromData as ((data: JsonSerialisable) => T) | undefined;
      if (deserialiser) {
        return deserialiser(data.data);
      }
      throw Error(`No deserialiser found for key ${data.type}`);
    });
  }

  /** .deserialise() for a serialised array of objects */
  bulkDeserialise(s: string): T[] {
    const parsed = fromJson<{ type: string; data: JsonSerialisable }[]>(s);
    return this.bulkRestore(parsed);
  }
}

export type RegistryType<R extends Registry<Typed>> = R extends Registry<infer T> ? T : never;

export class FunctionRegistry<R extends Registry<Typed>, D, const A extends unknown[] = []> {
  constructor(private functions: FunctionRecord<RegistryType<R>, D, A>) {}

  call(obj: RegistryType<R>, ...args: A): D {
    return this.functions[obj.type as RegistryType<R>["type"]](obj, ...args);
  }
}

export class PartialFunctionRegistry<R extends Registry<Typed>, D, const A extends unknown[] = []> {
  constructor(private functions: Partial<FunctionRecord<RegistryType<R>, D, A>>) {}

  call(obj: RegistryType<R>, ...args: A): D | undefined {
    return this.functions[obj.type as RegistryType<R>["type"]]?.(obj, ...args);
  }
}
