import { formatUnitPowers } from "../formatting";
import { MetricUnit } from "../units";

import type { Dimensions } from "../dimensions";

export const metre = new MetricUnit({
  symbol: "m",
  name: "metre",
  dimensions: { length: 1 },
  si: true
});
export const tonne = new MetricUnit({
  symbol: "t",
  name: "tonne",
  dimensions: { mass: 1 },
  conversion: 1000
});
export const gram = new MetricUnit({
  symbol: "g",
  name: "gram",
  dimensions: { mass: 1 },
  conversion: 0.001
});
export const kilogram = new MetricUnit({
  symbol: "kg",
  name: "kilogram",
  dimensions: { mass: 1 },
  superUnit: tonne,
  subUnit: gram
});

export const day = new MetricUnit({
  symbol: "d",
  name: "day",
  dimensions: { time: 1 },
  conversion: 86400
});
export const hour = new MetricUnit({
  symbol: "h",
  name: "hour",
  dimensions: { time: 1 },
  conversion: 3600,
  superUnit: day
});
export const minute = new MetricUnit({
  symbol: "min",
  name: "minute",
  dimensions: { time: 1 },
  conversion: 60,
  superUnit: hour
});
export const second = new MetricUnit({
  symbol: "s",
  name: "second",
  dimensions: { time: 1 },
  si: true,
  superUnit: minute
});

export const ampere = new MetricUnit({
  symbol: "A",
  name: "ampere",
  description: "Electrical current",
  dimensions: { current: 1 },
  si: true
});
export const kelvin = new MetricUnit({
  symbol: "K",
  name: "kelvin",
  description: "Degrees above absolute zero",
  dimensions: { temperature: 1 },
  si: true
});
export const celcius = new MetricUnit({
  name: "Celcius",
  symbol: "°C",
  expressionSymbol: "degC",
  dimensions: { temperature: 1 },
  conversion: 1,
  offset: 273.15
});
export const mole = new MetricUnit({
  symbol: "mol",
  name: "mole",
  description: "Quantity of atoms/molecules",
  dimensions: { moles: 1 },
  si: true
});
export const candela = new MetricUnit({
  symbol: "cd",
  name: "candela",
  description: "Light intensity",
  dimensions: { luminosity: 1 },
  si: true
});
export const byte = new MetricUnit({
  symbol: "B",
  name: "byte",
  dimensions: { data: 1 },
  si: true,
  fixedPlaces: 0
});
export const sterling = new MetricUnit({
  symbol: "£",
  name: "pound",
  dimensions: { sterling: 1 },
  prefix: true,
  fixedPlaces: 2
});
export const dollar = new MetricUnit({
  symbol: "$",
  name: "dollar",
  dimensions: { dollar: 1 },
  prefix: true,
  fixedPlaces: 2
});
export const euro = new MetricUnit({
  symbol: "€",
  name: "euro",
  dimensions: { euro: 1 },
  prefix: true,
  fixedPlaces: 2
});

export const SIUnits: Record<keyof Dimensions, MetricUnit> = {
  length: metre,
  mass: kilogram,
  time: second,
  current: ampere,
  temperature: kelvin,
  moles: mole,
  luminosity: candela,
  data: byte,
  sterling,
  dollar,
  euro
};
/**
 * Returns the SI unit in string form for a given dimension (e.g. {length: 1, time: -1} would be m/s)
 *
 * @param dimensions Dimensions of the unit
 * @param negativePowers Whether to use negative powers (ms-1) or not (m/s)
 * @param type Whether to use html superscript (preferred) or unicode superscript
 */
export const formatSIDimensions = (dimensions: Dimensions, type: "html" | "unicode" = "html", negativePowers = false): string =>
  formatUnitPowers(
    Object.entries(dimensions).map(([symbol, power]) => ({
      symbol: SIUnits[symbol as keyof Dimensions].symbol,
      power
    })),
    type,
    negativePowers
  );

/** Creates a SI MetricUnit corresponding to the given dimensions */
export const generateUnit = (dimensions: Dimensions) => {
  const symbol = formatSIDimensions(dimensions, "unicode", false);
  return new MetricUnit({
    name: symbol,
    dimensions,
    conversion: 1,
    symbol
  });
};
