/* eslint-disable max-lines */
import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";

import type { PhoneNumber } from "@data/constants/countries/phone";
import type { TenantFileStorage, TenantEmailIntegration, PickFromTenantIntegrations, TenantVoipDetails } from "@data/data/tenant/Integrations";
import type { Tenant, TenantDetails, TenantAuth, TenantAuthMethodUsage, TenantPlanDetails, TenantPeopleSettings } from "@data/data/tenant/Tenant";

const getTenantDetails = () =>
  useQuery<{ tenant?: Tenant & TenantDetails }>(
    gql`
      query tenantDetails {
        tenant {
          id
          name
          details {
            website
            orgSize
            country
            phone {
              prefix
              number
            }
            logoAssetId
          }
        }
      }
    `,
    {},
    { returnPartialData: false, fetchPolicy: "cache-and-network" }
  );

const getTenantDomains = () =>
  useQuery<{ tenant?: Tenant & TenantDetails }>(
    gql`
      query tenantDetails {
        tenant {
          id
          name
          details {
            domain
          }
        }
      }
    `,
    {},
    { returnPartialData: false, fetchPolicy: "cache-and-network" }
  );

const getTenantAuthMethods = () =>
  useQuery<{ tenant?: Tenant & TenantAuth & TenantAuthMethodUsage }>(
    gql`
      query tenantAuthMethods {
        tenant {
          id
          auth {
            password
            providers {
              key
              value {
                providerTenantId
                isEnabled
                status
                name
                createdAt
                createdById
                lastSynced
                personFieldAssignments {
                  jobTitleAssignment
                  managerAssignment
                }
              }
            }
          }
          authMethodUsage {
            key
            value {
              enabledCount
              disabledCount
              canDisable
            }
          }
          userCount
        }
      }
    `,
    {},
    { returnPartialData: false, fetchPolicy: "network-only" }
  );

const getTenantFileStorage = () =>
  useQuery<{ tenant?: Tenant & TenantFileStorage & TenantPlanDetails }>(
    gql`
      query tenantFileStorage {
        tenant {
          id
          fileStorage {
            provider
            maxFileSize
            maxStorage
            usedStorage
          }
          storageConsumption {
            collectionId
            count
            fileSize
          }
          userCount
          activeUserCount
          planCode
          plan {
            label
            variant
            planCode
            recordLimit
            storageLimit
          }
        }
      }
    `,
    {},
    { returnPartialData: false, fetchPolicy: "cache-and-network" }
  );

const syncUsersWithAuthProvider = () =>
  useMutation<{ tenantSyncUsersWithAuthProvider: AuthSyncResults }>(gql`
    mutation tenantSyncUsersWithAuthProvider($provider: AuthProvider!, $providerIdsToSync: [String!]!) {
      tenantSyncUsersWithAuthProvider(provider: $provider, providerIdsToSync: $providerIdsToSync) {
        usersAdded
        usersUpdated
        usersRemoved
        usersWithProvider
        failures
        success
      }
    }
  `);

const previewUser = `
id
name
email
groupIds`;
const syncUsersWithAuthProviderPreview = () =>
  useMutation<{ tenantSyncUsersWithAuthProviderPreview: AuthSyncResultsPreview }, { provider: string }>(gql`
    mutation tenantSyncUsersWithAuthProviderPreview($provider: AuthProvider!) {
      tenantSyncUsersWithAuthProviderPreview(provider: $provider) {
        usersAdded {
          ${previewUser}
        }
        usersUpdated {
          ${previewUser}
        }
        usersRemoved {
          ${previewUser}
        }
        success
      }
    }
  `);

const setAuthProviderStatus = () =>
  useMutation<{ tenantToggleAuthMethod: boolean }, { provider: string; shouldEnable: boolean }>(
    gql`
      mutation tenantSetAuthMethodStatus($provider: String!, $shouldEnable: Boolean!) {
        tenantSetAuthMethodStatus(provider: $provider, shouldEnable: $shouldEnable)
      }
    `,
    { refetchQueries: ["tenantAuthMethods"] }
  );

type SyncFieldAssignments = {
  jobTitleAssignment?: string;
  managerAssignment?: string;
};

const setAuthProviderFieldAssignments = () =>
  useMutation<{ tenantUpdateAuthProviderAssignments: boolean }, { provider: string; fieldAssignments: SyncFieldAssignments }>(gql`
    mutation tenantUpdateAuthProviderAssignments($provider: String!, $fieldAssignments: PersonFieldAssignmentsInput!) {
      tenantUpdateAuthProviderAssignments(provider: $provider, assignments: $fieldAssignments)
    }
  `);

interface TenantDetailsUpdate {
  name: string;
  website: string;
  country: string;
  orgSize: number;
  registrationNo: string;
  phone: PhoneNumber;
}

const setTenantDetails = () =>
  useMutation<{ tenantUpdateDetails: boolean }, { details: Partial<TenantDetailsUpdate> }>(gql`
    mutation tenantUpdateDetails($details: TenantDetailsUpdateInput!) {
      tenantUpdateDetails(details: $details)
    }
  `);

const getTenantEmailIntegration = () =>
  useQuery<{ tenant?: Tenant & TenantAuth & TenantEmailIntegration }>(
    gql`
      query tenantDetails {
        tenant {
          id
          name
          auth {
            providers {
              key
              value {
                isEnabled
                status
              }
            }
          }
          emailIntegration {
            isEnabled
            organisationCollectionId
            organisationNameFieldId
            organisationWebsiteFieldId
            contactCollectionId
            contactNameFieldId
            contactEmailFieldId
            lastUsed
          }
        }
      }
    `,
    {},
    { returnPartialData: false, fetchPolicy: "cache-and-network" }
  );

const setTenantEmailIntegration = () =>
  useMutation<{ tenantUpdateEmailIntegration: boolean }, { details: Partial<TenantEmailIntegration> }>(gql`
    mutation tenantUpdateEmailIntegration($details: TenantEmailIntegrationInput!) {
      tenantUpdateEmailIntegration(details: $details)
    }
  `);

const setTenantFileStorageProvider = () =>
  useMutation<{ provider: string; driveId?: string }>(gql`
    mutation tenantUpdateFileStorage($provider: FileStorageProvider!, $driveId: String) {
      tenantUpdateFileStorage(provider: $provider, driveId: $driveId)
    }
  `);

const getTenantFileStorageProvider = () =>
  useQuery<{ tenant?: Tenant & TenantFileStorage & TenantAuth & PickFromTenantIntegrations<"sharePoint"> & TenantPlanDetails }>(
    gql`
      query tenantDetails {
        tenant {
          plan {
            storageLimit
          }
          fileStorage {
            provider
            maxFileSize
            maxStorage
            usedStorage
          }
          integrations {
            sharePoint {
              driveId
            }
          }
          auth {
            providers {
              key
              value {
                isEnabled
                status
              }
            }
          }
        }
      }
    `,
    {},
    { returnPartialData: false, fetchPolicy: "cache-and-network" }
  );

const getTenantVoipIntegration = () =>
  useQuery<{ tenant?: Tenant & PickFromTenantIntegrations<"voip"> }>(
    gql`
      query tenantDetails {
        tenant {
          id
          name
          integrations {
            voip {
              type
              token
              extension
              callerId
              pbx
              username
              password
              apiKey
              userId
            }
          }
        }
      }
    `,
    {},
    { returnPartialData: false, fetchPolicy: "cache-and-network" }
  );

const updateTenantVoipIntegration = () =>
  useMutation<{ tenantUpdateVoipIntegration?: { type: TenantVoipDetails["type"] } }, { config?: TenantVoipDetails }>(gql`
    mutation tenantUpdateVoipIntegration($config: VoipIntegrationInput) {
      tenantUpdateVoipIntegration(config: $config) {
        type
      }
    }
  `);

const updateTenantPeopleVisibility = () =>
  useMutation<{ tenantUpdatePeoplePermissions?: TenantPeopleSettings }, { newPermissions: { read: string[] } }>(gql`
    mutation tenantUpdatePeoplePermissions($newPermissions: ReadOnlyPermissionsInput!) {
      tenantUpdatePeoplePermissions(newPermissions: $newPermissions) {
        permissions {
          read
        }
      }
    }
  `);

interface AuthSyncResults {
  usersAdded: string[];
  usersUpdated: string[];
  usersRemoved: string[];
  usersWithProvider: string[];
  failures: string[];
  success: boolean;
}

type PreviewUser = {
  id: string;
  name: string;
  email: string;
  groupIds: string[];
};

export type AuthSyncResultsPreview = {
  usersAdded: PreviewUser[];
  usersUpdated: PreviewUser[];
  usersRemoved: PreviewUser[];
  success: boolean;
};

export const TenantAPI = {
  getTenantDetails,
  getTenantDomains,
  getTenantAuthMethods,
  getTenantFileStorage,
  syncUsersWithAuthProvider,
  setAuthProviderStatus,
  setAuthProviderFieldAssignments,
  setTenantDetails,
  setTenantEmailIntegration,
  getTenantEmailIntegration,
  syncUsersWithAuthProviderPreview,
  setTenantFileStorageProvider,
  getTenantFileStorageProvider,
  getTenantVoipIntegration,
  updateTenantVoipIntegration,
  updateTenantPeopleVisibility
};
