import type { KField } from "@data/fields/KField";
import type { ComputedField } from "@data/fields/computed/ComputedField";
import { AggregationField } from "@data/fields/relational/AggregationField";
import { LookupField } from "@data/fields/relational/LookupField";
import type { PeopleField, PersonField } from "@data/fields/relational/PeopleFields";
import type { MultiRecordField, RecordField } from "@data/fields/relational/RecordFields";

// can't use isinstance as ComputedField is abstract class
export const isComputedField = (field: KField): field is ComputedField => "expression" in field;

export const isForeignField = (field: KField): field is LookupField | AggregationField => field instanceof LookupField || field instanceof AggregationField;

const relationalTypes = new Set(["record", "multiRecord", "person", "people"]);
export const isRelationalField = (field: KField): field is RecordField | MultiRecordField | PersonField | PeopleField => relationalTypes.has(field.type);

export const isServerCalculated = (field: KField) => isComputedField(field) || isForeignField(field);

export const isLinkedField = (field: KField) => isRelationalField(field) || isForeignField(field);
