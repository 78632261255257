import type { Ref } from "vue";
import { computed } from "vue";

import type { Collection } from "@data/data/Collection";
import type { KRecordOption, RelationalFilters } from "@data/fields/relational/RecordFields";
import { MultiRecordField, RecordField } from "@data/fields/relational/RecordFields";
import { toJson } from "@data/helpers/serialisation/TypedJSON";
import type { KContext } from "@data/expressions/context";
import { expressionParser } from "@data/expressions/parser";
import { ParsingError } from "@data/expressions/expressions";
import type { Person } from "@data/data/Person";
import { refine } from "@data/helpers/NullHelpers";
import type { KRecord } from "@data/data/Record";
import type { KField } from "@data/fields/KField";

import { usePeopleStore } from "@/api/stores/usePeopleStore";
import { useKContext } from "@/expressions/context";

const filterValidExpressions = (expressions: string[], context: KContext) =>
  expressions.filter((e) => {
    try {
      expressionParser.parseBoolean(e, context);
      return true;
    } catch (err) {
      if (err instanceof ParsingError) {
        return false;
      }
      throw err;
    }
  });

export const getRecordOptionFilters = (filters: RelationalFilters | undefined, context: KContext, targetCollection: Collection) => {
  if (!filters) return [];
  const currentRecord = context.record;
  const parseContext = { ...context, entity: targetCollection };
  const result = filterValidExpressions(filters.expressions, parseContext);
  if (!currentRecord) return result;
  for (const { parentFieldId, foreignFieldId } of filters.relations) {
    if (!targetCollection.getField(foreignFieldId)) {
      // skip invalid fields to avoid a server error (since the foreign field could be deleted)
      console.warn(`Warning: Non-existent foreign field ${foreignFieldId} in relational filter`);
      continue;
    }
    const parentField = context.entity?.getField(parentFieldId);
    const foreignField = targetCollection.getField(foreignFieldId);
    const multiForeign = foreignField instanceof MultiRecordField;
    if (!parentField) {
      console.warn(`Non-existent parent field ${parentFieldId} in relational filter`);
      continue;
    }
    if (parentField instanceof RecordField) {
      const value = parentField.getValue(currentRecord);
      if (value) {
        if (multiForeign) {
          result.push(`record.${foreignFieldId}.id intersects [${toJson(value.id)}]`);
        } else {
          result.push(`record.${foreignFieldId}.id matches ${toJson(value.id)}`);
        }
      }
    } else if (parentField instanceof MultiRecordField) {
      const value = parentField.getValue(currentRecord);
      if (multiForeign) {
        result.push(`record.${foreignFieldId}.id intersects ${toJson(value?.map((v) => v.id) ?? [])}`);
      } else {
        const combined = value?.map((v) => `record.${foreignFieldId}.id matches ${toJson(v.id)}`).join(" or ");
        if (combined) {
          result.push(`(${combined})`);
        }
      }
    } else {
      console.warn(`Parent field ${parentFieldId} is not a record field in relational filter`);
    }
  }
  return result;
};

const getIds = (record: KRecord, field: KField) => {
  if (field instanceof MultiRecordField) {
    return field.getValue(record)?.map((v) => v.id) ?? [];
  } else if (field instanceof RecordField) {
    const value = field.getValue(record);
    return value ? [value.id] : [];
  }
  return [];
};

export const getPersonFilter = (filters: RelationalFilters | undefined, context: KContext): ((p: Person) => boolean) => {
  const parsedValidExpressions = filters?.expressions
    ? refine(filters.expressions, (e) => {
        try {
          return expressionParser.parseBoolean(e, context);
        } catch (err) {
          if (err instanceof ParsingError) {
            return undefined;
          }
          throw err;
        }
      })
    : [];
  return (p: Person) => {
    for (const expression of parsedValidExpressions) {
      if (!expression.evaluate({ ...context, person: p })) {
        return false;
      }
    }
    if (!context.record) return true;
    for (const { parentFieldId, foreignFieldId } of filters?.relations ?? []) {
      const parentField = context.entity?.getField(parentFieldId);
      if (!parentField) continue;
      if (!p.extraData) return false;
      const pIds = new Set(getIds(p.extraData, parentField));
      if (pIds.size === 0) return false;
      const foreignField = context.entity?.getField(foreignFieldId);
      if (!foreignField) continue;
      if (!getIds(context.record, foreignField).some((id) => pIds.has(id))) {
        return false;
      }
    }
    return true;
  };
};

export const useFilteredPeopleOptions = (filters: Ref<RelationalFilters | undefined>) => {
  const context = useKContext();
  const peopleStore = usePeopleStore();
  const pFilter = computed(() => getPersonFilter(filters.value, context.value));
  return computed<KRecordOption[]>(() =>
    peopleStore.peopleList
      .filter(pFilter.value)
      .map((p) => ({ id: p.id, label: p.name }))
      .sort((a, b) => a.label.localeCompare(b.label))
  );
};
