<template>
  <p v-if="!currentDisplayValue" :id class="user-selectable text-secondary">None</p>
  <div v-else :id class="user-selectable mb-3 py-1">
    <div :class="`risk-badge score-badge me-1 bg-d-10-${currentDisplayValue.score.variant}`">
      <b>{{ currentDisplayValue.score.label }}</b
      >: {{ currentDisplayValue.score.value }}
    </div>
    =
    <div :class="`d-inline-block px-1 text-${currentDisplayValue.impact?.color}`">Impact: {{ currentDisplayValue.impact?.value }}</div>
    ×
    <div :class="`d-inline-block px-1 text-${currentDisplayValue.likelihood?.color}`">Likelihood: {{ currentDisplayValue.likelihood?.value }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import type { RiskScore } from "@data/types/RiskScore";
import { riskImpactScale, riskLikelihoodScale, getRiskScore } from "@data/types/RiskScore";

const props = defineProps<{
  /** ID of the display for labelling purposes */
  id: string;
  /** Address display value to show */
  value?: RiskScore;
}>();

const currentDisplayValue = computed(() => {
  if (!props.value) return;
  const impact = riskImpactScale.find((i) => i.value === props.value?.impact);
  const likelihood = riskLikelihoodScale.find((i) => i.value === props.value?.likelihood);
  const score = getRiskScore(props.value);
  return {
    impact,
    likelihood,
    score
  };
});
</script>

<style scoped>
.risk-badge {
  border-radius: 4px;
  line-height: 20px;
  padding: 0 6px;
  min-width: 90px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 0.9em;
}

.risk-badge.score-badge {
  color: white;
}

.risk-badge.bg-d-10-yellow {
  color: black;
}
</style>
