export const storageKeys = {
  auth: {
    lastSignInMethod: "auth:lastSignInMethod"
  },
  dashboard: {
    taskGroups: "dashboard:taskGroups"
  },
  cache: {
    collections: "kb-collections",
    navigation: "kb-nav",
    defaultViews: "kb-default-views"
  }
};
