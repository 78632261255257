import type { UnwrapRef } from "vue";
import { ref, watch, type Ref } from "vue";

import type { SimpleObject } from "@data/helpers/manipulation/Copying";
import { deepEquals } from "@data/helpers/manipulation/Comparison";

export type ReadonlyRef<T> = Readonly<Ref<T>>;

/** Returns a ref that doesn't change if the value is the same as the previous value. */
export const deepDebounce = <T extends SimpleObject>(input: ReadonlyRef<T>): ReadonlyRef<UnwrapRef<T>> => {
  const result = ref<T>(input.value);

  watch(input, (value) => {
    // need to do this otherwise there's a horrible recursive type problem in vue-tsc
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (!deepEquals(result.value, value)) {
      result.value = value as UnwrapRef<T>;
    }
  });

  return result;
};
