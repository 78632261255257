import { BooleanExpression, isInvertable } from "./expressions";

import type { KContext } from "./context";
import type { Expression, InvertableExpression } from "./expressions";

export interface WithPrecedence {
  precedence: number;
}

export const hasPrecedence = (e: Expression): e is Expression & WithPrecedence => "precedence" in e;

export const bracketAndJoin = (subExpressions: Expression[], context: KContext, precedence: number, operator: string): string => {
  const bracketed = subExpressions.map((e) => {
    if (hasPrecedence(e) && e.precedence <= precedence) {
      return `(${e.display(context)})`;
    }
    return e.display(context);
  });
  return bracketed.join(` ${operator} `);
};

export class AndExpression extends BooleanExpression implements WithPrecedence {
  readonly precedence = 3;

  readonly total: number;

  constructor(private readonly subexpressions: BooleanExpression[]) {
    super();
    this.total = subexpressions.length;
  }

  evaluate(context: KContext): boolean {
    return this.subexpressions.every((e) => e.evaluate(context));
  }

  display(context: KContext): string {
    return bracketAndJoin(this.subexpressions, context, this.precedence, "and");
  }
}

export class OrExpression extends BooleanExpression implements WithPrecedence {
  readonly precedence = 3;

  constructor(private readonly subexpressions: BooleanExpression[]) {
    super();
  }

  evaluate(context: KContext): boolean {
    return this.subexpressions.some((e) => e.evaluate(context));
  }

  display(context: KContext): string {
    return bracketAndJoin(this.subexpressions, context, this.precedence, "or");
  }
}

export class XorExpression extends BooleanExpression implements WithPrecedence {
  readonly precedence = 3;

  constructor(private readonly subexpressions: BooleanExpression[]) {
    super();
  }

  evaluate(context: KContext): boolean {
    // need !! to convert undefined to false
    let result = false;
    for (const e of this.subexpressions) {
      if (e.evaluate(context)) {
        result = !result;
      }
    }
    return result;
  }

  display(context: KContext): string {
    return bracketAndJoin(this.subexpressions, context, this.precedence, "xor");
  }
}

export class NotExpression extends BooleanExpression {
  constructor(private readonly subexpression: BooleanExpression) {
    super();
  }

  evaluate(context: KContext): boolean {
    return !this.subexpression.evaluate(context);
  }

  display(context: KContext): string {
    if (isInvertable(this.subexpression)) {
      return this.subexpression.displayInverted(context);
    }
    return `not ${this.subexpression.display(context)}`;
  }
}

export class EqualsExpression<E extends Expression> extends BooleanExpression implements InvertableExpression {
  constructor(
    private readonly left: E,
    private readonly right: E
  ) {
    super();
  }

  evaluate(context: KContext): boolean {
    return this.left.evaluate(context) === this.right.evaluate(context);
  }

  display(context: KContext): string {
    return `${this.left.display(context)} = ${this.right.display(context)}`;
  }

  displayInverted(context: KContext): string {
    return `${this.left.display(context)} ≠ ${this.right.display(context)}`;
  }
}
