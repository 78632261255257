<template>
  <div class="person-badges" :class="size ? `person-badges-${size}` : ''">
    <div class="hover-section">
      <k-people-badge v-for="(person, i) in people" :key="i" :size :badge="person" />
    </div>
    <span v-if="extra > NOT_WORTH_IT" class="ms-2 mt-1 text-d-15-gray fs-5">+{{ extra }}</span>
    <button v-if="showAdd" class="add-badge" title="Add" @click="emit('add')"><k-icon icon="plus" /></button>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import KIcon from "@ui/icon/KIcon.vue";

import KPeopleBadge from "./KPeopleBadge.vue";

import type { PersonBadge } from "@data/fields/TableCell";

const props = withDefaults(
  defineProps<{
    /** Array of people to show badges for */
    people: PersonBadge[];
    /** Size of the badges */
    size?: "sm" | "md";
    /** Maximum number of badges to display */
    max?: number;
    /** Whether to show an add button at the end of the row */
    showAdd?: boolean;
  }>(),
  {
    size: "md",
    max: 10
  }
);

const emit = defineEmits<{
  /** Emitted when the add button is clicked */
  (event: "add"): void;
}>();

// showing +1 or +2 isn't worthwhile since it takes up space that could be used for another badge
const NOT_WORTH_IT = 2;

const extra = computed<number>(() => props.people.length - props.max);

const people = computed<PersonBadge[]>(() => {
  if (extra.value <= NOT_WORTH_IT) return props.people;
  return props.people.slice(0, props.max);
});
</script>

<style scoped lang="scss">
.person-badges {
  height: 32px;
  display: flex;
  :deep(.person-badge) {
    margin-left: -12px;
    transition: margin 0.15s ease-in-out;
  }
  :deep(.person-badge:first-of-type) {
    margin-left: 0;
  }
  .hover-section:hover :deep(.person-badge) {
    margin-left: 0;
  }
}

.person-badges-sm {
  margin-top: -1px;
  height: 26px;
  :deep(.person-badge) {
    margin-left: -10px;
  }
}

.add-badge {
  width: 32px;
  height: 32px;
  line-height: 30px;
  border-radius: 50%;
  background-color: transparent;
  color: var(--k-color-secondary);
  border: 2px solid color-mix(in srgb, var(--k-color-secondary) 50%, transparent);
  text-align: center;
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: large;
  justify-content: center;
  &:hover {
    border: 2px solid var(--k-color-secondary);
  }
}
</style>
