<template>
  <k-input-file v-model="input" :label :accept :max-size="maxSize" />
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from "vue";

import { FileUpload } from "./FileUpload";
import KInputFile from "./KInputFile.vue";

import type { FileMetadata } from "@data/fields/complex/FileField";

import { RecordAPI } from "@/api";
import { maxFileTransferSize } from "@/api/file";
import { useInitialState } from "@/api/initial";

const props = defineProps<{
  /** Value of the input */
  modelValue?: FileMetadata;
  /** Label for the input */
  label?: string;
  /** What file types are allowed */
  accept?: string;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: FileMetadata | undefined): void;
}>();

const input = ref<FileUpload[]>([]);
const { mutate: uploadFile } = RecordAPI.uploadPendingFile();
const { tenant } = useInitialState();
const maxSize = computed(() => {
  const tenantMaxSize = tenant.value?.fileStorage?.maxFileSize;
  return Math.min(tenantMaxSize ?? Infinity, maxFileTransferSize);
});

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      const upload = new FileUpload();
      if ("id" in value) {
        upload.status = "UPLOADED";
        upload.url = "/api/v1/files/" + value.id;
        upload.id = value.id;
      } else {
        upload.status = "ERROR";
      }
      upload.description = value.name;
      input.value = [upload];
    } else {
      input.value = [];
    }
  },
  { immediate: true }
);

watch(
  () => input.value,
  (value) => {
    // KInputFile marks files as TO_DELETE when they are removed from the input
    if (value.every((f) => f.status === "TO_DELETE")) {
      emit("update:modelValue", undefined);
    }
  },
  { deep: true }
);

watchEffect(() => {
  const file = input.value.at(0);
  if (file?.status === "NOT_UPLOADED" && file.file) {
    file.status = "UPLOADING";
    file.uploadProgress = 50;
    uploadFile({ file: file.file })
      .then((response) => {
        if (response?.data?.uploadPendingFile) {
          file.status = "UPLOADED";
          file.id = response.data.uploadPendingFile.id;
          emit("update:modelValue", {
            name: file.fileName,
            id: file.id,
            size: file.size
          });
        } else {
          file.status = "ERROR";
        }
      })
      .catch(() => {
        file.status = "ERROR";
      });
  }
});
</script>
