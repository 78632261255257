export const getDatePickerAttributes = () => [
  {
    key: "today",
    dates: new Date(),
    content: {
      style: {
        color: "var(--k-color)",
        "background-color": "var(--k-navbar-item-pressed-background)"
      }
    }
  }
];
