/**
 * Selects all text in a contenteditable element
 *
 * @param {HTMLElement} element The element to select all text in
 */
export function selectContentEditableText(element: HTMLElement) {
  const range = document.createRange(); // Create a Range object
  range.selectNodeContents(element); // Set the range to cover the contenteditable element

  const selection = window.getSelection(); // Get the current Selection object
  if (!selection) return;

  selection.removeAllRanges(); // Clear any existing selections
  selection.addRange(range); // Add the new range to the selection, selecting all text
}
