<template>
  <div v-if="issues.length">
    <div v-for="issue in filtered" :key="issue.message">
      <small :class="issue.severity === 'error' ? 'text-danger' : 'text-warning'">
        <k-icon :icon="issueIcons[issue.severity]" class="me-1" :title="capitalise(issue.severity)" />{{ issue.message }}
      </small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import KIcon from "@ui/icon/KIcon.vue";

import { useInputConfig } from "./inputConfig";

import type { ValidationIssues, Severity } from "@data/validation/Validation";
import { getMaxSeverity } from "@data/validation/Validation";
import { capitalise } from "@data/helpers/strings/Casing";

const props = defineProps<{
  /** Validation issues to show */
  issues?: ValidationIssues;
}>();

const config = useInputConfig();
const issues = computed(() => props.issues ?? config.value.issues);

const maxSeverity = computed(() => getMaxSeverity(issues.value));
const filtered = computed(() => issues.value.filter((i) => i.severity === maxSeverity.value));

const issueIcons: Record<Severity, string> = {
  error: "circle-exclamation",
  warning: "warning"
};
</script>
