<template>
  <div ref="autocompleteElement" class="form-control-container autocomplete position-relative">
    <k-label :id="uuid" :label />
    <div
      :id="uuid"
      ref="textInputRef"
      :value="allTypes.get(selectedType)?.label"
      class="form-select form-select-sm field-type-input"
      :class="{ focus: isOpen, inline: inline }"
      :aria-label="label"
      role="input"
      placeholder="Type"
      tabindex="0"
      readonly
      autocomplete="autocomplete-form-control"
      @click="openSelect"
      @focus="openSelect">
      <span v-if="allTypes.get(selectedType)">
        <i :class="`far fa-fw fa-${allTypes.get(selectedType)?.icon} me-2`"></i>{{ allTypes.get(selectedType)?.label }}
      </span>
    </div>
    <teleport to="body">
      <div ref="popOverRef" :style="popOverStyle" class="input-field-type dropdown-menu autocomplete-results pb-0 ms-n3" :class="{ show: isOpen }">
        <div class="px-1 d-flex">
          <input
            ref="searchInput"
            v-model="search"
            title="Search"
            class="form-control form-control-sm bg-transparent border-0 me-1"
            placeholder="What information do you want to store? Type to search..."
            @input="onSearchChange"
            @change="onSearchChange" />
          <k-button variant="transparent" icon="close" title="Close" class="float-end" @click="closeSelect" @blur="onBlur" />
        </div>
        <div class="d-flex field-types">
          <div ref="categoriesParentRef" class="categories px-2 py-1" data-testid="categories-list-parent">
            <div
              v-for="(category, i) in categories.entries()"
              :key="i"
              role="button"
              class="field-type-item"
              :class="{
                'category-active': selectedCategory === category[0],
                'key-focused': selectedCategory === category[0] && selectingCategories
              }"
              @click="changeSelectedCategory(category[0], true)">
              {{ category[0] === "All" && search.length ? "Search" : category[0] }}
            </div>
          </div>

          <template v-if="selectedCategory === 'All' && search.length && searchResults.length === 0">
            <div class="d-flex align-items-center justify-content-center mb-5 w-100"><p class="text-secondary fs-4">No Results</p></div>
          </template>
          <template v-else>
            <div ref="typesParentRef" class="items px-2 py-1">
              <template v-if="search.length && selectedCategory === 'All'">
                <div
                  v-for="(type, i) in searchResults"
                  :key="i"
                  role="button"
                  class="field-type-item"
                  :class="{
                    'item-active': selectedType === type,
                    'key-focused': selectedType === type && !selectingCategories,
                    'fst-italic': allTypes.get(type)?.proposed
                  }"
                  @click="setSelectedType(type, true)">
                  <i :class="`far fa-fw fa-${allTypes.get(type)?.icon} me-2 ${allTypes.get(type)?.proposed ? 'text-muted' : ''}`"></i>
                  {{ allTypes.get(type)?.label }}
                </div>
              </template>
              <template v-else>
                <div
                  v-for="(typePair, i) in selectedCategoryTypePairs"
                  :key="i"
                  role="button"
                  class="field-type-item"
                  :class="{
                    'item-active': selectedType === typePair[0],
                    'key-focused': selectedType === typePair[0] && !selectingCategories,
                    'fst-italic': typePair[1]?.proposed
                  }"
                  @click="setSelectedType(typePair[0], true)">
                  <i :class="`far fa-fw fa-${typePair[1]?.icon} me-2 ${typePair[1]?.proposed ? 'text-muted' : ''}`"></i>
                  {{ typePair[1]?.label }}
                </div>
              </template>
            </div>
            <div class="detail px-3 py-2">
              <div class="float-end">
                <i :class="`far fa-${allTypes.get(selectedType)?.icon}`"></i>
              </div>
              <h5>{{ allTypes.get(selectedType)?.label }}</h5>
              <p>{{ allTypes.get(selectedType)?.description }}</p>
              <p v-if="allTypes.get(selectedType)?.secondaryDescription">{{ allTypes.get(selectedType)?.secondaryDescription }}</p>
              <template v-if="allTypes.get(selectedType)?.examples?.length">
                <k-label label="Examples" />
                <ul class="m-0 ps-3">
                  <li v-for="(example, i) in allTypes.get(selectedType)?.examples" :key="i">{{ example }}</li>
                </ul>
              </template>
            </div>
          </template>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, onUnmounted, inject, watchEffect } from "vue";

import { computePosition, autoUpdate } from "@floating-ui/dom";
import { onClickOutside, onKeyStroke, useFocusWithin } from "@vueuse/core";
import { v4 } from "uuid";

import { fieldTypeFilterKey } from "../validFieldTypes";

import KButton from "@ui/button/KButton.vue";
import KLabel from "@ui/label/KLabel.vue";

import { withIndefiniteArticle } from "@data/helpers/strings/Articles";
import { fieldTypeCategories, fieldTypes, type FieldType } from "@data/constants/fieldTypes";
import type { FieldTypeDefinition } from "@data/constants/fieldDefinitions";

import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { proposedCollectionsInjectionKey } from "@/pages/collections/wizard/generators/AICollectionGenerationUtils";

import type { FilterType } from "../validFieldTypes";

defineProps<{
  /** Label for the input */
  label?: string;
  /** Whether the input is inline (affects width) */
  inline?: boolean;
}>();

const selectedType = defineModel<FieldType>({ required: true });

const proposedCollections = inject(proposedCollectionsInjectionKey, ref([]));

const uuid = v4();
const collectionStore = useCollectionStore();

const isOpen = ref(false);
const autocompleteElement = ref<HTMLElement>();

interface FieldTypeDefWithMetaData extends FieldTypeDefinition {
  proposed?: boolean;
}

const relatableCollections = computed(() => [...proposedCollections.value, ...(collectionStore.collections ?? [])]);
// Init map of all field types, including collections
const allTypes = computed(() => {
  const map = new Map<FieldType, FieldTypeDefWithMetaData>([...Object.entries(fieldTypes)] as [FieldType, FieldTypeDefinition][]);

  for (const collection of relatableCollections.value) {
    const baseFieldTypeDef = {
      icon: collection.icon,
      secondaryDescription: collection.description,
      proposed: proposedCollections.value.includes(collection)
    };
    map.set(`record-${collection.id}`, {
      ...baseFieldTypeDef,
      label: collection.singular,
      description: `A dropdown menu where you can select ${withIndefiniteArticle(collection.singular.toLowerCase())}.`,
      searchTerms: [collection.singular.toLowerCase(), "record"]
    });
    map.set(`multiRecord-${collection.id}`, {
      ...baseFieldTypeDef,
      label: collection.plural === collection.singular ? `${collection.plural} (multiple)` : collection.plural,
      description: `A dropdown menu where you can select multiple ${collection.plural.toLowerCase()}.`,
      searchTerms: [collection.plural.toLowerCase(), "multiple", "record"]
    });
  }
  return map;
});

const validFieldTypes = inject(fieldTypeFilterKey, undefined);

const isValidFieldType = (type: FilterType) => {
  if (!validFieldTypes) return true;
  if ("whiteList" in validFieldTypes) {
    return validFieldTypes.whiteList.has(type);
  } else {
    return !validFieldTypes.blackList.has(type);
  }
};

const validFieldCategories = computed(() => {
  if (!validFieldTypes) return fieldTypeCategories;
  const result = new Map<string, FieldType[]>();
  for (const [category, types] of fieldTypeCategories) {
    const filteredTypes = types.filter((type) => isValidFieldType(type));
    if (filteredTypes.length) {
      result.set(category, filteredTypes);
    }
  }
  return result;
});

// Init categories of field types
const categories = computed(() => {
  const map = new Map<string, FieldType[]>(validFieldCategories.value);

  if (relatableCollections.value.length) {
    const collectionKeys: FieldType[] = [];
    for (const collection of relatableCollections.value) {
      if (isValidFieldType("record")) collectionKeys.push(`record-${collection.id}`);
      if (isValidFieldType("multiRecord")) collectionKeys.push(`multiRecord-${collection.id}`);
    }
    if (collectionKeys.length) map.set("Collections", collectionKeys);
    map.set("All", [...(validFieldCategories.value.get("All") ?? []), ...collectionKeys]);
  }
  return map;
});
const categoryKeys = computed(() => [...categories.value.keys()]);

const selectedCategory = ref("All");

const selectingCategories = ref(true);
const categoriesParentRef = ref<HTMLElement>();
const typesParentRef = ref<HTMLElement>();

// Handle search bar
const search = ref("Text");
const searchResults = computed(() => {
  if (!search.value) return [];
  return [...allTypes.value.entries()]
    .filter(
      ([ft, def]) =>
        isValidFieldType(ft) &&
        (def.label.toLowerCase().includes(search.value.toLowerCase()) || def.searchTerms?.some((s) => s.toLowerCase().includes(search.value.toLowerCase())))
    )
    .map(([type]) => type);
});

// Filter field types by selected category
const selectedCategoryTypes = computed(() => categories.value.get(selectedCategory.value) ?? []);

const relevantTypes = computed(() => (search.value.length && selectedCategory.value === "All" ? searchResults.value : selectedCategoryTypes.value));

const scrollToSelectedItem = (colToScrollIsCategories: boolean) => {
  const listParentElement: HTMLElement = colToScrollIsCategories ? (categoriesParentRef.value as HTMLElement) : (typesParentRef.value as HTMLElement);
  const listItems = colToScrollIsCategories ? categoryKeys.value : relevantTypes.value;
  const selectedItem = colToScrollIsCategories ? selectedCategory.value : selectedType.value;
  if (listItems.includes(selectedItem)) {
    const keyedIndex = listItems.indexOf(selectedItem);
    void nextTick(() => {
      const keyedElement = listParentElement.children[keyedIndex];
      if (typeof keyedElement.scrollIntoView === "function") {
        keyedElement.scrollIntoView({ block: "nearest", behavior: "instant" as ScrollBehavior });
      }
    });
  }
};

const changeSelectedCategory = (category: string, updateKeyedCol: boolean, scrollTypes = true) => {
  if (updateKeyedCol) {
    selectingCategories.value = true;
  }
  selectedCategory.value = category;
  scrollToSelectedItem(true);
  if (scrollTypes) {
    scrollToSelectedItem(false);
  }
};

const onSearchChange = () => {
  changeSelectedCategory("All", false, false);
  if (search.value.length && searchResults.value.length && searchResults.value.includes(selectedType.value)) {
    selectingCategories.value = false;
  } else {
    selectingCategories.value = true;
  }
};

// Handle user selecting a new field type
const setSelectedType = (newType: FieldType, updateKeyedCol = false) => {
  if (updateKeyedCol) {
    selectingCategories.value = false;
  }
  selectedType.value = newType;
  scrollToSelectedItem(false);
};

const setSelectedCategoryByIndex = (index: number) => {
  changeSelectedCategory(categoryKeys.value[index], false);
};

const scrollCategories = (delta: -1 | 1) => {
  const selectedCategoryIndex = categoryKeys.value.indexOf(selectedCategory.value);
  const newIndex = selectedCategoryIndex + delta;
  if (newIndex < 0) {
    setSelectedCategoryByIndex(categories.value.size - 1);
  } else if (newIndex >= categories.value.size) {
    setSelectedCategoryByIndex(0);
  } else {
    setSelectedCategoryByIndex(newIndex);
  }
};

const scrollTypes = (delta: -1 | 1) => {
  const newIndex = relevantTypes.value.indexOf(selectedType.value) + delta;
  if (newIndex < 0) {
    setSelectedType(relevantTypes.value.at(-1)!);
  } else if (newIndex >= relevantTypes.value.length) {
    setSelectedType(relevantTypes.value[0]);
  } else {
    setSelectedType(relevantTypes.value[newIndex]);
  }
};

const popOverRef = ref<HTMLDivElement>();
const { focused: autocompleteElementFocused } = useFocusWithin(autocompleteElement);
const { focused: popOverElementFocused } = useFocusWithin(popOverRef);
const focused = computed(() => autocompleteElementFocused.value || popOverElementFocused.value);

// Close dropdown
const closeSelect = () => {
  isOpen.value = false;
};

onKeyStroke("ArrowUp", (e) => {
  if (!focused.value) return;
  e.preventDefault();
  if (selectingCategories.value) {
    scrollCategories(-1);
  } else {
    scrollTypes(-1);
  }
});

onKeyStroke("ArrowDown", (e) => {
  if (!focused.value) return;
  e.preventDefault();
  if (selectingCategories.value) {
    scrollCategories(1);
  } else {
    scrollTypes(1);
  }
});

onKeyStroke("ArrowLeft", (e) => {
  if (!focused.value) return;
  e.preventDefault();
  selectingCategories.value = true;
});

const arrowRight = () => {
  if (relevantTypes.value.length) {
    selectingCategories.value = false;
    if (!relevantTypes.value.includes(selectedType.value)) {
      setSelectedType(relevantTypes.value[0]);
    }
  }
};

onKeyStroke("ArrowRight", (e) => {
  if (!focused.value) return;
  e.preventDefault();
  arrowRight();
});

onKeyStroke("Enter", (e) => {
  if (!focused.value) return;
  e.stopPropagation();
  e.preventDefault();
  if (selectingCategories.value) {
    arrowRight();
  } else {
    closeSelect();
  }
});

onKeyStroke("Escape", (e) => {
  e.stopPropagation(); // may be unessessary
  e.preventDefault();
  closeSelect();
});

// Filter field types by selected category
const selectedCategoryTypePairs = computed<[FieldType, FieldTypeDefWithMetaData?][]>(() => {
  const category = categories.value.get(selectedCategory.value) ?? [];
  return category.map((type) => [type, allTypes.value.get(type)]);
});

// Open dropdown when clicked
const searchInput = ref<HTMLInputElement>();
const openSelect = async () => {
  if (isOpen.value) return;

  isOpen.value = true;
  search.value = "";
  changeSelectedCategory("All", true);

  await nextTick(() => {
    if (searchInput.value) {
      searchInput.value.focus();
    }
  });
};

const onBlur = (evt: FocusEvent) => {
  if (!(autocompleteElement.value?.contains(evt.relatedTarget as Node) || popOverRef.value?.contains(evt.relatedTarget as Node))) {
    closeSelect();
  }
};

onClickOutside(
  autocompleteElement,
  () => {
    closeSelect();
  },
  { ignore: [popOverRef] }
);

const textInputRef = ref<HTMLDivElement>();
const popOverStyle = ref<{ left?: string; top?: string; right?: string; transform?: string }>({});
const stopAutoUpdate = ref<() => void>();
watchEffect(() => {
  if (textInputRef.value && popOverRef.value && !stopAutoUpdate.value) {
    stopAutoUpdate.value = autoUpdate(textInputRef.value, popOverRef.value, () => {
      if (textInputRef.value && popOverRef.value) {
        void computePosition(textInputRef.value, popOverRef.value).then(({ x, y }) => {
          const shouldAlignRight = x + 180 + 550 > window.innerWidth;
          popOverStyle.value = {
            left: shouldAlignRight ? undefined : `${x + 180}px`,
            right: "5px",
            top: `${y}px`,
            transform: shouldAlignRight ? "none" : undefined
          };
        });
      }
    });
  }
});
onUnmounted(() => {
  stopAutoUpdate.value?.();
});
</script>

<style scoped>
.input-field-type {
  height: 300px;
  width: 550px;
  position: absolute;
  transform: translateX(-40%);
  z-index: var(--k-index-popover);
}

.field-types {
  height: 258px;
  margin-top: 5px;
}

.input-field-type .items,
.input-field-type .categories {
  flex-shrink: 0;
  overflow-y: auto;
  border-right: 1px solid var(--k-border);
}

.input-field-type .categories {
  width: 130px;
}

.input-field-type .items {
  width: 160px;
}

.input-field-type .detail {
  overflow-y: auto;
  flex-grow: 1;
}

.field-type-item {
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.01s ease-in-out all;
}

.field-type-item:hover {
  background-color: var(--k-navbar-item-hover-background);
}

.field-type-item.key-focused {
  outline: 1px solid var(--k-color);
}

.field-type-item.category-active {
  background-color: var(--k-dropdown-item-active-background);
  color: var(--k-dropdown-item-active-color);
}

.field-type-item.item-active {
  background-color: var(--k-color-d-05-primary);
  color: white;
}

.field-type-input {
  user-select: none;
  -webkit-user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 25px;
  min-width: 130px;
}

.inline.field-type-input {
  width: 130px;
}
</style>
