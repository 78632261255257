<template>
  <div :class="{ 'form-control-container': !config.noContainer }">
    <k-label v-if="!config.hideLabel" :id :label />
    <div class="d-flex form-control-container mt-n2">
      <k-input-config :placeholder="config.placeholder || label" sticky="right" no-container required v-bind="passedConfig">
        <k-input-number :id v-model="inputValue" class="flex-grow-1" :placeholder="label" />
      </k-input-config>
      <k-input-config sticky="left" v-bind="passedConfig" no-container required>
        <k-input-unit v-model="selectedUnit" :options="Array.from(unitOptions.values())" />
      </k-input-config>
    </div>
    <k-issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { v4 } from "uuid";

import KInputConfig from "../KInputConfig.vue";
import KIssueDisplay from "../KIssueDisplay.vue";
import { useInputConfig } from "../inputConfig";

import KLabel from "@ui/label/KLabel.vue";

import KInputUnit from "./KInputUnit.vue";
import KInputNumber from "./KInputNumber.vue";

import { MetricUnit } from "@data/units/units";
import type { Dimensions } from "@data/units/dimensions";
import { SIUnits, generateUnit } from "@data/units/basic/si";
import { parseUnit } from "@data/units/parseUnit";
import { sortedBy } from "@data/helpers/manipulation/Ordering";

import type { UnitInfo } from "./unitInfo";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: number;
  /** Label for the input */
  label?: string;
  /** Unit of the input */
  unit: keyof Dimensions | Dimensions | UnitInfo;
  /** Minimum value */
  min?: number;
  /** Maximum value */
  max?: number;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: number | undefined): void;
  (event: "update:unit", value: UnitInfo): void;
}>();

const id = v4();

const config = useInputConfig();

const passedConfig = computed(() => ({
  status: config.value.status,
  disabled: config.value.disabled,
  readonly: config.value.readonly
}));

const currentUnit = computed(() => {
  if (typeof props.unit === "string") {
    return SIUnits[props.unit];
  } else if (props.unit instanceof MetricUnit) {
    return props.unit;
  } else if ("expressionSymbol" in props.unit) {
    return parseUnit(props.unit.expressionSymbol);
  } else {
    return generateUnit(props.unit);
  }
});

const selectedUnit = ref<UnitInfo>(currentUnit.value);

function* iterUnits(unit: MetricUnit) {
  yield unit;
  const max = props.max && props.max > 0 ? props.max : Infinity;
  for (let superUnit = unit.superUnit; superUnit && superUnit.conversion <= max; superUnit = superUnit.superUnit) {
    yield superUnit;
  }
  let subUnit = unit;
  const min = props.min && props.min > 0 ? props.min : 0;
  while (subUnit.subUnit && min < subUnit.conversion) {
    subUnit = subUnit.subUnit;
    yield subUnit;
  }
}

//List of metric unit options
const unitOptions = computed(() => {
  const unit = currentUnit.value;
  // sort by conversion
  const units = sortedBy(iterUnits(unit), (u) => u.conversion);
  const result = new Map<string, MetricUnit>();
  for (const u of units) {
    result.set(u.symbol, u);
  }
  return result;
});

const currentMultiplier = computed(() => selectedUnit.value.conversion);

const currentOffset = computed(() => selectedUnit.value.offset);

watch(
  () => selectedUnit.value,
  (value) => {
    emit("update:unit", value as MetricUnit);
  }
);

watch(currentUnit, (value) => {
  selectedUnit.value = value;
});

const inputValue = ref<number>();

const updateInputValue = (value: number | undefined) => {
  const converted = value && (value - currentOffset.value) / currentMultiplier.value;
  // check if the value is sufficiently different to the current value due to float shenanigans
  const precision = 6;
  if (converted === undefined) {
    inputValue.value = undefined;
  } else if (inputValue.value === undefined || Math.abs(converted - inputValue.value) > Math.pow(10, -precision)) {
    // round
    const magnitude = Math.floor(Math.log10(converted));
    if (magnitude > -precision) {
      // round to significant figures
      const targetDigits = Math.max(0, precision - magnitude - 1);
      inputValue.value = parseFloat(converted.toFixed(targetDigits));
    } else {
      inputValue.value = converted;
    }
  }
};

watch(
  () => props.modelValue,
  (value) => {
    updateInputValue(value);
  },
  { immediate: true }
);

const outputValue = computed(() => inputValue.value && inputValue.value * currentMultiplier.value + currentOffset.value);

watch(outputValue, (value) => {
  emit("update:modelValue", value);
});
</script>
