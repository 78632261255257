<template>
  <div class="border rounded p-2">
    <div class="d-flex gap-2 align-items-end mt-n2">
      <k-input-config :status="nameIssues.length && showErrors ? 'INVALID' : 'NONE'" :autofocus>
        <k-input
          v-model="formValue.name"
          label="Name"
          @blur="showErrors = true"
          @keydown.enter="
            () => {
              if (!formExpanded) {
                onSubmit();
              }
            }
          " />
      </k-input-config>
      <input-field-type v-model="formValue.type" :collection="props.collection" label="Type" />
      <k-button
        variant="secondary"
        :icon="formExpanded ? 'chevron-up' : 'chevron-down'"
        :title="formExpanded ? 'Collapse field form' : 'Expand field form'"
        @click="formExpanded = !formExpanded" />
    </div>
    <k-issue-display v-if="showErrors" :issues="nameIssues" class="mb-n3" />
    <div v-if="formExpanded" class="mt-4">
      <k-input-config v-if="editedField.type === 'boolean'" description="Configure how values are shown in tables">
        <k-input-select v-model="formValue.booleanDisplay" label="Display" :options="booleanDisplayOptions" />
      </k-input-config>

      <k-input-number v-if="editedField.type === 'number'" v-model="numericDefaultValue" label="Default Value" />

      <template v-if="editedField.type === 'rating'">
        <k-input-number v-model="numericDefaultValue" label="Default Value" />
        <k-issue-display :issues="defaultValueIssues" class="mb-1" />
        <rating-range-config v-model="formValue.range" @update:valid="(v) => (configValid = v)" />
      </template>

      <template v-if="editedField.type === 'currency'">
        <k-input-currency v-model="numericDefaultValue" label="Default Value" :currency="selectedCurrency" />
        <k-input-select v-model="selectedCurrency" label="Currency" :options="currencyOptions" required />
      </template>

      <template v-if="editedField.type === 'metric'">
        <k-input-metric v-model="numericDefaultValue" label="Default Value" :unit="editedField.unit" />
        <k-input-unit
          :model-value="editedField.unit"
          label="Default Unit"
          format="name"
          :options="metricUnitOptions"
          @update:model-value="(u) => (formValue.unit = u.expressionSymbol)" />
        <k-input-boolean
          v-if="metricUnitOptions.length > 1"
          v-model="formValue.lockUnit"
          class="mb-3 mt-0"
          inline-label="Always display with the default unit" />
      </template>

      <template v-if="editedField.type === 'probability'">
        <k-input-boolean
          :model-value="typeof formValue.defaultValue === 'number'"
          label="Enable Default Value"
          @update:model-value="(val: boolean) => (formValue.defaultValue = val ? 0.5 : undefined)" />
        <k-input-probability v-if="typeof formValue.defaultValue === 'number'" v-model="formValue.defaultValue" label="Default Value" />
      </template>

      <template v-if="editedField.type.includes('date')">
        <k-input-select
          :model-value="formValue.defaultValue === 'now' ? 'now' : 'blank'"
          label="Default Value"
          :options="[
            { label: 'Blank', value: 'blank' },
            { label: 'Now', value: 'now' }
          ]"
          required
          @update:model-value="(val: string) => (formValue.defaultValue = val === 'now' ? 'now' : undefined)" />
      </template>

      <template v-if="editedField?.type === 'person'">
        <k-input-select
          :model-value="formValue.defaultValue === 'current' ? 'current' : 'blank'"
          label="Default Value"
          :options="[
            { label: 'Blank', value: 'blank' },
            { label: 'Current User', value: 'current' }
          ]"
          required
          @update:model-value="(val: string | undefined) => (formValue.defaultValue = val === 'current' ? 'current' : undefined)" />
      </template>

      <template v-if="editedField.type === 'timeSpan'">
        <k-input-config description="Decide whether users can specify an actual time the event occurred (if different from the planned time)">
          <k-input-boolean v-model="formValue.allowActual" label="Allow Actual" />
        </k-input-config>
      </template>

      <template v-if="editedField.type === 'duration'">
        <div class="d-flex mb-3 gap-2">
          <k-input-select
            v-model="formValue.precision"
            label="Precision"
            :options="[
              { label: 'Hours + Minutes', value: 'minute' },
              { label: 'Hours', value: 'hour' },
              { label: 'Days', value: 'day' }
            ]"
            required />
          <k-input-generic-select
            v-if="formValue.precision === 'minute'"
            v-model="formValue.minutePrecision"
            class="mt-0"
            label="Granularity"
            :options="[1, 5, 6, 10, 15, 30]"
            :get-id="(val) => val.toString()"
            :get-label="(val) => (val === 1 ? 'Nearest minute' : `Nearest ${val} minutes`)"
            required />
        </div>
      </template>

      <template v-if="formValue.type === 'option' || formValue.type == 'multiOption'">
        <sub-records-input
          v-model="formValue.options"
          :entity="optionEntity"
          :loading
          @change-value="
            // only emit if we haven't changed the type of the field to prevent premature errors
            if (field?.type === formValue.type) {
              changedValue();
            }
          " />
        <template v-if="editedField.type === 'option'">
          <k-input-boolean v-model="formValue.allowOther" label="Allow Other" />
          <k-input-select
            v-model="stringDefaultValue"
            label="Default Value"
            :options="formValue.options?.map((o) => ({ value: o.id, label: o.label })) ?? []" />
        </template>
        <template v-else>
          <k-input-boolean v-model="formValue.canAdd" label="Can add new options from form" />
          <k-input-multiselect
            :model-value="Array.isArray(formValue.defaultValue) ? formValue.defaultValue : []"
            label="Default Value"
            :options="formValue.options?.map((o) => ({ value: o.id, label: o.label })) ?? []"
            @update:model-value="(val) => (formValue.defaultValue = [...val])" />
        </template>
      </template>

      <k-input-config v-if="editedField.type === 'computedNumber'" description="For example: $QUANTITY / 2 + 100">
        <input-formula v-model="formValue.formula" label="Formula" :collection :field-id="editedField.id" />
      </k-input-config>

      <k-input-config v-if="editedField.type === 'computedString'" description="For example: $FIRST_NAME $LAST_NAME">
        <input-format-string v-model="formValue.template" label="Template" :collection :field-id="editedField.id" />
      </k-input-config>

      <template v-if="editedField.type === 'computedDatetime'">
        <k-input-select
          v-model="formValue.precision"
          label="Precision"
          :options="datetimePrecisionOptions.map((p) => ({ label: capitalise(toPlural(p)), value: p }))"
          required />
        <input-date-formula v-model="formValue.dateFormula" label="Formula" :field-id="editedField.id" :precision="formValue.precision" />
      </template>

      <k-input-config v-if="editedField.type.includes('computed')" description="Who is allowed to override this field">
        <input-permissions v-model="formValue.override" label="Override Permissions" />
      </k-input-config>

      <k-input-config
        v-if="editedField.type.toLowerCase().includes('record')"
        description="Whether users are allowed to create records in linked collections from within the add record form">
        <k-input-boolean v-model="recordAddDefaultValue" class="mb-2 mt-n2" label="Allow linked record creation?" inline-label="" />
      </k-input-config>

      <person-filters-config v-if="editedField.type === 'person' || editedField.type === 'people'" v-model="formValue.relationalFilters" :collection />

      <relational-filters-config
        v-if="editedField.type == 'record' || editedField.type == 'multiRecord'"
        v-model="formValue.relationalFilters"
        :collection
        :relational-field="editedField" />

      <lookup-field-config
        v-if="editedField.type === 'lookup' && collection"
        v-model="formValue.lookupOptions"
        :collection
        :proposed-collections="proposedCollections"
        @update:valid="(v) => (configValid = v)" />

      <aggregation-field-config
        v-if="editedField.type === 'aggregation' && collection"
        v-model="formValue.aggregationOptions"
        :collection
        :proposed-collections="proposedCollections"
        @update:valid="(v) => (configValid = v)" />
      <k-input-config v-if="!isForeignField(editedField)" description="A description will appear under the field input like this">
        <k-input v-model="formValue.description" label="Description" />
      </k-input-config>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <k-button v-if="!(hideCancelWhenAdding && props.field === undefined)" variant="secondary" label="Cancel" class="mx-2" @click="onCancel" />
      <k-button
        variant="primary"
        :loading
        :disabled="!valid"
        :label="props.field === undefined ? props.addButton.label : props.updateButton.label"
        :icon="props.field === undefined ? props.addButton.icon : props.updateButton.icon"
        @click="onSubmit" />
    </div>
  </div>
  <!--</k-modal>-->
</template>

<script setup lang="ts">
// + ===================================================================== +
// |                                                                       |
// |  NOTE THAT THIS COMPONENT IS USED FOR BOTH EDITING AND ADDING FIELDS  |
// |                                                    ===                |
// + ===================================================================== +

// SETUP
import { ref, computed, watch, provide } from "vue";

import { proposedCollectionsInjectionKey } from "../../wizard/generators/AICollectionGenerationUtils";

import KButton from "@ui/button/KButton.vue";
import KInput from "@ui/inputs/strings/KInput.vue";
import KInputConfig from "@ui/inputs/KInputConfig.vue";
import KInputBoolean from "@ui/inputs/boolean/KInputBoolean.vue";
import type { SelectItem } from "@ui/inputs/select/SelectItem";
import KInputSelect from "@ui/inputs/select/KInputSelect.vue";
import KInputGenericSelect from "@ui/inputs/select/KInputGenericSelect.vue";
import KIssueDisplay from "@ui/inputs/KIssueDisplay.vue";
import KInputNumber from "@ui/inputs/numeric/KInputNumber.vue";
import KInputCurrency from "@ui/inputs/numeric/KInputCurrency.vue";
import KInputMetric from "@ui/inputs/numeric/KInputMetric.vue";
import KInputProbability from "@ui/inputs/numeric/KInputProbability.vue";
import KInputMultiselect from "@ui/inputs/select/KInputMultiselect.vue";
import KInputUnit from "@ui/inputs/numeric/KInputUnit.vue";
import type { UnitInfo } from "@ui/inputs/numeric/unitInfo";
import { toUnitInfo } from "@ui/inputs/numeric/unitInfo";

import InputFieldType from "./config/InputFieldType.vue";
import SubRecordsInput from "./config/SubRecordsInput.vue";
import RelationalFiltersConfig from "./config/RelationalFiltersConfig.vue";
import LookupFieldConfig from "./config/LookupFieldConfig.vue";
import AggregationFieldConfig from "./config/AggregationFieldConfig.vue";
import RatingRangeConfig from "./config/RatingRangeConfig.vue";
import PersonFiltersConfig from "./config/PersonFiltersConfig.vue";

import { datetimePrecisionOptions } from "@data/fields/basic/DatetimeField";
import { toPlural } from "@data/helpers/strings/Plurals";
import { capitalise } from "@data/helpers/strings/Casing";
import type { Collection } from "@data/data/Collection";
import type { RegisteredField } from "@data/fields/FieldRegistry";
import type { KField } from "@data/fields/KField";
import { MultiOptionField } from "@data/fields/basic/OptionFields";
import { StageField } from "@data/fields/complex/StageField";
import type { ValidationIssues } from "@data/validation/Validation";
import { pickFrom } from "@data/helpers/manipulation/Objects";
import type { DatetimePrecision } from "@data/fields/basic/DatetimeField";
import { deepCopy } from "@data/helpers/manipulation/Copying";
import type { ExtraFieldOptions, FieldType } from "@data/constants/fieldTypes";
import { createField, optionEntity } from "@data/constants/fieldTypes";
import { getMetricFieldType } from "@data/constants/metricFieldTypes";
import { deepEquals } from "@data/helpers/manipulation/Comparison";
import { allUnits } from "@data/units/allUnits";
import { deduplicate } from "@data/helpers/manipulation/Deduplication";
import type { CurrencyType } from "@data/fields/basic/CurrencyField";
import { isCurrencyType } from "@data/fields/basic/CurrencyField";

import { isForeignField } from "@/expressions/computed";
import InputPermissions from "@/components/inputs/permissions/InputPermissions.vue";
import InputFormatString from "@/components/inputs/expressions/InputFormatString.vue";
import InputFormula from "@/components/inputs/expressions/InputFormula.vue";
import { useKContext } from "@/expressions/context";
import { isComputedField } from "@/expressions/computed";
import InputDateFormula from "@/components/inputs/expressions/InputDateFormula.vue";

import type { Formula } from "@/components/inputs/expressions/convertInput";

const props = withDefaults(
  defineProps<{
    /** The collection the field belongs to */
    collection?: Collection;
    /** List of fields (can be passed instead of a collection */
    fields?: KField[];
    /** Field to edit (undefined if creating a new field) */
    field?: KField;
    /** Show cancel button */
    hideCancelWhenAdding?: boolean;
    /** A list of GeneratedCollections to show as record field types options (before they're created as actual collections) */
    proposedCollections?: Collection[];
    /** Whether to autofocus the name field */
    autofocus?: boolean;
    /** Whether a field mutation is in progress */
    loading?: boolean;
    /** Whether to expand full options by default */
    expandByDefault?: boolean;
    /** Add button appearance */
    addButton?: { label: string; icon: string };
    /** Update button appearance */
    updateButton?: { label: string; icon: string };
  }>(),
  {
    collection: undefined,
    fields: () => [],
    hideCancelWhenAdding: false,
    field: undefined,
    proposedCollections: () => [],
    autofocus: true,
    expandByDefault: false,
    addButton: () => ({ label: "Add", icon: "plus" }),
    updateButton: () => ({ label: "Update", icon: "check" })
  }
);

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "changeValue", field: RegisteredField): void;
  (e: "submit", field: RegisteredField): void;
}>();

// provide list of any proposed collections to InputFieldType for relation fields which refer to proposed collections
provide(
  proposedCollectionsInjectionKey,
  computed(() => props.proposedCollections)
);

// provide the collection to the formula field
useKContext(
  computed(() => ({ entity: props.collection })),
  true
);
// === FORM INITIALISATION ===
interface FormRecord extends ExtraFieldOptions {
  name?: string;
  type: FieldType;
  formula?: Formula;
  template?: string;
  dateFormula?: string;
  override?: string[];
}

const datetimePrecisionToType: Record<DatetimePrecision, FieldType> = {
  year: "year",
  quarter: "month", // TODO fix when quarter field exists
  month: "month",
  day: "date",
  hour: "datetime",
  minute: "datetime"
};

// eslint-disable-next-line complexity
const initialFormValue = computed<FormRecord>(() => {
  if (props.field) {
    const regField = props.field as RegisteredField;
    const value: FormRecord = {
      name: props.field.label,
      type: regField.type as FieldType,
      description: props.field.description,
      permissions: props.field.permissions
    };
    switch (regField.type) {
      case "option":
      case "multiOption":
        value.options = regField.options.map((o) => ({ label: o.label, variant: o.variant, id: o.value }));
        value.allowOther = regField.otherLabel !== undefined;
        if (regField instanceof MultiOptionField) {
          value.defaultValue = regField.defaultOptions;
          value.canAdd = regField.canAdd || false;
        } else {
          value.defaultValue = regField.defaultOption;
        }
        break;
      case "boolean":
        value.booleanDisplay = regField.displayType;
        break;
      case "record":
      case "multiRecord":
        value.type = `${regField.type}-${regField.collectionId}`;
        value.canCreateRecord = regField.canCreateRecord;
        break;
      case "date":
      case "datetime":
        value.defaultValue = regField.default;
        value.type = datetimePrecisionToType[regField.precision];
        break;
      case "timeSpan":
        value.precision = regField.precision;
        value.allowActual = regField.allowActual;
        break;
      case "duration":
        value.precision = regField.precision;
        value.minutePrecision = regField.minutePrecision;
        break;
      case "rating":
        value.range = regField.range;
        break;
      case "currency":
        value.unit = regField.currency;
        break;
      case "metric":
        if (typeof regField.unitSetup === "string") {
          value.type = getMetricFieldType(regField.unitSetup);
        }
        value.unit = regField.unit.symbol;
        value.lockUnit = regField.lockUnit;
        break;
      case "file":
        switch (regField.accept) {
          case "image/*":
            value.type = "image";
            break;
        }
        break;
      case "computedNumber":
        value.formula = regField.expression
          ? {
              expression: regField.expression,
              unit: regField.unit?.expressionSymbol
            }
          : undefined;
        break;
      case "computedString":
        value.template = regField.expression;
        break;
      case "computedDatetime":
        value.dateFormula = regField.expression;
        value.precision = regField.precision;
        break;
      case "lookup":
        value.lookupOptions = pickFrom(regField.lookupConfig, "targetCollection", "linkField", "targetField");
        break;
      case "aggregation":
        value.aggregationOptions = pickFrom(
          regField.aggregationSetup,
          "targetCollection",
          "link",
          "filters",
          "expression",
          "unit",
          "mode",
          "timeField",
          "durationUnit"
        );
        break;
    }
    if (isComputedField(regField)) {
      value.override = value.permissions?.write ?? [];
    }
    if ("defaultValue" in regField) {
      value.defaultValue = regField.defaultValue;
    }
    if ("filters" in regField) {
      value.relationalFilters = regField.filters;
    }
    return value;
  }
  return { type: "string" };
});

const formValue = ref(initialFormValue.value);

// ==== INPUT SETUP ====

const booleanDisplayOptions: SelectItem[] = [
  { label: "As text (shown as 'Yes' or 'No')", value: "TEXT" },
  { label: "As icon (shown as ticks & crosses)", value: "ICON" },
  { label: "As icon (show ticks only)", value: "ICON_TRUE_ONLY" },
  { label: "As icon (show crosses only)", value: "ICON_FALSE_ONLY" }
];

const currencyOptions: (SelectItem & { value: CurrencyType })[] = [
  { label: "Pounds (£)", value: "sterling" },
  { label: "Dollars ($)", value: "dollar" },
  { label: "Euros (€)", value: "euro" }
];
const selectedCurrency = computed({
  get() {
    const currency = formValue.value.unit;
    return currency && isCurrencyType(currency) ? currency : "sterling";
  },
  set(val) {
    formValue.value.unit = val;
  }
});

const numericDefaultValue = computed({
  get: () => (typeof formValue.value.defaultValue === "number" ? formValue.value.defaultValue : undefined),
  set(val: number | undefined) {
    formValue.value.defaultValue = val;
  }
});

const stringDefaultValue = computed({
  get: () => (typeof formValue.value.defaultValue === "string" ? formValue.value.defaultValue : undefined),
  set(val: string | undefined) {
    formValue.value.defaultValue = val;
  }
});

// for adding records on the fly default to true
const recordAddDefaultValue = computed({
  get: () => (typeof formValue.value.canCreateRecord === "boolean" ? formValue.value.canCreateRecord : true),
  set(val: boolean | undefined) {
    formValue.value.canCreateRecord = val ?? true;
  }
});
// uncomment this once we have hour, minute & second precisions
// const precisionOptions: SelectItem[] = [simpleOption("Year"), simpleOption("Day"), simpleOption("Minute")];

const usedKeys = computed(() => {
  const schema = props.collection?.schema ?? props.fields;
  return schema.filter((f) => f.id !== props.field?.id).map((f) => f.key);
});

const editedField = computed(() => {
  const fieldObj = deepCopy(formValue.value);
  fieldObj.id = props.field?.id;
  if (props.field instanceof StageField) {
    // don't mutate stages here
    fieldObj.stages = props.field.stages;
  }
  if (fieldObj.formula) {
    fieldObj.expression = fieldObj.formula.expression;
    fieldObj.unit = fieldObj.formula.unit;
  }
  if (fieldObj.override) {
    fieldObj.permissions = { read: fieldObj.permissions?.read ?? ["true"], write: fieldObj.override };
  }
  if (fieldObj.template) {
    fieldObj.expression = fieldObj.template;
  }
  if (fieldObj.dateFormula) {
    fieldObj.expression = fieldObj.dateFormula;
  }
  return createField(fieldObj.type, fieldObj.name ?? "Unnamed Field", usedKeys.value, fieldObj) as RegisteredField;
});

const nameIssues = computed<ValidationIssues>(() => {
  const name = formValue.value.name;
  const fields = props.collection?.fields ?? props.fields;
  if (name) {
    // Check for duplicate label and make sure it's not just us editing the same thing again.
    if (fields.some((field) => field.label === name && field.id !== editedField.value.id)) {
      return [{ message: "Field name already in use", severity: "error" }];
    } else if (name.length < 3) {
      return [{ message: "Field name must be at least 3 letters long", severity: "error" }];
    } else if (editedField.value.key.length < 3) {
      return [{ message: "Field name must be at least 3 alphanumeric characters long", severity: "error" }];
    } else if (name.length > 100) {
      return [{ message: "Field name must be less than 100 letters long", severity: "error" }];
    }
    return [];
  }
  return [{ message: "Name is required", severity: "error" }];
});
//for rating field
const defaultValueIssues = computed<ValidationIssues>(() => {
  if (formValue.value.type === "rating" && formValue.value.range && numericDefaultValue.value) {
    const value = numericDefaultValue.value;
    const setup = formValue.value.range;
    if (value < setup.min || value > setup.max) {
      return [{ severity: "error", message: `Default value must be between ${setup.min} and ${setup.max}` }];
    }
    const step = setup.step ?? 1;
    if (step === 1 && value % 1 !== 0) {
      return [{ severity: "error", message: "Default value must be a whole number" }];
    } else if ((setup.max - value) % step !== 0) {
      return [{ severity: "error", message: `Invalid default - value must increment in steps of ${setup.step} from ${setup.min}` }];
    }
  }
  return [];
});

const metricUnitOptions = computed<UnitInfo[]>(() => {
  if (editedField.value.type === "metric") {
    const unit = editedField.value.unit;
    const relevantUnits = deepEquals(unit.dimensions, {})
      ? unit.unitFamily
      : deduplicate(
          Object.values(allUnits)
            .filter((u) => deepEquals(u.dimensions, unit.dimensions))
            .flatMap((u) => u.unitFamily),
          (u) => u.expressionSymbol
        );
    return relevantUnits.map(toUnitInfo);
  }
  return [];
});
// ===== WATCHERS =====
watch(initialFormValue, (newInitialValue) => (formValue.value = newInitialValue));

const typesWhichTriggerFormExpansion = new Set(["option", "multiOption"]);
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const formExpanded = ref(props.expandByDefault || typesWhichTriggerFormExpansion.has(initialFormValue.value.type));

watch(
  () => formValue.value.type,
  (type) => {
    formExpanded.value = typesWhichTriggerFormExpansion.has(type) || props.expandByDefault;
    formValue.value.unit = undefined;
  }
);

// ===== CALLBACKS AND THEIR REFS =====
// only check config validation for certain field types - otherwise could be softlocked after changing field type
const fieldTypesWithConfigValidation = new Set(["aggregation"]);
const configValid = ref(true);
const valid = computed(
  () => !nameIssues.value.length && (!fieldTypesWithConfigValidation.has(formValue.value.type) || configValid.value) && !defaultValueIssues.value.length
);
const showErrors = ref(false);
function onSubmit() {
  if (valid.value) {
    emit("submit", editedField.value);
  } else {
    showErrors.value = true;
  }
}

function changedValue() {
  if (valid.value) {
    emit("changeValue", editedField.value);
  } else {
    showErrors.value = true;
  }
}

const onCancel = () => emit("cancel");
</script>
