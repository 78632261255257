import type { KFieldValue } from "./Record";
import type { StaticFieldValue } from "../fields/KField";
import type { ColourVariant } from "../types/ColourVariant";
import type { Datelike } from "../types/Dates";
import type { Dayjs } from "dayjs";

export type ActivityDisplayType = "system" | "default" | "expandable";

export type Activity = {
  id: string;
  timestamp: Dayjs;
  content: string;
  type: string;
  collectionId?: string;
  recordId?: string;
  recordLabel?: string;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: Datelike;
  updatedAt?: Datelike;
  relations?: { collectionId: string; recordId: string }[];
  // note: vue-tsc doesn't like JsonSerialisable here
  properties?: (Omit<StaticFieldValue, "rawValue"> & { rawValue: unknown })[];
  changes?: { fieldId: string; fieldLabel: string; fieldType: string; oldValue: KFieldValue; newValue: KFieldValue }[];
  email?: EmailActivity;
  __typename?: "Activity";
};

export type FormattedActivity = Activity & {
  parts: ActivityContentPart[];
  display: ActivityDisplayType;
  typeLabel: string;
  icon?: string;
  colour?: ColourVariant;
  parentLabel?: string;
  parentLink?: string;
  createdByName?: string;
};

export type EmailActivity = {
  subject?: string;
  textBody?: string;
  source?: "OUTLOOK_INBOX" | "SMTP_INBOX";
  externalServiceId?: string;
  sentAt?: Dayjs;
  notes?: string;
  jsonLd?: string;
  recipients: EmailRecipient[];
};

export type EmailRecipient = {
  displayName?: string;
  email: string;
  collectionId?: string;
  recordId?: string;
  type: "TO" | "CC" | "FROM";
};

export type ActivityContentPart = { label: string; to: string } | { label: string; href: string } | string;

export type ActivityType = {
  label: string;
  key: string;
  icon: string;
  colour?: ColourVariant;
  description?: string;
  display: "default" | "expandable" | "system";
  isEnabled?: boolean;
  isEditable: boolean;
  isBuiltIn?: boolean;
  collectionId?: string;
};

export const ActivityTypes = new Map<string, ActivityType>([
  [
    "note",
    {
      label: "Note",
      key: "note",
      icon: "note",
      display: "expandable",
      isEditable: true,
      isBuiltIn: true,
      isEnabled: true
    }
  ],
  [
    "email",
    {
      label: "Email",
      key: "email",
      icon: "envelope",
      display: "expandable",
      isEditable: true,
      isBuiltIn: true,
      isEnabled: true
    }
  ],
  [
    "phone",
    {
      label: "Phone Call",
      key: "phone",
      icon: "phone",
      display: "default",
      isEditable: true,
      isBuiltIn: true,
      isEnabled: true
    }
  ],
  [
    "meeting",
    {
      label: "Meeting",
      key: "meeting",
      icon: "comments",
      display: "expandable",
      isEditable: true,
      isBuiltIn: true,
      isEnabled: true
    }
  ],
  [
    "system",
    {
      label: "System",
      key: "system",
      icon: "cog",
      display: "system",
      isEditable: false,
      isBuiltIn: true,
      isEnabled: true,
      colour: "gray"
    }
  ]
]);
