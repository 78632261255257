import { createGlobalState, useStorage } from "@vueuse/core";

import { AlertHandler } from "@ui/alerts/alert-handler";

import { apolloClient } from "./apollo";

const useVersion = createGlobalState(() => useStorage<string | undefined>("kb-client-version", undefined));

/**
 * Update the local storage state with new version details, used to prompt the user to refresh the page if necessary
 *
 * @param version Git commit hash
 */
export const setVersion = (version?: string) => {
  const versionState = useVersion();
  versionState.value = version;
};

export const loadNewVersion = async () => {
  // Clear store in case of stale data and previous user's info left behind
  await apolloClient.resetStore();

  window.location.reload();
};

export const checkForNewVersion = async () => {
  const versionState = useVersion();
  const version = versionState.value;

  const response = await fetch("/api/v1/version");
  if (!response.ok) return;

  const latestVersion = (await response.json()) as { revision?: string };

  if (version !== latestVersion.revision && version !== undefined && latestVersion.revision) {
    const alert = new AlertHandler();
    alert.addAlert({
      title: "A new Kinabase update is available",
      content: "Please refresh the page.",
      variant: "primary",
      icon: "sparkles",
      duration: "never",
      position: "top",
      actions: [
        {
          label: "Refresh",
          onClick: async () => await loadNewVersion()
        }
      ]
    });
  }
};

export const getVersion = () => {
  const versionState = useVersion();
  void checkForNewVersion();
  return versionState.value;
};
