import { computed, inject, provide, ref } from "vue";
import type { InjectionKey } from "vue";

import type { ReadonlyRef } from "@ui/helpers/refHelpers";

import type { KContext } from "@data/expressions/context";
import type { Person } from "@data/data/Person";

import { useAuth } from "@/services/auth";

import type { provideInitialState } from "@/api/initial";

export const contextKey = Symbol() as InjectionKey<ReadonlyRef<KContext>>;

export const useKContext = (toAdd?: ReadonlyRef<KContext>, reprovide = false): ReadonlyRef<KContext> => {
  const injectedContext = inject(contextKey, undefined);
  const currentContext = computed(() => {
    if (injectedContext) {
      return { ...injectedContext.value, ...toAdd?.value };
    }
    return toAdd?.value ?? {};
  });
  if (reprovide) {
    provide(contextKey, currentContext);
  }
  return currentContext;
};

/** Add the current person and role labels to the context - should be called at a high level in the component tree to reduce repetition */
export const provideKBInfo = (state: ReturnType<typeof provideInitialState>): ReadonlyRef<KContext> => {
  const { user } = useAuth();
  const currentPerson = computed(() => {
    let result: Person | undefined;
    if (state.people.value) {
      result = state.people.value.find((p) => p.userId === user.value?.id);
      if (!result) {
        console.warn("No person found for current user!");
      }
    }
    return result;
  });

  const roleLabels = computed(() => {
    const roles = state.roles.value;
    if (!roles) return undefined;
    return new Map(roles.map((r) => [r.id, r.name]));
  });

  const toAdd = computed<KContext>(() => ({
    person: currentPerson.value,
    people: state.people.value,
    roleLabels: roleLabels.value,
    personEntity: state.personEntity.value
  }));

  const newContext = useKContext(toAdd, true);

  return newContext;
};

// provide context to be used in unit tests
export const provideTestKContext = (context: KContext) => ({ [contextKey as symbol]: ref(context) });
