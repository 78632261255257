import { computed } from "vue";
import type { Ref } from "vue";

import { useMutation, useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";

import { fullCollection } from "./fragments";

import type { ServerCollection, UploadServerField } from "@data/data/Collection";

export const useFieldAdding = () =>
  useMutation<{ addCollectionField: { key: string; id: string } }, { value: { collectionId: string; field: UploadServerField } }>(
    gql(`mutation addCollectionField($value: AddCollectionFieldInput!) {
      addCollectionField(fieldInput: $value) { key id }
    }`)
  );

export const useFieldUpdating = () =>
  useMutation<
    {
      updateCollectionField: ServerCollection;
    },
    { value: { collectionId: string; newField: UploadServerField; oldKey: string } }
  >(
    gql(`mutation updateCollectionField($value: UpdateCollectionFieldInput!) {
      updateCollectionField(input: $value) { 
        ${fullCollection}
      }
    }`)
  );

export const useFieldReordering = () =>
  useMutation<{ reorderCollectionFields: { id: string }[] }, { value: { collectionId: string; newFieldIdOrder: string[] } }>(
    gql(`mutation updateCollectionFieldOrder($value: ReorderCollectionFieldsInput!) {
      reorderCollectionFields(input: $value) { id }
    }`)
  );

export const useFieldDeleting = () =>
  useMutation<{ deleteCollectionField: boolean }, { value: { collectionId: string; key: string } }>(
    gql(`mutation deleteCollectionField($value: DeleteCollectionFieldInput!) {
        deleteCollectionField(input: $value)
      }`)
  );

export const useUpdatePrimaryField = () =>
  useMutation<{ updateCollectionPrimaryField: boolean }, { collectionId: string; fieldId: string }>(
    gql(`mutation updateCollectionPrimaryField($collectionId: String!, $fieldId: String!) {
        updateCollectionPrimaryField(collectionId: $collectionId, fieldId: $fieldId)
      }`)
  );

export const useUpdateSecondaryField = () =>
  useMutation<{ updateCollectionSecondaryField: boolean }, { collectionId: string; fieldId: string | undefined }>(
    gql(`mutation updateCollectionSecondaryField($collectionId: String!, $fieldId: String) {
        updateCollectionSecondaryField(collectionId: $collectionId, fieldId: $fieldId)
      }`)
  );

export const useUpdateSortField = () =>
  useMutation<{ updateCollectionSortField: boolean }, { collectionId: string; fieldId: string }>(
    gql(`mutation updateCollectionSortField($collectionId: String!, $fieldId: String!) {
        updateCollectionSortField(collectionId: $collectionId, fieldId: $fieldId)
      }`)
  );

export const useUpdateSortDirection = () =>
  useMutation<{ updateCollectionSortDirection: boolean }, { collectionId: string; direction: "ASC" | "DESC" }>(
    gql(`mutation updateCollectionSortDirection($collectionId: String!, $direction: SortDirection!) {
        updateCollectionSortDirection(collectionId: $collectionId, direction: $direction)
      }`)
  );

export const useFieldRecordCounting = (variables: Ref<{ id: string; key: string }>) =>
  useQuery<{ recordCountWithField: number }, { id: string; key: string }>(
    gql`
      query getRecordCount($id: String!, $key: String!) {
        recordCountWithField(collectionId: $id, fieldKey: $key)
      }
    `,
    variables,
    computed(() => ({ enabled: !!variables.value.key }))
  );

export interface AddOptionToFieldInput {
  collectionId: string;
  targetFieldId: string;
  optionLabel: string;
}

export const useAddOptionToField = () =>
  useMutation<{ addOptionToField: ServerCollection }, { input: AddOptionToFieldInput }>(gql`
    mutation addOptionToField($input: AddOptionToFieldInput!) {
      addOptionToField(input: $input) {
        ${fullCollection}
      }
    }
  `);

export const FieldAPI = {
  useFieldAdding,
  useFieldUpdating,
  useFieldReordering,
  useFieldDeleting,
  useFieldRecordCounting,
  useAddOptionToField
};
