import { RiskScore, riskScoreScale, getRiskScore } from "../../types/RiskScore";
import { KField } from "../KField";
import { NumberKeyField } from "../sub/SubField";

import type { ColourVariant } from "../../types/ColourVariant";
import type { ExpressionField } from "../../expressions/accessors";
import type { KRecord, StoredKRecord } from "../../data/Record";
import type { RiskObject } from "../../types/RiskScore";
import type { Sortable } from "../KField";
import type { BadgeCell, TableCell } from "../TableCell";
import type { Option } from "../basic/OptionFields";

const csvSubheaders = {
  likelihood: "Likelihood",
  impact: "Impact",
  score: "Score"
} satisfies Partial<Record<keyof RiskScore, string>>;

const riskBadges: ({ score: number } & BadgeCell)[] = riskScoreScale.map((score) => ({
  type: "badge",
  score: score.value,
  text: score.label,
  colour: score.color
}));

export class RiskField extends KField<RiskScore> implements ExpressionField<"number"> {
  readonly type = "risk";

  readonly expressionType = "number";

  constructor(...args: ConstructorParameters<typeof KField<RiskScore>>) {
    super(...args);

    this.subfields = [new NumberKeyField("likelihood", () => this, "Likelihood"), new NumberKeyField("impact", () => this, "Impact")];
  }

  getValue(record: KRecord) {
    return record[this.key] as RiskScore | undefined;
  }

  getSortableValue(record: KRecord): Sortable {
    const value = this.getValue(record);
    if (!value) return undefined;
    return value.score;
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    if (!value || !value.score) return "";
    return getRiskScore(value).label;
  }

  getExpressionValue(r: KRecord): number | undefined {
    const value = this.getValue(r);
    return value?.score;
  }

  getNumericalValue(record: KRecord): number | undefined {
    return this.getExpressionValue(record);
  }

  getColourVariant(displayValue: string): ColourVariant | undefined {
    return riskScoreScale.find((score) => score.label === displayValue)?.color;
  }

  getOptionValue(record: KRecord): Option | undefined {
    const value = this.getValue(record);
    if (!value || !value.score) return;
    const score = getRiskScore(value);
    return {
      label: score.label,
      variant: score.variant,
      value: score.value.toString()
    };
  }

  getCSVSubheaders(): string[] {
    return Object.values(csvSubheaders);
  }

  override getCSVColumns(): { subheader: string }[];

  override getCSVColumns(record: KRecord): { subheader: string; value: string }[];

  override getCSVColumns(record?: KRecord | undefined) {
    if (!record) return this.getCSVSubheaders().map((subheader) => ({ subheader }));
    const value = this.getValue(record);
    return Object.entries(csvSubheaders).map(([key, subheader]) => ({
      subheader,
      value: value?.[key as keyof RiskScore]?.toString() ?? ""
    }));
  }

  getTableCell(record: KRecord): TableCell | undefined {
    const value = this.getValue(record);
    if (!value) return;
    for (const badge of riskBadges) {
      if (badge.score <= value.score) return badge;
    }
  }

  validateType(value: unknown): value is RiskScore {
    return value instanceof RiskScore;
  }

  storeValue(source: KRecord, target: StoredKRecord): void {
    const value = this.getValue(source);
    if (value) {
      target[this.key] = value.toJSON();
    }
  }

  restoreValue(source: StoredKRecord, target: KRecord): void {
    const value = source[this.key] as RiskObject | undefined;
    target[this.key] = value ? new RiskScore(value) : undefined;
  }

  getDefaultColumnWidth() {
    return 80;
  }
}
