import { generateUnit } from "../../units/basic/si";
import { parseUnit } from "../../units/parseUnit";
import { getMetricFieldType, metricFieldTypes } from "../../constants/metricFieldTypes";

import { SimpleField } from "./SimpleFields";

import type { Dimensions } from "../../units/dimensions";
import type { MetricUnit } from "../../units/units";
import type { ExpressionField } from "../../expressions/accessors";
import type { KRecord } from "../../data/Record";
import type { KFieldSetup, Sortable } from "../KField";
import type { TableCell } from "../TableCell";

interface MetricFieldSetup extends KFieldSetup {
  min?: number;
  max?: number;
  defaultValue?: number;
  lockUnit?: boolean;
}

export class MetricField extends SimpleField<number> implements ExpressionField<"number"> {
  readonly type = "metric";

  unit: MetricUnit;

  get dimensions() {
    return this.unit.dimensions;
  }

  min?: number;

  max?: number;

  defaultValue?: number;

  lockUnit = false;

  readonly expressionType = "number";

  constructor(
    setup: string | MetricFieldSetup,
    public readonly unitSetup: string | Dimensions
  ) {
    super(setup);
    if (typeof setup !== "string") {
      this.min = setup.min;
      this.max = setup.max;
      this.defaultValue = setup.defaultValue;
      this.lockUnit = setup.lockUnit ?? false;
    }
    if (typeof unitSetup === "string") {
      this.unit = parseUnit(unitSetup);
    } else {
      this.unit = generateUnit(unitSetup);
    }
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    return value === undefined ? "" : this.unit.display(value, { convert: true, lockUnit: this.lockUnit });
  }

  getSortableValue(record: KRecord): Sortable {
    return this.getValue(record);
  }

  getExpressionValue(r: KRecord): number | undefined {
    return this.getValue(r);
  }

  getDefaultValue(): number | undefined {
    return this.defaultValue;
  }

  getTableCell(record: KRecord): TableCell | undefined {
    const display = this.getDisplayValue(record);
    return display ? { type: "text", text: display, align: "right" } : undefined;
  }

  getFieldTypeName(): string {
    const fieldType = getMetricFieldType(typeof this.unitSetup === "string" ? this.unitSetup : this.unit.expressionSymbol);
    return metricFieldTypes[fieldType].label;
  }

  validateType(value: unknown): value is number {
    return typeof value === "number";
  }

  override getCSVColumns(): { subheader: string }[];

  override getCSVColumns(record: KRecord): { subheader: string; value: string }[];

  override getCSVColumns(record?: KRecord | undefined): { subheader: string; value: string }[] | { subheader: string }[] {
    if (!record) {
      return [{ subheader: this.unit.symbol }];
    }
    const value = this.getValue(record);
    return [
      {
        subheader: this.unit.symbol,
        value: value ? this.unit.convertTo(value).toString() : ""
      }
    ];
  }
}
