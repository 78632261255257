export const deduplicate = <T>(arr: T[], getKey: (item: T) => string | number): T[] => {
  const used = new Set<string | number>();
  return arr.filter((item) => {
    const key = getKey(item);
    if (used.has(key)) {
      return false;
    }
    used.add(key);
    return true;
  });
};
