import { deepEquals } from "../helpers/manipulation/Comparison";
import { deepCopy } from "../helpers/manipulation/Copying";

import type { SimpleObject, ISimpleObject } from "../helpers/manipulation/Copying";

/** Expression equivalent of an array, has extra counts and unique properties for faster evaluation with repeated values */
export class BulkData<T extends SimpleObject> implements ISimpleObject {
  private _counts?: Map<T, number>;

  private _unique?: Set<T>;

  constructor(public readonly ordered: T[]) {}

  get counts(): Map<T, number> {
    if (!this._counts) {
      this._counts = new Map();
      for (const item of this.ordered) {
        this._counts.set(item, (this._counts.get(item) ?? 0) + 1);
      }
    }
    return this._counts;
  }

  get unique(): Set<T> {
    this._unique ??= this._counts ? new Set(this._counts.keys()) : new Set(this.ordered);
    return this._unique;
  }

  deepEquals(other: SimpleObject): boolean {
    if (!(other instanceof BulkData)) return false;
    return deepEquals(this.ordered, other.ordered);
  }

  deepCopy(): ISimpleObject {
    return new BulkData(deepCopy(this.ordered));
  }
}
