type Primitive = string | number | boolean | undefined | null;
/** A map allowing safe use of objects as keys using a key-generating function */
export class KeyedMap<K, V> {
  private map = new Map<Primitive, V>();

  /** Reverse map to recover an object from it's key */
  private reverse = new Map<Primitive, K>();

  constructor(
    private keyFunction: (k: K) => Primitive,
    items?: [K, V][]
  ) {
    if (items) {
      for (const [k, v] of items) this.set(k, v);
    }
  }

  set(k: K, v: V) {
    const key = this.keyFunction(k);
    this.map.set(key, v);
    this.reverse.set(key, k);
  }

  get(k: K) {
    return this.map.get(this.keyFunction(k));
  }

  has(k: K) {
    return this.map.has(this.keyFunction(k));
  }

  remove(k: K) {
    this.map.delete(this.keyFunction(k));
    this.reverse.delete(this.keyFunction(k));
  }

  get size() {
    return this.map.size;
  }

  *entries() {
    for (const [key, value] of this.map.entries()) {
      yield [this.reverse.get(key), value] as [K, V];
    }
  }

  *keys() {
    for (const key of this.map.keys()) {
      yield this.reverse.get(key) as K;
    }
  }

  values() {
    return this.map.values();
  }

  [Symbol.iterator]() {
    return this.entries();
  }

  clear() {
    this.map.clear();
    this.reverse.clear();
  }
}
