<template>
  <k-input-autocomplete v-model="input" :label :items="fullOptions" :loading="!options.length" property="label" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import KInputAutocomplete from "@ui/inputs/autocomplete/KInputAutocomplete.vue";

import { useFilteredPeopleOptions } from "./generateFilter";

import type { KRecordOption, RelationalFilters } from "@data/fields/relational/RecordFields";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: KRecordOption;
  /** Label for the input */
  label?: string;
  /** Filters to use */
  filters?: RelationalFilters;
}>();
const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", newValue: KRecordOption | undefined): void;
}>();

const options = useFilteredPeopleOptions(computed(() => props.filters));

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const input = ref(props.modelValue);
// tracks the last input so that always it appears in the options
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const previousValue = ref(props.modelValue);

watch(input, (newRecord) => emit("update:modelValue", newRecord));

watch(
  () => props.modelValue,
  (newValue) => {
    if (input.value !== newValue) {
      input.value = newValue;
      previousValue.value = newValue;
    }
  }
);

// include previous value so that input isn't blank with deleted value
const fullOptions = computed(() => {
  const result = [...options.value];
  if (previousValue.value && !options.value.some((o) => o.id === previousValue.value?.id)) {
    result.push(previousValue.value);
  }
  result.sort((a, b) => a.label.localeCompare(b.label));
  return result;
});
</script>
