<template>
  <div class="pt-5 text-center">
    <div class="h1 mb-3 mt-2 text-secondary"><i class="fa fa-wand-magic-sparkles"></i></div>
    <p class="mb-4">No collections added yet</p>
    <p class="mx-auto text-secondary placeholder-content-size">
      <small
        >Add collections to organise and track what's important to you.<br />
        Then, add fields to store the specific pieces of information you need to.<br /><br />
        These collections will not be saved until you press "Next".
      </small>
    </p>
    <k-button icon="plus" variant="primary" label="Add collection" class="mt-4" :disabled @click="emit('add')" />
    <small class="d-block bordered mt-5 rounded text-secondary">Tip: After adding a collection, press "Suggest fields" for some AI-powered ideas</small>
  </div>
</template>

<script setup lang="ts">
import KButton from "@ui/button/KButton.vue";

defineProps<{
  /** Whether to enable buttons */
  disabled: boolean;
}>();

const emit = defineEmits<{
  (event: "add"): void;
}>();
</script>

<style scoped>
.placeholder-content-size {
  max-width: 420px;
}
</style>
