import { gql } from "@apollo/client/core";
import { useMutation, useSubscription } from "@vue/apollo-composable";

import type { Role } from "@data/data/Role";

export type ImportResult = {
  totalRows: number;
  importedRows: number;
  skippedRows: number;
  errorRows: number[];
  errors: { fieldId?: string; message: string; row: number }[];
};

export type ImportProgress = {
  totalCount: number;
  processedCount: number;
  errorCount: number;
};

const importCSVRecords = () =>
  useMutation<
    {
      importCsvRecords: ImportResult;
    },
    {
      input: { collectionId: string; headerMap: { header: string; fieldId: string; subheader: string }[]; file: File; skipDuplicates: boolean };
      requestId: string;
    }
  >(
    gql`
      mutation importRecordCSV($input: ImportRecordCsvInput!, $requestId: String!) {
        importCsvRecords(input: $input, requestId: $requestId) {
          totalRows
          importedRows
          skippedRows
          errorRows
          errors {
            fieldId
            message
            row
          }
        }
      }
    `,
    () => ({
      refetchQueries: () => ["getRecords", "getRecordCount", "getFilteredRecordCount"]
    })
  );

const updateCSVRecords = () =>
  useMutation<
    {
      updateCsvRecords: ImportResult;
    },
    {
      input: {
        collectionId: string;
        headerMap: { header: string; fieldId: string; subheader: string }[];
        file: File;
        matchFieldId: string;
        matchFieldHeader: string;
      };
      requestId: string;
    }
  >(
    gql`
      mutation updateRecordCSV($input: UpdateRecordCsvInput!, $requestId: String!) {
        updateCsvRecords(input: $input, requestId: $requestId) {
          totalRows
          importedRows
          skippedRows
          errorRows
          errors {
            fieldId
            message
            row
          }
        }
      }
    `,
    () => ({
      refetchQueries: () => ["getRecords", "getFilteredRecordCount"]
    })
  );

const onImportProgressUpdate = (requestId: string) =>
  useSubscription<{ importProgress: ImportProgress }>(
    gql`
    subscription onImportProgress {
      importProgress(requestId: "${requestId}") {
        totalCount
        processedCount
        errorCount
      }
    }
  `
  );

const cancelImportCsvRecords = () =>
  useMutation<{ importCsvRecords: ImportResult }, { requestId: string }>(gql`
    mutation importCsvRecordsCancel($requestId: String!) {
      importCsvRecordsCancel(requestId: $requestId)
    }
  `);

export type CollectionImportResult = { errorMessages: string[]; importedCollections?: number; totalTemplates: number };

const importRolesFromTemplate = () =>
  useMutation<{ rolesFromTemplate: Role[] }, { input: { files: File[] } }>(gql`
    mutation rolesFromTemplate($input: ImportCollectionInput!) {
      rolesFromTemplate(input: $input) {
        id
        name
      }
    }
  `);

const importCollectionTemplate = () =>
  useMutation<
    { importCollectionTemplate: CollectionImportResult },
    { input: { roleMap: { templateRoleName: string; assignedRole: string; assignedRoleId?: string }[]; files: File[] }; requestId: string }
  >(gql`
    mutation importCollection($input: ImportCollectionInput!, $requestId: String!) {
      importCollectionTemplate(input: $input, requestId: $requestId) {
        errorMessages
        importedCollections
        totalTemplates
      }
    }
  `);

const cancelImportCollectionTemplate = () =>
  useMutation<{ importCollection: CollectionImportResult }, { requestId: string }>(gql`
    mutation importCollectionTemplateCancel($requestId: String!) {
      importCollectionTemplateCancel(requestId: $requestId)
    }
  `);

export const ImportAPI = {
  importCSVRecords,
  updateCSVRecords,
  onImportProgressUpdate,
  cancelImportCsvRecords,
  importRolesFromTemplate,
  importCollectionTemplate,
  cancelImportCollectionTemplate
};
