import { formatReasonableNumber } from "../../helpers/strings/Precision";
import { parseUnit } from "../../units/parseUnit";
import { expressionParser } from "../../expressions/parser";

import { SimpleComputedField } from "./ComputedField";

import type { KContext } from "../../expressions/context";
import type { KRecord } from "../../data/Record";
import type { MetricUnit } from "../../units/units";
import type { KFieldSetup, Sortable } from "../KField";
import type { TableCell } from "../TableCell";
import type { Expression } from "../../expressions/expressions";

type ComputedNumberSetup = KFieldSetup & { unit?: string };
export class ComputedNumberField extends SimpleComputedField<"number"> {
  readonly type = "computedNumber";

  readonly unit?: MetricUnit;

  readonly expressionType = "number";

  constructor(setup: string | ComputedNumberSetup, expression: string | undefined) {
    super(setup, expression);
    if (typeof setup !== "string") {
      this.unit = setup.unit ? parseUnit(setup.unit) : undefined;
    }
  }

  getSortableValue(record: KRecord): Sortable {
    return this.getValue(record)?.lastValue;
  }

  getDisplayValue(record: KRecord): string {
    const lastValue = this.getValue(record)?.lastValue;
    if (this.unit && lastValue != null) {
      return this.unit.display(lastValue, { convert: true });
    }
    return formatReasonableNumber(lastValue);
  }

  getParsedExpression(context: KContext): Expression | undefined {
    return this.expression ? expressionParser.parseNumber(this.expression, context) : undefined;
  }

  getTableCell(record: KRecord): TableCell | undefined {
    const display = this.getDisplayValue(record);
    return display ? { type: "text", text: display, align: "right" } : undefined;
  }
}
