import type { Ref } from "vue";
import { computed } from "vue";

import { useQuery, useMutation, useSubscription } from "@vue/apollo-composable";
import gql from "graphql-tag";

import type { ServerNotification } from "@data/data/Notification";

const useNotifications = (enable: Ref<boolean>) =>
  useQuery<{ notifications: ServerNotification[] }>(
    gql`
      query notifications {
        notifications {
          id
          createdAt
          updatedAt
          createdBy
          updatedBy
          title
          content
          priority
          isRead
          metadata
        }
      }
    `,
    {},
    computed(() => ({
      enabled: enable.value,
      fetchPolicy: "network-only"
    }))
  );

const useMarkNotificationRead = () =>
  useMutation<{ markNotificationRead: boolean }, { ids: string[] }>(gql`
    mutation markNotificationRead($ids: [String!]!) {
      markNotificationRead(ids: $ids)
    }
  `);

const useDeleteNotifications = () =>
  useMutation<{ deleteNotifications: boolean }, { ids: string[] }>(gql`
    mutation deleteNotifications($ids: [String!]!) {
      deleteNotifications(ids: $ids)
    }
  `);

type NotificationUpdateEvent = {
  eventType: "NOTIFICATION_ADDED" | "NOTIFICATION_READ" | "NOTIFICATION_DELETED";
  notificationIds: string[];
};

const onNotificationUpdated = () =>
  useSubscription<{ notificationUpdated: NotificationUpdateEvent }>(gql`
    subscription notificationUpdate {
      notificationUpdated {
        eventType
        notificationIds
      }
    }
  `);

export const NotificationAPI = {
  useNotifications,
  useMarkNotificationRead,
  useDeleteNotifications,
  onNotificationUpdated
};
