<template>
  <div>
    <h5>Features</h5>
    <template v-for="(info, feature) in features" :key="feature">
      <div class="d-flex mt-4">
        <div class="me-4"><k-icon class="icon" :icon="info.icon" /></div>
        <div class="flex-grow-1">
          <h6 class="mb-2">{{ capitalise(feature) }}</h6>
          <p class="mb-0">{{ info.description }}</p>
          <p v-if="info.disabled?.value && info.disabledMessage" class="gray-message mt-1">{{ info.disabledMessage }}</p>
        </div>
        <div class="d-flex">
          <k-input-config :disabled="info.disabled?.value" :hide-label="true">
            <k-input-boolean
              :model-value="enabled[feature]"
              class="ms-5"
              :title="`Enable ${feature} for this collection`"
              @update:model-value="$emit('toggleFeature', feature)" />
          </k-input-config>
          <div class="px-2 me-3">{{ enabled[feature] ? "On" : "Off" }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import { computed } from "vue";

import KInputConfig from "@ui/inputs/KInputConfig.vue";
import KInputBoolean from "@ui/inputs/boolean/KInputBoolean.vue";
import KIcon from "@ui/icon/KIcon.vue";

import { capitalise } from "@data/helpers/strings/Casing";

import { TenantAPI } from "@/api/tenant";

type Feature = "activities" | "tasks" | "files";

defineProps<{
  /** Whether the feature is enabled */
  enabled: Record<Feature, boolean>;
}>();

defineEmits<{
  (event: "toggleFeature", feature: Feature): void;
}>();

const { result } = TenantAPI.getTenantFileStorageProvider();

type FeatureInfo = {
  description: string;
  icon: string;
  disabled?: Ref<boolean>;
  disabledMessage?: string;
};

const usingKinabaseStorage = computed(() => result.value?.tenant?.fileStorage.provider === "KINABASE");

const features: Record<Feature, FeatureInfo> = {
  activities: {
    description: "Activities represent something that happened at a point in time. This could include emails, phone calls, meetings or general notes.",
    icon: "messages"
  },
  tasks: {
    description: "Tasks represent something that needs to be done, such as a to-do list or a list of things to follow up on.",
    icon: "list-check"
  },
  files: {
    description: "Files represent documents, images or other files that you want to store against a record.",
    icon: "files",
    disabled: usingKinabaseStorage,
    disabledMessage: "Please configure an external file storage provider in settings to enable files."
  }
};
</script>

<style scoped>
.gray-message {
  font-size: 0.8rem;
  color: gray;
}
.icon {
  font-size: xx-large;
}
</style>
