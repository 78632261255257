import { formatReasonableNumber } from "../../helpers/strings/Precision";
import { parseUnit } from "../../units/parseUnit";
import { SimpleField } from "../basic/SimpleFields";
import { formatDuration } from "../../helpers/strings/Durations";

import type { DatetimePrecision } from "../basic/DatetimeField";
import type { ExpressionField } from "../../expressions/accessors";
import type { TableCell } from "../TableCell";
import type { KRecord } from "../../data/Record";
import type { MetricUnit } from "../../units/units";
import type { KFieldSetup, Sortable } from "../KField";

export type AggregationMode = "count" | "sum" | "average" | "min" | "max" | "delta";

export type AggregationFieldConfig = {
  targetCollection?: string;
  link: {
    foreignField?: string;
    localField?: string;
  };
  filters?: string[];
  expression?: string;
  unit?: string;
  durationUnit?: DatetimePrecision;
  mode: AggregationMode;
  /* One for the delta */
  timeField?: string;
};

export type AggregationFieldSetup = KFieldSetup & AggregationFieldConfig;

export class AggregationField extends SimpleField<number> implements ExpressionField<"number"> {
  readonly type = "aggregation";

  readonly unit?: MetricUnit;

  readonly expressionType = "number";

  aggregationSetup: AggregationFieldSetup;

  constructor(setup: string | AggregationFieldSetup) {
    super(setup);
    if (typeof setup === "string") {
      this.aggregationSetup = { ...this.setup, mode: "count", link: {} };
    } else {
      this.aggregationSetup = setup;
      this.unit = setup.unit ? parseUnit(setup.unit) : undefined;
    }
  }

  getSortableValue(record: KRecord): Sortable {
    return this.getValue(record);
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    if (value === undefined) return "";
    if (this.aggregationSetup.durationUnit) {
      return formatDuration(value, this.aggregationSetup.durationUnit);
    }
    if (this.unit) {
      return this.unit.display(value, { convert: true });
    }
    return formatReasonableNumber(value);
  }

  getExpressionValue(r: KRecord): number | undefined {
    return this.getValue(r);
  }

  getTableCell(record: KRecord): TableCell | undefined {
    const display = this.getDisplayValue(record);
    return display ? { type: "text", text: display, align: "right" } : undefined;
  }

  validateType(value: unknown): value is number {
    return typeof value === "number";
  }
}
