<template>
  <p v-if="!addressLines.length" :id class="user-selectable text-secondary">None</p>
  <p v-else :id class="user-selectable">
    <template v-for="line in addressLines">
      <span v-if="line" :key="line" class="address-line">{{ line }}</span>
    </template>
    <a v-if="url" :href="url" target="blank" class="pt-1 mb-2 map-link address-line">Open in Maps</a>
  </p>
</template>

<script setup lang="ts">
import { computed } from "vue";

import type { Address } from "@data/types/Location";
import { addressDisplayOrder } from "@data/types/Location";

const props = defineProps<{
  /** ID of the display for labelling purposes */
  id: string;
  /** Address display value to show */
  value?: Address;
  /** Title of the address */
  title?: string;
}>();

const url = computed(() => {
  const value = props.value;
  const title = props.title ?? value?.title;
  if (!title || value?.lat === undefined || value.lng === undefined) return undefined;
  // Note Apple maps redirects to Google Maps on the web / non-iOS devices
  return `https://maps.apple.com/?q=${encodeURIComponent(title)}&ll=${value.lat},${value.lng}`;
});

// version of address without repeated lines
const addressLines = computed<string[]>(() => {
  const value = props.value;
  if (!value) return [];
  const lines: string[] = [];
  let lastLine = "";
  for (const key of addressDisplayOrder) {
    const line = value[key];
    if (line && line !== lastLine) {
      lines.push(line);
      lastLine = line;
    }
  }
  return lines;
});
</script>

<style scoped>
.address-line {
  display: block;
}

.map-link {
  font-size: 0.9em;
}
</style>
