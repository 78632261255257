import { ApolloClient, defaultDataIdFromObject, InMemoryCache, split } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { logErrorMessages } from "@vue/apollo-util";
import { createUploadLink } from "apollo-upload-client";
import { persistCache, LocalForageWrapper } from "apollo3-cache-persist";
import fetch from "cross-fetch";
import { createClient } from "graphql-ws";
import LocalForage from "localforage";
import { Kind, OperationTypeNode } from "graphql";

import { AlertHandler } from "@ui/alerts/alert-handler";

import { PlanUpgradeHandler } from "./plan-upgrade-handler";
import { signOut } from "./auth";
import { checkForNewVersion } from "./version";

import type { FieldPolicy, FieldReadFunction, ServerError, TypePolicies } from "@apollo/client/core";

const uri = "/api/graphql";
const authLink = setContext((_, { headers }: { headers?: { [header: string]: string } }) => {
  // // Return the headers to the context so HTTP link can read them
  headers = headers || {};
  headers["GraphQL-Preflight"] = "1";
  return {
    uri,
    headers,
    credentials: "same-origin"
  };
});

// HTTP connection to the API
const uploadLink = createUploadLink({ uri, fetch });

const host = window.location.host;
const isHttps = window.location.protocol.includes("https");
const wsLink = new GraphQLWsLink(
  createClient({
    url: `ws${isHttps ? "s" : ""}://${host}/api/graphql/ws`
  })
);

let hasDisconnected = false;
wsLink.client.on("connected", () => {
  if (!hasDisconnected) return;
  void checkForNewVersion();
});

wsLink.client.on("closed", () => {
  hasDisconnected = true;
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION;
  },
  wsLink,
  uploadLink
);

// Error handling
const errorLink = onError((error) => {
  const alert = new AlertHandler();

  const networkError = error.networkError as Partial<ServerError> | undefined;
  if (networkError && networkError.response?.redirected) {
    (window as Window).location = networkError.response.url;
    return;
  }
  console.error(error);

  if (window.__ssr__?.env.startsWith("dev")) {
    logErrorMessages(error);
  }

  if (error.graphQLErrors?.some((e) => e.message.startsWith("TenantPlanUpgradeException"))) {
    // Handle plan upgrade exceptions, and show user the reason why they need to upgrade
    const reason = error.graphQLErrors.find((e) => e.message.startsWith("TenantPlanUpgradeException"))?.message.replace("TenantPlanUpgradeException: ", "");
    const planUpgrade = new PlanUpgradeHandler();
    planUpgrade.openUpgradeModal({ reason });
  } else if (error.graphQLErrors) {
    alert.addAlert({
      title: "Request failed",
      content: error.graphQLErrors
        .map((e) => e.message)
        // deduplicate errors
        .filter((e, idx, arr) => arr.indexOf(e) === idx)
        .join(",")
        .toString(),
      variant: "danger",
      duration: 5000
    });
  } else if (error.networkError && (error.networkError as ServerError).statusCode === 401) {
    void signOut();
    return;
  } else if (error.networkError && error.networkError.message.startsWith("Socket closed")) {
    // Ignore socket closed errors, means the user has disconnected from an active subscription
    return;
  } else if (error.networkError) {
    alert.addAlert({
      title: "Network error",
      content: error.networkError.message,
      variant: "danger",
      duration: 5000
    });

    if (error.networkError.message === "Failed to fetch") {
      console.error("Failed to fetch. Does the user need to log in? Recommend replacing this line of code with a login page redirect!");
    }
  }
});

const typePolicies: TypePolicies = {
  Tenant: {
    fields: {
      details: {
        merge: true
      }
    }
  },
  // Disable normalisation and caching for EventLogRelation && Fields, otherwise it loses the original data
  Field: {
    keyFields: false
  },
  EventLogRelation: {
    keyFields: false
  }
};

const queryFieldPolicies: { [fieldName: string]: FieldPolicy | FieldReadFunction } = {};
typePolicies.Query = { fields: queryFieldPolicies };

// Cache implementation
const cache = new InMemoryCache({
  typePolicies,
  dataIdFromObject(responseObject) {
    // If the object is a record, use the collection and id to create a unique id across all collections
    if (responseObject.__typename === "Record" && responseObject.collection && responseObject.id) {
      const result = responseObject as { collection: string; id: string };
      // Note the value for result.collection sometimes has double quotes around the collection id
      return `Record:${result.collection}:${result.id}`;
    } else if (responseObject.__typename === "RecordOption" && responseObject.collectionId) {
      const result = responseObject as { collectionId: string; id: string };
      return `RecordOption:${result.collectionId}:${result.id}}`;
    }
    return defaultDataIdFromObject(responseObject);
  }
});

void persistCache({
  cache,
  storage: new LocalForageWrapper(LocalForage),
  key: "kb-cache"
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(splitLink),
  cache,
  queryDeduplication: true
});
window.__apollo__ = apolloClient;

export { apolloClient };
