<template>
  <div :class="{ 'form-control-container': true }">
    <k-label v-if="!config.hideLabel" :id="uuid" :label />
    <date-picker
      v-if="!config.readonly && !config.disabled"
      v-model="input"
      mode="dateTime"
      is24hr
      :is-required="config.required"
      locale="en-GB"
      :popover="{ visibility: 'focus' }"
      :input-debounce="500"
      :attributes>
      <template #default="{ inputValue, inputEvents }">
        <input :id="uuid" :class="classes" :value="inputValue" :placeholder="config.placeholder || label" v-on="inputEvents" />
      </template>
    </date-picker>
    <input v-else v-model="formattedDate" :class="classes" :readonly="true" :disabled="true" :aria-disabled="true" />
    <issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { v4 } from "uuid";
import { DatePicker } from "v-calendar";

import IssueDisplay from "../KIssueDisplay.vue";
import { useInputClasses, useInputConfig } from "../inputConfig";

import KLabel from "@ui/label/KLabel.vue";

import { getDatePickerAttributes } from "./DatePickerAttributes";

import { formatDateTime } from "@data/helpers/strings/Dates";

import type { Dayjs } from "dayjs";

const props = defineProps<{
  /** Value for the input */
  modelValue?: Dayjs;
  /** Label for the input */
  label?: string;
}>();

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", newValue: Dayjs | undefined): void;
}>();

dayjs.extend(utc);

const config = useInputConfig();

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const input = ref(props.modelValue?.toDate());
const dayjsInput = computed(() => input.value && dayjs.utc(input.value));
const formattedDate = computed(() => (input.value ? formatDateTime(input.value, true) : ""));

watch(
  () => props.modelValue,
  (newValue) => (input.value = newValue?.toDate())
);

watch(
  dayjsInput,

  (value, oldValue) => {
    if (value?.toString() !== oldValue?.toString()) {
      emit("update:modelValue", value);
    }
  }
);

const classes = useInputClasses(config);
const uuid = v4();

const attributes = getDatePickerAttributes();
</script>
