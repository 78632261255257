import { toSuperscriptUnicode } from "./Superscript";

const x10p = (x: number, sf = 3) => {
  const p = Math.floor(Math.log10(x));
  const decimal = x / Math.pow(10, p);
  const rounded = Math.round(decimal * Math.pow(10, sf - 1)) / Math.pow(10, sf - 1);
  return `${rounded}×10${p === 0 ? "" : toSuperscriptUnicode(p)}`;
};

// largest number we'll show in non-scientific notation
const EXP_THRESHOLD = 1e6;
// smallest number we'll show in non-scientific notation
const EXP_THRESHOLD_NEG = 1e-6;

/**
 * Format a number to look reasonable without having a stupid number of digits
 *
 * @param value
 */
export const formatReasonableNumber = (value: number | undefined | null, sf = 3): string => {
  if (value == null) {
    return "";
  }
  const absValue = Math.abs(value);
  const sign = value < 0 ? "-" : "";
  if (absValue >= EXP_THRESHOLD || (absValue <= EXP_THRESHOLD_NEG && absValue > 0)) {
    return sign + x10p(absValue, sf);
  }
  // is it a whole number?
  if (Math.floor(value) === value) {
    return value.toString();
  }
  const p = Math.floor(Math.log10(absValue));
  const fixed = value.toFixed(Math.max(1, sf - p - 1));
  // strip trailing zeros
  return fixed.replace(/(?<!\.)0+$/, "");
};
