<template>
  <k-button v-if="showAsButton" variant="primary" icon="plus" label="Add collections" @click="addCollection" />
  <k-modal
    v-model:show="isVisible"
    title="Add collections"
    static-backdrop
    hide-header
    hide-footer
    :modal-style="{ 'max-width': '680px', 'overflow-y': 'hidden' }">
    <collections-wizard @close="cancelForm" />
  </k-modal>
</template>

<script setup lang="ts">
import CollectionsWizard from "../pages/collections/wizard/CollectionsWizard.vue";

import KButton from "@ui/button/KButton.vue";
import KModal from "@ui/modal/KModal.vue";

defineProps<{
  /** Whether to show as a button */
  showAsButton?: boolean;
}>();

const isVisible = defineModel<boolean>("visible");

const addCollection = () => {
  isVisible.value = true;
};

const cancelForm = () => {
  isVisible.value = false;
};
</script>
