import { toBase64, fromBase64 } from "../../helpers/strings/Encoding";
import { StringlikeField } from "../basic/StringFields";

import type { KRecord, StoredKRecord } from "../../data/Record";
import type { KFieldSetup } from "../KField";

type PasswordStorage = "base64";
export type PasswordAutocompleteType = "current-password" | "new-password";

interface PasswordSetup extends KFieldSetup {
  /**
   * Whether to show the password strength meter
   *
   * @type {boolean}
   */
  showPasswordStrength?: boolean;

  /**
   * How the password is stored in the database Defaults to base64
   *
   * @type {PasswordStorage}
   */
  storageType?: PasswordStorage;

  /**
   * The autocomplete type for the password field See: https://www.chromium.org/developers/design-documents/create-amazing-password-forms/
   *
   * @type {PasswordAutocompleteType}
   */
  autocomplete?: PasswordAutocompleteType;
}

export class PasswordField extends StringlikeField {
  readonly type = "password";

  storageType: PasswordStorage;

  showPasswordStrength: boolean;

  autocomplete: PasswordAutocompleteType;

  constructor(setup: string | PasswordSetup) {
    super(setup);
    const passwordSetup = typeof setup === "string" ? { label: setup } : setup;
    this.storageType = passwordSetup.storageType ?? "base64";
    this.showPasswordStrength = passwordSetup.showPasswordStrength ?? true;
    this.autocomplete = passwordSetup.autocomplete ?? "current-password";
  }

  getDisplayValue(record: KRecord): string {
    return this.getValue(record) ? "********" : "";
  }

  storeValue(source: KRecord, target: StoredKRecord): void {
    const password = source[this.key] as string | undefined;
    if (password) {
      switch (this.storageType) {
        case "base64":
          target[this.key] = toBase64(password);
      }
    }
  }

  restoreValue(source: StoredKRecord, target: KRecord): void {
    const password = source[this.key] as string | undefined;
    if (password) {
      switch (this.storageType) {
        case "base64":
          target[this.key] = fromBase64(password);
      }
    }
  }
}
