import { capitalise } from "../../helpers/strings/Casing";

import { SimpleField } from "./SimpleFields";

import type { ExpressionField } from "../../expressions/accessors";
import type { KRecord } from "../../data/Record";
import type { Sortable } from "../KField";
import type { TableCell } from "../TableCell";

export abstract class StringlikeField extends SimpleField<string> implements ExpressionField<"string"> {
  readonly expressionType = "string";

  getSortableValue(record: KRecord, toLowerCase = false): string | undefined {
    const value = this.getValue(record);
    if (value && toLowerCase) {
      return value.toLowerCase();
    }
    return value;
  }

  getDisplayValue(record: KRecord): string {
    return this.getValue(record) ?? "";
  }

  getSearchValue(record: KRecord): string | undefined {
    return this.getValue(record);
  }

  getExpressionValue(r: KRecord): string | undefined {
    return this.getValue(r);
  }

  validateType(value: unknown): value is string {
    return typeof value === "string";
  }
}

export class StringField extends StringlikeField {
  readonly type = "string";

  readonly expressionType = "string";
}

export class RichTextField extends SimpleField<string> implements ExpressionField<"string"> {
  readonly type = "richText";

  readonly expressionType = "string";

  getSortableValue(record: KRecord, toLowerCase = false): string {
    const value = this.getValue(record);
    if (value) {
      // Note this is much faster than using a DOM parser, using cut load time by 60%.
      const tmp = document.createElement("div");
      tmp.innerHTML = value;

      // Add whitespace between paragraphs
      const paragraphs = tmp.getElementsByTagName("p");
      for (let i = 0; i < paragraphs.length - 1; i++) {
        paragraphs[i].innerHTML += " ";
      }
      // Add whitespace for line breaks
      const breaks = tmp.getElementsByTagName("br");
      for (const break_ of Array.from(breaks)) {
        break_.innerHTML = " " + break_.innerHTML;
      }

      const textValue = tmp.textContent || tmp.innerText || "";
      return toLowerCase ? textValue.toLowerCase() : textValue;
    }
    return "";
  }

  getDisplayValue(record: KRecord): string {
    return this.getSortableValue(record);
  }

  getSearchValue(record: KRecord): string | undefined {
    return this.getSortableValue(record);
  }

  getCSVValue(record: KRecord): string {
    return this.getValue(record) ?? "";
  }

  getExpressionValue(r: KRecord): string | undefined {
    return this.getDisplayValue(r);
  }

  validateType(value: unknown): value is string {
    return typeof value === "string";
  }
}

export class IconField extends SimpleField<string> {
  readonly type = "icon";

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    return value ? capitalise(value) : "";
  }

  getSortableValue(record: KRecord, toLowerCase?: boolean | undefined): Sortable {
    return toLowerCase ? this.getDisplayValue(record).toLowerCase() : this.getDisplayValue(record);
  }

  getTableCell(record: KRecord): TableCell | undefined {
    const value = this.getValue(record);
    return value ? { type: "icon", icon: value } : undefined;
  }

  validateType(value: unknown): value is string {
    return typeof value === "string";
  }
}
