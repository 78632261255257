import { LazyMap } from "./LazyMap";

/** A map that creates values for missing keys */
export class DefaultMap<K, V> extends LazyMap<K, V> {
  constructor(dft: ((k: K) => V) | V, entries?: [K, V][]) {
    super(dft);
    if (entries) {
      this.map = new Map(entries);
    }
  }

  set(k: K, v: V) {
    this.map.set(k, v);
  }

  /** Returns a readonly copy of the map */
  frozen(): ReadonlyMap<K, V> {
    return new Map(this.map);
  }
}
