import { MetricUnit } from "../units";

const inches = new MetricUnit({
  symbol: "in",
  name: "inches",
  dimensions: { length: 1 },
  conversion: 0.0254 // (exact)
});

const feet = inches.addSuperUnit({ symbol: "ft", name: "feet" }, 12);

const squareFeet = new MetricUnit({
  symbol: "ft²",
  expressionSymbol: "ft2",
  name: "square feet",
  dimensions: { length: 2 },
  conversion: feet.conversion ** 2
});

const pounds = new MetricUnit({
  symbol: "lb",
  name: "pounds",
  dimensions: { mass: 1 },
  conversion: 0.45359237 // (also exact)
});

const ounces = pounds.addSubUnit({ symbol: "oz", name: "ounces" }, 16);

export const imperialUnits = {
  inches,
  feet,
  squareFeet,
  pounds,
  ounces
};
