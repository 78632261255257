import type { defineComponent } from "vue";

import type { SelectItem } from "@ui/inputs/select/SelectItem";
import InputColour from "@ui/inputs/colour/KInputColour.vue";
import KInputBoolean from "@ui/inputs/boolean/KInputBoolean.vue";
import KInputDate from "@ui/inputs/date-time/KInputDate.vue";
import KInputDateTime from "@ui/inputs/date-time/KInputDateTime.vue";
import KInputMinutes from "@ui/inputs/duration/KInputMinutes.vue";
import KInputMonth from "@ui/inputs/date-time/KInputMonth.vue";
import KInputYear from "@ui/inputs/date-time/KInputYear.vue";
import KInputTimeSpan from "@ui/inputs/date-time/KInputTimeSpan.vue";
import KInputLocation from "@ui/inputs/location/KInputLocation.vue";
import KInputCurrency from "@ui/inputs/numeric/KInputCurrency.vue";
import KInputMetric from "@ui/inputs/numeric/KInputMetric.vue";
import KInputNumber from "@ui/inputs/numeric/KInputNumber.vue";
import KInputRadio from "@ui/inputs/numeric/KInputRadio.vue";
import KInputRange from "@ui/inputs/numeric/KInputRange.vue";
import KInputProbability from "@ui/inputs/numeric/KInputProbability.vue";
import KInputRisk from "@ui/inputs/risk/KInputRiskScore.vue";
import KInputSelect from "@ui/inputs/select/KInputSelect.vue";
import KInputSelectOther from "@ui/inputs/select/KInputSelectOther.vue";
import KInput from "@ui/inputs/strings/KInput.vue";
import KInputPassword from "@ui/inputs/strings/KInputPassword.vue";
import KInputRichText from "@ui/inputs/strings/KInputRichText.vue";
import KInputWebsite from "@ui/inputs/strings/KInputWebsite.vue";
import IssueDisplay from "@ui/inputs/KIssueDisplay.vue";
import KInputMultiselect from "@ui/inputs/select/KInputMultiselect.vue";

import InputRecord from "./records/InputRecord.vue";
import InputPerson from "./records/InputPerson.vue";
import InputPeople from "./records/InputPeople.vue";
import InputMultiRecord from "./records/InputMultiRecord.vue";
import InputPhone from "./phone/InputPhone.vue";
import InputFile from "./file/InputFile.vue";
import InputMultiFile from "./file/InputMultiFile.vue";
import InputRoles from "./InputRoles.vue";
import KInputMultiselectWithAdd from "./KInputMultiselectWithAdd.vue";

import { Collection } from "@data/data/Collection";
import type { KEntity } from "@data/data/KEntity";
import { fieldRegistry } from "@data/fields/FieldRegistry";
import type { DatetimeField } from "@data/fields/basic/DatetimeField";
import type { ColourField } from "@data/fields/utility/ColourField";
import { FunctionRegistry, PartialFunctionRegistry } from "@data/helpers/serialisation/Registry";
import { sterling } from "@data/units/basic/si";
import type { RolesField } from "@data/fields/utility/RolesField";
import type { ComputedDatetimeField } from "@data/fields/computed/ComputedDatetimeField";

const getDateTimeInput = (f: DatetimeField | ComputedDatetimeField) => {
  switch (f.precision) {
    case "year":
      return { component: KInputYear };
    case "quarter":
    case "month":
      return { component: KInputMonth, props: { quarter: f.precision === "quarter" } };
    case "day":
      return { component: KInputDate };
    default:
      return { component: KInputDateTime };
  }
};

const noStorePair = <T>() =>
  [
    (_f: T) => {
      throw new Error("Shouldn't be stored");
    },
    () => {
      throw new Error("Shouldn't be stored");
    }
  ] as const;
// temporary registry for fields that we use in forms but not collections
export const extendedFieldRegistry = fieldRegistry.register("roles", ...noStorePair<RolesField>()).register("colour", ...noStorePair<ColourField>());

export type InputMode = "add" | "edit" | "filter";
export const getFieldInput = new FunctionRegistry<
  typeof extendedFieldRegistry,
  { component?: ReturnType<typeof defineComponent>; props?: Record<string, unknown> },
  [KEntity, InputMode]
>({
  boolean: () => ({ component: KInputBoolean }),
  number: () => ({ component: KInputNumber }),
  rating: (f) => {
    if (f.inputType === "radio") {
      return { component: KInputRadio, props: f.range };
    }
    return { component: KInputRange, props: f.range };
  },
  currency: (f) => ({ component: KInputCurrency, props: { currency: f.currency } }),
  probability: () => ({ component: KInputProbability }),
  metric: (f) => ({
    component: KInputMetric,
    props: {
      unit: f.unit,
      min: f.lockUnit ? f.unit.conversion : f.min,
      max: f.lockUnit ? f.unit.conversion : f.max
    }
  }),
  string: () => ({ component: KInput }),
  richText: () => ({ component: KInputRichText }),
  icon: () => ({ component: KInput }),
  password: (f) => ({ component: KInputPassword, props: { showPasswordStrength: f.showPasswordStrength } }),
  website: () => ({ component: KInputWebsite }),
  email: () => ({ component: KInput, props: { type: "email" } }),
  date: getDateTimeInput,
  datetime: getDateTimeInput,
  timeSpan: (f) => ({ component: KInputTimeSpan, props: { precision: f.precision, allowActual: f.allowActual } }),
  duration: (f) => {
    switch (f.precision) {
      case "minute": {
        return { component: KInputMinutes, props: { precision: f.minutePrecision } };
      }
      case "hour": {
        return { component: KInputNumber, props: { suffix: "hours" } };
      }
      case "day": {
        return { component: KInputNumber, props: { suffix: "days" } };
      }
      // No default
    }
    // return KInputNumber for now
    return { component: KInputNumber };
  },
  option: (f) => ({ component: KInputSelectOther, props: { options: f.options, otherLabel: f.otherLabel } }),
  multiOption: (f, e) => {
    if (!(e instanceof Collection) || !f.canAdd) {
      return {
        component: KInputMultiselect,
        props: { options: f.options }
      };
    }
    return { component: KInputMultiselectWithAdd, props: { collection: e, field: f } };
  },
  location: () => ({ component: KInputLocation }),
  risk: () => ({ component: KInputRisk }),
  phone: () => ({ component: InputPhone }),
  record: (f, _e, mode) => ({
    component: InputRecord,
    props: { collectionId: f.collectionId, filters: f.filters, canCreateRecord: mode !== "filter" && f.canCreateRecord }
  }),
  multiRecord: (f, _e, mode) => ({
    component: InputMultiRecord,
    props: { collectionId: f.collectionId, filters: f.filters, canCreateRecord: mode !== "filter" && f.canCreateRecord }
  }),
  person: () => ({ component: InputPerson }),
  people: () => ({ component: InputPeople }),
  file: (f, e) => {
    {
      // don't need entity in props anymore, but files won't be persisted without it being a collection
      if (!(e instanceof Collection)) {
        console.error("FileField can only be used with a collection");
        return {
          component: IssueDisplay,
          props: { issues: [{ message: "FileField can only be used with a collection", severity: "error" }], class: "mt-2" }
        };
      }
      return { component: InputFile, props: { accept: f.accept } };
    }
  },
  multiFile: (f, e) => {
    {
      // don't need entity in props anymore, but files won't be persisted without it being a collection
      if (!(e instanceof Collection)) {
        console.error("MultiFileField can only be used with a collection");
        return {
          component: IssueDisplay,
          props: { issues: [{ message: "MultiFileField can only be used with a collection", severity: "error" }], class: "mt-2" }
        };
      }
      return { component: InputMultiFile, props: { accept: f.accept } };
    }
  },
  stage: (f) => {
    // used for creating a filter
    const options: SelectItem[] = f.stages.map((s) => ({ value: s.stageId, label: s.label }));
    return { component: KInputSelect, props: { options } };
  },
  computedNumber: () => ({ component: undefined }),
  computedString: () => ({ component: undefined }),
  computedDatetime: () => ({ component: undefined }),
  lookup: () => ({ component: undefined }),
  aggregation: () => ({ component: undefined }),
  roles: () => ({ component: InputRoles }),
  colour: () => ({ component: InputColour })
});

export const getComputedDisplay = new PartialFunctionRegistry<
  typeof fieldRegistry,
  { component: ReturnType<typeof defineComponent>; props?: Record<string, unknown> }
>({
  computedNumber: (f) => {
    if (f.unit) {
      if (f.unit.expressionSymbol === sterling.expressionSymbol) {
        return { component: KInputCurrency };
      }
      return { component: KInputMetric, props: { unit: f.unit } };
    }
    return { component: KInputNumber };
  },
  computedString: () => ({ component: KInput }),
  computedDatetime: (f) => getDateTimeInput(f)
});
