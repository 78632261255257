import { SimpleField } from "../basic/SimpleFields";
import { BulkData } from "../../expressions/data";
import { refine } from "../../helpers/NullHelpers";

import type { TableCell } from "../TableCell";
import type { Role } from "../../data/Role";
import type { ExpressionField } from "../../expressions/accessors";
import type { KRecord } from "../../data/Record";
import type { Sortable } from "../KField";

// cheeky global variable to get RolesField display values to work correctly
export const globalRoles = new Map<string, Role>();

export class RolesField extends SimpleField<string[]> implements ExpressionField<"data"> {
  readonly expressionType = "data";

  readonly type = "roles";

  readonly idFor = "role";

  getSortableValue(record: KRecord): Sortable {
    return this.getValue(record)?.length ?? 0;
  }

  private static getActualRoles(roleIds: string[]): Role[] {
    return refine(roleIds, (id) => {
      const role = globalRoles.get(id);
      if (!role) {
        console.warn(`Role with ID ${id} not found`);
      }
      return role;
    });
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    if (!value?.length) {
      return "No roles";
    }
    return RolesField.getActualRoles(value)
      .map((r) => r.name)
      .join(", ");
  }

  getExpressionValue(record: KRecord) {
    const v = this.getValue(record);
    return v ? new BulkData(RolesField.getActualRoles(v).map((r) => r.name)) : undefined;
  }

  validateType(value: unknown): value is string[] {
    return Array.isArray(value) && value.every((v) => typeof v === "string");
  }

  getTableCell(record: KRecord): TableCell | undefined {
    const value = this.getValue(record);
    if (!value?.length) {
      return { type: "text", text: "No roles", colour: "secondary" };
    }
    return {
      type: "badges",
      badges: RolesField.getActualRoles(value).map((r) => ({
        text: r.name
      }))
    };
  }
}
