<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div class="form-control-container">
    <k-label v-if="!config.hideLabel" :id="uuid" :label />
    <bubble-menu v-if="editor" class="bubble-menu" :tippy-options="{ duration: 100 }" :editor>
      <k-button variant="transparent" icon="bold" title="Bold" :pressed="editor.isActive('bold')" @click="editorAction('toggleBold')" />
      <k-button variant="transparent" icon="italic" title="Italic" :pressed="editor.isActive('italic')" @click="editorAction('toggleItalic')" />
      <k-button variant="transparent" icon="strikethrough" title="Strikethrough" :pressed="editor.isActive('strike')" @click="editorAction('toggleStrike')" />
      <k-button variant="transparent" icon="list-ul" title="Bullet Points" :pressed="editor.isActive('bulletList')" @click="editorAction('toggleBulletList')" />
      <k-button
        variant="transparent"
        icon="list-ol"
        title="Numerical List"
        :pressed="editor.isActive('orderedList')"
        @click="editorAction('toggleOrderedList')" />
    </bubble-menu>
    <editor-content
      :id="uuid"
      ref="editorDiv"
      :class="classes"
      :style="styles"
      :editor
      :aria-label="label"
      v-bind="commonBindings(config, props.label)"
      @click="onEditorClick"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @change="$emit('change', $event)" />
    <svg
      v-if="!hideGrip"
      class="input-grip"
      width="10"
      height="10"
      viewBox="0 0 18 19"
      stroke-width="2px"
      stroke="#a7a7a7"
      fill="none"
      stroke-linecap="square"
      @mousedown="onGripMouseDown">
      <path d="M15 8l-6 6m8-15l-15 15" />
    </svg>
    <issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch } from "vue";

import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { useEditor, EditorContent, BubbleMenu } from "@tiptap/vue-3";
import { clamp } from "@vueuse/core";
import { v4 } from "uuid";

import IssueDisplay from "../KIssueDisplay.vue";
import { commonBindings, getInputClasses, useInputConfig } from "../inputConfig";

import KLabel from "@ui/label/KLabel.vue";
import KButton from "@ui/button/KButton.vue";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: string;
  /** Label for the input */
  label?: string;
  /** Number of rows to display */
  rows?: number;
  /** Whether to show the grip for resizing */
  hideGrip?: boolean;
}>();

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", newValue: string | undefined): void;
}>();

const config = useInputConfig();

const isFocused = ref(false);
const editorDiv = ref<typeof EditorContent>();
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const input = ref(props.modelValue);

// matches input if it's a single paragraph with only whitespace
const emptyRegex = /^<p>\s*<\/p>$/;

const editable = computed(() => !config.value.disabled && !config.value.readonly);
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const editor = useEditor({
  content: props.modelValue,
  editable: editable.value,
  extensions: [
    StarterKit.configure({
      heading: false,
      horizontalRule: false
    }),
    // eslint-disable-next-line vue/no-setup-props-reactivity-loss
    Placeholder.configure({
      placeholder: config.value.placeholder ?? props.label
    })
  ],
  onFocus() {
    isFocused.value = true;
    emit("focus");
  },
  onBlur() {
    isFocused.value = false;
    emit("blur");
  },
  onUpdate: () => {
    const value = editor.value?.getHTML();
    emit("update:modelValue", value && emptyRegex.test(value) ? undefined : value);
    //v-model is not used with tiptap Editor, therfore, editor.value.getText() is used to update input value. see Docs here: https://tiptap.dev/api/editor#get-text
    input.value = editor.value?.getText();
  }
});

const editorAction = (command: "toggleBold" | "toggleItalic" | "toggleStrike" | "toggleBulletList" | "toggleOrderedList") => {
  if (!editor.value) return;
  const chain = editor.value.chain().focus();
  chain[command]();
  chain.run();
};

onBeforeUnmount(() => {
  editor.value?.destroy();
});

watch(
  () => props.modelValue,
  (value) => {
    const isSame = editor.value?.getHTML() === value;
    if (isSame) {
      return;
    }
    editor.value?.commands.setContent(value ?? "", false);
  }
);

watch(
  () => editable,
  () => {
    editor.value?.setOptions({ editable: editable.value });
  }
);

let startY = 0;
let startSize = 30 * (props.rows ?? 1);
let elementSize = 30;

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const elementHeight = ref(props.rows ? `${startSize}px` : "auto");

const onGripMouseDown = (e: MouseEvent) => {
  startY = e.clientY;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  startSize = (editorDiv.value?.$el.clientHeight as number | undefined) ?? elementSize;

  const onGripMouseMove = (moveEvent: MouseEvent) => {
    const deltaY = moveEvent.clientY - startY;
    elementSize = clamp(deltaY + startSize, 30, 300);
    elementHeight.value = `${elementSize}px`;
  };

  const onGripMouseUp = () => {
    window.removeEventListener("mousemove", onGripMouseMove);
    window.removeEventListener("mouseup", onGripMouseUp);
  };

  window.addEventListener("mousemove", onGripMouseMove);
  window.addEventListener("mouseup", onGripMouseUp);
};

const onEditorClick = () => {
  editor.value?.commands.focus();
};

const classes = computed(() => ({
  textarea: true,
  focus: isFocused.value,
  ...getInputClasses(config.value)
}));
const styles = computed(() => ({
  height: elementHeight.value,
  overflow: "auto"
}));

const uuid = v4();
</script>

<style scoped lang="scss">
.form-control.textarea {
  padding: 0.375rem 0.75rem;
  line-height: 1.4;
  -webkit-user-select: text;
  user-select: text;
  > :deep(.ProseMirror) {
    p {
      margin-bottom: 0.5rem;
    }
    p:last-child {
      margin-bottom: 0;
    }
    p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: var(--bs-gray-600);
      pointer-events: none;
      height: 0;
    }
  }
}

.form-control-sm.textarea {
  padding: 0.375rem 0.5rem;
}

.form-control :deep(.ProseMirror-focused),
.form-control :deep(.ProseMirror.focus-visible),
.form-control :deep(.ProseMirror:focus-visible) {
  outline: none !important;
}

.form-control.textarea :deep(blockquote) {
  padding-left: 0.5rem;
  border-left: 2px solid var(--k-color-secondary);
}

.form-control-container .bubble-menu {
  background: var(--k-dropdown-background);
  border: 1px solid var(--k-dropdown-border-color);
  color: var(--k-color);
  padding: 0.2rem;
  box-shadow: 0 1px 3px 0px rgb(0 0 0 / 30%);
  border-radius: 6px;
  transition: none;
  :deep(button) {
    margin-right: 0.25rem;
  }
  :deep(button:last-child) {
    margin-right: 0;
  }
}

.form-control-container .input-grip {
  float: right;
  margin-top: -12px;
  margin-right: 2px;
  cursor: ns-resize;
}
</style>
