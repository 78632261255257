import { ForwardsBiMap } from "../../helpers/maps/BiMap";

import { shortCodeMap } from "./countries";
import { getFlagEmoji } from "./emoji";
import phoneData from "./phone.data";

export type PhoneNumber = {
  prefix: string;
  number: string;
  extension?: string;
};

export const countryPrefixes = new ForwardsBiMap(phoneData);

type PrefixInfo = {
  name: string;
  emoji: string;
};

export const specialPrefixes = new Map<string, PrefixInfo>([
  ["+800", { name: "International Free", emoji: "🆓" }],
  ["+808", { name: "International Shared", emoji: "🔁" }],
  ["+870", { name: "Inmarsat", emoji: "🛰" }],
  ["+881", { name: "Satellite", emoji: "🛰" }],
  ["+882", { name: "Other International", emoji: "🌍" }],
  ["+883", { name: "Other International", emoji: "🌍" }],
  ["+888", { name: "UN", emoji: "🇺🇳" }]
]);

export const allPrefixes = [...specialPrefixes.keys(), ...countryPrefixes.backKeys()];

export const getPrefixInfo = (prefix: string): PrefixInfo => {
  const special = specialPrefixes.get(prefix);
  if (special) {
    return special;
  }
  if (prefix === "+1") {
    return { name: "North America", emoji: "🇺🇸" };
  }
  const countryCode = countryPrefixes.getBack(prefix);
  if (!countryCode) {
    throw new Error(`Invalid country prefix: ${prefix}`);
  }
  const countryInfo = shortCodeMap.get(countryCode);
  if (!countryInfo) {
    throw new Error(`Invalid country code: ${countryCode}`);
  }
  return {
    name: countryInfo.name,
    emoji: getFlagEmoji(countryCode)
  };
};

export const toTelNumber = ({ prefix, number, extension }: PhoneNumber): string => {
  if (number.startsWith("0")) {
    number = number.slice(1);
  }

  const fullNumber = extension ? `${prefix}${number},${extension}` : `${prefix}${number}`;
  // remove spaces and (0) from the number
  return fullNumber.replace(/\s|\(0\)/g, "");
};
