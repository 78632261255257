const countryData: { code: string; code3: string; name: string; id: string }[] = [
  { code: "AF", code3: "AFG", name: "Afghanistan", id: "004" },
  { code: "AL", code3: "ALB", name: "Albania", id: "008" },
  { code: "DZ", code3: "DZA", name: "Algeria", id: "012" },
  { code: "AS", code3: "ASM", name: "American Samoa", id: "016" },
  { code: "AD", code3: "AND", name: "Andorra", id: "020" },
  { code: "AO", code3: "AGO", name: "Angola", id: "024" },
  { code: "AI", code3: "AIA", name: "Anguilla", id: "660" },
  { code: "AQ", code3: "ATA", name: "Antarctica", id: "010" },
  { code: "AG", code3: "ATG", name: "Antigua and Barbuda", id: "028" },
  { code: "AR", code3: "ARG", name: "Argentina", id: "032" },
  { code: "AM", code3: "ARM", name: "Armenia", id: "051" },
  { code: "AW", code3: "ABW", name: "Aruba", id: "533" },
  { code: "AU", code3: "AUS", name: "Australia", id: "036" },
  { code: "AT", code3: "AUT", name: "Austria", id: "040" },
  { code: "AZ", code3: "AZE", name: "Azerbaijan", id: "031" },
  { code: "BS", code3: "BHS", name: "Bahamas", id: "044" },
  { code: "BH", code3: "BHR", name: "Bahrain", id: "048" },
  { code: "BD", code3: "BGD", name: "Bangladesh", id: "050" },
  { code: "BB", code3: "BRB", name: "Barbados", id: "052" },
  { code: "BY", code3: "BLR", name: "Belarus", id: "112" },
  { code: "BE", code3: "BEL", name: "Belgium", id: "056" },
  { code: "BZ", code3: "BLZ", name: "Belize", id: "084" },
  { code: "BJ", code3: "BEN", name: "Benin", id: "204" },
  { code: "BM", code3: "BMU", name: "Bermuda", id: "060" },
  { code: "BT", code3: "BTN", name: "Bhutan", id: "064" },
  { code: "BO", code3: "BOL", name: "Bolivia (Plurinational State of)", id: "068" },
  { code: "BQ", code3: "BES", name: "Bonaire, Sint Eustatius and Saba", id: "535" },
  { code: "BA", code3: "BIH", name: "Bosnia and Herzegovina", id: "070" },
  { code: "BW", code3: "BWA", name: "Botswana", id: "072" },
  { code: "BV", code3: "BVT", name: "Bouvet Island", id: "074" },
  { code: "BR", code3: "BRA", name: "Brazil", id: "076" },
  { code: "IO", code3: "IOT", name: "British Indian Ocean Territory", id: "086" },
  { code: "BN", code3: "BRN", name: "Brunei Darussalam", id: "096" },
  { code: "BG", code3: "BGR", name: "Bulgaria", id: "100" },
  { code: "BF", code3: "BFA", name: "Burkina Faso", id: "854" },
  { code: "BI", code3: "BDI", name: "Burundi", id: "108" },
  { code: "CV", code3: "CPV", name: "Cabo Verde", id: "132" },
  { code: "KH", code3: "KHM", name: "Cambodia", id: "116" },
  { code: "CM", code3: "CMR", name: "Cameroon", id: "120" },
  { code: "CA", code3: "CAN", name: "Canada", id: "124" },
  { code: "KY", code3: "CYM", name: "Cayman Islands", id: "136" },
  { code: "CF", code3: "CAF", name: "Central African Republic", id: "140" },
  { code: "TD", code3: "TCD", name: "Chad", id: "148" },
  { code: "CL", code3: "CHL", name: "Chile", id: "152" },
  { code: "CN", code3: "CHN", name: "China", id: "156" },
  { code: "CX", code3: "CXR", name: "Christmas Island", id: "162" },
  { code: "CC", code3: "CCK", name: "Cocos (Keeling) Islands", id: "166" },
  { code: "CO", code3: "COL", name: "Colombia", id: "170" },
  { code: "KM", code3: "COM", name: "Comoros", id: "174" },
  { code: "CD", code3: "COD", name: "Congo (the Democratic Republic of the)", id: "180" },
  { code: "CG", code3: "COG", name: "Congo", id: "178" },
  { code: "CK", code3: "COK", name: "Cook Islands", id: "184" },
  { code: "CR", code3: "CRI", name: "Costa Rica", id: "188" },
  { code: "HR", code3: "HRV", name: "Croatia", id: "191" },
  { code: "CU", code3: "CUB", name: "Cuba", id: "192" },
  { code: "CW", code3: "CUW", name: "Curaçao", id: "531" },
  { code: "CY", code3: "CYP", name: "Cyprus", id: "196" },
  { code: "CZ", code3: "CZE", name: "Czechia", id: "203" },
  { code: "CI", code3: "CIV", name: "Côte d'Ivoire", id: "384" },
  { code: "DK", code3: "DNK", name: "Denmark", id: "208" },
  { code: "DJ", code3: "DJI", name: "Djibouti", id: "262" },
  { code: "DM", code3: "DMA", name: "Dominica", id: "212" },
  { code: "DO", code3: "DOM", name: "Dominican Republic", id: "214" },
  { code: "EC", code3: "ECU", name: "Ecuador", id: "218" },
  { code: "EG", code3: "EGY", name: "Egypt", id: "818" },
  { code: "SV", code3: "SLV", name: "El Salvador", id: "222" },
  { code: "GQ", code3: "GNQ", name: "Equatorial Guinea", id: "226" },
  { code: "ER", code3: "ERI", name: "Eritrea", id: "232" },
  { code: "EE", code3: "EST", name: "Estonia", id: "233" },
  { code: "SZ", code3: "SWZ", name: "Eswatini", id: "748" },
  { code: "ET", code3: "ETH", name: "Ethiopia", id: "231" },
  { code: "FK", code3: "FLK", name: "Falkland Islands [Malvinas]", id: "238" },
  { code: "FO", code3: "FRO", name: "Faroe Islands", id: "234" },
  { code: "FJ", code3: "FJI", name: "Fiji", id: "242" },
  { code: "FI", code3: "FIN", name: "Finland", id: "246" },
  { code: "FR", code3: "FRA", name: "France", id: "250" },
  { code: "GF", code3: "GUF", name: "French Guiana", id: "254" },
  { code: "PF", code3: "PYF", name: "French Polynesia", id: "258" },
  { code: "TF", code3: "ATF", name: "French Southern Territories", id: "260" },
  { code: "GA", code3: "GAB", name: "Gabon", id: "266" },
  { code: "GM", code3: "GMB", name: "Gambia", id: "270" },
  { code: "GE", code3: "GEO", name: "Georgia", id: "268" },
  { code: "DE", code3: "DEU", name: "Germany", id: "276" },
  { code: "GH", code3: "GHA", name: "Ghana", id: "288" },
  { code: "GI", code3: "GIB", name: "Gibraltar", id: "292" },
  { code: "GR", code3: "GRC", name: "Greece", id: "300" },
  { code: "GL", code3: "GRL", name: "Greenland", id: "304" },
  { code: "GD", code3: "GRD", name: "Grenada", id: "308" },
  { code: "GP", code3: "GLP", name: "Guadeloupe", id: "312" },
  { code: "GU", code3: "GUM", name: "Guam", id: "316" },
  { code: "GT", code3: "GTM", name: "Guatemala", id: "320" },
  { code: "GG", code3: "GGY", name: "Guernsey", id: "831" },
  { code: "GN", code3: "GIN", name: "Guinea", id: "324" },
  { code: "GW", code3: "GNB", name: "Guinea-Bissau", id: "624" },
  { code: "GY", code3: "GUY", name: "Guyana", id: "328" },
  { code: "HT", code3: "HTI", name: "Haiti", id: "332" },
  { code: "HM", code3: "HMD", name: "Heard Island and McDonald Islands", id: "334" },
  { code: "VA", code3: "VAT", name: "Holy See", id: "336" },
  { code: "HN", code3: "HND", name: "Honduras", id: "340" },
  { code: "HK", code3: "HKG", name: "Hong Kong", id: "344" },
  { code: "HU", code3: "HUN", name: "Hungary", id: "348" },
  { code: "IS", code3: "ISL", name: "Iceland", id: "352" },
  { code: "IN", code3: "IND", name: "India", id: "356" },
  { code: "ID", code3: "IDN", name: "Indonesia", id: "360" },
  { code: "IR", code3: "IRN", name: "Iran (Islamic Republic of)", id: "364" },
  { code: "IQ", code3: "IRQ", name: "Iraq", id: "368" },
  { code: "IE", code3: "IRL", name: "Ireland", id: "372" },
  { code: "IM", code3: "IMN", name: "Isle of Man", id: "833" },
  { code: "IL", code3: "ISR", name: "Israel", id: "376" },
  { code: "IT", code3: "ITA", name: "Italy", id: "380" },
  { code: "JM", code3: "JAM", name: "Jamaica", id: "388" },
  { code: "JP", code3: "JPN", name: "Japan", id: "392" },
  { code: "JE", code3: "JEY", name: "Jersey", id: "832" },
  { code: "JO", code3: "JOR", name: "Jordan", id: "400" },
  { code: "KZ", code3: "KAZ", name: "Kazakhstan", id: "398" },
  { code: "KE", code3: "KEN", name: "Kenya", id: "404" },
  { code: "KI", code3: "KIR", name: "Kiribati", id: "296" },
  { code: "KP", code3: "PRK", name: "Korea (the Democratic People's Republic of)", id: "408" },
  { code: "KR", code3: "KOR", name: "Korea (the Republic of)", id: "410" },
  { code: "KW", code3: "KWT", name: "Kuwait", id: "414" },
  { code: "KG", code3: "KGZ", name: "Kyrgyzstan", id: "417" },
  { code: "LA", code3: "LAO", name: "Lao People's Democratic Republic", id: "418" },
  { code: "LV", code3: "LVA", name: "Latvia", id: "428" },
  { code: "LB", code3: "LBN", name: "Lebanon", id: "422" },
  { code: "LS", code3: "LSO", name: "Lesotho", id: "426" },
  { code: "LR", code3: "LBR", name: "Liberia", id: "430" },
  { code: "LY", code3: "LBY", name: "Libya", id: "434" },
  { code: "LI", code3: "LIE", name: "Liechtenstein", id: "438" },
  { code: "LT", code3: "LTU", name: "Lithuania", id: "440" },
  { code: "LU", code3: "LUX", name: "Luxembourg", id: "442" },
  { code: "MO", code3: "MAC", name: "Macao", id: "446" },
  { code: "MG", code3: "MDG", name: "Madagascar", id: "450" },
  { code: "MW", code3: "MWI", name: "Malawi", id: "454" },
  { code: "MY", code3: "MYS", name: "Malaysia", id: "458" },
  { code: "MV", code3: "MDV", name: "Maldives", id: "462" },
  { code: "ML", code3: "MLI", name: "Mali", id: "466" },
  { code: "MT", code3: "MLT", name: "Malta", id: "470" },
  { code: "MH", code3: "MHL", name: "Marshall Islands", id: "584" },
  { code: "MQ", code3: "MTQ", name: "Martinique", id: "474" },
  { code: "MR", code3: "MRT", name: "Mauritania", id: "478" },
  { code: "MU", code3: "MUS", name: "Mauritius", id: "480" },
  { code: "YT", code3: "MYT", name: "Mayotte", id: "175" },
  { code: "MX", code3: "MEX", name: "Mexico", id: "484" },
  { code: "FM", code3: "FSM", name: "Micronesia (Federated States of)", id: "583" },
  { code: "MD", code3: "MDA", name: "Moldova (the Republic of)", id: "498" },
  { code: "MC", code3: "MCO", name: "Monaco", id: "492" },
  { code: "MN", code3: "MNG", name: "Mongolia", id: "496" },
  { code: "ME", code3: "MNE", name: "Montenegro", id: "499" },
  { code: "MS", code3: "MSR", name: "Montserrat", id: "500" },
  { code: "MA", code3: "MAR", name: "Morocco", id: "504" },
  { code: "MZ", code3: "MOZ", name: "Mozambique", id: "508" },
  { code: "MM", code3: "MMR", name: "Myanmar", id: "104" },
  { code: "NA", code3: "NAM", name: "Namibia", id: "516" },
  { code: "NR", code3: "NRU", name: "Nauru", id: "520" },
  { code: "NP", code3: "NPL", name: "Nepal", id: "524" },
  { code: "NL", code3: "NLD", name: "Netherlands", id: "528" },
  { code: "NC", code3: "NCL", name: "New Caledonia", id: "540" },
  { code: "NZ", code3: "NZL", name: "New Zealand", id: "554" },
  { code: "NI", code3: "NIC", name: "Nicaragua", id: "558" },
  { code: "NE", code3: "NER", name: "Niger", id: "562" },
  { code: "NG", code3: "NGA", name: "Nigeria", id: "566" },
  { code: "NU", code3: "NIU", name: "Niue", id: "570" },
  { code: "NF", code3: "NFK", name: "Norfolk Island", id: "574" },
  { code: "MP", code3: "MNP", name: "Northern Mariana Islands", id: "580" },
  { code: "NO", code3: "NOR", name: "Norway", id: "578" },
  { code: "OM", code3: "OMN", name: "Oman", id: "512" },
  { code: "PK", code3: "PAK", name: "Pakistan", id: "586" },
  { code: "PW", code3: "PLW", name: "Palau", id: "585" },
  { code: "PS", code3: "PSE", name: "Palestine, State of", id: "275" },
  { code: "PA", code3: "PAN", name: "Panama", id: "591" },
  { code: "PG", code3: "PNG", name: "Papua New Guinea", id: "598" },
  { code: "PY", code3: "PRY", name: "Paraguay", id: "600" },
  { code: "PE", code3: "PER", name: "Peru", id: "604" },
  { code: "PH", code3: "PHL", name: "Philippines", id: "608" },
  { code: "PN", code3: "PCN", name: "Pitcairn", id: "612" },
  { code: "PL", code3: "POL", name: "Poland", id: "616" },
  { code: "PT", code3: "PRT", name: "Portugal", id: "620" },
  { code: "PR", code3: "PRI", name: "Puerto Rico", id: "630" },
  { code: "QA", code3: "QAT", name: "Qatar", id: "634" },
  { code: "MK", code3: "MKD", name: "Republic of North Macedonia", id: "807" },
  { code: "RO", code3: "ROU", name: "Romania", id: "642" },
  { code: "RU", code3: "RUS", name: "Russian Federation", id: "643" },
  { code: "RW", code3: "RWA", name: "Rwanda", id: "646" },
  { code: "RE", code3: "REU", name: "Réunion", id: "638" },
  { code: "BL", code3: "BLM", name: "Saint Barthélemy", id: "652" },
  { code: "SH", code3: "SHN", name: "Saint Helena, Ascension and Tristan da Cunha", id: "654" },
  { code: "KN", code3: "KNA", name: "Saint Kitts and Nevis", id: "659" },
  { code: "LC", code3: "LCA", name: "Saint Lucia", id: "662" },
  { code: "MF", code3: "MAF", name: "Saint Martin", id: "663" },
  { code: "PM", code3: "SPM", name: "Saint Pierre and Miquelon", id: "666" },
  { code: "VC", code3: "VCT", name: "Saint Vincent and the Grenadines", id: "670" },
  { code: "WS", code3: "WSM", name: "Samoa", id: "882" },
  { code: "SM", code3: "SMR", name: "San Marino", id: "674" },
  { code: "ST", code3: "STP", name: "Sao Tome and Principe", id: "678" },
  { code: "SA", code3: "SAU", name: "Saudi Arabia", id: "682" },
  { code: "SN", code3: "SEN", name: "Senegal", id: "686" },
  { code: "RS", code3: "SRB", name: "Serbia", id: "688" },
  { code: "SC", code3: "SYC", name: "Seychelles", id: "690" },
  { code: "SL", code3: "SLE", name: "Sierra Leone", id: "694" },
  { code: "SG", code3: "SGP", name: "Singapore", id: "702" },
  { code: "SX", code3: "SXM", name: "Sint Maarten", id: "534" },
  { code: "SK", code3: "SVK", name: "Slovakia", id: "703" },
  { code: "SI", code3: "SVN", name: "Slovenia", id: "705" },
  { code: "SB", code3: "SLB", name: "Solomon Islands", id: "090" },
  { code: "SO", code3: "SOM", name: "Somalia", id: "706" },
  { code: "ZA", code3: "ZAF", name: "South Africa", id: "710" },
  { code: "GS", code3: "SGS", name: "South Georgia and the South Sandwich Islands", id: "239" },
  { code: "SS", code3: "SSD", name: "South Sudan", id: "728" },
  { code: "ES", code3: "ESP", name: "Spain", id: "724" },
  { code: "LK", code3: "LKA", name: "Sri Lanka", id: "144" },
  { code: "SD", code3: "SDN", name: "Sudan", id: "729" },
  { code: "SR", code3: "SUR", name: "Suriname", id: "740" },
  { code: "SJ", code3: "SJM", name: "Svalbard and Jan Mayen", id: "744" },
  { code: "SE", code3: "SWE", name: "Sweden", id: "752" },
  { code: "CH", code3: "CHE", name: "Switzerland", id: "756" },
  { code: "SY", code3: "SYR", name: "Syrian Arab Republic", id: "760" },
  { code: "TW", code3: "TWN", name: "Taiwan", id: "158" },
  { code: "TJ", code3: "TJK", name: "Tajikistan", id: "762" },
  { code: "TZ", code3: "TZA", name: "Tanzania, United Republic of", id: "834" },
  { code: "TH", code3: "THA", name: "Thailand", id: "764" },
  { code: "TL", code3: "TLS", name: "Timor-Leste", id: "626" },
  { code: "TG", code3: "TGO", name: "Togo", id: "768" },
  { code: "TK", code3: "TKL", name: "Tokelau", id: "772" },
  { code: "TO", code3: "TON", name: "Tonga", id: "776" },
  { code: "TT", code3: "TTO", name: "Trinidad and Tobago", id: "780" },
  { code: "TN", code3: "TUN", name: "Tunisia", id: "788" },
  { code: "TR", code3: "TUR", name: "Turkey", id: "792" },
  { code: "TM", code3: "TKM", name: "Turkmenistan", id: "795" },
  { code: "TC", code3: "TCA", name: "Turks and Caicos Islands", id: "796" },
  { code: "TV", code3: "TUV", name: "Tuvalu", id: "798" },
  { code: "UG", code3: "UGA", name: "Uganda", id: "800" },
  { code: "UA", code3: "UKR", name: "Ukraine", id: "804" },
  { code: "AE", code3: "ARE", name: "United Arab Emirates", id: "784" },
  { code: "GB", code3: "GBR", name: "United Kingdom", id: "826" },
  { code: "UM", code3: "UMI", name: "United States Minor Outlying Islands", id: "581" },
  { code: "US", code3: "USA", name: "United States of America", id: "840" },
  { code: "UY", code3: "URY", name: "Uruguay", id: "858" },
  { code: "UZ", code3: "UZB", name: "Uzbekistan", id: "860" },
  { code: "VU", code3: "VUT", name: "Vanuatu", id: "548" },
  { code: "VE", code3: "VEN", name: "Venezuela (Bolivarian Republic of)", id: "862" },
  { code: "VN", code3: "VNM", name: "Viet Nam", id: "704" },
  { code: "VG", code3: "VGB", name: "Virgin Islands (British)", id: "092" },
  { code: "VI", code3: "VIR", name: "Virgin Islands (U.S.)", id: "850" },
  { code: "WF", code3: "WLF", name: "Wallis and Futuna", id: "876" },
  { code: "EH", code3: "ESH", name: "Western Sahara", id: "732" },
  { code: "YE", code3: "YEM", name: "Yemen", id: "887" },
  { code: "ZM", code3: "ZMB", name: "Zambia", id: "894" },
  { code: "ZW", code3: "ZWE", name: "Zimbabwe", id: "716" }
];

export default countryData;
