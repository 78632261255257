import { KField } from "../KField";
import { checkIsObject } from "../../helpers/NullHelpers";

import type { ExpressionField } from "../../expressions/accessors";
import type { KContext } from "../../expressions/context";
import type { Expression, ExpressionReturnType } from "../../expressions/expressions";
import type { KFieldSetup } from "../KField";
import type { KRecord, StoredKRecord } from "../../data/Record";

export type ComputedFieldValue<V extends Expression["type"] = Expression["type"]> = { lastValue?: ExpressionReturnType<V> | null; overridden?: boolean };

export abstract class ComputedField<const V extends Expression["type"] = Expression["type"]>
  extends KField<ComputedFieldValue<V>>
  implements ExpressionField<V>
{
  // default to read-only - write permissions allow the field to be overridden
  override get permissions() {
    return this.setup.permissions ?? { read: ["true"], write: [] };
  }

  abstract expressionType: V;

  constructor(
    setup: string | KFieldSetup,
    public readonly expression: string | undefined
  ) {
    super(setup);
  }

  getValue(record: KRecord): ComputedFieldValue<V> | undefined {
    return record[this.key] as ComputedFieldValue<V> | undefined;
  }

  abstract getParsedExpression(context: KContext): Expression | undefined;

  getExpressionValue(r: KRecord): ExpressionReturnType<V> {
    return this.getValue(r)?.lastValue ?? undefined;
  }
}

// SimpleField analogue for ComputedField
export abstract class SimpleComputedField<const V extends "string" | "number" | "boolean"> extends ComputedField<V> {
  validateType(value: unknown): value is ComputedFieldValue<V> {
    if (!checkIsObject(value)) return false;
    if (value.lastValue != null && typeof value.lastValue !== this.expressionType) return false;
    if (value.overridden != null && typeof value.overridden !== "boolean") return false;
    return true;
  }

  storeValue(source: KRecord, target: StoredKRecord): void {
    const value = this.getValue(source);
    if (value != null) {
      target[this.key] = value;
    }
  }

  restoreValue(source: StoredKRecord, target: KRecord): void {
    const value = source[this.key];
    if (value != null) {
      target[this.key] = value;
    }
  }
}
