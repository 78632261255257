<template>
  <input
    class="selection-checkbox"
    :checked="selected === true || state === 'all'"
    :indeterminate="state === 'some'"
    type="checkbox"
    @input="($evt) => emit('input', $evt)"
    @click="(evt) => emit('click', evt)" />
</template>

<script setup lang="ts">
import type { TableSelectionState } from "../selection/TableSelectionManager";

defineProps<{
  /** Whether the row is selected (shown as light-blue), may be one of many */
  selected?: true | false;
  /** Current selection state */
  state?: TableSelectionState;
}>();

const emit = defineEmits<{
  (e: "input", evt: Event): void;
  (e: "click", evt: Event): void;
}>();
</script>

<style scoped>
.selection-checkbox {
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--k-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: var(--k-color-d-05-light);
  width: 12px;
  height: 12px;
  border: 1px solid var(--k-color-d-05-light);
  border-radius: 0.15em;

  display: grid;
  place-content: center;
  transition:
    0.1s background-color ease-out,
    0.1s border-color ease-out;
}

.selection-checkbox:hover {
  border: 1px solid var(--k-color-d-10-secondary);
}

.selection-checkbox::before {
  content: "";
  width: 7px;
  height: 7px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0.75);
  opacity: 0;
  transform-origin: center center;
  transition: 0.05s all ease-out;
  box-shadow: inset 1em 1em white;
}

.selection-checkbox:checked {
  background: var(--k-color-primary);
  border-color: var(--k-color-primary);
}

.selection-checkbox:checked::before {
  transform: scale(1) rotate(10deg);
  opacity: 1;
}

.selection-checkbox:indeterminate {
  background: var(--k-color-d-10-secondary);
  border-color: var(--k-color-d-10-secondary);
}

.selection-checkbox:indeterminate::before {
  clip-path: polygon(0 35%, 100% 35%, 100% 65%, 0 65%);
  transform: scale(1);
  opacity: 1;
}
</style>
