export const pickFrom = <O, K extends (keyof O)[]>(object: O, ...keys: K): Pick<O, K[number]> => {
  const result = {} as Pick<O, K[number]>;
  for (const key of keys) {
    result[key] = object[key];
  }
  return result;
};

export const omitFrom = <O, K extends (keyof O)[]>(object: O, ...keys: K): Omit<O, K[number]> => {
  const result = { ...object };
  for (const key of keys) {
    delete result[key];
  }
  return result;
};

type Truthy<T> = T extends false | "" | 0 | null | undefined ? never : T;
type WithTruthyKeys<O, K extends keyof O> = { [P in K]-?: Truthy<O[P]> };
export const withRequiredKeys = <O, const K extends keyof O>(object: O, ...keys: K[]): WithTruthyKeys<O, K> | undefined => {
  const result = {} as WithTruthyKeys<O, K>;
  for (const key of keys) {
    const value = object[key];
    if (value) {
      result[key] = value as Truthy<O[K]>;
    } else {
      return undefined;
    }
  }
  return result;
};
