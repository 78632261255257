import { BooleanExpression, NumberExpression, StringExpression } from "../expressions";

import type { KContext } from "../context";
import type { DataExpression, InvertableExpression } from "../expressions";

export class StringItemExpression extends StringExpression {
  constructor(private readonly depth: number) {
    super();
  }

  evaluate(context: KContext): string | undefined {
    const value = context.items?.[this.depth];
    if (value === undefined || typeof value === "string") {
      return value;
    }
  }

  display(context: KContext): string {
    return (context.items?.[this.depth] as string | undefined) ?? "item";
  }
}

export class NumberItemExpression extends NumberExpression {
  constructor(private readonly depth: number) {
    super();
  }

  evaluate(context: KContext) {
    const value = context.items?.[this.depth];
    if (value === undefined || typeof value === "number") {
      return value;
    }
  }

  display(context: KContext): string {
    return (context.items?.[this.depth] as string | undefined) ?? "item";
  }
}

export class AnyExpression extends BooleanExpression {
  constructor(
    private readonly data: DataExpression,
    private readonly condition: BooleanExpression
  ) {
    super();
  }

  evaluate(context: KContext): boolean {
    const data = this.data.evaluate(context);
    if (data === undefined) {
      return false;
    }
    for (const item of data.unique) {
      const items = [...(context.items ?? []), item];
      if (this.condition.evaluate({ ...context, items })) {
        return true;
      }
    }
    return false;
  }

  display(context: KContext): string {
    return `any ${this.condition.display(context)} in ${this.data.display(context)}`;
  }
}

export class AllExpression extends BooleanExpression {
  constructor(
    private readonly data: DataExpression,
    private readonly condition: BooleanExpression
  ) {
    super();
  }

  evaluate(context: KContext): boolean {
    const data = this.data.evaluate(context);
    if (data === undefined) {
      return false;
    }
    for (const item of data.unique) {
      const items = [...(context.items ?? []), item];
      if (!this.condition.evaluate({ ...context, items })) {
        return false;
      }
    }
    return true;
  }

  display(context: KContext): string {
    return `all ${this.data.display(context)} satisfy ${this.condition.display(context)}`;
  }
}

export class IsEmptyExpression extends BooleanExpression implements InvertableExpression {
  constructor(private readonly data: DataExpression) {
    super();
  }

  evaluate(context: KContext): boolean {
    const data = this.data.evaluate(context);
    return data === undefined || data.unique.size === 0;
  }

  display(context: KContext): string {
    return `${this.data.display(context)} is empty`;
  }

  displayInverted(context: KContext): string {
    return `${this.data.display(context)} is not empty`;
  }
}

export class IntersectsExpression extends BooleanExpression implements InvertableExpression {
  constructor(
    private readonly left: DataExpression,
    private readonly right: DataExpression
  ) {
    super();
  }

  evaluate(context: KContext): boolean {
    const left = this.left.evaluate(context);
    if (left === undefined) {
      return false;
    }
    const right = this.right.evaluate(context);
    if (right === undefined) {
      return false;
    }
    for (const item of left.unique) {
      if (right.unique.has(item)) {
        return true;
      }
    }
    return false;
  }

  display(context: KContext): string {
    return `${this.left.display(context)} includes some of ${this.right.display(context)}`;
  }

  displayInverted(context: KContext): string {
    return `${this.left.display(context)} includes none of ${this.right.display(context)}`;
  }
}

export class SubsetOfExpression extends BooleanExpression implements InvertableExpression {
  constructor(
    private readonly left: DataExpression,
    private readonly right: DataExpression
  ) {
    super();
  }

  evaluate(context: KContext): boolean {
    const left = this.left.evaluate(context);
    if (left === undefined) {
      return false;
    }
    const right = this.right.evaluate(context);
    if (right === undefined) {
      return false;
    }
    for (const item of left.unique) {
      if (!right.unique.has(item)) {
        return false;
      }
    }
    return true;
  }

  display(context: KContext): string {
    return `${this.right.display(context)} includes all of ${this.left.display(context)}`;
  }

  displayInverted(context: KContext): string {
    return `${this.right.display(context)} doesn't include all of ${this.left.display(context)}`;
  }
}
