<template>
  <p v-if="value" :id class="user-selectable" :class="classes">
    {{ value }}
  </p>
  <p v-else :id class="user-selectable text-secondary">None</p>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  /** ID of the display for labelling purposes */
  id: string;
  /** String display value to show */
  value: string;
}>();
const classes = computed(() => ({ "text-secondary": !props.value }));
</script>
