import type { ContextMenuDatePicker, ContextMenuItem, ContextMenuTextItem } from "./ContextMenuItem";

export const filterContextItems = <T>(items: ContextMenuItem<T>[], selectedRecord: T | undefined) => {
  const withoutHidden = items.filter((item) => {
    if (item.type === "divider") {
      return true;
    }

    if (item.showOption === "has-items") {
      // Note this is an approximation and we're not checking if the subitems are hidden, to avoid unnecessary computation
      if (Array.isArray(item.items)) {
        return item.items.length > 0;
      } else if (typeof item.items === "function") {
        return item.items(selectedRecord).length > 0;
      }
      return false;
    } else if (typeof item.showOption === "function") {
      return item.showOption(selectedRecord);
    }

    return true;
  });

  return withoutHidden.filter((item, idx) => {
    if (item.type !== "divider") return true;
    if (!idx) return false;
    const nextItem = withoutHidden[idx + 1] as ContextMenuItem<T> | undefined;
    return nextItem && nextItem.type !== "divider";
  });
};

export const getContextSubitems = <T>(option: ContextMenuTextItem<T> | ContextMenuDatePicker<T>, selectedRecord: T | undefined) => {
  if (Array.isArray(option.items)) {
    return filterContextItems(option.items, selectedRecord);
  } else if (typeof option.items === "function") {
    return filterContextItems(option.items(selectedRecord), selectedRecord);
  }
  return null;
};

export const hasContextSubitems = <T>(option: ContextMenuTextItem<T> | ContextMenuDatePicker<T>, selectedRecord: T | undefined) =>
  !!getContextSubitems(option, selectedRecord)?.length;
