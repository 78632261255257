import type { MaybeRef } from "vue";
import { computed, toValue, unref } from "vue";

import { useEntityLookup, useLinkLookup } from "./entity";

import { peopleCollectionName } from "@data/constants/names";
import type { Activity, FormattedActivity } from "@data/data/Activity";
import { Collection } from "@data/data/Collection";
import { RecordField } from "@data/fields/relational/RecordFields";
import { globalUsers } from "@data/fields/relational/UserField";
import type { KEntity } from "@data/data/KEntity";
import type { KRecord } from "@data/data/Record";

import { useCollectionStore } from "@/api/stores/useCollectionStore";

type LinkLookup = ReturnType<typeof useLinkLookup>;

const processFieldChange = (changeValue: unknown, change: NonNullable<Activity["changes"]>[number], entity: KEntity | undefined, getLink: LinkLookup) => {
  if (changeValue && change.fieldType === "person") {
    if ((changeValue as { id?: number }).id) {
      return `/${peopleCollectionName.slug}/${(changeValue as { id: number }).id}`;
    }
  } else if (changeValue && change.fieldType === "record") {
    const field = entity?.getField(change.fieldId, RecordField);
    if ((changeValue as { id?: number }).id && field) {
      return getLink({ collectionId: field.collectionId, recordId: (changeValue as { id: number }).id.toString() });
    }
  }
};

const getPersonIdFromUserId = (userId: string | undefined) =>
  // urgh have to use global users again...
  globalUsers.get(userId ?? "")?.id;
const getPersonNameFromUserId = (userId: string | undefined) =>
  // urgh have to use global users again...
  globalUsers.get(userId ?? "")?.name;
/**
 * Format the content of an activity to be displayed in the UI, adding links where necessary
 *
 * @param activity Activity to format
 * @param collection Collection of the activity
 * @param getCollectionSlug Function to get the slug of a collection by id
 */
// eslint-disable-next-line complexity
const formatActivityContent = (
  activity: Activity,
  currentEntity: KEntity | undefined,
  currentRecordId: string | undefined,
  lookups: {
    entity: ReturnType<typeof useEntityLookup>;
    link: LinkLookup;
  }
) => {
  const { entity: entityLookup, link: linkLookup } = lookups;
  if (Array.isArray(activity.content)) return activity.content;

  const entity = currentEntity ?? entityLookup(activity.collectionId);
  const collection = entity instanceof Collection ? entity : undefined;

  const splitContent = activity.content.split(/(\[\[|]])/).filter((c) => !!c);
  const data = [];
  for (let i = 0; i < splitContent.length; i++) {
    // Identify parts of the activity in this format [[label|field]]
    if (splitContent[i] === "[[") {
      const [label, field] = splitContent[i + 1].split("|");

      if (field && field.startsWith("link")) {
        // handle cases where field is "link.collectionId.recordId"
        const [_link, collectionId, recordId] = field.split(".");
        if (collectionId === collection?.id && recordId === currentRecordId) {
          // lowercase since this is only used mid-sentence - refactor to use proper case if used elsewhere
          data.push("this record");
        } else {
          const to = linkLookup({ collectionId, recordId });
          data.push(to ? { label, to } : label);
        }
      } else if (field && field.startsWith("changes") && activity.changes) {
        // Handle cases where field is "changes.0.oldValue" etc
        const [_changes, stringIndex, changeType] = field.split(".");
        const index = parseInt(stringIndex);
        const change = activity.changes[index];
        let to: string | undefined;
        if (changeType === "oldValue" || changeType === "newValue") {
          const changeValue = change[changeType];
          to = processFieldChange(changeValue, change, entity, linkLookup);
        }
        data.push(to ? { label, to } : label);
      } else if (field === "recordId") {
        // Handle cases where field references current record
        if (activity.recordId === currentRecordId && activity.collectionId === collection?.id) {
          data.push("This record");
        } else {
          const to = linkLookup({ collectionId: activity.collectionId, recordId: activity.recordId });
          data.push(to ? { label, to } : label);
        }
      } else if (field === "createdBy") {
        // Handle cases where field references the user who created the activity
        const to = activity.createdBy ? `/${peopleCollectionName.slug}/${getPersonIdFromUserId(activity.createdBy)}` : undefined;
        data.push(to ? { label, to } : label);
      } else {
        // Otherwise just add the label
        data.push(label);
      }
      i += 2;
    } else if (splitContent[i] !== "]]") {
      data.push(splitContent[i]);
    }
  }
  return data;
};

export const getActivityStringContent = (activity: Partial<Activity>) => {
  if (activity.email && activity.email.textBody) {
    return activity.email.textBody;
  }
  return activity.content ?? "";
};

export const useFormattedActivities = (
  results: MaybeRef<Activity[]>,
  collection?: MaybeRef<KEntity | string | undefined>,
  record?: MaybeRef<KRecord | string | undefined>,
  linkLookup?: LinkLookup
) => {
  const collectionStore = useCollectionStore();
  const lookups = { entity: useEntityLookup(), link: linkLookup ?? useLinkLookup() };
  const types = collectionStore.useCollectionActivityTypes();
  const currentCollection = computed(() => {
    const c = toValue(collection);
    switch (typeof c) {
      case "object":
        return c;
      case "string":
        return lookups.entity(c);
    }
    return undefined;
  });

  const currentCollectionId = computed(() => {
    const c = toValue(collection);
    switch (typeof c) {
      case "object":
        return c instanceof Collection ? c.id : undefined;
      case "string":
        return c;
    }
    return undefined;
  });

  const currentRecordId = computed(() => {
    const r = toValue(record);
    switch (typeof r) {
      case "object":
        return currentCollection.value?.getId(r);
      case "string":
        return r;
    }
    return undefined;
  });

  return computed<FormattedActivity[]>(() =>
    unref(results).map((a: Activity) => {
      let type = types.value.find((x) => x.key === a.type && x.collectionId === a.collectionId);
      type ??= types.value.find((x) => x.key === a.type && x.collectionId === undefined);

      const parts = formatActivityContent(a, currentCollection.value, currentRecordId.value, lookups);
      return {
        ...a,
        parts,
        typeLabel: type?.label ?? a.type,
        type: a.type,
        display: type?.display && !a.email ? type.display : "expandable",
        icon: type?.icon ?? "note",
        colour: type?.colour ?? "blue",
        isEditable: type?.isEditable ?? false,
        parentLabel: a.collectionId === currentCollectionId.value ? undefined : a.recordLabel,
        parentLink: a.collectionId === currentCollectionId.value ? undefined : lookups.link(a),
        createdByName: getPersonNameFromUserId(a.createdBy)
      };
    })
  );
};
