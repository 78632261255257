<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div ref="autocompleteElement" class="form-control-container autocomplete autocomplete-multi" :class="{ 'no-label': !label }" tabindex="-1">
    <k-label v-if="!config.hideLabel" :id="uuid" :label />
    <div :class="classes" @click="onClick" @keydown.enter="onClick">
      <template v-if="config.disabled">
        <span v-for="idx in selectedIndices" :key="idx" disabled>{{ options[idx].label ?? options[idx].value }}</span>
      </template>
      <template v-else>
        <span
          v-for="idx in selectedIndices"
          :key="idx"
          title="Click to remove"
          role="button"
          class="enabled"
          tabindex="0"
          @click="removeItem($event, options[idx])"
          @keydown.enter="removeItem($event, options[idx])"
          >{{ options[idx].label ?? options[idx].value }}</span
        >
      </template>
      <input
        :id="uuid"
        ref="searchElement"
        v-model="search"
        autocomplete="off"
        type="text"
        v-bind="commonBindings(config, label)"
        :placeholder="!selectedIndices.length ? config.placeholder || label : ''"
        :aria-label="label"
        @focus="isFocused = true"
        @blur="onBlur"
        @click="onClick"
        @input="onChange"
        @keyup.down="onArrowDown"
        @keyup.up="onArrowUp"
        @keyup.enter="onEnter"
        @keydown="onKeyDown" />
      <k-spinner v-if="addLoading" size="sm" tabindex="-1" class="clear-field me-4" show-instantly />
      <button v-if="!config.disabled" type="button" tabindex="-1" class="clear-field" title="Clear" @click="clearField($event)">
        <i class="fa fa-close"></i>
      </button>
    </div>

    <ul ref="autocompleteResults" :class="{ show: isOpen, secondary: hasSecondaries }" class="dropdown-menu autocomplete-results">
      <li v-if="loading" class="dropdown-item loading"><k-spinner size="sm" class="me-2" show-instantly /> Loading results...</li>
      <template v-else>
        <li v-if="canAdd && search.length > 0 && !options.some((op) => op.label === search)">
          <div class="ml-1 dropdown-item text-secondary" @click="addOption">
            <i class="far fa-fw fa-plus me-1"></i>Add: <b>{{ search }}</b>
          </div>
        </li>
        <li v-if="showResultsMessage" class="dropdown-item disabled">{{ noResultsMessage }}</li>
        <li
          v-for="(item, i) in searchResults"
          v-else
          :key="item.value"
          class="dropdown-item"
          :class="{ highlighted: i === arrowCounter, active: input.includes(item.value) }"
          @click.meta="selectResult(item, true)"
          @click.ctrl="selectResult(item, true)"
          @click.exact="selectResult(item)">
          {{ item.label ?? item.value }}
          <small v-if="item.secondaryLabel" class="autocomplete-secondary">
            {{ item.secondaryLabel }}
          </small>
        </li>
        <li v-if="hasMore" class="dropdown-item disabled">Search for more results...</li></template
      >
    </ul>

    <issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { useEventListener } from "@vueuse/core";
import { v4 } from "uuid";

import IssueDisplay from "../KIssueDisplay.vue";
import { getInputClasses, useInputConfig, commonBindings } from "../inputConfig";

import KSpinner from "@ui/progress/KSpinner.vue";
import KLabel from "@ui/label/KLabel.vue";

import { deepEquals } from "@data/helpers/manipulation/Comparison";

import type { MultiselectItem } from "./SelectItem";

const props = defineProps<{
  /** Current value of the input */
  modelValue: readonly string[] | undefined;
  /** Label for the input */
  label?: string;
  /** Whether autocomplete results are loading */
  loading?: boolean;
  /** Whether it's waiting to add a new item */
  addLoading?: boolean;
  /** Whether more results are available via searching */
  hasMore?: boolean;
  /** Options to show */
  options: MultiselectItem[];
  /** Custom search filter to use */
  searchFilter?: (value: MultiselectItem, filterValue: string) => boolean;
  /** Can add a new item if it dosn't yet exist */
  canAdd?: boolean;
}>();

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", newValue: readonly string[]): void;
  (event: "showRelatedRecordForm", value: string): void;
  (event: "updateOptions", newOpt: string): void;
}>();

const search = defineModel<string>("search", { default: "" });

const config = useInputConfig();

const autocompleteElement = ref<HTMLElement>();
const searchElement = ref<HTMLInputElement>();
const autocompleteResults = ref<HTMLDivElement>();
const isOpen = ref(false);
const isFocused = ref(false);
const arrowCounter = ref(-1);

const input = ref<readonly string[]>([]);

watch(
  () => props.modelValue,
  (newValue) => {
    if (!deepEquals(newValue ?? [], input.value)) {
      input.value = newValue ?? [];
    }
  },
  { immediate: true, deep: true }
);

watch(
  input,
  (newValue) => {
    if (!deepEquals(newValue, props.modelValue)) {
      emit("update:modelValue", newValue);
    }
  },
  { deep: true }
);

const addLoading = computed(() => props.addLoading);

const selectedIndices = computed(() => input.value.map((value) => props.options.findIndex((item) => item.value === value)).filter((idx) => idx !== -1));

const addOption = () => {
  if (addLoading.value) return;
  if (search.value) {
    emit("updateOptions", search.value);
    emit("showRelatedRecordForm", search.value);
    search.value = "";
    isOpen.value = false;
  }
};

const readonly = computed(() => config.value.readonly);

const includes = (toSearch: string, target: string): boolean => toSearch.toLowerCase().includes(target.toLowerCase());

const searchResults = computed(() =>
  props.options.filter((item) => {
    if (input.value.includes(item.value)) {
      return false;
    }

    // Use custom search filter function if it exists
    if (props.searchFilter) {
      return props.searchFilter(item, search.value ? search.value.toLowerCase() : "");
    }

    return includes(item.label ?? item.value, search.value) || (item.secondaryLabel && includes(item.secondaryLabel, search.value));
  })
);

const noResultsMessage = computed(() => {
  if (input.value.length >= props.options.length || (searchResults.value.length === 0 && !search.value)) {
    return "No items left";
  }
  return "No results found";
});

const showResultsMessage = computed(() => {
  if (searchResults.value.length === 0 && !props.canAdd) return true;
  if (searchResults.value.length === 0 && props.options.some((o) => o.label === search.value)) return true;
  if (!props.options.length && !input.value.length && !search.value) return true;
  if (searchResults.value.length === 0 && !search.value) return true;
  return false;
});

watch(
  () => props.options,
  (newOptions) => {
    const newValues = new Set(newOptions.map((item) => item.value));
    const filtered = input.value.filter((value) => newValues.has(value));
    if (filtered.length !== input.value.length) {
      input.value = filtered;
    }
  },
  { deep: true }
);

// Handle user selection of a result in the dropdown
const selectResult = (item: MultiselectItem, isMultiSelect?: boolean) => {
  if (readonly.value) {
    return;
  }
  if (!isMultiSelect) isOpen.value = false;
  if (!input.value.includes(item.value)) {
    input.value = input.value.concat(item.value);
  }
  search.value = "";

  searchElement.value?.focus();
};

// Handle search input changes
const onChange = () => {
  if (readonly.value) {
    return;
  }
  // Let's search our array
  isOpen.value = true;
  if (search.value) {
    arrowCounter.value = 0;
  }
};

// clears field text, index value and calls onChange function
const clearField = (event?: MouseEvent) => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  search.value = "";
  arrowCounter.value = -1;
  input.value = [];
  onChange();
};

// Handle clicking on a result
const onClick = () => {
  if (readonly.value) {
    return;
  }

  onChange();

  searchElement.value?.select();
  searchElement.value?.setSelectionRange(0, search.value ? search.value.length : 100);

  if (search.value !== "") {
    isOpen.value = true;
    emit("focus");
  }
};

// Handle arrow keys
const hasSecondaries = computed(() => {
  if (props.loading || !searchResults.value.length) {
    return false;
  }
  return props.options.some((item) => item.secondaryLabel);
});
const adjustment = computed(() => (hasSecondaries.value ? 48 : 30));
const visibleItems = computed(() => (hasSecondaries.value ? 2 : 4));

const onArrowDown = () => {
  if (readonly.value) {
    return;
  }

  if (arrowCounter.value < searchResults.value.length - 1) {
    arrowCounter.value += 1;

    if (arrowCounter.value > visibleItems.value && autocompleteResults.value) {
      const pos = arrowCounter.value * adjustment.value - visibleItems.value * adjustment.value;
      autocompleteResults.value.scrollTop = pos > 0 ? pos : 0;
    }
  }
};

const onArrowUp = () => {
  if (readonly.value) {
    return;
  }

  if (arrowCounter.value > 0) {
    arrowCounter.value -= 1;

    const pos = arrowCounter.value * adjustment.value - visibleItems.value * adjustment.value;
    if (autocompleteResults.value) {
      autocompleteResults.value.scrollTop = pos > 0 ? pos : 0;
    }
  }
};

const onKeyDown = (e: KeyboardEvent) => {
  if (search.value && search.value.length > 0) {
    return;
  }
  if (e.key === "Backspace" && input.value.length > 0) {
    input.value = input.value.slice(0, -1);
  }
};

// Handle pressing "Enter" on a selected item
const onEnter = (e: KeyboardEvent) => {
  e.preventDefault();
  e.stopPropagation();

  if (readonly.value) {
    return;
  }

  const index = arrowCounter.value;
  if (index >= 0 && index < searchResults.value.length) {
    selectResult(searchResults.value[index]);
    search.value = "";
  }
  isOpen.value = false;
  arrowCounter.value = -1;
};

// Handle clicking outside the autocomplete element, dismiss dropdown
useEventListener(document, "click", (evt: MouseEvent) => {
  if (readonly.value) {
    return;
  }

  if (!autocompleteElement.value?.contains(evt.target as Node)) {
    isOpen.value = false;
    emit("blur");
  }
});

// Handle tabbing away from the current field
const onBlur = (evt: FocusEvent) => {
  if (!autocompleteElement.value?.contains(evt.relatedTarget as Node)) {
    isOpen.value = false;
    isFocused.value = false;
    emit("blur");
  }
};

const removeItem = (event: MouseEvent | KeyboardEvent, toRemove: MultiselectItem) => {
  event.preventDefault();
  event.stopPropagation();
  input.value = input.value.filter((item) => item !== toRemove.value);
};

// Setup CSS classes to handle states
const classes = computed(() => ({
  "dropdown-toggle": true,
  "has-items": selectedIndices.value.length > 0,
  "no-items": selectedIndices.value.length > 0,
  focus: isFocused.value || isOpen.value,
  ...getInputClasses(config.value, "select")
}));
const uuid = v4();
</script>

<style scoped lang="scss">
.autocomplete-multi .dropdown-toggle {
  padding-right: 30px;
  height: auto;
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  padding-left: 6px;
  position: relative;
  &.has-items {
    padding-left: 4px;
  }
  &::after {
    display: none;
  }
  input {
    display: inline-block;
    max-width: 100%;
    flex-grow: 1;
    z-index: 1;
    border: 1px solid transparent;
    border-left: none;
    outline: none;
    background: transparent;
    padding: 0;
    margin-right: 25px;
  }
  span {
    display: inline-block;
    padding: 0 6px;
    margin-top: 2px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-right: 4px;
    height: 22px;
    line-height: 22px;
    &.enabled {
      user-select: none;
      cursor: pointer;
      &:hover {
        background: rgba(0, 0, 0, 0.15);
      }
      &:active {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.autocomplete-multi .clear-field {
  top: calc(50% - 12px);
}

.add-record-button {
  height: fit-content;
  padding: 0 !important;
  margin-left: -4px;
}
</style>
