<!-- eslint-disable vuejs-accessibility/no-autofocus -->
<template>
  <form ref="form" @submit.prevent="onSubmit">
    <template v-for="(container, containerIndex) in filledLayout" :key="containerIndex">
      <div v-if="container.display === 'tabs'" class="form-control-container">
        <k-tab-view full-width :update-route="false">
          <k-tab v-for="(tab, tabIndex) in container.tabs" :id="tab.name" :key="tabIndex" :title="tab.name">
            <div class="vertical-scroll py-sm-3 pe-2 border-bottom" :style="{ height: container.tabContentHeight }">
              <template v-for="(tabContainer, tabContainerIndex) in tab.containers" :key="tabContainerIndex">
                <!-- REPEATED START EXCEPT: focus-on-mount condition-->
                <div :class="{ 'd-flex': tabContainer.display === 'inline', 'form-control-container': true }">
                  <template v-for="(formField, formFieldIndex) in tabContainer.fields" :key="formFieldIndex">
                    <k-field-input
                      v-model="formValue[formField.field.key]"
                      :show-issues-lazy="showErrors == 'lazy'"
                      :autofocus="autofocus && containerIndex === 0 && tabIndex === 0 && tabContainerIndex === 0 && formFieldIndex === 0"
                      :field="formField.field"
                      :readonly="readonly || formField.props?.readonly"
                      :required="formField.props?.required"
                      :entity
                      :mode="isEditing ? 'edit' : 'add'"
                      :is-inline="tabContainer.display === 'inline'"
                      v-bind="formField.props ?? {}"
                      :issues="showErrors ? issues.get(formField.field.id) : []" />
                    <!-- Slot used for passing buttons into the form -->
                    <slot :name="`after:${formField.field.id}`" />
                  </template>
                </div>
                <!-- REPEATED END -->
              </template>
            </div>
          </k-tab>
        </k-tab-view>
      </div>
      <div v-else :class="{ 'd-flex': container.display === 'inline', 'form-control-container': true }">
        <!-- REPEATED START -->
        <template v-for="(formField, formFieldIndex) in container.fields" :key="formFieldIndex">
          <k-field-input
            v-model="formValue[formField.field.key]"
            :show-issues-lazy="showErrors == 'lazy'"
            :autofocus="autofocus && containerIndex === 0 && formFieldIndex === 0"
            :field="formField.field"
            :readonly="readonly || formField.props?.readonly"
            :required="formField.props?.required"
            :entity
            :record="formValue"
            :mode="isEditing ? 'edit' : 'add'"
            :is-inline="container.display === 'inline'"
            v-bind="formField.props ?? {}"
            :issues="showErrors ? issues.get(formField.field.id) : issues.get(formField.field.id)?.filter((i) => i.severity === 'warning')" />
          <!-- Slot used for passing buttons into the form -->
          <slot :name="`after:${formField.field.id}`" />
        </template>
      </div>
      <!-- REPEATED END -->
    </template>
    <div class="form-control-container">
      <slot name="end" />
    </div>
  </form>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { manageObjectVModel } from "../../helpers/vModel";

import KTab from "@ui/tabs/KTab.vue";
import KTabView from "@ui/tabs/KTabView.vue";

import type { KEntity } from "@data/data/KEntity";
import type { KRecord } from "@data/data/Record";
import type { BooleanExpression } from "@data/expressions/expressions";
import { getFieldVisibleExpression } from "@data/expressions/helpers";
import type { ParsedValidator } from "@data/validation/ConditionalValidation";
import { parseValidators, runValidators } from "@data/validation/ConditionalValidation";
import type { ValidationIssues } from "@data/validation/Validation";
import { getMaxSeverity, validate } from "@data/validation/Validation";

import { useKContext } from "@/expressions/context";
import KFieldInput from "@/components/inputs/KFieldInput.vue";

import type { KFormField, KFormLayout, KFormContainerSchema, PrefilledField } from "./FormTypes";

const props = withDefaults(
  defineProps<{
    /** Form fields to use */
    fields: KFormField[];
    /** Defines how the provided fields should be arranged (in inlines blocks and tabs) */
    layout?: KFormLayout<string>;
    /** Entity to pass down to KInputFields */
    entity?: KEntity;
    /** Live value of the edited/new record */
    modelValue: KRecord;
    /** Whether the form is being edited */
    isEditing?: boolean;
    /** Sets all field inputs to readonly */
    readonly?: boolean;
    /** Whether to show validation errors, if "lazy", errors are shown only when an element has lost focus. Always shows warnings */
    showErrors?: boolean | "lazy";
    /** Prefill fields with values */
    prefillFields?: PrefilledField[];
    /** Boolean indicating whether to autofocus the first field of the form when it opens */
    autofocus?: boolean;
  }>(),
  {
    isEditing: true,
    prefillFields: () => [],
    showErrors: false,
    entity: undefined,
    layout: undefined,
    showErrorsLazy: false,
    autofocus: false
  }
);

const emit = defineEmits<{
  (event: "update:modelValue", value: KRecord): void;
  (event: "update:valid", value: boolean): void;
  (event: "submit"): void;
}>();

const recordlessContext = useKContext(computed(() => ({ entity: props.entity, state: { editing: props.isEditing } })));

// Init initial form values
const prefilledFields = computed<KRecord>(() => {
  const prefilled: KRecord = {};
  for (const { field } of props.fields) {
    const defaultValue = field.getDefaultValue?.(recordlessContext.value);
    if (defaultValue !== undefined) {
      prefilled[field.key] = defaultValue;
    }
  }
  for (const { key, value } of props.prefillFields) {
    prefilled[key] = value;
  }
  return prefilled;
});

const formValue = manageObjectVModel(
  computed(() => props.modelValue),
  emit,
  prefilledFields
);

const parseContext = useKContext(computed(() => ({ entity: props.entity })));
const context = useKContext(
  computed(() => ({ entity: props.entity, record: formValue.value, state: { editing: props.isEditing } })),
  // passing true here to provide to children (used in various Kinabase input components)
  true
);

const fieldVisibleExpressions = computed<Map<string, BooleanExpression>>(() => {
  const result = new Map<string, BooleanExpression>();
  for (const { field } of props.fields) {
    result.set(field.id, getFieldVisibleExpression(parseContext.value, field));
  }
  return result;
});

const visibleFields = computed(() => props.fields.filter(({ field }) => fieldVisibleExpressions.value.get(field.id)?.evaluate(context.value)));

const parsedValidators = computed(() => {
  const validatorMap = new Map<string, ParsedValidator[]>();
  for (const { field, validator } of visibleFields.value) {
    if (Array.isArray(validator)) {
      validatorMap.set(field.id, parseValidators(validator, props.entity));
    }
  }
  return validatorMap;
});

const issues = computed<Map<string, ValidationIssues>>(
  () =>
    new Map(
      visibleFields.value.map(({ field, validator }) => {
        const baseIssues = field.validate?.(formValue.value) ?? [];
        if (Array.isArray(validator)) {
          const parsed = parsedValidators.value.get(field.id);
          return [field.id, baseIssues.concat(runValidators(parsed ?? [], { entity: props.entity, record: formValue.value, field }))];
        } else {
          return [field.id, baseIssues.concat(validate(validator, field.getValue(formValue.value), formValue.value))];
        }
      })
    )
);

const isValid = computed(() => {
  for (const i of issues.value.values()) {
    if (getMaxSeverity(i) === "error") {
      return false;
    }
  }
  return true;
});

function getFilledContainer(formFields: KFormField[], container: KFormContainerSchema<string>) {
  const filledFields: KFormField[] = [];
  for (const fieldId of container.fields) {
    const matchingFormField = formFields.find((formField) => formField.field.id === fieldId);
    if (matchingFormField) {
      filledFields.push(matchingFormField);
    }
  }
  return { ...container, fields: filledFields };
}

const filledLayout = computed(() => {
  const formFields = visibleFields.value.filter((f) => !props.prefillFields.some((p) => p.key === f.field.key && p.mode === "hidden"));
  for (const formField of formFields) {
    // Make field readonly if its prefill value is not editable
    if (props.prefillFields.some((p) => p.key === formField.field.key && p.mode !== "editable")) {
      if (!formField.props) formField.props = {};
      formField.props.readonly = true;
    }
  }

  const layoutResult: KFormLayout<KFormField> = [];
  if (props.layout) {
    for (const container of props.layout) {
      if (container.display === "tabs") {
        const filledTabs = container.tabs.map((tab) => ({
          ...tab,
          containers: tab.containers.map((tabContainer) => getFilledContainer(formFields, tabContainer))
        }));
        layoutResult.push({ ...container, tabs: filledTabs });
      } else {
        layoutResult.push(getFilledContainer(formFields, container));
      }
    }
  } else {
    layoutResult.push({
      display: "block",
      fields: formFields
    });
  }
  return layoutResult;
});

watch(isValid, (v) => emit("update:valid", v), { immediate: true });

const onSubmit = () => {
  emit("submit");
};

const form = ref<HTMLFormElement | null>(null);
defineExpose({
  focus() {
    setTimeout(() => {
      // Focus first field in form after it's shown
      form.value?.querySelector("input")?.focus();
    }, 200);
  }
});
</script>

<style scoped>
.vertical-scroll {
  overflow-y: auto;
}
</style>
