import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";

export type FeedbackInput = {
  description: string;
  type: "SUGGESTION" | "PROBLEM" | "QUESTION" | "TESTIMONIAL" | "OTHER";
};

const useAddFeedback = () =>
  useMutation<boolean, { item: FeedbackInput }>(gql`
    mutation addAppRegistration($item: FeedbackInput!) {
      submitFeedback(item: $item)
    }
  `);

export const FeedbackAPI = {
  useAddFeedback
};
