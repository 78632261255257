import { computed, toValue } from "vue";
import type { MaybeRef, Ref } from "vue";

import type { Collection } from "@data/data/Collection";
import type { KEntity } from "@data/data/KEntity";
import type { KRecord } from "@data/data/Record";
import type { KContext } from "@data/expressions/context";
import { InsufficientContextError } from "@data/expressions/context";
import type { BooleanExpression } from "@data/expressions/expressions";
import type { KField } from "@data/fields/KField";
import { deepEquals } from "@data/helpers/manipulation/Comparison";
import { getFieldVisibleExpression, safeEvaluateExpression, safeParseBooleanExpression } from "@data/expressions/helpers";
import type { FilterablePermission, PermissionsKey } from "@data/helpers/data/Permissions";
import { getCollectionPermissions } from "@data/helpers/data/Permissions";

import { useIsAdmin } from "@/services/auth";
import { useKContext } from "@/expressions/context";
import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { useInitialState } from "@/api/initial";

export const useHasPermissions = (permissions: MaybeRef<string[]>, context?: Ref<KContext>) => {
  const actualContext = context ?? useKContext();
  const parsed = computed(() => toValue(permissions).map((p) => safeParseBooleanExpression(p, actualContext.value, true)));
  return computed(() => parsed.value.some((p) => safeEvaluateExpression(p, actualContext.value, false)));
};

export const useHasPeoplePermission = (permission: "read") => {
  const initialState = useInitialState();
  const permissions = computed(() => initialState.peoplePermissions[permission].value);
  const admin = useIsAdmin();
  const hasPermission = useHasPermissions(permissions);
  return computed(() => admin.value || hasPermission.value);
};

export const useHasCollectionPermission = (collection: Ref<Collection | undefined>, permission: PermissionsKey, extraContext?: Ref<KContext>) => {
  const context = useKContext(computed(() => (extraContext ? { ...extraContext.value, entity: collection.value } : { entity: collection.value })));
  const admin = useIsAdmin();
  const effectivePermission = computed(() => {
    if (!collection.value) return [];
    return getCollectionPermissions(collection.value, permission, context.value.record);
  });
  const expressions = computed(() => effectivePermission.value.map((p) => safeParseBooleanExpression(p, context.value, true)));
  const hasPermission = computed(() => {
    if (permission === "config" && admin.value) return true;
    return expressions.value.some((e) => safeEvaluateExpression(e, context.value, true, false));
  });
  return hasPermission;
};

export const usePermissionFunction = (
  collection: Ref<Collection | undefined>,
  permission: "read" | "write" | "activityRead" | "activityWrite",
  extraContext?: Ref<KContext>
): ((r: KRecord) => boolean) => {
  const context = useKContext(computed(() => (extraContext ? { ...extraContext.value, entity: collection.value } : { entity: collection.value })));
  const effectivePermission = computed(() => {
    if (!collection.value) return [];
    return getCollectionPermissions(collection.value, permission, context.value.record);
  });
  const expressions = computed(() => effectivePermission.value.map((p) => safeParseBooleanExpression(p, context.value, true)));
  return (r: KRecord) => {
    const effectiveContext = { ...context.value, record: r };
    return expressions.value.some((e) => safeEvaluateExpression(e, effectiveContext, true));
  };
};

// Filter multiple collections by permission
export const useCollectionsWithPermission = (permission: MaybeRef<FilterablePermission | undefined>, overrideCollections?: Ref<Collection[] | undefined>) => {
  const store = useCollectionStore();
  const admin = useIsAdmin();
  const context = useKContext();
  const collections = computed(() => overrideCollections?.value ?? store.collections ?? []);

  const expressions = computed<Map<string, BooleanExpression[]>>(() => {
    const map = new Map<string, BooleanExpression[]>();
    const pv = toValue(permission);
    if (!pv) return map;
    for (const collection of collections.value) {
      if (collection.permissions.listVisibility) {
        const parsed = collection.permissions.listVisibility.map((p) => safeParseBooleanExpression(p, { ...context.value, entity: collection }, true));
        map.set(collection.id, parsed);
        continue;
      }

      const permissions = getCollectionPermissions(collection, pv);
      const parsed = permissions.map((p) => safeParseBooleanExpression(p, { ...context.value, entity: collection }, true));
      map.set(collection.id, parsed);
    }
    return map;
  });

  return computed<Collection[]>(() => {
    if (!toValue(permission) || (admin.value && toValue(permission) === "config")) return collections.value;

    return collections.value.filter((c) => {
      try {
        const parsed = expressions.value.get(c.id);
        if (!parsed) return false;
        return parsed.some((e) => e.evaluate({ ...context.value, entity: c }));
      } catch (e) {
        if (e instanceof InsufficientContextError) {
          return true;
        }
      }
    });
  });
};

export const useVisibleFields = (entity: Ref<KEntity>, fields?: Ref<readonly KField[]>): Ref<KField[]> => {
  const context = useKContext(computed(() => ({ entity: entity.value })));
  const baseFields = computed(() => fields?.value ?? entity.value.fields);
  const fieldVisibleExpressions = computed<Map<string, BooleanExpression>>(() => {
    const result = new Map<string, BooleanExpression>();
    for (const field of baseFields.value) {
      result.set(field.id, getFieldVisibleExpression(context.value, field));
    }
    return result;
  });

  return computed(() =>
    baseFields.value.filter((f) => {
      try {
        return fieldVisibleExpressions.value.get(f.id)?.evaluate(context.value);
      } catch (e) {
        // check that it's just missing record
        if (e instanceof InsufficientContextError && deepEquals(e.missing, ["record"])) {
          return true;
        }
        throw e;
      }
    })
  );
};
