<template>
  <div class="d-flex flex-column w-75 mx-auto start-panel">
    <h4 class="text-center mt-5">What would you like to use Kinabase for?</h4>
    <div class="pt-2">
      <k-input ref="searchBoxElement" v-model="search" class="ai-input" hide-label placeholder="Type here..." @keydown="onKeyDown" @input="debouncedFn" />
      <ul class="dropdown-menu w-75" :class="{ show: search.length > 0 }">
        <li v-if="showSuggestions" class="dropdown-header py-1">Suggestions</li>
        <template v-if="showSuggestions">
          <li class="dropdown-item text-wrap" @click="changePage('SUGGESTED')">
            <i class="far fa-bolt me-2 text-teal"></i>Add collections for
            <b>{{
              generatedNames
                .map(({ name }) => name.toLocaleLowerCase())
                .toString()
                .replaceAll(",", ", ")
            }}</b>
          </li>
        </template>
        <template v-else>
          <li class="dropdown-item disabled text-wrap gap-3 text-secondary"><i class="far fa-bolt me-2 text-teal"></i>Generating AI Suggestions...</li>
        </template>
        <li v-for="(name, i) in possibleCollectionNames" :key="i" class="dropdown-item" @click="changePage('MANUAL-SINGLE', name)">
          <i class="fa fa-plus me-2"></i>Add <b>{{ name }}</b> collection
        </li>

        <li class="dropdown-header pt-2 mb-0">Add manually</li>
        <div v-if="search.length > 0" class="pb-2 px-2">
          <li class="dropdown-item rounded d-inline border px-2 py-1 w-50" @click="changePage('MANUAL-SINGLE', search)">
            <i class="far fa-plus fa-fw"></i><small>Add collection</small>
          </li>
          <li class="dropdown-item rounded d-inline border px-2 py-1 w-50" @click="changePage('MANUAL-MULTIPLE')">
            <i class="far fa-rectangle-history-circle-plus fa-fw me-1"></i><small>Add multiple collections</small>
          </li>
        </div>
      </ul>
      <p class="mt-3 text-secondary text-center">For example, try "client management" or "organising IT equipment"</p>
    </div>
    <div class="text-center mt-5">
      <div class="mb-4">
        Create collections to organise and track what's important to you.<br />
        Use our AI-powered search above to get started.
      </div>
      <div v-if="!search.length">
        <div class="dropdown-item rounded d-inline border px-2 py-1 w-50" @click="changePage('MANUAL-SINGLE')">
          <i class="far fa-plus fa-fw"></i><small>Add collection</small>
        </div>
        <div class="dropdown-item rounded d-inline border px-2 py-1 w-50" @click="changePage('MANUAL-MULTIPLE')">
          <i class="far fa-rectangle-history-circle-plus fa-fw me-1"></i><small>Add multiple collections</small>
        </div>
      </div>
    </div>
    <div v-if="showSuggestions" class="text-secondary text-center mt-auto pb-3">
      <small>Please note suggestions are AI generated and may contain errors or inaccuracies</small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted, onBeforeUnmount } from "vue";

import { useDebounceFn } from "@vueuse/core";
import { v4 as uuidv4 } from "uuid";

import KInput from "@ui/inputs/strings/KInput.vue";

import { CollectionNamesGenerator } from "./generators/CollectionNamesGenerator";
import { extractCollectionNames } from "./helpers/extractNames";

import { Collection } from "@data/data/Collection";
import { toSingular } from "@data/helpers/strings/Plurals";

import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { toTitleCase } from "@/helpers/useCollectionNameVariants";
type PageType = "SUGGESTED" | "MANUAL-SINGLE" | "MANUAL-MULTIPLE";
const props = defineProps<{
  /** The value of the input */
  prompt?: string;
  /** The initial collections to show */
  collections?: Collection[];
}>();
const emit = defineEmits<{
  (event: "changePage", page: PageType, collectionName?: string): void;
  (event: "update:prompt", value: string): void;
  (event: "update:collections", value: Collection[]): void;
}>();
const { collections: existingCollections } = useCollectionStore();
const generator = new CollectionNamesGenerator(props.collections, existingCollections);
const search = ref("");
watch(
  () => props.prompt,
  (val) => {
    if (val) search.value = val;
  },
  { immediate: true }
);
const searchBoxElement = ref<{ $el: HTMLElement }>();
onMounted(() => {
  // Delay to give modal time to render, focus search box
  setTimeout(() => {
    if (!searchBoxElement.value) return;
    searchBoxElement.value.$el.getElementsByTagName("input")[0].focus();
  }, 200);
});
onBeforeUnmount(() => {
  generator.stop();
});
const onKeyDown = (e: KeyboardEvent) => {
  emit("update:prompt", search.value);
  if (e.key === "Enter" && search.value !== "") {
    void generator.generateCollectionNames(search.value);
  } else if (!search.value) {
    generator.clear();
  }
};
// Automatically search after timeout after user stops typing
const unchangedSearchTimeForGen_ms = 1500;
let unchangedSearchTimeout: ReturnType<typeof setTimeout>;
watch(search, () => {
  clearInterval(unchangedSearchTimeout);
  unchangedSearchTimeout = setTimeout(() => {
    emit("update:prompt", search.value);
    void generator.generateCollectionNames(search.value);
  }, unchangedSearchTimeForGen_ms);
});
const generatedNames = computed(() => generator.collectionNames.value);
const showSuggestions = computed(() => generator.collectionNames.value.length > 0);
// ===== PAGE MANAGEMENT =====
const changePage = (page: PageType, collectionName?: string) => {
  if (page === "SUGGESTED") {
    const collections = generator.collectionNames.value.map(
      (col) => new Collection(uuidv4(), toSingular(toTitleCase(col.name)), [], { icon: col.icon, activities: { isEnabled: true } })
    );
    emit("update:collections", collections);
  }
  emit("changePage", page, collectionName);
};
const collectionStore = useCollectionStore();
const possibleCollectionNames = ref<string[]>([]);
const debouncedFn = useDebounceFn(() => {
  possibleCollectionNames.value = extractCollectionNames(search.value).filter(
    (x) => !collectionStore.collections?.find((y) => y.plural.toLowerCase() === x.toLowerCase())
  );
}, 250);
</script>

<style scoped>
.start-panel {
  height: 400px;
}
.link-card {
  box-shadow: 1px 1px 4px var(--k-color-gray);
  border-radius: var(--bs-border-radius-sm);
  padding: 0.2rem 0.5rem;
}
.link-card:hover {
  box-shadow: 1px 1px 4px var(--k-color);
}
.ai-input :deep(input) {
  box-shadow:
    1px 1px 4px rgba(0, 205, 137, 0.5),
    -1px -1px 4px rgba(53, 138, 208, 0.5);
}
.ai-input :deep(input:focus) {
  box-shadow:
    1px 1px 8px rgba(0, 205, 137, 0.5),
    -1px -1px 8px rgba(53, 138, 208, 0.5);
}
.template-browser {
  max-height: 200px;
}
.kb-template {
  width: 200px;
  height: 100px;
  border: 1px solid var(--k-border);
  border-radius: 4px;
}
.template-overlay {
  position: sticky;
  width: 100%;
  bottom: -20px;
  margin-top: -180px;
  pointer-events: none;
  height: 200px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
</style>
