import { toCamelCase } from "../helpers/strings/Casing";
import { pickFrom } from "../helpers/manipulation/Objects";

import type { SortOption } from "../helpers/data/Sorting";
import type { ColourVariant } from "../types/ColourVariant";
import type { JsonSerialisable } from "../helpers/serialisation/TypedJSON";
import type { KContext } from "../expressions/context";
import type { TableCell } from "./TableCell";
import type { KFieldValue, KRecord, StoredKRecord } from "../data/Record";
import type { DeepPartial, Typed } from "../helpers/serialisation/Registry";
import type { ValidationIssues } from "../validation/Validation";

export type Sortable = string | number | Date | boolean | undefined | null;

/**
 * Type for readonly field values detached from their fields, containing limited information about the field it came from
 *
 * (e.g. for extra data logged against activities)
 */
export type StaticFieldValue = {
  field: {
    type: string;
    label: string;
    key: string;
    id: string;
  };
  rawValue?: JsonSerialisable;
  displayValue?: string;
  link?: string;
};

export type TableDisplayOptions = {
  hiddenByDefault?: boolean;
  defaultWidth?: number;
  secondaryField?: string;
};

export type FormOptions = {
  label?: string;
  showDescription?: boolean;
  ignore?: boolean; // whether the field should be shown in the form
  /** Sequential number to sort the fields by in form views */
  fieldOrder?: number;
};

/** Common setup options for a field */
export type KFieldSetup = {
  label: string;
  key?: string;
  id?: string;
  description?: string;
  serverControlled?: boolean;
  table?: TableDisplayOptions;
  form?: FormOptions;
  include?: string; // whether the field makes sense in the current context (e.g. mileage on an expense claim depends on the type of expense)
  permissions?: {
    read: string[]; // whether the field can be read
    write: string[]; // whether the field can be written
  };
};

export abstract class KField<V extends KFieldValue = KFieldValue> implements Typed {
  abstract readonly type: string;

  key: string;

  /** Unique id for the field, should never change after intialisation */
  id: string;

  setup: KFieldSetup;

  subfields: KField[] = [];

  sortFieldDirectionLabels?: Record<SortOption["direction"], string>;

  get label() {
    return this.setup.label;
  }

  get description() {
    return this.setup.description;
  }

  get serverControlled() {
    return this.setup.serverControlled;
  }

  get table(): TableDisplayOptions {
    return this.setup.table ?? {};
  }

  get form(): FormOptions {
    return this.setup.form ?? {};
  }

  get permissions() {
    return this.setup.permissions ?? { read: ["true"], write: ["true"] };
  }

  get include() {
    return this.setup.include;
  }

  /** @param setup Either full setup object, or just the label for the field */
  constructor(setup: KFieldSetup | string) {
    if (typeof setup === "string") {
      this.setup = { label: setup };
    } else {
      this.setup = setup;
    }
    this.key = this.setup.key ?? toCamelCase(this.label);
    this.id = this.setup.id ?? this.key;
  }

  equals(other: KField): boolean {
    return this.id === other.id;
  }

  abstract getValue(record: KRecord): V | undefined;

  abstract getSortableValue(record: KRecord, toLowerCase?: boolean): Sortable;

  abstract getDisplayValue(record: KRecord): string;

  getSearchValue?(record: KRecord): string | undefined;

  getDefaultValue?(context: KContext): V | undefined;

  validate?(_record: KRecord): ValidationIssues;

  abstract validateType(value: KFieldValue): value is V;

  abstract storeValue(source: KRecord, target: StoredKRecord): void;

  abstract restoreValue(source: StoredKRecord, target: KRecord): void;

  /** Get the value of this field as a string suitable for CSV files (will be escaped as necessary) (don't call this externally, use getCSVColumns instead) */
  getCSVValue(record: KRecord): string {
    return this.getDisplayValue(record);
  }

  /** Get CSV columns for this field - if called without a record, it just returns the header row */
  getCSVColumns(): { subheader: string }[];

  getCSVColumns(record: KRecord): { subheader: string; value: string }[];

  getCSVColumns(record?: KRecord) {
    if (record) {
      return [{ subheader: "", value: this.getCSVValue(record) }];
    }
    return [{ subheader: "" }];
  }

  getTableCell(record: KRecord): TableCell | undefined {
    return { type: "text", text: this.getDisplayValue(record) };
  }

  getStaticValue(record: KRecord): StaticFieldValue {
    return {
      field: {
        ...pickFrom(this, "type", "label", "key", "id")
      },
      displayValue: this.getDisplayValue(record)
    };
  }

  getSubfield(id: string): KField | undefined {
    return this.subfields.find((f) => f.id === id || f.key === id);
  }

  /**
   * Return the name for the field type
   *
   * (only needed if the field has different names depending on configuration, e.g. DatetimeField with different precisions)
   */
  getFieldTypeName?(): string;

  getColourVariant?(displayValue: string): ColourVariant | undefined;

  getDefaultColumnWidth() {
    return this.table.defaultWidth ?? 100;
  }

  isAssignableTo(otherField: KField) {
    return this.type === otherField.type;
  }
}

/**
 * Utility function to restore a field setup from a partial serialised object
 *
 * @param recieved Partial serialised object
 * @param restoreDefaultValue Function to restore the default value, pass if field stores values as different types (e.g. DateField), or uses objects with
 *   required fields (e.g. RiskField)
 * @returns
 */
export const restoreSetup = (recieved: DeepPartial<KFieldSetup>): KFieldSetup => {
  if (!recieved.label) {
    throw Error("Field label not found during deserialisation");
  }
  const permissions = recieved.permissions && {
    read: ["true"],
    write: ["true"],
    ...recieved.permissions
  };
  return {
    ...recieved,
    label: recieved.label,
    permissions
  };
};
