<template>
  <div :class="dropdownClasses">
    <button v-if="split" :id type="button" class="btn" :class="classes" :title="title ?? label" @click="onClick">
      <slot name="button">
        <span class="d-inline-block">
          <span class="btn-content">
            <i v-if="!!icon" :class="`fa fa-fw fa-${icon}`"></i>
            <span v-if="!hideLabel" class="btn-text">{{ label }}</span>
          </span></span
        >
      </slot>
    </button>
    <button
      :id
      type="button"
      class="btn dropdown-toggle dropdown-toggle-split"
      :class="classes"
      :disabled
      :title="title ?? label"
      :data-bs-toggle="modelValue === null ? 'dropdown' : undefined"
      :data-bs-display="modelValue === null ? 'static' : undefined"
      :aria-expanded="!!modelValue"
      :aria-disabled="disabled"
      :aria-pressed="pressed"
      @click="onToggle">
      <slot v-if="!split" name="button"
        ><span class="d-inline-block">
          <span class="btn-content">
            <k-icon v-if="!!icon" :icon />
            <span v-if="!hideLabel" class="btn-text">{{ label }}</span>
          </span></span
        >
      </slot>
    </button>
    <ul class="dropdown-menu" :class="{ show: modelValue, 'dropdown-menu-end': alignright }" :aria-labelledby="modelValue === null ? id : undefined">
      <slot></slot>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import KIcon from "@ui/icon/KIcon.vue";

import type { UIColourVariant, UIColourVariantWithOutlines } from "@data/types/ColourVariant";

const props = withDefaults(
  defineProps<{
    /** Button display label for dropdown */
    label?: string;
    /** Colour of button */
    variant?: UIColourVariant | UIColourVariantWithOutlines;
    /** Size of the input element */
    size?: "sm" | "md" | "lg";
    /** Boolean indicating whether dropdown should be a separate button to the right or the entire button */
    split?: boolean;
    /** Boolean indicating whether dropdown is pressable */
    disabled?: boolean;
    /** Boolean indicating if button should be in the pressed state */
    pressed?: boolean;
    /** Boolean indicating if loading spinner should be shown */
    loading?: boolean;
    /** Determines if menu should be right aligned */
    alignright?: boolean;
    /** Determines if label should be shown on button */
    hideLabel?: boolean;
    /** Icon to show in button */
    icon?: string;
    /** Determines if dropdown menu should appear above button */
    dropup?: boolean;
    /** Determines if dropdown menu should be at right of button */
    dropend?: boolean;
    /** Determines if dropdown menu should be at left of button */
    dropstart?: boolean;
    /** Determines if down arrow is shown */
    noCaret?: boolean;
    /** State of dropdown (whether it is open or not) */
    modelValue?: boolean | null;
    /** Title of dropdown */
    title?: string;
  }>(),
  {
    label: undefined,
    variant: "secondary",
    size: "sm",
    icon: "",
    modelValue: null,
    title: undefined
  }
);

const emit = defineEmits<{
  (event: "click"): void;
  (event: "update:modelValue", open: boolean): void;
}>();

let dropdownUuid = 0;

const onClick = (event: MouseEvent) => {
  if (props.disabled) {
    event.preventDefault();
  } else {
    emit("click");
  }
};

const onToggle = (event: MouseEvent) => {
  if (props.disabled) {
    event.preventDefault();
  } else {
    emit("update:modelValue", !props.modelValue);
    emit("click");
  }
};

const id = "_dd_" + dropdownUuid.toString();
dropdownUuid += 1;

const classes = computed(() => ({
  btn: true,
  [`btn-${props.size}`]: true,
  [`btn-${props.variant}`]: true,
  active: props.pressed,
  disabled: props.disabled || props.loading,
  "ps-2": props.loading,
  "dropdown-toggle-no-caret": props.noCaret
}));

const dropdownClasses = computed(() => ({
  dropup: props.dropup,
  dropend: props.dropend,
  dropstart: props.dropstart,
  "btn-group": props.split,
  dropdown: !props.split && !props.dropup
}));
</script>

<style scoped>
.btn-content {
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.25rem;
}

.btn-text {
  font-size: 0.8rem;
}
</style>
