<template>
  <div :class="{ 'tab-view': true, 'px-3': !fullWidth }">
    <div class="tabs">
      <k-button
        v-if="showMenuButton"
        variant="transparent"
        icon="ellipsis"
        class="tab-menu me-n1 float-end"
        title="Menu"
        @click="onRightClick($event, undefined, true)" />
      <ul ref="tabElement" v-dragscroll.x class="tab-header" role="tablist">
        <li
          v-for="(tab, i) in tabs"
          :key="i"
          class="tab-item"
          :class="{ disabled: tab.disabled, active: i == activeTab }"
          :tabindex="i == activeTab ? 0 : -1"
          role="tab"
          :aria-disabled="tab.disabled"
          @keydown.enter.prevent="setActiveTab(i)"
          @click.stop="setActiveTab(i)"
          @click.right.stop.prevent="onRightClick($event, i)">
          <a class="tab-link">
            <component :is="tab.content.children.title" v-if="tab.content.children && tab.content.children.title" />
            <template v-else>
              {{ tab.title }}
            </template>
          </a>
        </li>
        <k-button v-if="showAddButton" variant="transparent" icon="plus" class="tab-add ms-n2" title="Add tab" @click="onAddTab" />
      </ul>
      <div v-show="opacityLeft > 0" class="tab-overflow-left" :style="{ opacity: opacityLeft }">
        <i class="fa fa-chevron-left" title="Scroll left" role="button" @click="scroll(-1)" @keydown.enter="scroll(-1)"></i>
      </div>
      <div v-show="opacityRight > 0" class="tab-overflow-right" :style="{ opacity: opacityRight }" :class="{ 'show-menu': showMenuButton }">
        <i class="fa fa-chevron-right" title="Scroll right" role="button" @click="scroll(1)" @keydown.enter="scroll(1)"></i>
      </div>
      <div class="tab-content">
        <template v-for="(tab, i) in tabs" :key="i">
          <component :is="tab.content" v-if="activeTab == i" v-show="activeTab == i" :active="activeTab == i" />
        </template>
      </div>
    </div>
    <k-context-menu v-if="tabContextMenuItems && tabContextMenuItems.length > 0" ref="contextMenu" :items="tabContextMenuItems" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import type { Slot } from "vue";
import { computed, onBeforeMount, onMounted, watch, watchEffect } from "vue";
import { dragscroll as vDragscroll } from "vue-dragscroll";

import { useScroll, useElementSize, onKeyUp } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";

import { getChildrenOfType } from "@ui/helpers/slotHelpers";
import { hasContextItems, type ContextMenuItem } from "@ui/context-menu/ContextMenuItem";
import KButton from "@ui/button/KButton.vue";
import KContextMenu from "@ui/context-menu/KContextMenu.vue";

import KTab from "./KTab.vue";

import type { KRecord } from "@data/data/Record";

import type { TabProps } from "./TabProps";

const props = withDefaults(
  defineProps<{
    /** Context menu items */
    tabContextMenuItems?: ContextMenuItem<{ id: number }>[];
    /** Allow adding tabs */
    showAddButton?: boolean;
    /** Allow showing visible dropdown menu */
    showMenuButton?: boolean;
    /** Boolean indicating whether to remove the elements x-padding */
    fullWidth?: boolean;
    /** Determines whether to add query params to route */
    updateRoute?: boolean;
  }>(),
  {
    canClose: true,
    canNavigate: undefined,
    tabContextMenuItems: undefined,
    fullWidth: false,
    updateRoute: true
  }
);

const emit = defineEmits<{
  (e: "onClose"): void;
  (e: "onNavigate", direction: "next" | "prev"): void;
  (e: "onAdd", evt: MouseEvent): void;
}>();

const activeTab = defineModel<number>({ default: 0 });

const content = defineSlots<{ default: Slot }>();

const contextMenu = ref<{ show: (evt: Event, record?: KRecord, buttonTrigger?: boolean) => void }>();

const tabElement = ref<HTMLElement>();
const { x, arrivedState } = useScroll(tabElement);
const { width } = useElementSize(tabElement);

const tabs = computed(() =>
  getChildrenOfType<Partial<TabProps>>(content.default(), KTab).map<TabProps>((tab) => {
    if (!tab.props) {
      tab.props = {};
    }
    return {
      content: tab,
      id: tab.props.id,
      title: tab.props.title,
      disabled: tab.props.disabled === true,
      requiredPermission: tab.props.permission
    };
  })
);

const routeParam = useRouteQuery("t");

const setActiveTab = (index: number) => {
  if (index < 0 || index >= tabs.value.length) {
    return;
  }
  const tab = tabs.value[index];
  if (tab.disabled || !tab.id) {
    return;
  }
  if (props.updateRoute) {
    routeParam.value = tab.id;
  }
  activeTab.value = index;
};

onBeforeMount(() => {
  if (routeParam.value && props.updateRoute) {
    const index = tabs.value.findIndex((i) => i.id === routeParam.value);
    setActiveTab(index);
  }
});

watch([routeParam, tabs], () => {
  if (routeParam.value && props.updateRoute) {
    const index = tabs.value.findIndex((i) => i.id === routeParam.value);
    setActiveTab(index);
  }
});

const scroll = (direction: -1 | 1) => {
  tabElement.value?.scrollBy({
    left: 100 * direction,
    behavior: "smooth"
  });
};

onMounted(() => {
  onKeyUp(
    "ArrowLeft",
    () => {
      for (let i = activeTab.value - 1; i >= 0; i--) {
        if (!tabs.value[i].disabled) {
          setActiveTab(i);
          break;
        }
      }
    },
    { target: tabElement.value }
  );

  onKeyUp(
    "ArrowRight",
    () => {
      for (let i = activeTab.value + 1; i < tabs.value.length; i++) {
        if (!tabs.value[i].disabled) {
          setActiveTab(i);
          break;
        }
      }
    },
    { target: tabElement.value }
  );
});

const fadeThreshold = 10;
const opacityLeft = computed(() => {
  if (!tabs.value.length) {
    // Include tabs variable in here to recompute function when slots change
    return 0;
  }
  if (arrivedState.left) {
    return 0;
  }
  return x.value < fadeThreshold ? x.value / fadeThreshold : 1;
});
const opacityRight = computed(() => {
  if (!tabs.value.length) {
    // Include tabs variable in here to recompute function when slots change
    return 0;
  }
  const xVal = Math.floor((tabElement.value?.scrollWidth ?? 0) - width.value - x.value);
  if (xVal <= 0) {
    return 0;
  }
  return xVal < fadeThreshold ? xVal / fadeThreshold : 1;
});

const onRightClick = (evt: MouseEvent, tabIndex?: number, buttonTrigger?: boolean) => {
  const item = tabIndex ? { id: tabIndex } : undefined;
  if (hasContextItems(props.tabContextMenuItems, item)) {
    contextMenu.value?.show(evt, item, buttonTrigger);
  }
};

const onAddTab = (evt: MouseEvent) => {
  emit("onAdd", evt);
};

// if tabs removed, change to the last valid tab
watchEffect(() => {
  if (activeTab.value >= tabs.value.length) {
    setActiveTab(tabs.value.length - 1);
  }
});
</script>

<style scoped lang="scss">
.tab-view {
  flex-grow: 1;
  & :deep(.list-details) {
    margin: 0 -0.75rem;
    height: 100%;
  }
}
.tabs {
  display: block;
  position: relative;

  .tab-header {
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    scrollbar-width: none;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    .tab-item {
      margin: 0 1.4rem 0 0;
      display: inline-block;
      border-bottom: 2px solid transparent;
      transition:
        ease-in-out border 0.05s,
        outline-offset 0.05s ease-out;
      user-select: none;
      -webkit-user-select: none;
      cursor: default;
      position: relative;
      a {
        text-decoration: none;
        color: var(--k-color-secondary);
        font-weight: 600;
        line-height: 2.5rem;
        display: block;
        transition: ease-in-out color 0.05s;
        margin: 0 -0.4rem;
        padding: 0 0.4rem;
        cursor: default;
      }
      &:hover,
      &:focus {
        border-bottom: 2px solid var(--k-color-secondary);
        &.disabled {
          border-bottom: 2px solid transparent;
        }
      }
      &.active::after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        border-bottom: 2px solid var(--k-color-link);
        z-index: 1;
      }
      &.active {
        a {
          color: var(--k-color-link);
        }
      }
      &.disabled a {
        color: var(--k-input-disabled-border);
      }
    }
  }

  .tab-overflow-left,
  .tab-overflow-right {
    display: block;
    position: absolute;
    top: 1px;
    height: 40px;
    width: 50px;
    bottom: 0;
    opacity: 0;
    line-height: 40px;
    color: var(--k-color-secondary);
    pointer-events: none;
    i {
      pointer-events: all;
      opacity: 0.8;
    }
  }

  .tab-overflow-left {
    left: -1px;
    text-align: left;
    background: linear-gradient(-90deg, var(--k-background-translucent-100), var(--k-background) 85%);
    i {
      padding-right: 0.5rem;
    }
  }

  .tab-overflow-right {
    right: -1px;
    text-align: right;
    background: linear-gradient(90deg, var(--k-background-translucent-100), var(--k-background) 85%);
    i {
      padding-left: 0.5rem;
    }
  }

  .tab-overflow-right.show-menu {
    right: 40px;

    ::after {
      content: "";
      position: absolute;
      right: -10px;
      width: 10px;
      height: 40px;
      background-color: var(--k-background);
    }
  }

  .tab-content {
    height: calc(100% - 36px);
  }

  .tab-content::before {
    content: " ";
    border-bottom: 1px solid var(--k-border);
    width: 100%;
    display: block;
    height: 1px;
    margin-top: -1px;
    white-space: normal;
  }

  :deep(.tab-pane .container) {
    margin: 0;
    padding: 0;
  }

  .tab-menu {
    margin-top: 6px;
  }
}
</style>
