<!-- 
  autocomplete="off" doesn't work reliably, the workaround is to give it an alternative string that the browser doesn't recognise
  See: https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164 
-->

<template>
  <div ref="autocompleteElement" class="autocomplete" :class="{ 'no-label': !label, 'form-control-container': !config.noContainer }" tabindex="-1">
    <k-label v-if="!config.hideLabel" :id="uuid" :label />
    <div class="position-relative">
      <input
        :id="uuid"
        ref="searchElement"
        v-model="search"
        :class="classes"
        :aria-label="label"
        v-bind="commonBindings(config, label)"
        autocomplete="autocomplete-form-control"
        @click="onClick"
        @change="onChange"
        @input="onChange"
        @focus="focused = true"
        @blur="onBlur"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown="onKeyDown"
        @keyup.enter="onEnter" />
      <button v-if="!config.disabled" type="button" class="clear-field" title="Clear" tabindex="-1" @click="clearField($event)">
        <i class="fa fa-close"></i>
      </button>
    </div>

    <ul ref="autocompleteResults" :class="{ show: isOpen, secondary: secondaryProperty && !showInlineSecondary }" class="dropdown-menu autocomplete-results">
      <li v-if="loading && !results.length" class="loading dropdown-item"><k-spinner size="sm" class="me-2" show-instantly /> Loading results...</li>
      <template v-else>
        <li
          v-for="(result, i) in results"
          :key="i"
          class="dropdown-item"
          :class="{ highlighted: i === arrowCounter, active: result.id === modelValue?.id }"
          @click="selectResult(result, i)"
          @keydown.enter="selectResult(result, i)">
          {{ result[property ? property : "name"]
          }}<span v-if="showInlineSecondary && secondaryProperty" class="ps-2 text-secondary d-inline-block">{{
            getSecondaryProperty(result) ?? result["id"]
          }}</span>
          <small v-if="secondaryProperty && !showInlineSecondary" class="autocomplete-secondary">
            {{ getSecondaryProperty(result) ?? result["id"] }}
          </small>
        </li>
        <li v-if="canCreateNewOptions && search.length > 0">
          <div
            class="ml-1 dropdown-item text-secondary"
            @click="
              $emit('showRelatedRecordForm', search);
              isOpen = false;
            ">
            <i class="far fa-fw fa-plus me-1"></i>Add: <b>{{ search }}</b>
          </div>
        </li>
        <li v-else-if="results.length === 0" class="dropdown-item disabled">No results found</li>
        <li v-if="hasMore" class="dropdown-item disabled">Search for more results...</li>
      </template>
    </ul>

    <issue-display />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, watchEffect } from "vue";

import { useEventListener } from "@vueuse/core";
import { v4 } from "uuid";

import IssueDisplay from "../KIssueDisplay.vue";
import { useInputConfig, commonBindings, getInputClasses } from "../inputConfig";

import KSpinner from "@ui/progress/KSpinner.vue";
import KLabel from "@ui/label/KLabel.vue";

import type { KRecord } from "@data/data/Record";
import { prioritySearch } from "@data/helpers/data/Searching";

const props = withDefaults(
  defineProps<{
    /** Current value of the input */
    modelValue: KRecord | undefined;
    /** Label for the input */
    label?: string;
    /** Items to select from */
    items: KRecord[];
    /** Primary property to show in results, defaults to "name" */
    property?: string;
    /** Secondary property to show in results */
    secondaryProperty?: string;
    /** Whether the results are still loading */
    loading?: boolean;
    /** Whether more results are available via searching */
    hasMore?: boolean;
    /** Custom filter to use to match items */
    searchFilter?: (item: KRecord, search: string) => boolean;
    /** Whether to show the secondary property inline */
    showInlineSecondary?: boolean;
    /** Whether to allow creating new instances on the fly */
    canCreateNewOptions?: boolean;
  }>(),
  {
    label: "",
    property: "name",
    secondaryProperty: undefined,
    searchFilter: undefined
  }
);

const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", value: KRecord | undefined): void;
  (event: "showRelatedRecordForm", value: string): void;
}>();

const search = defineModel<string>("search", { default: "" });

const config = useInputConfig();

const autocompleteElement = ref<HTMLElement>();
const searchElement = ref<HTMLInputElement>();
const autocompleteResults = ref<HTMLUListElement>();

const shouldFilterResults = ref(false);
const isOpen = ref(false);
const arrowCounter = ref(-1);
const focused = ref(false);

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const input = ref(props.modelValue);
const selectedIndex = computed(() => (input.value ? props.items.findIndex((i) => i.id === input.value?.id) : -1));

watch(input, (value) => {
  emit("update:modelValue", value);
});

const resetSearch = () => {
  search.value = input.value ? (input.value[props.property] as string) : "";
};
// set search string to the name when the input is set
watchEffect(() => {
  if (input.value && !search.value) {
    resetSearch();
  }
});

// Identify initially selected ID value in list and highlight by default
const setInitialValue = () => {
  if (props.modelValue) {
    const idx = props.items.findIndex((i) => i.id === props.modelValue?.id);
    arrowCounter.value = idx;
    if (idx !== -1) {
      input.value = props.items[idx];
      return;
    }
  }
};

watch(
  () => props.loading,
  () => setInitialValue()
);

watch(
  () => props.modelValue,
  (newValue) => {
    setInitialValue();
    if (!newValue) {
      input.value = undefined;
      search.value = "";
    } else if (!focused.value) {
      resetSearch();
    }
  }
);

watch(
  () => props.items,
  () => setInitialValue()
);

// Handle user selection of a result in the dropdown
const selectResult = (value: KRecord, index: number) => {
  if (config.value.readonly) {
    return;
  }
  isOpen.value = false;
  arrowCounter.value = index;
  input.value = value;
  resetSearch();
  shouldFilterResults.value = false;
};

const getSecondaryProperty = (item: KRecord): string | undefined => (props.secondaryProperty ? (item[props.secondaryProperty] as string) : undefined);

// Filter down the results in the dropdown by user search
const results = computed(() => {
  if (!shouldFilterResults.value) {
    return props.items;
  }
  const toSearchFor = search.value.toLowerCase();
  // Use custom search filter function if it exists
  if (props.searchFilter) {
    return props.items.filter((item) => props.searchFilter?.(item, toSearchFor));
  }
  const searchProperties = [props.property];
  if (props.secondaryProperty) {
    searchProperties.push(props.secondaryProperty);
  }
  return prioritySearch(props.items, toSearchFor, searchProperties);
});

watch(results, (newValue, oldValue) => {
  if (newValue.length !== oldValue.length) {
    arrowCounter.value = -1;
  }
});

// clears field text
const clearField = (e?: Event) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  shouldFilterResults.value = true;
  search.value = "";
  input.value = undefined;
};

// Handle search input changes
const onChange = () => {
  if (config.value.readonly) {
    return;
  }
  shouldFilterResults.value = true;
  isOpen.value = true;
};

watch(search, (newValue) => {
  if (newValue === "") {
    input.value = undefined;
  }
});

// Handle clicking on a result
const onClick = () => {
  if (config.value.readonly) {
    return;
  }

  onChange();
  shouldFilterResults.value = false;

  searchElement.value?.select();
  searchElement.value?.setSelectionRange(0, search.value ? search.value.length : 100);

  if (search.value) {
    isOpen.value = true;
    emit("focus");
    arrowCounter.value = selectedIndex.value;
  }
};

// Handle arrow keys
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const adjustment = props.secondaryProperty && !props.showInlineSecondary ? 48 : 30;
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const visibleItems = props.secondaryProperty && !props.showInlineSecondary ? 2 : 4;

const changeArrow = (delta: number) => {
  if (config.value.readonly) {
    return;
  }

  if (!isOpen.value) isOpen.value = true;

  const newIndex = arrowCounter.value + delta;
  if (newIndex >= 0 && newIndex < results.value.length) {
    arrowCounter.value = newIndex;
    if (arrowCounter.value > visibleItems) {
      const pos = arrowCounter.value * adjustment - visibleItems * adjustment;
      if (autocompleteResults.value) {
        autocompleteResults.value.scrollTop = Math.max(pos, 0);
      }
    }
  }
};

const onArrowDown = () => {
  changeArrow(1);
};

const onArrowUp = () => {
  changeArrow(-1);
};

const getSelectionIndex = () => {
  let index = arrowCounter.value;
  if (
    index === -1 &&
    search.value &&
    typeof results.value[0][props.property] === "string" &&
    (results.value[0][props.property] as string).toLowerCase().includes(search.value.toLowerCase())
  ) {
    index = 0;
  }
  return index;
};

// Handle pressing "Enter" on a selected item
const onEnter = (e: KeyboardEvent) => {
  e.preventDefault();
  e.stopPropagation();

  if (config.value.readonly) {
    return;
  }

  const index = getSelectionIndex();
  if (index > -1) {
    selectResult(results.value[index], index);
  }
  isOpen.value = false;
  arrowCounter.value = -1;
};

// Handle clicking outside the autocomplete element, dismiss dropdown
useEventListener(document, "click", (evt) => {
  if (config.value.readonly) {
    return;
  }

  if (!autocompleteElement.value?.contains(evt.target as Node)) {
    isOpen.value = false;
    emit("blur");
  }
});

// Handle tabbing away from the current field
const onBlur = (evt: FocusEvent) => {
  focused.value = false;
  if (!autocompleteElement.value?.contains(evt.relatedTarget as Node)) {
    isOpen.value = false;
    resetSearch();
    emit("blur");
  }
};

const onKeyDown = (e: KeyboardEvent) => {
  if (config.value.readonly) {
    return;
  }

  if (e.key === "Tab" && !input.value) {
    const index = getSelectionIndex();
    if (index > -1) {
      selectResult(results.value[index], index);
    }
    isOpen.value = false;
  }
};

// Setup CSS classes to handle states
const classes = computed(() => ({
  "dropdown-toggle": true,
  ...getInputClasses(config.value, "select")
}));

const uuid = v4();
</script>
