import { useEventBus } from "@vueuse/core";

import { AlertEvent } from "./alert-event";

import type { Alert } from "./alert";

export class AlertHandler {
  /**
   * Function to add a new alert to the UI
   *
   * @param {Alert} alert Alert to show
   */
  public addAlert(alert: Alert) {
    if (alert.duration == null) {
      alert.duration = 4000;
    }

    if (alert.variant == null) {
      alert.variant = "primary";
    }

    const bus = useEventBus(AlertEvent);
    bus.emit(alert);
  }
}
