<template>
  <date-picker
    v-if="!disabled"
    v-model="range"
    :mode
    is-range
    :columns="2"
    :disabled
    class="flex-grow-1 py-0 form-control timespan-input-control"
    :class="classes"
    :is-required="required"
    :popover
    locale="en-GB"
    :mask="{ input: ['DD/MM/YY', 'DD/MM/YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'] }"
    :input-debounce="500"
    :attributes
    @click="focusEnd">
    <template #default="{ inputValue, inputEvents, showPopover, hidePopover }">
      <div class="w-100 position-relative" @click="showPopover">
        <button v-if="!disabled" type="button" class="clear-field" title="Clear" tabindex="-1" @click="clearField($event)">
          <i class="fa fa-close"></i>
        </button>
        <input
          :size="mode == 'date' ? 10 : 18"
          :disabled
          :placeholder="label ?? 'Enter date range'"
          class="timespan-input"
          :class="{ 'w-100': !inputValue.start }"
          :value="inputValue.start"
          @focus="showPopover"
          v-on="inputEvents.start" />
        <span v-if="!!inputValue.start" class="px-2 d-inline-block timespan-to text-secondary">to</span>
        <input
          v-if="!!inputValue.start"
          ref="end"
          :size="mode == 'date' ? 10 : 18"
          :tabindex="!inputValue.start ? -1 : 0"
          :disabled
          :placeholder="inputValue.start ? 'End' : ''"
          class="timespan-input"
          :value="inputValue.end"
          @blur="hidePopover"
          @keydown="onKeyDown"
          v-on="inputEvents.end" />
      </div>
    </template>
  </date-picker>
  <input v-else :disabled="true" :placeholder="label ?? 'Enter date range'" class="flex-grow-1 form-control form-control-sm" :value="formattedRange" readonly />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { DatePicker } from "v-calendar";
import dayjs from "dayjs";

import { getDatePickerAttributes } from "./DatePickerAttributes";

import type { TimeSpanField, TimeSpanValue } from "@data/fields/complex/TimeSpanField";
import { deepEquals } from "@data/helpers/manipulation/Comparison";

const props = defineProps<{
  /** Precision of the input */
  precision?: TimeSpanField["precision"];
  /** Value of the input */
  modelValue?: TimeSpanValue;
  /** Whether the input is required */
  required?: boolean;
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Classes to apply to the input */
  classes?: string | Record<string, boolean>;
  /** Label for the input */
  label?: string;
}>();

const emit = defineEmits<(event: "update:modelValue", newValue?: TimeSpanValue) => void>();

const popover = ref({
  visibility: "none",
  placement: "bottom"
});

interface Range {
  start?: Date;
  end?: Date;
}

const mode = computed(() => (props.precision === "day" ? "date" : "dateTime"));

const range = ref<Range | undefined>({});

const formattedRange = computed(() => {
  if (range.value?.start || range.value?.end) {
    const dates = {
      start: range.value.start ? dayjs(range.value.start).format(props.precision === "day" ? "DD/MM/YYYY" : "DD/MM/YYYY HH:mm") : undefined,
      end: range.value.end ? dayjs(range.value.end).format(props.precision === "day" ? "DD/MM/YYYY" : "DD/MM/YYYY HH:mm") : undefined
    };
    return `${dates.start ?? ""} to ${dates.end ?? ""}`;
  }
  return undefined;
});

const end = ref<HTMLElement>();
const focusEnd = (e: MouseEvent) => {
  const target = e.target as HTMLElement;
  if (target.tagName === "INPUT" || target.tagName === "SELECT") return;

  e.preventDefault();
  e.stopPropagation();
  end.value?.focus();
};

const onKeyDown = (e: KeyboardEvent, skip?: boolean) => {
  if (e.key === "Tab" && !skip) {
    e.preventDefault();
    e.stopPropagation();
    end.value?.blur();
  }
};

watch(
  () => props.modelValue,
  (value) => {
    range.value = {
      start: value?.start?.toDate(),
      end: value?.end?.toDate()
    };
  },
  { immediate: true }
);

const result = computed<TimeSpanValue | undefined>(() => {
  if (range.value?.start || range.value?.end) {
    return {
      start: range.value.start ? dayjs(range.value.start).startOf(props.precision ?? "day") : undefined,
      end: range.value.end ? dayjs(range.value.end).startOf(props.precision ?? "day") : undefined
    } satisfies TimeSpanValue;
  }
  return undefined;
});

watch(
  () => result.value,
  (value) => {
    if (!deepEquals(value, props.modelValue)) {
      emit("update:modelValue", value);
    }
  }
);

const clearField = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  range.value = undefined;
};

const attributes = getDatePickerAttributes();
</script>

<style scoped>
.timespan-input {
  background-color: transparent;
  border: none;
  padding: 0;
  width: initial;
  margin: 0;
  line-height: 28px;
  font-variant-numeric: tabular-nums;
  text-align: center;
}

.timespan-input:focus {
  outline: none;
}

.timespan-input:placeholder-shown {
  text-align: left;
}

.timespan-input-control:focus-within {
  background-color: var(--k-input-background);
  border-color: var(--k-color-primary);
  outline: 0;
  box-shadow: 0 0 0 0.15rem var(--k-input-shadow);
}
.timespan-to {
  user-select: none;
}

.clear-field {
  position: absolute;
  top: 2px;
  right: 0;
  border: none;
  color: var(--k-color);
  font-size: 11px;
  cursor: pointer;
  background: transparent;
}
</style>
