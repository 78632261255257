import type { KEntity } from "@data/data/KEntity";
import { toCamelCase } from "@data/helpers/strings/Casing";

export type Formula = {
  expression: string;
  unit?: string;
};

const screamingSnakeToCamelCase = (screamingSnake: string) => toCamelCase(screamingSnake.toLowerCase().replace(/_/g, " "));

const camelToScreamingSnake = (camel: string) =>
  camel
    .replace(/([A-Z][a-z]*)/g, (match) => `_${match}`)
    .replace(/-/g, "_")
    .toUpperCase();

export class FieldNotFoundError extends Error {
  public get screaming() {
    return camelToScreamingSnake(this.key);
  }

  constructor(
    public key: string,
    entity?: KEntity
  ) {
    super(entity ? `Field ${key} not found on ${entity.singular}` : `Field ${key} not found`);
  }
}

/**
 * Add spaces around numerical operators
 *
 * Only add space to front of minus since it could be a negative number
 *
 * Only remove excess spaces from * or / since they're used in metric units :/
 */
const operatorRegex = /( *\+ *)|( *- +)|( +[*/] +)/g;
const normaliseSpacing = (expression: string) => expression.replace(operatorRegex, (o) => ` ${o.trim()} `).trim();

/** Convert $ accessors to expression accessors (e.g. "$AGE + $HEIGHT" -> record.age + record.height) */
export const fromFormula = (dollar: string, entity: KEntity | undefined, normalise = false) => {
  if (normalise) {
    dollar = normaliseSpacing(dollar);
  }
  return dollar.replace(/\$([\d.A-Z_]*)/g, (match) => {
    const key = match.slice(1).split(".").map(screamingSnakeToCamelCase).join(".");
    if (!key) {
      throw new Error("Empty field name");
    }
    if (entity) {
      const field = entity.getField(key);
      if (field) {
        return `record.${field.id}`;
      }
    }
    throw new FieldNotFoundError(key, entity);
  });
};

const recordRegex = /record\.([\d.a-z-]+)/gi;

export const toFormula = (camel: string, entity?: KEntity) =>
  camel.replace(recordRegex, (match) => {
    const key = match.slice(7);
    const field = entity?.getField(key);
    return `$${camelToScreamingSnake(field?.key ?? key)}`;
  });

// (not an unescaped dollar) then (match accessor)
const formatStringRegex = /(?:[^$]|\$\$|^)(\$[\d.A-Z_]+\$?)/;
const single$ = /(^|[^$])\$(?!\$)/g;
const quoteAndUnescape = (s: string) => {
  if (single$.test(s)) {
    throw new Error("Unescaped $ in string (to escape, use $$)");
  }
  return JSON.stringify(s.replace(/\$\$/g, "$"));
};

/**
 * Convert $ accessors to expression accessors, and turn into a string expression (e.g. "Bertha is $AGE years old" -> "Bertha is " + record.age + " years old")
 * $$ escapes a $ can end accessors with a space or $
 */
export const fromFormatString = (expression: string, entity: KEntity | undefined) => {
  let match = formatStringRegex.exec(expression);
  const parts: string[] = [];
  while (match) {
    let accessor = match[1];
    const index = match.index + match[0].indexOf(accessor);
    const before = expression.slice(0, index);
    const after = expression.slice(index + accessor.length);
    if (accessor.endsWith("$")) {
      accessor = accessor.slice(0, -1);
    }
    if (parts.length) {
      parts.push(" + ");
    }
    if (before) {
      parts.push(quoteAndUnescape(before), " + ");
    }
    parts.push(fromFormula(accessor, entity));
    expression = after;
    match = formatStringRegex.exec(expression);
  }
  if (expression) {
    if (parts.length) {
      parts.push(" + ");
    }
    parts.push(quoteAndUnescape(expression));
  }
  return parts.join("");
};

const splitByUnquoted = (s: string, split: string) => {
  const parts: string[] = [];
  let part = "";
  let quote = false;
  let escape = false;
  for (const c of s) {
    part += c;
    if (escape) {
      escape = false;
    } else if (c === "\\") {
      escape = true;
    } else if (c === '"') {
      quote = !quote;
    } else {
      if (part.endsWith(split) && !quote) {
        parts.push(part.slice(0, -split.length));
        part = "";
      }
    }
  }
  if (part) {
    parts.push(part);
  }
  return parts;
};

/** The reverse of fromFormatString */
export const toFormatString = (expression: string, entity?: KEntity) => {
  const parts = splitByUnquoted(expression, " + ");
  const result = parts
    .map((part) => {
      if (part.startsWith('"')) {
        return (JSON.parse(part) as string).replace(/\$/g, () => "$$");
      }
      return toFormula(part, entity) + "$";
    })
    .join("");
  // replace extra $ at the end of accessors if appropriate
  return result.replace(/\$[\d.A-Z_]+\$(?:\s|$|[^\w$])/g, (m) => m.replace(/\S\$/, (om) => om.slice(0, -1)));
};
