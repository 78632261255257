import { filterEmpty } from "../../helpers/NullHelpers";
import { SimpleField } from "../basic/SimpleFields";
import { KeyField, NumberKeyField } from "../sub/SubField";

import type { KRecord } from "../../data/Record";
import type { Address } from "../../types/Location";
import type { Sortable } from "../KField";

const csvSubheaders: Record<keyof Address, string> = {
  title: "Title",
  line1: "Line 1",
  line2: "Line 2",
  city: "City",
  county: "County",
  country: "Country",
  postcode: "Postcode",
  lat: "Latitude",
  lng: "Longitude"
};

export class LocationField extends SimpleField<Address> {
  readonly type = "location";

  static groupSubfields = ["city", "county", "country"];

  constructor(...args: ConstructorParameters<typeof SimpleField<Address>>) {
    super(...args);
    this.subfields = [
      new KeyField("title", () => this, "Title"),
      new KeyField("line1", () => this, "Line 1"),
      new KeyField("line2", () => this, "Line 2"),

      new KeyField("city", () => this, "City"),
      new KeyField("county", () => this, "County"),
      new KeyField("country", () => this, "Country"),
      new KeyField("postcode", () => this, "Postcode"),
      new NumberKeyField("lat", () => this, "Latitude"),
      new NumberKeyField("lng", () => this, "Longitude")
    ];
  }

  getSortableValue(record: KRecord, toLowerCase?: boolean | undefined): Sortable {
    const res = this.getDisplayValue(record);
    return toLowerCase ? res.toLowerCase() : res;
  }

  getDisplayValue(record: KRecord): string {
    const value = this.getValue(record);
    if (!value) return "";
    if (value.city && value.country) return `${value.city}, ${value.country}`;
    if (value.county && value.country) return `${value.county}, ${value.country}`;
    if (value.city) return value.city;
    return value.title || value.line1 || value.line2 || value.county || value.country || value.postcode || "";
  }

  getSearchValue(record: KRecord): string | undefined {
    const value = this.getValue(record);
    if (!value) return undefined;
    return filterEmpty([value.title, value.line1, value.line2, value.city, value.county, value.country, value.postcode]).join(", ");
  }

  getCSVSubheaders(): string[] {
    return Object.values(csvSubheaders);
  }

  override getCSVColumns(): { subheader: string }[];

  override getCSVColumns(record: KRecord): { subheader: string; value: string }[];

  override getCSVColumns(record?: KRecord | undefined) {
    if (!record) return this.getCSVSubheaders().map((subheader) => ({ subheader }));
    const value = this.getValue(record);
    return Object.entries(csvSubheaders).map(([key, subheader]) => ({
      subheader,
      value: value?.[key as keyof Address]?.toString() ?? ""
    }));
  }

  getDefaultColumnWidth() {
    return 150;
  }

  validateType(value: unknown): value is Address {
    if (!value) return false;
    if (typeof value !== "object") return false;
    // can leverage subfields to validate each part
    const testRecord = { [this.key]: value };
    return this.subfields.every((field) => {
      const subValue = field.getValue(testRecord);
      return subValue == null || field.validateType(subValue);
    });
  }
}
