import { SIUnits } from "../../units/basic/si";

import { NumericField } from "./SimpleFields";

import type { MetricUnit } from "../../units/units";
import type { NumericFieldSetup } from "./SimpleFields";

const currencyCodes = {
  sterling: "GBP",
  dollar: "USD",
  euro: "EUR"
};

export type CurrencyType = keyof typeof currencyCodes;

export const isCurrencyType = (value: string): value is CurrencyType => value in currencyCodes;

export interface CurrencyFieldSetup extends NumericFieldSetup {
  currency?: CurrencyType;
}

export class CurrencyField extends NumericField {
  readonly type = "currency";

  readonly currency: CurrencyType = "sterling";

  readonly unit: MetricUnit;

  constructor(setup: string | CurrencyFieldSetup) {
    super(setup);
    if (typeof setup === "object") {
      this.currency = setup.currency ?? "sterling";
    }
    this.formatOptions = {
      style: "currency",
      currency: currencyCodes[this.currency],
      currencyDisplay: "narrowSymbol"
    };
    this.unit = SIUnits[this.currency];
  }
}
