<template>
  <div class="wizard m-n3">
    <k-toolbar
      v-if="page === 'START' || page === 'MANUAL-SINGLE'"
      :title="pageTitle"
      class="position-relative wizard-toolbar"
      :show-overflow-indicator="page !== 'START'"
      rounded
      :back-button="page === 'START' ? undefined : 'BACK'"
      :structure="{
        end: [
          {
            title: 'Help',
            icon: 'question-circle',
            onClick: () => openHelp('/getting-started/setting-up'),
            type: 'button'
          },
          {
            title: 'Close',
            icon: 'close',
            onClick: onClose,
            type: 'button'
          }
        ]
      }"
      @back="onBack" />
    <wizard-start v-if="page === 'START'" v-model:prompt="search" v-model:collections="suggestedCollections" @change-page="changePage" />
    <wizard-single-collection v-else-if="page === 'MANUAL-SINGLE'" :prompt="suggestedCollectionName ?? search" @close="onClose" />
    <wizard-multi-collection v-else-if="page === 'SUGGESTED'" isai :prompt="search" :collections="suggestedCollections" @back="onBack" @close="onClose" />
    <wizard-multi-collection v-else-if="page === 'MANUAL-MULTIPLE'" :isai="false" @back="onBack" @close="onClose" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import KToolbar from "@ui/toolbar/KToolbar.vue";

import WizardMultiCollection from "./WizardMultiCollection.vue";
import WizardSingleCollection from "./WizardSingleCollection.vue";
import WizardStart from "./WizardStart.vue";

import type { Collection } from "@data/data/Collection";
import { toPlural } from "@data/helpers/strings/Plurals";

import { openHelp } from "@/consts/openHelp";
import { toTitleCase } from "@/helpers/useCollectionNameVariants";

type PageType = "START" | "SUGGESTED" | "MANUAL-SINGLE" | "MANUAL-MULTIPLE";

const emit = defineEmits<{
  /** Emitted when the modal is closed */
  (event: "close"): void;
}>();

const onClose = () => emit("close");

const search = ref("");

const page = ref<PageType>("START");

const pageTitle = computed(() => {
  switch (page.value) {
    case "SUGGESTED":
      return "Add Collections";
    case "MANUAL-MULTIPLE":
      return "Add Collections";
    case "MANUAL-SINGLE":
      return "Add Collection";
    default:
      return "";
  }
});

const suggestedCollections = ref<Collection[]>([]);
const suggestedCollectionName = ref<string>();
const changePage = (newPage: PageType, collectionName?: string) => {
  page.value = newPage;
  if (collectionName) {
    suggestedCollectionName.value = toTitleCase(toPlural(collectionName));
  } else {
    suggestedCollectionName.value = undefined;
  }
};

const onBack = () => {
  page.value = "START";
};
</script>

<style scoped>
.wizard {
  user-select: none;
  min-height: 450px;
}
</style>
