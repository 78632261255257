<template>
  <div class="d-flex gap-2 align-items-start form-control-container">
    <component
      :is="computedComponent.component"
      v-if="computedComponent"
      ref="inputElRef"
      v-bind="computedComponent.props"
      v-model="computedValue"
      :label
      :class="classes" />
    <k-button v-if="canOverride || overridden" :disabled="!canOverride" v-bind="overrideButton" class="override-button" @click="toggleOverride" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import KButton from "@ui/button/KButton.vue";
import { useInputConfig, mergeInputConfig } from "@ui/inputs/inputConfig";

import { getComputedDisplay } from "./fieldInputs";

import type { KEntity } from "@data/data/KEntity";
import type { KFieldValue } from "@data/data/Record";
import type { KContext } from "@data/expressions/context";
import type { RegisteredField } from "@data/fields/FieldRegistry";
import type { ComputedField, ComputedFieldValue } from "@data/fields/computed/ComputedField";
import { deepEquals } from "@data/helpers/manipulation/Comparison";
import { expressionParser } from "@data/expressions/parser";

import { isForeignField } from "@/expressions/computed";

const props = withDefaults(
  defineProps<{
    /** Label for the input */
    label?: string;
    /** Classes for the input component */
    classes?: string[];
    /** Current value of the field input */
    modelValue: KFieldValue;
    /** Entity of the record */
    entity?: KEntity;
    /** Current context */
    context: KContext;
    /** Field which input we're displaying */
    field: ComputedField;
  }>(),
  {
    classes: () => [],
    entity: undefined,
    label: undefined
  }
);
const emit = defineEmits<{
  (event: "update:modelValue", value: ComputedFieldValue): void;
}>();

const computedComponent = computed(() => getComputedDisplay.call(props.field as RegisteredField));

const parsedExpression = computed(() =>
  // only use reduced context here to avoid unnecessary re-renders
  props.field.getParsedExpression({ entity: props.entity })
);

const config = useInputConfig();

const overrideValue = ref<ComputedFieldValue["lastValue"]>();
const overridden = ref(false);

const canOverride = computed(() => !config.value.disabled);

const needsServerInfo = computed(() => {
  if (!props.entity || !props.field.expression || overridden.value) return false;
  const deps = expressionParser.getDependencies(props.field.expression);
  const fields = props.entity.getFields([...deps]);
  return fields.some((f) => isForeignField(f));
});

// disable input if can't override
mergeInputConfig(
  computed(() => {
    let issues = config.value.issues;
    if (needsServerInfo.value) {
      // add warning that field will only fully update on server
      issues = issues.concat({
        severity: "warning",
        message: "This field will be calculated after saving the record."
      });
    }
    return {
      disabled: config.value.disabled || !overridden.value,
      issues
    };
  }),
  config
);

const overrideButton = computed<{ title: string; icon: string }>(() => {
  if (overridden.value) {
    return {
      title: "Reset to computed value",
      icon: "rotate-left"
    };
  }
  return {
    title: "Override computed value",
    icon: "edit"
  };
});

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    const oldCValue = oldValue as ComputedFieldValue | undefined;
    const newCValue = newValue as ComputedFieldValue | undefined;
    if (!deepEquals(oldCValue, newCValue)) {
      overridden.value = newCValue?.overridden ?? false;
      if (overridden.value) {
        overrideValue.value = newCValue?.lastValue;
      }
    }
  },
  { immediate: true }
);

const evaluatedValue = computed(() => (needsServerInfo.value ? undefined : parsedExpression.value?.evaluate(props.context)));

const toggleOverride = () => {
  overridden.value = !overridden.value;
  overrideValue.value = evaluatedValue.value;
};

const computedValue = computed({
  get() {
    if (overridden.value) {
      return overrideValue.value;
    }
    return evaluatedValue.value;
  },
  set(newValue) {
    if (!overridden.value) {
      return;
    }
    overrideValue.value = newValue;
  }
});

const resultValue = computed<ComputedFieldValue>(() => {
  if (overridden.value) {
    return {
      overridden: true,
      lastValue: overrideValue.value
    };
  }
  return {
    overridden: false,
    lastValue: evaluatedValue.value
  };
});

watch(
  resultValue,
  (newValue) => {
    if (!deepEquals(newValue, props.modelValue as ComputedFieldValue)) {
      emit("update:modelValue", newValue);
    }
  },
  { immediate: true }
);
</script>

<style scoped>
.override-button {
  /* bit of a hack to ensure button is aligned even with issues */
  margin-top: 1.12rem;
}
</style>
