import { StringAccessor, getField, matchAccessor } from "../accessors";
import { StringConstant, RoleConstant } from "../constants";
import { StringItemExpression } from "../data/conditions";
import { ConcatExpression, JoinExpression } from "../strings";
import { ParsingError, type StringExpression } from "../expressions";
import { coalesceExpression } from "../generic";

import { checkOneOf, splitTokens } from "./operators";
import { itemRegex } from "./regexes";

import type { KContext } from "../context";
import type { ExpressionParser } from "../parser";

const roleRegex = /^role\.(\S*)$/;

export function parseString(this: ExpressionParser, expression: string | string[], context: KContext): StringExpression {
  return this.baseParse<StringExpression>(expression, "string", context, {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    recurse: parseString.bind(this),
    single: (token) => {
      // check for string constants
      if (token.startsWith('"')) {
        return new StringConstant(JSON.parse(token) as string);
      }
      if (token === "null") {
        return new StringConstant(undefined);
      }

      // check for accessors
      const accessor = matchAccessor(token);
      if (accessor) {
        const field = getField(context, accessor);
        return new StringAccessor(accessor.type, field);
      }
      const itemMatch = token.match(itemRegex);
      if (itemMatch) {
        const depth = itemMatch[1] ? parseInt(itemMatch[1]) - 1 : 0;
        return new StringItemExpression(depth);
      }
      const roleMatch = token.match(roleRegex);
      if (roleMatch) {
        return new RoleConstant(roleMatch[1]);
      }
      throw new ParsingError(`Unknown string constant: ${token}`);
    },
    multi: (tokens) => {
      // string concatenation
      const concat = checkOneOf(tokens, ["+"], false);
      if (concat) {
        const subexpressions = splitTokens(tokens, concat.indices).map((t) => this.parseString(t, context));
        return new ConcatExpression(subexpressions);
      }
      const join = checkOneOf(tokens, [".."], false);
      if (join) {
        const subexpressions = splitTokens(tokens, join.indices).map((t) => this.parseString(t, context));
        return new JoinExpression(subexpressions);
      }
      const coalesce = checkOneOf(tokens, ["??"], false);
      if (coalesce) {
        return coalesceExpression(splitTokens(tokens, coalesce.indices).map((t) => this.parseString(t, context)));
      }

      throw new ParsingError(`Unknown string expression: ${tokens.join(" ")}`);
    }
  });
}
