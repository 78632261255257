import { MetricField } from "../fields/basic/MetricField";
import { deepEquals } from "../helpers/manipulation/Comparison";
import { KeyedMap } from "../helpers/maps/KeyedMap";
import { day } from "../units/basic/si";
import { parseUnit } from "../units/parseUnit";

import type { allUnits } from "../units/allUnits";
import type { Dimensions } from "../units/dimensions";
import type { KFieldSetup } from "../fields/KField";
import type { FieldTypeDefinition } from "./fieldDefinitions";

export const metricFieldTypes = {
  // metric: {
  //   label: "Measurement",
  //   description: "A numeric input for physical quantities, with configurable units.",
  //   examples: ["1.5 m", "2.5 kg", "3 min"],
  //   searchTerms: ["metric", "measurement", "unit", "quantity"],
  //   icon: "microscope"
  // },
  percentage: {
    label: "Percentage",
    description: "A numeric input representing a percentage, not limited to 0% to 100%.",
    examples: ["99%", "50%", "1%", "120%"],
    searchTerms: ["percentage", "percent", "fraction", "ratio"],
    icon: "percentage"
  },
  degrees: {
    label: "Angle",
    description: "A numeric input representing an angle, in degrees.",
    examples: ["45°", "180°", "360°"],
    searchTerms: ["angle", "degree", "slope", "gradient"],
    icon: "angle"
  },
  length: {
    label: "Length",
    description: "A numeric input representing a length",
    examples: ["1.5 m", "2.5 km", "3 mm"],
    searchTerms: ["length", "distance", "height", "width", "depth"],
    icon: "ruler"
  },
  miles: {
    label: "Distance (mileage)",
    description: "A numeric input representing a distance, in miles",
    examples: ["1.5 mi", "500 mi"],
    searchTerms: ["distance", "miles", "mileage", "journey"],
    icon: "road"
  },
  area: {
    label: "Area",
    description: "A numeric input representing an area",
    examples: ["1.5 m²", "2.5 km²", "33 ha", "63.4 cm²"],
    searchTerms: ["area", "surface", "floor", "hectare"],
    icon: "ruler-combined"
  },
  volume: {
    label: "Volume",
    description: "A numeric input representing a volume",
    examples: ["3 l", "1.5 m³", "568 ml"],
    searchTerms: ["volume", "capacity", "space", "litre", "liquid"],
    icon: "flask"
  },
  mass: {
    label: "Weight",
    description: "A numeric input representing a weight, in kilograms.",
    examples: ["1.5 kg", "340 kg"],
    searchTerms: ["weight", "mass", "load"],
    icon: "weight-hanging"
  },
  time: {
    label: "Scientific Time",
    description: "A numeric input representing an amount of time",
    examples: ["1.5 s", "2 min 30 s", "20 ms"],
    searchTerms: ["time", "duration", "period"],
    icon: "stopwatch"
  },
  data: {
    label: "Data",
    description: "A numeric input representing a quantity of data",
    examples: ["1.5 MB", "2.5 GB", "3 TB"],
    searchTerms: ["data", "storage", "memory", "disk"],
    icon: "binary"
  },
  current: {
    label: "Current",
    description: "A numeric input representing an electric current, in amps.",
    examples: ["1.5 A", "2.3 mA", "20 kA"],
    searchTerms: ["current", "amps", "ampere", "electricity"],
    icon: "bolt-auto"
  },
  voltage: {
    label: "Voltage",
    description: "A numeric input representing an electric voltage.",
    examples: ["4.5 V", "255 kV", "10 mV"],
    searchTerms: ["voltage", "volts", "potential", "electricity"],
    icon: "bolt"
  },
  charge: {
    label: "Charge",
    description: "A numeric input representing an electric charge.",
    examples: ["1.5 C", "2.3 mC", "20 µC"],
    searchTerms: ["charge", "coulombs", "electricity"],
    icon: "battery-three-quarters"
  },
  resistance: {
    label: "Resistance",
    description: "A numeric input representing electrical resistance, in ohms.",
    examples: ["333 Ω", "1 kΩ", "10 MΩ"],
    searchTerms: ["resistance", "ohms", "electricity"],
    icon: "bolt-slash"
  },
  capacitance: {
    label: "Capacitance",
    description: "A numeric input representing electrical capacitance, in farads.",
    examples: ["1.5 F", "2.3 mF", "20 µF"],
    searchTerms: ["capacitance", "farads", "electricity"],
    icon: "transformer-bolt"
  },
  inductance: {
    label: "Inductance",
    description: "A numeric input representing electrical inductance, in henrys.",
    examples: ["1.5 H", "2.3 mH", "20 µH"],
    searchTerms: ["inductance", "henrys", "electricity"],
    icon: "lightbulb-cfl"
  },
  energy: {
    label: "Energy",
    description: "A numeric input representing energy, in joules.",
    examples: ["1.5 J", "2.3 kJ", "20 MJ"],
    searchTerms: ["energy", "joules", "work"],
    icon: "fire"
  },
  power: {
    label: "Power",
    description: "A numeric input representing power, in watts.",
    examples: ["1.5 W", "2.3 kW", "1.3 GW"],
    searchTerms: ["power", "watts", "electricity"],
    icon: "meter-bolt"
  }
} satisfies Partial<Record<keyof typeof allUnits, FieldTypeDefinition>>;

export type MetricFieldType = keyof typeof metricFieldTypes;

const serialiseDimensions = (dimensions: Dimensions): string => {
  const entries = Object.entries(dimensions).sort(([a], [b]) => a.localeCompare(b));
  return entries
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}:${value}`)
    .join(",");
};
const dimMap = new KeyedMap<Dimensions, string>(serialiseDimensions);

export const getMetricFieldType = (unit: string): MetricFieldType => {
  if (unit in metricFieldTypes) {
    return unit as MetricFieldType;
  }
  const { dimensions } = parseUnit(unit);
  if (!dimMap.size) {
    // populate the map
    for (const key of Object.keys(metricFieldTypes)) {
      const { dimensions: unitDimensions } = parseUnit(key);
      if (!dimMap.has(unitDimensions)) {
        dimMap.set(unitDimensions, key);
      }
    }
  }
  const dimUnit = dimMap.get(dimensions);
  if (dimUnit) {
    return dimUnit as MetricFieldType;
  }
  throw new Error(`No metric field type for dimensions ${serialiseDimensions(dimensions)}`);
};

export const createMetricField = (
  baseSetup: KFieldSetup,
  type: MetricFieldType,
  options: {
    defaultValue?: number;
    unit?: string;
    lockUnit?: boolean;
  }
): MetricField => {
  const { defaultValue, unit, lockUnit } = options;
  let min: number | undefined = undefined;
  let max: number | undefined = undefined;
  const actualType = unit ?? type;
  const actualUnit = parseUnit(actualType);
  if (unit) {
    // check unit is assignable to type
    const baseUnit = parseUnit(type);
    if (!deepEquals(baseUnit.dimensions, actualUnit.dimensions)) {
      throw new Error(`Unit ${unit} is not compatible with metric field type ${type}`);
    }
  } else if (!lockUnit) {
    // set sensible defaults for min and max
    switch (type) {
      case "length":
        min = 0.001;
        max = 1000;
        break;
      case "time":
        min = 0.001;
        max = day.conversion;
        break;
      case "data":
        min = 1;
        max = 1e12;
        break;
    }
  }
  return new MetricField({ ...baseSetup, defaultValue, min, max, lockUnit }, actualType);
};
