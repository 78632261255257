<template>
  <div class="file-icon-container">
    <div v-if="isFolder">
      <i class="fal fa-folder" />
    </div>
    <div v-else>
      <i class="fal fa-file" />
      <img
        v-if="iconValue"
        :src="iconValue"
        class="file-icon"
        :style="{ backgroundColor, border, padding: icon?.isMonotone ? '2px' : '1px' }"
        :alt="extension" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { computedAsync } from "@vueuse/core";

import icons from "./icons.json";

const props = defineProps<{
  /** The file extension */
  extension: string;
  /** True if the file is a folder, in this case we show a generic folder icon, as we dont have to worry about file type */
  isFolder?: boolean;
}>();

// Generate import functions for all SVG icons
const mt = import.meta.glob("./file-icons/*.svg");

const iconValue = computedAsync(async () => {
  try {
    const icon = mt[`./file-icons/${props.extension.replace(".", "").toLowerCase()}.svg`];
    const svg = (await icon()) as { default?: string };
    return svg.default;
  } catch (e) {
    return null;
  }
});

interface IconSchema {
  isMonotone: string;
  primaryColour?: string;
  fileName: string;
}

const icon = computed<IconSchema | undefined>(() => icons.find((i) => i.fileName === props.extension.replace(".", "").toLowerCase()) as IconSchema | undefined);

const backgroundColor = computed(() => (icon.value?.isMonotone ? (icon.value.primaryColour ?? "#fff") : "#fff"));

const border = computed(() => (icon.value?.isMonotone ? "none" : `1px solid ${icon.value?.primaryColour ?? "var(--k-color)"}`));
</script>

<style scoped>
.file-icon-container {
  position: relative;
  width: 20px;
  height: 22px;
  overflow: visible;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-icon {
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  border-radius: 4px;
}

.file-icon-container .fa-file {
  font-size: 18px;
}
</style>
