<template>
  <div
    role="button"
    class="risk-option"
    :class="selected ? `selected bg-d-10-${backgroundColour}` : `bg-l-15-${backgroundColour}`"
    tabindex="-1"
    :title="`Impact: ${impact}, Likelihood: ${likelihood}`"
    @keydown.enter="toggleSelected"
    @click="toggleSelected">
    {{ impact * likelihood }}
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import type { RiskScore } from "@data/types/RiskScore";
import { getRiskScore } from "@data/types/RiskScore";

const props = defineProps<{
  /** Impact value for this option */
  impact: number;
  /** Likelihood value for this option */
  likelihood: number;
  /** Current value of the input */
  currentValue?: RiskScore;
}>();

const emit = defineEmits<{
  (event: "click", impact: number, likelihood: number): void;
}>();

const toggleSelected = () => {
  emit("click", props.impact, props.likelihood);
};

const selected = computed(() => props.currentValue?.impact === props.impact && props.currentValue.likelihood === props.likelihood);

const backgroundColour = computed(() => {
  const risk = getRiskScore(props.impact * props.likelihood);
  return risk.variant;
});
</script>

<style scoped lang="scss">
.risk-option {
  width: 30px;
  flex-grow: 1;
  height: 30px;
  line-height: 28px;
  border: 1px solid transparent;
  margin: 1px;
  border-radius: 1px;
  transition: all ease-in-out 0.02s;
  user-select: none;
  -webkit-user-select: none;
  color: rgba(0, 0, 0, 0.3);
}

.risk-option:hover {
  filter: brightness(0.95);
}

.risk-option.selected {
  border: 1px solid var(--k-color);
  border-radius: 2px;
  z-index: 2;
  box-shadow: 0 0 0 0.05rem var(--k-color);
  font-weight: 800;
  color: white;
}

.risk-option.bg-d-10-yellow {
  color: black;
}
</style>
