import { ref } from "vue";

import { v4 as uuidv4 } from "uuid";

import { fixInitialCollectionData } from "./AICollectionGenerationUtils";
import { MockCollectionGenerationAPI } from "./AICollectionGenerationAPI.mock";

import { untruncateJson } from "@data/helpers/serialisation/untruncateJson";
import type { Collection } from "@data/data/Collection";

import { CollectionGenerationAPI } from "@/api/collectionGeneration";

import type { InitialCollectionData } from "./AICollectionGenerationUtils";

export class CollectionNamesGenerator {
  /** Unique ID representing the current request to generate collection names */
  private generationId = ref(uuidv4());

  /** Whether or not the collection names are currently being generated */
  public isGenerating = ref(false);

  /** Last prompt used to generate collection names */
  public lastPrompt = "";

  /** Collection names generated from the API */
  public collectionNames = ref<InitialCollectionData[]>([]);

  private existingCollections: Collection[] = [];

  private collectionNamesMutation = CollectionGenerationAPI.generateCollectionNames();

  private collectionNamesStream = CollectionGenerationAPI.onCollectionNamesStreamUpdated(this.generationId);

  private cancelMutation = CollectionGenerationAPI.cancelCollectionGeneration();

  constructor(initialCollections: Collection[] = [], existingCollections?: Collection[], useMock?: boolean) {
    // Set the initial collection names
    if (initialCollections.length > 0) {
      this.collectionNames.value = initialCollections.map((collection) => ({
        name: collection.plural,
        icon: collection.icon
      }));
    }

    if (existingCollections) {
      this.existingCollections = existingCollections;
    }

    if (useMock) {
      this.collectionNamesMutation = MockCollectionGenerationAPI.generateCollectionNames();
      this.collectionNamesStream = MockCollectionGenerationAPI.onCollectionNamesStreamUpdated(this.generationId);
    }

    // Setup the stream event handler
    this.collectionNamesStream.onResult((result) => this.onNameStreamUpdate(result.data?.collectionNamesStreamUpdate));
  }

  /**
   * Start fetching collection names for the given search term from the API.
   *
   * @param search Search prompt to use for fetching collection names.
   */
  public async generateCollectionNames(search: string) {
    if (this.isGenerating.value) {
      await this.cancelMutation.mutate({ generationId: this.generationId.value });
    }

    if (search.trim().length === 0 || search.trim() === this.lastPrompt.trim()) return;

    this.lastPrompt = search;
    this.collectionNames.value = [];

    const currentGenerationId = uuidv4();
    this.generationId.value = currentGenerationId;
    this.isGenerating.value = true;
    this.rawCollectionNamesResult = "";

    await this.collectionNamesMutation.mutate({ prompt: search, generationId: currentGenerationId });

    if (this.generationId.value === currentGenerationId) {
      this.isGenerating.value = false;
    }
  }

  /** Raw collection names result from the API, may be incomplete */
  private rawCollectionNamesResult = "";

  /**
   * Handle additional text streaming in from the API, process and update the collection names if possible.
   *
   * @param result New additions to the collection names result.
   */
  public onNameStreamUpdate(result?: string) {
    if (!result) return;

    this.rawCollectionNamesResult += result;
    const maybeCollectionNames = untruncateJson(this.rawCollectionNamesResult);

    if (maybeCollectionNames !== null) {
      const names = fixInitialCollectionData(maybeCollectionNames);

      // Filter out any existing collections
      this.collectionNames.value = names.filter(
        (name) => !this.existingCollections.some((collection) => collection.plural === name.name || collection.singular === name.name)
      );
    }
  }

  /** Whether or not the collection names are valid. */
  get hasValidCollectionNames() {
    return this.collectionNames.value.length > 0;
  }

  public stopGenerating() {
    if (this.isGenerating.value) {
      void this.cancelMutation.mutate({ generationId: this.generationId.value });
    }
  }

  /**
   * Stop the collection names generator. This will stop the stream and set the isGenerating flag to false. This should be called when the component is
   * unmounted.
   */
  public stop() {
    if (this.isGenerating.value) this.stopGenerating();

    this.isGenerating.value = false;
    if (this.collectionNamesStream.stop as (() => void) | undefined) {
      this.collectionNamesStream.stop();
    }
  }

  public clear() {
    this.collectionNames.value = [];
    this.lastPrompt = "";
  }
}
