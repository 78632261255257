<template>
  <component
    :is="inputComponent.component"
    v-if="inputComponent"
    ref="inputElRef"
    v-bind="inputComponent.props"
    v-model="fieldValue"
    :label="inputLabel"
    :class="classes" />
  <template v-else-if="computedField">
    <computed-input v-model="fieldValue" :field="computedField" :record :entity :label="inputLabel" :classes :context />
  </template>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted, nextTick } from "vue";

import { useFocusWithin } from "@vueuse/core";

import { mergeInputConfig } from "@ui/inputs/inputConfig";
import type { InputConfig } from "@ui/inputs/inputConfig";

import ComputedInput from "./ComputedInput.vue";
import { getFieldInput, extendedFieldRegistry } from "./fieldInputs";

import { KEntity } from "@data/data/KEntity";
import type { KFieldValue, KRecord } from "@data/data/Record";
import type { KContext } from "@data/expressions/context";
import type { RegisteredField } from "@data/fields/FieldRegistry";
import type { KField } from "@data/fields/KField";
import { ComputedField } from "@data/fields/computed/ComputedField";
import type { ValidationIssues } from "@data/validation/Validation";

import { useHasPermissions } from "@/helpers/permissions";
import { useKContext } from "@/expressions/context";

import type { InputMode } from "./fieldInputs";

const props = withDefaults(
  defineProps<{
    /** Current value of the field input */
    modelValue: KFieldValue;
    /** Record we're editing at the moment (used for validation purposes) */
    record?: KRecord;
    /** Entity of the record (used for some input types) */
    entity?: KEntity;
    /** Field which input we're displaying */
    field: KField;
    /** Whether we are editing the field (as some fields prevent editing once created) */
    mode: InputMode;
    /** Whether this input can be edited */
    readonly?: boolean;
    /** Whether this input is readonly (shows required asterisk) */
    required?: boolean;
    /** Validation issues to show */
    issues?: ValidationIssues;
    /** Percentage width of this field */
    width?: number;
    /** Label for the field input, defaults to the field label */
    label?: string;
    /** Whether to show the field in a container */
    noContainer?: boolean;
    /** Hides label */
    hideLabel?: boolean;
    /** Indicates whether the field is within an inline layout container */
    isInline?: boolean;
    /** Indicates whether the field should show its issues lazily */
    showIssuesLazy?: boolean;
    /** Indicates whether to focus itself when it mounts */
    autofocus?: boolean;
  }>(),
  {
    record: () => ({ id: 0 }),
    entity: undefined,
    readonly: undefined,
    required: false,
    associationOptions: undefined,
    customOptions: undefined,
    width: undefined,
    label: undefined,
    issues: () => []
  }
);
const emit = defineEmits<{
  (event: "update:modelValue", value: KFieldValue): void;
}>();

const showIssues = ref(false);

watch(
  () => props.showIssuesLazy,
  () => (showIssues.value = !props.showIssuesLazy)
);

const inputElRef = ref<HTMLElement | null>(null);
const { focused } = useFocusWithin(inputElRef);
watch(focused, (newValue) => {
  if (!newValue) showIssues.value = true;
});

onMounted(() => {
  showIssues.value = !props.showIssuesLazy;
  void nextTick(() => {
    if (props.autofocus && inputElRef.value) {
      const inputElRefVal = inputElRef.value as unknown as { $el: HTMLElement };
      inputElRefVal.$el.getElementsByTagName("input")[0].focus();
    }
  });
});

const fieldValue = ref<KFieldValue>();

const inputEntity = computed(() => props.entity ?? new KEntity("Unknown", [props.field]));
const computedField = computed(() => props.field instanceof ComputedField && props.field);
const inputComponent = computed(() => {
  if (computedField.value) {
    return undefined;
  }
  if (extendedFieldRegistry.has(props.field.type)) {
    return getFieldInput.call(props.field as RegisteredField, inputEntity.value, props.mode);
  }
  console.error(`Field type ${props.field.type} is not supported for input yet`);
  return undefined;
});

const lastRecievedValue = ref<KFieldValue>();
const updateFromVModel = (newValue: KFieldValue) => {
  lastRecievedValue.value = newValue;
  fieldValue.value = newValue;
};

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
updateFromVModel(props.modelValue);

const context = useKContext(computed<KContext>(() => ({ record: props.record, entity: inputEntity.value, state: { editing: props.mode === "edit" } })));

const canWrite = useHasPermissions(
  computed(() => props.field.permissions.write),
  context
);

const disabled = computed(() => props.readonly || !canWrite.value);

const config = computed<Partial<InputConfig>>(() => ({
  disabled: disabled.value,
  placeholder: props.field.form.label ?? props.field.label,
  inlineDescription: props.field.form.showDescription ?? true,
  description: props.field.description,
  readonly: props.readonly,
  required: props.required,
  issues: showIssues.value ? props.issues : [],
  noContainer: props.noContainer,
  hideLabel: props.hideLabel,
  autofocus: props.autofocus
}));

const classes = computed(() => {
  const result: string[] = [];
  if (props.width && props.width < 100) {
    result.push(`w-${props.width}`);
  }
  if (props.isInline) {
    result.push("form-control-col");
  }
  return result;
});

const inputLabel = computed(() => props.label ?? config.value.placeholder);

mergeInputConfig(config);

watch(fieldValue, (newValue) => {
  if (newValue !== lastRecievedValue.value) {
    emit("update:modelValue", newValue);
  }
});

watch(
  () => props.modelValue,
  (newValue) => updateFromVModel(newValue)
);
</script>
