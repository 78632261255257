import { filterEmpty } from "../helpers/NullHelpers";
import { toSlug } from "../helpers/strings/Casing";
import { toPlural } from "../helpers/strings/Plurals";

import type { KRecord, StoredKRecord } from "./Record";
import type { KField } from "../fields/KField";
import type { ConditionalValidator } from "../validation/ConditionalValidation";

type Constructor<T> = new (...args: never[]) => T;

export interface EntityOptions {
  plural: string;
  slug: string;
  icon: string;
  primaryKey: string;
  secondaryKey: string;
  sortKey: string;
  sortDirection: "ASC" | "DESC";
  expressionPrefix: "record" | "person";
}

const flattenSubfields = (fields: readonly KField[]): readonly KField[] =>
  fields.flatMap((f) => {
    if (f.subfields.length) {
      return [f, ...flattenSubfields(f.subfields)];
    }
    return [f];
  });

export class KEntity {
  /** Readable singular form, capitalised (e.g. "Purchase Order") */
  singular: string;

  /** Readable plural form, capitalised (e.g. "Purchase Orders") */
  plural: string;

  /** Path to use in URL (e.g. "purchase-orders"). Doubles as the unique identifier for the entity */
  slug: string;

  /** Icon to display in toolbars etc. */
  icon?: string;

  /** Primary field of entity to show in lists & searches */
  primaryField: KField;

  /** Secondary field of entity to show in lists & searches */
  secondaryField?: KField;

  /** Fields that aren't intrinsic */
  fields: KField[];

  /** Full list of fields that make up the schema for this entity */
  get schema(): readonly KField[] {
    return this.intrinsicFields.concat(this.fields);
  }

  /** All fields, including subfields */
  get allFields(): readonly KField[] {
    return flattenSubfields(this.fields);
  }

  /** All fields, including subfields AND intrinsic fields */
  get fullSchema(): readonly KField[] {
    return flattenSubfields(this.schema);
  }

  /** Property of entity to sort by (must be a key in the schema) */
  sortField: KField;

  /** ID field to differentiate records (cached for better performance) */
  private _idField?: KField;

  get idField(): KField {
    if (!this._idField) {
      this._idField = this.getField("id") ?? this.primaryField;
    }
    return this._idField;
  }

  /** Direction of sorting of the sort Property options= 'ASC' or 'DESC' */
  defaultSortDirection?: "ASC" | "DESC";

  /** Conditional validators for fields */
  validators: ConditionalValidator[] = [];

  /** Options passed to the constructor (for later serialisation) */
  options?: Partial<EntityOptions>;

  get expressionPrefix() {
    return this.options?.expressionPrefix ?? "record";
  }

  constructor(
    singular: string,
    fields: KField[],
    options?: Partial<EntityOptions>,
    public readonly intrinsicFields: readonly KField[] = []
  ) {
    this.options = options;
    this.singular = singular;
    this.plural = options?.plural ?? toPlural(singular);
    this.icon = options?.icon;
    this.fields = fields;
    this.slug = options?.slug ?? toSlug(this.plural);
    this.primaryField = this.getField(options?.primaryKey) ?? fields[0];
    this.secondaryField = this.getField(options?.secondaryKey);
    this.sortField = this.getField(options?.sortKey) ?? this.primaryField;
    this.defaultSortDirection = options?.sortDirection;
  }

  /**
   * Get all fields matching type F
   *
   * @template F - Type of field to return
   * @param typeFilter Type of F (type-safe if passed)
   * @returns All fields from schema matching type F
   */
  getFieldsOfType<F extends KField = KField>(typeFilter?: Constructor<F> | Constructor<F>[]): F[] {
    if (typeFilter) {
      if (Array.isArray(typeFilter)) {
        return this.fullSchema.filter((f) => typeFilter.some((t) => f instanceof t)) as F[];
      }
      return this.fullSchema.filter((f) => f instanceof typeFilter) as F[];
    }
    return this.fullSchema as F[];
  }

  /**
   * Get a matching field from the schema if it exists
   *
   * @template F - Type of field to return
   * @param id ID or key of field to return
   * @param {Constructor<F>} typeFilter Type to filter by (more type-safe than getFields<...>(...))
   * @returns Field with matching key
   */
  getField<F extends KField = KField>(id: string | undefined, typeFilter?: Constructor<F>): F | undefined {
    return id ? this.getFieldsOfType(typeFilter).find((f) => f.id === id || f.key === id) : undefined;
  }

  /**
   * Get all matching fields from the schema
   *
   * @template F - Type of field to return
   * @param {string[]} ids IDs or keys of field to return
   * @param {Constructor<F>} typeFilter Type to filter by (more type-safe than getFields<...>(...))
   * @returns Fields that match the keys
   */
  getFields<F extends KField = KField>(ids: string[], typeFilter?: Constructor<F>): F[] {
    const correctType = this.getFieldsOfType(typeFilter);
    // order by input order
    return filterEmpty(ids.map((id) => correctType.find((f) => f.id === id || f.key === id)));
  }

  /**
   * Helper function to get the ID of a record
   *
   * @param record Record to get ID of
   * @returns String ID of record
   */
  getId(record: KRecord): string {
    const value = this.idField.getValue(record);
    switch (typeof value) {
      case "string":
        return value;
      case "number":
        return value.toString();
      default:
        return this.idField.getDisplayValue(record);
    }
  }

  validateRecordType(record: KRecord): boolean {
    return this.schema.every((f) => {
      const value = f.getValue(record);
      if (value != null) {
        return f.validateType(value);
      }
      return true;
    });
  }

  /** Convert a record of this entity to JSON-serialisable form */
  storeRecord(record: KRecord): StoredKRecord {
    const stored: StoredKRecord = {};
    for (const f of this.schema) {
      if (!f.serverControlled) {
        f.storeValue(record, stored);
      }
    }
    return stored;
  }

  /** Convert an object created with storeRecord back into a full record */
  restoreRecord(stored: StoredKRecord): KRecord {
    const newRecord = {};
    for (const f of this.schema) {
      f.restoreValue(stored, newRecord);
    }
    return newRecord;
  }
}
