import { refine } from "../../helpers/NullHelpers";
import { quantityRegex, parseQuantity } from "../../units/parseUnit";
import { getFieldOfType, matchAccessor, NumericalAccessor } from "../accessors";
import { NumberConstant, mathsConstants } from "../constants";
import { NumberItemExpression } from "../data/conditions";
import { NegationExpression, SumExpression, ReciprocalExpression, ProductExpression } from "../maths";
import { ParsingError, type NumberExpression } from "../expressions";
import { coalesceExpression } from "../generic";

import { checkAndSplit } from "./operators";
import { itemRegex } from "./regexes";

import type { KContext } from "../context";
import type { ExpressionParser } from "../parser";

export function parseNumber(this: ExpressionParser, expression: string | string[], context: KContext): NumberExpression {
  return this.baseParse(expression, "number", context, {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    recurse: parseNumber.bind(this),
    single: (token) => {
      // check for negation
      if (token.startsWith("-")) {
        const subexpression = this.parseNumber(token.slice(1), context);
        if (subexpression instanceof NumberConstant) {
          const value = subexpression.evaluate();
          return new NumberConstant(value === undefined ? undefined : -value, subexpression.unit);
        }
        return new NegationExpression(subexpression);
      }
      if (token === "null") {
        return new NumberConstant(undefined, undefined);
      }
      const constant = mathsConstants.get(token);
      if (constant) {
        return constant;
      }
      // check for accessors
      const accessor = matchAccessor(token);
      if (accessor) {
        const field = getFieldOfType(context, accessor, ["number", "duration"]);
        return new NumericalAccessor(accessor.type, field);
      }
      const itemMatch = token.match(itemRegex);
      if (itemMatch) {
        const depth = itemMatch[1] ? parseInt(itemMatch[1]) - 1 : 0;
        return new NumberItemExpression(depth);
      }
      if (quantityRegex.test(token)) {
        const { value, unit } = parseQuantity(token);
        return new NumberConstant(value, unit);
      }
      throw new ParsingError(`Unknown number constant: ${token}`);
    },
    multi: (tokens) => {
      // maths - evaluate in order of precedence
      const addsub = checkAndSplit(tokens, ["+", "-"]);
      if (addsub) {
        const subexpressions: NumberExpression[] = [];
        let negated = false;
        for (const thing of addsub) {
          if (Array.isArray(thing)) {
            const parsed = this.parseNumber(thing, context);
            subexpressions.push(negated ? new NegationExpression(parsed) : parsed);
            negated = false;
          } else {
            negated = thing === "-";
          }
        }
        if (subexpressions.length === 1) {
          return subexpressions[0];
        }
        return new SumExpression(subexpressions);
      }
      const proddiv = checkAndSplit(tokens, ["*", "x", "/"]);
      if (proddiv) {
        const subexpressions: NumberExpression[] = [];
        let reciprocal = false;
        for (const thing of proddiv) {
          if (Array.isArray(thing)) {
            const parsed = this.parseNumber(thing, context);
            subexpressions.push(reciprocal ? new ReciprocalExpression(parsed) : parsed);
            reciprocal = false;
          } else {
            reciprocal = thing === "/";
          }
        }
        return new ProductExpression(subexpressions);
      }
      const coalesce = checkAndSplit(tokens, ["??"]);
      if (coalesce) {
        return coalesceExpression(refine(coalesce, (s) => (Array.isArray(s) ? this.parseNumber(s, context) : undefined)));
      }
      throw new ParsingError(`Unknown number expression: ${tokens.join(" ")}`);
    }
  });
}
