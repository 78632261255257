<template>
  <k-input-file v-model="input" :label :accept :max-size="maxSize" multiple />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { FileUpload } from "./FileUpload";
import KInputFile from "./KInputFile.vue";

import type { FileMetadata } from "@data/fields/complex/FileField";

import { RecordAPI } from "@/api";
import { maxFileTransferSize } from "@/api/file";
import { useInitialState } from "@/api/initial";

const props = defineProps<{
  /** Value of the input */
  modelValue?: FileMetadata[];
  /** Label for the input */
  label?: string;
  /** What file types are allowed */
  accept?: string;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: FileMetadata[] | undefined): void;
}>();

const input = ref<FileUpload[]>([]);
const { mutate: uploadFile } = RecordAPI.uploadPendingFile();
const { tenant } = useInitialState();
const maxSize = computed(() => {
  const tenantMaxSize = tenant.value?.fileStorage?.maxFileSize;
  return Math.min(tenantMaxSize ?? Infinity, maxFileTransferSize);
});

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      input.value = value.map((data) => {
        const upload = new FileUpload();
        if ("id" in data) {
          upload.status = "UPLOADED";
          upload.url = "/api/v1/files/" + data.id;
          upload.id = data.id;
        } else {
          upload.status = "ERROR";
        }
        upload.description = data.name;
        return upload;
      });
    } else {
      input.value = [];
    }
  },
  { immediate: true }
);

watch(
  () => input.value,
  (value) => {
    // KInputFile marks files as TO_DELETE when they are removed from the input
    const deletedFileIds = new Set(value.filter((f) => f.status === "TO_DELETE").map((f) => f.id));
    if (deletedFileIds.size === 0) return;
    emit(
      "update:modelValue",
      props.modelValue?.filter((f) => !deletedFileIds.has(f.id))
    );
  },
  { deep: true }
);

watch(
  input,
  async (newValue) => {
    // check for new files and upload them
    let didUpload = false;
    for (const file of newValue) {
      if (file.status === "NOT_UPLOADED" && file.file) {
        didUpload = true;
        file.status = "UPLOADING";
        file.uploadProgress = 50;
        try {
          const response = await uploadFile({ file: file.file });
          if (response?.data?.uploadPendingFile) {
            file.status = "UPLOADED";
            file.id = response.data.uploadPendingFile.id;
          } else {
            file.status = "ERROR";
          }
        } catch (e) {
          file.status = "ERROR";
        }
      }
    }
    // emit new value if all files were uploaded successfully
    if (didUpload && newValue.every((f) => f.status === "UPLOADED")) {
      emit(
        "update:modelValue",
        newValue.map((f) => ({ name: f.fileName || f.description, id: f.id!, size: f.size }))
      );
    }
  },
  { deep: true }
);
</script>
