<template>
  <div ref="autocompleteElement" class="d-inline-block form-control-container autocomplete position-relative">
    <k-label :id="uuid" :label />
    <div
      :id="uuid"
      class="form-select form-select-sm risk-input"
      :class="{ focus: isOpen, ...classes }"
      :aria-label="label"
      placeholder="Type"
      role="button"
      tabindex="0"
      readonly
      autocomplete="autocomplete-form-control"
      @keydown.enter="onFocus"
      @click="onFocus"
      @focus="onFocus"
      @blur="onBlur">
      <template v-if="currentDisplayValue">
        {{ currentDisplayValue.score.label }}
        <div class="float-end text-center">
          <div :class="`risk-badge bg-l-20-${currentDisplayValue.impact?.color}`">Impact: {{ currentDisplayValue.impact?.value }}</div>
          ×
          <div :class="`risk-badge bg-l-20-${currentDisplayValue.likelihood?.color}`">Likelihood: {{ currentDisplayValue.likelihood?.value }}</div>
          =
          <div :class="`risk-badge score-badge bg-d-10-${currentDisplayValue.score.variant}`">Score: {{ currentDisplayValue.score.value }}</div>
        </div>
      </template>
      <span v-else class="risk-placeholder">{{ config.placeholder || label }}</span>
    </div>

    <div class="dropdown-menu autocomplete-results risk-picker-dropdown px-3 pt-3" :class="{ show: isOpen }">
      <div class="d-flex">
        <div class="risk-matrix-left-label position-relative">
          <div class="risk-matrix-left-label-content">
            <k-label label="Likelihood" description="" />
          </div>
        </div>
        <div class="risk-matrix text-center flex-grow-1">
          <div v-for="(likelihood, i) in riskLikelihoodScale" :key="i" class="risk-row d-flex">
            <div class="risk-label text-end pe-2" :class="{ selected: likelihood.value === currentValue?.likelihood }">
              {{ likelihood.label }}
            </div>
            <k-input-risk-option
              v-for="(impact, j) in riskImpactScale"
              :key="j"
              :current-value="currentValue"
              :impact="impact.value"
              :likelihood="likelihood.value"
              @click="setValue" />
          </div>
          <div class="risk-row d-flex">
            <div class="risk-label"></div>
            <div v-for="(impact, i) in riskImpactScale" :key="i" class="risk-label bottom" :class="{ selected: impact.value === currentValue?.impact }">
              {{ impact.label }}
            </div>
          </div>
          <div class="d-flex mb-0 pt-1">
            <div class="risk-label mb-n3"></div>
            <div class="flex-grow-1 pt-2">
              <k-label label="Impact" class="mb-0" description="" />
            </div>
            <div v-if="!required" class="text-end mb-1">
              <k-button variant="transparent" icon="undo" label="Clear" @click="resetValue" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { onClickOutside, useDebounceFn } from "@vueuse/core";
import { v4 } from "uuid";

import { getInputClasses, useInputConfig } from "../inputConfig";

import KLabel from "@ui/label/KLabel.vue";
import KButton from "@ui/button/KButton.vue";

import KInputRiskOption from "./KInputRiskOption.vue";

import { RiskScore, riskImpactScale, riskLikelihoodScale, getRiskScore } from "@data/types/RiskScore";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: RiskScore | null;
  /** Label for the input */
  label?: string;
  /** Whether a value is required (i.e. if a null option shouldn't be shown) */
  required?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: RiskScore | undefined): void;
}>();

const uuid = v4();

const isOpen = ref(false);
const autocompleteElement = ref<HTMLElement>();
const config = useInputConfig(props);

// Currently selected type
const currentValue = ref<RiskScore>();

const currentDisplayValue = computed(() => {
  if (!currentValue.value || !currentValue.value.score) return;
  const impact = riskImpactScale.find((i) => i.value === currentValue.value?.impact);
  const likelihood = riskLikelihoodScale.find((i) => i.value === currentValue.value?.likelihood);
  const score = getRiskScore(currentValue.value.score);
  return {
    impact,
    likelihood,
    score
  };
});

watch(
  () => props.modelValue,
  (newVal) => {
    currentValue.value = newVal ?? undefined;
  },
  { immediate: true }
);

const openSelect = () => {
  if (isOpen.value) return;

  isOpen.value = true;
};

const onFocus = () => openSelect();

// Close dropdown
const closeSelect = () => {
  if (!isOpen.value) return;
  isOpen.value = false;
};

onClickOutside(autocompleteElement, () => {
  closeSelect();
});

const onBlur = (evt: FocusEvent) => {
  if (!autocompleteElement.value?.contains(evt.relatedTarget as Node)) {
    closeSelect();
  }
};

const debouncedClose = useDebounceFn(closeSelect, 150);

const setValue = (impact: number, likelihood: number) => {
  currentValue.value = new RiskScore({ impact, likelihood });
  emit("update:modelValue", currentValue.value);
  void debouncedClose();
};

const resetValue = () => {
  currentValue.value = undefined;
  emit("update:modelValue", currentValue.value);
};

const classes = computed(() => ({
  ...getInputClasses(config.value, config.value.readonly ? "control" : "select"),
  placeholder: !props.modelValue
}));
</script>

<style scoped lang="scss">
.risk-input {
  user-select: none;
  -webkit-user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 25px;
  min-width: 130px;
}
.risk-label {
  width: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 0.9em;
  &.bottom {
    width: 30px;
    flex-grow: 1;
    height: 30px;
  }
  &.selected {
    font-weight: bold;
  }
}

.risk-picker-dropdown {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;
  -webkit-user-select: none;
}

.risk-picker-dropdown.show {
  height: 250px;
  max-height: 250px;
}

.risk-matrix-left-label {
  width: 20px;
}

.risk-matrix-left-label-content {
  rotate: -90deg;
  position: absolute;
  top: 30%;
  left: -20px;
}

.risk-badge {
  border-radius: 4px;
  line-height: 20px;
  padding: 0 6px;
  min-width: 72px;
  font-variant-numeric: tabular-nums;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  &.score-badge {
    color: white;
  }
  &.bg-d-10-yellow {
    color: black;
  }
}

.risk-placeholder {
  color: var(--k-placeholder-color);
}
</style>
