<template>
  <div class="form-control-container">
    <k-label v-if="!config.hideLabel" :label="label ?? entity.plural" class="mt-3" />
    <k-item-list
      v-if="records.length"
      :items="records"
      :context-menu-items="contextItems"
      :disable-context-menu-evaluator="(item?: KRecordWithId) => item?.id === editing">
      <template #default="{ item }">
        <div class="d-inline-block record-item">
          <div class="d-flex align-items-end">
            <template v-if="editing === item.id">
              <div class="flex-grow-1">
                <k-input-config hide-label>
                  <k-field-input v-model="editedPrimary" :field="entity.primaryField" mode="edit" />
                </k-input-config>
              </div>
              <div class="ms-2">
                <k-input-config hide-label>
                  <k-field-input v-if="entity.secondaryField" v-model="editedSecondary" :field="entity.secondaryField" mode="edit" />
                </k-input-config>
              </div>
              <k-button title="Cancel" icon="xmark" class="ms-2" @click="editing = undefined" />
              <k-button title="OK" :loading icon="check" variant="primary" class="ms-1" @click="submitEdit" />
            </template>
            <template v-else>
              <span class="flex-grow-1">{{ props.entity.primaryField.getDisplayValue(item) }} </span>
              <k-field-display v-if="props.entity.secondaryField" :record="item" :field="props.entity.secondaryField" :entity="props.entity" hide-label />
            </template>
          </div>
        </div>
      </template>
    </k-item-list>
    <div v-else class="text-secondary pb-2">No {{ entity.plural.toLowerCase() }} added</div>
    <div v-if="editable" class="create-record d-flex align-items-end" :class="{ 'mt-n2': records.length }">
      <div class="flex-grow-1">
        <k-input-config hide-label>
          <k-field-input v-model="newPrimary" :field="entity.primaryField" mode="add" @keydown.enter="addRecord" />
        </k-input-config>
      </div>
      <div v-if="entity.secondaryField" class="ps-2">
        <k-input-config hide-label>
          <k-field-input v-model="newSecondary" :field="entity.secondaryField" mode="add" @keydown.enter="addRecord" />
        </k-input-config>
      </div>
      <div>
        <k-button variant="primary" :loading title="Add" icon="plus" :disabled="!canAdd" class="ms-2" @click="addRecord" />
      </div>
    </div>
    <k-issue-display />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";

import { v4 } from "uuid";

import KItemList from "@ui/item-list/KItemList.vue";
import KButton from "@ui/button/KButton.vue";
import KLabel from "@ui/label/KLabel.vue";
import type { ContextMenuItem } from "@ui/context-menu/ContextMenuItem";
import { useInputConfig } from "@ui/inputs/inputConfig";
import KInputConfig from "@ui/inputs/KInputConfig.vue";
import KIssueDisplay from "@ui/inputs/KIssueDisplay.vue";
import type { KRecordWithId } from "@ui/inputs/select/SelectItem";

import type { KEntity } from "@data/data/KEntity";
import type { KRecord } from "@data/data/Record";

import KFieldDisplay from "@/components/displays/KFieldDisplay.vue";
import KFieldInput from "@/components/inputs/KFieldInput.vue";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: KRecord[];
  /** Label for the input */
  label?: string;
  /** What entity we are creating records of */
  entity: KEntity;
  /** Whether a field mutation is in progress */
  loading?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: KRecord[] | undefined): void;
  (e: "changeValue", value: KRecord[]): void;
}>();

const config = useInputConfig();

const records = ref<KRecordWithId[]>([]);
const editable = computed(() => !(config.value.disabled || config.value.readonly));

const newPrimary = ref<unknown>();
const newSecondary = ref<unknown>();

const canAdd = computed(() => newPrimary.value);

const addRecord = () => {
  if (canAdd.value) {
    const newRecord: KRecordWithId = { id: v4() };
    newRecord[props.entity.primaryField.key] = newPrimary.value;
    if (props.entity.secondaryField) {
      newRecord[props.entity.secondaryField.key] = newSecondary.value;
    }
    records.value.push(newRecord);
    emit("update:modelValue", records.value);
    emit("changeValue", records.value);
    newPrimary.value = undefined;
    newSecondary.value = undefined;
  }
};

const editing = ref<string>();
const editedPrimary = ref<unknown>();
const editedSecondary = ref<unknown>();

const editRecord = (r: KRecordWithId) => {
  editing.value = r.id;
  editedPrimary.value = props.entity.primaryField.getValue(r);
  editedSecondary.value = props.entity.secondaryField?.getValue(r);
};

const submitEdit = () => {
  const edited = records.value.find((r) => r.id === editing.value);
  if (edited) {
    edited[props.entity.primaryField.key] = editedPrimary.value;
    if (props.entity.secondaryField) {
      edited[props.entity.secondaryField.key] = editedSecondary.value;
    }
    emit("update:modelValue", records.value);
    emit("changeValue", records.value);
  } else {
    throw new Error("Can't find edited field!");
  }
  editing.value = undefined;
};

const contextItems = computed<ContextMenuItem<KRecordWithId>[] | undefined>(() => {
  if (!editable.value) return undefined;
  return [
    {
      label: "Edit",
      icon: "pencil",
      onClick: (record) => {
        if (record) {
          editRecord(record);
        }
      }
    },
    {
      label: "Move up",
      icon: "arrow-up",
      showOption: (record) => !!record && records.value.indexOf(record) > 0,
      onClick: (record) => {
        if (record) {
          const idx = records.value.indexOf(record);
          records.value.splice(idx, 1);
          records.value.splice(idx - 1, 0, record);
          emit("update:modelValue", records.value);
        }
      }
    },
    {
      label: "Move down",
      icon: "arrow-down",
      showOption: (record) => !!record && records.value.indexOf(record) < records.value.length - 1,
      onClick: (record) => {
        if (record) {
          const idx = records.value.indexOf(record);
          records.value.splice(idx, 1);
          records.value.splice(idx + 1, 0, record);
          emit("update:modelValue", records.value);
        }
      }
    },
    {
      label: "Delete",
      icon: "trash",
      onClick: (record) => {
        if (record) {
          records.value.splice(records.value.indexOf(record), 1);
          emit("update:modelValue", records.value);
        }
      },
      variant: "danger"
    }
  ];
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (records.value.length !== newValue?.length || !newValue.every((r, idx) => r.id === records.value[idx].id)) {
      records.value = newValue?.map((r) => ({ id: v4(), ...r })) ?? [];
    }
  },
  { immediate: true }
);
</script>

<style scoped>
.create-record {
  opacity: 1;
}

.record-item {
  width: calc(100% - 30px);
}
</style>
