/** Bidirection map utility class */
export class BiMap<A, B> {
  _forwards = new Map<A, B>();

  _backwards = new Map<B, A>();

  constructor(items?: [A, B][]) {
    if (items) {
      for (const [a, b] of items) this.set(a, b);
    }
  }

  set(a: A, b: B) {
    if (this._forwards.has(a)) {
      throw new Error(`Forwards key already exists`);
    }
    if (this._backwards.has(b)) {
      throw new Error(`Backwards key already exists`);
    }
    this._forwards.set(a, b);
    this._backwards.set(b, a);
  }

  get(a: A) {
    return this._forwards.get(a);
  }

  getBack(b: B) {
    return this._backwards.get(b);
  }

  has(a: A) {
    return this._forwards.has(a);
  }

  hasBack(b: B) {
    return this._backwards.has(b);
  }

  remove(a: A) {
    const b = this._forwards.get(a);
    this._forwards.delete(a);
    if (b) {
      this._backwards.delete(b);
    }
  }

  removeBack(b: B) {
    const a = this._backwards.get(b);
    this._backwards.delete(b);
    if (a) {
      this._forwards.delete(a);
    }
  }

  get size() {
    return this._forwards.size;
  }

  entries() {
    return this._forwards.entries();
  }

  keys() {
    return this._forwards.keys();
  }

  backKeys() {
    return this._backwards.keys();
  }
}

/** Does not throw error for duplicate backwards keys, prioritises earlier entries */
export class ForwardsBiMap<A, B> extends BiMap<A, B> {
  set(a: A, b: B) {
    if (this._forwards.has(a)) {
      throw new Error(`Forwards key already exists`);
    }
    this._forwards.set(a, b);
    if (!this._backwards.has(b)) {
      this._backwards.set(b, a);
    }
  }
}
