import { computed, type Ref, toValue } from "vue";
import type { MaybeRef } from "vue";

import { useMutation, useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";

import { fullAddress } from "./fragments";

import type { Address } from "@data/types/Location";
import type { Tenant, TenantPlanDetails } from "@data/data/tenant/Tenant";
import type { TenantBilling, TaxCode, BillingFrequency } from "@data/data/tenant/Billing";
import type { TenantFileStorage } from "@data/data/tenant/Integrations";
import type { BillingState, BillingInvoice } from "@data/data/Billing";

import type { Dayjs } from "dayjs";

// graphql fragments
const planDetails = `plan {
  label
  variant
  planCode
  recordLimit
  storageLimit
}`;
const upcomingInvoice = `upcomingInvoice {
  id
  amountDue
  subtotalExcludingTax
  tax
  total
  nextPaymentAttempt
  lines { amount description quantity }
}`;

type FullBillingDetails = {
  tenant: Tenant & TenantPlanDetails & TenantFileStorage & TenantBilling;
  billingState: BillingState;
  billingInvoices: Partial<BillingInvoice>[];
};
const getTenantBillingDetails = () =>
  useQuery<FullBillingDetails>(
    gql`
      query tenantBillingState {
        tenant {
          id
          userCount
          activeUserCount
          planCode
          details {
            country
          }
          ${planDetails}
          billing {
            address {
              ${fullAddress}
            }
            vatNo {
              type
              code
            }
            frequency
            cancellation
            currentStatus
          }
          fileStorage {
            usedStorage
            maxStorage
          }
          storageConsumption {
            collectionId
            count
            fileSize
          }
        }
        billingState {
          currentPrice
          trialEndDate
          billingEmail
          paymentMethodInfo
          ${upcomingInvoice}
          latestPaymentIntent {
            id
            amount
            currency
            description
            paymentMethod
            receiptEmail
            status
            lastPaymentErrorMessage
            lastPaymentErrorType
            lastPaymentErrorDeclineCode
            nextActionType
            nextActionRedirectUrl
          }
        }
        billingInvoices {
          id
          total
          description
          periodEnd
          periodStart
          status
          attemptCount
          attempted
          endingBalance
          created
          amountDue
          amountPaid
          amountRemaining
          invoicePdf
          dueDate
        }
      }
    `,
    {},
    { returnPartialData: false, fetchPolicy: "cache-and-network" }
  );

export type PlanDetails = {
  label: string;
  variant: string;
  recordLimit?: number;
  storageLimit: number;
  automationLimit?: number;
  access: {
    api: boolean;
    eventLog: boolean;
    extensions: boolean;
  };
};
export type PlanPricing = {
  monthly?: number;
  yearly?: number;
};
const getPlanDetails = (planCode: MaybeRef<TenantPlanDetails["planCode"]>) =>
  useQuery<{ plan: PlanDetails; planPricing: PlanPricing }>(
    gql`
      query planDetails($planCode: String!) {
        plan(planCode: $planCode) {
          label
          variant
          recordLimit
          storageLimit
          automationLimit
          access {
            api
            eventLog
            extensions
          }
        }
        planPricing(planCode: $planCode) {
          monthly
          yearly
        }
      }
    `,
    computed(() => ({ planCode: toValue(planCode) })),
    { fetchPolicy: "cache-and-network" }
  );

export type PlanProrationInfo = {
  oldBasePrice: number;
  newBasePrice: number;
  proratedPrice: number;
  baseInvoice?: BillingInvoice;
};

const getPlanProrationInfo = (planCode: MaybeRef<TenantPlanDetails["planCode"]>, changeTime: Ref<Dayjs>, enable?: Ref<boolean>) =>
  useQuery<{ planProrationInfo?: PlanProrationInfo }, { newPlanCode: string; changeTime: string }>(
    gql`
      query planProrationInfo($newPlanCode: String!, $changeTime: DateTime!) {
        planProrationInfo(newPlanCode: $newPlanCode, changeTime: $changeTime) {
          oldBasePrice
          newBasePrice
          proratedPrice
          baseInvoice {
            amountDue
            subtotalExcludingTax
            tax
            total
            nextPaymentAttempt
          }
        }
      }
    `,
    computed(() => ({
      newPlanCode: toValue(planCode),
      changeTime: changeTime.value.toISOString()
    })),
    computed(() => ({
      fetchPolicy: "network-only",
      enabled: enable?.value ?? true
    }))
  );

type BillingDetailsInput = { vatNo?: TaxCode; address?: Address };
const updateBillingDetails = () =>
  useMutation<{ tenantUpdateBillingDetails: Tenant & TenantBilling }, { billingDetails: BillingDetailsInput }>(gql`
    mutation tenantUpdateBillingDetails($billingDetails: TenantBillingDetailsUpdateInput!) {
      tenantUpdateBillingDetails(details: $billingDetails) {
        id
        billing {
          address {
            ${fullAddress}
          }
          vatNo {
            type
            code
          }
          frequency
          currentStatus
        }
      }
    }
  `);

type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> };
// we don't really care about the return type, and different mutations may need to update different parts
type BillingUpdate = DeepPartial<FullBillingDetails>;
const updateTenantPlan = () =>
  useMutation<{ tenantUpdatePlan: BillingUpdate }, { planCode: string }>(gql`
    mutation tenantUpdatePlan($planCode: String!) {
      tenantUpdatePlan(planCode: $planCode){
        tenant {
          id
          planCode
          ${planDetails}
        }
        billingState {
          ${upcomingInvoice}
          currentPrice
        }
      }
    }
  `);

const updateTenantPlanFrequency = () =>
  useMutation<{ tenantUpdatePlanFrequency: BillingUpdate }, { newFrequency: BillingFrequency }>(gql`
    mutation tenantUpdatePlanFrequency($newFrequency: BillingFrequency!) {
      tenantUpdatePlanFrequency(newFrequency: $newFrequency){
        tenant {
          id
          billing {
            frequency
          }
        }
        billingState {
          ${upcomingInvoice}
          currentPrice
        }
      }
    }
  `);

const cancelTenantSubscription = () =>
  useMutation<{ tenantCancelSubscription: BillingUpdate }>(gql`
    mutation tenantCancelSubscription {
      tenantCancelSubscription{
        tenant {
          id
          billing {
            cancellation
          }
        }
        billingState {
          ${upcomingInvoice}
          currentPrice
        }
      }
    }
  `);

const restartTenantSubscription = () =>
  useMutation<{ tenantRestartSubscription: BillingUpdate }>(gql`
    mutation tenantRestartSubscription {
      tenantRestartSubscription{
        tenant {
          id
          billing {
            cancellation
          }
        }
        billingState {
          ${upcomingInvoice}
          currentPrice
        }
      }
    }
  `);

export const BillingAPI = {
  getTenantBillingDetails,
  getPlanProrationInfo,
  getPlanDetails,
  updateBillingDetails,
  updateTenantPlan,
  updateTenantPlanFrequency,
  cancelTenantSubscription,
  restartTenantSubscription
};
