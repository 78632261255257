import type { InjectionKey } from "vue";

import { v4 as uuidv4 } from "uuid";

import { fixValAgainstTypeDef } from "../helpers/fixValAgainstTypeDef";

import { inputColours } from "@ui/inputs/colour/InputColours";
import { iconList } from "@ui/inputs/icon/icons";
import type { ReadonlyRef } from "@ui/helpers/refHelpers";

import type { Collection } from "@data/data/Collection";
import type { KField } from "@data/fields/KField";
import type { JsonSerialisable } from "@data/helpers/serialisation/TypedJSON";
import type { ColourVariant } from "@data/types/ColourVariant";
import type { FieldType, ExtraFieldOptions } from "@data/constants/fieldTypes";
import { createField, fieldTypes } from "@data/constants/fieldTypes";

import { toTitleCase } from "@/helpers/useCollectionNameVariants";

import type { ArrayTypeDef, ObjectTypeDef } from "../helpers/fixValAgainstTypeDef";

// ===== INJECTION KEYS =====
export const proposedCollectionsInjectionKey = Symbol() as InjectionKey<ReadonlyRef<Collection[]>>;

// ===== GENERATED TYPES =====
export type InitialCollectionData = {
  name: string;
  icon: string;
};

type GeneratedOptions = { label: string; colour: ColourVariant }[];
type GeneratedField = {
  name: string;
  type: Exclude<FieldType, `record-${string}` | `multiRecord-${string}`> | "single-record" | "multiple-records";
  description: string;
  tableName?: string;
  options?: GeneratedOptions;
};
export type GeneratedCollection = {
  name: string;
  description: string;
  fields: GeneratedField[];
};

// ===== GENERATED FIELD TO KFIELD CONVERSION =====
export function getKFieldFromGeneratedField(generatedField: GeneratedField, existingFieldKeys: string[], relatableCollections: Collection[]): KField {
  let fieldType: FieldType;
  const extraFieldOptions: ExtraFieldOptions = { description: generatedField.description };
  // record types
  if (generatedField.type === "single-record" || generatedField.type === "multiple-records") {
    let collectionId;
    if (generatedField.tableName !== undefined) {
      const matchingExistingCollection = relatableCollections.find(({ plural }) => plural === generatedField.tableName);
      if (matchingExistingCollection) {
        collectionId = matchingExistingCollection.id;
      }
    }

    if (collectionId === undefined) {
      fieldType = "unknown";
    } else {
      fieldType = generatedField.type === "single-record" ? `record-${collectionId}` : `multiRecord-${collectionId}`;
    }
    // option types
  } else if (generatedField.type === "option") {
    if (generatedField.options === undefined) {
      fieldType = "unknown";
    } else {
      extraFieldOptions.options = [];
      for (const generatedOption of generatedField.options) {
        extraFieldOptions.options.push({ id: uuidv4(), label: generatedOption.label, variant: generatedOption.colour });
      }
      fieldType = "option";
    }
    // all other types
  } else {
    fieldType = generatedField.type;
  }
  return createField(fieldType, generatedField.name, existingFieldKeys, extraFieldOptions) as KField<unknown>;
}

// ===== FIXING GENERATED COLLECTIONS =====
const generatedOptionsTypeDef: ArrayTypeDef = {
  category: "array",
  itemType: {
    category: "object",
    type: [
      ["label", "string", ""],
      ["colour", inputColours, undefined]
    ]
  }
};
export function fixGeneratedCollection(generatedCollection: JsonSerialisable, relatableTableNames: string[]): GeneratedCollection {
  const generatedFieldsTypeDef: ArrayTypeDef = {
    category: "array",
    itemType: {
      category: "object",
      type: [
        ["name", "string", ""],
        ["type", [...Object.keys(fieldTypes), "single-record", "multiple-records"], "unknown"],
        ["description", "string", ""],
        ["tableName", relatableTableNames, undefined],
        ["options", generatedOptionsTypeDef, undefined]
      ]
    }
  };
  const generatedCollectionTypeDef: ObjectTypeDef = {
    category: "object",
    type: [
      // name is already checked as it's used as the id by gpt
      // icon was already generated as part of the initial collection data so gpt does not provide it
      ["description", "string", ""],
      ["fields", generatedFieldsTypeDef, undefined]
    ]
  };
  return fixValAgainstTypeDef(generatedCollection, generatedCollectionTypeDef, undefined) as GeneratedCollection;
}

// ===== FIXING INITIAL COLLECTION DATA =====
const generatedInitialCollectionDataTypeDef: ArrayTypeDef = {
  category: "array",
  itemType: {
    category: "object",
    type: [
      ["name", "string", ""],
      ["icon", iconList.map(({ icon }) => icon), "folder"]
    ]
  }
};

export function fixInitialCollectionData(generatedInitialCollectionData: JsonSerialisable): InitialCollectionData[] {
  return (fixValAgainstTypeDef(generatedInitialCollectionData, generatedInitialCollectionDataTypeDef, undefined) as InitialCollectionData[])
    .map((datum) => ({
      ...datum,
      name: toTitleCase(datum.name)
    }))
    .filter(({ name }) => !["Tasks", "Activities", "People", ""].includes(name));
}
