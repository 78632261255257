<!-- Component for the selection of multiple records of an unloaded collection -->
<template>
  <div class="form-control-container">
    <k-input-multiselect
      v-model="input"
      v-model:search="search"
      :label
      :options="multiOptions ?? []"
      :loading
      :has-more="hasMore"
      :can-add="showCreateRecordButton"
      :search-filter="fullResult ? undefined : () => true"
      @show-related-record-form="(value) => openModal(value)" />
    <record-form
      v-if="collection"
      v-model:visible="showRelatedRecordForm"
      :title="`Add linked ${collection.singular}`"
      :collection
      :prefill-fields="prefillField"
      is-related-record
      @new-option="(option) => selectNewOption(option)" />
  </div>
</template>

<script setup lang="ts">
import { computed, provide, ref, watch, watchEffect } from "vue";

import { useInputConfig, inputConfigKey } from "@ui/inputs/inputConfig";
import KInputMultiselect from "@ui/inputs/select/KInputMultiselect.vue";
import type { MultiselectItem } from "@ui/inputs/select/SelectItem";

import { useSearchedRecordOptions } from "./useSearchedRecordOptions";

import type { Collection } from "@data/data/Collection";
import type { KBRecord } from "@data/data/Record";
import type { KRecordOption, RelationalFilters } from "@data/fields/relational/RecordFields";
import type { ValidationIssues } from "@data/validation/Validation";

import { useRecordSubscription } from "@/helpers/collection";
import RecordForm from "@/pages/collections/records/form/RecordForm.vue";
import { useKContext } from "@/expressions/context";
import { useCollectionStore } from "@/api/stores/useCollectionStore";
import { useHasCollectionPermission } from "@/helpers/permissions";

import type { PrefilledField } from "@/components/form/FormTypes";

const props = defineProps<{
  /** Current value of the input */
  modelValue?: KRecordOption[];
  /** Id of the collection to show options for */
  collectionId: string;
  /** Filters to use */
  filters?: RelationalFilters;
  /** Label for the input */
  label?: string;
  /** Allow users to create on the fly - config option */
  canCreateRecord?: boolean;
}>();
const emit = defineEmits<{
  (event: "focus"): void;
  (event: "blur"): void;
  (event: "change", evt: Event): void;
  (event: "update:modelValue", newValue: KRecordOption[] | undefined): void;
}>();

const input = ref<string[]>([]);

const collectionStore = useCollectionStore();
const collection = collectionStore.useCollectionWithId(computed(() => props.collectionId));

const context = useKContext();
const { options, loading, search, hasMore, fullResult } = useSearchedRecordOptions({
  collection,
  propFilters: computed(() => props.filters),
  context,
  selected: computed(() => props.modelValue ?? [])
});

const config = useInputConfig();
const issues = computed<ValidationIssues>(() => (collection.value ? config.value.issues : [{ message: "This collection was deleted", severity: "warning" }]));

// manually providing these to avoid Vue throwing a warning about setting the class on KInputConfig
provide(inputConfigKey, {
  ...config.value,
  issues: issues.value,
  disabled: !collection.value
});
// if the current record is in the collection, it should not be an option
const forbiddenId = computed(() => {
  const entity = context.value.entity as Collection | undefined;
  const record = context.value.record as KBRecord | undefined;
  if (entity && record && props.collectionId === entity.id) {
    return record.id;
  }
  return undefined;
});

const newOptions = ref<KRecordOption[]>([]);
if (collection.value) {
  useRecordSubscription(
    computed(() => collection.value!),
    (event) => {
      if (event.eventType === "RECORD_ADDED" && collection.value) {
        for (const record of event.recordData ?? []) {
          const restoredRecord = collection.value.restoreRecord(record.data);
          newOptions.value.push({
            id: record.id,
            label: collection.value.primaryField.getDisplayValue(restoredRecord),
            secondaryLabel: collection.value.secondaryField?.getDisplayValue(restoredRecord)
          });
        }
      }
    }
  );
}

const fullOptions = ref<KRecordOption[]>([]);
// always include current values in the options
watchEffect(() => {
  let result = [...options.value];
  for (const lr of props.modelValue ?? []) {
    if (!options.value.some((o) => o.id === lr.id)) {
      result.push(lr);
    }
  }
  if (forbiddenId.value) {
    result = result.filter((o) => o.id !== forbiddenId.value);
  }
  if (newOptions.value.length > 0) {
    result = [...newOptions.value, ...result];
  }
  fullOptions.value = result;
});

const multiOptions = computed<MultiselectItem[]>(() =>
  fullOptions.value.map((o) => ({ value: o.id.toString(), label: o.label, secondaryLabel: o.secondaryLabel }))
);

watch(
  () => props.modelValue,
  (newValue) => {
    const newInput = newValue?.map((r) => r.id.toString());
    if (newInput?.length !== input.value.length || !input.value.every((i, idx) => i === newInput[idx])) {
      input.value = newInput ?? [];
    }
  },
  { immediate: true }
);
watch(
  input,
  (newValues) => {
    emit("update:modelValue", newValues.map((id) => fullOptions.value.find((o) => o.id === id)).filter((o) => o) as KRecordOption[]);
  },
  { deep: true }
);

const selectNewOption = (option?: KRecordOption) => {
  if (option) {
    input.value.push(option.id);
  } else {
    search.value = "";
  }
};

const hasAddPermission = useHasCollectionPermission(collection, "add");
const showCreateRecordButton = computed(() => props.canCreateRecord && hasAddPermission.value && !!collection.value && !collection.value.isReadOnly);

const showRelatedRecordForm = ref(false);
const prefillField = ref<PrefilledField[]>([]);
const openModal = (value: string) => {
  if (!collection.value) return;
  showRelatedRecordForm.value = true;
  const primaryField = collection.value.primaryField;
  prefillField.value.push({ key: primaryField.key, value, mode: "editable" });
};
</script>
