export const notEmpty = <T>(value: T | null | undefined): value is T => value != null;

export const nonEmptyMap = <K, V>(map: Map<K, V | undefined | null>): Map<K, V> => {
  const result = new Map<K, V>();
  for (const [k, v] of map.entries()) {
    if (notEmpty(v)) {
      result.set(k, v);
    }
  }
  return result;
};

export const filterEmpty = <T>(array: (T | null | undefined)[]): T[] => array.filter(notEmpty);

/** Checks if a value is an object, with better narrowing than typeof */
export const checkIsObject = (value: unknown): value is Record<string, unknown> => value !== null && typeof value === "object";

/** Helper function to assert that we've handled all cases of a discriminated union. */
export const assertNever = (value: never): never => {
  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
};

/**
 * Combines map and filterEmpty
 *
 * @param fn Conversion function, return undefined to filter out
 */
export const refine = <I, O>(input: Iterable<I>, fn: (input: I, idx: number) => O | undefined): O[] => {
  const arr = Array.isArray(input) ? input : [...input];
  return filterEmpty(arr.map(fn));
};
