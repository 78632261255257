<template>
  <div class="form-control-container">
    <k-input-select v-model="permissionType" :options="permissionOptions" :label required />
    <template v-if="permissionType === 'role' || permissionType === 'person'">
      <k-input-config description="People with these roles will have access">
        <k-input-multiselect v-model="rolePermissions" :options="roleOptions" label="Roles" />
      </k-input-config>
      <template v-if="permissionType === 'person' && ownerOptions.length">
        <k-input-config description="People assigned to this field will also have access">
          <k-input-field-select v-model="ownerField" :options="ownerOptions" label="Person Field"
        /></k-input-config>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";

import KInputConfig from "@ui/inputs/KInputConfig.vue";
import KInputFieldSelect from "@ui/inputs/select/KInputFieldSelect.vue";
import KInputSelect from "@ui/inputs/select/KInputSelect.vue";
import type { SelectItem } from "@ui/inputs/select/SelectItem";
import KInputMultiselect from "@ui/inputs/select/KInputMultiselect.vue";

import type { Collection } from "@data/data/Collection";
import { PersonField, PeopleField } from "@data/fields/relational/PeopleFields";
import { deepEquals } from "@data/helpers/manipulation/Comparison";
import type { PermissionInfo } from "@data/helpers/data/Permissions";
import { getPermissionInfo } from "@data/helpers/data/Permissions";

import { useInitialState } from "@/api/initial";

const props = defineProps<{
  /** Label for the input */
  label?: string;
  /** Current value of the input */
  modelValue?: string[];
  /** Collection to define permissions for (optional) */
  collection?: Collection;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: string[]): void;
}>();

const ownerField = ref<PersonField | PeopleField>();

const singleOwnerOptions = computed(() => props.collection?.getFieldsOfType(PersonField) ?? []);
const multiOwnerOptions = computed(() => props.collection?.getFieldsOfType(PeopleField) ?? []);
const ownerOptions = computed(() => [...singleOwnerOptions.value, ...multiOwnerOptions.value]);

const permissionOptions = computed<SelectItem[]>(() => {
  const options: { value: PermissionInfo["type"]; label: string }[] = [
    { value: "everyone", label: "Everyone" },
    { value: "role", label: "Role-specific" },
    { value: "nobody", label: "Nobody" }
  ];
  if (ownerOptions.value.length) {
    options.splice(2, 0, { value: "person", label: "Person-specific" });
  }
  return options;
});

const permissionType = ref<PermissionInfo["type"]>("custom");
const rolePermissions = ref<string[]>([]);

const { roles, personEntity } = useInitialState();
const peoplePersonFields = computed(() => personEntity.value.getFieldsOfType(PersonField));
const roleOptions = computed(() => {
  const basicOptions = roles.value?.map((r) => ({ value: `hasRole("${r.id}")`, label: r.name })) ?? [];
  const lookupOptions = singleOwnerOptions.value.flatMap((rf) =>
    peoplePersonFields.value.map((pf) => ({
      value: `personLookup(record.${rf.id}.id, person.${pf.id}.id) matches personId()`,
      label: `${pf.label} of ${rf.label}`
    }))
  );
  return basicOptions.concat(lookupOptions);
});

const generatedPermissions = computed<string[]>(() => {
  switch (permissionType.value) {
    case "everyone":
      return ["true"];
    case "role":
    case "person": {
      if (ownerField.value instanceof PersonField) {
        return [`record.${ownerField.value.id}.id matches personId()`].concat(rolePermissions.value);
      } else if (ownerField.value instanceof PeopleField) {
        return [`record.${ownerField.value.id}.id intersects [personId()]`].concat(rolePermissions.value);
      }
      return rolePermissions.value;
    }
  }
  return [];
});

watch(generatedPermissions, (value) => {
  // don't emit if empty permission to prevent reset to nobody
  if (permissionType.value === "role" && !rolePermissions.value.length) {
    return;
  }
  if (permissionType.value === "person" && !rolePermissions.value.length && !ownerField.value) {
    return;
  }

  if (!deepEquals(value, props.modelValue)) {
    emit("update:modelValue", value);
  }
});

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      const info = getPermissionInfo(value);
      if (permissionType.value === "person" && info.type === "role") {
        // don't downgrade to role if we have a person field
      } else {
        permissionType.value = info.type;
      }
      const vset = new Set(value);
      rolePermissions.value = roleOptions.value.map((r) => r.value).filter((exp) => vset.has(exp));
      switch (info.type) {
        case "person":
          ownerField.value = props.collection?.getField<PersonField>(info.fieldIds[0]);
          break;
      }
    } else {
      permissionType.value = "nobody";
    }
  },
  { immediate: true }
);
</script>
