import { toPlural } from "../helpers/strings/Plurals";
import { minute } from "../units/basic/si";
import { formatList } from "../helpers/strings/List";
import { LookupField } from "../fields/relational/LookupField";

import { InsufficientContextError } from "./context";
import { BulkData } from "./data";
import { DataExpression, ParsingError } from "./expressions";
import { BooleanExpression, DatetimeExpression, NumberExpression, StringExpression } from "./expressions";

import type { KEntity } from "../data/KEntity";
import type { KContext } from "./context";
import type { KRecord } from "../data/Record";
import type { KField } from "../fields/KField";
import type { DatetimePrecision } from "../fields/basic/DatetimeField";
import type { MetricUnit } from "../units/units";
import type { Dayjs } from "dayjs";

type DataTypes = {
  boolean: boolean;
  string: string;
  number: number;
  datetime: Dayjs;
  duration: number;
  data: ReturnType<DataExpression["evaluate"]>;
};

export interface ExpressionField<T extends keyof DataTypes = keyof DataTypes> {
  expressionType: T;
  /** Whether a field has distinct values (OptionField) or is freeform (StringField) */
  granular?: boolean;
  /** Unit to use (if numerical) */
  unit?: MetricUnit;
  /** Precision (if datetime) */
  precision?: DatetimePrecision;
  /** What type of object this is an id for */
  readonly idFor?: string;
  getExpressionValue(r: KRecord): DataTypes[T] | undefined;
}

export function isAccessible<O extends object>(e: O): e is O & ExpressionField<keyof DataTypes>;
export function isAccessible<O extends object, const T extends keyof DataTypes>(e: O, t: T): e is O & ExpressionField<T>;
export function isAccessible<O extends object, const T extends keyof DataTypes>(e: O, t?: T): boolean {
  if (e instanceof LookupField) {
    // lookup fields get special logic here as otherwise they always count as accessible
    return !!e.targetField && isAccessible(e.targetField, t as T);
  } else {
    return "expressionType" in e && (t === undefined || e.expressionType === t);
  }
}

type AccessorType = "record" | "person";
type FieldAccessor = {
  type: AccessorType;
  fieldId: string;
};

export const accessorRegex = /^(record|person)\.(\S*)$/;

export const multiAccessorRegex = /^(records|people)\.(\S*)$/;

export const matchAccessor = (token: string): FieldAccessor | undefined => {
  const match = token.match(accessorRegex);
  if (!match) return;
  return { type: match[1] as AccessorType, fieldId: match[2] };
};

export const matchMultiAccessor = (token: string): FieldAccessor | undefined => {
  const match = token.match(multiAccessorRegex);
  if (!match) return;
  switch (match[1]) {
    case "records":
      return { type: "record", fieldId: match[2] };
    case "people":
      return { type: "person", fieldId: match[2] };
  }
};

export const getField = (context: KContext, { type, fieldId }: FieldAccessor) => {
  let entity: KEntity;
  if (type === "record") {
    if (!context.entity) throw new InsufficientContextError("entity");
    entity = context.entity;
  } else {
    if (!context.personEntity) throw new InsufficientContextError("personEntity");
    entity = context.personEntity;
  }
  const field = entity.getField(fieldId);
  if (!field) {
    throw new ParsingError(`Couldn't find field with ID ${fieldId}`);
  }
  return field;
};

const dataDescriptions: Record<keyof DataTypes, string> = {
  boolean: "a boolean",
  string: "a string",
  number: "a number",
  datetime: "a date/time",
  duration: "a duration",
  data: "a list"
};

export const getFieldOfType = <const T extends keyof DataTypes>(
  context: KContext,
  accessor: FieldAccessor,
  type: T | readonly T[]
): KField & ExpressionField<T> => {
  const field = getField(context, accessor);
  if (typeof type === "string") {
    if (!isAccessible(field, type)) {
      throw new ParsingError(`${field.label} is not ${dataDescriptions[type]}`);
    }
    return field;
  } else {
    if (!type.some((t) => isAccessible(field, t))) {
      throw new ParsingError(
        `${field.label} is not ${formatList(
          type.map((t) => dataDescriptions[t]),
          { conjunction: "or" }
        )}`
      );
    }
    return field as KField & ExpressionField<T>;
  }
};

export const getEffectiveRecord = (context: KContext, accessor: AccessorType): KRecord => {
  switch (accessor) {
    case "record":
      if (!context.record) throw new InsufficientContextError("record");
      return context.record;
    case "person":
      if (!context.person) throw new InsufficientContextError("person");
      return { ...context.person, ...context.person.extraData };
  }
};

const displayAccessor = (field: KField, type: AccessorType, context: KContext) => {
  if (context.entity && context.entity !== context.personEntity && type === "person") {
    // make it clear that this refers to the person
    return `Current User's ${field.label}`;
  }
  return field.label;
};

export class StringAccessor extends StringExpression {
  constructor(
    private readonly accessorType: AccessorType,
    private readonly field: KField
  ) {
    super();
  }

  evaluate(context: KContext): string | undefined {
    const record = getEffectiveRecord(context, this.accessorType);
    if (isAccessible(this.field, "string")) {
      return this.field.getExpressionValue(record);
    }
    return this.field.getDisplayValue(record);
  }

  display(context: KContext): string {
    return displayAccessor(this.field, this.accessorType, context);
  }
}

export class NumericalAccessor extends NumberExpression {
  constructor(
    private readonly accessorType: AccessorType,
    private readonly field: KField & (ExpressionField<"number"> | ExpressionField<"duration">)
  ) {
    super();
    if ("unit" in field) {
      this.unit = field.unit;
    } else if (field.expressionType === "duration") {
      if (field.precision === "minute") {
        this.unit = minute;
      } else {
        throw new ParsingError("Duration field is too imprecise to be used as a number");
      }
    }
  }

  evaluate(context: KContext): number | undefined {
    const record = getEffectiveRecord(context, this.accessorType);
    const value = this.field.getExpressionValue(record);
    if (this.field.expressionType === "duration") {
      // convert to seconds
      return value ? value * 60 : undefined;
    }
    return value;
  }

  display(context: KContext): string {
    return displayAccessor(this.field, this.accessorType, context);
  }
}

export class DatetimeAccessor extends DatetimeExpression {
  constructor(
    private readonly accessorType: AccessorType,
    private readonly field: KField & ExpressionField<"datetime">
  ) {
    super(field.precision ?? "minute");
  }

  evaluate(context: KContext) {
    const record = getEffectiveRecord(context, this.accessorType);
    return this.field.getExpressionValue(record);
  }

  display(context: KContext): string {
    return displayAccessor(this.field, this.accessorType, context);
  }
}

export class BooleanAccessor extends BooleanExpression {
  constructor(
    private readonly accessorType: AccessorType,
    private readonly field: KField & ExpressionField<"boolean">
  ) {
    super();
  }

  evaluate(context: KContext) {
    const record = getEffectiveRecord(context, this.accessorType);
    return this.field.getExpressionValue(record);
  }

  display(context: KContext): string {
    return displayAccessor(this.field, this.accessorType, context);
  }
}

export class DataAccessor extends DataExpression {
  constructor(
    private readonly accessorType: AccessorType,
    private readonly field: KField & ExpressionField<"data">
  ) {
    super();
  }

  evaluate(context: KContext) {
    const record = getEffectiveRecord(context, this.accessorType);
    return this.field.getExpressionValue(record);
  }

  display(context: KContext): string {
    return displayAccessor(this.field, this.accessorType, context);
  }
}

export class MultiRecordAccessor extends DataExpression {
  constructor(
    private readonly accessorType: AccessorType,
    private readonly field: KField & ExpressionField<"string" | "number" | "datetime">
  ) {
    super();
  }

  evaluate(context: KContext) {
    if (!context.records) throw new InsufficientContextError("records");
    return new BulkData(context.records.map((r) => this.field.getExpressionValue(r)));
  }

  display(): string {
    return `record ${toPlural(this.field.label)}`;
  }
}
